import { grpc } from '@improbable-eng/grpc-web'
import type { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { retry } from '@reduxjs/toolkit/query/react'

import type { RootState } from '../../../app/store'
import { getSsoToken, requiredScope } from '../../../features/auth/authSlice'
import { AuthActions } from '../../../features/auth/authSlice'
import { ExchangeTokenRequest } from '../../../proto/auth_pb'
import { AuthAPI } from '../../../proto/auth_pb_service'
import {
  GRPCErrorResponseObject,
  authorizedGrpcRequest,
  grpcStandardRequestPromise,
} from '../../deskApiCommon/common'

/**
 * OperatorDeskAPIのためのgRPCクライアント
 * Unauthenticatedのエラー時は1度だけトークンリフレッシュを行う
 */
export const grpcBaseQuery =
  <
    TMessage extends grpc.ProtobufMessage,
    TResponse extends grpc.ProtobufMessage
  >(): BaseQueryFn<
    {
      service: grpc.MethodDefinition<TMessage, TResponse>
      body: TMessage
      disableAuth?: boolean
    },
    ReturnType<TResponse['toObject']>,
    GRPCErrorResponseObject
  > =>
  async (args, baseQueryAPI) => {
    let state = baseQueryAPI.getState() as RootState
    const accessToken = state.auth.accessToken ?? ''

    // 認証が必要なAPIで認証されていない時
    if (!args.disableAuth && !state.auth.loading && accessToken === '') {
      throw new Error('access token has not been set')
    }

    const originalRes = args.disableAuth
      ? await grpcStandardRequestPromise(args.service, args.body)
      : await authorizedGrpcRequest(args.service, args.body, accessToken)

    // Unauthenticated が返ってきた時に一度だけトークンリフレッシュを行う
    if (
      originalRes.error &&
      originalRes.error.code === grpc.Code.Unauthenticated
    ) {
      // トークンリフレッシュのためにAzureADTokenを取得する
      await baseQueryAPI.dispatch(getSsoToken())
      state = baseQueryAPI.getState() as RootState

      // トークンリフレッシュのリクエストを行う
      const req = new ExchangeTokenRequest()
      req.setSsoToken(state.auth.ssoToken ?? '')
      req.setGraphScope(requiredScope.join(' '))
      const refreshTokenRes = await grpcStandardRequestPromise(
        AuthAPI.ExchangeToken,
        req
      )
      // トークンリフレッシュがエラーだったらオリジナルのレスポンスを返す
      // この時リトライを行わないようにBailOutする https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#bailing-out-of-error-re-tries
      if (refreshTokenRes.error) {
        return retry.fail(originalRes.error)
      }

      // 新しいトークンをStoreに反映
      baseQueryAPI.dispatch(
        AuthActions.setRefreshToken({
          message: refreshTokenRes.data,
        })
      )

      // 新しいトークンでリトライする
      return await authorizedGrpcRequest(
        args.service,
        args.body,
        refreshTokenRes.data.accessToken
      )
    }

    return originalRes
  }
