import {
  Flex,
  Pill,
  Skeleton,
  SkeletonShape,
  Text,
} from '@fluentui/react-northstar'
import { AadUserConversationMember } from '@microsoft/microsoft-graph-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import CompactUserNames from '../../../components/CompactUserNames'
import { ellipsis } from '../../../utils'
import { requestersSelector } from '../requestersSlice'
import { setRequesterUserIds } from '../ticketSlice'
import styles from './Pill.module.css'

const RequestersFilterPill: React.FC = () => {
  const dispatch = useDispatch()
  const ticketState = useSelector((state: RootState) => state.ticket)
  const requesters = useSelector(requestersSelector.selectAll)
  const memberState = useSelector((state: RootState) => state.member)
  const members = memberState.ids.map(
    (id) => memberState.entities[id] as AadUserConversationMember
  )
  const usersState = useSelector((state: RootState) => state.users)
  const initializing = usersState.meId == null || members.length === 0

  return (
    <>
      {initializing ? (
        <Skeleton
          animation="pulse"
          className={styles.skelton}
          content={
            <SkeletonShape
              className={styles.skeltonShape}
              content={<Pill className={styles.pillSkelton} />}
            />
          }
        />
      ) : (
        <Pill
          actionable={ticketState.filters.requesterUserIds.length > 0}
          onDismiss={() =>
            dispatch(setRequesterUserIds({ requesterUserIds: [] }))
          }
          className={styles.pill}
        >
          <Flex vAlign="center">
            <Text content="依頼人：" className={styles.pillTitle} />
            {
              <CompactUserNames
                userNames={requesters.map((r) => ellipsis(r.displayName, 14))}
              />
            }
          </Flex>
        </Pill>
      )}
    </>
  )
}

export default RequestersFilterPill
