import * as React from 'react'

import { RecommendedFaqEntity } from '../RecommendedFaqList/types/RecommendedFaqEntity'
import RecommendedFaqTicketChat from './features/RecommendedFaqTicketChat'
import RecommendedFaqTicketUnselected from './features/RecommendedFaqTicketUnselected'
import RecommendedFaqTicketPresenter from './presenter'

type Props = {
  faq: RecommendedFaqEntity | null
}

const RecommendedFaqTicket: React.FC<Props> = (props) => {
  const renderRecommendedFaqTicketChat = React.useCallback(() => {
    if (!props.faq) {
      return <RecommendedFaqTicketUnselected />
    }

    return (
      <RecommendedFaqTicketChat
        title={props.faq.title}
        ticketId={props.faq.representativeTicketId}
      />
    )
  }, [props.faq])

  return (
    <RecommendedFaqTicketPresenter
      renderRecommendedFaqTicketChat={renderRecommendedFaqTicketChat}
    />
  )
}

export default React.memo(RecommendedFaqTicket)
