import { AttachmentItem } from '../../ActivityMessageAttachment/types/AttachmentItem'
import { ActivityMessagePlanStatus } from '../../ActivityMessagePlanList/types/ActivityMessagePlanEntity'

export type ActivityMessagePlanDetailEntity = {
  id: number
  creatorID: string
  body: string
  receiverType: ActivityMessagePlanReceiverType
  status: ActivityMessagePlanStatus
  createdAt: string
  sentAt: string | null
  receiverIDs: string[]
  succeedReceiverIDs: string[]
  failedReceiverIDs: string[]
  attachments: AttachmentItem[]
}

export enum ActivityMessagePlanReceiverType {
  All,
  ReceiverIDs,
}
