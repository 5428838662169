import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { Dropdown } from '../../../components/Dropdown'
import ticket_custom_field from '../../../proto/ticket_custom_field_pb'
import style from '../TabConfig.module.css'
import {
  isCustomSelectValueFilter,
  setCustomSelectFilter,
} from '../tabConfigSlice'

type Props = {
  customField: ticket_custom_field.TicketCustomField.AsObject
  tabConfigFilterIndex: number
}
const TicketCustomFieldValueSelectFilter: React.FC<Props> = ({
  customField,
  tabConfigFilterIndex,
}) => {
  const tabConfigState = useSelector((state: RootState) => state.tabConfig)
  const filter = tabConfigState.filters[tabConfigFilterIndex].filter
  const dispatch = useDispatch()

  return (
    <Dropdown
      fluid
      multiple
      className={style.filter}
      placeholder={'選択してください'}
      clearable={true}
      loadingMessage={'読込中'}
      value={isCustomSelectValueFilter(filter) ? filter.values : []}
      items={[
        ...customField.optionsList.map((ol) => ({
          value: ol.value,
          header: ol.value,
        })),
      ]}
      checkable
      onValueChange={(values) => {
        dispatch(
          setCustomSelectFilter({
            customFieldId: customField.id,
            tabConfigFilterIndex,
            values: values,
          })
        )
      }}
    />
  )
}

export default TicketCustomFieldValueSelectFilter
