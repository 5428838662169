import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import ActivityMessagePlanEditForm from '../../renewfeatures/ActivityMessagePlanEditForm'
import ActivityMessagePlanEditPresenter from './presenter'

const ActivityMessagePlanEdit: React.FC = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()

  const clickBackButton = React.useCallback(() => {
    history.push('/activity-messages')
  }, [history])

  React.useEffect(() => {
    const num = Number(params.id)
    if (isNaN(num)) {
      history.push('/activity-messages')
    }
  }, [params.id, history])

  return (
    <ActivityMessagePlanEditPresenter
      onClickBackButton={clickBackButton}
      renderForm={() => <ActivityMessagePlanEditForm id={Number(params.id)} />}
    />
  )
}

export default ActivityMessagePlanEdit
