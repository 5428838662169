import { grpc } from '@improbable-eng/grpc-web'
import {
  EntityState,
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk, RootState } from '../../app/store'
import notification_setting_pb, {
  NewTicketNotificationUser,
  NotificationTicketCustomField,
} from '../../proto/notification_setting_pb'
import notification_setting_pb_service from '../../proto/notification_setting_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'

interface NotificationSettingState {
  notificationUsers: EntityState<notification_setting_pb.NewTicketNotificationUser.AsObject>
  notificationTicketCustomFields: EntityState<notification_setting_pb.NotificationTicketCustomField.AsObject>
  loading: boolean
  error?: string
}

const notificationSettingAdapter =
  createEntityAdapter<notification_setting_pb.NewTicketNotificationUser.AsObject>(
    {
      selectId: (m) => m.userId || '',
      sortComparer: (a, b) => {
        return a.userId.localeCompare(b.userId)
      },
    }
  )

const notificationTicketCustomFieldSettingAdapter =
  createEntityAdapter<notification_setting_pb.NotificationTicketCustomField.AsObject>(
    {
      selectId: (m) => m.ticketCustomFieldId || '',
    }
  )

export const notificationSetttingSelector =
  notificationSettingAdapter.getSelectors(
    (rootState: RootState) => rootState.notificationSetting.notificationUsers
  )

export const notificationTicketCustomFieldSettingSelector =
  notificationTicketCustomFieldSettingAdapter.getSelectors(
    (rootState: RootState) =>
      rootState.notificationSetting.notificationTicketCustomFields
  )

const notificationSettingSlice = createSlice({
  name: 'notificationSetting',
  initialState:
    notificationSettingAdapter.getInitialState<NotificationSettingState>({
      loading: false,
      notificationUsers: notificationSettingAdapter.getInitialState(),
      notificationTicketCustomFields:
        notificationTicketCustomFieldSettingAdapter.getInitialState(),
    }),
  reducers: {
    fetchNotificationSettingOnStart: (state) => {
      state.loading = true
      delete state.error
    },
    fetchNotificationSettingOnMessage: (
      state,
      action: PayloadAction<{
        message: notification_setting_pb.ListNewTicketNotificationUsersResponse.AsObject
      }>
    ) => {
      notificationSettingAdapter.setAll(
        state.notificationUsers,
        action.payload.message.newTicketNotificationUsersList
      )
      notificationTicketCustomFieldSettingAdapter.setAll(
        state.notificationTicketCustomFields,
        action.payload.message.notificationTicketCustomFieldsList
      )
    },
    fetchNotificationSettingOnEnd: (
      state,
      action: PayloadAction<{ code: grpc.Code; message: string }>
    ) => {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        delete state.error
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
    updateNotificationSettingOnStart: (state) => {
      state.loading = true
      delete state.error
    },
    updateNotificationSettingOnEnd: (
      state,
      action: PayloadAction<{ code: grpc.Code; message: string }>
    ) => {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        delete state.error
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
  },
})

export const {
  fetchNotificationSettingOnStart,
  fetchNotificationSettingOnMessage,
  fetchNotificationSettingOnEnd,
  updateNotificationSettingOnStart,
  updateNotificationSettingOnEnd,
} = notificationSettingSlice.actions

export const fetchNewTicketNotificationUsers =
  (): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      notification_setting_pb.ListNewTicketNotificationUsersRequest,
      notification_setting_pb.ListNewTicketNotificationUsersResponse
    >(
      notification_setting_pb_service.NotificationSettingsAPI
        .ListNewTicketNotificationUsers
    )
    const req =
      new notification_setting_pb.ListNewTicketNotificationUsersRequest()

    client.onMessage((message) => {
      dispatch(
        fetchNotificationSettingOnMessage({ message: message.toObject() })
      )
    })

    client.onEnd((code, message) => {
      dispatch(fetchNotificationSettingOnEnd({ code, message }))
    })

    dispatch(fetchNotificationSettingOnStart())
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const updateNewTicketNotificationUsers =
  (userIds: string[], ticketCustomFieldIds: number[]): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      notification_setting_pb.UpdateNewTicketNotificationUsersRequest,
      notification_setting_pb.UpdateNewTicketNotificationUsersResponse
    >(
      notification_setting_pb_service.NotificationSettingsAPI
        .UpdateNewTicketNotificationUsers
    )
    const req =
      new notification_setting_pb.UpdateNewTicketNotificationUsersRequest()

    req.setNewTicketNotificationUsersList(
      userIds.map((id) => {
        const obj = new NewTicketNotificationUser()
        obj.setUserId(id)
        return obj
      })
    )

    req.setNotificationTicketCustomFieldsList(
      ticketCustomFieldIds.map((id) => {
        const obj = new NotificationTicketCustomField()
        obj.setTicketCustomFieldId(id)
        return obj
      })
    )

    client.onEnd((code, message) => {
      dispatch(updateNotificationSettingOnEnd({ code, message }))
    })

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export default notificationSettingSlice.reducer
