import { FormatIcon, SendIcon, Tooltip } from '@fluentui/react-northstar'
import * as React from 'react'

import FileInput from '../../../../components/FileInput'
import styles from './style.module.css'
import type { ExtensionEvent, ExtensionEventType } from './types/ExtensionEvent'

type Props = {
  formatActive: boolean
  fileUploadActive: boolean
  isLimited: boolean
  isEmptyText: boolean
  hasImageNodeOrAttachments: boolean
  onClick: (type: ExtensionEvent) => void
  className?: string
}
const ExtensionToolbarPlugin: React.FC<Props> = (props) => {
  const [hoveredExtensionIcon, setHoveredExtensionIcon] =
    React.useState<ExtensionEventType | null>(null)

  const clickExtension = (type: ExtensionEvent) => () => {
    return props.onClick(type)
  }
  const changeAttachment = (files: File[]) => {
    return props.onClick({ type: 'attachment', files })
  }

  const isOutline = (type: ExtensionEventType) =>
    hoveredExtensionIcon !== type && !props.formatActive

  return (
    <div className={`${styles.container} ${props.className}`}>
      <div className={styles.left}>
        <FormatIcon
          outline={isOutline('format')}
          onMouseEnter={() => setHoveredExtensionIcon('format')}
          onMouseLeave={() => setHoveredExtensionIcon(null)}
          onClick={clickExtension({ type: 'format' })}
          className={styles.extensionIcon + ' ' + styles.selected}
        />
        {props.fileUploadActive && (
          <FileInput
            outline={isOutline('attachment')}
            onMouseEnter={() => setHoveredExtensionIcon('attachment')}
            onMouseLeave={() => setHoveredExtensionIcon(null)}
            onChange={changeAttachment}
            className={styles.extensionIcon + ' ' + styles.selected}
          />
        )}
      </div>
      <div className={styles.right}>
        {props.isLimited ? (
          <Tooltip
            trigger={
              <SendIcon
                outline={isOutline('send')}
                onMouseEnter={() => setHoveredExtensionIcon('send')}
                onMouseLeave={() => setHoveredExtensionIcon(null)}
                onClick={clickExtension({ type: 'send' })}
                disabled
                className={styles.sendIcon}
              />
            }
            content="1000文字以内で入力してください"
          />
        ) : (
          <SendIcon
            outline={isOutline('send')}
            onMouseEnter={() => setHoveredExtensionIcon('send')}
            onMouseLeave={() => setHoveredExtensionIcon(null)}
            onClick={clickExtension({ type: 'send' })}
            className={styles.sendIcon}
            disabled={
              props.isLimited ||
              (props.isEmptyText && !props.hasImageNodeOrAttachments)
            }
          />
        )}
      </div>
    </div>
  )
}

export default ExtensionToolbarPlugin
