import { Input, Text } from '@fluentui/react-northstar'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { TicketCustomField } from '../../../proto/ticket_custom_field_pb'
import { isSafeNumber } from '../../../utils'
import { addAlert } from '../../alert/alertsSlice'
import {
  deleteTicketCustomFieldValue,
  setCustomFieldValueEntityError,
  updateTicketCustomFieldValue,
} from '../customFieldValueSlice'
import styles from '../TicketDetail.module.css'
import { useErrorHandler } from './useErrorHandler'

type Props = {
  ticketId: number
  field: TicketCustomField.AsObject
  fieldValue: string
}

const delayTime = 500

const TicketPropertyCustomNumberField: React.FC<Props> = ({
  ticketId,
  field,
  fieldValue,
}) => {
  const dispatch = useDispatch()
  const customFieldValuesState = useSelector(
    (state: RootState) => state.customFieldValue
  )
  const customFieldValueEntity = customFieldValuesState.entities[field.id]
  const hasValue = customFieldValueEntity != null
  const [inputNumber, setInputNumber] = useState(fieldValue)
  const isMountRef = useRef(true)

  useEffect(() => {
    if (isMountRef.current) {
      isMountRef.current = false
      return
    }
    if (!field.editable) return

    // UX向上のためのアラート
    const numAlert = () => {
      dispatch(
        addAlert({
          id: field.id,
          title: '数値フィールドの最大桁数を超えています',
          type: 'custom_field_value_error',
        })
      )
    }

    if (!hasValue) {
      const timeOutId = setTimeout(() => {
        if (inputNumber === '') {
          dispatch(deleteTicketCustomFieldValue(ticketId, field.id))
        } else {
          dispatch(
            updateTicketCustomFieldValue(ticketId, field.id, field.type, [
              {
                numberValue: Number(inputNumber),
              },
            ])
          )
        }
      }, delayTime)
      return () => clearTimeout(timeOutId)
    }

    if (isSafeNumber(Number(inputNumber))) {
      dispatch(
        setCustomFieldValueEntityError({
          ticketCustomFieldId: field.id,
          isError: false,
        })
      )
      const timeOutId = setTimeout(() => {
        if (inputNumber === '') {
          dispatch(deleteTicketCustomFieldValue(ticketId, field.id))
        } else {
          dispatch(
            updateTicketCustomFieldValue(ticketId, field.id, field.type, [
              {
                numberValue: Number(inputNumber),
              },
            ])
          )
        }
      }, delayTime)
      return () => clearTimeout(timeOutId)
    } else {
      dispatch(
        setCustomFieldValueEntityError({
          ticketCustomFieldId: field.id,
          isError: true,
        })
      )
      numAlert()
    }
  }, [
    dispatch,
    inputNumber,
    ticketId,
    field.id,
    field.type,
    field.editable,
    hasValue,
  ])

  useErrorHandler(customFieldValueEntity, field, dispatch, hasValue)
  return (
    <>
      <Text weight="bold" content={field.name} />
      {field.editable ? (
        <Input
          fluid
          error={hasValue && customFieldValuesState.entities[field.id]?.isError}
          className={styles.ticketCustomFieldNumberInput}
          value={String(inputNumber)}
          type="number"
          onChange={(_, e) => {
            if (e?.value != null) setInputNumber(e.value)
          }}
          onBlur={() => {
            // 00002などの数値を入力した時にBlur時に2に変換する
            if (inputNumber !== '') setInputNumber(String(Number(inputNumber)))
          }}
          placeholder={`${field.name}を入力`}
        />
      ) : (
        <Text content={inputNumber ? String(inputNumber) : '--'} />
      )}
    </>
  )
}

export default TicketPropertyCustomNumberField
