import { Checkbox, Flex, Text } from '@fluentui/react-northstar'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import ticket_custom_field from '../../../proto/ticket_custom_field_pb'
import {
  deleteOneCustomFieldFilter,
  setCustomCheckboxFieldValuesFilter,
} from '../ticketSlice'
import styles from './TicketCustomFieldFilter.module.css'

type Props = {
  customField: ticket_custom_field.TicketCustomField.AsObject
}

const TicketCustomFieldValueCheckboxFilter: React.FC<Props> = ({
  customField,
}) => {
  const ticketState = useSelector((state: RootState) => state.ticket)
  const isExistIdInCheckboxFilter =
    ticketState.filters.customFieldValues.checkBoxFilters[customField.id] !=
    null
  const checkBoxFilterValues = isExistIdInCheckboxFilter
    ? ticketState.filters.customFieldValues.checkBoxFilters[customField.id]
    : []

  const dispatch = useDispatch()
  return (
    <Flex column hAlign="start">
      <Text content="絞り込み" weight="bold" className={styles.headingText} />
      <Flex column hAlign="start">
        <Checkbox
          label="チェック済み"
          labelPosition="end"
          defaultChecked={false}
          className={styles.checkbox}
          checked={
            isExistIdInCheckboxFilter ? checkBoxFilterValues.includes(1) : false
          }
          onChange={(_, value) => {
            if (value?.checked) {
              dispatch(
                setCustomCheckboxFieldValuesFilter({
                  customFieldId: customField.id,
                  values: [...checkBoxFilterValues, 1],
                })
              )
            } else {
              checkBoxFilterValues.length === 1
                ? dispatch(
                    deleteOneCustomFieldFilter({
                      customFieldId: customField.id,
                      customFieldType: customField.type,
                    })
                  )
                : dispatch(
                    setCustomCheckboxFieldValuesFilter({
                      customFieldId: customField.id,
                      values: checkBoxFilterValues.filter((fv) => fv !== 1),
                    })
                  )
            }
          }}
        ></Checkbox>
        <Checkbox
          label="未チェック"
          labelPosition="end"
          defaultChecked={false}
          className={styles.checkbox}
          checked={
            isExistIdInCheckboxFilter ? checkBoxFilterValues.includes(0) : false
          }
          onChange={(_, value) => {
            if (value?.checked) {
              dispatch(
                setCustomCheckboxFieldValuesFilter({
                  customFieldId: customField.id,
                  values: [...checkBoxFilterValues, 0],
                })
              )
            } else {
              checkBoxFilterValues.length === 1
                ? dispatch(
                    deleteOneCustomFieldFilter({
                      customFieldId: customField.id,
                      customFieldType: customField.type,
                    })
                  )
                : dispatch(
                    setCustomCheckboxFieldValuesFilter({
                      customFieldId: customField.id,
                      values: checkBoxFilterValues.filter((fv) => fv !== 0),
                    })
                  )
            }
          }}
        ></Checkbox>
      </Flex>
    </Flex>
  )
}

export default TicketCustomFieldValueCheckboxFilter
