import group_pb from '../../proto/group_pb'
import group_pb_service from '../../proto/group_pb_service'
import { deskOperatorApi } from '../deskApiOperator'

export const groupApi = deskOperatorApi.injectEndpoints({
  endpoints: (build) => ({
    upsertGroup: build.query<void, group_pb.UpsertGroupRequest.AsObject>({
      query: () => ({
        service: group_pb_service.GroupAPI.UpsertGroup,
        body: (() => {
          return new group_pb.UpsertGroupRequest()
        })(),
      }),
    }),
  }),
})
