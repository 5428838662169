import * as React from 'react'

import CompletedTicketsChart from './features/CompletedTicketsChart'
import CreatedTicketsChart from './features/CreatedTicketsChart'
import DailyTicketsChart from './features/DailyTicketsChart'
import HourlyTicketsChart from './features/HourlyTicketsChart'
import WeekdayTicketsChart from './features/WeekdayTicketsChart'
import WorkingTicketsChart from './features/WorkingTicketsChart'
import DashboardChartsPresenter from './presenter'

const DashboardCharts: React.FC = () => {
  return (
    <DashboardChartsPresenter
      renderCreatedTicketsChart={() => <CreatedTicketsChart />}
      renderWorkingTicketsChart={() => <WorkingTicketsChart />}
      renderCompletedTicketsChart={() => <CompletedTicketsChart />}
      renderHoulyTicketsChart={() => <HourlyTicketsChart />}
      renderWeekdayTicketsChart={() => <WeekdayTicketsChart />}
      renderDailyTicketsChart={() => <DailyTicketsChart />}
    />
  )
}

export default DashboardCharts
