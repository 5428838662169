// package: 
// file: proto/channel.proto

var proto_channel_pb = require("../proto/channel_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ChannelAPI = (function () {
  function ChannelAPI() {}
  ChannelAPI.serviceName = "ChannelAPI";
  return ChannelAPI;
}());

ChannelAPI.GetChannels = {
  methodName: "GetChannels",
  service: ChannelAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_channel_pb.GetChannelsRequest,
  responseType: proto_channel_pb.GetChannelsResponse
};

exports.ChannelAPI = ChannelAPI;

function ChannelAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ChannelAPIClient.prototype.getChannels = function getChannels(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ChannelAPI.GetChannels, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ChannelAPIClient = ChannelAPIClient;

