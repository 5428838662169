import crypto from 'crypto'

import { grpc } from '@improbable-eng/grpc-web'
import * as graph from '@microsoft/microsoft-graph-client'
import * as microsoftTeams from '@microsoft/teams-js'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'

import { AppThunk } from '../../app/store'
import { appInsights } from '../../appInsights'
import auth_pb from '../../proto/auth_pb'
import auth_pb_service from '../../proto/auth_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'
import { upsertGroup } from './group'

export const requiredScope = [
  'https://graph.microsoft.com/User.Read',
  'https://graph.microsoft.com/User.ReadBasic.All',
  'https://graph.microsoft.com/ChannelMember.Read.All',
  'https://graph.microsoft.com/TeamsActivity.Send',
]

export interface DecodedSsoToken {
  aio?: string
  aud?: string
  azp?: string
  azpacr?: string
  exp?: number
  iat?: number
  iss?: string
  name?: string
  nbf?: number
  oid?: string
  oidv: string
  preferred_username?: string
  rh?: string
  scp?: string
  sub?: string
  tid?: string
  uti?: string
  ver?: string
}

export interface AuthState {
  context?: microsoftTeams.app.Context
  ssoToken?: string | null
  decodedSsoToken?: DecodedSsoToken
  error?: string | null
  consentRequired: boolean
  consentProvided: boolean
  graphAccessToken?: string
  graphRefreshToken?: string
  accessToken?: string
  availableFeatures?: auth_pb.AvailableFeatures.AsObject
  deskAvailableFeatures?: auth_pb.DeskAvailableFeatures.AsObject
  loading: boolean
  uploadFolder?: string
  needsInitialGuidance: boolean
}

const initialState: AuthState = {
  consentRequired: false,
  consentProvided: false,
  loading: false,
  needsInitialGuidance: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getContextCallback(
      state,
      action: PayloadAction<{ context: microsoftTeams.app.Context }>
    ) {
      state.context = action.payload.context
      console.info('context success')
    },
    getSsoTokenSuccess(state, action: PayloadAction<{ ssoToken: string }>) {
      const { ssoToken } = action.payload
      state.ssoToken = ssoToken
      state.decodedSsoToken = jwt_decode(ssoToken)
      console.info('sso token success')
    },
    getSsoTokenFailure(state, action: PayloadAction<{ reason: string }>) {
      const { reason } = action.payload
      state.error = reason
      console.warn(`sso token failure: ${reason}`)
    },
    exchangeTokenStart(state) {
      state.loading = true
      state.error = null
    },
    exchangeTokenOnMessage(
      state,
      action: PayloadAction<{
        message: auth_pb.ExchangeTokenResponse.AsObject
      }>
    ) {
      tokenOnMessage(state, action, 'exchange')
    },
    exchangeTokenOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      state.loading = false
      const { code, message } = action.payload
      if (code === grpc.Code.OK) return

      state.error = message
      consoleErrorWithAirbrake(`exchange token failure: ${message}`)
    },
    authenticateSuccess(state, action: PayloadAction<{ result?: string }>) {
      const { result } = action.payload
      state.graphAccessToken = result
      state.consentProvided = true
      console.info('authentication success')
    },
    authenticateFailure(state, action: PayloadAction<{ reason?: string }>) {
      const { reason } = action.payload
      state.error = reason
      console.warn(`authentication failure: ${reason}`)
    },
    resetTokens(state) {
      delete state.graphAccessToken
      delete state.accessToken
      state.consentRequired = true
      state.consentProvided = false
    },
    fetchUploadFolderSuccess(
      state,
      action: PayloadAction<{ folderName: string }>
    ) {
      state.uploadFolder = action.payload.folderName
    },
    setAppInsightsUser(state) {
      if (state.context?.user?.id != null)
        appInsights.context.user.id = state.context?.user?.id
    },
    refreshTokenOnMessage(
      state,
      action: PayloadAction<{
        message: auth_pb.RefreshTokenResponse.AsObject
      }>
    ) {
      tokenOnMessage(state, action, 'refresh')
    },
    refreshTokenOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      state.loading = false
      const { code, message } = action.payload
      if (code === grpc.Code.OK) return
      if (
        code === grpc.Code.Unknown &&
        message === 'Response closed without headers'
      ) {
        return
      }
      state.error = message
      consoleErrorWithAirbrake(`refresh token failure: ${message}`)
    },
    initialGuidance(state) {
      state.needsInitialGuidance = true
    },
    /*
      TODO: 他の認証部分のリファクタは範囲が大きすぎるので
      一時策としてtokenOnEndpointをrenewfeature用に実装しています
    */
    setRefreshToken(
      state,
      action: PayloadAction<{ message: auth_pb.ExchangeTokenResponse.AsObject }>
    ) {
      const { message } = action.payload
      if (message.graphError != null) {
        const err = message.graphError.error
        switch (err) {
          case 'invalid_grant':
          case 'interaction_required':
            state.consentRequired = true
            console.info(`refresh token consent required`)
            break
          default:
            state.error = err
            consoleErrorWithAirbrake(`refresh token error: ${err}`)
        }
        return
      }

      const scope = message.graphToken?.scope
      const splitScope = scope?.split(' ')
      if (!requiredScope.every((s) => splitScope?.includes(s))) {
        state.consentRequired = true
        console.info(`refresh token missing scope, acquired scope: ${scope}`)
        return
      }

      // success
      state.graphAccessToken = message.graphToken?.accessToken
      state.accessToken = message.accessToken
      state.availableFeatures = message.availableFeatures
      state.deskAvailableFeatures = message.deskAvailableFeatures
      state.graphRefreshToken = message.graphToken?.refreshToken
      console.info(
        `refresh token success, acquired scope: ${scope} expiresIn: ${message.graphToken?.expiresIn}`
      )
    },
  },
})

const tokenOnMessage = (
  state: AuthState,
  action: PayloadAction<{
    message:
      | auth_pb.ExchangeTokenResponse.AsObject
      | auth_pb.RefreshTokenResponse.AsObject
  }>,
  type: 'refresh' | 'exchange'
) => {
  const { message } = action.payload
  if (message.graphError != null) {
    const err = message.graphError.error
    switch (err) {
      case 'invalid_grant':
      case 'interaction_required':
        state.consentRequired = true
        console.info(`${type} token consent required`)
        break
      default:
        state.error = err
        consoleErrorWithAirbrake(`${type} token error: ${err}`)
    }
    return
  }

  const scope = message.graphToken?.scope
  const splitScope = scope?.split(' ')
  if (!requiredScope.every((s) => splitScope?.includes(s))) {
    state.consentRequired = true
    console.info(`${type} token missing scope, acquired scope: ${scope}`)
    return
  }

  // success
  state.graphAccessToken = message.graphToken?.accessToken
  state.accessToken = message.accessToken
  state.availableFeatures = message.availableFeatures
  state.deskAvailableFeatures = message.deskAvailableFeatures
  state.graphRefreshToken = message.graphToken?.refreshToken
  console.info(
    `${type} token success, acquired scope: ${scope} expiresIn: ${message.graphToken?.expiresIn}`
  )
}
export const {
  getContextCallback,
  getSsoTokenSuccess,
  getSsoTokenFailure,
  exchangeTokenStart,
  exchangeTokenOnMessage,
  exchangeTokenOnEnd,
  authenticateSuccess,
  authenticateFailure,
  resetTokens,
  fetchUploadFolderSuccess,
  setAppInsightsUser,
  refreshTokenOnMessage,
  refreshTokenOnEnd,
  initialGuidance,
} = authSlice.actions
export const AuthActions = authSlice.actions
export default authSlice.reducer

export const getContext = (): AppThunk => async (dispatch) => {
  try {
    const res = await microsoftTeams.app.getContext()
    dispatch(getContextCallback({ context: res }))
  } catch (e) {
    console.warn(`getContext failed: ${e}`)
  }
}

export const getSsoToken = (): AppThunk => async (dispatch) => {
  try {
    const res = await microsoftTeams.authentication.getAuthToken()
    dispatch(getSsoTokenSuccess({ ssoToken: res }))
  } catch (e) {
    console.warn(`getAuthToken failed: ${e}`)
    dispatch(getSsoTokenFailure({ reason: 'not success' }))
  }
}

export const exchangeToken =
  (ssoToken: string): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    dispatch(exchangeTokenStart())
    const client = grpcClient<
      auth_pb.ExchangeTokenRequest,
      auth_pb.ExchangeTokenResponse
    >(auth_pb_service.AuthAPI.ExchangeToken)
    const context = getState().auth.context
    if (context == null) {
      return
    }

    const { team, channel } = context

    if (team == null) {
      return
    }

    if (team.groupId == null) {
      consoleErrorWithAirbrake(`groupId is undefined, ${JSON.stringify(team)}`)
      return
    }

    if (channel == null || channel.id == null) {
      return
    }
    const groupId = team.groupId
    const channelId = channel.id
    const req = new auth_pb.ExchangeTokenRequest()
    req.setSsoToken(ssoToken)
    req.setGraphScope(requiredScope.join(' '))
    req.setChannelId(channelId)
    req.setGroupId(groupId)
    client.start()
    client.onMessage((message) => {
      dispatch(exchangeTokenOnMessage({ message: message.toObject() }))
      // feat: チャネル移動。ユーザー委任権限の設定とGroup.Read.Allがあればチーム情報とチャネル情報を更新するリクエストを非同期で行う。
      if (message.toObject().availableFeatures?.delegateMultiChannel) {
        dispatch(upsertGroup())
      }
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      } else if (code === grpc.Code.PermissionDenied) {
        dispatch(initialGuidance())
        return
      }
      dispatch(exchangeTokenOnEnd({ code, message }))
    })
    client.send(req)
    client.finishSend()
  }

export const refreshToken =
  (): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      auth_pb.RefreshTokenRequest,
      auth_pb.RefreshTokenResponse
    >(auth_pb_service.AuthAPI.RefreshToken)
    const req = new auth_pb.RefreshTokenRequest()

    const { graphRefreshToken, ssoToken } = getState().auth
    if (graphRefreshToken == null) {
      return
    }
    if (ssoToken == null) {
      return
    }

    req.setSsoToken(ssoToken)
    req.setGraphScope(requiredScope.join(' '))
    req.setRefreshToken(graphRefreshToken)

    const context = getState().auth.context
    if (context == null) {
      return
    }

    const { team, channel } = context
    if (team == null) {
      return
    }

    if (team.groupId == null) {
      consoleErrorWithAirbrake(`groupId is undefined, ${JSON.stringify(team)}`)
      return
    }

    if (channel == null || channel.id == null) {
      return
    }

    const groupId = team.groupId
    const channelId = channel.id
    req.setChannelId(channelId)
    req.setGroupId(groupId)
    req.setRedirectUrl(`${window.location.origin}/auth/close`)

    client.start()
    client.onMessage((message) => {
      dispatch(refreshTokenOnMessage({ message: message.toObject() }))
      const graphAccessToken = message.toObject().graphToken?.accessToken
      if (graphAccessToken != null) {
        dispatch(setupGraphAPI(graphAccessToken))
      }
    })
    client.onEnd((code, message) => {
      console.log('refresh token on end')
      dispatch(refreshTokenOnEnd({ code, message }))
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
      }
    })

    client.send(req)
    client.finishSend()
  }

export const authenticate = (): AppThunk => async (dispatch, getState) => {
  try {
    const res = await microsoftTeams.authentication.authenticate({
      url: window.location.origin + '/auth/consent',
      width: 600,
      height: 535,
    })

    dispatch(authenticateSuccess({ result: res }))

    const ssoToken = getState().auth.ssoToken
    if (ssoToken) {
      dispatch(exchangeToken(ssoToken))
    }
  } catch (e) {
    dispatch(authenticateFailure({ reason: 'authenticateFailure' }))
  }
}

export const consent =
  (clientId: string, tenantId: string): AppThunk =>
  async () => {
    //Form a query for the Azure implicit grant authorization flow
    //https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-implicit-grant-flow
    const queryParams = new URLSearchParams({
      tenant: tenantId,
      client_id: clientId,
      response_type: 'token', //token_id in other samples is only needed if using open ID
      scope: requiredScope.join(' '),
      redirect_uri: `${window.location.origin}/auth/close`,
      nonce: crypto.randomBytes(16).toString('base64'),
    }).toString()
    const authEndpoint = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?${queryParams}`
    window.location.assign(authEndpoint)
  }

export const consentClose = (): AppThunk => async () => {
  //The Azure implicit grant flow injects the result into the window.location.hash object. Parse it to find the results.
  const hashParams: { [key: string]: string } = {}
  window.location.hash
    .substr(1)
    .split('&')
    .forEach(function (item: string) {
      const [key, value] = item.split('=')
      hashParams[key] = decodeURIComponent(value)
    })

  //If consent has been successfully granted, the Graph access token should be present as a field in the dictionary.
  if (hashParams['access_token']) {
    //Notify the showConsentDialogue function in Tab.js that authorization succeeded. The success callback should fire.
    microsoftTeams.authentication.notifySuccess(hashParams['access_token'])
  } else {
    microsoftTeams.authentication.notifyFailure('Consent failed')
  }
}

export const setupGraphAPI =
  (accessToken: string): AppThunk =>
  (_, __, services) => {
    services.graphAPI = graph.Client.init({
      authProvider: (done) => {
        done(null, accessToken)
      },
    })
  }

/*
https://docs.microsoft.com/ja-jp/graph/api/channel-get-filesfolder?view=graph-rest-1.0&tabs=http
*/
export const fetchUploadFolderName =
  (groupId: string, channelId: string): AppThunk =>
  async (dispatch, getState, { graphAPI }) => {
    try {
      const filesFolderRequest = graphAPI.api(
        `/teams/${groupId}/channels/${channelId}/filesFolder`
      )
      const res = await filesFolderRequest.get()
      if (res.name == null) {
        consoleErrorWithAirbrake(
          `failed at fetchUploadFolderName, groupId:${groupId}, channelId:${channelId}, response: ${JSON.stringify(
            res
          )}, context: ${JSON.stringify(getState().auth.context)}`
        )
        return
      }
      dispatch(fetchUploadFolderSuccess({ folderName: res.name }))
    } catch (e) {
      console.warn(
        `failed at fetchUploadFolderName,error: ${JSON.stringify(
          e
        )} groupId:${groupId}, channelId:${channelId}, context: ${JSON.stringify(
          getState().auth.context
        )}`,
        e
      )
    }
  }
