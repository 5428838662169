import { RefreshIcon } from '@fluentui/react-icons-mdl2'
import { Button, Dialog, Text } from '@fluentui/react-northstar'
import * as React from 'react'

import ActivityMessagePlanStatus, {
  ActivityMessagePlanStatusComponentType,
} from '../../components/ActivityMessagePlanStatus'
import { assertNever } from '../../utils'
import styles from './styles.module.css'

type Props = {
  status: ActivityMessagePlanStatusComponentType
  receiverType: 'all' | 'receiverIDs'
  receiverIDs: string[]
  succeedReceiverCount: number
  failedReceiverCount: number
  isShowResult: boolean
  isPublishLoading?: boolean
  isRemoveLoading?: boolean
  onClickFailedReceiverCheckButton: () => void
  onClickSucceedReceiverCheckButton: () => void
  onClickMemberCheckButton: () => void
  onClickSendButton: () => void
  onClickRefreshButton: () => void
  onClickRemoveButton: () => void
}
const ActivityMessagePlanPreviewSidebarPresenter: React.FC<Props> = (props) => {
  const receiverTypeText = React.useMemo(() => {
    switch (props.receiverType) {
      case 'all':
        return (
          <div>
            全ユーザー
            <br />
            (アプリをインストール済が対象)
          </div>
        )
      case 'receiverIDs':
        return '特定のユーザー'
      default:
        assertNever(props.receiverType)
        return (
          <div>
            全ユーザー
            <br />
            (アプリをインストール済が対象)
          </div>
        )
    }
  }, [props.receiverType])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.inner}>
          <div className={styles.detailTitleRow}>
            <Text weight="bold" content="配信概要" />
            <Button
              onClick={props.onClickRefreshButton}
              primary
              icon={<RefreshIcon />}
              text
              content="更新"
            />
          </div>
          <table className={styles.listTable}>
            <tbody>
              <tr>
                <td>ステータス</td>
                <td>
                  <ActivityMessagePlanStatus status={props.status} />
                </td>
              </tr>
              <tr>
                <td>配信時間</td>
                <td>即時配信</td>
              </tr>
              {props.receiverType === 'receiverIDs' && (
                <tr>
                  <td>配信数</td>
                  <td>{props.receiverIDs.length}名</td>
                </tr>
              )}
              <tr>
                <td className={styles.notificationDestinationText}>配信先</td>
                <td className={styles.notificationDestinationText}>
                  {receiverTypeText}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.bottom}>
          {props.isShowResult ? (
            <>
              <Text weight="bold" content="配信結果" />
              <table className={styles.listTable}>
                <tbody>
                  <tr>
                    <td>総配信数</td>
                    <td>{receiverCountOrDash(props.receiverIDs.length)}</td>
                  </tr>
                  <tr>
                    <td>配信到達数</td>
                    <td>
                      {receiverCountOrDash(props.succeedReceiverCount)}
                      {props.succeedReceiverCount > 0 && (
                        <span
                          onClick={props.onClickSucceedReceiverCheckButton}
                          className={styles.memberCheckText}
                        >
                          (確認)
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>配信失敗数</td>
                    <td>
                      {receiverCountOrDash(props.failedReceiverCount)}
                      {props.failedReceiverCount > 0 && (
                        <span
                          onClick={props.onClickFailedReceiverCheckButton}
                          className={styles.memberCheckText}
                        >
                          (確認)
                        </span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <div className={styles.bottomButtonGroup}>
              <Dialog
                header="お知らせを削除します"
                content="お知らせを削除すると元に戻すことが出来ません。"
                confirmButton={{
                  content: '削除する',
                  primary: true,
                  styles: {
                    backgroundColor: '#C4314B',
                    ':hover': { backgroundColor: '#C4314B' },
                  },
                }}
                cancelButton="キャンセル"
                onConfirm={props.onClickRemoveButton}
                trigger={
                  <Button
                    loading={props.isRemoveLoading}
                    style={{ color: '#cc4a31' }}
                    flat
                    text
                    content="下書きを削除"
                  />
                }
              />
              <Dialog
                cancelButton="キャンセル"
                confirmButton="配信を実施"
                header="お知らせの配信を行います"
                content="配信を行った後は中止を行うことが出来ませんので、配信内容を今一度確認ください。"
                onConfirm={props.onClickSendButton}
                trigger={
                  <Button
                    loading={props.isPublishLoading}
                    primary
                    flat
                    content="配信を行う"
                  />
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.outter}>
        ※
        ユーザーに外部の社員などのゲストユーザーが含まれる場合も、送信対象に含まれますのでご注意ください。
      </div>
    </>
  )
}

function receiverCountOrDash(count: number): string {
  return count > 0 ? `${count}名` : '-'
}

export default React.memo(ActivityMessagePlanPreviewSidebarPresenter)
