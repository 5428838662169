// package: 
// file: proto/requester_message.proto

var proto_requester_message_pb = require("../proto/requester_message_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var RequesterMessageAPI = (function () {
  function RequesterMessageAPI() {}
  RequesterMessageAPI.serviceName = "RequesterMessageAPI";
  return RequesterMessageAPI;
}());

RequesterMessageAPI.CreateRequesterMessage = {
  methodName: "CreateRequesterMessage",
  service: RequesterMessageAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_requester_message_pb.CreateRequesterMessageRequest,
  responseType: proto_requester_message_pb.RequesterMessage
};

RequesterMessageAPI.ListRequesterConversations = {
  methodName: "ListRequesterConversations",
  service: RequesterMessageAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_requester_message_pb.ListRequesterConversationsRequest,
  responseType: proto_requester_message_pb.ListRequesterConversationsResponse
};

RequesterMessageAPI.ListRequesterMessages = {
  methodName: "ListRequesterMessages",
  service: RequesterMessageAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_requester_message_pb.ListRequesterMessagesRequest,
  responseType: proto_requester_message_pb.ListRequesterMessagesResponse
};

RequesterMessageAPI.CreateWelcomeMessage = {
  methodName: "CreateWelcomeMessage",
  service: RequesterMessageAPI,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: proto_requester_message_pb.WelcomeMessageResponse
};

exports.RequesterMessageAPI = RequesterMessageAPI;

function RequesterMessageAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

RequesterMessageAPIClient.prototype.createRequesterMessage = function createRequesterMessage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RequesterMessageAPI.CreateRequesterMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RequesterMessageAPIClient.prototype.listRequesterConversations = function listRequesterConversations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RequesterMessageAPI.ListRequesterConversations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RequesterMessageAPIClient.prototype.listRequesterMessages = function listRequesterMessages(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RequesterMessageAPI.ListRequesterMessages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RequesterMessageAPIClient.prototype.createWelcomeMessage = function createWelcomeMessage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RequesterMessageAPI.CreateWelcomeMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.RequesterMessageAPIClient = RequesterMessageAPIClient;

