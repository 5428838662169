import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface EditorToolState {
  linkMenuOpen: boolean
  trashOpen: boolean
}

const initState: EditorToolState = {
  linkMenuOpen: false,
  trashOpen: false,
}

export const editorToolStateSlice = createSlice({
  name: 'editorStateSlice',
  initialState: initState,
  reducers: {
    setLinkMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.linkMenuOpen = action.payload
    },
    setTrashOpen: (state, action: PayloadAction<boolean>) => {
      state.trashOpen = action.payload
    },
  },
})

export const { setLinkMenuOpen, setTrashOpen } = editorToolStateSlice.actions
export default editorToolStateSlice.reducer
