/**
 *  README https://pkshatech.atlassian.net/wiki/spaces/WOR/pages/13398638615/RequesterTicketList
 */
import { Loader } from '@fluentui/react-northstar'
import * as microsoftTeams from '@microsoft/teams-js'
import { EntityId } from '@reduxjs/toolkit'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import { useCreateWelcomeMessageMutation } from './api'
import RequesterTicketItem from './features/RequesterTicketItem'
import RequesterTicketReplyItem from './features/RequesterTicketReplyItem'
import RequesterTicketReplyList from './features/RequesterTicketReplyList'
import { useRequesterConversations } from './hooks/useRequesterConversations'
import RequesterTicketListPresenter from './presenter'
import * as Selectors from './selectors'
import { triggerScrollToBottom } from './selectors'
import { actions } from './slice'
import { requesterTicketListsInCurrentViewport } from './slice'

type Props = {
  otherElementHeight: number
}
const RequesterTicketList: React.FC<Props> = (props) => {
  const dispatch = ReactRedux.useDispatch()
  const [createWelcomeMessage] = useCreateWelcomeMessageMutation()

  const requesterConversationsQuery = useRequesterConversations()
  const ticketDisplays = ReactRedux.useSelector(
    Selectors.ticketOrderDependsOnViewportDisplays
  )
  const intersectionObserver = React.useRef<IntersectionObserver>()

  const triggerScroll = ReactRedux.useSelector(triggerScrollToBottom)

  // welcome messageを一度だけ送信するためのフラグ
  const isMountRef = React.useRef(false)
  React.useEffect(() => {
    if (isMountRef.current) {
      return
    }
    createWelcomeMessage()
    isMountRef.current = true
  }, [createWelcomeMessage])

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        for (const entry of entries) {
          if (entry.intersectionRatio === 0) {
            requesterTicketListsInCurrentViewport.delete(entry.target.id)
          } else {
            requesterTicketListsInCurrentViewport.set(entry.target.id, true)
          }
        }
      },
      { threshold: [0, 0.25, 0.5, 0.75, 1] }
    )

    intersectionObserver.current = observer
    return () => {
      observer.disconnect()
    }
  }, [dispatch])

  React.useEffect(() => {
    microsoftTeams.app.getContext().then((context) => {
      if (!context.page.subPageId) {
        return
      }
      const splited = context.page.subPageId.split('/')

      dispatch(
        actions.setLinkedMessageID({
          conversationID: splited[0],
          replyMessageID: splited[1],
        })
      )
    })
  }, [dispatch])

  const renderTicketReplyItem = React.useCallback(
    (replyId: EntityId, ticketId: string) => (
      <RequesterTicketReplyItem
        key={replyId}
        id={replyId}
        ticketId={ticketId}
      />
    ),
    []
  )

  const renderTicketReplyList = React.useCallback(
    (ticketId: string) => {
      return (
        <RequesterTicketReplyList
          ticketId={ticketId}
          renderTicketReplyItem={renderTicketReplyItem}
        />
      )
    },
    [renderTicketReplyItem]
  )

  const renderTicketItem = React.useCallback(
    (id: string) => (
      <RequesterTicketItem
        intersectionObserver={intersectionObserver}
        id={id}
        renderTicketReplyList={renderTicketReplyList}
      />
    ),
    [renderTicketReplyList, intersectionObserver]
  )

  const linkedConversationID = ReactRedux.useSelector(
    Selectors.linkedConversationID
  )

  React.useEffect(() => {
    // リンクから飛ばれた時には、最初に必ず返信欄を表示する
    if (!linkedConversationID) {
      return
    }

    dispatch(
      actions.setTicketRepliesOpen({
        conversationID: linkedConversationID,
        open: true,
      })
    )
  }, [dispatch, linkedConversationID])

  if (
    requesterConversationsQuery.isUninitialized ||
    requesterConversationsQuery.isLoading
  )
    return <Loader />
  return (
    <RequesterTicketListPresenter
      listItems={ticketDisplays}
      linkedConversationID={linkedConversationID}
      renderTicketItem={renderTicketItem}
      triggerScroll={triggerScroll}
      otherElementHeight={props.otherElementHeight}
    />
  )
}

export default React.memo(RequesterTicketList)
