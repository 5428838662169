// package: 
// file: proto/csv.proto

var proto_csv_pb = require("../proto/csv_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var CSVAPI = (function () {
  function CSVAPI() {}
  CSVAPI.serviceName = "CSVAPI";
  return CSVAPI;
}());

CSVAPI.CreateCSV = {
  methodName: "CreateCSV",
  service: CSVAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_csv_pb.CreateCSVRequest,
  responseType: proto_csv_pb.CSV
};

CSVAPI.CreateTenantAppCSV = {
  methodName: "CreateTenantAppCSV",
  service: CSVAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_csv_pb.CreateTenantAppCSVRequest,
  responseType: proto_csv_pb.CreateTenantAppCSVResponse
};

CSVAPI.ListCSVs = {
  methodName: "ListCSVs",
  service: CSVAPI,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: proto_csv_pb.ListCSVsResponse
};

CSVAPI.ListTenantAppCSVExportLogs = {
  methodName: "ListTenantAppCSVExportLogs",
  service: CSVAPI,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: proto_csv_pb.ListTenantAppCSVExportLogsResponse
};

exports.CSVAPI = CSVAPI;

function CSVAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

CSVAPIClient.prototype.createCSV = function createCSV(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CSVAPI.CreateCSV, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CSVAPIClient.prototype.createTenantAppCSV = function createTenantAppCSV(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CSVAPI.CreateTenantAppCSV, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CSVAPIClient.prototype.listCSVs = function listCSVs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CSVAPI.ListCSVs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CSVAPIClient.prototype.listTenantAppCSVExportLogs = function listTenantAppCSVExportLogs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CSVAPI.ListTenantAppCSVExportLogs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.CSVAPIClient = CSVAPIClient;

