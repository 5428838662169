import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface Layout {
  chatLayout?: ChatLayout
}

interface ChatLayout {
  clientHeight: number
  clientWidth: number
}

const initChatLayout: ChatLayout = {
  clientHeight: 0,
  clientWidth: 0,
}

const initLayout: Layout = {
  chatLayout: initChatLayout,
}

export const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState: initLayout,
  reducers: {
    onChatLayoutFetched: (
      state,
      action: PayloadAction<{ h: number; w: number }>
    ) => {
      state.chatLayout = {
        clientHeight: action.payload.h,
        clientWidth: action.payload.w,
      }
    },
  },
})

export const { onChatLayoutFetched } = layoutSlice.actions

export default layoutSlice.reducer
