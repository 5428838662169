import { CustomFieldValue } from '../../../consts'

export const handleTicketCustomFieldValueError = (message: string): string => {
  return errorMessageMatcher(message)
}

const errorMessageMatcher = (serverMessage: string): string => {
  switch (serverMessage) {
    case CustomFieldValue.ServerErrorMessage.ValidOption: {
      return CustomFieldValue.ClientErrorMessage.ValidOption
    }
    case CustomFieldValue.ServerErrorMessage.ValidCheckbox: {
      return CustomFieldValue.ClientErrorMessage.ValidCheckbox
    }
    case CustomFieldValue.ServerErrorMessage.ValidText: {
      return CustomFieldValue.ClientErrorMessage.ValidText
    }
    case CustomFieldValue.ServerErrorMessage.ValidNumber: {
      return CustomFieldValue.ClientErrorMessage.ValidNumber
    }
    case CustomFieldValue.ServerErrorMessage.ValidDate: {
      return CustomFieldValue.ClientErrorMessage.ValidDate
    }
    default: {
      return CustomFieldValue.ClientErrorMessage.Default
    }
  }
}
