import { Text as FluentText } from '@fluentui/react-northstar'
import { BarChart, Card } from '@tremor/react'
import * as React from 'react'

export type ChartRow = {
  hour: `${number}時`
  ['比率']: number
}

type Props = {
  data: ChartRow[]
}
const HoulyTicketsChartPresenter: React.FC<Props> = (props) => {
  return (
    <Card>
      <FluentText size="small" weight="bold">
        時間別の問い合わせ比率
      </FluentText>
      <BarChart
        data={props.data}
        dataKey="hour"
        categories={['比率']}
        valueFormatter={(value) => `${value}%`}
      />
    </Card>
  )
}

export default React.memo(HoulyTicketsChartPresenter)
