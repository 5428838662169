import { Col, ColGrid } from '@tremor/react'
import * as React from 'react'

import style from './style.module.css'

type Props = {
  renderCreatedTicketsChart: () => React.ReactElement
  renderWorkingTicketsChart: () => React.ReactElement
  renderCompletedTicketsChart: () => React.ReactElement
  renderHoulyTicketsChart: () => React.ReactElement
  renderWeekdayTicketsChart: () => React.ReactElement
  renderDailyTicketsChart: () => React.ReactElement
}
const DashboardChartsPresenter: React.FC<Props> = (props) => {
  return (
    <div className={style.container}>
      <ColGrid numCols={3} gapX="gap-x-4" marginTop="mt-4">
        {props.renderCreatedTicketsChart()}
        {props.renderWorkingTicketsChart()}
        {props.renderCompletedTicketsChart()}
      </ColGrid>
      <ColGrid numCols={3} gapX="gap-x-4" marginTop="mt-4">
        <Col numColSpan={2}>{props.renderHoulyTicketsChart()}</Col>
        {props.renderWeekdayTicketsChart()}
      </ColGrid>
      <ColGrid numCols={1} gapX="gap-x-4" marginTop="mt-4">
        {props.renderDailyTicketsChart()}
      </ColGrid>
    </div>
  )
}

export default DashboardChartsPresenter
