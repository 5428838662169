import * as Graph from '@microsoft/microsoft-graph-types'
import * as fns from 'date-fns'
import addMilliseconds from 'date-fns/fp/addMilliseconds'
import fromUnixTime from 'date-fns/fp/fromUnixTime'
import jaLocale from 'date-fns/locale/ja'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { useEffect, useState } from 'react'
import { debounce } from 'throttle-debounce'

import airbrake from './airbrake'
import { Tickets } from './consts'

export const ellipsis = (
  text: string | null | undefined,
  length: number
): string => {
  if (text == null) return ''
  let b = 0
  for (let i = 0; i < text.length; i++) {
    b += text.charCodeAt(i) <= 255 ? 1 : 2
    if (b > length) {
      return text.substr(0, i) + '...'
    }
  }
  return text
}

export const format = (
  timestamp: Timestamp.AsObject | null | undefined,
  format: string,
  placeholder?: string
): string => {
  if (timestamp == null) return placeholder ?? ''
  return fns.format(fns.fromUnixTime(timestamp.seconds), format)
}

export const formatAbsolute = (
  timestamp: Timestamp.AsObject | null | undefined,
  placeholder?: string
): string => {
  if (timestamp == null) return placeholder ?? ''
  const date = fns.fromUnixTime(timestamp.seconds)
  return fns.format(date, 'yyyy/MM/dd HH:mm')
}

export const formatShort = (timestamp: number): string => {
  const date = fns.fromUnixTime(timestamp)
  if (fns.isToday(date)) return fns.format(date, 'HH:mm')
  if (fns.isYesterday(date)) return fns.format(date, '昨日 HH:mm')
  if (fns.isThisWeek(date)) {
    return fns.format(date, 'iiii HH:mm', {
      locale: jaLocale,
    })
  }
  if (fns.isThisYear(date)) return fns.format(date, 'MM/dd HH:mm')
  return fns.format(date, 'yyyy/MM/dd HH:mm')
}

export const isGreaterEqThan = (
  t0: Timestamp.AsObject,
  t1: Timestamp.AsObject
): boolean => {
  return fns.fromUnixTime(t0.seconds) >= fns.fromUnixTime(t1.seconds)
}

export const convertCamelToSnake = (text: string): string =>
  text.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const unique = <T>(array: T[]): T[] => {
  return Array.from(new Set<T>(array))
}

export const consoleErrorWithAirbrake = <T>(
  message?: T,
  ...optionalParams: any[]
): void => {
  console.error(message, optionalParams)
  airbrake.notify(message)
}

export const createFieldMaskPathsList = (
  obj: Record<string, unknown>
): string[] => {
  const pathsList: string[] = []
  createPathsList(pathsList, obj)
  return pathsList.map(convertCamelToSnake)
}

const createPathsList = (
  pathsList: string[],
  // TODO: 再帰的な適切な型をつける
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any,
  parentPath?: string
) => {
  const keys = Object.keys(obj)
  for (const key of keys) {
    const path = parentPath == null ? key : `${parentPath}.${key}`
    const value = obj[key]
    if (isDictHasKey(value)) {
      createPathsList(pathsList, value, path)
    } else pathsList.push(path)
  }
}

const isDictHasKey = (obj: Record<string, unknown>) =>
  Object.keys(obj).length > 0 && obj.constructor === Object

// 小数点を含む場合
// JSは整数と小数部合わせて16桁(JS最大精度)以内
// && 整数部が12桁(DBの最大精度)以内
// && 小数部が6桁(DBの最大精度)以内、であればOK。
export const isSafeNumber = (num: number): boolean => {
  const splitNum = String(num).split('.')
  // TODO: testする
  if (splitNum.length === 2) {
    const numLength = splitNum[0].length
    const decimalLength = splitNum[1].length
    return (
      numLength + decimalLength <= 16 && numLength <= 12 && decimalLength <= 6
    )
  }
  // 整数のみの場合は12桁以内であれば安全
  return splitNum[0].length <= 12
}

export const getDisplayName = (user: Graph.User | undefined): string => {
  return user != null ? user?.displayName || '' : Tickets.Users.UserNotExists
}

export const getWebagentDisplayName = (requesterUserId: string): string => {
  const requesterUserIds = requesterUserId.split('::')
  if (requesterUserIds.length === 3) {
    return requesterUserIds[2]
  }
  return requesterUserId
}

export const getChannelDisplayName = (ch?: Graph.Channel): string => {
  return ch?.displayName === 'General' ? '一般' : ch?.displayName || ''
}

export const copyToClipboard = (text: string): void => {
  const elem = document.createElement('textarea')
  elem.value = text
  document.body.appendChild(elem)
  elem.select()
  document.execCommand('copy')
  document.body.removeChild(elem)
}

// 2022/01/01のフォーマットを出力するため
export const customFormatter: (date: Date) => string = (date) => {
  const day = date.getDate()
  let strDay = day.toString()
  if (day < 10) {
    strDay = `0${strDay}`
  }
  const month = date.getMonth() + 1
  let strMonth = month.toString()
  if (month < 10) {
    strMonth = `0${strMonth}`
  }
  const year = date.getFullYear()
  return `${year}/${strMonth}/${strDay}`
}

// dateの入力として日付が入ってない状態はundefined
export const displayDate = (isoString?: string): Date | undefined => {
  if (isoString === undefined) return undefined
  return new Date(isoString)
}

export const getQueryParamsByKey = (
  url: string,
  queryKeyName: string
): string[] => {
  const params = new URLSearchParams(url.split('?')[1])
  return params.getAll(queryKeyName)
}

const createPadNumItems = (max: number) => {
  const items = []
  for (let i = 0; i <= max; i++) {
    const padNum = String(i).padStart(2, '0')
    const item = { header: padNum, value: padNum }
    items.push(item)
  }
  return items
}

export const hourItems = createPadNumItems(23)
export const minuteItems = createPadNumItems(59)

export const stringToUint8Array = (str: string): Uint8Array => {
  const buf = new ArrayBuffer(str.length)
  const bufView = new Uint8Array(buf)
  for (let i = 0; i < str.length; i++) {
    bufView[i] = str.charCodeAt(i)
  }
  return bufView
}

// TODO: 使用箇所がなさそうなので、確認のうえ削除
/**
 * スマートフォン用のUIを表示するかどうかを返却します
 */
export const useIsSP = (): boolean => {
  const [isSP, setIsSP] = useState(false)

  // リサイズ時に実行
  useEffect(() => {
    const onResize = debounce(300, () => {
      // 425px 以内の画面幅の時は SP と判定
      setIsSP(window.innerWidth < 425)
    })
    // 初回実行
    onResize()

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return isSP
}

export const dateFromTimestamp = (timestamp: Timestamp.AsObject) =>
  addMilliseconds(timestamp.nanos / 10 ** 6)(fromUnixTime(timestamp.seconds))

export function assertNever(value: never): never {
  return value
}

export function splitArray<T>(array: T[], size: number): T[][] {
  return Array.from(Array(Math.ceil(array.length / size)).keys())
    .map((key) => key + 1)
    .reduce<T[][]>(
      (prev, batch) => [...prev, array.slice(size * (batch - 1), size * batch)],
      []
    )
}
