import * as React from 'react'

import RecommendedFaqTicketChatPastAutoChatTogglePresenter from './presenter'

type Props = {
  showAllAutoMessage: boolean
  onClick: () => void
}

const RecommendedFaqTicketChatPastAutoChatToggle: React.FC<Props> = (props) => {
  return (
    <RecommendedFaqTicketChatPastAutoChatTogglePresenter
      showAllAutoMessage={props.showAllAutoMessage}
      onClick={props.onClick}
    />
  )
}

export default React.memo(RecommendedFaqTicketChatPastAutoChatToggle)
