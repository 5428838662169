import * as React from 'react'
import * as ReactRedux from 'react-redux'

import type { RootState } from '../../../../app/store'
import { defaultAppIcon, defaultAppName } from '../../../../consts'
import { useCreateMessageReplyMutation } from '../../../deskApi'
import { useGetMemberPhotoQuery, useGetMemberQuery } from '../../../graphApi'
import { useGetTeamsCustomApplicationInfoQuery } from '../../../graphApi'
import * as AuthSelectors from '../../../RequesterAuth/selectors'
import RequesterChatInput from '../../../RequesterChatInput'
import { attachmentOnedriveData as attachmentOnedriveDataSelector } from '../../../RequesterChatInput/selectors'
import { linkedConversationID as linkedConversationIDSelector } from '../../selectors'
import { actions } from '../../slice'
import { ConversationButtons } from '../RequesterChatButtons'
import { ConversationText } from '../RequesterChatText'
import RequesterLastReplyItem from '../RequesterLastReplyItem'
import { chatInputKey } from './lib/chatInputKey'
import RequesterTicketItemPresenter from './presenter'
import * as Selectors from './selectors'

type Props = {
  id: string
  intersectionObserver?: React.MutableRefObject<
    IntersectionObserver | undefined
  >
  renderTicketReplyList: (ticketId: string) => React.ReactElement
}
const RequesterTicketItem: React.FC<Props> = (props) => {
  const { id, intersectionObserver } = props

  const time = ReactRedux.useSelector((state: RootState) =>
    Selectors.time(state, props.id)
  )
  const authorId = ReactRedux.useSelector((state: RootState) =>
    Selectors.authorId(state, props.id)
  )
  const replyCount = ReactRedux.useSelector((state: RootState) =>
    Selectors.replyCount(state, props.id)
  )
  const fileProps = ReactRedux.useSelector((state: RootState) =>
    Selectors.fileProps(state, props.id)
  )
  const attachmentOnedriveData = ReactRedux.useSelector((state: RootState) =>
    attachmentOnedriveDataSelector(state, chatInputKey(props.id))
  )
  const appId = ReactRedux.useSelector((state: RootState) =>
    AuthSelectors.appId(state)
  )
  const isRequesterCreatedTicket = ReactRedux.useSelector((state: RootState) =>
    Selectors.isRequesterCreatedTicket(state, props.id)
  )
  const linkedConversationID = ReactRedux.useSelector(
    linkedConversationIDSelector
  )
  const showLastReply = ReactRedux.useSelector((state: RootState) =>
    Selectors.showLastReply(state, props.id)
  )

  const isNewlyCreatedConversation = ReactRedux.useSelector(
    (state: RootState) => Selectors.isNewlyCreated(state, props.id)
  )

  const isLinked = React.useMemo(
    () => linkedConversationID === props.id,
    [props.id, linkedConversationID]
  )

  const { data: member } = useGetMemberQuery(
    { id: authorId },
    { skip: !isRequesterCreatedTicket }
  )
  const { data: photo } = useGetMemberPhotoQuery(
    { id: authorId },
    { skip: !isRequesterCreatedTicket }
  )
  const teamsCustomApplicationQuery = useGetTeamsCustomApplicationInfoQuery(
    {
      externalId: appId,
    },
    { skip: isRequesterCreatedTicket }
  )
  const [createMessageReplyMutation] = useCreateMessageReplyMutation()

  const dispatch = ReactRedux.useDispatch()

  const renderReplyList = React.useCallback(() => {
    return props.renderTicketReplyList(props.id)
  }, [props])

  const handleShowReplies = React.useCallback(
    (ticketId: string, open: boolean) => {
      dispatch(
        actions.setTicketRepliesOpen({ conversationID: ticketId, open: open })
      )
    },
    [dispatch]
  )

  const clickSend = React.useCallback(
    async (message: string) => {
      await createMessageReplyMutation({
        html: message,
        plainText: message.replace(/<[^>]*>/g, ''),
        attachments: attachmentOnedriveData,
        conversationId: props.id,
      })

      // trigger handleReplies
      handleShowReplies(props.id, true)
    },
    [
      props,
      createMessageReplyMutation,
      attachmentOnedriveData,
      handleShowReplies,
    ]
  )

  const renderLastReply = React.useCallback(() => {
    return <RequesterLastReplyItem ticketId={props.id} />
  }, [props])

  const showTicketReplies = ReactRedux.useSelector((state: RootState) => {
    return Selectors.showTicketReplies(state, id)
  })

  const container = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const current = container?.current
    if (current == null) {
      return
    }
    const currentObserver = intersectionObserver?.current
    currentObserver?.observe(current)
    return () => {
      currentObserver?.unobserve(current)
    }
  }, [intersectionObserver])

  // 新しいメッセージがスレッドに届くと）そのスレッドの返信一覧を展開された状態にする
  // 2023-12-06 最初の段階では、ビューポート内の全てを開くと、大量のリクエストが発生し、それが結果的にesに負荷をかけます。したがって、原因を特定する目的も含めて、一時的にコメントアウトします。
  // React.useEffect(() => {
  //   if (hasNewMessage) handleShowReplies(props.id, true)
  // }, [hasNewMessage, props.id, handleShowReplies])

  const renderChatInput = React.useCallback(
    (onClickClose: () => void) => (
      // チケット返信欄
      <RequesterChatInput
        chatKey={chatInputKey(props.id)}
        onClickSend={clickSend}
        onClickClose={onClickClose}
        inputFocus={isNewlyCreatedConversation ?? false}
      />
    ),
    [clickSend, isNewlyCreatedConversation, props.id]
  )

  return (
    <div ref={container} id={id}>
      <RequesterTicketItemPresenter
        avatarImage={
          isRequesterCreatedTicket
            ? photo ?? ''
            : teamsCustomApplicationQuery.isLoading
            ? ''
            : teamsCustomApplicationQuery.data?.iconURL ?? defaultAppIcon
        }
        memberName={
          isRequesterCreatedTicket
            ? member?.name ?? ''
            : teamsCustomApplicationQuery.isLoading
            ? ''
            : teamsCustomApplicationQuery.data?.displayName ?? defaultAppName
        }
        time={time}
        replyCount={replyCount}
        files={fileProps}
        isLinked={isLinked}
        showLastReply={showLastReply}
        renderChatText={() => <ConversationText conversationId={props.id} />}
        renderChatButtons={() => (
          <ConversationButtons conversationId={props.id} />
        )}
        renderReplyList={renderReplyList}
        renderLastReply={renderLastReply}
        renderChatInput={renderChatInput}
        replyInputDefaultOpen={isNewlyCreatedConversation}
        showTicketReplies={showTicketReplies}
        handleShowReplies={(open) => handleShowReplies(props.id, open)}
      />
    </div>
  )
}

export default React.memo(RequesterTicketItem)
