import { grpc } from '@improbable-eng/grpc-web'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'

import { AppThunk, RootState } from '../../app/store'
import { CustomFieldType, CustomFieldValue } from '../../consts'
import {
  DeleteTicketCustomFieldValueRequest,
  ListTicketCustomFieldValuesRequest,
  ListTicketCustomFieldValuesResponse,
  TicketCustomFieldOneOfValue,
  TicketCustomFieldValue,
  UpdateTicketCustomFieldValueRequest,
} from '../../proto/ticket_custom_field_value_pb'
import { TicketCustomFieldValueAPI } from '../../proto/ticket_custom_field_value_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from '../auth/authSlice'

export type TicketCustomFieldValueErrorObject = {
  isError: boolean
  errorMessage: string | null
}

// field_id: [fieldValue]
export const customFieldValuesAdapter = createEntityAdapter<
  TicketCustomFieldValue.AsObject & TicketCustomFieldValueErrorObject
>({
  selectId: (customFieldValue) => customFieldValue.ticketCustomFieldId,
})

export interface CustomFieldValueState {
  loading: boolean
  error: string | null
  isInitializing: boolean
}

export const customFieldValueSlice = createSlice({
  name: 'customFieldValues',
  initialState: customFieldValuesAdapter.getInitialState<CustomFieldValueState>(
    {
      loading: false,
      error: null,
      isInitializing: true,
    }
  ),
  reducers: {
    getCustomFieldValuesStart(state) {
      state.loading = true
      state.error = null
    },
    getCustomFieldValuesOnMessage(
      state,
      action: PayloadAction<{
        message: ListTicketCustomFieldValuesResponse.AsObject
      }>
    ) {
      const { message } = action.payload
      const customFieldValues = message.ticketCustomFieldValuesList
      const values = customFieldValues.map((v) => {
        return { ...v, isError: false, errorMessage: null }
      })
      customFieldValuesAdapter.setAll(state, values)
    },
    getCustomFieldValuesOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = null
        state.isInitializing = false
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
    updateCustomFieldValueStart(
      state,
      action: PayloadAction<{
        ticketCustomFieldId: number
      }>
    ) {
      const { ticketCustomFieldId } = action.payload
      state.loading = true
      const customFieldValueEntity = state.entities[ticketCustomFieldId]
      // entityが存在しない場合は作成
      if (customFieldValueEntity == null) {
        const customFieldValue = new TicketCustomFieldValue()
        customFieldValue.setTicketCustomFieldId(ticketCustomFieldId)
        const value = {
          ...customFieldValue.toObject(),
          isError: false,
          errorMessage: null,
        }
        customFieldValuesAdapter.upsertOne(state, value)
      } else {
        customFieldValueEntity.isError = false
        customFieldValueEntity.errorMessage = null
      }
    },
    updateCustomFieldValueOnMessage(
      state,
      action: PayloadAction<{
        message: TicketCustomFieldValue.AsObject
      }>
    ) {
      const customFieldValue = action.payload.message
      const value = { ...customFieldValue, isError: false, errorMessage: null }
      customFieldValuesAdapter.upsertOne(state, value)
    },
    updateCustomFieldValueOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
        ticketCustomFieldId: number
      }>
    ) {
      const { code, message, ticketCustomFieldId } = action.payload
      const customFieldValue = state.entities[ticketCustomFieldId]
      state.loading = false

      // Update対象がStateに存在しない場合はState自体のエラーとする
      if (customFieldValue != null) {
        state.error = null
      } else {
        const errorMessage = CustomFieldValue.ClientErrorMessage.Unknown
        state.error = errorMessage
        consoleErrorWithAirbrake(errorMessage)
        return
      }
      // それぞれのフィールドのエラーはEntity内部で状態をもつ
      if (code === grpc.Code.OK) {
        customFieldValue.isError = false
        customFieldValue.errorMessage = null
      } else {
        customFieldValue.isError = true
        customFieldValue.errorMessage = message
        consoleErrorWithAirbrake(message)
      }
    },
    deleteRequestOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
        ticketCustomFieldId: number
      }>
    ) {
      const { code, message, ticketCustomFieldId } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = null
        customFieldValuesAdapter.removeOne(state, ticketCustomFieldId)
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
    setCustomFieldValueInitialize(state) {
      state.isInitializing = true
      state.error = null
    },
    setCustomFieldValueEntityError(
      state,
      action: PayloadAction<{
        ticketCustomFieldId: number
        isError: boolean
      }>
    ) {
      const { ticketCustomFieldId, isError } = action.payload
      const customFieldValue = state.entities[ticketCustomFieldId]
      if (customFieldValue != null) customFieldValue.isError = isError
    },
  },
})

export const {
  getCustomFieldValuesStart,
  getCustomFieldValuesOnMessage,
  getCustomFieldValuesOnEnd,
  updateCustomFieldValueStart,
  updateCustomFieldValueOnMessage,
  updateCustomFieldValueOnEnd,
  setCustomFieldValueInitialize,
  setCustomFieldValueEntityError,
  deleteRequestOnEnd,
} = customFieldValueSlice.actions
export default customFieldValueSlice.reducer

export const customFieldValueSelector = customFieldValuesAdapter.getSelectors(
  (state: RootState) => state.customFieldValue
)

export const fetchCustomFieldValues =
  (ticketId: number): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      ListTicketCustomFieldValuesRequest,
      ListTicketCustomFieldValuesResponse
    >(TicketCustomFieldValueAPI.ListTicketCustomFieldValues)
    const req = new ListTicketCustomFieldValuesRequest()
    req.setTicketId(ticketId)

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      dispatch(
        getCustomFieldValuesOnMessage({
          message: message.toObject(),
        })
      )
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(getCustomFieldValuesOnEnd({ code, message }))
    })

    dispatch(getCustomFieldValuesStart())
    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const deleteTicketCustomFieldValue =
  (ticketId: number, ticketCustomFieldId: number): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<DeleteTicketCustomFieldValueRequest, Empty>(
      TicketCustomFieldValueAPI.DeleteTicketCustomFieldValue
    )
    const req = new DeleteTicketCustomFieldValueRequest()
    req.setTicketId(ticketId)
    req.setTicketCustomFieldId(ticketCustomFieldId)
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(deleteRequestOnEnd({ code, message, ticketCustomFieldId }))
    })
    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const updateTicketCustomFieldValue =
  (
    ticketId: number,
    ticketCustomFieldId: number,
    fieldType: string,
    changeValue: Array<Partial<TicketCustomFieldOneOfValue.AsObject>>
  ): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      UpdateTicketCustomFieldValueRequest,
      TicketCustomFieldValue
    >(TicketCustomFieldValueAPI.UpdateTicketCustomFieldValue)
    const req = createUpdateTicketCustomFieldValueRequest(
      ticketId,
      ticketCustomFieldId,
      fieldType,
      changeValue
    )

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      dispatch(updateCustomFieldValueOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(
        updateCustomFieldValueOnEnd({
          code,
          message,
          ticketCustomFieldId,
        })
      )
    })
    dispatch(updateCustomFieldValueStart({ ticketCustomFieldId }))
    client.start(meta)
    client.send(req)
    client.finishSend()
  }

const createUpdateTicketCustomFieldValueRequest = (
  ticketId: number,
  ticketCustomFieldId: number,
  fieldType: string,
  changeValue: Array<Partial<TicketCustomFieldOneOfValue.AsObject>>
) => {
  const req = new UpdateTicketCustomFieldValueRequest()
  req.setTicketId(ticketId)
  req.setTicketCustomFieldId(ticketCustomFieldId)

  const values = new Array<TicketCustomFieldOneOfValue>()

  if (
    fieldType === CustomFieldType.select ||
    fieldType === CustomFieldType.multiselect ||
    fieldType === CustomFieldType.text
  ) {
    changeValue.forEach((v) => {
      const ticketCustomFieldOneOfValue = new TicketCustomFieldOneOfValue()
      if (v.stringValue != null) {
        ticketCustomFieldOneOfValue.setStringValue(v.stringValue)
        values.push(ticketCustomFieldOneOfValue)
      }
    })
  }

  if (
    fieldType === CustomFieldType.number ||
    fieldType === CustomFieldType.checkbox
  ) {
    changeValue.forEach((v) => {
      const ticketCustomFieldOneOfValue = new TicketCustomFieldOneOfValue()
      if (v.numberValue != null) {
        ticketCustomFieldOneOfValue.setNumberValue(v.numberValue)
        values.push(ticketCustomFieldOneOfValue)
      }
    })
  }

  if (fieldType === CustomFieldType.textarea) {
    changeValue.forEach((v) => {
      const ticketCustomFieldOneOfValue = new TicketCustomFieldOneOfValue()
      if (v.textValue != null) {
        ticketCustomFieldOneOfValue.setTextValue(v.textValue)
        values.push(ticketCustomFieldOneOfValue)
      }
    })
  }

  if (fieldType === CustomFieldType.date) {
    changeValue.forEach((v) => {
      const ticketCustomFieldOneOfValue = new TicketCustomFieldOneOfValue()
      if (v.dateValue != null) {
        ticketCustomFieldOneOfValue.setDateValue(v.dateValue)
        values.push(ticketCustomFieldOneOfValue)
      }
    })
  }
  req.setValueList(values)
  return req
}
