import { Button, ChevronStartIcon, FilterIcon } from '@fluentui/react-northstar'
import * as React from 'react'
import { Link } from 'react-router-dom'

import DashboardBadgeContent from './components/DashboardBadgeContent'
import styles from './styles.module.css'

type FilterBadge = {
  text: string
}
type Props = {
  isFilterButtonPrimary: boolean
  filterBadges: FilterBadge[]
  onClickFilterButton: () => void
}
const DashboardHeaderPresenter: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.headerLeft}>
        <Button
          text
          icon={<ChevronStartIcon />}
          content={<Link to="/">問い合わせ一覧</Link>}
        />
      </div>
      <div className={styles.headerRight}>
        <div className={styles.filterList}>
          {props.filterBadges.map((badge, i) => (
            <DashboardBadgeContent key={i} text={badge.text} />
          ))}
        </div>
        <Button
          text
          icon={<FilterIcon />}
          content="フィルター"
          className={styles.filterButton}
          primary={props.isFilterButtonPrimary}
          onClick={props.onClickFilterButton}
        />
      </div>
    </div>
  )
}

export default DashboardHeaderPresenter
