import React from 'react'

import { DefaultFields } from '../../consts'
import type { PulldownItem } from '../assigneeSelection/types/PulldownItem'
import FaqStatusFilter from './FaqStatusFilter'
import TicketAssignedUserIdFilter from './TicketAssignedUserIdFilter'
import TicketCreatedAtFilter from './TicketCreatedAtFilter'
import TicketKeyIdFilter from './TicketKeyIdFilter'
import TicketRequesterFilter from './TicketRequesterFilter'
import TicketStatusFilter from './TicketStatusFilter'
import TicketSubjectFilter from './TicketSubjectFilter'

type Props = {
  fieldName: string
  renderAssigneeSelection: (
    values: string[],
    onChange: (values: string[]) => void,
    extendedItems: PulldownItem[]
  ) => React.ReactElement
}
const TicketDefaultFieldFilter: React.FC<Props> = (props) => {
  if (props.fieldName === DefaultFields.Requester.name) {
    return <TicketRequesterFilter />
  }
  if (props.fieldName === DefaultFields.Subject.name) {
    return <TicketSubjectFilter />
  }
  if (props.fieldName === DefaultFields.KeyId.name) {
    return <TicketKeyIdFilter />
  }
  if (props.fieldName === DefaultFields.Status.name) {
    return <TicketStatusFilter />
  }
  if (props.fieldName === DefaultFields.AssignedUserId.name) {
    return (
      <TicketAssignedUserIdFilter
        renderAssigneeSelection={props.renderAssigneeSelection}
      />
    )
  }
  if (props.fieldName === DefaultFields.CreatedAt.name) {
    return <TicketCreatedAtFilter />
  }
  if (props.fieldName === DefaultFields.FaqStatus.name) {
    return <FaqStatusFilter />
  }
  return <></>
}

export default TicketDefaultFieldFilter
