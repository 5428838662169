import { Loader } from '@fluentui/react-northstar'
import { EntityId } from '@reduxjs/toolkit'
import * as React from 'react'

import { useGetRequesterConversationRepliesQuery } from '../../api'
import RequesterTicketReplyListPresenter from './presenter'

type Props = {
  ticketId: string
  renderTicketReplyItem: (id: EntityId, ticketId: string) => React.ReactElement
}
const RequesterTicketReplyList: React.FC<Props> = (props) => {
  const { data, isLoading } = useGetRequesterConversationRepliesQuery(
    {
      ticketId: props.ticketId,
    },
    { pollingInterval: 5000 }
  )

  const replyList = React.useMemo<EntityId[]>(() => {
    if (!data) return []
    // 一番最初の要素はチケットの本文になっているため、返信のみにするように除外する
    // return data.ids.filter((_, i) => i !== 0)
    return data.ids
  }, [data])

  const renderReplyItem = React.useCallback(
    (id: EntityId) => {
      return props.renderTicketReplyItem(id, props.ticketId)
    },
    [props]
  )

  if (isLoading) return <Loader />
  return (
    <RequesterTicketReplyListPresenter
      key={props.ticketId}
      replyList={replyList}
      renderReplyItem={renderReplyItem}
    />
  )
}

export default RequesterTicketReplyList
