import { Label } from '@fluentui/react-northstar'
import React from 'react'

import { FaqStatus, FaqStatusColors, FaqStatusLabels } from '../../consts'
import styles from './FaqStatusLabel.module.css'

type Props = {
  faqStatus: FaqStatus
}

const FaqStatusLabel: React.FC<Props> = ({ faqStatus }) => {
  return faqStatus !== FaqStatus.nothing ? (
    <Label
      className={styles.statusLabel}
      styles={({ theme: { siteVariables } }) => {
        const arr = FaqStatusColors[faqStatus].split('.')
        const t = arr.reduce((a, b) => {
          return a?.[b]
        }, siteVariables)
        return {
          background: t,
          color: siteVariables.primitiveColors.white,
        }
      }}
      content={FaqStatusLabels[faqStatus]}
    />
  ) : (
    <></>
  )
}

export default FaqStatusLabel
