import * as React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../../../app/store'
import RecommendedFaqListPicturePreviewPresenter from './presenter'

const RecommendedFaqListPicturePreview: React.FC = () => {
  const imagePreviewState = useSelector(
    (state: RootState) => state.imagePreview
  )

  return (
    <RecommendedFaqListPicturePreviewPresenter
      hidden={imagePreviewState.hidden}
      src={imagePreviewState.src || ''}
      downloadData={imagePreviewState.downloadData}
      openUrl={imagePreviewState.openUrl}
    />
  )
}

export default React.memo(RecommendedFaqListPicturePreview)
