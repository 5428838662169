import * as React from 'react'
import { useSelector } from 'react-redux'

import DashboardCharts from '../../renewfeatures/DashboardCharts'
import DashboardFilterSidepanel from '../../renewfeatures/DashboardFilterSidepanel'
import * as DashboardFilterSidepanelSelectors from '../../renewfeatures/DashboardFilterSidepanel/selectors'
import DashboardHeader from '../../renewfeatures/DashboardHeader'
import DashboardPresenter from './presenter'

const Dashboard: React.FC = () => {
  const showSidepanel = useSelector(DashboardFilterSidepanelSelectors.isOpen)

  return (
    <DashboardPresenter
      showSidepanel={showSidepanel}
      renderDashboardCharts={() => <DashboardCharts />}
      renderHeader={() => <DashboardHeader />}
      renderSidepanel={() => <DashboardFilterSidepanel />}
    />
  )
}

export default Dashboard
