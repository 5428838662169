import {
  Avatar,
  Button,
  CallIcon,
  CallVideoIcon,
  ChatIcon,
  ClipboardCopiedToIcon,
  Flex,
  Popup,
  Text,
} from '@fluentui/react-northstar'
import * as Graph from '@microsoft/microsoft-graph-types'
import * as microsoftTeams from '@microsoft/teams-js'

import { Tickets } from '../../consts'
import {
  consoleErrorWithAirbrake,
  copyToClipboard,
  getDisplayName,
  getWebagentDisplayName,
} from '../../utils'
import { Ticket } from './ticketSlice'

export const RequesterContactInfo: React.FC<{
  requester: Graph.User | undefined
  requesterPhoto: string
  ticket: Ticket
}> = (props) => {
  const requesterName =
    props.ticket.requesterType === Tickets.RequesterType.RequesterTypeWebagent
      ? getWebagentDisplayName(props.ticket.requesterUserId)
      : getDisplayName(props.requester)
  const requesterUserEmail =
    props.requester?.mail || props.requester?.userPrincipalName || ''
  const principalName = props.requester?.userPrincipalName || ''
  const chatURL = `https://teams.microsoft.com/l/chat/0/0?users=${encodeURI(
    principalName
  )}`
  const callURL = `https://teams.microsoft.com/l/call/0/0?users=${encodeURI(
    principalName
  )}`
  const videoURL = `https://teams.microsoft.com/l/call/0/0?users=${encodeURI(
    principalName
  )}&withVideo=true`

  // NOTE: リクエスタが Webagent の場合は、一部の項目を隠す。
  const hidden =
    props.ticket.requesterType === Tickets.RequesterType.RequesterTypeWebagent

  return (
    <Flex column>
      <Flex gap="gap.smaller" style={{ padding: '5px' }}>
        <Avatar
          name={requesterName}
          image={props.requesterPhoto}
          size="large"
          style={{
            marginRight: '15px',
            flexShrink: 0,
          }}
        />
        <Flex.Item align="center">
          <Text
            style={{
              fontWeight: 'bold',
              lineHeight: 1.3,
              overflowWrap: 'anywhere',
              flexShrink: 1,
            }}
          >
            {requesterName}
          </Text>
        </Flex.Item>
      </Flex>
      <Flex gap="gap.smaller" hidden={hidden}>
        <Flex.Item align="center">
          <Text truncated>
            <a
              href={`mailto:${requesterUserEmail}`}
              style={{ color: '#6264a7', textDecoration: 'none' }}
            >
              {requesterUserEmail}
            </a>
          </Text>
        </Flex.Item>
        <Flex.Item align="center">
          <Popup
            pointing={true}
            trigger={
              <Button
                onClick={() => {
                  copyToClipboard(requesterUserEmail)
                }}
                icon={<ClipboardCopiedToIcon />}
                text
                iconOnly
              />
            }
            content={'メールアドレスをコピーしました'}
            on="click"
          />
        </Flex.Item>
      </Flex>
      <Flex vAlign="center" hAlign="start" gap="gap.smaller" hidden={hidden}>
        <Button
          onClick={() => {
            try {
              microsoftTeams.app.openLink(chatURL)
            } catch (e) {
              consoleErrorWithAirbrake(
                `error at microsoftTeams.app.openLink: ${e}`
              )
            }
          }}
          icon={<ChatIcon />}
          text
          iconOnly
        />
        <Button
          onClick={() => {
            try {
              microsoftTeams.app.openLink(callURL)
            } catch (e) {
              consoleErrorWithAirbrake(
                `error at microsoftTeams.app.openLink: ${e}`
              )
            }
          }}
          icon={<CallIcon />}
          text
          iconOnly
        />
        <Button
          onClick={() => {
            try {
              microsoftTeams.app.openLink(videoURL)
            } catch (e) {
              consoleErrorWithAirbrake(
                `error at microsoftTeams.app.openLink: ${e}`
              )
            }
          }}
          icon={<CallVideoIcon />}
          text
          iconOnly
        />
      </Flex>
    </Flex>
  )
}
