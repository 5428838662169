import { Dialog } from '@fluentui/react-northstar'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import PageViewLog from '../../components/PageViewLog'
import { PageName } from '../../consts'
import { createDisplayName } from '../auth/users'
import { fetchTicket } from './ticketSlice'

interface Props {
  ticketId: number
  onConfirm: () => void
  onCancel: () => void
}

const TicketAssignedUserDialog: React.FC<Props> = ({
  ticketId,
  onConfirm,
  onCancel,
}) => {
  const dispatch = useDispatch()
  const ticketState = useSelector((state: RootState) => state.ticket)
  const memberState = useSelector((state: RootState) => state.member)
  const isOpen = ticketState.isRequiredAssignedUserConfirm
  useEffect(() => {
    dispatch(fetchTicket(ticketId))
  }, [dispatch, ticketId, isOpen])

  const ticket = ticketState.entities[ticketId]
  if (ticket == null) return null

  return (
    <PageViewLog
      pageName={PageName.TicketAssignedUserDialog}
      isOpenable
      isOpen={isOpen}
    >
      <Dialog
        open={isOpen}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
        cancelButton="キャンセル"
        confirmButton="確定"
        content={`既に${createDisplayName(
          ticket.assignedUserId,
          memberState.entities
        )}さんが担当者に設定されています。変更しますか？`}
      />
    </PageViewLog>
  )
}

export default TicketAssignedUserDialog
