import { Button, Popup, TableDeleteIcon } from '@fluentui/react-northstar'
import * as React from 'react'

import styles from './style.module.css'

type Props = {
  onClickRemoveTable: () => void
  onClickRemoveRow: () => void
  onClickRemoveColumn: () => void
}
const TableRemovePopup: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <Popup
      open={isOpen}
      onOpenChange={(e, data) => setIsOpen(data?.open ?? false)}
      trigger={<Button text iconOnly icon={<TableDeleteIcon />} />}
      content={<Content {...props} close={() => setIsOpen(false)} />}
    />
  )
}

export default TableRemovePopup

const Content: React.FC<Props & { close: () => void }> = (props) => {
  const withClose =
    <T extends () => void>(fn: T) =>
    () => {
      fn()
      props.close()
    }
  return (
    <ul className={styles.list}>
      <li onClick={withClose(props.onClickRemoveColumn)}>列を削除</li>
      <li onClick={withClose(props.onClickRemoveRow)}>行を削除</li>
      <li onClick={withClose(props.onClickRemoveTable)}>テーブルを削除</li>
    </ul>
  )
}
