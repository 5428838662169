import {
  ExcelColorIcon,
  FilesCodeIcon,
  FilesEmptyIcon,
  FilesImageIcon,
  FilesPdfIcon,
  FilesSoundIcon,
  FilesTxtIcon,
  FilesVideoIcon,
  FilesZipIcon,
  PowerPointColorIcon,
  WordColorIcon,
} from '@fluentui/react-icons-northstar'

export const attachmentIcon = (fileName: string): JSX.Element => {
  const extension = fileName.split('.').pop()
  if (!extension) return <FilesEmptyIcon />
  switch (extension.toLowerCase()) {
    case 'pdf':
      return <FilesPdfIcon />
    case 'txt':
      return <FilesTxtIcon />
    case 'zip':
      return <FilesZipIcon />
    case 'doc':
    case 'docx':
    case 'docm':
    case 'dotx':
      return <WordColorIcon />
    case 'xlsx':
    case 'xlsm':
    case 'xlsb':
    case 'xls':
    case 'csv':
      return <ExcelColorIcon />
    case 'pptx':
    case 'pptm':
    case 'ppt':
    case 'potm':
      return <PowerPointColorIcon />
    case 'jpg':
    case 'png':
    case 'jpeg':
    case 'gif':
    case 'tiff':
    case 'bmp':
      return <FilesImageIcon />
    case 'mp3':
    case 'wav':
    case 'm4a':
    case 'flac':
      return <FilesSoundIcon />
    case 'mp4':
    case 'mov':
      return <FilesVideoIcon />
    case 'html':
    case 'xml':
      return <FilesCodeIcon />
    default:
      return <FilesEmptyIcon />
  }
}

const imageFileTypes = ['image/jpeg', 'image/png', 'image/gif']

export const isImage = (fileType: string): boolean =>
  imageFileTypes.some((t) => t === fileType)
