import { createAsyncThunk } from '@reduxjs/toolkit'
import * as uuid from 'uuid'

import { RootState, ThunkDispatchType } from '../../app/store'
import { betaGraphApi, graphApi } from '../graphApi'
import { actions } from './slice'

const isLargeSize = (size: number) => size >= 4 * 1024 * 1024

type UploadImageToGraphResult = {
  driveId: string
  itemId: string
  src: string
}
/**
 * エディタテキスト内の画像をアップロードする
 */
export const uploadImageToGraph = createAsyncThunk<
  UploadImageToGraphResult,
  { file: File; appId: string }
>('requesterChatInput/uploadImage', async (args, thunkAPI) => {
  const teamsInfo = await thunkAPI
    .dispatch(
      betaGraphApi.endpoints.getTeamsCustomApplicationInfo.initiate({
        externalId: args.appId,
      })
    )
    .unwrap()

  if (isLargeSize(args.file.size)) {
    return await uploadLargeImage(
      thunkAPI.dispatch,
      teamsInfo.displayName
    )(args.file)
  }
  return await uploadSmallImage(
    thunkAPI.dispatch,
    teamsInfo.displayName
  )(args.file)
})

const uploadLargeImage =
  (dispatch: ThunkDispatchType, folderName: string) =>
  async (file: File): Promise<UploadImageToGraphResult> => {
    const uploadRes = await dispatch(
      graphApi.endpoints.uploadLargeFile.initiate(
        {
          file,
          folderName: folderName,
        },
        { track: false }
      )
    ).unwrap()

    return getThumbnailFromGraph(dispatch)(uploadRes)
  }

const uploadSmallImage =
  (dispatch: ThunkDispatchType, folderName: string) =>
  async (file: File): Promise<UploadImageToGraphResult> => {
    const uploadRes = await dispatch(
      graphApi.endpoints.uploadFile.initiate(
        {
          file,
          folderName: folderName,
        },
        { track: false }
      )
    ).unwrap()

    return getThumbnailFromGraph(dispatch)(uploadRes)
  }

const getThumbnailFromGraph =
  (dispatch: ThunkDispatchType) =>
  async (item: {
    itemId: string
    driveId: string
  }): Promise<UploadImageToGraphResult> => {
    const thumbnailRes = await dispatch(
      graphApi.endpoints.getThumbnail.initiate(
        {
          driveId: item.driveId,
          itemId: item.itemId,
        },
        { track: false }
      )
    )
    if ('error' in thumbnailRes) {
      throw thumbnailRes.error
    }

    return {
      driveId: item.driveId,
      itemId: item.itemId,
      src: thumbnailRes.data,
    }
  }

/**
 * エディタに付随するファイルをアップロードする
 */
export const uploadFileToGraph = createAsyncThunk<
  void,
  { key: string; file: File; appId: string }
>('requesterChatInput/uploadFile', async (args, thunkAPI): Promise<void> => {
  const teamsInfo = await thunkAPI
    .dispatch(
      betaGraphApi.endpoints.getTeamsCustomApplicationInfo.initiate({
        externalId: args.appId,
      })
    )
    .unwrap()

  const id = uuid.v4()
  thunkAPI.dispatch(
    actions.appendAttachment({
      key: args.key,
      file: args.file,
      id,
      uniqueId: '',
    })
  )

  if (isLargeSize(args.file.size)) {
    const uploadLargeFile = uploadFileFactory(async (file) => {
      const res = await thunkAPI.dispatch(
        graphApi.endpoints.uploadLargeFile.initiate({
          file,
          folderName: teamsInfo.displayName,
        })
      )
      if ('error' in res) {
        thunkAPI.dispatch(actions.errorUploadAttachment({ key: args.key, id }))
        throw new Error('upload large file error')
      }
      return res.data
    })

    await uploadLargeFile(thunkAPI.dispatch)(args.file, args.key, id)
  }

  const uploadSmallFile = uploadFileFactory(async (file) => {
    const res = await thunkAPI.dispatch(
      graphApi.endpoints.uploadFile.initiate({
        file,
        folderName: teamsInfo.displayName,
      })
    )
    if ('error' in res) {
      thunkAPI.dispatch(actions.errorUploadAttachment({ key: args.key, id }))
      throw new Error('upload small file error')
    }
    return res.data
  })

  await uploadSmallFile(thunkAPI.dispatch)(args.file, args.key, id)
})

const uploadFileFactory =
  (
    upload: (file: File) => Promise<{
      driveId: string
      itemId: string
      webUrl?: string
      uniqueId: string
    }>
  ) =>
  (dispatch: ThunkDispatchType) =>
  async (file: File, key: string, id: string) => {
    const uploadRes = await upload(file)

    const driveId = uploadRes.driveId
    if (!driveId) {
      dispatch(actions.errorUploadAttachment({ key, id }))
      throw new Error('graph api not returns driveId')
    }

    const itemId = uploadRes.itemId
    if (!itemId) {
      dispatch(actions.errorUploadAttachment({ key, id }))
      throw new Error('graph api not returns itemId')
    }

    const webUrl = uploadRes.webUrl
    if (!webUrl) {
      dispatch(actions.errorUploadAttachment({ key, id }))
      throw new Error('graph api not returns webUrl')
    }

    const shareLink = await getShareLinkFromGraph(dispatch)(
      driveId,
      itemId,
      key,
      id
    )

    dispatch(
      actions.finishUploadAttachment({
        key,
        id,
        driveId,
        itemId,
        shareLink,
        url: webUrl,
        uniqueId: uploadRes.uniqueId,
      })
    )
  }

const getShareLinkFromGraph =
  (dispatch: ThunkDispatchType) =>
  async (
    driveId: string,
    itemId: string,
    key: string,
    id: string
  ): Promise<string> => {
    const shareLinkRes = await dispatch(
      graphApi.endpoints.getShareLink.initiate(
        { driveId, itemId },
        { track: false }
      )
    )
    if ('error' in shareLinkRes) {
      dispatch(actions.errorUploadAttachment({ key, id }))
      throw shareLinkRes.error
    }

    const shareLink = shareLinkRes.data.link?.webUrl
    if (!shareLink) {
      dispatch(actions.errorUploadAttachment({ key, id }))
      throw new Error('graph api not returns webUrl')
    }

    return shareLink
  }

/**
 * エディタに付随するファイルを削除する
 */
export const deleteFileFromGraph = createAsyncThunk<
  void,
  { key: string; id: string },
  { state: RootState }
>('requesterChatInput/deleteFile', async (args, thunkAPI) => {
  const state = thunkAPI.getState()
  const field = state.requesterChatInput.fields[args.key]
  if (!field) return

  const stateFile = field.attachments.find((f) => f.id === args.id)
  if (!stateFile) return
  if (!stateFile.driveId) return
  if (!stateFile.itemId) return

  await thunkAPI.dispatch(
    graphApi.endpoints.removeFile.initiate(
      {
        driveId: stateFile.driveId,
        itemId: stateFile.itemId,
      },
      { track: false }
    )
  )

  thunkAPI.dispatch(actions.removeAttachment({ key: args.key, id: args.id }))
})
