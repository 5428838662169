// source: proto/ticket.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var proto_ticket_custom_field_value_pb = require('../proto/ticket_custom_field_value_pb.js');
goog.object.extend(proto, proto_ticket_custom_field_value_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.CreateManualTicketRequest', null, global);
goog.exportSymbol('proto.DeleteManualTicketRequest', null, global);
goog.exportSymbol('proto.GetTicketRequest', null, global);
goog.exportSymbol('proto.GetTicketResponse', null, global);
goog.exportSymbol('proto.ListTicketsRequest', null, global);
goog.exportSymbol('proto.ListTicketsResponse', null, global);
goog.exportSymbol('proto.Ticket', null, global);
goog.exportSymbol('proto.TicketChannel', null, global);
goog.exportSymbol('proto.TicketCustomDateFieldSort', null, global);
goog.exportSymbol('proto.TicketCustomFieldOneOfSort', null, global);
goog.exportSymbol('proto.TicketCustomFieldOneOfSort.ValuesCase', null, global);
goog.exportSymbol('proto.TicketCustomNumberFieldSort', null, global);
goog.exportSymbol('proto.TicketErrorCode', null, global);
goog.exportSymbol('proto.TicketErrorDetail', null, global);
goog.exportSymbol('proto.TicketOneOfSort', null, global);
goog.exportSymbol('proto.TicketOneOfSort.ValuesCase', null, global);
goog.exportSymbol('proto.UpdateTicketRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Ticket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Ticket.repeatedFields_, null);
};
goog.inherits(proto.Ticket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Ticket.displayName = 'proto.Ticket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TicketChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketChannel.displayName = 'proto.TicketChannel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetTicketRequest.displayName = 'proto.GetTicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetTicketResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetTicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetTicketResponse.displayName = 'proto.GetTicketResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListTicketsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListTicketsRequest.repeatedFields_, null);
};
goog.inherits(proto.ListTicketsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListTicketsRequest.displayName = 'proto.ListTicketsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListTicketsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListTicketsResponse.repeatedFields_, null);
};
goog.inherits(proto.ListTicketsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListTicketsResponse.displayName = 'proto.ListTicketsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateTicketRequest.displayName = 'proto.UpdateTicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketOneOfSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.TicketOneOfSort.oneofGroups_);
};
goog.inherits(proto.TicketOneOfSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketOneOfSort.displayName = 'proto.TicketOneOfSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketCustomFieldOneOfSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.TicketCustomFieldOneOfSort.oneofGroups_);
};
goog.inherits(proto.TicketCustomFieldOneOfSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketCustomFieldOneOfSort.displayName = 'proto.TicketCustomFieldOneOfSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketCustomNumberFieldSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TicketCustomNumberFieldSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketCustomNumberFieldSort.displayName = 'proto.TicketCustomNumberFieldSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketCustomDateFieldSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TicketCustomDateFieldSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketCustomDateFieldSort.displayName = 'proto.TicketCustomDateFieldSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketErrorDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TicketErrorDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketErrorDetail.displayName = 'proto.TicketErrorDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateManualTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateManualTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateManualTicketRequest.displayName = 'proto.CreateManualTicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteManualTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteManualTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteManualTicketRequest.displayName = 'proto.DeleteManualTicketRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Ticket.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Ticket.prototype.toObject = function(opt_includeInstance) {
  return proto.Ticket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Ticket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Ticket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    botId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    status: jspb.Message.getFieldWithDefault(msg, 5, ""),
    requesterUserId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    assignedUserId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 8, ""),
    receivedAt: (f = msg.getReceivedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sentAt: (f = msg.getSentAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    completedAt: (f = msg.getCompletedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    noteRequestResponses: jspb.Message.getFieldWithDefault(msg, 12, ""),
    channel: (f = msg.getChannel()) && proto.TicketChannel.toObject(includeInstance, f),
    keyId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    ticketCustomFieldValuesList: jspb.Message.toObjectList(msg.getTicketCustomFieldValuesList(),
    proto_ticket_custom_field_value_pb.TicketCustomFieldValue.toObject, includeInstance),
    isManual: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    note: jspb.Message.getFieldWithDefault(msg, 17, ""),
    deletedAt: (f = msg.getDeletedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    faqCreated: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    faqCreatorUserId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    faqStatus: jspb.Message.getFieldWithDefault(msg, 21, ""),
    requesterType: jspb.Message.getFieldWithDefault(msg, 22, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Ticket}
 */
proto.Ticket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Ticket;
  return proto.Ticket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Ticket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Ticket}
 */
proto.Ticket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBotId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequesterUserId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedUserId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReceivedAt(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSentAt(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletedAt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoteRequestResponses(value);
      break;
    case 13:
      var value = new proto.TicketChannel;
      reader.readMessage(value,proto.TicketChannel.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setKeyId(value);
      break;
    case 15:
      var value = new proto_ticket_custom_field_value_pb.TicketCustomFieldValue;
      reader.readMessage(value,proto_ticket_custom_field_value_pb.TicketCustomFieldValue.deserializeBinaryFromReader);
      msg.addTicketCustomFieldValues(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsManual(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 18:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeletedAt(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFaqCreated(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaqCreatorUserId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaqStatus(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequesterType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Ticket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Ticket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Ticket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Ticket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBotId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRequesterUserId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAssignedUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getReceivedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSentAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompletedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNoteRequestResponses();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.TicketChannel.serializeBinaryToWriter
    );
  }
  f = message.getKeyId();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getTicketCustomFieldValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto_ticket_custom_field_value_pb.TicketCustomFieldValue.serializeBinaryToWriter
    );
  }
  f = message.getIsManual();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFaqCreated();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getFaqCreatorUserId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getFaqStatus();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getRequesterType();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.Ticket.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Ticket.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Ticket} returns this
*/
proto.Ticket.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Ticket.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Ticket.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Ticket} returns this
*/
proto.Ticket.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Ticket.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 bot_id = 4;
 * @return {number}
 */
proto.Ticket.prototype.getBotId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.setBotId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.Ticket.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string requester_user_id = 6;
 * @return {string}
 */
proto.Ticket.prototype.getRequesterUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.setRequesterUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string assigned_user_id = 7;
 * @return {string}
 */
proto.Ticket.prototype.getAssignedUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.setAssignedUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string subject = 8;
 * @return {string}
 */
proto.Ticket.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp received_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Ticket.prototype.getReceivedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Ticket} returns this
*/
proto.Ticket.prototype.setReceivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.clearReceivedAt = function() {
  return this.setReceivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Ticket.prototype.hasReceivedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp sent_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Ticket.prototype.getSentAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Ticket} returns this
*/
proto.Ticket.prototype.setSentAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.clearSentAt = function() {
  return this.setSentAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Ticket.prototype.hasSentAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp completed_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Ticket.prototype.getCompletedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Ticket} returns this
*/
proto.Ticket.prototype.setCompletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.clearCompletedAt = function() {
  return this.setCompletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Ticket.prototype.hasCompletedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string note_request_responses = 12;
 * @return {string}
 */
proto.Ticket.prototype.getNoteRequestResponses = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.setNoteRequestResponses = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional TicketChannel channel = 13;
 * @return {?proto.TicketChannel}
 */
proto.Ticket.prototype.getChannel = function() {
  return /** @type{?proto.TicketChannel} */ (
    jspb.Message.getWrapperField(this, proto.TicketChannel, 13));
};


/**
 * @param {?proto.TicketChannel|undefined} value
 * @return {!proto.Ticket} returns this
*/
proto.Ticket.prototype.setChannel = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.clearChannel = function() {
  return this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Ticket.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional uint32 key_id = 14;
 * @return {number}
 */
proto.Ticket.prototype.getKeyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.setKeyId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated TicketCustomFieldValue ticket_custom_field_values = 15;
 * @return {!Array<!proto.TicketCustomFieldValue>}
 */
proto.Ticket.prototype.getTicketCustomFieldValuesList = function() {
  return /** @type{!Array<!proto.TicketCustomFieldValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_ticket_custom_field_value_pb.TicketCustomFieldValue, 15));
};


/**
 * @param {!Array<!proto.TicketCustomFieldValue>} value
 * @return {!proto.Ticket} returns this
*/
proto.Ticket.prototype.setTicketCustomFieldValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.TicketCustomFieldValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TicketCustomFieldValue}
 */
proto.Ticket.prototype.addTicketCustomFieldValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.TicketCustomFieldValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.clearTicketCustomFieldValuesList = function() {
  return this.setTicketCustomFieldValuesList([]);
};


/**
 * optional bool is_manual = 16;
 * @return {boolean}
 */
proto.Ticket.prototype.getIsManual = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.setIsManual = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string note = 17;
 * @return {string}
 */
proto.Ticket.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional google.protobuf.Timestamp deleted_at = 18;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Ticket.prototype.getDeletedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 18));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Ticket} returns this
*/
proto.Ticket.prototype.setDeletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.clearDeletedAt = function() {
  return this.setDeletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Ticket.prototype.hasDeletedAt = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional bool faq_created = 19;
 * @return {boolean}
 */
proto.Ticket.prototype.getFaqCreated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.setFaqCreated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional string faq_creator_user_id = 20;
 * @return {string}
 */
proto.Ticket.prototype.getFaqCreatorUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.setFaqCreatorUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string faq_status = 21;
 * @return {string}
 */
proto.Ticket.prototype.getFaqStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.setFaqStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string requester_type = 22;
 * @return {string}
 */
proto.Ticket.prototype.getRequesterType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.Ticket} returns this
 */
proto.Ticket.prototype.setRequesterType = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    channelId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketChannel}
 */
proto.TicketChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketChannel;
  return proto.TicketChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketChannel}
 */
proto.TicketChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.TicketChannel.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketChannel} returns this
 */
proto.TicketChannel.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string channel_id = 2;
 * @return {string}
 */
proto.TicketChannel.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketChannel} returns this
 */
proto.TicketChannel.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetTicketRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetTicketRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetTicketRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetTicketRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetTicketRequest}
 */
proto.GetTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetTicketRequest;
  return proto.GetTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetTicketRequest}
 */
proto.GetTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.GetTicketRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GetTicketRequest} returns this
 */
proto.GetTicketRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetTicketResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetTicketResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetTicketResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetTicketResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticket: (f = msg.getTicket()) && proto.Ticket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetTicketResponse}
 */
proto.GetTicketResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetTicketResponse;
  return proto.GetTicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetTicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetTicketResponse}
 */
proto.GetTicketResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Ticket;
      reader.readMessage(value,proto.Ticket.deserializeBinaryFromReader);
      msg.setTicket(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetTicketResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetTicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetTicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetTicketResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicket();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Ticket.serializeBinaryToWriter
    );
  }
};


/**
 * optional Ticket ticket = 1;
 * @return {?proto.Ticket}
 */
proto.GetTicketResponse.prototype.getTicket = function() {
  return /** @type{?proto.Ticket} */ (
    jspb.Message.getWrapperField(this, proto.Ticket, 1));
};


/**
 * @param {?proto.Ticket|undefined} value
 * @return {!proto.GetTicketResponse} returns this
*/
proto.GetTicketResponse.prototype.setTicket = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetTicketResponse} returns this
 */
proto.GetTicketResponse.prototype.clearTicket = function() {
  return this.setTicket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetTicketResponse.prototype.hasTicket = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ListTicketsRequest.repeatedFields_ = [3,4,7,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListTicketsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ListTicketsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListTicketsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTicketsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    statusesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    assignedUserIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    createdAtStart: (f = msg.getCreatedAtStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAtEnd: (f = msg.getCreatedAtEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    ticketCustomFieldFilterList: jspb.Message.toObjectList(msg.getTicketCustomFieldFilterList(),
    proto_ticket_custom_field_value_pb.TicketCustomFieldOneOfFilter.toObject, includeInstance),
    sort: (f = msg.getSort()) && proto.TicketOneOfSort.toObject(includeInstance, f),
    requesterUserIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    subject: jspb.Message.getFieldWithDefault(msg, 10, ""),
    search: jspb.Message.getFieldWithDefault(msg, 11, ""),
    keyId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    faqstatus: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListTicketsRequest}
 */
proto.ListTicketsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListTicketsRequest;
  return proto.ListTicketsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListTicketsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListTicketsRequest}
 */
proto.ListTicketsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addStatuses(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssignedUserIds(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAtStart(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAtEnd(value);
      break;
    case 7:
      var value = new proto_ticket_custom_field_value_pb.TicketCustomFieldOneOfFilter;
      reader.readMessage(value,proto_ticket_custom_field_value_pb.TicketCustomFieldOneOfFilter.deserializeBinaryFromReader);
      msg.addTicketCustomFieldFilter(value);
      break;
    case 8:
      var value = new proto.TicketOneOfSort;
      reader.readMessage(value,proto.TicketOneOfSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addRequesterUserIds(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setKeyId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaqstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListTicketsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListTicketsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListTicketsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTicketsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getAssignedUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getCreatedAtStart();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAtEnd();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTicketCustomFieldFilterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto_ticket_custom_field_value_pb.TicketCustomFieldOneOfFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.TicketOneOfSort.serializeBinaryToWriter
    );
  }
  f = message.getRequesterUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getKeyId();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getFaqstatus();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional uint32 limit = 1;
 * @return {number}
 */
proto.ListTicketsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 offset = 2;
 * @return {number}
 */
proto.ListTicketsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string statuses = 3;
 * @return {!Array<string>}
 */
proto.ListTicketsRequest.prototype.getStatusesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * repeated string assigned_user_ids = 4;
 * @return {!Array<string>}
 */
proto.ListTicketsRequest.prototype.getAssignedUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.setAssignedUserIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.addAssignedUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.clearAssignedUserIdsList = function() {
  return this.setAssignedUserIdsList([]);
};


/**
 * optional google.protobuf.Timestamp created_at_start = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ListTicketsRequest.prototype.getCreatedAtStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ListTicketsRequest} returns this
*/
proto.ListTicketsRequest.prototype.setCreatedAtStart = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.clearCreatedAtStart = function() {
  return this.setCreatedAtStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTicketsRequest.prototype.hasCreatedAtStart = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp created_at_end = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ListTicketsRequest.prototype.getCreatedAtEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ListTicketsRequest} returns this
*/
proto.ListTicketsRequest.prototype.setCreatedAtEnd = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.clearCreatedAtEnd = function() {
  return this.setCreatedAtEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTicketsRequest.prototype.hasCreatedAtEnd = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated TicketCustomFieldOneOfFilter ticket_custom_field_filter = 7;
 * @return {!Array<!proto.TicketCustomFieldOneOfFilter>}
 */
proto.ListTicketsRequest.prototype.getTicketCustomFieldFilterList = function() {
  return /** @type{!Array<!proto.TicketCustomFieldOneOfFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_ticket_custom_field_value_pb.TicketCustomFieldOneOfFilter, 7));
};


/**
 * @param {!Array<!proto.TicketCustomFieldOneOfFilter>} value
 * @return {!proto.ListTicketsRequest} returns this
*/
proto.ListTicketsRequest.prototype.setTicketCustomFieldFilterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.TicketCustomFieldOneOfFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TicketCustomFieldOneOfFilter}
 */
proto.ListTicketsRequest.prototype.addTicketCustomFieldFilter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.TicketCustomFieldOneOfFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.clearTicketCustomFieldFilterList = function() {
  return this.setTicketCustomFieldFilterList([]);
};


/**
 * optional TicketOneOfSort sort = 8;
 * @return {?proto.TicketOneOfSort}
 */
proto.ListTicketsRequest.prototype.getSort = function() {
  return /** @type{?proto.TicketOneOfSort} */ (
    jspb.Message.getWrapperField(this, proto.TicketOneOfSort, 8));
};


/**
 * @param {?proto.TicketOneOfSort|undefined} value
 * @return {!proto.ListTicketsRequest} returns this
*/
proto.ListTicketsRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTicketsRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string requester_user_ids = 9;
 * @return {!Array<string>}
 */
proto.ListTicketsRequest.prototype.getRequesterUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.setRequesterUserIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.addRequesterUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.clearRequesterUserIdsList = function() {
  return this.setRequesterUserIdsList([]);
};


/**
 * optional string subject = 10;
 * @return {string}
 */
proto.ListTicketsRequest.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string search = 11;
 * @return {string}
 */
proto.ListTicketsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional uint32 key_id = 12;
 * @return {number}
 */
proto.ListTicketsRequest.prototype.getKeyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.setKeyId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string faqStatus = 13;
 * @return {string}
 */
proto.ListTicketsRequest.prototype.getFaqstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.ListTicketsRequest} returns this
 */
proto.ListTicketsRequest.prototype.setFaqstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ListTicketsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListTicketsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ListTicketsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListTicketsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTicketsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketsList: jspb.Message.toObjectList(msg.getTicketsList(),
    proto.Ticket.toObject, includeInstance),
    totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListTicketsResponse}
 */
proto.ListTicketsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListTicketsResponse;
  return proto.ListTicketsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListTicketsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListTicketsResponse}
 */
proto.ListTicketsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Ticket;
      reader.readMessage(value,proto.Ticket.deserializeBinaryFromReader);
      msg.addTickets(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListTicketsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListTicketsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListTicketsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTicketsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Ticket.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * repeated Ticket tickets = 1;
 * @return {!Array<!proto.Ticket>}
 */
proto.ListTicketsResponse.prototype.getTicketsList = function() {
  return /** @type{!Array<!proto.Ticket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Ticket, 1));
};


/**
 * @param {!Array<!proto.Ticket>} value
 * @return {!proto.ListTicketsResponse} returns this
*/
proto.ListTicketsResponse.prototype.setTicketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Ticket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Ticket}
 */
proto.ListTicketsResponse.prototype.addTickets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Ticket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListTicketsResponse} returns this
 */
proto.ListTicketsResponse.prototype.clearTicketsList = function() {
  return this.setTicketsList([]);
};


/**
 * optional uint32 total_count = 2;
 * @return {number}
 */
proto.ListTicketsResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ListTicketsResponse} returns this
 */
proto.ListTicketsResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateTicketRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateTicketRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateTicketRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTicketRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticket: (f = msg.getTicket()) && proto.Ticket.toObject(includeInstance, f),
    updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f),
    force: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateTicketRequest}
 */
proto.UpdateTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateTicketRequest;
  return proto.UpdateTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateTicketRequest}
 */
proto.UpdateTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Ticket;
      reader.readMessage(value,proto.Ticket.deserializeBinaryFromReader);
      msg.setTicket(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForce(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicket();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Ticket.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
  f = message.getForce();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional Ticket ticket = 1;
 * @return {?proto.Ticket}
 */
proto.UpdateTicketRequest.prototype.getTicket = function() {
  return /** @type{?proto.Ticket} */ (
    jspb.Message.getWrapperField(this, proto.Ticket, 1));
};


/**
 * @param {?proto.Ticket|undefined} value
 * @return {!proto.UpdateTicketRequest} returns this
*/
proto.UpdateTicketRequest.prototype.setTicket = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateTicketRequest} returns this
 */
proto.UpdateTicketRequest.prototype.clearTicket = function() {
  return this.setTicket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateTicketRequest.prototype.hasTicket = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.UpdateTicketRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.UpdateTicketRequest} returns this
*/
proto.UpdateTicketRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateTicketRequest} returns this
 */
proto.UpdateTicketRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateTicketRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool force = 3;
 * @return {boolean}
 */
proto.UpdateTicketRequest.prototype.getForce = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateTicketRequest} returns this
 */
proto.UpdateTicketRequest.prototype.setForce = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.TicketOneOfSort.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.TicketOneOfSort.ValuesCase = {
  VALUES_NOT_SET: 0,
  KEY_ID: 1,
  STATUS: 2,
  CREATED_AT: 3,
  RECEIVED_AT: 4,
  SENT_AT: 5,
  TICKET_CUSTOM_FIELD_SORT: 6
};

/**
 * @return {proto.TicketOneOfSort.ValuesCase}
 */
proto.TicketOneOfSort.prototype.getValuesCase = function() {
  return /** @type {proto.TicketOneOfSort.ValuesCase} */(jspb.Message.computeOneofCase(this, proto.TicketOneOfSort.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketOneOfSort.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketOneOfSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketOneOfSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketOneOfSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    receivedAt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sentAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ticketCustomFieldSort: (f = msg.getTicketCustomFieldSort()) && proto.TicketCustomFieldOneOfSort.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketOneOfSort}
 */
proto.TicketOneOfSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketOneOfSort;
  return proto.TicketOneOfSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketOneOfSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketOneOfSort}
 */
proto.TicketOneOfSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceivedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSentAt(value);
      break;
    case 6:
      var value = new proto.TicketCustomFieldOneOfSort;
      reader.readMessage(value,proto.TicketCustomFieldOneOfSort.deserializeBinaryFromReader);
      msg.setTicketCustomFieldSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketOneOfSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketOneOfSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketOneOfSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketOneOfSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTicketCustomFieldSort();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.TicketCustomFieldOneOfSort.serializeBinaryToWriter
    );
  }
};


/**
 * optional string key_id = 1;
 * @return {string}
 */
proto.TicketOneOfSort.prototype.getKeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketOneOfSort} returns this
 */
proto.TicketOneOfSort.prototype.setKeyId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.TicketOneOfSort.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TicketOneOfSort} returns this
 */
proto.TicketOneOfSort.prototype.clearKeyId = function() {
  return jspb.Message.setOneofField(this, 1, proto.TicketOneOfSort.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketOneOfSort.prototype.hasKeyId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.TicketOneOfSort.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketOneOfSort} returns this
 */
proto.TicketOneOfSort.prototype.setStatus = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.TicketOneOfSort.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TicketOneOfSort} returns this
 */
proto.TicketOneOfSort.prototype.clearStatus = function() {
  return jspb.Message.setOneofField(this, 2, proto.TicketOneOfSort.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketOneOfSort.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string created_at = 3;
 * @return {string}
 */
proto.TicketOneOfSort.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketOneOfSort} returns this
 */
proto.TicketOneOfSort.prototype.setCreatedAt = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.TicketOneOfSort.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TicketOneOfSort} returns this
 */
proto.TicketOneOfSort.prototype.clearCreatedAt = function() {
  return jspb.Message.setOneofField(this, 3, proto.TicketOneOfSort.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketOneOfSort.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string received_at = 4;
 * @return {string}
 */
proto.TicketOneOfSort.prototype.getReceivedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketOneOfSort} returns this
 */
proto.TicketOneOfSort.prototype.setReceivedAt = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.TicketOneOfSort.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TicketOneOfSort} returns this
 */
proto.TicketOneOfSort.prototype.clearReceivedAt = function() {
  return jspb.Message.setOneofField(this, 4, proto.TicketOneOfSort.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketOneOfSort.prototype.hasReceivedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string sent_at = 5;
 * @return {string}
 */
proto.TicketOneOfSort.prototype.getSentAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketOneOfSort} returns this
 */
proto.TicketOneOfSort.prototype.setSentAt = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.TicketOneOfSort.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TicketOneOfSort} returns this
 */
proto.TicketOneOfSort.prototype.clearSentAt = function() {
  return jspb.Message.setOneofField(this, 5, proto.TicketOneOfSort.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketOneOfSort.prototype.hasSentAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional TicketCustomFieldOneOfSort ticket_custom_field_sort = 6;
 * @return {?proto.TicketCustomFieldOneOfSort}
 */
proto.TicketOneOfSort.prototype.getTicketCustomFieldSort = function() {
  return /** @type{?proto.TicketCustomFieldOneOfSort} */ (
    jspb.Message.getWrapperField(this, proto.TicketCustomFieldOneOfSort, 6));
};


/**
 * @param {?proto.TicketCustomFieldOneOfSort|undefined} value
 * @return {!proto.TicketOneOfSort} returns this
*/
proto.TicketOneOfSort.prototype.setTicketCustomFieldSort = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.TicketOneOfSort.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketOneOfSort} returns this
 */
proto.TicketOneOfSort.prototype.clearTicketCustomFieldSort = function() {
  return this.setTicketCustomFieldSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketOneOfSort.prototype.hasTicketCustomFieldSort = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.TicketCustomFieldOneOfSort.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.TicketCustomFieldOneOfSort.ValuesCase = {
  VALUES_NOT_SET: 0,
  NUMBER: 1,
  DATE: 2
};

/**
 * @return {proto.TicketCustomFieldOneOfSort.ValuesCase}
 */
proto.TicketCustomFieldOneOfSort.prototype.getValuesCase = function() {
  return /** @type {proto.TicketCustomFieldOneOfSort.ValuesCase} */(jspb.Message.computeOneofCase(this, proto.TicketCustomFieldOneOfSort.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketCustomFieldOneOfSort.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketCustomFieldOneOfSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketCustomFieldOneOfSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomFieldOneOfSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    number: (f = msg.getNumber()) && proto.TicketCustomNumberFieldSort.toObject(includeInstance, f),
    date: (f = msg.getDate()) && proto.TicketCustomDateFieldSort.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketCustomFieldOneOfSort}
 */
proto.TicketCustomFieldOneOfSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketCustomFieldOneOfSort;
  return proto.TicketCustomFieldOneOfSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketCustomFieldOneOfSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketCustomFieldOneOfSort}
 */
proto.TicketCustomFieldOneOfSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TicketCustomNumberFieldSort;
      reader.readMessage(value,proto.TicketCustomNumberFieldSort.deserializeBinaryFromReader);
      msg.setNumber(value);
      break;
    case 2:
      var value = new proto.TicketCustomDateFieldSort;
      reader.readMessage(value,proto.TicketCustomDateFieldSort.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketCustomFieldOneOfSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketCustomFieldOneOfSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketCustomFieldOneOfSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomFieldOneOfSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumber();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.TicketCustomNumberFieldSort.serializeBinaryToWriter
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.TicketCustomDateFieldSort.serializeBinaryToWriter
    );
  }
};


/**
 * optional TicketCustomNumberFieldSort number = 1;
 * @return {?proto.TicketCustomNumberFieldSort}
 */
proto.TicketCustomFieldOneOfSort.prototype.getNumber = function() {
  return /** @type{?proto.TicketCustomNumberFieldSort} */ (
    jspb.Message.getWrapperField(this, proto.TicketCustomNumberFieldSort, 1));
};


/**
 * @param {?proto.TicketCustomNumberFieldSort|undefined} value
 * @return {!proto.TicketCustomFieldOneOfSort} returns this
*/
proto.TicketCustomFieldOneOfSort.prototype.setNumber = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.TicketCustomFieldOneOfSort.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketCustomFieldOneOfSort} returns this
 */
proto.TicketCustomFieldOneOfSort.prototype.clearNumber = function() {
  return this.setNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomFieldOneOfSort.prototype.hasNumber = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TicketCustomDateFieldSort date = 2;
 * @return {?proto.TicketCustomDateFieldSort}
 */
proto.TicketCustomFieldOneOfSort.prototype.getDate = function() {
  return /** @type{?proto.TicketCustomDateFieldSort} */ (
    jspb.Message.getWrapperField(this, proto.TicketCustomDateFieldSort, 2));
};


/**
 * @param {?proto.TicketCustomDateFieldSort|undefined} value
 * @return {!proto.TicketCustomFieldOneOfSort} returns this
*/
proto.TicketCustomFieldOneOfSort.prototype.setDate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.TicketCustomFieldOneOfSort.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketCustomFieldOneOfSort} returns this
 */
proto.TicketCustomFieldOneOfSort.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomFieldOneOfSort.prototype.hasDate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketCustomNumberFieldSort.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketCustomNumberFieldSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketCustomNumberFieldSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomNumberFieldSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketCustomFieldId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stringValue: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketCustomNumberFieldSort}
 */
proto.TicketCustomNumberFieldSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketCustomNumberFieldSort;
  return proto.TicketCustomNumberFieldSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketCustomNumberFieldSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketCustomNumberFieldSort}
 */
proto.TicketCustomNumberFieldSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketCustomFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketCustomNumberFieldSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketCustomNumberFieldSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketCustomNumberFieldSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomNumberFieldSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketCustomFieldId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStringValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 ticket_custom_field_id = 1;
 * @return {number}
 */
proto.TicketCustomNumberFieldSort.prototype.getTicketCustomFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TicketCustomNumberFieldSort} returns this
 */
proto.TicketCustomNumberFieldSort.prototype.setTicketCustomFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string string_value = 2;
 * @return {string}
 */
proto.TicketCustomNumberFieldSort.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketCustomNumberFieldSort} returns this
 */
proto.TicketCustomNumberFieldSort.prototype.setStringValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketCustomDateFieldSort.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketCustomDateFieldSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketCustomDateFieldSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomDateFieldSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketCustomFieldId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stringValue: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketCustomDateFieldSort}
 */
proto.TicketCustomDateFieldSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketCustomDateFieldSort;
  return proto.TicketCustomDateFieldSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketCustomDateFieldSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketCustomDateFieldSort}
 */
proto.TicketCustomDateFieldSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketCustomFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketCustomDateFieldSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketCustomDateFieldSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketCustomDateFieldSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomDateFieldSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketCustomFieldId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStringValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 ticket_custom_field_id = 1;
 * @return {number}
 */
proto.TicketCustomDateFieldSort.prototype.getTicketCustomFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TicketCustomDateFieldSort} returns this
 */
proto.TicketCustomDateFieldSort.prototype.setTicketCustomFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string string_value = 2;
 * @return {string}
 */
proto.TicketCustomDateFieldSort.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketCustomDateFieldSort} returns this
 */
proto.TicketCustomDateFieldSort.prototype.setStringValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketErrorDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketErrorDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketErrorDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketErrorDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketErrorDetail}
 */
proto.TicketErrorDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketErrorDetail;
  return proto.TicketErrorDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketErrorDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketErrorDetail}
 */
proto.TicketErrorDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.TicketErrorCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketErrorDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketErrorDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketErrorDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketErrorDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional TicketErrorCode code = 1;
 * @return {!proto.TicketErrorCode}
 */
proto.TicketErrorDetail.prototype.getCode = function() {
  return /** @type {!proto.TicketErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.TicketErrorCode} value
 * @return {!proto.TicketErrorDetail} returns this
 */
proto.TicketErrorDetail.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.TicketErrorDetail.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketErrorDetail} returns this
 */
proto.TicketErrorDetail.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateManualTicketRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateManualTicketRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateManualTicketRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateManualTicketRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requesterUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateManualTicketRequest}
 */
proto.CreateManualTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateManualTicketRequest;
  return proto.CreateManualTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateManualTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateManualTicketRequest}
 */
proto.CreateManualTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequesterUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateManualTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateManualTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateManualTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateManualTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequesterUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string requester_user_id = 1;
 * @return {string}
 */
proto.CreateManualTicketRequest.prototype.getRequesterUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateManualTicketRequest} returns this
 */
proto.CreateManualTicketRequest.prototype.setRequesterUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subject = 2;
 * @return {string}
 */
proto.CreateManualTicketRequest.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateManualTicketRequest} returns this
 */
proto.CreateManualTicketRequest.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteManualTicketRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteManualTicketRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteManualTicketRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteManualTicketRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteManualTicketRequest}
 */
proto.DeleteManualTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteManualTicketRequest;
  return proto.DeleteManualTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteManualTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteManualTicketRequest}
 */
proto.DeleteManualTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteManualTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteManualTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteManualTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteManualTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.DeleteManualTicketRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeleteManualTicketRequest} returns this
 */
proto.DeleteManualTicketRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.TicketErrorCode = {
  TICKET_OPERATOR_CONFIRM_REQUIRED: 0
};

goog.object.extend(exports, proto);
