import * as React from 'react'

import RequesterChatFileList, {
  FileProp,
} from '../../../../components/RequesterChatFileList'

type Props = {
  files: FileProp[]
  onClickRemove: (id: string) => void
  className?: string
}
const AttachmentList: React.FC<Props> = (props) => {
  if (props.files.length <= 0) return <></>
  return (
    <RequesterChatFileList
      removable
      files={props.files}
      onClickRemove={props.onClickRemove}
      className={props.className}
    />
  )
}

export default AttachmentList

export type {
  FileProp,
  FilePropStatus,
} from '../../../../components/RequesterChatFileList'
