import { Dropdown, DropdownProps, Text } from '@fluentui/react-northstar'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { TicketCustomField } from '../../../proto/ticket_custom_field_pb'
import { updateTicketCustomFieldValue } from '../customFieldValueSlice'
import styles from '../TicketDetail.module.css'
import { useErrorHandler } from './useErrorHandler'

type Props = {
  ticketId: number
  field: TicketCustomField.AsObject
  fieldValue?: Array<string>
}

const TicketPropertyCustomMultipleSelectField: React.FC<Props> = ({
  ticketId,
  field,
  fieldValue,
}) => {
  const dispatch = useDispatch()
  const customFieldValuesState = useSelector(
    (state: RootState) => state.customFieldValue
  )
  const customFieldValueEntity = customFieldValuesState.entities[field.id]
  const hasValue = customFieldValueEntity != null
  const [selectedItems, setSelectedItems] = useState(fieldValue)
  useErrorHandler(customFieldValueEntity, field, dispatch, hasValue)
  return (
    <>
      <Text weight="bold" content={field.name} />
      {field.editable ? (
        <Dropdown
          multiple
          fluid
          placeholder={`${field.name}を選択`}
          error={hasValue && customFieldValuesState.entities[field.id]?.isError}
          className={styles.ticketCustomFieldSelectDropdown}
          items={field.optionsList.map((o) => o.value)}
          value={selectedItems ?? []}
          onChange={(_, data: DropdownProps) => {
            if (!field.editable) return
            const value = data.value as string[]
            setSelectedItems(value)
            dispatch(
              updateTicketCustomFieldValue(
                ticketId,
                field.id,
                field.type,
                value.map((v: string) => {
                  return { stringValue: v }
                })
              )
            )
          }}
        />
      ) : (
        <Text content={selectedItems ? selectedItems.join(', ') : '--'} />
      )}
    </>
  )
}

export default TicketPropertyCustomMultipleSelectField
