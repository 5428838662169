import { Box, ChevronDownIcon, List, Popup } from '@fluentui/react-northstar'
import React, { useEffect, useState } from 'react'

import styles from './RichStyleToolBarItem.module.css'

interface Props {
  onClick: (e: string) => void
  currentBlockType: string
  icon: boolean
}

const RichStyleToolBarItem: React.FC<Props> = (props: Props) => {
  const [index, setIndex] = useState<number>(3)
  const [open, setOpen] = useState<boolean>(false)
  useEffect(() => {
    const currentBlockType = props.currentBlockType
    const index = richTextItems.findIndex(
      (item) => item.key === currentBlockType
    )
    if (index >= 0) {
      setIndex(index)
    }
  }, [props.currentBlockType])
  return (
    <Popup
      open={open}
      onOpenChange={(_, d) => {
        if (d != null) {
          setOpen(d.open as boolean)
        }
      }}
      content={
        <List
          onMouseDown={(e: { preventDefault: () => void }) => {
            e.preventDefault()
          }}
          onSelectedIndexChange={(_, s) => {
            const index = s?.selectedIndex
            if (index == null) {
              return
            }
            setIndex(index)
            props.onClick(richTextItems[index].key)
            setOpen(false)
          }}
          className={styles.list}
          selectable={true}
          items={richTextItems}
        />
      }
      trigger={
        <Box className={styles.container}>
          <span className={styles.title}>{richTextItems[index].header}</span>
          {props.icon && <ChevronDownIcon size="smaller" />}
        </Box>
      }
    ></Popup>
  )
}

export const RichStyleToolBarContent = (props: {
  onClick: (e: string) => void
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIndex: React.Dispatch<React.SetStateAction<number>>
}): JSX.Element => {
  const { setOpen, setIndex } = props
  return (
    <List
      onMouseDown={(e: { preventDefault: () => void }) => {
        e.preventDefault()
      }}
      onSelectedIndexChange={(_, s) => {
        const index = s?.selectedIndex
        if (index == null) {
          return
        }
        setIndex(index)
        props.onClick(richTextItems[index].key)
        setOpen(false)
      }}
      className={styles.list}
      selectable={true}
      items={richTextItems}
    />
  )
}

export const richTextItems: { key: Draft.DraftBlockType; header: string }[] = [
  {
    key: 'header-one',
    header: '見出し1',
  },
  {
    key: 'header-two',
    header: '見出し2',
  },
  {
    key: 'header-three',
    header: '見出し3',
  },

  {
    key: 'unstyled',
    header: '段落',
  },
  {
    key: 'code-block',
    header: '等幅',
  },
]

export default RichStyleToolBarItem
