import { createSelector } from '@reduxjs/toolkit'
import map from 'lodash/fp/map'
import { compose } from 'redux'

import { RootState } from '../../../../app/store'
import type { FileProp } from '../../../../components/RequesterChatFileList'
import { Tickets } from '../../../../consts'
import { dateToTicketDateTime } from '../../lib/dateToTicketDateTime'
import { requesterTicketListApiDataSelector } from '../../selectors'
import { requesterTicketEntityAdapter } from '../../slice'
import { RequesterChatFile } from '../../types/RequesterTicketEntity'

export const time = createSelector(
  [requesterTicketListApiDataSelector, (_, id: string) => id],
  (ticketEntity, id): string => {
    if (!ticketEntity) return ''
    return compose(
      dateToTicketDateTime,
      (str: string) => new Date(str),
      (ticket) => ticket?.createdAt ?? '',
      requesterTicketEntityAdapter.getSelectors().selectById
    )(ticketEntity, id)
  }
)

export const authorId = createSelector(
  [requesterTicketListApiDataSelector, (_, id: string) => id],
  (ticketEntity, id): string => {
    if (!ticketEntity) return ''
    return compose(
      (ticket) => ticket?.authorId ?? '',
      requesterTicketEntityAdapter.getSelectors().selectById
    )(ticketEntity, id)
  }
)

export const replyCount = createSelector(
  [requesterTicketListApiDataSelector, (_, id: string) => id],
  (ticketEntity, id): number => {
    if (!ticketEntity) return 0
    return compose(
      // 最新の返信を表示するためそれを差し引いて-1しておく
      (count: number) => (count >= 1 ? count - 1 : 0),
      (ticket) => ticket?.replyCount ?? 0,
      requesterTicketEntityAdapter.getSelectors().selectById
    )(ticketEntity, id)
  }
)

export const fileProps = createSelector(
  [requesterTicketListApiDataSelector, (_, id: string) => id],
  (ticketEntity, id): FileProp[] => {
    if (!ticketEntity) return []
    return compose(
      map<RequesterChatFile, FileProp>((f) => ({
        id: f.id,
        url: f.url,
        fileName: f.name,
        mimeType: f.mimeType,
        status: 'done',
      })),
      (ticket) => ticket?.files ?? [],
      requesterTicketEntityAdapter.getSelectors().selectById
    )(ticketEntity, id)
  }
)

export const showLastReply = createSelector(
  [requesterTicketListApiDataSelector, (_, id: string) => id],
  (ticketEntity, id): boolean => {
    if (!ticketEntity) return false
    const ticket = requesterTicketEntityAdapter
      .getSelectors()
      .selectById(ticketEntity, id)
    if (!ticket || !ticket.lastReplyMessage) return false
    return true
  }
)

export const hasNewMessage = createSelector(
  [(state: RootState) => state.requesterTicketList, (_, id: string) => id],
  (state, id): boolean => {
    const s = state.ticketOrders.find((t) => t.id === id)
    return s?.hasNewMessage === true
  }
)

export const isNewlyCreated = createSelector(
  [(state: RootState) => state.requesterTicketList, (_, id: string) => id],
  (state, id): boolean => {
    return state.ticketOrders.find((t) => t.id === id)?.isNewlyCreated === true
  }
)

export const isRequesterCreatedTicket = createSelector(
  [requesterTicketListApiDataSelector, (_, id: string) => id],
  (ticketEntity, id): boolean => {
    if (!ticketEntity) return false
    return compose(
      (ticket) => ticket?.senderType === Tickets.Messages.SenderTypeRequester,
      requesterTicketEntityAdapter.getSelectors().selectById
    )(ticketEntity, id)
  }
)

export const showTicketReplies = createSelector(
  [(state: RootState) => state.requesterTicketList, (_, id: string) => id],
  (state, id): boolean => {
    return state.showRepliesTicketIds.some((_id) => _id === id)
  }
)
