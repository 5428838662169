import { Text } from '@fluentui/react-northstar'
import React, { useState } from 'react'

interface Props {
  onClick: () => void
  initializing: boolean
}

const TicketSelfAssignButton: React.FC<Props> = ({ onClick, initializing }) => {
  const [selfAssignButtonStyle, setSelfAssignButtonStyle] = useState({
    color: '#6264a7',
    textDecolation: '',
  })

  if (initializing === true) {
    return (
      <>
        <Text weight="bold" content="自分に割り当てる" />
      </>
    )
  }

  return (
    <>
      <Text
        weight="bold"
        styles={{
          color: selfAssignButtonStyle.color,
          cursor: 'pointer',
          textDecoration: selfAssignButtonStyle.textDecolation,
        }}
        onMouseOver={() =>
          setSelfAssignButtonStyle({
            color: '#8B8CC7',
            textDecolation: 'underline',
          })
        }
        onMouseOut={() =>
          setSelfAssignButtonStyle({
            color: '#6264a7',
            textDecolation: '',
          })
        }
        onClick={() => onClick()}
        content="自分に割り当てる"
      />
    </>
  )
}

export default TicketSelfAssignButton
