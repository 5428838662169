import { FilterIcon } from '@fluentui/react-icons-mdl2'
import { Button } from '@fluentui/react-northstar'
import React from 'react'

import { CustomFieldType } from '../../../consts'
import { TicketCustomField } from '../../../proto/ticket_custom_field_pb'
import { CustomFieldValueFilter } from '../ticketSlice'
import styles from './TicketCustomFieldFilterButton.module.css'

type Props = {
  customField?: TicketCustomField.AsObject
  customFieldValueFilters: CustomFieldValueFilter
}
const TicketCustomFieldFilterButton: React.FC<Props> = ({
  customField,
  customFieldValueFilters,
}) => {
  const isFiltered = () => {
    switch (customField?.type) {
      case CustomFieldType.select:
      case CustomFieldType.multiselect:
        return (
          customFieldValueFilters.selectFilters[String(customField.id)] != null
        )
      case CustomFieldType.text:
        return (
          customFieldValueFilters.textFilters[String(customField.id)] != null
        )
      case CustomFieldType.textarea:
        return (
          customFieldValueFilters.textAreaFilters[String(customField.id)] !=
          null
        )
      case CustomFieldType.date:
        return (
          customFieldValueFilters.dateFilters[String(customField.id)] != null
        )
      case CustomFieldType.number:
        return (
          customFieldValueFilters.numberFilters[String(customField.id)] != null
        )
      case CustomFieldType.checkbox:
        return (
          customFieldValueFilters.checkBoxFilters[String(customField.id)] !=
          null
        )
      default:
        return false
    }
  }
  return (
    <Button
      text
      iconOnly
      className={styles.columnOptionIcon}
      styles={({ theme: { siteVariables } }) => ({
        color: isFiltered()
          ? siteVariables.colorScheme.red.foreground
          : siteVariables.naturalColors.grey[350],
      })}
      icon={<FilterIcon />}
    />
  )
}

export default TicketCustomFieldFilterButton
