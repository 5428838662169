import { AadUserConversationMember } from '@microsoft/microsoft-graph-types'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import { RootState } from '../../app/store'
import {
  getCompactUserNamesText,
  getUserNamesFromMembers,
} from '../../components/CompactUserNames'
import * as DashboardFilterSidepanelSelectors from '../DashboardFilterSidepanel/selectors'
import { actions as DashboardFilterSidepanelActions } from '../DashboardFilterSidepanel/slice'
import DashboardHeaderPresenter from './presenter'

const DashboardHeader: React.FC = () => {
  const dispatch = ReactRedux.useDispatch()

  const isFilterOpen = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.isOpen
  )

  const openFilter = React.useCallback(() => {
    dispatch(DashboardFilterSidepanelActions.open())
  }, [dispatch])

  const filterDateRange = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.dateRangeString
  )

  const assigneeUserIds = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.assigneeUserIds
  )
  const memberState = ReactRedux.useSelector((state: RootState) => state.member)
  const members = memberState.ids.map(
    (id) => memberState.entities[id] as AadUserConversationMember
  )
  const userNames = getUserNamesFromMembers(assigneeUserIds, members)
  const assigneeUserNames = getCompactUserNamesText(userNames)

  const filterBadges = React.useMemo(() => {
    const filterBadges = [
      {
        text: `表示範囲：${filterDateRange}`,
      },
    ]

    if (assigneeUserIds.length > 0) {
      filterBadges.push({
        text: `担当者：${assigneeUserNames}`,
      })
    }

    return filterBadges
  }, [filterDateRange, assigneeUserIds, assigneeUserNames])

  return (
    <DashboardHeaderPresenter
      isFilterButtonPrimary={isFilterOpen}
      filterBadges={filterBadges}
      onClickFilterButton={openFilter}
    />
  )
}

export default DashboardHeader
