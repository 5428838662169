import { FontSizeIcon, List, Popup } from '@fluentui/react-northstar'
import React from 'react'
import { useState } from 'react'

import styles from './FontSizeIconToolBarItem.module.css'

const items = [
  {
    key: 'large',
    header: '大',
  },
  {
    key: 'medium',
    header: '中',
  },
  {
    key: 'small',
    header: '小',
  },
]
interface Props {
  onClick: (size: string) => void
  holdSelection: () => void
}

const onItemMouseDown = (
  fontSize: string,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  onClick: (e: string) => void
) => {
  onClick(fontSize)
  setOpen(false)
}

export const FontSizeToolBarContent = (props: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClick: (size: string) => void
}): JSX.Element => {
  const { setOpen, onClick } = props

  return (
    <List
      onMouseDown={(e: { preventDefault: () => void }) => {
        e.preventDefault()
      }}
      onSelectedIndexChange={(_, s) => {
        if (s?.selectedIndex === 0) {
          onItemMouseDown('largeFont', setOpen, onClick)
          return
        }

        if (s?.selectedIndex === 1) {
          onItemMouseDown('mediumFont', setOpen, onClick)
          return
        }

        if (s?.selectedIndex === 2) {
          onItemMouseDown('smallFont', setOpen, onClick)
          return
        }
      }}
      className={styles.list}
      selectable={true}
      items={items}
    />
  )
}

const FontSizeToolBarItem: React.FC<Props> = ({
  onClick,
  holdSelection,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <Popup
      open={open}
      onOpenChange={(_, d) => {
        holdSelection()
        if (d != null) {
          setOpen(d.open as boolean)
        }
      }}
      content={<FontSizeToolBarContent onClick={onClick} setOpen={setOpen} />}
      trigger={<FontSizeIcon outline={true} />}
    ></Popup>
  )
}

export default FontSizeToolBarItem
