import { grpc } from '@improbable-eng/grpc-web'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import {
  FaqLabelSelector,
  getFaqLabels,
} from '../../../../features/faq/faqLabelSlice'
import {
  FaqStateError,
  extractFaqError,
} from '../../../../features/faq/faqSlice'
import { GRPCErrorResponseObject } from '../../../deskApiCommon/common'
import { useCreateFaqMutation } from '../../../deskApiOperator'
import RecommendedFaqRedundantIntentList from '../../../RecommendedFaqRedundantIntentList'
import { useSkipRecommendedFaqMutation } from '../../api'
import { RecommendedFaqEntity } from '../../types/RecommendedFaqEntity'
import RecommendedFaqItemContentPresenter, {
  RecommendedFaqButtonsPresenter,
  RecommendedFaqPresenter,
} from './presenter'

type Props = {
  recommendedFaqItem: RecommendedFaqEntity
}
const RecommendedFaqItemContent: React.FC<Props> = (props) => {
  const dispatch = ReactRedux.useDispatch()
  const [request, setRequest] = React.useState(props.recommendedFaqItem.request)
  const [response, setResponse] = React.useState(
    props.recommendedFaqItem.response
  )
  const [publish, setPublish] = React.useState(false)
  const [intentName, seIntentName] = React.useState('')
  const [labels, setLabels] = React.useState<string[]>([])
  const [error, setError] = React.useState<FaqStateError | null>(null)
  const registeredFaqLabels = ReactRedux.useSelector(FaqLabelSelector.selectAll)
  const [skipRecommendedFaq, { isLoading: isLoadingSkipRecommendFaq }] =
    useSkipRecommendedFaqMutation()
  const [createFaq, { isLoading: isLoadingCreateFaq }] = useCreateFaqMutation()

  // FIXME: faqLabel取得ロジックは旧実装から流用しており別途リファクタリングする必要がある
  React.useEffect(() => {
    dispatch(getFaqLabels())
  }, [dispatch])

  const handleCreateFaq = React.useCallback(() => {
    return createFaq({
      ticketId: props.recommendedFaqItem.representativeTicketId,
      request: request,
      response: response,
      name: intentName,
      labelsList: labels.map((it) => parseInt(it, 10)),
      publish: publish,
      from: 'recommended_faq',
    })
      .unwrap()
      .catch((e: GRPCErrorResponseObject) => {
        if (e.code !== grpc.Code.OK) {
          const errors = extractFaqError(e.trailers)
          if (errors.length > 0) {
            const err = errors[0]
            setError({
              responseCode: e.code,
              errorCode: err.code,
              message: err.message,
              param: err.param,
            })
          } else {
            setError({
              responseCode: e.code,
            })
          }
        }
      })
  }, [props, request, response, intentName, labels, publish, createFaq])

  const renderButtons = React.useCallback(() => {
    return (
      <RecommendedFaqButtonsPresenter
        isLoadingSkipRecommendFaq={isLoadingSkipRecommendFaq}
        isLoadingCreateFaq={isLoadingCreateFaq}
        onSkipClick={() => skipRecommendedFaq(props.recommendedFaqItem)}
        onCreateClick={handleCreateFaq}
      />
    )
  }, [
    props,
    isLoadingSkipRecommendFaq,
    isLoadingCreateFaq,
    skipRecommendedFaq,
    handleCreateFaq,
  ])

  const renderFaq = React.useCallback(() => {
    return (
      <RecommendedFaqPresenter
        request={request}
        setRequest={setRequest}
        response={response}
        setResponse={setResponse}
        intentName={intentName}
        seIntentName={seIntentName}
        labels={labels}
        setLabels={setLabels}
        publish={publish}
        setPublish={setPublish}
        registeredFaqLabels={registeredFaqLabels}
        error={error}
      />
    )
  }, [
    request,
    response,
    intentName,
    labels,
    publish,
    registeredFaqLabels,
    error,
  ])

  const renderRedundantIntents = React.useCallback(() => {
    const recommendedFaqId = Number(props.recommendedFaqItem.id)
    if (!recommendedFaqId) {
      return <></>
    }

    return (
      <RecommendedFaqRedundantIntentList recommendedFaqId={recommendedFaqId} />
    )
  }, [props.recommendedFaqItem.id])

  return (
    <RecommendedFaqItemContentPresenter
      renderFaq={renderFaq}
      renderRedundantIntents={renderRedundantIntents}
      renderButtons={renderButtons}
      relatedTicketCount={props.recommendedFaqItem.relatedTicketCount}
    />
  )
}

export default RecommendedFaqItemContent
