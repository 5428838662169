import * as React from 'react'
import { useParams } from 'react-router-dom'

import { ActivityMessagePlanStatus } from '../../renewfeatures/ActivityMessagePlanList/types/ActivityMessagePlanEntity'
import ActivityMessagePlanPreviewBody from '../../renewfeatures/ActivityMessagePlanPreviewBody'
import { usePlanDetailQuery } from '../../renewfeatures/ActivityMessagePlanPreviewBody/api'
import ActivityMessagePlanPreviewReceiverList from '../../renewfeatures/ActivityMessagePlanPreviewReceiverList'
import ActivityMessagePlanPreviewSidebar from '../../renewfeatures/ActivityMessagePlanPreviewSidebar'
import ActivityMessagePlanPreviewPresenter from './presenter'

const ActivityMessagePlanPreview = () => {
  const params = useParams<{ id: string }>()
  const planDetailQuery = usePlanDetailQuery({
    id: Number(params.id),
  })

  const isShowBackButton = React.useMemo<boolean>(() => {
    if (!planDetailQuery.data) return false
    return planDetailQuery.data.status === ActivityMessagePlanStatus.Draft
  }, [planDetailQuery])

  return (
    <ActivityMessagePlanPreviewPresenter
      id={params.id}
      isShowBackButton={isShowBackButton}
      renderPreviewBody={() => (
        <ActivityMessagePlanPreviewBody id={params.id} />
      )}
      renderPreviewSidebar={() => (
        <ActivityMessagePlanPreviewSidebar id={params.id} />
      )}
      renderReceiverList={() => <ActivityMessagePlanPreviewReceiverList />}
    />
  )
}

export default ActivityMessagePlanPreview
