import { Label } from '@fluentui/react-northstar'
import React from 'react'

import {
  TicketStatus,
  TicketStatusColors,
  TicketStatusLabels,
} from '../../consts'
import styles from './TicketProperty.module.css'

type Props = {
  ticketStatus: TicketStatus
}

const TicketStatusLabel: React.FC<Props> = ({ ticketStatus }) => {
  return (
    <Label
      className={styles.statusLabel}
      styles={({ theme: { siteVariables } }) => {
        // [TicketStatus.pending]: 'naturalColors.grey.400'から
        // siteVariables[naturalColors][grey][400] を構築してカラーコード取得
        const arr = TicketStatusColors[ticketStatus].split('.')
        const t = arr.reduce((a, b) => {
          return a?.[b]
        }, siteVariables)
        return {
          background: t,
          color:
            ticketStatus === TicketStatus.completed
              ? siteVariables.primitiveColors.black
              : siteVariables.primitiveColors.white,
        }
      }}
      content={TicketStatusLabels[ticketStatus]}
    />
  )
}

export default TicketStatusLabel
