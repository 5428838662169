import { Flex, Loader } from '@fluentui/react-northstar'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import styles from './Auth.module.css'
import { consentClose } from './authSlice'

const AuthClose: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(consentClose())
  }, [dispatch])

  return (
    <Flex
      column
      className={styles.container}
      styles={({ theme: { siteVariables } }) => ({
        backgroundColor: siteVariables.colorScheme.default.background2,
      })}
    >
      <Flex gap="gap.small">
        <h1>同意の確認が完了しました</h1>
        <Loader size="small" />
      </Flex>
      <div>自動的に画面が切り替わりますので、しばらくお待ち下さい。</div>
    </Flex>
  )
}

export default AuthClose
