import { Text } from '@fluentui/react-northstar'
import { AadUserConversationMember } from '@microsoft/microsoft-graph-types'
import React from 'react'

import { Tickets } from '../consts'
import { ellipsis } from '../utils'

type Props = {
  userNames: string[]
}

export const getUserNamesFromMembers = (
  assigneeUserIds: string[],
  members: AadUserConversationMember[]
): string[] => {
  return assigneeUserIds.reduce((displayNames: string[], userId) => {
    if (userId === '') {
      displayNames.push(Tickets.Users.UserNotIndicated)
      return displayNames
    }
    members.map((m) => {
      if (userId === m.userId && m.displayName != null) {
        displayNames.push(ellipsis(m.displayName, 14))
      }
    })
    return displayNames
  }, [])
}

export const getCompactUserNamesText = (userNames: string[]): string => {
  let text = ''
  if (userNames.includes(Tickets.Users.UserNotIndicated)) {
    const index = userNames.indexOf(Tickets.Users.UserNotIndicated)
    userNames.splice(index, 1)
    userNames.unshift(Tickets.Users.UserNotIndicated)
  }
  // 3人以上の場合は、最初の3人と残りの人数を表示する
  if (userNames.length > 3) {
    text = `${userNames.slice(0, 3).join('・')} 他${userNames.length - 3}人`
  } else {
    text = userNames.join('・')
  }
  return text
}

const CompactUserNames: React.FC<Props> = ({ userNames }) => {
  return <Text content={getCompactUserNamesText(userNames)} />
}

export default CompactUserNames
