import { createSlice } from '@reduxjs/toolkit'

import * as Operations from './operations'

// `conversationId` | `conversationId-replyId`
export type MessageKey = `${string}` | `${string}-${string}`

/**
 * メッセージを加工し、Conversation, ReplyのIDをキーにして保存する
 */
export type State = {
  [key in MessageKey]: string
}
const initialState: State = {}
const slice = createSlice({
  name: 'requesterTicketList/requesterChatText',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Operations.initMessage.fulfilled, (state, action) => {
      state[action.payload.key] = action.payload.message
    })
  },
})

export const { actions, reducer } = slice
