// package: 
// file: proto/recommended_faq.proto

var proto_recommended_faq_pb = require("../proto/recommended_faq_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var RecommendedFaqAPI = (function () {
  function RecommendedFaqAPI() {}
  RecommendedFaqAPI.serviceName = "RecommendedFaqAPI";
  return RecommendedFaqAPI;
}());

RecommendedFaqAPI.ListRecommendedFaqs = {
  methodName: "ListRecommendedFaqs",
  service: RecommendedFaqAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_recommended_faq_pb.ListRecommendedFaqsRequest,
  responseType: proto_recommended_faq_pb.ListRecommendedFaqsResponse
};

RecommendedFaqAPI.ListRecommendedFaqRedundantIntents = {
  methodName: "ListRecommendedFaqRedundantIntents",
  service: RecommendedFaqAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_recommended_faq_pb.ListRecommendedFaqRedundantIntentsRequest,
  responseType: proto_recommended_faq_pb.ListRecommendedFaqRedundantIntentsResponse
};

RecommendedFaqAPI.UpdateRecommendedFaq = {
  methodName: "UpdateRecommendedFaq",
  service: RecommendedFaqAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_recommended_faq_pb.UpdateRecommendedFaqRequest,
  responseType: proto_recommended_faq_pb.UpdateRecommendedFaqResponse
};

exports.RecommendedFaqAPI = RecommendedFaqAPI;

function RecommendedFaqAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

RecommendedFaqAPIClient.prototype.listRecommendedFaqs = function listRecommendedFaqs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RecommendedFaqAPI.ListRecommendedFaqs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RecommendedFaqAPIClient.prototype.listRecommendedFaqRedundantIntents = function listRecommendedFaqRedundantIntents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RecommendedFaqAPI.ListRecommendedFaqRedundantIntents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RecommendedFaqAPIClient.prototype.updateRecommendedFaq = function updateRecommendedFaq(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RecommendedFaqAPI.UpdateRecommendedFaq, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.RecommendedFaqAPIClient = RecommendedFaqAPIClient;

