import {
  Button,
  RadioGroup,
  RadioGroupItemProps,
  Text,
  TextArea,
  TextAreaProps,
} from '@fluentui/react-northstar'
import * as React from 'react'

import { assertNever } from '../../utils'
import FileInput from '../FileInput'
import RequesterChatFileList, { FileProp } from '../RequesterChatFileList'
import styles from './styles.module.css'

export enum RadioKeys {
  All,
  UserIDs,
}

export type FormDataReceiverType = 'all' | 'userIDs'

export type FormData = {
  body: string
  receiverType: FormDataReceiverType
}

type BodyError =
  | {
      type: 'empty'
    }
  | {
      type: 'tooLong'
    }

type Props = {
  title: string
  attachments: FileProp[]
  receiversAreEmpty: boolean
  isShowAttachment?: boolean
  defaultFormData?: FormData
  isSubmitLoading?: boolean
  onSubmitAndBack: (value: FormData) => void
  onSubmitAndPreview: (value: FormData) => void
  onClickAppendAttachment: (file: File) => void
  onClickRemoveAttachment: (id: string) => void
  renderReceiverSelect: () => React.ReactElement
}
const ActivityMessagePlanForm: React.FC<Props> = (props) => {
  const [body, setBody] = React.useState<string>(
    props.defaultFormData?.body ?? ''
  )
  const [bodyFocused, setBodyFocused] = React.useState<boolean>(false)
  const [bodyError, setBodyError] = React.useState<null | BodyError>(null)
  const [receiverType, setReceiverType] = React.useState<RadioKeys>(
    props.defaultFormData
      ? receiverTypeToRadioKey(props.defaultFormData.receiverType)
      : RadioKeys.UserIDs
  )

  const radioGroupItems = React.useMemo<RadioGroupItemProps[]>(() => {
    return [
      {
        key: RadioKeys.All,
        label:
          '全ユーザーに対して配信 (現在、全社員に対して配信は利用できません)',
        value: RadioKeys.All,
        disabled: true,
      },
      {
        key: RadioKeys.UserIDs,
        label: '特定のユーザーを絞り込んで配信',
        value: RadioKeys.UserIDs,
      },
    ]
  }, [])
  const submitDisabled = React.useMemo(() => {
    if (receiverType === RadioKeys.UserIDs && props.receiversAreEmpty)
      return true
    if (body === '') return true
    return (bodyError && bodyFocused) ?? false
  }, [props, body, bodyError, bodyFocused, receiverType])

  const changeBody = React.useCallback(
    (_: unknown, data: TextAreaProps | undefined) => {
      setBody(data?.value ?? '')
    },
    [setBody]
  )
  const changeReceiverType = React.useCallback(
    (_: unknown, data: RadioGroupItemProps | undefined) => {
      setReceiverType((data?.value as RadioKeys) ?? RadioKeys.All)
    },
    [setReceiverType]
  )
  const appendAttachment = React.useCallback(
    (files: File[]) => {
      props.onClickAppendAttachment(files[0])
    },
    [props]
  )
  const bodyAreaFocus = React.useCallback(() => {
    setBodyFocused(true)
  }, [setBodyFocused])
  const bodyAreaBlur = React.useCallback(() => {
    setBodyError(validateBody(body))
  }, [body, setBodyError])

  React.useEffect(() => {
    if (!props.defaultFormData) return
    setBody(props.defaultFormData.body)
    setReceiverType(receiverTypeToRadioKey(props.defaultFormData.receiverType))
  }, [props.defaultFormData])

  return (
    <div className={styles.body}>
      <Text weight="bold" size="large" content={props.title} />
      <div className={styles.formCard}>
        <div className={styles.formCardInner}>
          <Text weight="bold" content="配信内容" />
          <TextArea
            defaultValue={body}
            onFocus={bodyAreaFocus}
            onBlur={bodyAreaBlur}
            onChange={changeBody}
            className={styles.formBody}
            error={(bodyError && bodyFocused) ?? false}
            styles={{
              paddingBottom: '100px',
            }}
          />
          {bodyError && bodyFocused && (
            <p className={styles.formBodyError}>
              {bodyErrorToString(bodyError)}
            </p>
          )}
          <div className={styles.formFileList}>
            <RequesterChatFileList
              files={props.attachments}
              removable
              onClickRemove={props.onClickRemoveAttachment}
            />
          </div>
          {props.isShowAttachment && (
            <div className={styles.formBodyAttachments}>
              <FileInput onChange={appendAttachment} />
            </div>
          )}
          <Text
            className={styles.formReceiverTitle}
            weight="bold"
            content="配信先"
          />
          <RadioGroup
            checkedValue={receiverType}
            onCheckedValueChange={changeReceiverType}
            vertical
            className={styles.formReceiver}
            items={radioGroupItems}
          />
          {receiverType === RadioKeys.UserIDs && props.renderReceiverSelect()}
        </div>
        <div className={styles.formCardFooter}>
          <Button
            loading={props.isSubmitLoading}
            disabled={submitDisabled}
            onClick={() =>
              props.onSubmitAndBack({
                body,
                receiverType: receiverTypeToString(receiverType),
              })
            }
            flat
            content="内容を保存"
          />
          <Button
            loading={props.isSubmitLoading}
            disabled={submitDisabled}
            onClick={() =>
              props.onSubmitAndPreview({
                body,
                receiverType: receiverTypeToString(receiverType),
              })
            }
            flat
            className={styles.submitButton}
            primary
            content="保存してプレビューを確認"
          />
        </div>
      </div>
    </div>
  )
}

function receiverTypeToString(receiverType: RadioKeys): FormDataReceiverType {
  switch (receiverType) {
    case RadioKeys.All:
      return 'all'
    case RadioKeys.UserIDs:
      return 'userIDs'
    default:
      assertNever(receiverType)
      return 'all'
  }
}

function receiverTypeToRadioKey(receiverType: FormDataReceiverType): RadioKeys {
  switch (receiverType) {
    case 'all':
      return RadioKeys.All
    case 'userIDs':
      return RadioKeys.UserIDs
    default:
      assertNever(receiverType)
      return RadioKeys.All
  }
}

function validateBody(body: string): BodyError | null {
  if (body === '') return { type: 'empty' }
  if (body.length > 1000) return { type: 'tooLong' }
  return null
}

function bodyErrorToString(err: BodyError): string {
  switch (err.type) {
    case 'empty':
      return '内容が空です'
    case 'tooLong':
      return '内容は1000文字以内に収めてください'
    default:
      assertNever(err)
      return ''
  }
}

export default React.memo(ActivityMessagePlanForm)
