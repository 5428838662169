import { Button, Flex, FontColorIcon, Popup } from '@fluentui/react-northstar'
import React from 'react'
import { useState } from 'react'

import styles from './FontColorToolBarItem.module.css'

interface Props {
  onClick: (a: string) => void
  holdSelection: () => void
}

const onItemMouseDown = (
  e: { preventDefault: () => void },
  color: string,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  onClick: (e: string) => void
) => {
  e.preventDefault()
  onClick(color)
  setOpen(false)
}

export const FontColorToolBarContent = (props: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClick: (color: string) => void
}): JSX.Element => {
  const { setOpen, onClick } = props
  return (
    <>
      <Button
        onMouseDown={(e: { preventDefault: () => void }) =>
          onItemMouseDown(e, 'clear', setOpen, onClick)
        }
      >
        自動
      </Button>
      <Flex className={styles.flex}>
        <button
          onMouseDown={(e) => onItemMouseDown(e, 'color0', setOpen, onClick)}
          className={`${styles.button} ${styles.button0}`}
        ></button>
        <button
          onMouseDown={(e) => onItemMouseDown(e, 'color1', setOpen, onClick)}
          className={`${styles.button} ${styles.button1}`}
        ></button>
        <button
          onMouseDown={(e) => onItemMouseDown(e, 'color2', setOpen, onClick)}
          className={`${styles.button} ${styles.button2}`}
        ></button>
        <button
          onMouseDown={(e) => onItemMouseDown(e, 'color3', setOpen, onClick)}
          className={`${styles.button} ${styles.button3}`}
        ></button>
      </Flex>

      <Flex className={styles.flex}>
        <button
          onMouseDown={(e) => onItemMouseDown(e, 'color4', setOpen, onClick)}
          className={`${styles.button} ${styles.button4}`}
        ></button>
        <button
          onMouseDown={(e) => onItemMouseDown(e, 'color5', setOpen, onClick)}
          className={`${styles.button} ${styles.button5}`}
        ></button>
        <button
          onMouseDown={(e) => onItemMouseDown(e, 'color6', setOpen, onClick)}
          className={`${styles.button} ${styles.button6}`}
        ></button>
        <button
          onMouseDown={(e) => onItemMouseDown(e, 'color7', setOpen, onClick)}
          className={`${styles.button} ${styles.button7}`}
        ></button>
      </Flex>
    </>
  )
}

const FontColorToolBarItem: React.FC<Props> = ({ onClick, holdSelection }) => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <Popup
      open={open}
      trigger={<FontColorIcon outline={true} />}
      onOpenChange={(_, d) => {
        holdSelection()
        if (d != null) {
          setOpen(d.open as boolean)
        }
      }}
      content={<FontColorToolBarContent setOpen={setOpen} onClick={onClick} />}
    ></Popup>
  )
}

export default FontColorToolBarItem
