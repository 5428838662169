import { setTimeout } from 'timers'

import {
  Alert,
  Box,
  Button,
  Datepicker,
  Dialog,
  Flex,
  Table,
  Text,
} from '@fluentui/react-northstar'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import PageViewLog from '../../components/PageViewLog'
import { PageName } from '../../consts'
import { format } from '../../utils'
import { exchangeToken } from '../auth/authSlice'
import { closeFetchCSVs, createCSV, fetchCSVs } from './csvSlice'
import styles from './TicketList.module.css'

interface Props {
  open?: boolean
  onCancel: () => void
}

type APIState =
  | 'idle'
  | 'exchangeTokenStart'
  | 'exchangeTokenWait'
  | 'exchangeTokenComplete'
  | 'createCSVStart'
  | 'createCSVWait'
  | 'createCSVComplete'

const CSVExportDialog: React.FC<Props> = ({ open, onCancel }) => {
  const dispatch = useDispatch()

  const csvState = useSelector((state: RootState) => state.csvs)
  const authState = useSelector((state: RootState) => state.auth)
  const csvs = csvState.ids.map((id) => csvState.entities[id])

  const now = new Date()
  const defaultStartDate = new Date(
    now.getFullYear(),
    now.getMonth() - 1,
    now.getDate()
  )
  const defaultEndDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  )
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [successMessage, setSuccessMessage] = useState('')
  const [apiState, setAPIState] = useState<APIState>('idle')

  useEffect(() => {
    if (open) {
      dispatch(fetchCSVs())
    } else {
      closeFetchCSVs()
    }
    return () => {
      closeFetchCSVs()
    }
  }, [dispatch, open])

  // CreateCSV時に直列でAPIを叩くためにAPI実行のStateを管理する
  useEffect(() => {
    switch (apiState) {
      case 'exchangeTokenStart':
        dispatch(exchangeToken(authState.ssoToken as string))
        setAPIState('exchangeTokenWait')
        return
      case 'exchangeTokenWait':
        if (!authState.loading) {
          setAPIState('exchangeTokenComplete')
        }
        return
      case 'exchangeTokenComplete':
        if (authState.error) {
          setAPIState('idle')
        } else {
          setAPIState('createCSVStart')
        }
        return
      case 'createCSVStart':
        dispatch(
          // 終了日の23:59:59まで対象にしたいので翌日を渡す(BEDORE準拠)
          createCSV(
            startDate,
            new Date(
              endDate.getFullYear(),
              endDate.getMonth(),
              endDate.getDate() + 1
            )
          )
        )
        setAPIState('createCSVWait')
        return
      case 'createCSVWait':
        if (!csvState.loading) {
          setAPIState('createCSVComplete')
        }
        return
      case 'createCSVComplete': {
        setAPIState('idle')
        if (csvState.error) return

        setSuccessMessage('CSV出力を受け付けました')
        const timeoutId = setTimeout(() => setSuccessMessage(''), 5000)
        return () => clearTimeout(timeoutId)
      }
    }
  }, [
    dispatch,
    csvState.loading,
    csvState.error,
    authState.ssoToken,
    authState.loading,
    authState.error,
    startDate,
    endDate,
    apiState,
    setAPIState,
  ])

  const formatter = (d: Date) =>
    `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`

  const header = {
    items: ['出力期間', '受付時刻', '有効期限', '状態'],
  }
  const rows = csvs.map((e) => {
    let status = <Text>失敗</Text>
    if (e?.status === 'finished') {
      status = (
        <Button
          content="ダウンロード"
          text
          primary
          onClick={() => {
            if (authState.accessToken != null) {
              const downloadUrl = new URL(
                `/api/v1/csvs/${e.id}`,
                process.env.REACT_APP_SERVER_URL
              )
              downloadUrl.searchParams.append('token', authState.accessToken)
              window.open(downloadUrl.toString())
            }
          }}
        />
      )
    } else if (e?.status === 'started') {
      status = <Text>データ生成中...</Text>
    }

    // 終了日は閉区間になっているため、表示上は1秒前を使う
    const endDateForDisplay = e?.endDate
      ? new Timestamp()
          .setSeconds(e.endDate.seconds - 1)
          .setNanos(e.endDate.nanos)
          .toObject()
      : undefined
    return {
      key: e?.id,
      items: [
        `${e?.startDate ? format(e.startDate, 'yyyy/MM/dd') : ''} - ${
          endDateForDisplay ? format(endDateForDisplay, 'yyyy/MM/dd') : ''
        }`,
        format(e?.registeredAt, 'yyyy/MM/dd HH:mm'),
        format(e?.expiredAt, 'yyyy/MM/dd HH:mm'),
        status,
      ],
    }
  })

  const content = (
    <Box>
      <Text
        className={styles.csvExportDialogHeader}
        content="過去の問い合わせ内容をCSV形式で出力します。出力されたファイルは3日間ダウンロードできます"
      />
      <Flex className={styles.csvExportDialogContent} gap="gap.small">
        <Box className={styles.csvExportDialogFormItem}>
          <Text content="開始日" />
          <Datepicker
            allowManualInput={false}
            formatMonthDayYear={formatter}
            defaultSelectedDate={defaultStartDate}
            maxDate={endDate}
            onDateChange={(_, newDate) => {
              if (newDate) setStartDate(newDate.value)
            }}
          />
        </Box>
        <Box className={styles.csvExportDialogFormItem}>
          <Text content="終了日" />
          <Datepicker
            allowManualInput={false}
            formatMonthDayYear={formatter}
            defaultSelectedDate={defaultEndDate}
            minDate={startDate}
            maxDate={now}
            onDateChange={(_, newDate) => {
              if (newDate) setEndDate(newDate.value)
            }}
          />
        </Box>
        <Box className={styles.csvExportDialogFormItem}>
          <Button
            primary
            onClick={async () => {
              setAPIState('exchangeTokenStart')
            }}
            loading={apiState !== 'idle'}
            disabled={apiState !== 'idle'}
            content="出力"
          />
        </Box>
      </Flex>
      {successMessage ? <Alert success content={successMessage}></Alert> : ''}
      <Box className={styles.csvExportTableWrap}>
        <Table header={header} />
        <Box className={styles.csvExportTable}>
          <Table rows={rows} />
        </Box>
      </Box>
    </Box>
  )
  return (
    <PageViewLog pageName={PageName.CSVExportDialog} isOpenable isOpen={open}>
      <Dialog
        open={open}
        onCancel={() => onCancel()}
        content={content}
        header="データのエクスポート"
        className={styles.csvExportDialogOuter}
      />
    </PageViewLog>
  )
}

export default CSVExportDialog
