import { Loader } from '@fluentui/react-northstar'
import { wait } from '@testing-library/user-event/dist/utils'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import { useHistory } from 'react-router-dom'
import { debounce } from 'throttle-debounce'

import { ThunkDispatchType } from '../../app/store'
import { ActivityMessagePlanStatusComponentType } from '../../components/ActivityMessagePlanStatus'
import { assertNever } from '../../utils'
import { ActivityMessagePlanStatus } from '../ActivityMessagePlanList/types/ActivityMessagePlanEntity'
import * as ActivitymessagePlanPreviewAPI from '../ActivityMessagePlanPreviewBody/api'
import { ActivityMessagePlanReceiverType } from '../ActivityMessagePlanPreviewBody/types/ActivityMessagePlanDetailEntity'
import * as ReceiverListSlice from '../ActivityMessagePlanPreviewReceiverList/slice'
import * as ActivityMessagePlanPreviewSidebarAPI from './api'
import ActivityMessagePlanPreviewSidebarPresenter from './presenter'

type Props = {
  id: string
}
const ActivityMessagePlanPreviewSidebar: React.FC<Props> = (props) => {
  const history = useHistory()
  const dispatch = ReactRedux.useDispatch<ThunkDispatchType>()
  const {
    data: plan,
    isLoading: isPlanLoading,
    refetch,
    isFetching: isPlanFetching,
  } = ActivitymessagePlanPreviewAPI.usePlanDetailQuery({
    id: Number(props.id),
  })
  const [publishPlanMutation] =
    ActivityMessagePlanPreviewSidebarAPI.usePublishPlanMutation()
  const [removePlanMutation] =
    ActivityMessagePlanPreviewSidebarAPI.useRemovePlanMutation()

  const [isPublishLoading, setIsPublishLoading] = React.useState<boolean>(false)
  const [isRemoveLoading, setisRemoveLoading] = React.useState<boolean>(false)

  const isShowResult = React.useMemo(() => {
    if (!plan) return false
    return plan.status !== ActivityMessagePlanStatus.Draft
  }, [plan])

  const receiverType = React.useMemo<'all' | 'receiverIDs'>(() => {
    if (!plan) return 'all'

    switch (plan.receiverType) {
      case ActivityMessagePlanReceiverType.All:
        return 'all'
      case ActivityMessagePlanReceiverType.ReceiverIDs:
        return 'receiverIDs'
      default:
        assertNever(plan.receiverType)
        return 'all'
    }
  }, [plan])

  const clickSendButton = React.useCallback(async () => {
    if (!plan) return
    setIsPublishLoading(true)
    await publishPlanMutation({
      id: plan.id,
    })
    // レプリケーションを待つため少し待つ
    await wait(1500)
    refetch()
    setIsPublishLoading(false)
  }, [plan, publishPlanMutation, refetch, setIsPublishLoading])

  const clickRefreshButton = React.useCallback(() => {
    refetch()
  }, [refetch])

  const clickFailedReceiverCheckButton = React.useCallback(() => {
    if (!plan) return
    dispatch(
      ReceiverListSlice.actions.show({ receivers: plan.failedReceiverIDs })
    )
  }, [plan, dispatch])
  const clickSucceedReceiverCheckButton = React.useCallback(() => {
    if (!plan) return
    dispatch(
      ReceiverListSlice.actions.show({ receivers: plan.succeedReceiverIDs })
    )
  }, [plan, dispatch])
  const clickRemoveButton = React.useCallback(async () => {
    setisRemoveLoading(true)
    if (!plan) return
    const res = await removePlanMutation({
      id: plan.id,
    })
    if ('error' in res) {
      throw new Error(res.error as string)
    }
    // レプリケーションを待つため少し待つ
    await wait(1500)
    history.push('/activity-messages')
  }, [plan, removePlanMutation, history])

  if (isPlanLoading || isPlanFetching || !plan) {
    return <Loader />
  }

  return (
    <ActivityMessagePlanPreviewSidebarPresenter
      status={entityIntoActivityMessagePlanStatus(plan.status)}
      succeedReceiverCount={plan.succeedReceiverIDs.length}
      failedReceiverCount={plan.failedReceiverIDs.length}
      receiverType={receiverType}
      receiverIDs={plan.receiverIDs}
      isShowResult={isShowResult}
      isPublishLoading={isPublishLoading}
      isRemoveLoading={isRemoveLoading}
      onClickSendButton={debounce(300, clickSendButton)}
      onClickMemberCheckButton={() => null}
      onClickRefreshButton={clickRefreshButton}
      onClickFailedReceiverCheckButton={clickFailedReceiverCheckButton}
      onClickSucceedReceiverCheckButton={clickSucceedReceiverCheckButton}
      onClickRemoveButton={debounce(300, clickRemoveButton)}
    />
  )
}

export default React.memo(ActivityMessagePlanPreviewSidebar)

function entityIntoActivityMessagePlanStatus(
  status: ActivityMessagePlanStatus
): ActivityMessagePlanStatusComponentType {
  switch (status) {
    case ActivityMessagePlanStatus.Draft:
      return 'draft'
    case ActivityMessagePlanStatus.Processing:
      return 'pending'
    case ActivityMessagePlanStatus.Scheduled:
      return 'scheduled'
    case ActivityMessagePlanStatus.Done:
      return 'sent'
    default:
      assertNever(status)
      return 'draft'
  }
}
