import { createAsyncThunk } from '@reduxjs/toolkit'

import type { AsyncThunkConfig } from '../../app/store'
import { stateSelector } from '../ActivityMessagePlanNewForm/selectors'
import { graphApi } from '../graphApi'
import { attachmentEntityAdapter } from './lib/entityAdapter'

const isLargeSize = (size: number) => size >= 4 * 1024 * 1024

type UploadFileOperationResult = {
  id: string
  url: string
  itemID: string
  groupID: string
}
export const uploadFileFactory = (typePrefix: string) =>
  createAsyncThunk<
    UploadFileOperationResult,
    { id: string; file: File; groupID: string }
  >(typePrefix, async (args, thunkAPI) => {
    const fileUploadRes = await (() => {
      if (isLargeSize(args.file.size)) {
        return thunkAPI
          .dispatch(
            graphApi.endpoints.uploadLargeFileToGroup.initiate(
              { file: args.file, groupID: args.groupID },
              { track: false }
            )
          )
          .unwrap()
      }
      return thunkAPI
        .dispatch(
          graphApi.endpoints.uploadFileToGroup.initiate(
            {
              file: args.file,
              groupID: args.groupID,
            },
            { track: false }
          )
        )
        .unwrap()
    })()

    const shareLinkRes = await thunkAPI
      .dispatch(
        graphApi.endpoints.createShareLinkFromGroup.initiate(
          {
            itemID: fileUploadRes.itemID,
            groupID: args.groupID,
          },
          { track: false }
        )
      )
      .unwrap()
    return {
      id: args.id,
      url: shareLinkRes.url,
      itemID: fileUploadRes.itemID,
      groupID: args.groupID,
    }
  })

export const removeFileFactory = (typePrefix: string) =>
  createAsyncThunk<
    void,
    { id: string },
    AsyncThunkConfig<{ type: 'NotFound' | 'ItemRejected' | 'ItemUploading' }>
  >(typePrefix, async (args, thunkAPI) => {
    const attachment = attachmentEntityAdapter
      .getSelectors(stateSelector)
      .selectById(thunkAPI.getState(), args.id)
    if (!attachment) {
      return thunkAPI.rejectWithValue({ type: 'NotFound' })
    }

    if (attachment.status === 'pending') {
      return thunkAPI.rejectWithValue({ type: 'ItemUploading' })
    }
    if (attachment.status === 'error') {
      return thunkAPI.rejectWithValue({ type: 'ItemRejected' })
    }

    await thunkAPI
      .dispatch(
        graphApi.endpoints.removeFileFromGroup.initiate(
          {
            groupID: attachment.groupID ?? '',
            itemID: attachment.itemID ?? '',
          },
          { track: false }
        )
      )
      .unwrap()
  })
