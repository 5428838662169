import { Box, Flex } from '@fluentui/react-northstar'
import React from 'react'
import { useSelector } from 'react-redux'

import AlertBox from './AlertBox'
import styles from './AlertContainer.module.css'
import { alertsSelectors } from './alertsSlice'

const AlertContainer: React.FC = () => {
  const alerts = useSelector(alertsSelectors.selectAll)
  return (
    <Box className={styles.container}>
      <Flex column gap="gap.smaller">
        {alerts.map((alert) => (
          <AlertBox key={alert.id} alert={alert} />
        ))}
      </Flex>
    </Box>
  )
}

export default AlertContainer
