export type ActivityMessagePlanEntity = {
  id: number
  body: string
  status: ActivityMessagePlanStatus
  creatorID: string
  createdAt: Date
  sentAt: Date | null
}

export enum ActivityMessagePlanStatus {
  Draft,
  Scheduled,
  Processing,
  Done,
}
