import * as React from 'react'
import { useHistory } from 'react-router-dom'

import ActivityMessagePlanNewForm from '../../renewfeatures/ActivityMessagePlanNewForm'
import ActivityMessagePlanNewPresenter from './presenter'

const ActivityMessagePlanNew: React.FC = () => {
  const history = useHistory()

  const clickBackButton = React.useCallback(() => {
    history.push('/activity-messages')
  }, [history])

  return (
    <ActivityMessagePlanNewPresenter
      onClickBackButton={clickBackButton}
      renderForm={() => <ActivityMessagePlanNewForm />}
    />
  )
}

export default React.memo(ActivityMessagePlanNew)
