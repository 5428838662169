// package: 
// file: proto/requester_auth.proto

var proto_requester_auth_pb = require("../proto/requester_auth_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var RequesterAuthAPI = (function () {
  function RequesterAuthAPI() {}
  RequesterAuthAPI.serviceName = "RequesterAuthAPI";
  return RequesterAuthAPI;
}());

RequesterAuthAPI.RequesterExchangeToken = {
  methodName: "RequesterExchangeToken",
  service: RequesterAuthAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_requester_auth_pb.RequesterExchangeTokenRequest,
  responseType: proto_requester_auth_pb.RequesterExchangeTokenResponse
};

exports.RequesterAuthAPI = RequesterAuthAPI;

function RequesterAuthAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

RequesterAuthAPIClient.prototype.requesterExchangeToken = function requesterExchangeToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RequesterAuthAPI.RequesterExchangeToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.RequesterAuthAPIClient = RequesterAuthAPIClient;

