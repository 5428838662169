import { Loader } from '@fluentui/react-northstar'
import * as microsoftTeams from '@microsoft/teams-js'
import { wait } from '@testing-library/user-event/dist/utils'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { debounce } from 'throttle-debounce'
import * as uuid from 'uuid'

import { RootState, ThunkDispatchType } from '../../app/store'
import ActivityMessagePlanForm, {
  FormData,
  FormDataReceiverType,
} from '../../components/ActivityMessagePlanForm'
import { assertNever } from '../../utils'
import ActivityMessagePlanFormReceiverSelect from '../ActivityMessagePlanFormReceiverSelect'
import { usePlanDetailQuery } from '../ActivityMessagePlanPreviewBody/api'
import { ActivityMessagePlanReceiverType } from '../ActivityMessagePlanPreviewBody/types/ActivityMessagePlanDetailEntity'
import * as Operations from './operations'
import * as Selectors from './selectors'
import * as Slices from './slice'

type Props = { id: number }
const ActivityMessagePlanEditForm: React.FC<Props> = (props) => {
  const history = useHistory()
  const dispatch = useDispatch<ThunkDispatchType>()
  const [receiverIDs, setReceiverIDs] = React.useState<string[]>([])
  const [groupID, setGroupID] = React.useState<string | null>(null)
  const [isSubmitLoading, setIsSubmitLoading] = React.useState<boolean>(false)

  const planQuery = usePlanDetailQuery({
    id: props.id,
  })

  const attachmentsFactory = ReactRedux.useSelector(
    (state: RootState) => (id: number) =>
      Selectors.currentAttachments(state, id)
  )

  const attachments = React.useMemo(
    () => attachmentsFactory(props.id),
    [props.id, attachmentsFactory]
  )

  const isAvailableAttachment = ReactRedux.useSelector(
    (state: RootState) => state.auth?.availableFeatures?.fileAttachment ?? false
  )

  const defaultFormData = React.useMemo<FormData>(() => {
    if (!planQuery.data)
      return {
        body: '',
        receiverType: 'all',
        receivers: [],
        attachments: [],
      }

    return {
      body: planQuery.data.body,
      attachments: [],
      receiverType: modelReceiverTypeToFormReceiverType(
        planQuery.data.receiverType
      ),
    }
  }, [planQuery.data])

  const submitAndPreview = React.useCallback(
    async (value: FormData) => {
      setIsSubmitLoading(true)
      await dispatch(
        Operations.submitForm({
          id: Number(props.id),
          data: value,
          receiverIDs: receiverIDs,
        })
      ).unwrap()
      // レプリケーションを待つため少し待つ
      await wait(1500)
      history.push(`/activity-messages/${props.id}`)
    },
    [props.id, history, receiverIDs, dispatch]
  )
  const submitAndBack = React.useCallback(
    async (value: FormData) => {
      setIsSubmitLoading(true)
      await dispatch(
        Operations.submitForm({
          id: Number(props.id),
          data: value,
          receiverIDs: receiverIDs,
        })
      ).unwrap()
      // レプリケーションを待つため少し待つ
      await wait(1500)
      history.push('/activity-messages')
    },
    [props.id, history, receiverIDs, dispatch]
  )
  const changeReceiverIDs = React.useCallback(
    (value: string[]) => {
      setReceiverIDs(value)
    },
    [setReceiverIDs]
  )
  const appendAttachment = React.useCallback(
    (file: File) => {
      if (!groupID) return
      dispatch(Operations.uploadFile({ id: uuid.v4(), file, groupID }))
    },
    [dispatch, groupID]
  )
  const removeAttachment = React.useCallback(
    (id: string) => {
      dispatch(Slices.actions.removeAttachment({ id }))
    },
    [dispatch]
  )
  const renderReceiverSelect = React.useCallback(() => {
    return (
      <ActivityMessagePlanFormReceiverSelect
        defaultReceiverIDs={receiverIDs}
        onChange={changeReceiverIDs}
      />
    )
  }, [receiverIDs, changeReceiverIDs])

  React.useEffect(() => {
    if (!planQuery.data) return
    setReceiverIDs(planQuery.data.receiverIDs)
  }, [setReceiverIDs, planQuery.data])
  React.useEffect(() => {
    microsoftTeams.app.getContext().then((context) => {
      setGroupID(context.team?.groupId ?? '')
    })
  }, [])
  React.useEffect(() => {
    return () => {
      dispatch(Slices.actions.reset())
    }
  }, [dispatch])

  if (planQuery.isLoading) return <Loader />

  return (
    <ActivityMessagePlanForm
      title="配信の編集"
      attachments={attachments}
      defaultFormData={defaultFormData}
      receiversAreEmpty={receiverIDs.length <= 0}
      isSubmitLoading={isSubmitLoading}
      isShowAttachment={isAvailableAttachment}
      onSubmitAndPreview={debounce(300, submitAndPreview)}
      onSubmitAndBack={debounce(300, submitAndBack)}
      onClickRemoveAttachment={removeAttachment}
      onClickAppendAttachment={appendAttachment}
      renderReceiverSelect={renderReceiverSelect}
    />
  )
}

export default React.memo(ActivityMessagePlanEditForm)

function modelReceiverTypeToFormReceiverType(
  type: ActivityMessagePlanReceiverType
): FormDataReceiverType {
  switch (type) {
    case ActivityMessagePlanReceiverType.All:
      return 'all'
    case ActivityMessagePlanReceiverType.ReceiverIDs:
      return 'userIDs'
    default:
      assertNever(type)
      return 'all'
  }
}
