import { Flex } from '@fluentui/react-northstar'
import React from 'react'
import { useLocation } from 'react-router-dom'

import styles from './Auth.module.css'

const AuthAdminConsent: React.FC = () => {
  const search = useLocation().search
  const error = new URLSearchParams(search).get('error')
  const errorDescription = new URLSearchParams(search).get('error_description')
  const errorUri = new URLSearchParams(search).get('error_uri')

  // if error returned, its description is written in url query parameters
  console.log('adminconsent result: ' + search)

  return (
    <Flex
      column
      className={styles.container}
      styles={({ theme: { siteVariables } }) => ({
        backgroundColor: siteVariables.colorScheme.default.background2,
      })}
    >
      {error === null ? (
        <React.Fragment>
          <h1>管理者の同意が完了しました</h1>
          <p>同意が正常に完了しましたので、画面を閉じてください。</p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h1>管理者の同意に問題がありました</h1>
          <p>下記の問題が発生しました。</p>
          <dl>
            <dt>エラー</dt>
            <dd>{error}</dd>
            <dt>説明</dt>
            <dd>{errorDescription}</dd>
            <dt>URL</dt>
            <dd>{errorUri}</dd>
          </dl>
        </React.Fragment>
      )}
    </Flex>
  )
}

export default AuthAdminConsent
