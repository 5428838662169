import { Loader } from '@fluentui/react-northstar'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import { RootState } from '../../app/store'
import { addAlert } from '../../features/alert/alertsSlice'
import * as faqSelector from '../../features/faq/selector'
import RecommendedFaqTicket from '../RecommendedFaqTicket'
import { useGetRecommendedFaqListQuery } from './api'
import RecommendedFaqItem from './features/RecommendedFaqItem'
import RecommendedFaqListHeaderPresenter from './features/RecommendedFaqListHeader/presenter'
import RecommendedFaqListPicturePreview from './features/RecommendedFaqListPicturePreview'
import RecommendedFaqListPresenter from './presenter'
import * as Selectors from './selector'
import * as Slice from './slice'
import { RecommendedFaqEntity } from './types/RecommendedFaqEntity'

const RecommendedFaqList: React.FC = () => {
  const dispatch = ReactRedux.useDispatch()
  const res = useGetRecommendedFaqListQuery({})
  const recommendedFaqs = res.data?.recommendedFaqEntities ?? []
  const scheduledAt = ReactRedux.useSelector((state: RootState) =>
    Selectors.scheduledAt(state)
  )
  const executedAt = ReactRedux.useSelector((state: RootState) =>
    Selectors.executedAt(state)
  )
  const recommendedFaqListError = ReactRedux.useSelector((state: RootState) =>
    Selectors.recommendedFaqListError(state)
  )
  const faqLabelError = ReactRedux.useSelector((state: RootState) =>
    faqSelector.faqLabelError(state)
  )
  const selectedRecommendedFaq = ReactRedux.useSelector((state: RootState) =>
    Selectors.selectedRecommendedFaqSelector(state)
  )
  const isConfigured = ReactRedux.useSelector((state: RootState) =>
    Selectors.isConfigured(state)
  )
  const handleFaqItemClick = React.useCallback(
    (faq: RecommendedFaqEntity) => {
      if (faq.id === selectedRecommendedFaq?.id) {
        // 選択中のFAQ提案をクリックした場合は選択を解除する
        dispatch(Slice.actions.unselectRecommendedFaq())
        return
      }
      dispatch(Slice.actions.selectRecommendedFaq({ faq }))
    },
    [dispatch, selectedRecommendedFaq?.id]
  )
  const renderRecommendedFaqItem = React.useCallback(
    (faq: RecommendedFaqEntity) => {
      return (
        <RecommendedFaqItem
          recommendedFaqItem={faq}
          open={faq.id === selectedRecommendedFaq?.id}
          onClick={handleFaqItemClick}
        />
      )
    },
    [selectedRecommendedFaq?.id, handleFaqItemClick]
  )
  const renderRecommendedFaqTicket = React.useCallback(
    () => <RecommendedFaqTicket faq={selectedRecommendedFaq} />,
    [selectedRecommendedFaq]
  )
  const renderRecommendedFaqListPicturePreview = React.useCallback(
    () => <RecommendedFaqListPicturePreview />,
    []
  )

  React.useEffect(() => {
    if (recommendedFaqListError != null) {
      dispatch(
        addAlert({
          id: Math.random() * 1000,
          title: recommendedFaqListError.message,
          type: 'faq_create_error',
        })
      )
    }
  }, [dispatch, recommendedFaqListError])

  // FIXME: FAQラベルのリファクタ
  React.useEffect(() => {
    if (faqLabelError === true) {
      dispatch(
        addAlert({
          id: Math.random() * 1000,
          title: 'ラベルの取得に失敗しました。',
          type: 'faq_label_error',
        })
      )
    }
  }, [dispatch, faqLabelError])

  if (res.isLoading || res.isUninitialized) {
    return <Loader />
  }
  return (
    <RecommendedFaqListPresenter
      isConfigured={isConfigured}
      scheduledAt={scheduledAt}
      executedAt={executedAt}
      recommendedFaqLength={recommendedFaqs.length}
      listRecommendedFaqItem={recommendedFaqs}
      renderHeader={() => <RecommendedFaqListHeaderPresenter />}
      renderRecommendedFaqItem={renderRecommendedFaqItem}
      renderRecommendedFaqTicket={renderRecommendedFaqTicket}
      renderRecommendedFaqListPicturePreview={
        renderRecommendedFaqListPicturePreview
      }
    />
  )
}

export default RecommendedFaqList
