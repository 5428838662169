import { Button, Flex, HighlightIcon, Popup } from '@fluentui/react-northstar'
import React from 'react'
import { useState } from 'react'

import styles from './HighLightToolBarItem.module.css'

interface Props {
  onClick: (color: string) => void
  holdSelection: () => void
}

const onItemMouseDown = (
  e: { preventDefault: () => void },
  color: string,
  onClick: (e: string) => void
) => {
  e.preventDefault()
  onClick(color)
}

export const HightLightToolBarContent: React.FC<{
  onClick: (color: string) => void
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = (props) => {
  const { onClick, setOpen } = props
  return (
    <>
      <Button
        onMouseDown={(e: { preventDefault: () => void }) => {
          onItemMouseDown(e, 'clear', onClick)
          setOpen(false)
        }}
      >
        ハイライトなし
      </Button>
      <Flex className={styles.flex}>
        <button
          onMouseDown={(e) => {
            onItemMouseDown(e, 'backgroundColor0', onClick)
            setOpen(false)
          }}
          className={`${styles.button} ${styles.button0}`}
        ></button>
        <button
          onMouseDown={(e) => {
            onItemMouseDown(e, 'backgroundColor1', onClick)
            setOpen(false)
          }}
          className={`${styles.button} ${styles.button1}`}
        ></button>
        <button
          onMouseDown={(e) => {
            onItemMouseDown(e, 'backgroundColor2', onClick)
            setOpen(false)
          }}
          className={`${styles.button} ${styles.button2}`}
        ></button>
        <button
          onMouseDown={(e) => {
            onItemMouseDown(e, 'backgroundColor3', onClick)
            setOpen(false)
          }}
          className={`${styles.button} ${styles.button3}`}
        ></button>
      </Flex>

      <Flex className={styles.flex}>
        <button
          onMouseDown={(e) => {
            onItemMouseDown(e, 'backgroundColor4', onClick)
            setOpen(false)
          }}
          className={`${styles.button} ${styles.button4}`}
        ></button>
        <button
          onMouseDown={(e) => {
            onItemMouseDown(e, 'backgroundColor5', onClick)
            setOpen(false)
          }}
          className={`${styles.button} ${styles.button5}`}
        ></button>
        <button
          onMouseDown={(e) => {
            onItemMouseDown(e, 'backgroundColor6', onClick)
            setOpen(false)
          }}
          className={`${styles.button} ${styles.button6}`}
        ></button>
        <button
          onMouseDown={(e) => {
            onItemMouseDown(e, 'backgroundColor7', onClick)
            setOpen(false)
          }}
          className={`${styles.button} ${styles.button7}`}
        ></button>
      </Flex>
    </>
  )
}

const HighLightToolBarItem: React.FC<Props> = ({
  holdSelection,
  onClick,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <Popup
      open={open}
      onOpenChange={(e, d) => {
        if (d != null) {
          setOpen(d.open as boolean)
        }
        holdSelection()
      }}
      content={<HightLightToolBarContent onClick={onClick} setOpen={setOpen} />}
      trigger={<HighlightIcon outline={true} />}
    ></Popup>
  )
}

export default HighLightToolBarItem
