import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  Tooltip,
} from '@fluentui/react-northstar'
import * as React from 'react'

import styles from './styles.module.css'
import { RecommendedFaqEntity } from './types/RecommendedFaqEntity'

type Props = {
  isConfigured: boolean
  scheduledAt?: string
  executedAt?: string
  recommendedFaqLength: number
  listRecommendedFaqItem: RecommendedFaqEntity[]
  renderHeader: () => React.ReactElement
  renderRecommendedFaqItem: (entity: RecommendedFaqEntity) => React.ReactElement
  renderRecommendedFaqTicket: () => React.ReactElement
  renderRecommendedFaqListPicturePreview: () => React.ReactElement
}
const RecommendedFaqListPresenter: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      {props.renderRecommendedFaqListPicturePreview()}
      <div className={styles.header}>{props.renderHeader()}</div>
      <Flex column className={styles.body}>
        <Box>
          <Flex column>
            <Flex
              gap="gap.small"
              space="between"
              vAlign="center"
              className={styles.leftContent}
            >
              <Flex gap="gap.small" vAlign="center">
                <Text weight="bold" className={styles.title}>
                  AIアシスタントが提案するFAQ一覧
                </Text>
                <Tooltip
                  trigger={
                    <Button
                      text
                      iconOnly
                      icon={<img src="/assets/questionIcon.svg" />}
                    />
                  }
                  position="after"
                  pointing
                  content={
                    <div>
                      <Text content="有人対話ログを解析し、AIがFAQを提案します。" />
                      <br />
                      <Text content="影響度や減らせる有人問い合わせ等の指標はあくまで参考値としてご確認ください。" />
                    </div>
                  }
                />
                {props.executedAt != null ? (
                  <Text className={styles.lastUpdate}>
                    最終更新日:{props.executedAt}
                  </Text>
                ) : null}
              </Flex>
              {props.recommendedFaqLength > 0 && props.isConfigured && (
                <Flex.Item>
                  <Text weight="bold" className={styles.unconfirmed}>
                    未確認のFAQ:{' '}
                    <Text
                      className={styles.unconfirmedCount}
                      content={props.recommendedFaqLength}
                    />{' '}
                    件
                  </Text>
                </Flex.Item>
              )}
            </Flex>
            {props.recommendedFaqLength > 0 && props.isConfigured ? (
              <Flex gap="gap.medium">
                <Flex column className={styles.leftContent}>
                  {props.listRecommendedFaqItem.map((recommendedFaqItem) => {
                    return (
                      <div key={recommendedFaqItem.id}>
                        {props.renderRecommendedFaqItem(recommendedFaqItem)}
                      </div>
                    )
                  })}
                </Flex>
                <Flex column className={styles.rightContent}>
                  {props.renderRecommendedFaqTicket()}
                </Flex>
              </Flex>
            ) : (
              <Flex column className={styles.noRecommendedFaqListContainer}>
                <Flex column>
                  {props.isConfigured ? (
                    <>
                      <Text
                        content={'全てFAQ化対応できています！'}
                        className={styles.noRecommendedFaqListBodyText}
                      />
                      <Text
                        content={'別のカテゴリも確認し対応を進めてください'}
                        className={styles.noRecommendedFaqListBodyText}
                      />
                    </>
                  ) : (
                    <>
                      <Text
                        content={
                          'AIによるFAQ作成提案機能 (無料) は利用申請が必要となります。'
                        }
                        className={styles.noRecommendedFaqListBodyText}
                      />
                      <Text
                        content={'ご希望の方はPKSHA担当者までご連絡ください'}
                        className={styles.noRecommendedFaqListBodyText}
                      />
                    </>
                  )}
                </Flex>
                <Image
                  className={styles.noRecommendedFaqListImage}
                  src={'/assets/allRecommendedFaqCreated.svg'}
                />
                <Text
                  content={
                    props.isConfigured
                      ? `次回は${props.scheduledAt}に更新されます`
                      : 'AIがFAQ化を推奨する対話と、FAQのドラフトを自動で作成します'
                  }
                  className={styles.scheduled}
                />
              </Flex>
            )}
          </Flex>
        </Box>
      </Flex>
    </div>
  )
}

export default RecommendedFaqListPresenter
