import { Button, EditIcon } from '@fluentui/react-northstar'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useMonaco } from '@monaco-editor/react'
import {
  DOMExportOutput,
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical'
import * as React from 'react'

import RequesterChatCodeblockEdit, {
  Languages,
} from '../../../../components/RequesterChatCodeblockEdit'
import { EDIT_CODEBLOCK_COMMAND } from './index'

export type CodeblockPalyload = {
  title: string
  value: string
  language: string
}

export type SerializedCodeblockNode = Spread<
  { title: string; value: string; language: string; version: 1 },
  SerializedLexicalNode
>

export class CodeblockNode extends DecoratorNode<JSX.Element> {
  __value: string
  __title: string
  __language: string

  constructor(title: string, value: string, language: string, key?: NodeKey) {
    super(key)
    this.__value = value
    this.__title = title
    this.__language = language
  }

  static getType(): 'codeblock' {
    return 'codeblock'
  }

  static clone(node: CodeblockNode): CodeblockNode {
    return new CodeblockNode(node.__title, node.__value, node.__language)
  }

  static importJSON(serializedNode: SerializedCodeblockNode): CodeblockNode {
    return $createCodeblockNode({
      value: serializedNode.value,
      title: serializedNode.title,
      language: serializedNode.language,
    })
  }

  createDOM(): HTMLDivElement {
    return document.createElement('div')
  }

  exportDOM(): DOMExportOutput {
    const titleEl = document.createElement('p')
    titleEl.innerText = this.__title

    const preEl = document.createElement('pre')
    preEl.innerHTML = this.__value
    preEl.setAttribute('data-language', this.__language)

    const outer = document.createElement('div')
    outer.appendChild(titleEl)
    outer.appendChild(preEl)
    outer.className = 'codeblock'

    return {
      element: outer,
    }
  }

  updateDOM(prevNode: CodeblockNode): boolean {
    return [
      prevNode.__title !== this.__title,
      prevNode.__value !== this.__value,
      prevNode.__language !== this.__language,
    ].some((a) => a)
  }

  exportJSON(): SerializedCodeblockNode {
    return {
      type: CodeblockNode.getType(),
      title: this.__title,
      value: this.__value,
      language: this.__language,
      version: 1,
    }
  }

  decorate(): JSX.Element {
    return (
      <Codeblock
        nodeKey={this.__key}
        title={this.__title}
        value={this.__value}
        language={this.__language}
      />
    )
  }

  setContent(title: string, value: string, language: string) {
    const writable = this.getWritable()
    writable.__title = title
    writable.__value = value
    writable.__language = language
  }
}

export const $createCodeblockNode = (
  payload: CodeblockPalyload
): CodeblockNode => {
  return new CodeblockNode(payload.title, payload.value, payload.language)
}

export const $isCodeblockNode = (
  node: LexicalNode | null
): node is CodeblockNode => {
  return node instanceof CodeblockNode
}

type Props = {
  title: string
  value: string
  language: string
  nodeKey: NodeKey
}
const Codeblock: React.FC<Props> = (props) => {
  const monaco = useMonaco()
  const [editor] = useLexicalComposerContext()
  const submit = React.useCallback(
    (title: string, value: string, language: string) => {
      editor.dispatchCommand(EDIT_CODEBLOCK_COMMAND, {
        key: props.nodeKey,
        title,
        value,
        language,
      })
    },
    [editor, props]
  )
  const [colorized, setColorized] = React.useState(props.value)
  const displayLanguage = React.useMemo(() => {
    return (
      Object.entries(Languages).find(([, v]) => v === props.language)?.[0] ??
      Languages.Text
    )
  }, [props.language])

  React.useEffect(() => {
    if (!monaco) return
    monaco.editor
      .colorize(props.value, props.language, {})
      .then((value) => setColorized(value))
  }, [monaco, props])

  return (
    <div className="codeblock" data-language={props.language}>
      <div className="title-row">
        <div className="title-row-left">
          <p className="title">{props.title}</p>
          <p className="language">{displayLanguage}</p>
        </div>
        <RequesterChatCodeblockEdit
          defaultTitle={props.title}
          defaultValue={props.value}
          defaultLanguage={
            props.language as typeof Languages[keyof typeof Languages]
          }
          trigger={<Button iconOnly text icon={<EditIcon />} />}
          onSubmit={submit}
        />
      </div>
      <pre dangerouslySetInnerHTML={{ __html: colorized }}></pre>
    </div>
  )
}
