// source: proto/requester_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var proto_message_pb = require('../proto/message_pb.js');
goog.object.extend(proto, proto_message_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.CreateRequesterConversationRequest', null, global);
goog.exportSymbol('proto.CreateRequesterMessageRequest', null, global);
goog.exportSymbol('proto.ListRequesterConversationsRequest', null, global);
goog.exportSymbol('proto.ListRequesterConversationsResponse', null, global);
goog.exportSymbol('proto.ListRequesterMessagesRequest', null, global);
goog.exportSymbol('proto.ListRequesterMessagesResponse', null, global);
goog.exportSymbol('proto.ReplyCountAndLastReplySearchResponse', null, global);
goog.exportSymbol('proto.ReplyCountAndLastReplySearchResponse.Aggregations', null, global);
goog.exportSymbol('proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages', null, global);
goog.exportSymbol('proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket', null, global);
goog.exportSymbol('proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc', null, global);
goog.exportSymbol('proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits', null, global);
goog.exportSymbol('proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit', null, global);
goog.exportSymbol('proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total', null, global);
goog.exportSymbol('proto.RequesterConversation', null, global);
goog.exportSymbol('proto.RequesterConversationSearchResponse', null, global);
goog.exportSymbol('proto.RequesterConversationSearchResponse.Hits', null, global);
goog.exportSymbol('proto.RequesterConversationSearchResponse.Item', null, global);
goog.exportSymbol('proto.RequesterConversationWithPartialReplies', null, global);
goog.exportSymbol('proto.RequesterMessage', null, global);
goog.exportSymbol('proto.RequesterMessageSearchResponse', null, global);
goog.exportSymbol('proto.RequesterMessageSearchResponse.Hits', null, global);
goog.exportSymbol('proto.RequesterMessageSearchResponse.Item', null, global);
goog.exportSymbol('proto.RequesterMessageSearchResponse.Total', null, global);
goog.exportSymbol('proto.WelcomeMessageResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequesterMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RequesterMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequesterMessage.displayName = 'proto.RequesterMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequesterMessageSearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RequesterMessageSearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequesterMessageSearchResponse.displayName = 'proto.RequesterMessageSearchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequesterMessageSearchResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RequesterMessageSearchResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequesterMessageSearchResponse.Item.displayName = 'proto.RequesterMessageSearchResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequesterMessageSearchResponse.Total = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RequesterMessageSearchResponse.Total, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequesterMessageSearchResponse.Total.displayName = 'proto.RequesterMessageSearchResponse.Total';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequesterMessageSearchResponse.Hits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.RequesterMessageSearchResponse.Hits.repeatedFields_, null);
};
goog.inherits(proto.RequesterMessageSearchResponse.Hits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequesterMessageSearchResponse.Hits.displayName = 'proto.RequesterMessageSearchResponse.Hits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequesterConversationWithPartialReplies = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RequesterConversationWithPartialReplies, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequesterConversationWithPartialReplies.displayName = 'proto.RequesterConversationWithPartialReplies';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequesterConversation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RequesterConversation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequesterConversation.displayName = 'proto.RequesterConversation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequesterConversationSearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RequesterConversationSearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequesterConversationSearchResponse.displayName = 'proto.RequesterConversationSearchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequesterConversationSearchResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RequesterConversationSearchResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequesterConversationSearchResponse.Item.displayName = 'proto.RequesterConversationSearchResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequesterConversationSearchResponse.Hits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.RequesterConversationSearchResponse.Hits.repeatedFields_, null);
};
goog.inherits(proto.RequesterConversationSearchResponse.Hits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequesterConversationSearchResponse.Hits.displayName = 'proto.RequesterConversationSearchResponse.Hits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReplyCountAndLastReplySearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReplyCountAndLastReplySearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReplyCountAndLastReplySearchResponse.displayName = 'proto.ReplyCountAndLastReplySearchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReplyCountAndLastReplySearchResponse.Aggregations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReplyCountAndLastReplySearchResponse.Aggregations.displayName = 'proto.ReplyCountAndLastReplySearchResponse.Aggregations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.repeatedFields_, null);
};
goog.inherits(proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.displayName = 'proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.displayName = 'proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.displayName = 'proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.repeatedFields_, null);
};
goog.inherits(proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.displayName = 'proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total.displayName = 'proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.repeatedFields_, null);
};
goog.inherits(proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.displayName = 'proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateRequesterConversationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateRequesterConversationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateRequesterConversationRequest.displayName = 'proto.CreateRequesterConversationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateRequesterMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateRequesterMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateRequesterMessageRequest.displayName = 'proto.CreateRequesterMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListRequesterConversationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListRequesterConversationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListRequesterConversationsRequest.displayName = 'proto.ListRequesterConversationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListRequesterConversationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListRequesterConversationsResponse.repeatedFields_, null);
};
goog.inherits(proto.ListRequesterConversationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListRequesterConversationsResponse.displayName = 'proto.ListRequesterConversationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListRequesterMessagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListRequesterMessagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListRequesterMessagesRequest.displayName = 'proto.ListRequesterMessagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListRequesterMessagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListRequesterMessagesResponse.repeatedFields_, null);
};
goog.inherits(proto.ListRequesterMessagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListRequesterMessagesResponse.displayName = 'proto.ListRequesterMessagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.WelcomeMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.WelcomeMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.WelcomeMessageResponse.displayName = 'proto.WelcomeMessageResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequesterMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.RequesterMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequesterMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tenantId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    conversationId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    senderType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    botId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    event: (f = msg.getEvent()) && proto_message_pb.Event.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequesterMessage}
 */
proto.RequesterMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequesterMessage;
  return proto.RequesterMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequesterMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequesterMessage}
 */
proto.RequesterMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConversationId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBotId(value);
      break;
    case 9:
      var value = new proto_message_pb.Event;
      reader.readMessage(value,proto_message_pb.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequesterMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequesterMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequesterMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConversationId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSenderType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBotId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_message_pb.Event.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.RequesterMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RequesterMessage} returns this
 */
proto.RequesterMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.RequesterMessage.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.RequesterMessage} returns this
*/
proto.RequesterMessage.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequesterMessage} returns this
 */
proto.RequesterMessage.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequesterMessage.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.RequesterMessage.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.RequesterMessage} returns this
*/
proto.RequesterMessage.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequesterMessage} returns this
 */
proto.RequesterMessage.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequesterMessage.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string tenant_id = 4;
 * @return {string}
 */
proto.RequesterMessage.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.RequesterMessage} returns this
 */
proto.RequesterMessage.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string conversation_id = 5;
 * @return {string}
 */
proto.RequesterMessage.prototype.getConversationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.RequesterMessage} returns this
 */
proto.RequesterMessage.prototype.setConversationId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string user_id = 6;
 * @return {string}
 */
proto.RequesterMessage.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.RequesterMessage} returns this
 */
proto.RequesterMessage.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sender_type = 7;
 * @return {string}
 */
proto.RequesterMessage.prototype.getSenderType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.RequesterMessage} returns this
 */
proto.RequesterMessage.prototype.setSenderType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 bot_id = 8;
 * @return {number}
 */
proto.RequesterMessage.prototype.getBotId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.RequesterMessage} returns this
 */
proto.RequesterMessage.prototype.setBotId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional Event event = 9;
 * @return {?proto.Event}
 */
proto.RequesterMessage.prototype.getEvent = function() {
  return /** @type{?proto.Event} */ (
    jspb.Message.getWrapperField(this, proto_message_pb.Event, 9));
};


/**
 * @param {?proto.Event|undefined} value
 * @return {!proto.RequesterMessage} returns this
*/
proto.RequesterMessage.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequesterMessage} returns this
 */
proto.RequesterMessage.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequesterMessage.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequesterMessageSearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RequesterMessageSearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequesterMessageSearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterMessageSearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hits: (f = msg.getHits()) && proto.RequesterMessageSearchResponse.Hits.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequesterMessageSearchResponse}
 */
proto.RequesterMessageSearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequesterMessageSearchResponse;
  return proto.RequesterMessageSearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequesterMessageSearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequesterMessageSearchResponse}
 */
proto.RequesterMessageSearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RequesterMessageSearchResponse.Hits;
      reader.readMessage(value,proto.RequesterMessageSearchResponse.Hits.deserializeBinaryFromReader);
      msg.setHits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequesterMessageSearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequesterMessageSearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequesterMessageSearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterMessageSearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHits();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.RequesterMessageSearchResponse.Hits.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequesterMessageSearchResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.RequesterMessageSearchResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequesterMessageSearchResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterMessageSearchResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    source: (f = msg.getSource()) && proto.RequesterMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequesterMessageSearchResponse.Item}
 */
proto.RequesterMessageSearchResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequesterMessageSearchResponse.Item;
  return proto.RequesterMessageSearchResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequesterMessageSearchResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequesterMessageSearchResponse.Item}
 */
proto.RequesterMessageSearchResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.RequesterMessage;
      reader.readMessage(value,proto.RequesterMessage.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequesterMessageSearchResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequesterMessageSearchResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequesterMessageSearchResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterMessageSearchResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.RequesterMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.RequesterMessageSearchResponse.Item.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RequesterMessageSearchResponse.Item} returns this
 */
proto.RequesterMessageSearchResponse.Item.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RequesterMessage source = 2;
 * @return {?proto.RequesterMessage}
 */
proto.RequesterMessageSearchResponse.Item.prototype.getSource = function() {
  return /** @type{?proto.RequesterMessage} */ (
    jspb.Message.getWrapperField(this, proto.RequesterMessage, 2));
};


/**
 * @param {?proto.RequesterMessage|undefined} value
 * @return {!proto.RequesterMessageSearchResponse.Item} returns this
*/
proto.RequesterMessageSearchResponse.Item.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequesterMessageSearchResponse.Item} returns this
 */
proto.RequesterMessageSearchResponse.Item.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequesterMessageSearchResponse.Item.prototype.hasSource = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequesterMessageSearchResponse.Total.prototype.toObject = function(opt_includeInstance) {
  return proto.RequesterMessageSearchResponse.Total.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequesterMessageSearchResponse.Total} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterMessageSearchResponse.Total.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequesterMessageSearchResponse.Total}
 */
proto.RequesterMessageSearchResponse.Total.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequesterMessageSearchResponse.Total;
  return proto.RequesterMessageSearchResponse.Total.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequesterMessageSearchResponse.Total} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequesterMessageSearchResponse.Total}
 */
proto.RequesterMessageSearchResponse.Total.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequesterMessageSearchResponse.Total.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequesterMessageSearchResponse.Total.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequesterMessageSearchResponse.Total} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterMessageSearchResponse.Total.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 value = 1;
 * @return {number}
 */
proto.RequesterMessageSearchResponse.Total.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RequesterMessageSearchResponse.Total} returns this
 */
proto.RequesterMessageSearchResponse.Total.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.RequesterMessageSearchResponse.Hits.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequesterMessageSearchResponse.Hits.prototype.toObject = function(opt_includeInstance) {
  return proto.RequesterMessageSearchResponse.Hits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequesterMessageSearchResponse.Hits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterMessageSearchResponse.Hits.toObject = function(includeInstance, msg) {
  var f, obj = {
    hitsList: jspb.Message.toObjectList(msg.getHitsList(),
    proto.RequesterMessageSearchResponse.Item.toObject, includeInstance),
    total: (f = msg.getTotal()) && proto.RequesterMessageSearchResponse.Total.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequesterMessageSearchResponse.Hits}
 */
proto.RequesterMessageSearchResponse.Hits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequesterMessageSearchResponse.Hits;
  return proto.RequesterMessageSearchResponse.Hits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequesterMessageSearchResponse.Hits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequesterMessageSearchResponse.Hits}
 */
proto.RequesterMessageSearchResponse.Hits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RequesterMessageSearchResponse.Item;
      reader.readMessage(value,proto.RequesterMessageSearchResponse.Item.deserializeBinaryFromReader);
      msg.addHits(value);
      break;
    case 2:
      var value = new proto.RequesterMessageSearchResponse.Total;
      reader.readMessage(value,proto.RequesterMessageSearchResponse.Total.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequesterMessageSearchResponse.Hits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequesterMessageSearchResponse.Hits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequesterMessageSearchResponse.Hits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterMessageSearchResponse.Hits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.RequesterMessageSearchResponse.Item.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.RequesterMessageSearchResponse.Total.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Item hits = 1;
 * @return {!Array<!proto.RequesterMessageSearchResponse.Item>}
 */
proto.RequesterMessageSearchResponse.Hits.prototype.getHitsList = function() {
  return /** @type{!Array<!proto.RequesterMessageSearchResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.RequesterMessageSearchResponse.Item, 1));
};


/**
 * @param {!Array<!proto.RequesterMessageSearchResponse.Item>} value
 * @return {!proto.RequesterMessageSearchResponse.Hits} returns this
*/
proto.RequesterMessageSearchResponse.Hits.prototype.setHitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.RequesterMessageSearchResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RequesterMessageSearchResponse.Item}
 */
proto.RequesterMessageSearchResponse.Hits.prototype.addHits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.RequesterMessageSearchResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.RequesterMessageSearchResponse.Hits} returns this
 */
proto.RequesterMessageSearchResponse.Hits.prototype.clearHitsList = function() {
  return this.setHitsList([]);
};


/**
 * optional Total total = 2;
 * @return {?proto.RequesterMessageSearchResponse.Total}
 */
proto.RequesterMessageSearchResponse.Hits.prototype.getTotal = function() {
  return /** @type{?proto.RequesterMessageSearchResponse.Total} */ (
    jspb.Message.getWrapperField(this, proto.RequesterMessageSearchResponse.Total, 2));
};


/**
 * @param {?proto.RequesterMessageSearchResponse.Total|undefined} value
 * @return {!proto.RequesterMessageSearchResponse.Hits} returns this
*/
proto.RequesterMessageSearchResponse.Hits.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequesterMessageSearchResponse.Hits} returns this
 */
proto.RequesterMessageSearchResponse.Hits.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequesterMessageSearchResponse.Hits.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Hits hits = 1;
 * @return {?proto.RequesterMessageSearchResponse.Hits}
 */
proto.RequesterMessageSearchResponse.prototype.getHits = function() {
  return /** @type{?proto.RequesterMessageSearchResponse.Hits} */ (
    jspb.Message.getWrapperField(this, proto.RequesterMessageSearchResponse.Hits, 1));
};


/**
 * @param {?proto.RequesterMessageSearchResponse.Hits|undefined} value
 * @return {!proto.RequesterMessageSearchResponse} returns this
*/
proto.RequesterMessageSearchResponse.prototype.setHits = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequesterMessageSearchResponse} returns this
 */
proto.RequesterMessageSearchResponse.prototype.clearHits = function() {
  return this.setHits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequesterMessageSearchResponse.prototype.hasHits = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequesterConversationWithPartialReplies.prototype.toObject = function(opt_includeInstance) {
  return proto.RequesterConversationWithPartialReplies.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequesterConversationWithPartialReplies} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterConversationWithPartialReplies.toObject = function(includeInstance, msg) {
  var f, obj = {
    conversation: (f = msg.getConversation()) && proto.RequesterConversation.toObject(includeInstance, f),
    replyCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastReply: (f = msg.getLastReply()) && proto.RequesterMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequesterConversationWithPartialReplies}
 */
proto.RequesterConversationWithPartialReplies.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequesterConversationWithPartialReplies;
  return proto.RequesterConversationWithPartialReplies.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequesterConversationWithPartialReplies} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequesterConversationWithPartialReplies}
 */
proto.RequesterConversationWithPartialReplies.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RequesterConversation;
      reader.readMessage(value,proto.RequesterConversation.deserializeBinaryFromReader);
      msg.setConversation(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReplyCount(value);
      break;
    case 3:
      var value = new proto.RequesterMessage;
      reader.readMessage(value,proto.RequesterMessage.deserializeBinaryFromReader);
      msg.setLastReply(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequesterConversationWithPartialReplies.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequesterConversationWithPartialReplies.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequesterConversationWithPartialReplies} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterConversationWithPartialReplies.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConversation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.RequesterConversation.serializeBinaryToWriter
    );
  }
  f = message.getReplyCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLastReply();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.RequesterMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequesterConversation conversation = 1;
 * @return {?proto.RequesterConversation}
 */
proto.RequesterConversationWithPartialReplies.prototype.getConversation = function() {
  return /** @type{?proto.RequesterConversation} */ (
    jspb.Message.getWrapperField(this, proto.RequesterConversation, 1));
};


/**
 * @param {?proto.RequesterConversation|undefined} value
 * @return {!proto.RequesterConversationWithPartialReplies} returns this
*/
proto.RequesterConversationWithPartialReplies.prototype.setConversation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequesterConversationWithPartialReplies} returns this
 */
proto.RequesterConversationWithPartialReplies.prototype.clearConversation = function() {
  return this.setConversation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequesterConversationWithPartialReplies.prototype.hasConversation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 reply_count = 2;
 * @return {number}
 */
proto.RequesterConversationWithPartialReplies.prototype.getReplyCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.RequesterConversationWithPartialReplies} returns this
 */
proto.RequesterConversationWithPartialReplies.prototype.setReplyCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional RequesterMessage last_reply = 3;
 * @return {?proto.RequesterMessage}
 */
proto.RequesterConversationWithPartialReplies.prototype.getLastReply = function() {
  return /** @type{?proto.RequesterMessage} */ (
    jspb.Message.getWrapperField(this, proto.RequesterMessage, 3));
};


/**
 * @param {?proto.RequesterMessage|undefined} value
 * @return {!proto.RequesterConversationWithPartialReplies} returns this
*/
proto.RequesterConversationWithPartialReplies.prototype.setLastReply = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequesterConversationWithPartialReplies} returns this
 */
proto.RequesterConversationWithPartialReplies.prototype.clearLastReply = function() {
  return this.setLastReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequesterConversationWithPartialReplies.prototype.hasLastReply = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequesterConversation.prototype.toObject = function(opt_includeInstance) {
  return proto.RequesterConversation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequesterConversation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterConversation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tenantId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    botId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    message: (f = msg.getMessage()) && proto.RequesterMessage.toObject(includeInstance, f),
    activityMessagePlanId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    activityMessagePublishLogId: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequesterConversation}
 */
proto.RequesterConversation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequesterConversation;
  return proto.RequesterConversation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequesterConversation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequesterConversation}
 */
proto.RequesterConversation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBotId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 7:
      var value = new proto.RequesterMessage;
      reader.readMessage(value,proto.RequesterMessage.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivityMessagePlanId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivityMessagePublishLogId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequesterConversation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequesterConversation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequesterConversation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterConversation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBotId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.RequesterMessage.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.RequesterConversation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RequesterConversation} returns this
 */
proto.RequesterConversation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.RequesterConversation.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.RequesterConversation} returns this
*/
proto.RequesterConversation.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequesterConversation} returns this
 */
proto.RequesterConversation.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequesterConversation.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.RequesterConversation.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.RequesterConversation} returns this
*/
proto.RequesterConversation.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequesterConversation} returns this
 */
proto.RequesterConversation.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequesterConversation.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string tenant_id = 4;
 * @return {string}
 */
proto.RequesterConversation.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.RequesterConversation} returns this
 */
proto.RequesterConversation.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 bot_id = 5;
 * @return {number}
 */
proto.RequesterConversation.prototype.getBotId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.RequesterConversation} returns this
 */
proto.RequesterConversation.prototype.setBotId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string user_id = 6;
 * @return {string}
 */
proto.RequesterConversation.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.RequesterConversation} returns this
 */
proto.RequesterConversation.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional RequesterMessage message = 7;
 * @return {?proto.RequesterMessage}
 */
proto.RequesterConversation.prototype.getMessage = function() {
  return /** @type{?proto.RequesterMessage} */ (
    jspb.Message.getWrapperField(this, proto.RequesterMessage, 7));
};


/**
 * @param {?proto.RequesterMessage|undefined} value
 * @return {!proto.RequesterConversation} returns this
*/
proto.RequesterConversation.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequesterConversation} returns this
 */
proto.RequesterConversation.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequesterConversation.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint32 activity_message_plan_id = 8;
 * @return {number}
 */
proto.RequesterConversation.prototype.getActivityMessagePlanId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.RequesterConversation} returns this
 */
proto.RequesterConversation.prototype.setActivityMessagePlanId = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.RequesterConversation} returns this
 */
proto.RequesterConversation.prototype.clearActivityMessagePlanId = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequesterConversation.prototype.hasActivityMessagePlanId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint32 activity_message_publish_log_id = 9;
 * @return {number}
 */
proto.RequesterConversation.prototype.getActivityMessagePublishLogId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.RequesterConversation} returns this
 */
proto.RequesterConversation.prototype.setActivityMessagePublishLogId = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.RequesterConversation} returns this
 */
proto.RequesterConversation.prototype.clearActivityMessagePublishLogId = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequesterConversation.prototype.hasActivityMessagePublishLogId = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequesterConversationSearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RequesterConversationSearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequesterConversationSearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterConversationSearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hits: (f = msg.getHits()) && proto.RequesterConversationSearchResponse.Hits.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequesterConversationSearchResponse}
 */
proto.RequesterConversationSearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequesterConversationSearchResponse;
  return proto.RequesterConversationSearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequesterConversationSearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequesterConversationSearchResponse}
 */
proto.RequesterConversationSearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RequesterConversationSearchResponse.Hits;
      reader.readMessage(value,proto.RequesterConversationSearchResponse.Hits.deserializeBinaryFromReader);
      msg.setHits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequesterConversationSearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequesterConversationSearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequesterConversationSearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterConversationSearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHits();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.RequesterConversationSearchResponse.Hits.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequesterConversationSearchResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.RequesterConversationSearchResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequesterConversationSearchResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterConversationSearchResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    source: (f = msg.getSource()) && proto.RequesterConversation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequesterConversationSearchResponse.Item}
 */
proto.RequesterConversationSearchResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequesterConversationSearchResponse.Item;
  return proto.RequesterConversationSearchResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequesterConversationSearchResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequesterConversationSearchResponse.Item}
 */
proto.RequesterConversationSearchResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.RequesterConversation;
      reader.readMessage(value,proto.RequesterConversation.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequesterConversationSearchResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequesterConversationSearchResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequesterConversationSearchResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterConversationSearchResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.RequesterConversation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.RequesterConversationSearchResponse.Item.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RequesterConversationSearchResponse.Item} returns this
 */
proto.RequesterConversationSearchResponse.Item.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RequesterConversation source = 2;
 * @return {?proto.RequesterConversation}
 */
proto.RequesterConversationSearchResponse.Item.prototype.getSource = function() {
  return /** @type{?proto.RequesterConversation} */ (
    jspb.Message.getWrapperField(this, proto.RequesterConversation, 2));
};


/**
 * @param {?proto.RequesterConversation|undefined} value
 * @return {!proto.RequesterConversationSearchResponse.Item} returns this
*/
proto.RequesterConversationSearchResponse.Item.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequesterConversationSearchResponse.Item} returns this
 */
proto.RequesterConversationSearchResponse.Item.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequesterConversationSearchResponse.Item.prototype.hasSource = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.RequesterConversationSearchResponse.Hits.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequesterConversationSearchResponse.Hits.prototype.toObject = function(opt_includeInstance) {
  return proto.RequesterConversationSearchResponse.Hits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequesterConversationSearchResponse.Hits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterConversationSearchResponse.Hits.toObject = function(includeInstance, msg) {
  var f, obj = {
    hitsList: jspb.Message.toObjectList(msg.getHitsList(),
    proto.RequesterConversationSearchResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequesterConversationSearchResponse.Hits}
 */
proto.RequesterConversationSearchResponse.Hits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequesterConversationSearchResponse.Hits;
  return proto.RequesterConversationSearchResponse.Hits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequesterConversationSearchResponse.Hits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequesterConversationSearchResponse.Hits}
 */
proto.RequesterConversationSearchResponse.Hits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RequesterConversationSearchResponse.Item;
      reader.readMessage(value,proto.RequesterConversationSearchResponse.Item.deserializeBinaryFromReader);
      msg.addHits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequesterConversationSearchResponse.Hits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequesterConversationSearchResponse.Hits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequesterConversationSearchResponse.Hits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequesterConversationSearchResponse.Hits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.RequesterConversationSearchResponse.Item.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Item hits = 1;
 * @return {!Array<!proto.RequesterConversationSearchResponse.Item>}
 */
proto.RequesterConversationSearchResponse.Hits.prototype.getHitsList = function() {
  return /** @type{!Array<!proto.RequesterConversationSearchResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.RequesterConversationSearchResponse.Item, 1));
};


/**
 * @param {!Array<!proto.RequesterConversationSearchResponse.Item>} value
 * @return {!proto.RequesterConversationSearchResponse.Hits} returns this
*/
proto.RequesterConversationSearchResponse.Hits.prototype.setHitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.RequesterConversationSearchResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RequesterConversationSearchResponse.Item}
 */
proto.RequesterConversationSearchResponse.Hits.prototype.addHits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.RequesterConversationSearchResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.RequesterConversationSearchResponse.Hits} returns this
 */
proto.RequesterConversationSearchResponse.Hits.prototype.clearHitsList = function() {
  return this.setHitsList([]);
};


/**
 * optional Hits hits = 1;
 * @return {?proto.RequesterConversationSearchResponse.Hits}
 */
proto.RequesterConversationSearchResponse.prototype.getHits = function() {
  return /** @type{?proto.RequesterConversationSearchResponse.Hits} */ (
    jspb.Message.getWrapperField(this, proto.RequesterConversationSearchResponse.Hits, 1));
};


/**
 * @param {?proto.RequesterConversationSearchResponse.Hits|undefined} value
 * @return {!proto.RequesterConversationSearchResponse} returns this
*/
proto.RequesterConversationSearchResponse.prototype.setHits = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequesterConversationSearchResponse} returns this
 */
proto.RequesterConversationSearchResponse.prototype.clearHits = function() {
  return this.setHits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequesterConversationSearchResponse.prototype.hasHits = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReplyCountAndLastReplySearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ReplyCountAndLastReplySearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReplyCountAndLastReplySearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    aggregations: (f = msg.getAggregations()) && proto.ReplyCountAndLastReplySearchResponse.Aggregations.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReplyCountAndLastReplySearchResponse}
 */
proto.ReplyCountAndLastReplySearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReplyCountAndLastReplySearchResponse;
  return proto.ReplyCountAndLastReplySearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReplyCountAndLastReplySearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReplyCountAndLastReplySearchResponse}
 */
proto.ReplyCountAndLastReplySearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ReplyCountAndLastReplySearchResponse.Aggregations;
      reader.readMessage(value,proto.ReplyCountAndLastReplySearchResponse.Aggregations.deserializeBinaryFromReader);
      msg.setAggregations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReplyCountAndLastReplySearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReplyCountAndLastReplySearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReplyCountAndLastReplySearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAggregations();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ReplyCountAndLastReplySearchResponse.Aggregations.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.prototype.toObject = function(opt_includeInstance) {
  return proto.ReplyCountAndLastReplySearchResponse.Aggregations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.toObject = function(includeInstance, msg) {
  var f, obj = {
    messages: (f = msg.getMessages()) && proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReplyCountAndLastReplySearchResponse.Aggregations;
  return proto.ReplyCountAndLastReplySearchResponse.Aggregations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages;
      reader.readMessage(value,proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.deserializeBinaryFromReader);
      msg.setMessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReplyCountAndLastReplySearchResponse.Aggregations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessages();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.prototype.toObject = function(opt_includeInstance) {
  return proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.toObject = function(includeInstance, msg) {
  var f, obj = {
    bucketsList: jspb.Message.toObjectList(msg.getBucketsList(),
    proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages;
  return proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket;
      reader.readMessage(value,proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.deserializeBinaryFromReader);
      msg.addBuckets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBucketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.prototype.toObject = function(opt_includeInstance) {
  return proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    topDoc: (f = msg.getTopDoc()) && proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket;
  return proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = new proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc;
      reader.readMessage(value,proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.deserializeBinaryFromReader);
      msg.setTopDoc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTopDoc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.prototype.toObject = function(opt_includeInstance) {
  return proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.toObject = function(includeInstance, msg) {
  var f, obj = {
    hits: (f = msg.getHits()) && proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc;
  return proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits;
      reader.readMessage(value,proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.deserializeBinaryFromReader);
      msg.setHits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHits();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.prototype.toObject = function(opt_includeInstance) {
  return proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: (f = msg.getTotal()) && proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total.toObject(includeInstance, f),
    hitsList: jspb.Message.toObjectList(msg.getHitsList(),
    proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits;
  return proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total;
      reader.readMessage(value,proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    case 2:
      var value = new proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit;
      reader.readMessage(value,proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.deserializeBinaryFromReader);
      msg.addHits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total.serializeBinaryToWriter
    );
  }
  f = message.getHitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total.prototype.toObject = function(opt_includeInstance) {
  return proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total;
  return proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 value = 1;
 * @return {number}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total} returns this
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.prototype.toObject = function(opt_includeInstance) {
  return proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: (f = msg.getSource()) && proto.RequesterMessage.toObject(includeInstance, f),
    sortList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit;
  return proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RequesterMessage;
      reader.readMessage(value,proto.RequesterMessage.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSort(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.RequesterMessage.serializeBinaryToWriter
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional RequesterMessage source = 1;
 * @return {?proto.RequesterMessage}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.prototype.getSource = function() {
  return /** @type{?proto.RequesterMessage} */ (
    jspb.Message.getWrapperField(this, proto.RequesterMessage, 1));
};


/**
 * @param {?proto.RequesterMessage|undefined} value
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit} returns this
*/
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit} returns this
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.prototype.hasSource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated int64 sort = 2;
 * @return {!Array<number>}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.prototype.getSortList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit} returns this
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit} returns this
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit} returns this
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit} returns this
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Total total = 1;
 * @return {?proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.prototype.getTotal = function() {
  return /** @type{?proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total} */ (
    jspb.Message.getWrapperField(this, proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total, 1));
};


/**
 * @param {?proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Total|undefined} value
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits} returns this
*/
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits} returns this
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Hit hits = 2;
 * @return {!Array<!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit>}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.prototype.getHitsList = function() {
  return /** @type{!Array<!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit, 2));
};


/**
 * @param {!Array<!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit>} value
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits} returns this
*/
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.prototype.setHitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.prototype.addHits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.Hit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits} returns this
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits.prototype.clearHitsList = function() {
  return this.setHitsList([]);
};


/**
 * optional Hits hits = 1;
 * @return {?proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.prototype.getHits = function() {
  return /** @type{?proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits} */ (
    jspb.Message.getWrapperField(this, proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits, 1));
};


/**
 * @param {?proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.Hits|undefined} value
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc} returns this
*/
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.prototype.setHits = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc} returns this
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.prototype.clearHits = function() {
  return this.setHits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc.prototype.hasHits = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket} returns this
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TopDoc top_doc = 2;
 * @return {?proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.prototype.getTopDoc = function() {
  return /** @type{?proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc} */ (
    jspb.Message.getWrapperField(this, proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc, 2));
};


/**
 * @param {?proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.TopDoc|undefined} value
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket} returns this
*/
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.prototype.setTopDoc = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket} returns this
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.prototype.clearTopDoc = function() {
  return this.setTopDoc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket.prototype.hasTopDoc = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Bucket buckets = 1;
 * @return {!Array<!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket>}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.prototype.getBucketsList = function() {
  return /** @type{!Array<!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket, 1));
};


/**
 * @param {!Array<!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket>} value
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages} returns this
*/
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.prototype.setBucketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.prototype.addBuckets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.Bucket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages} returns this
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages.prototype.clearBucketsList = function() {
  return this.setBucketsList([]);
};


/**
 * optional Messages messages = 1;
 * @return {?proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.prototype.getMessages = function() {
  return /** @type{?proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages} */ (
    jspb.Message.getWrapperField(this, proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages, 1));
};


/**
 * @param {?proto.ReplyCountAndLastReplySearchResponse.Aggregations.Messages|undefined} value
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations} returns this
*/
proto.ReplyCountAndLastReplySearchResponse.Aggregations.prototype.setMessages = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReplyCountAndLastReplySearchResponse.Aggregations} returns this
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.prototype.clearMessages = function() {
  return this.setMessages(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReplyCountAndLastReplySearchResponse.Aggregations.prototype.hasMessages = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Aggregations aggregations = 1;
 * @return {?proto.ReplyCountAndLastReplySearchResponse.Aggregations}
 */
proto.ReplyCountAndLastReplySearchResponse.prototype.getAggregations = function() {
  return /** @type{?proto.ReplyCountAndLastReplySearchResponse.Aggregations} */ (
    jspb.Message.getWrapperField(this, proto.ReplyCountAndLastReplySearchResponse.Aggregations, 1));
};


/**
 * @param {?proto.ReplyCountAndLastReplySearchResponse.Aggregations|undefined} value
 * @return {!proto.ReplyCountAndLastReplySearchResponse} returns this
*/
proto.ReplyCountAndLastReplySearchResponse.prototype.setAggregations = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ReplyCountAndLastReplySearchResponse} returns this
 */
proto.ReplyCountAndLastReplySearchResponse.prototype.clearAggregations = function() {
  return this.setAggregations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReplyCountAndLastReplySearchResponse.prototype.hasAggregations = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateRequesterConversationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateRequesterConversationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateRequesterConversationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateRequesterConversationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && proto_message_pb.Event.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateRequesterConversationRequest}
 */
proto.CreateRequesterConversationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateRequesterConversationRequest;
  return proto.CreateRequesterConversationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateRequesterConversationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateRequesterConversationRequest}
 */
proto.CreateRequesterConversationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_message_pb.Event;
      reader.readMessage(value,proto_message_pb.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateRequesterConversationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateRequesterConversationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateRequesterConversationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateRequesterConversationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_message_pb.Event.serializeBinaryToWriter
    );
  }
};


/**
 * optional Event event = 1;
 * @return {?proto.Event}
 */
proto.CreateRequesterConversationRequest.prototype.getEvent = function() {
  return /** @type{?proto.Event} */ (
    jspb.Message.getWrapperField(this, proto_message_pb.Event, 1));
};


/**
 * @param {?proto.Event|undefined} value
 * @return {!proto.CreateRequesterConversationRequest} returns this
*/
proto.CreateRequesterConversationRequest.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateRequesterConversationRequest} returns this
 */
proto.CreateRequesterConversationRequest.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateRequesterConversationRequest.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateRequesterMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateRequesterMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateRequesterMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateRequesterMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && proto_message_pb.Event.toObject(includeInstance, f),
    conversationId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateRequesterMessageRequest}
 */
proto.CreateRequesterMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateRequesterMessageRequest;
  return proto.CreateRequesterMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateRequesterMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateRequesterMessageRequest}
 */
proto.CreateRequesterMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_message_pb.Event;
      reader.readMessage(value,proto_message_pb.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConversationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateRequesterMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateRequesterMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateRequesterMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateRequesterMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_message_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getConversationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Event event = 1;
 * @return {?proto.Event}
 */
proto.CreateRequesterMessageRequest.prototype.getEvent = function() {
  return /** @type{?proto.Event} */ (
    jspb.Message.getWrapperField(this, proto_message_pb.Event, 1));
};


/**
 * @param {?proto.Event|undefined} value
 * @return {!proto.CreateRequesterMessageRequest} returns this
*/
proto.CreateRequesterMessageRequest.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateRequesterMessageRequest} returns this
 */
proto.CreateRequesterMessageRequest.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateRequesterMessageRequest.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string conversation_id = 2;
 * @return {string}
 */
proto.CreateRequesterMessageRequest.prototype.getConversationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateRequesterMessageRequest} returns this
 */
proto.CreateRequesterMessageRequest.prototype.setConversationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListRequesterConversationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ListRequesterConversationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListRequesterConversationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRequesterConversationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxUpdatedAt: (f = msg.getMaxUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    minUpdatedAt: (f = msg.getMinUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListRequesterConversationsRequest}
 */
proto.ListRequesterConversationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListRequesterConversationsRequest;
  return proto.ListRequesterConversationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListRequesterConversationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListRequesterConversationsRequest}
 */
proto.ListRequesterConversationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMaxUpdatedAt(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMinUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListRequesterConversationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListRequesterConversationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListRequesterConversationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRequesterConversationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMinUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp max_updated_at = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ListRequesterConversationsRequest.prototype.getMaxUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ListRequesterConversationsRequest} returns this
*/
proto.ListRequesterConversationsRequest.prototype.setMaxUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListRequesterConversationsRequest} returns this
 */
proto.ListRequesterConversationsRequest.prototype.clearMaxUpdatedAt = function() {
  return this.setMaxUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListRequesterConversationsRequest.prototype.hasMaxUpdatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp min_updated_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ListRequesterConversationsRequest.prototype.getMinUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ListRequesterConversationsRequest} returns this
*/
proto.ListRequesterConversationsRequest.prototype.setMinUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListRequesterConversationsRequest} returns this
 */
proto.ListRequesterConversationsRequest.prototype.clearMinUpdatedAt = function() {
  return this.setMinUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListRequesterConversationsRequest.prototype.hasMinUpdatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ListRequesterConversationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListRequesterConversationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ListRequesterConversationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListRequesterConversationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRequesterConversationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    conversationsList: jspb.Message.toObjectList(msg.getConversationsList(),
    proto.RequesterConversationWithPartialReplies.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListRequesterConversationsResponse}
 */
proto.ListRequesterConversationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListRequesterConversationsResponse;
  return proto.ListRequesterConversationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListRequesterConversationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListRequesterConversationsResponse}
 */
proto.ListRequesterConversationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RequesterConversationWithPartialReplies;
      reader.readMessage(value,proto.RequesterConversationWithPartialReplies.deserializeBinaryFromReader);
      msg.addConversations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListRequesterConversationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListRequesterConversationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListRequesterConversationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRequesterConversationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConversationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.RequesterConversationWithPartialReplies.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RequesterConversationWithPartialReplies conversations = 1;
 * @return {!Array<!proto.RequesterConversationWithPartialReplies>}
 */
proto.ListRequesterConversationsResponse.prototype.getConversationsList = function() {
  return /** @type{!Array<!proto.RequesterConversationWithPartialReplies>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.RequesterConversationWithPartialReplies, 1));
};


/**
 * @param {!Array<!proto.RequesterConversationWithPartialReplies>} value
 * @return {!proto.ListRequesterConversationsResponse} returns this
*/
proto.ListRequesterConversationsResponse.prototype.setConversationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.RequesterConversationWithPartialReplies=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RequesterConversationWithPartialReplies}
 */
proto.ListRequesterConversationsResponse.prototype.addConversations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.RequesterConversationWithPartialReplies, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListRequesterConversationsResponse} returns this
 */
proto.ListRequesterConversationsResponse.prototype.clearConversationsList = function() {
  return this.setConversationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListRequesterMessagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ListRequesterMessagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListRequesterMessagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRequesterMessagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    conversationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    maxUpdatedAt: (f = msg.getMaxUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    minUpdatedAt: (f = msg.getMinUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListRequesterMessagesRequest}
 */
proto.ListRequesterMessagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListRequesterMessagesRequest;
  return proto.ListRequesterMessagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListRequesterMessagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListRequesterMessagesRequest}
 */
proto.ListRequesterMessagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConversationId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMaxUpdatedAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMinUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListRequesterMessagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListRequesterMessagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListRequesterMessagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRequesterMessagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConversationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMaxUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMinUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string conversation_id = 1;
 * @return {string}
 */
proto.ListRequesterMessagesRequest.prototype.getConversationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ListRequesterMessagesRequest} returns this
 */
proto.ListRequesterMessagesRequest.prototype.setConversationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp max_updated_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ListRequesterMessagesRequest.prototype.getMaxUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ListRequesterMessagesRequest} returns this
*/
proto.ListRequesterMessagesRequest.prototype.setMaxUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListRequesterMessagesRequest} returns this
 */
proto.ListRequesterMessagesRequest.prototype.clearMaxUpdatedAt = function() {
  return this.setMaxUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListRequesterMessagesRequest.prototype.hasMaxUpdatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp min_updated_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ListRequesterMessagesRequest.prototype.getMinUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ListRequesterMessagesRequest} returns this
*/
proto.ListRequesterMessagesRequest.prototype.setMinUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListRequesterMessagesRequest} returns this
 */
proto.ListRequesterMessagesRequest.prototype.clearMinUpdatedAt = function() {
  return this.setMinUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListRequesterMessagesRequest.prototype.hasMinUpdatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ListRequesterMessagesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListRequesterMessagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ListRequesterMessagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListRequesterMessagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRequesterMessagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    messagesList: jspb.Message.toObjectList(msg.getMessagesList(),
    proto.RequesterMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListRequesterMessagesResponse}
 */
proto.ListRequesterMessagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListRequesterMessagesResponse;
  return proto.ListRequesterMessagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListRequesterMessagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListRequesterMessagesResponse}
 */
proto.ListRequesterMessagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RequesterMessage;
      reader.readMessage(value,proto.RequesterMessage.deserializeBinaryFromReader);
      msg.addMessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListRequesterMessagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListRequesterMessagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListRequesterMessagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRequesterMessagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.RequesterMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RequesterMessage messages = 1;
 * @return {!Array<!proto.RequesterMessage>}
 */
proto.ListRequesterMessagesResponse.prototype.getMessagesList = function() {
  return /** @type{!Array<!proto.RequesterMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.RequesterMessage, 1));
};


/**
 * @param {!Array<!proto.RequesterMessage>} value
 * @return {!proto.ListRequesterMessagesResponse} returns this
*/
proto.ListRequesterMessagesResponse.prototype.setMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.RequesterMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RequesterMessage}
 */
proto.ListRequesterMessagesResponse.prototype.addMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.RequesterMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListRequesterMessagesResponse} returns this
 */
proto.ListRequesterMessagesResponse.prototype.clearMessagesList = function() {
  return this.setMessagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.WelcomeMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.WelcomeMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.WelcomeMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WelcomeMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    conversation: (f = msg.getConversation()) && proto.RequesterConversation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.WelcomeMessageResponse}
 */
proto.WelcomeMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.WelcomeMessageResponse;
  return proto.WelcomeMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.WelcomeMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.WelcomeMessageResponse}
 */
proto.WelcomeMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RequesterConversation;
      reader.readMessage(value,proto.RequesterConversation.deserializeBinaryFromReader);
      msg.setConversation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.WelcomeMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.WelcomeMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.WelcomeMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WelcomeMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConversation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.RequesterConversation.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequesterConversation conversation = 1;
 * @return {?proto.RequesterConversation}
 */
proto.WelcomeMessageResponse.prototype.getConversation = function() {
  return /** @type{?proto.RequesterConversation} */ (
    jspb.Message.getWrapperField(this, proto.RequesterConversation, 1));
};


/**
 * @param {?proto.RequesterConversation|undefined} value
 * @return {!proto.WelcomeMessageResponse} returns this
*/
proto.WelcomeMessageResponse.prototype.setConversation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.WelcomeMessageResponse} returns this
 */
proto.WelcomeMessageResponse.prototype.clearConversation = function() {
  return this.setConversation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.WelcomeMessageResponse.prototype.hasConversation = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto);
