import { GripperBarHorizontalIcon } from '@fluentui/react-icons-mdl2'
import { Box, Button, Text, TrashCanIcon } from '@fluentui/react-northstar'
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Column, Row, useTable } from 'react-table'

import { DragItem, hoverFunc } from '../../draggableRow'
import styles from './TicketCustomFieldOptionList.module.css'

interface Option {
  value: string
  sequence: number
}

const dndItemType = 'optionRow'

const DraggableRow = ({
  row,
  index,
  moveRow,
}: {
  row: Row<Option>
  index: number
  moveRow: (i: number, j: number) => void
}) => {
  const dropRef = useRef<HTMLTableRowElement>(null)
  const dragRef = useRef(null)

  const [, drop] = useDrop({
    accept: dndItemType,
    hover(item: DragItem, monitor) {
      hoverFunc(index, dropRef, moveRow, item, monitor)
    },
  })

  const [, drag, preview] = useDrag({
    type: dndItemType,
    item: { type: dndItemType, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  preview(drop(dropRef))
  drag(dragRef)

  return (
    <tr ref={dropRef} className={styles.tr}>
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            key={cell.getCellProps().key}
            className={styles.td}
          >
            {cell.value && (
              <span ref={dragRef} className={styles.iconcontainer}>
                <GripperBarHorizontalIcon className={styles.icon} />
              </span>
            )}
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}

export const TicketCustomFieldOptionList: FC<{
  options: string[]
  setOptions: Dispatch<SetStateAction<string[]>>
  needsOptions: boolean
}> = ({ options, setOptions, needsOptions }) => {
  const columns: Column<Option>[] = useMemo(
    () => [
      {
        Header: '名前',
        accessor: 'value',
        Cell: ({ cell }) => {
          const [newValue, setNewValue] = useState<string>(cell.value)
          const [isComposing, setIsComposing] = useState(false)
          return (
            <>
              <input
                className={`${styles.input} ${!cell.value && styles.newInput}`}
                value={newValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setNewValue(e.target.value)
                }}
                onBlur={(e) => {
                  // 重複していたら元に戻す
                  if (
                    options.find(
                      (o, i) =>
                        cell.row.original.sequence !== i &&
                        o === e.target.value.trim()
                    )
                  ) {
                    setNewValue(cell.row.original.value)
                    return
                  }
                  // 設定した値が空だったら削除、空じゃなかったら更新
                  const newOptions =
                    e.target.value.trim() !== ''
                      ? options.map((o, i) =>
                          i === cell.row.original.sequence
                            ? e.target.value.trim()
                            : o
                        )
                      : options.filter(
                          (_, i) => i !== cell.row.original.sequence
                        )

                  // 新規追加行がなくなっていれば追加
                  if (newOptions.findIndex((o) => o === '') === -1) {
                    newOptions.push('')
                  }
                  setOptions(newOptions)
                }}
                onCompositionStart={() => setIsComposing(true)}
                onCompositionEnd={() => setIsComposing(false)}
                onKeyDown={(e) => {
                  if (e.code === 'Enter' && !isComposing) {
                    e.currentTarget.blur()
                  }
                }}
                placeholder="新しい選択肢の追加"
                maxLength={255}
              />
              {cell.value && (
                <Button
                  icon={<TrashCanIcon />}
                  text
                  iconOnly
                  title="Delete"
                  onClick={() => {
                    setOptions(
                      options.filter((_, i) => i !== cell.row.original.sequence)
                    )
                  }}
                  className={styles.deleteButton}
                />
              )}
            </>
          )
        },
      },
    ],
    [options, setOptions]
  )
  const { getTableProps, getTableBodyProps, rows, prepareRow } =
    useTable<Option>({
      columns,
      data: options.map((value, i) => ({ value, sequence: i })),
      getRowId: (row) => row.sequence.toString(),
    })

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    if (hoverIndex === options.length - 1) {
      return
    }

    const a = options[dragIndex]
    const b = options[hoverIndex]
    options[dragIndex] = b
    options[hoverIndex] = a

    setOptions([...options])
  }

  return needsOptions ? (
    <>
      <Text weight="bold">選択肢と表示順序</Text>
      <Box className={styles.container}>
        <table {...getTableProps()} className={styles.table}>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <DraggableRow
                  index={index}
                  row={row}
                  moveRow={moveRow}
                  {...row.getRowProps()}
                  key={row.getRowProps().key}
                />
              )
            })}
          </tbody>
        </table>
      </Box>
    </>
  ) : null
}
