import * as RTK from '@reduxjs/toolkit'

export type State = {
  open: boolean
}

const initialState: State = {
  open: true,
}

const slice = RTK.createSlice({
  name: 'recommendedFaqRedundantIntentList',
  initialState,
  reducers: {
    open: (state) => {
      state.open = true
    },
    close: (state) => {
      state.open = false
    },
  },
})

export const { reducer, actions } = slice
