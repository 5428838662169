import { Text as FluentText } from '@fluentui/react-northstar'
import { BarChart, Card } from '@tremor/react'
import * as React from 'react'

export type Weekday = '日' | '月' | '火' | '水' | '木' | '金' | '土'

export type ChartRow = {
  weekday: string
  ['平均問い合わせ数']: number
}
type Props = {
  data: ChartRow[]
}
const WeekdayTicketsChartPresenter: React.FC<Props> = (props) => {
  return (
    <Card>
      <FluentText size="small" weight="bold">
        曜日別平均問い合わせ数
      </FluentText>
      <BarChart
        data={props.data}
        categories={['平均問い合わせ数']}
        dataKey="weekday"
      />
    </Card>
  )
}

export default React.memo(WeekdayTicketsChartPresenter)
