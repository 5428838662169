import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { retry } from '@reduxjs/toolkit/query/react'

import { addAlert } from '../../features/alert/alertsSlice'
import {
  AvailableActivityMessageRequest,
  AvailableActivityMessageResponse,
} from '../../proto/activity_message_pb'
import { ActivityMessageService } from '../../proto/activity_message_pb_service'
import { CreateFaqRequest, Faq, FaqRequestFrom } from '../../proto/faq_pb'
import { FaqAPI } from '../../proto/faq_pb_service'
import { Ticket } from '../../proto/ticket_pb'
import { grpcBaseQuery } from './lib/grpcBaseQuery'

// literal typeを作成する。ticket_detail or recommended_faqという文字列
type FaqRequestFromLiteral = 'ticket_detail' | 'recommended_faq'

export const deskOperatorApi = createApi({
  reducerPath: 'deskOperatorApi',
  baseQuery: retry(grpcBaseQuery(), { maxRetries: 3 }),
  endpoints: (build) => ({
    createFaq: build.mutation<
      Ticket.AsObject,
      Partial<Faq.AsObject & { from: FaqRequestFromLiteral }>
    >({
      query: (args) => ({
        service: FaqAPI.CreateFaq,
        body: (() => {
          const faq = new Faq()
          if (args.request != null) faq.setRequest(args.request)
          if (args.response != null) faq.setResponse(args.response)
          if (args.publish != null) faq.setPublish(args.publish)
          if (args.name != null) faq.setName(args.name)
          if (args.labelsList != null) faq.setLabelsList(args.labelsList)
          if (args.ticketId != null) faq.setTicketId(args.ticketId)
          const req = new CreateFaqRequest()
          req.setFaq(faq)
          switch (args.from) {
            case 'ticket_detail':
              req.setFrom(FaqRequestFrom.FAQ_REQUEST_FROM_TICKET_DETAIL)
              break
            case 'recommended_faq':
              req.setFrom(FaqRequestFrom.FAQ_REQUEST_FROM_RECOMMENDED_FAQ)
              break
            default:
              req.setFrom(FaqRequestFrom.FAQ_REQUEST_FROM_UNKNOWN)
          }
          return req
        })(),
      }),
      transformResponse: (res: Ticket.AsObject) => {
        return res
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(
          addAlert({
            id: Math.random() * 1000,
            title: 'FAQの作成が完了しました',
            type: 'success_announcement',
          })
        )
      },
      invalidatesTags: (result) => {
        return result ? ['RecommendedFaqs'] : []
      },
    }),
    isAvailableActivityMessage: build.query<boolean, void>({
      query: () => ({
        service: ActivityMessageService.AvailableActivityMessage,
        body: (() => {
          const req = new AvailableActivityMessageRequest()
          return req
        })(),
      }),
      transformResponse(result: AvailableActivityMessageResponse.AsObject) {
        return result.isAvailable
      },
    }),
  }),
  tagTypes: [
    'RecommendedFaqs',
    'RecommendedFaqRedundantIntents',
    'RecommendedFaqTickets',
    'RecommendedFaqTicketManualMessages',
    'ActivityMessagePlans',
    'ActivityMessagePlanDetail',
  ],
})

export const { useCreateFaqMutation, useIsAvailableActivityMessageQuery } =
  deskOperatorApi
