// package: 
// file: proto/tab_config.proto

var proto_tab_config_pb = require("../proto/tab_config_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TabConfigNewAPI = (function () {
  function TabConfigNewAPI() {}
  TabConfigNewAPI.serviceName = "TabConfigNewAPI";
  return TabConfigNewAPI;
}());

TabConfigNewAPI.GetNewTabConfig = {
  methodName: "GetNewTabConfig",
  service: TabConfigNewAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_tab_config_pb.GetNewTabConfigRequest,
  responseType: proto_tab_config_pb.TabConfig
};

TabConfigNewAPI.UpdateNewTabConfig = {
  methodName: "UpdateNewTabConfig",
  service: TabConfigNewAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_tab_config_pb.UpdateNewTabConfigRequest,
  responseType: google_protobuf_empty_pb.Empty
};

TabConfigNewAPI.CreateNewTabConfig = {
  methodName: "CreateNewTabConfig",
  service: TabConfigNewAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_tab_config_pb.CreateNewTabConfigRequest,
  responseType: google_protobuf_empty_pb.Empty
};

exports.TabConfigNewAPI = TabConfigNewAPI;

function TabConfigNewAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TabConfigNewAPIClient.prototype.getNewTabConfig = function getNewTabConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TabConfigNewAPI.GetNewTabConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TabConfigNewAPIClient.prototype.updateNewTabConfig = function updateNewTabConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TabConfigNewAPI.UpdateNewTabConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TabConfigNewAPIClient.prototype.createNewTabConfig = function createNewTabConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TabConfigNewAPI.CreateNewTabConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TabConfigNewAPIClient = TabConfigNewAPIClient;

