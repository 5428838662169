import React from 'react'
import { useDispatch } from 'react-redux'

import { Dropdown } from '../../components/Dropdown'
import {
  DefaultFields,
  FaqStatus,
  FaqStatusLabels,
  TicketStatus,
  TicketStatusLabels,
  Tickets,
} from '../../consts'
import { TicketCustomField } from '../../proto/ticket_custom_field_pb'
import type { PulldownItem } from '../assigneeSelection/types/PulldownItem'
import style from './TabConfig.module.css'
import TabConfigCreatedAtFilter from './TabConfigCreatedAtFilter'
import TicketCustomFieldValueFilter from './tabConfigCustomFieldFilter/TicketCustomFieldValueFilter'
import {
  DefaultTabConfig,
  isStringValueFilter,
  setAssignedUserIds,
  setFaqStatuses,
  setStatuses,
} from './tabConfigSlice'

type Props = {
  tabConfigFilterIndex: number
  tabConfigState: DefaultTabConfig
  customFields: TicketCustomField.AsObject[]
  renderAssigneeSelection: (
    values: string[],
    extendedItems: PulldownItem[],
    onChange: (values: string[]) => void
  ) => React.ReactElement
}

const CustomFilter: React.FC<Props> = ({
  tabConfigFilterIndex,
  tabConfigState,
  customFields,
  renderAssigneeSelection,
}) => {
  const dispatch = useDispatch()

  const filter = tabConfigState.filters[tabConfigFilterIndex].filter

  return (
    <>
      {tabConfigState.filters[tabConfigFilterIndex].identifierName ===
        DefaultFields.Status.value && (
        <Dropdown
          fluid
          multiple
          placeholder={'選択してください'}
          clearable
          className={style.filter}
          checkable
          value={isStringValueFilter(filter) ? filter.values : []}
          items={[
            {
              value: TicketStatus.new,
              header: TicketStatusLabels[TicketStatus.new],
            },
            {
              value: TicketStatus.working,
              header: TicketStatusLabels[TicketStatus.working],
            },
            {
              value: TicketStatus.pending,
              header: TicketStatusLabels[TicketStatus.pending],
            },
            {
              value: TicketStatus.completed,
              header: TicketStatusLabels[TicketStatus.completed],
            },
          ]}
          onValueChange={(values) => {
            dispatch(
              setStatuses({
                statuses: values.map((v) => v as TicketStatus),
                tabConfigFilterIndex,
              })
            )
          }}
        />
      )}
      {tabConfigState.filters[tabConfigFilterIndex].identifierName ===
        DefaultFields.AssignedUserId.value && (
        <>
          {renderAssigneeSelection(
            isStringValueFilter(filter) ? filter.values : [],
            [
              {
                value: '',
                header: Tickets.Users.UserNotIndicated,
              },
              {
                value: Tickets.Users.loginUserId,
                header: '(ログインユーザー)',
              },
            ],
            (values) =>
              dispatch(
                setAssignedUserIds({
                  tabConfigFilterIndex,
                  assignedUserIds: values,
                })
              )
          )}
        </>
      )}
      {tabConfigState.filters[tabConfigFilterIndex].identifierName ===
        DefaultFields.CreatedAt.value && (
        <TabConfigCreatedAtFilter
          tabConfigFilterIndex={tabConfigFilterIndex}
          tabConfigFilterState={tabConfigState}
        />
      )}
      {tabConfigState.filters[tabConfigFilterIndex].ticketCustomFieldId !=
        null && (
        <TicketCustomFieldValueFilter
          customField={
            customFields.filter((field) => {
              return (
                field.id ===
                tabConfigState.filters[tabConfigFilterIndex].ticketCustomFieldId
              )
            })[0]
          }
          tabConfigFilterIndex={tabConfigFilterIndex}
        />
      )}
      {tabConfigState.filters[tabConfigFilterIndex].identifierName ===
        DefaultFields.FaqStatus.value && (
        <Dropdown
          fluid
          placeholder={'選択してください'}
          clearable
          className={style.filter}
          checkable
          value={isStringValueFilter(filter) ? filter.values : []}
          items={[
            {
              value: FaqStatus.created,
              header: FaqStatusLabels[FaqStatus.created],
            },
          ]}
          onValueChange={(values) => {
            dispatch(
              setFaqStatuses({
                statuses: values.map((v) => v as FaqStatus),
                tabConfigFilterIndex,
              })
            )
          }}
        />
      )}
    </>
  )
}

export default CustomFilter
