import { setTimeout } from 'timers'

import {
  Alert,
  Box,
  Button,
  Datepicker,
  DatepickerProps,
  Flex,
  Table,
  Text,
} from '@fluentui/react-northstar'
import { differenceInCalendarDays } from 'date-fns'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { format } from '../../utils'
import { exchangeToken } from '../auth/authSlice'
import { createTenantAppCSV } from './tenantAppCSVSlice'
import styles from './TicketList.module.css'

enum ExportState {
  idle = 'idle',
  exchangeTokenStart = 'exchangeTokenStart',
  exchangeTokenWait = 'exchangeTokenWait',
  exchangeTokenComplete = 'exchangeTokenComplete',
  createTenantAppCSVStart = 'createTenantAppCSVStart',
  createTenantAppCSVWait = 'createTenantAppCSVWait',
  createTenantAppCSVComplete = 'createTenantAppCSVComplete',
}

const TenantAppCSVExportDialogContent: React.FC = () => {
  const dispatch = useDispatch()

  // RootState
  const authState = useSelector((state: RootState) => state.auth)
  const exportLogState = useSelector((state: RootState) => state.tenantAppCSV)

  // DatePicker
  const maxDateRange = 31
  const maxDateDiff = maxDateRange - 1 // NOTE: 減算対象の日付を含めないため１日分減らす
  const now: Date = useMemo(() => new Date(), [])
  const defaultEndDate: Date = useMemo(
    () => new Date(now.getFullYear(), now.getMonth(), now.getDate()),
    [now]
  )
  const defaultStartDate: Date = useMemo(() => defaultEndDate, [defaultEndDate])
  const dateFormatter = useCallback(
    (d: Date): string =>
      `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`,
    []
  )
  const validateDateRange = useCallback(
    (start: Date, end: Date): boolean => {
      if (differenceInCalendarDays(end, start) > maxDateDiff) {
        setErrorMessage('指定できる期間は最大31日間です')
        setCanExport(false)
        return false
      }

      setErrorMessage('')
      setCanExport(true)
      return true
    },
    [maxDateDiff]
  )

  // State
  const [startDate, setStartDate] = useState<Date>(defaultStartDate)
  const [endDate, setEndDate] = useState<Date>(defaultEndDate)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [exportState, setExportState] = useState<ExportState>(ExportState.idle)
  const [canExport, setCanExport] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)

  // ExportLogList
  const exportLogListHeader = useMemo(
    () => ({
      items: ['出力期間', '受付時刻', '有効期限', '状態'],
    }),
    []
  )
  const toExportLogDateRangeCell = (
    startDate: Timestamp.AsObject | undefined,
    endDate: Timestamp.AsObject | undefined
  ): string => {
    const dateFormat = 'yyyy/MM/dd'
    const undefinedDateString = ''
    // NOTE: 終了日は閉区間になっているため、表示上は1秒前を使う
    const endDateForDisplay = endDate
      ? new Timestamp()
          .setSeconds(endDate.seconds - 1)
          .setNanos(endDate.nanos)
          .toObject()
      : undefined
    const formattedStartDate = startDate
      ? format(startDate, dateFormat)
      : undefinedDateString
    const formattedEndDate = endDateForDisplay
      ? format(endDateForDisplay, dateFormat)
      : undefinedDateString
    return `${formattedStartDate} - ${formattedEndDate}`
  }
  const toExportLogStatusCell = useCallback(
    (id: number | undefined, status: string | undefined): JSX.Element => {
      switch (status) {
        case 'finished':
          return (
            <Button
              content="ダウンロード"
              text
              primary
              onClick={() => {
                if (authState.accessToken != null) {
                  const downloadUrl = new URL(
                    `/api/v1/tenant-app-csvs/${id}`,
                    process.env.REACT_APP_SERVER_URL
                  )
                  downloadUrl.searchParams.append(
                    'token',
                    authState.accessToken
                  )
                  window.open(downloadUrl.toString())
                }
              }}
            />
          )
        case 'failed':
          return <Text>失敗</Text>
        default:
          return <Text>データ生成中...</Text>
      }
    },
    [authState.accessToken]
  )
  const exportLogListRows = useMemo(() => {
    const exportLogs = exportLogState.ids.map(
      (id) => exportLogState.entities[id]
    )
    return exportLogs.map((e) => {
      return {
        key: e?.id,
        items: [
          toExportLogDateRangeCell(e?.startDate, e?.endDate),
          format(e?.registeredAt, 'yyyy/MM/dd HH:mm'),
          format(e?.expiredAt, 'yyyy/MM/dd HH:mm'),
          toExportLogStatusCell(e?.id, e?.status),
        ],
      }
    })
  }, [exportLogState.ids, exportLogState.entities, toExportLogStatusCell])

  const handleStartDateChange = useCallback(
    (
      _: React.SyntheticEvent<HTMLElement, Event>,
      data: (DatepickerProps & { value: Date }) | undefined
    ): void => {
      if (!data) {
        return
      }

      const { value } = data
      validateDateRange(value, endDate)
      setStartDate(value)
    },
    [endDate, validateDateRange]
  )

  const handleEndDateChange = useCallback(
    (
      _: React.SyntheticEvent<HTMLElement, Event>,
      data: (DatepickerProps & { value: Date }) | undefined
    ): void => {
      if (!data) {
        return
      }

      const { value } = data
      validateDateRange(startDate, value)
      setEndDate(value)
    },
    [startDate, validateDateRange]
  )

  const handleExport = useCallback(() => {
    if (!canExport) {
      return
    }

    setCanExport(false)
    setLoading(true)
    setExportState(ExportState.exchangeTokenStart)
  }, [canExport])

  // ExportStateの状態遷移によってAPIを直列で呼び出す
  useEffect(() => {
    switch (exportState) {
      case ExportState.exchangeTokenStart:
        dispatch(exchangeToken(authState.ssoToken || ''))
        setExportState(ExportState.exchangeTokenWait)
        return
      case ExportState.exchangeTokenWait:
        if (authState.loading) {
          return
        }
        setExportState(ExportState.exchangeTokenComplete)
        return
      case ExportState.exchangeTokenComplete:
        if (authState.error) {
          setExportState(ExportState.idle)
          setLoading(false)
          setCanExport(true)
          return
        }
        setExportState(ExportState.createTenantAppCSVStart)
        return
      case ExportState.createTenantAppCSVStart:
        dispatch(
          createTenantAppCSV(
            startDate,
            new Date(
              endDate.getFullYear(),
              endDate.getMonth(),
              endDate.getDate() + 1 // NOTE: 終了日の23:59:59まで対象にするため1日分加算
            )
          )
        )
        setExportState(ExportState.createTenantAppCSVWait)
        return
      case ExportState.createTenantAppCSVWait:
        if (exportLogState.loading) {
          return
        }
        setExportState(ExportState.createTenantAppCSVComplete)
        return
      case ExportState.createTenantAppCSVComplete: {
        setExportState(ExportState.idle)
        setLoading(false)
        setCanExport(true)
        if (exportLogState.error) {
          return
        }
        setSuccessMessage('CSV出力を受け付けました')
        const timeoutId = setTimeout(() => setSuccessMessage(''), 5000)
        return () => clearTimeout(timeoutId)
      }
    }
  }, [
    dispatch,
    authState.ssoToken,
    authState.loading,
    authState.error,
    exportLogState.loading,
    exportLogState.error,
    startDate,
    endDate,
    exportState,
  ])

  return (
    <Box>
      <Text
        className={styles.csvExportDialogHeader}
        content="テナントに存在する全てのチャネルに対する過去の問合せ内容をCSV形式で出力します。出力されたファイルは3日間ダウンロードできます。指定できる期間は最大31日間です。"
      />
      <Flex className={styles.csvExportDialogContent} gap="gap.small">
        <Box className={styles.csvExportDialogFormItem}>
          <Text content="開始日" />
          <Datepicker
            allowManualInput={false}
            formatMonthDayYear={dateFormatter}
            defaultSelectedDate={defaultStartDate}
            maxDate={endDate}
            onDateChange={handleStartDateChange}
          />
        </Box>
        <Box className={styles.csvExportDialogFormItem}>
          <Text content="終了日" />
          <Datepicker
            allowManualInput={false}
            formatMonthDayYear={dateFormatter}
            defaultSelectedDate={defaultEndDate}
            minDate={startDate}
            maxDate={now}
            onDateChange={handleEndDateChange}
          />
        </Box>
        <Box className={styles.csvExportDialogFormItem}>
          <Button
            primary
            onClick={handleExport}
            loading={loading}
            disabled={!canExport}
            content="出力"
          />
        </Box>
      </Flex>
      {successMessage ? <Alert success content={successMessage} /> : ''}
      {errorMessage ? <Alert danger content={errorMessage} /> : ''}
      <Box className={styles.csvExportTableWrap}>
        <Table header={exportLogListHeader} />
        <Box className={styles.csvExportTable}>
          <Table rows={exportLogListRows} />
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(TenantAppCSVExportDialogContent)
