import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { OverflowNode } from '@lexical/overflow'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { CharacterLimitPlugin } from '@lexical/react/LexicalCharacterLimitPlugin'
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode'
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { TablePlugin } from '@lexical/react/LexicalTablePlugin'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { mergeRegister } from '@lexical/utils'
import {
  $createParagraphNode,
  $getRoot,
  $insertNodes,
  $setSelection,
  BLUR_COMMAND,
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_LOW,
  FOCUS_COMMAND,
  KEY_MODIFIER_COMMAND,
} from 'lexical'
import * as React from 'react'

import { searchImageTags } from '../RequesterNewTicket/lib/searchImageTags'
import AttachmentList, { FileProp } from './components/AttachmentList'
import CodeblockPlugin from './components/CodeblockPlugin'
import { CodeblockNode } from './components/CodeblockPlugin/node'
import ExtensionToolbarPlugin from './components/ExtensionToolbarPlugin'
import type { ExtensionEvent } from './components/ExtensionToolbarPlugin/types/ExtensionEvent'
import ImagePlugin, { UploadImageResponse } from './components/ImagePlugin'
import { ImageNode } from './components/ImagePlugin/node'
import ImportantPlugin from './components/ImportantPlugin'
import { ImportantNode } from './components/ImportantPlugin/node'
import KeyEventPlugin from './components/KeyEventPlugin'
import RichTextToolbarPlugin from './components/RichTextToolbarPlugin'
import styles from './style.module.css'

const TEXT_LIMIT = 1000

const onError = (e: Error) => {
  console.error(e)
}

type Props = {
  value?: string
  placeholder?: string
  // files
  files: FileProp[]
  isAvailableFileUpload: boolean
  inputFocus?: boolean
  onClickRemoveFile: (id: string) => void
  onClickAppendFile: (files: File[]) => void
  onClickClose: () => void
  // form events
  onClickSend: (value: string) => void
  onChange?: (value: string) => void
  uploadImage: (file: File) => Promise<UploadImageResponse>
}
const RequesterChatInput: React.FC<Props> = (props) => {
  return (
    <LexicalComposer
      initialConfig={{
        // 初期値の設定
        editorState: (editor) => {
          editor.update(() => {
            // stringからDOMへ変換
            const parser = new DOMParser()
            const doc = parser.parseFromString(props.value || '', 'text/html')
            // rootノードを取得
            const root = $getRoot()
            const paragraph = $createParagraphNode()
            // rootノードの初期化
            root.clear()
            // 空の<p>を入れておく（なぜか<p>を入れないとエラーになるため）
            root.append(paragraph)
            // select したノードへDOMを挿入
            root.select()
            $insertNodes($generateNodesFromDOM(editor, doc))
          })
        },
        namespace: 'RequesterChatInput',
        onError,
        nodes: [
          HeadingNode,
          ImageNode,
          OverflowNode,
          ListNode,
          ListItemNode,
          QuoteNode,
          LinkNode,
          ImportantNode,
          HorizontalRuleNode,
          CodeblockNode,
          TableNode,
          TableRowNode,
          TableCellNode,
        ],
        theme: {
          text: {
            bold: 'font-bold',
            italic: 'font-italic',
            underline: 'font-underline',
            strikethrough: 'font-strikethrough',
            underlineStrikethrough: 'font-underline-strikethrough',
          },
          list: {
            nested: {
              listitem: 'nested-list-item',
            },
          },
          quote: 'quote',
        },
      }}
    >
      <Editor {...props} />
    </LexicalComposer>
  )
}

const Editor: React.FC<Props> = (props) => {
  const [editor] = useLexicalComposerContext()
  const [showToolbar, setShowToolbar] = React.useState(false)
  const [isFocused, setIsFocused] = React.useState(false)
  const [isLimited, setIsLimited] = React.useState(false)
  const [isEmptyText, setIsEmptyText] = React.useState(true)
  const [hasImageNode, setHasImageNode] = React.useState(false)

  const clickExtension = React.useCallback(
    (event: ExtensionEvent) => {
      switch (event.type) {
        case 'format':
          setShowToolbar(!showToolbar)
          return
        case 'attachment':
          props.onClickAppendFile(event.files)
          return
        case 'send':
          if (
            !isLimited &&
            (!isEmptyText || props.files.length > 0 || hasImageNode)
          ) {
            editor.update(() => {
              props.onClickSend($generateHtmlFromNodes(editor))
              editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
            })
          }
          return
      }
    },
    [editor, props, showToolbar, isLimited, isEmptyText, hasImageNode]
  )

  React.useEffect(() => {
    return editor.registerTextContentListener((text) => {
      text.length > TEXT_LIMIT ? setIsLimited(true) : setIsLimited(false)
      text.length === 0 ? setIsEmptyText(true) : setIsEmptyText(false)
    })
  })

  const onChange = React.useCallback(() => {
    editor.update(() => {
      props.onChange?.($generateHtmlFromNodes(editor))
    })
  }, [props, editor])

  React.useEffect(() => {
    // once inputFocus is true, focus the editor
    if (props.inputFocus) {
      editor.focus()
    }
  }, [props.inputFocus, editor])

  React.useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          setIsFocused(true)
          return true
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          setIsFocused(false)
          return true
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_MODIFIER_COMMAND,
        (e) => {
          /*
          「control + shift + x」か「command + shift + x」でツールバーを開閉
           e.metaKeyはmacではcommandキー、windowsではwindowsキー押下でtrueになる
          */
          if (
            (e.ctrlKey && e.shiftKey && e.key === 'X') ||
            (e.metaKey && e.shiftKey && e.key === 'x') // 'x'は意図して小文字にしています
          ) {
            setShowToolbar(!showToolbar)
          }

          return true
        },
        COMMAND_PRIORITY_LOW
      )
    )
  }, [editor, showToolbar])

  React.useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const imageTags = searchImageTags($generateHtmlFromNodes(editor))
        setHasImageNode(imageTags != null && imageTags.length > 0)
      })
    })
  }, [editor])

  return (
    <div className={styles.container}>
      <div className={`${styles.inputCardBox}`}>
        {showToolbar && (
          <RichTextToolbarPlugin
            onClickClose={props.onClickClose}
            className={styles.richTextToolbar}
          />
        )}
        <div
          className={`${styles.richTextContainer} ${
            showToolbar && styles.expanded
          } ${isFocused && styles.focused}`}
        >
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className={`${styles.contentEditable} ${
                  showToolbar && styles.expanded
                }`}
              />
            }
            ErrorBoundary={LexicalErrorBoundary}
            placeholder={
              <div className={styles.placeholder}>
                {props.placeholder || 'メッセージを入力してください'}
              </div>
            }
          />
          <AttachmentList
            files={props.files}
            onClickRemove={props.onClickRemoveFile}
            className={styles.attachments}
          />
          <KeyEventPlugin
            isLimited={isLimited}
            isEmptyText={isEmptyText}
            hasImageNodeOrAttachments={hasImageNode || props.files.length > 0}
            showToolbar={showToolbar}
            onClickSend={props.onClickSend}
          />
        </div>
      </div>
      <ExtensionToolbarPlugin
        formatActive={showToolbar}
        isLimited={isLimited}
        isEmptyText={isEmptyText}
        hasImageNodeOrAttachments={hasImageNode || props.files.length > 0}
        fileUploadActive={props.isAvailableFileUpload}
        onClick={clickExtension}
        className={styles.extensionToolbar}
      />
      <ClearEditorPlugin
        onClear={() => {
          const root = $getRoot()
          root.clear()
          $setSelection(null)
          // これがないと返信した最後のスレッドがviewport内に収まるが、スレッドが上に移動して返信を展開してしまう
          // viewportからスレッドが外れた状態で返信が来ると、最新の一件として最下部に移動してしまう
          const activeElement = window.document.activeElement as HTMLElement
          if (activeElement != null) {
            activeElement.blur()
          }
        }}
      />
      <AutoFocusPlugin />
      {props.isAvailableFileUpload && (
        <ImagePlugin uploadImage={props.uploadImage} />
      )}
      <OnChangePlugin onChange={onChange} />
      <div style={{ visibility: 'hidden', height: 0 }}>
        <CharacterLimitPlugin charset="UTF-16" maxLength={TEXT_LIMIT} />
      </div>
      <ListPlugin />
      <LinkPlugin />
      <ImportantPlugin />
      <HorizontalRulePlugin />
      <CodeblockPlugin />
      <TablePlugin />
      <HistoryPlugin />
    </div>
  )
}

export default RequesterChatInput
