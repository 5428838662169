import React from 'react'
import * as ReactRedux from 'react-redux'

import type { RootState } from '../../../../app/store'
import RequesterChatButtonGroup from '../../../../components/RequesterChatButtonGroup'
import { useSendRequesterReplyButtonMutation } from '../../api'
import * as Selectors from './selectors'

type ConversationButtonsProps = {
  conversationId: string
}

export const ConversationButtons: React.FC<ConversationButtonsProps> = (
  props
) => {
  const buttons = ReactRedux.useSelector((state: RootState) =>
    Selectors.ticketMessageButtonsSelector(state, props.conversationId)
  )

  const [sendRequesterReplyButton] = useSendRequesterReplyButtonMutation()

  const clickButtonGroup = React.useCallback(
    (key: string) => {
      const button = buttons.flatMap((row) => row).find((b) => b.key === key)
      if (!button) return
      if (button.url) {
        window.open(button.url, '_blank')
        return
      }
      sendRequesterReplyButton({
        ticketId: props.conversationId,
        text: button.label,
        type: button.type,
        data: button.data,
      })
    },
    [props.conversationId, sendRequesterReplyButton, buttons]
  )

  if (buttons.length === 0) return <></>

  return (
    <RequesterChatButtonGroup buttons={buttons} onClick={clickButtonGroup} />
  )
}
