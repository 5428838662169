import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../app/store'

const faqStateSelector = (state: RootState) => state.faq
const faqLabelStateSelector = (state: RootState) => state.faqLabel

export const faqError = createSelector(faqStateSelector, (state) => {
  return state.error
})

export const faqLabelError = createSelector(faqLabelStateSelector, (state) => {
  return state.error
})
