import { grpc } from '@improbable-eng/grpc-web'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'

import { AppThunk } from '../../app/store'
import channel_auto_assigned_config_pb from '../../proto/channel_auto_assigned_config_pb'
import channel_auto_assigned_config_pb_service from '../../proto/channel_auto_assigned_config_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from '../auth/authSlice'

interface AutoAssignConfigState {
  loading: boolean
  error: string | null
  userIds: string[]
  isActive: boolean
  hasConfig: boolean
}

const initialState: AutoAssignConfigState = {
  loading: false,
  error: null,
  userIds: [],
  isActive: false,
  hasConfig: false,
}
const autoAssignConfigSlice = createSlice({
  name: 'autoAssignConfig',
  initialState: initialState,
  reducers: {
    fetchAutoAssignConfigStart(state) {
      state.loading = true
      state.error = null
    },
    fetchAutoAssignConfigOnMessage(
      state,
      action: PayloadAction<{
        message: channel_auto_assigned_config_pb.GetChannelAutoAssignedConfigResponse.AsObject
      }>
    ) {
      const { message } = action.payload
      state.userIds = message.userIdsList
      state.isActive = message.active
    },
    fetchAutoAssignConfigOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      state.loading = false
      const { code, message } = action.payload
      if (code === grpc.Code.OK) {
        state.error = null
        state.hasConfig = true
        state.loading = false
        return
      }
      // レコードが存在しない場合は初期状態
      if (code == grpc.Code.NotFound) {
        state.error = null
        state.hasConfig = false
        state.loading = false
        state.isActive = false
        state.userIds = []
        return
      }
      consoleErrorWithAirbrake(message)
    },
    updateAutoAssignConfigOnMessage(state) {
      state.loading = false
      state.error = null
    },
    updateAutoAssignConfigOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      if (code === grpc.Code.OK) {
        state.error = null
        return
      }
      consoleErrorWithAirbrake(message)
    },
    createAutoAssignConfigOnMessage(state) {
      state.loading = false
      state.error = null
    },
    createAutoAssignConfigOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      if (code === grpc.Code.OK) {
        state.error = null
        return
      }
      consoleErrorWithAirbrake(message)
    },
    setIsActive(state, action: PayloadAction<{ isActive: boolean }>) {
      state.isActive = action.payload.isActive
    },
  },
})

export const {
  fetchAutoAssignConfigOnMessage,
  fetchAutoAssignConfigStart,
  fetchAutoAssignConfigOnEnd,
  updateAutoAssignConfigOnMessage,
  updateAutoAssignConfigOnEnd,
  createAutoAssignConfigOnMessage,
  createAutoAssignConfigOnEnd,
  setIsActive,
} = autoAssignConfigSlice.actions
export default autoAssignConfigSlice.reducer

export const fetchAutoAssignConfig =
  (): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      channel_auto_assigned_config_pb.GetChannelAutoAssignedConfigRequest,
      channel_auto_assigned_config_pb.GetChannelAutoAssignedConfigResponse
    >(
      channel_auto_assigned_config_pb_service.ChannelAutoAssignedConfigAPI
        .GetChannelAutoAssignedConfig
    )

    dispatch(fetchAutoAssignConfigStart())

    const req =
      new channel_auto_assigned_config_pb.GetChannelAutoAssignedConfigRequest()
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      dispatch(fetchAutoAssignConfigOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(fetchAutoAssignConfigOnEnd({ code, message }))
    })

    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const updateAutoAssignConfig =
  (isActive: boolean, userIds: Array<string>): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      channel_auto_assigned_config_pb.UpdateChannelAutoAssignedConfigRequest,
      Empty
    >(
      channel_auto_assigned_config_pb_service.ChannelAutoAssignedConfigAPI
        .UpdateChannelAutoAssignedConfig
    )

    const req =
      new channel_auto_assigned_config_pb.UpdateChannelAutoAssignedConfigRequest()
    req.setActive(isActive)
    req.setUserIdsList(userIds)
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage(() => {
      dispatch(updateAutoAssignConfigOnMessage())
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(updateAutoAssignConfigOnEnd({ code, message }))
    })

    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const createAutoAssignConfig =
  (isActive: boolean, userIds: Array<string>): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      channel_auto_assigned_config_pb.CreateChannelAutoAssignedConfigRequest,
      Empty
    >(
      channel_auto_assigned_config_pb_service.ChannelAutoAssignedConfigAPI
        .CreateChannelAutoAssignedConfig
    )
    const req =
      new channel_auto_assigned_config_pb.CreateChannelAutoAssignedConfigRequest()
    req.setActive(isActive)
    req.setUserIdsList(userIds)
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage(() => {
      dispatch(createAutoAssignConfigOnMessage())
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(createAutoAssignConfigOnEnd({ code, message }))
    })

    client.start(meta)
    client.send(req)
    client.finishSend()
  }
