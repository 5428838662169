import { Button, ChevronStartIcon } from '@fluentui/react-northstar'
import * as React from 'react'

import styles from './styles.module.css'

type Props = {
  onClickBackButton: () => void
  renderForm: () => React.ReactElement
}
const ActivityMessagePlanNewPresenter: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button
          text
          icon={<ChevronStartIcon />}
          content="お知らせ配信の一覧"
          onClick={props.onClickBackButton}
        />
      </div>
      <div className={styles.bodyWrapper}>{props.renderForm()}</div>
    </div>
  )
}

export default React.memo(ActivityMessagePlanNewPresenter)
