import { Button, Popup, TableIcon, Text } from '@fluentui/react-northstar'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { INSERT_TABLE_COMMAND } from '@lexical/table'
import * as React from 'react'

import styles from './style.module.css'

const TableSizeSelector: React.FC = () => {
  return (
    <Popup
      content={<Content />}
      trigger={<Button text iconOnly icon={<TableIcon />} />}
    />
  )
}

const Content = React.memo(() => {
  const [editor] = useLexicalComposerContext()

  const [hoverdRow, setRow] = React.useState(0)
  const [hoverdCol, setCol] = React.useState(0)

  const tablePreviewText = React.useMemo(() => {
    if (hoverdCol <= 0 && hoverdCol <= 0) return '表を挿入'
    return `${hoverdRow} x ${hoverdCol} の表を挿入`
  }, [hoverdRow, hoverdCol])

  return (
    <div className={styles.tableSelector}>
      <Text
        onMouseEnter={() => {
          setRow(0)
          setCol(0)
        }}
        content={tablePreviewText}
      >
        {tablePreviewText}
      </Text>
      {Array.from(Array(5).keys())
        .map((key) => key + 1)
        .map((row) => (
          <div
            key={row}
            className={styles.tableRow}
            onMouseEnter={() => setRow(row)}
          >
            {Array.from(Array(5).keys())
              .map((key) => key + 1)
              .map((col) => (
                <div
                  key={col}
                  className={[
                    styles.tableCol,
                    row <= hoverdRow && col <= hoverdCol ? styles.hover : '',
                  ].join(' ')}
                  onMouseEnter={() => setCol(col)}
                  onClick={() =>
                    editor.dispatchCommand(INSERT_TABLE_COMMAND, {
                      rows: row.toString(),
                      columns: col.toString(),
                      includeHeaders: false,
                    })
                  }
                ></div>
              ))}
          </div>
        ))}
    </div>
  )
})

export default TableSizeSelector
