// package: 
// file: proto/bot_added_group.proto

var proto_bot_added_group_pb = require("../proto/bot_added_group_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var BotAddedGroupAPI = (function () {
  function BotAddedGroupAPI() {}
  BotAddedGroupAPI.serviceName = "BotAddedGroupAPI";
  return BotAddedGroupAPI;
}());

BotAddedGroupAPI.ListBotAddedGroups = {
  methodName: "ListBotAddedGroups",
  service: BotAddedGroupAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_bot_added_group_pb.ListBotAddedGroupsRequest,
  responseType: proto_bot_added_group_pb.ListBotAddedGroupsResponse
};

exports.BotAddedGroupAPI = BotAddedGroupAPI;

function BotAddedGroupAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

BotAddedGroupAPIClient.prototype.listBotAddedGroups = function listBotAddedGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BotAddedGroupAPI.ListBotAddedGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.BotAddedGroupAPIClient = BotAddedGroupAPIClient;

