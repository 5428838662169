import { Box, Checkbox, Flex, Text } from '@fluentui/react-northstar'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import ticket_custom_field from '../../../proto/ticket_custom_field_pb'
import {
  deleteOneCustomFieldFilter,
  setCustomSelectFieldValuesFilter,
} from '../ticketSlice'
import styles from './TicketCustomFieldFilter.module.css'

type Props = {
  customField: ticket_custom_field.TicketCustomField.AsObject
}
const TicketCustomFieldValueSelectFilter: React.FC<Props> = ({
  customField,
}) => {
  const ticketState = useSelector((state: RootState) => state.ticket)
  const isExistIdInFilter =
    ticketState.filters.customFieldValues.selectFilters[customField.id] != null
  const fieldFilterValues = isExistIdInFilter
    ? ticketState.filters.customFieldValues.selectFilters[customField.id]
    : []

  const dispatch = useDispatch()

  return (
    <Flex column hAlign="start">
      <Text content="絞り込み" weight="bold" className={styles.headingText} />
      <Box className={styles.selectFilter}>
        <Flex column hAlign="start">
          {customField.optionsList.map(
            (ol: ticket_custom_field.TicketCustomFieldOption.AsObject) => {
              return (
                <Checkbox
                  key={ol.id}
                  label={ol.value}
                  labelPosition="end"
                  defaultChecked={false}
                  className={styles.checkbox}
                  checked={
                    isExistIdInFilter
                      ? fieldFilterValues.includes(ol.value)
                      : false
                  }
                  onChange={(_, value) => {
                    if (value?.checked) {
                      dispatch(
                        setCustomSelectFieldValuesFilter({
                          customFieldId: customField.id,
                          values: [...fieldFilterValues, ol.value],
                        })
                      )
                    } else {
                      // 最後の一つの時はフィルタからkey,valueを削除
                      fieldFilterValues.length === 1
                        ? dispatch(
                            deleteOneCustomFieldFilter({
                              customFieldId: customField.id,
                              customFieldType: customField.type,
                            })
                          )
                        : dispatch(
                            setCustomSelectFieldValuesFilter({
                              customFieldId: customField.id,
                              values: fieldFilterValues.filter(
                                (fv) => fv !== ol.value
                              ),
                            })
                          )
                    }
                  }}
                ></Checkbox>
              )
            }
          )}
        </Flex>
      </Box>
    </Flex>
  )
}

export default TicketCustomFieldValueSelectFilter
