import { Divider } from '@fluentui/react-northstar'
import { useVirtualizer } from '@tanstack/react-virtual'
import * as React from 'react'

import RequesterNoTickets from '../../components/RequesterNoTickets'
import styles from './style.module.css'

export type TicketDisplay =
  | { type: 'ticket'; id: string }
  | { type: 'divider'; label: string }

type Props = {
  listItems: TicketDisplay[]
  renderTicketItem: (id: string) => React.ReactElement
  triggerScroll: number
  linkedConversationID: string | null
  otherElementHeight: number
}

const SCROLL_TO_BOTTOM = 100000000

const RequesterTicketListPresenter: React.FC<Props> = (props) => {
  // The scrollable element for your list
  const parentRef = React.useRef(null)

  const { linkedConversationID, listItems, renderTicketItem, triggerScroll } =
    props

  // initialize virtualizer
  // See: doc https://tanstack.com/virtual/v3/docs/api/virtualizer
  const virtualizer = useVirtualizer({
    //The total number of items to virtualize.
    count: listItems.length,
    getScrollElement: () => parentRef.current || null, // indicate the scroll element
    estimateSize: React.useCallback(() => 150, []), // default height for each height, and those heights would be recaluclated by core of tanstack. 元は50でしたが最適化されていませんでした。スレッドに1行メッセージがある場合のスレッドのDOMの高さ+最後の返信のDOMの高さのおおよその和である数値にしました
    overscan: 5, // be adjustable by viewing behaviour of users。こちらの数値も元々は36でしたが、上下に36DOMずつレンダリングすると70近くのDOMのレンダリングが行われパフォーマンスを下げていたので5にしました。
    initialOffset: SCROLL_TO_BOTTOM, // 元々はlinkedConversationIDの指定がある場合はundefined, おそらく0を指定してたがなぜかレンダリングが非常に遅い問題が発生したので最下部を明示的に指定することによりパフォーマンス改善できたのでこのようにしている
  })

  const items = virtualizer.getVirtualItems()

  // 新規チケットが作成された時に一番下までスクロールする
  React.useEffect(() => {
    // not be triggered yet
    if (triggerScroll === 0) {
      return
    }
    virtualizer.scrollToOffset(SCROLL_TO_BOTTOM)
  }, [triggerScroll, virtualizer])

  const activityFeedItemIndex = React.useMemo(
    () =>
      listItems.findIndex((item) => {
        return (item as { id: string }).id === linkedConversationID
      }),
    [listItems, linkedConversationID]
  )

  React.useEffect(() => {
    // it it is opened directly, do not jump, follow default configuration
    if (activityFeedItemIndex === -1) {
      return
    }
    // If it is from activity feed, go to the related conversation item
    virtualizer.scrollToIndex(activityFeedItemIndex, { align: 'center' })
  }, [virtualizer, activityFeedItemIndex])

  if (listItems.length <= 0) return <RequesterNoTickets />

  return (
    <div className={styles.container}>
      <div
        ref={parentRef}
        style={{
          height: `calc(100vh - ${props.otherElementHeight}px)`,
          overflow: 'auto',
          paddingRight: '32px',
        }}
      >
        {/* The large inner element to hold all of the items */}
        <div
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${items[0].start}px)`,
            }}
          >
            {/* Only the visible items in the virtualizer, manually positioned to be in view */}
            {items.map((virtualRow, index) => (
              <div
                key={virtualRow.key}
                data-index={virtualRow.index}
                // start dynamic mode
                ref={virtualizer.measureElement}
              >
                {renderTicketDisplay(renderTicketItem)(
                  listItems[virtualRow.index],
                  index
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const renderTicketDisplay =
  (renderTicketItem: (id: string) => React.ReactElement) =>
  (ticketDisplay: TicketDisplay, index: number): React.ReactElement => {
    switch (ticketDisplay.type) {
      case 'ticket':
        return (
          <div className={styles.ticketItem} key={ticketDisplay.id}>
            {renderTicketItem(ticketDisplay.id)}
          </div>
        )
      case 'divider':
        return (
          <Divider
            className={styles.dividerItem}
            key={`divider-${index}`}
            content={ticketDisplay.label}
          />
        )
      default:
        return <></>
    }
  }

export default React.memo(RequesterTicketListPresenter)
