import { useCallback, useRef } from 'react'

const useDebounce = (delay: number) => {
  const idRef = useRef<NodeJS.Timeout>()
  return useCallback(
    function (fn: () => void) {
      clearTimeout(idRef.current)
      idRef.current = setTimeout(() => {
        fn()
      }, delay)
    },
    [delay]
  )
}

export default useDebounce
