import React from 'react'

import { CustomFieldType } from '../../../consts'
import ticket_custom_field from '../../../proto/ticket_custom_field_pb'
import TicketCustomFieldValueCheckboxFilter from './TicketCustomFieldValueCheckboxFilter'
import TicketCustomFieldValueDateFilter from './TicketCustomFieldValueDateFilter'
import TicketCustomFieldValueNumberFilter from './TicketCustomFieldValueNumberFilter'
import TicketCustomFieldValueSelectFilter from './TicketCustomFieldValueSelectFilter'
import TicketCustomFieldValueTextFilter from './TicketCustomFieldValueTextFilter'

type Props = {
  customField?: ticket_custom_field.TicketCustomField.AsObject
}

const TicketCustomFieldValueFilter: React.FC<Props> = ({ customField }) => {
  // fieldIdからどの選択肢のリストを取得する
  if (customField == null) return null
  const isSelectFilter = (): boolean => {
    return (
      customField.type === CustomFieldType.select ||
      customField.type === CustomFieldType.multiselect
    )
  }
  const isDateFilter = (): boolean => {
    return customField.type === CustomFieldType.date
  }
  const isCheckboxFilter = (): boolean => {
    return customField.type === CustomFieldType.checkbox
  }
  const isTextFilter = (): boolean => {
    return (
      customField.type === CustomFieldType.text ||
      customField.type === CustomFieldType.textarea
    )
  }
  const isNumberFilter = (): boolean => {
    return customField.type === CustomFieldType.number
  }
  return (
    <>
      {isSelectFilter() && (
        <TicketCustomFieldValueSelectFilter
          customField={customField}
        ></TicketCustomFieldValueSelectFilter>
      )}
      {isTextFilter() && (
        <TicketCustomFieldValueTextFilter
          customField={customField}
        ></TicketCustomFieldValueTextFilter>
      )}
      {isNumberFilter() && (
        <TicketCustomFieldValueNumberFilter
          customField={customField}
        ></TicketCustomFieldValueNumberFilter>
      )}
      {isDateFilter() && (
        <TicketCustomFieldValueDateFilter
          customField={customField}
        ></TicketCustomFieldValueDateFilter>
      )}
      {isCheckboxFilter() && (
        <TicketCustomFieldValueCheckboxFilter
          customField={customField}
        ></TicketCustomFieldValueCheckboxFilter>
      )}
    </>
  )
}

export default TicketCustomFieldValueFilter
