import { Flex, Pill, Text } from '@fluentui/react-northstar'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { setKeyId } from '../ticketSlice'
import styles from './Pill.module.css'

const KeyIdFilterPill: React.FC = () => {
  const ticketState = useSelector((state: RootState) => state.ticket)
  const dispatch = useDispatch()

  return (
    <Pill
      actionable
      onDismiss={() => dispatch(setKeyId({ keyId: 0 }))}
      className={styles.pill}
    >
      <Flex vAlign="center">
        <Text
          content={`ID：${ticketState.filters.keyId}`}
          className={styles.pillTitle}
        />
      </Flex>
    </Pill>
  )
}

export default KeyIdFilterPill
