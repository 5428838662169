import { Flex, Text } from '@fluentui/react-northstar'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { Dropdown } from '../../components/Dropdown'
import { FaqStatus, FaqStatusLabels } from '../../consts'
import styles from './TicketDefaultFieldFilter.module.css'
import { setFaqStatuses } from './ticketSlice'

const FaqStatusFilter: React.FC = () => {
  const ticketState = useSelector((state: RootState) => state.ticket)
  const dispatch = useDispatch()
  return (
    <Flex column gap="gap.small">
      <Text content="絞り込み" weight="bold" className={styles.headingText} />
      <Dropdown
        fluid
        className={styles.dropdown}
        placeholder={'すべて'}
        clearable
        checkable
        value={ticketState.filters.faqStatuses}
        items={[
          {
            value: FaqStatus.created,
            header: FaqStatusLabels[FaqStatus.created],
          },
        ]}
        onValueChange={(values) => {
          dispatch(
            setFaqStatuses({ statuses: values.map((v) => v as FaqStatus) })
          )
        }}
      />
    </Flex>
  )
}

export default FaqStatusFilter
