import { grpc } from '@improbable-eng/grpc-web'

export const grpcClient = <
  TRequest extends grpc.ProtobufMessage,
  TResponse extends grpc.ProtobufMessage
>(
  methodDefinition: grpc.MethodDefinition<TRequest, TResponse>
): grpc.Client<TRequest, TResponse> => {
  const client = grpc.client<
    TRequest,
    TResponse,
    grpc.MethodDefinition<TRequest, TResponse>
  >(methodDefinition, {
    host: process.env.REACT_APP_SERVER_URL,
  })
  return client
}
