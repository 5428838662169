import * as React from 'react'
import * as ReactRedux from 'react-redux'

import { RootState } from '../../app/store'
import { useListRecommendedFaqRedundantIntentsQuery } from './api'
import RecommendedFaqRedundantIntentListPresenter from './presenter'
import * as Selectors from './selector'
import * as Slice from './slice'
import { RecommendedFaqRedundantIntentEntity } from './types/RecommendedFaqRedundantIntentEntity'

type Props = {
  recommendedFaqId: number
}

const RecommendedFaqRedundantIntentList: React.FC<Props> = (props) => {
  const dispatch = ReactRedux.useDispatch()
  const { data, isLoading } = useListRecommendedFaqRedundantIntentsQuery({
    recommendedFaqId: props.recommendedFaqId,
  })
  const open = ReactRedux.useSelector((state: RootState) =>
    Selectors.open(state)
  )

  React.useEffect(() => {
    dispatch(Slice.actions.open())
  }, [dispatch, props.recommendedFaqId])

  const redundantIntents: RecommendedFaqRedundantIntentEntity[] =
    React.useMemo(() => {
      const intents = data?.entities ?? []
      return intents
    }, [data?.entities])

  const handleClose = React.useCallback(() => {
    dispatch(Slice.actions.close())
  }, [dispatch])

  return (
    <RecommendedFaqRedundantIntentListPresenter
      open={open}
      loading={isLoading}
      intents={redundantIntents}
      onClose={handleClose}
    />
  )
}

export default React.memo(RecommendedFaqRedundantIntentList)
