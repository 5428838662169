import * as React from 'react'
import * as ReactRedux from 'react-redux'

import { ThunkDispatchType } from '../../app/store'
import ActivityMessagePlanPreviewReceiverListItem from './features/ActivityMessagePlanPreviewReceiverListItem'
import Presenter from './presenter'
import * as Selectors from './selectors'
import * as Slice from './slice'

const ActivityMessagePlanPreviewReceiverList: React.FC = () => {
  const dispatch = ReactRedux.useDispatch<ThunkDispatchType>()

  const receivers = ReactRedux.useSelector(Selectors.receiversSelector)
  const isShow = ReactRedux.useSelector(Selectors.isShowSelector)

  const renderReceiverItem = React.useCallback((id: string) => {
    return <ActivityMessagePlanPreviewReceiverListItem id={id} />
  }, [])
  const clickClose = React.useCallback(() => {
    dispatch(Slice.actions.hide())
  }, [dispatch])

  return (
    <Presenter
      isActive={isShow}
      receivers={receivers}
      renderReceiverItem={renderReceiverItem}
      onClickClose={clickClose}
    />
  )
}

export default ActivityMessagePlanPreviewReceiverList
