import { createAsyncThunk } from '@reduxjs/toolkit'

import { AsyncThunkConfig, RootState } from '../../app/store'
import { FormData } from '../../components/ActivityMessagePlanForm'
import { assertNever } from '../../utils'
import { attachmentEntityAdapter } from '../ActivityMessageAttachment/lib/entityAdapter'
import * as AttachmentOperations from '../ActivityMessageAttachment/operations'
import { AttachmentItem } from '../ActivityMessageAttachment/types/AttachmentItem'
import EditFormAPI from './api'
import * as Selectors from './selectors'
import {
  EditActivityMessagePlanArgs,
  EditActivityMessagePlanReceiver,
} from './types/EditActivityMessagePlanArgs'

export const uploadFile = AttachmentOperations.uploadFileFactory(
  'ActivityMessagePlanEditForm/uploadFile'
)

export const submitForm = createAsyncThunk<
  void,
  { id: number; data: FormData; receiverIDs: string[] },
  AsyncThunkConfig
>('ActivityMessagePlanEditFrom/submitForm', async (args, thunkAPI) => {
  const state = thunkAPI.getState()
  const creatingAttachments = attachmentEntityAdapter
    .getSelectors(
      (state: RootState) => Selectors.stateSelector(state).creatingAttachments
    )
    .selectAll(state)

  await thunkAPI
    .dispatch(
      EditFormAPI.endpoints.editPlan.initiate(
        formValueToEditActivityMessagePlan(
          args.data,
          args.id,
          args.receiverIDs,
          creatingAttachments,
          Selectors.stateSelector(state).removedAttachmentIDs
        )
      )
    )
    .unwrap()
})

function formValueToEditActivityMessagePlan(
  value: FormData,
  id: number,
  receiverIDs: string[],
  creatingAttachmentItem: AttachmentItem[],
  removedAttachmentIDs: string[]
): EditActivityMessagePlanArgs {
  return {
    id,
    body: value.body,
    receiver: formValueToEditActivityMessagePlanReceiver(value, receiverIDs),
    creatingAttachments: creatingAttachmentItem,
    deletingAttachmentIDs: removedAttachmentIDs,
  }
}

function formValueToEditActivityMessagePlanReceiver(
  value: FormData,
  receiverIDs: string[]
): EditActivityMessagePlanReceiver {
  switch (value.receiverType) {
    case 'all':
      return { type: 'all' }
    case 'userIDs':
      return { type: 'receiverIDs', receiverIDs }
    default:
      assertNever(value.receiverType)
      return { type: 'all' }
  }
}
