import { Alert } from '@fluentui/react-northstar'

import styles from './NotificationBanner.module.css'

export interface NotificationBannerProps {
  content: string
  visible: boolean
}

export const NotificationBanner: React.FC<NotificationBannerProps> = (
  props
) => {
  return (
    <Alert
      content={props.content}
      visible={props.visible}
      className={styles.alert}
    />
  )
}
