import { GraphError } from '@microsoft/microsoft-graph-client'
import * as Graph from '@microsoft/microsoft-graph-types'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk, RootState } from '../../app/store'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from './authSlice'

const memberTopNum = 999

export const membersAdapter =
  createEntityAdapter<Graph.AadUserConversationMember>({
    selectId: (member) => member.userId || '',
    sortComparer: (a, b) => {
      if (a.displayName == null) return -1
      if (b.displayName == null) return 1
      return a.displayName.localeCompare(b.displayName)
    },
  })

export const membersSelector = membersAdapter.getSelectors(
  (state: RootState) => state.member
)

interface MemberState {
  loading: boolean
  error: string | null
}

export const memberSlice = createSlice({
  name: 'member',
  initialState: membersAdapter.getInitialState<MemberState>({
    loading: false,
    error: null,
  }),
  reducers: {
    fetchMembersStart(state) {
      state.loading = true
      state.error = null
    },
    fetchMembersSuccess(
      state,
      action: PayloadAction<{
        members: [Graph.AadUserConversationMember]
      }>
    ) {
      membersAdapter.setAll(state, action.payload.members)
      state.loading = false
    },
    fetchMembersFailure(state, action: PayloadAction<{ error: string }>) {
      state.loading = false
      state.error = action.payload.error
      consoleErrorWithAirbrake(action.payload.error)
    },
  },
})

export const { fetchMembersStart, fetchMembersSuccess, fetchMembersFailure } =
  memberSlice.actions
export default memberSlice.reducer

const MAX_RETRIES = 3
const RETRY_DELAY = 1000 // 1 second

async function fetchWithRetry<T>(request: () => Promise<T>): Promise<T | null> {
  for (let i = 0; i < MAX_RETRIES; i++) {
    try {
      return await request()
    } catch (error) {
      if (i < MAX_RETRIES - 1) {
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY))
      } else {
        throw error
      }
    }
  }

  // reach the limitation of retry number, so return null
  return null
}

declare type fetchMembersSuccessResponse = {
  '@odata.nextLink'?: string
  '@odata.context'?: string
  '@odata.count'?: number
  value: [Graph.AadUserConversationMember]
}

export const fetchMembers =
  (): AppThunk =>
  async (dispatch, getState, { graphAPI }) => {
    dispatch(fetchMembersStart())

    const context = getState().auth.context
    if (context == null) {
      return
    }

    const { channel, team } = context

    if (channel == null) {
      return
    }

    if (team == null) {
      return
    }
    const channelId = channel.id
    const groupId = team.groupId

    if (channelId == null || groupId == null) {
      dispatch(fetchMembersFailure({ error: 'lack of information' }))
      return
    }

    try {
      const res = await fetchWithRetry<fetchMembersSuccessResponse>(
        async () => {
          return await graphAPI
            ?.api(
              `/teams/${encodeURIComponent(
                groupId
              )}/channels/${encodeURIComponent(
                channelId
              )}/members?$top=${memberTopNum}`
            )
            .get()
        }
      )

      if (res == null) {
        dispatch(fetchMembersFailure({ error: 'failed at fetch members' }))
        return
      }

      // memberTopNum以上のメンバーが存在する場合、キー「@odata.nextLink」が生成され、残りのメンバーを取得するためのURLが値として入っている
      if (res['@odata.nextLink'] != null) {
        consoleErrorWithAirbrake(
          `channel "${channelId}" in team "${groupId}" has more members than memberTopNum "${memberTopNum}"`
        )
      }

      dispatch(fetchMembersSuccess({ members: res.value }))
    } catch (e) {
      if (e instanceof GraphError === false) {
        dispatch(fetchMembersFailure({ error: JSON.stringify(e) }))
        return
      }

      const err = e as GraphError
      // delegate token is invalid, at this case we force user to reauthentication
      if (err.statusCode === 401) {
        dispatch(resetTokens())
        return
      }

      // network error
      if (err.statusCode == -1) {
        return
      }

      dispatch(fetchMembersFailure({ error: JSON.stringify(err) }))
    }
  }
