import { Layout } from '@fluentui/react-northstar'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import placeholder from '../../images/placeholder.png'
import { consoleErrorWithAirbrake } from '../../utils'
import { ChatImage } from './ChatImage'
import styles from './ChatItemImageAttachment.module.css'
import { onImagePreviewShow } from './imagePreviewSlice'
import { prepareInquiry } from './thumbnailsQuery'
import { ThumbnailsStatus, thumbnailsSelectors } from './thumbnailsSlice'

interface ChatItemImageAttachmentProps {
  itemId: string
  driveId?: string // onedriveのどのドライブの画像なのかを示します。
  userId?: string //どのユーザーの画像なのかを示します
  webUrl: string
}

const ChatImagePlaceHolderMaxWidth = 250
const ChatImagePlaceHolderMaxRatio = 0.45

export const ChatItemImageAttachment: React.FC<ChatItemImageAttachmentProps> = (
  props
) => {
  const dispatch = useDispatch()
  const thumbnails = useSelector(thumbnailsSelectors.selectAll)
  const authState = useSelector((state: RootState) => state.auth)
  const layoutState = useSelector((state: RootState) => state.layout)

  const groupId = authState.context?.team?.groupId

  // 画像のサムネールを取得します。
  useEffect(() => {
    const inquiry = prepareInquiry({
      driveId: props.driveId,
      groupId: groupId,
      userId: props.userId,
      itemId: props.itemId,
    })
    if (inquiry == null) {
      consoleErrorWithAirbrake(
        `get empty inquiry when tying to fetch thumbnail with driveId:${props.driveId} and groupId:${groupId} and userId:${props.userId} and itemId:${props.itemId}`
      )
      return
    }
    dispatch(inquiry.query())
  }, [dispatch, groupId, props.driveId, props.itemId, props.userId])

  //画像を表示させるために、取得したサムネール中から、該当するサムネールを取り出します。
  const imageThumbnail = thumbnails.find((item) => item.itemId === props.itemId)

  const thumbnailUrl =
    imageThumbnail?.thumbnails?.c10000x10000?.url ||
    imageThumbnail?.thumbnails?.large?.url ||
    imageThumbnail?.thumbnails?.medium?.url ||
    imageThumbnail?.thumbnails?.small?.url

  // サムネールは取得できなかった(404)の場合は、画像が見つかりませんでしたを表示させます。
  const isNotFound = imageThumbnail?.status === ThumbnailsStatus.NotFound
  const isLoading = thumbnailUrl == null && !isNotFound

  const imageText = useMemo(() => {
    if (isLoading) {
      return '画像を読み込んでいます...'
    }
    if (isNotFound) {
      return '画像が見つかりませんでした'
    }
    return
  }, [isLoading, isNotFound])

  const imageSrc = useMemo(() => {
    if (isLoading || isNotFound) {
      return placeholder
    }

    if (thumbnailUrl != null) {
      return thumbnailUrl
    }

    return ''
  }, [thumbnailUrl, isLoading, isNotFound])

  const placeHolderSize = layoutState?.chatLayout?.clientWidth
    ? Math.min(
        layoutState?.chatLayout?.clientWidth * ChatImagePlaceHolderMaxRatio,
        ChatImagePlaceHolderMaxWidth
      )
    : ChatImagePlaceHolderMaxWidth

  const imageStyle = useMemo(() => {
    if (isLoading === true) {
      return {
        width: `${placeHolderSize}px`,
        height: `${placeHolderSize}px`,
      }
    }
    return {}
  }, [placeHolderSize, isLoading])

  return (
    <Layout
      renderMainArea={() => (
        <div
          onClick={() =>
            dispatch(
              onImagePreviewShow({
                src: thumbnailUrl,
                openUrl: `${props.webUrl}`,
              })
            )
          }
        >
          <ChatImage
            src={imageSrc}
            styles={imageStyle}
            fluid={true}
            className={styles.attachmentImage}
            text={imageText}
          />
        </div>
      )}
    />
  )
}
