import { Dispatch, useEffect } from 'react'

import { TicketCustomField } from '../../../proto/ticket_custom_field_pb'
import { TicketCustomFieldValue } from '../../../proto/ticket_custom_field_value_pb'
import { Alert, addAlert } from '../../alert/alertsSlice'
import { TicketCustomFieldValueErrorObject } from '../customFieldValueSlice'
import { handleTicketCustomFieldValueError } from './ticketCustomFieldValueErrorMessage'

export const useErrorHandler = (
  customFieldValueEntity:
    | (TicketCustomFieldValue.AsObject & TicketCustomFieldValueErrorObject)
    | undefined,
  field: TicketCustomField.AsObject,
  dispatch: Dispatch<{
    payload: Alert
    type: string
  }>,
  hasValue: boolean
): void => {
  useEffect(() => {
    const errorMessage = customFieldValueEntity?.errorMessage
    if (hasValue && customFieldValueEntity?.isError && errorMessage != null) {
      dispatch(
        addAlert({
          id: field.id,
          title: handleTicketCustomFieldValueError(errorMessage),
          type: 'custom_field_value_error',
        })
      )
    }
  }, [
    dispatch,
    customFieldValueEntity?.isError,
    field.id,
    customFieldValueEntity?.errorMessage,
    hasValue,
  ])
}
