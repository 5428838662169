import * as RTK from '@reduxjs/toolkit'

export type State = {
  isShow: boolean
  receivers: string[]
}
const slice = RTK.createSlice({
  name: 'ActivityMessagePlanPreviewReceiverList',
  initialState: {
    isShow: false,
    receivers: [],
  } as State,
  reducers: {
    show: (state, action: RTK.PayloadAction<{ receivers: string[] }>) => {
      state.isShow = true
      state.receivers = action.payload.receivers
      return state
    },
    hide: (state) => {
      state.isShow = false
      state.receivers = []
      return state
    },
  },
})

export const { actions, reducer } = slice
