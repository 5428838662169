import { Image } from '@fluentui/react-northstar'
import React, { CSSProperties } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import styles from './ChatImage.module.css'

const ChatImageMaxWidth = 400
const ChatImageMaxRatio = 0.45
export const ChatImage: React.FC<{
  src: string
  fluid?: boolean
  hidden?: boolean
  id?: string
  className?: string
  alt?: string
  onClick?: () => void
  text?: string
  styles?: CSSProperties
}> = (props) => {
  const layoutState = useSelector((state: RootState) => state.layout)
  return (
    <span className={styles.imageContainer}>
      <Image
        styles={{
          'max-width': `${
            layoutState?.chatLayout?.clientWidth == null
              ? ChatImageMaxWidth
              : Math.min(
                  layoutState.chatLayout.clientWidth * ChatImageMaxRatio,
                  ChatImageMaxWidth
                )
          }px`,
          ...props.styles,
        }}
        className={props.className}
        hidden={props.hidden}
        id={props.id}
        alt={props.alt}
        src={props.src}
        onClick={props.onClick}
        fluid={props.fluid}
      />
      <span className={styles.text}>{props.text}</span>
    </span>
  )
}
