import * as React from 'react'

import ActivityMessagePlanHeader from './components/ActivityMessagePlanHeader'

type Props = {
  renderList: () => React.ReactElement
  renderPagination: () => React.ReactElement
  onClickNewMessageButton: () => void
}
const ActivityMessagePlans: React.FC<Props> = (props) => {
  return (
    <div>
      <ActivityMessagePlanHeader
        renderPagination={props.renderPagination}
        onClickNewMessageButton={props.onClickNewMessageButton}
      />
      {props.renderList()}
    </div>
  )
}

export default React.memo(ActivityMessagePlans)
