import * as React from 'react'
import * as ReactRedux from 'react-redux'

import DashboardFilterSidepanelPresenter, { FormData } from './presenter'
import * as Selectors from './selectors'
import { actions } from './slice'

const DashboardFilterSidepanel: React.FC = () => {
  const dispatch = ReactRedux.useDispatch()
  const startDate = ReactRedux.useSelector(Selectors.startDate)
  const endDate = ReactRedux.useSelector(Selectors.endDate)
  const assigneeUserIds = ReactRedux.useSelector(Selectors.assigneeUserIds)

  const clickApply = React.useCallback(
    (data: FormData) => {
      dispatch(actions.updateStateDate(data.startDate))
      dispatch(actions.updateEndDate(data.endDate))
      dispatch(actions.updateAssigneeUserIds(data.assigneeUserIds))
    },
    [dispatch]
  )

  const clickCancel = React.useCallback(() => {
    dispatch(actions.close())
  }, [dispatch])

  const clickClear = React.useCallback(() => {
    dispatch(actions.clearDate())
    dispatch(actions.clearAssigneeUserIds())
  }, [dispatch])

  const clickClose = React.useCallback(() => {
    dispatch(actions.close())
  }, [dispatch])

  return (
    <DashboardFilterSidepanelPresenter
      startDate={startDate}
      endDate={endDate}
      assigneeUserIds={assigneeUserIds}
      onClickApply={clickApply}
      onClickCancel={clickCancel}
      onClickClear={clickClear}
      onClickClose={clickClose}
    />
  )
}

export default DashboardFilterSidepanel
