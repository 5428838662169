import { Pill } from '@fluentui/react-northstar'
import { AadUserConversationMember } from '@microsoft/microsoft-graph-types'
import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import styles from './Pill.module.css'

const NoFilterPill: React.FC = () => {
  const memberState = useSelector((state: RootState) => state.member)
  const members = memberState.ids.map(
    (id) => memberState.entities[id] as AadUserConversationMember
  )
  const usersState = useSelector((state: RootState) => state.users)
  const initializing = usersState.meId == null || members.length === 0
  // メンバーやユーザーの情報取得後にPillがレンダリングされるので、絞り込みがない場合のコンポーネント表示もそのタイミングに合わせています。
  // タイミングを合わせない場合、一瞬このコンポーネントが表示され、Pill表示部分がちらついてしまいます。
  if (initializing) return null
  return (
    <Pill className={styles.pill} disabled>
      絞り込み条件なし
    </Pill>
  )
}

export default NoFilterPill
