import { Pill, Text } from '@fluentui/react-northstar'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { clearCreatedAtFilter } from '../ticketSlice'
import styles from './Pill.module.css'

const CreatedAtFilterPill: React.FC = () => {
  const formatter = (d: Date) =>
    `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`
  const ticketState = useSelector((state: RootState) => state.ticket)
  const dispatch = useDispatch()

  return (
    <Pill
      actionable
      onDismiss={() => dispatch(clearCreatedAtFilter())}
      className={styles.pill}
    >
      <Text content="依頼日：" className={styles.pillTitle} />
      {(() => {
        if (
          isFilteredByCreatedAtStart(
            ticketState.filters.startHour,
            ticketState.filters.startMinutes,
            ticketState.filters.startDate
          ) &&
          isFilteredByCreatedAtEnd(
            ticketState.filters.endHour,
            ticketState.filters.endMinutes,
            ticketState.filters.endDate
          )
        ) {
          if (
            ticketState.filters.startDate != null &&
            ticketState.filters.endDate != null
          )
            return `${formatter(ticketState.filters.startDate)} ${
              ticketState.filters.startHour
            }時${ticketState.filters.startMinutes}分～${formatter(
              ticketState.filters.endDate
            )} ${ticketState.filters.endHour}時${
              ticketState.filters.endMinutes
            }分`
        }
        if (
          isFilteredByCreatedAtStart(
            ticketState.filters.startHour,
            ticketState.filters.startMinutes,
            ticketState.filters.startDate
          )
        ) {
          if (ticketState.filters.startDate != null)
            return `${formatter(ticketState.filters.startDate)} ${
              ticketState.filters.startHour
            }時${ticketState.filters.startMinutes}分～`
        }
        if (
          isFilteredByCreatedAtEnd(
            ticketState.filters.endHour,
            ticketState.filters.endMinutes,
            ticketState.filters.endDate
          )
        ) {
          if (ticketState.filters.endDate != null)
            return `～${formatter(ticketState.filters.endDate)} ${
              ticketState.filters.endHour
            }時${ticketState.filters.endMinutes}分`
        }
      })()}
    </Pill>
  )
}

export default CreatedAtFilterPill

export const isFilteredByCreatedAtStart = (
  startHour: string[],
  startMinutes: string[],
  startDate?: Date
): boolean => {
  return (
    startDate != null &&
    startHour !== [''] &&
    startHour.length > 0 &&
    startMinutes !== [''] &&
    startMinutes.length > 0
  )
}

export const isFilteredByCreatedAtEnd = (
  endHour: string[],
  endMinutes: string[],
  endDate?: Date
): boolean => {
  return (
    endDate != null &&
    endHour !== [''] &&
    endHour.length > 0 &&
    endMinutes !== [''] &&
    endMinutes.length > 0
  )
}
