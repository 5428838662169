// package: 
// file: proto/channel_auto_assigned_config.proto

var proto_channel_auto_assigned_config_pb = require("../proto/channel_auto_assigned_config_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ChannelAutoAssignedConfigAPI = (function () {
  function ChannelAutoAssignedConfigAPI() {}
  ChannelAutoAssignedConfigAPI.serviceName = "ChannelAutoAssignedConfigAPI";
  return ChannelAutoAssignedConfigAPI;
}());

ChannelAutoAssignedConfigAPI.GetChannelAutoAssignedConfig = {
  methodName: "GetChannelAutoAssignedConfig",
  service: ChannelAutoAssignedConfigAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_channel_auto_assigned_config_pb.GetChannelAutoAssignedConfigRequest,
  responseType: proto_channel_auto_assigned_config_pb.GetChannelAutoAssignedConfigResponse
};

ChannelAutoAssignedConfigAPI.UpdateChannelAutoAssignedConfig = {
  methodName: "UpdateChannelAutoAssignedConfig",
  service: ChannelAutoAssignedConfigAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_channel_auto_assigned_config_pb.UpdateChannelAutoAssignedConfigRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ChannelAutoAssignedConfigAPI.CreateChannelAutoAssignedConfig = {
  methodName: "CreateChannelAutoAssignedConfig",
  service: ChannelAutoAssignedConfigAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_channel_auto_assigned_config_pb.CreateChannelAutoAssignedConfigRequest,
  responseType: google_protobuf_empty_pb.Empty
};

exports.ChannelAutoAssignedConfigAPI = ChannelAutoAssignedConfigAPI;

function ChannelAutoAssignedConfigAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ChannelAutoAssignedConfigAPIClient.prototype.getChannelAutoAssignedConfig = function getChannelAutoAssignedConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ChannelAutoAssignedConfigAPI.GetChannelAutoAssignedConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ChannelAutoAssignedConfigAPIClient.prototype.updateChannelAutoAssignedConfig = function updateChannelAutoAssignedConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ChannelAutoAssignedConfigAPI.UpdateChannelAutoAssignedConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ChannelAutoAssignedConfigAPIClient.prototype.createChannelAutoAssignedConfig = function createChannelAutoAssignedConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ChannelAutoAssignedConfigAPI.CreateChannelAutoAssignedConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ChannelAutoAssignedConfigAPIClient = ChannelAutoAssignedConfigAPIClient;

