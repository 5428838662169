import { Flex, ListItemProps, Pill, Text } from '@fluentui/react-northstar'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { SearchableDropdownList } from '../../components/SearchableDropdown'
import { ellipsis } from '../../utils'
import { requestersSelector } from './requestersSlice'
import {
  fetchRequesterSuggests,
  requesterSuggestsSelector,
} from './requesterSuggestsSlice'
import styles from './TicketDefaultFieldFilter.module.css'
import { setRequesterUserIds } from './ticketSlice'

const TicketRequesterFilter: React.FC = () => {
  const ticketState = useSelector((state: RootState) => state.ticket)
  const requesterSuggestsState = useSelector(
    (state: RootState) => state.requesterSuggests
  )
  const requesterSuggests = useSelector(requesterSuggestsSelector.selectAll)
  const requesters = useSelector(requestersSelector.selectAll)
  const [inputText, setInputText] = useState('')
  const dispatch = useDispatch()

  const searchForRequesters = useCallback(
    (inputText: string) => {
      dispatch(fetchRequesterSuggests(inputText))
    },
    [dispatch]
  )

  const list = useMemo(
    () =>
      requesterSuggests.map((r) => ({
        id: r.userId,
        header: r.displayName,
        key: r.userId,
        content: r.mail,
      })),
    [requesterSuggests]
  )

  return (
    <Flex column className={styles.container} gap="gap.smaller">
      <Text content="絞り込み" weight="bold" className={styles.headingText} />
      <Flex wrap>
        {ticketState.filters.requesterUserIds.length > 0 &&
          requesters.map((requester) => (
            <Pill
              key={requester.userId}
              content={ellipsis(requester.displayName, 14)}
              className={styles.pill}
              actionable
              onDismiss={() => {
                dispatch(
                  setRequesterUserIds({
                    requesterUserIds: [
                      ...ticketState.filters.requesterUserIds.filter(
                        (rId) => rId !== requester.userId
                      ),
                    ],
                  })
                )
              }}
            />
          ))}
      </Flex>
      <SearchableDropdownList
        loading={requesterSuggestsState.loading}
        inputText={inputText}
        handleUpdateOnInputChange={searchForRequesters}
        setInputText={setInputText}
        list={list}
        isSelected={(userId: string) =>
          ticketState.filters.requesterUserIds.includes(userId)
        }
        handleOptionSelected={(data: ListItemProps) => {
          if (data.index == null) {
            return
          }
          const requesterUserIds = [
            ...ticketState.filters.requesterUserIds,
            requesterSuggests[data.index].userId,
          ]
          dispatch(setRequesterUserIds({ requesterUserIds: requesterUserIds }))
          setInputText('')
        }}
        listTopMargin={'calc(100% - 16px)'}
      />
    </Flex>
  )
}

export default TicketRequesterFilter
