import * as Graph from '@microsoft/microsoft-graph-types'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk, RootState } from '../../app/store'

export interface RequesterState {
  loading: boolean
  error: string | null
}

export const requestersAdapter = createEntityAdapter<{
  userId: string
  displayName: string
}>({
  selectId: (requester) => requester.userId || '',
  sortComparer: (a, b) => {
    if (a.displayName == null) return -1
    if (b.displayName == null) return 1
    return a.displayName.localeCompare(b.displayName)
  },
})

export const requestersSelector = requestersAdapter.getSelectors(
  (root: RootState) => root.requesters
)

export const requesterSlice = createSlice({
  name: 'requesters',
  initialState: requestersAdapter.getInitialState<RequesterState>({
    loading: false,
    error: null,
  }),
  reducers: {
    fetchRequestersStart(state) {
      state.loading = true
      state.error = null
    },
    fetchRequestersSuccess(
      state,
      action: PayloadAction<{ users: Array<Graph.User> }>
    ) {
      const { users } = action.payload
      const requesters = users
        .map((user) => {
          return {
            userId: user.id ?? '',
            displayName: user.displayName ?? '',
          }
        })
        .filter((obj) => obj !== { userId: '', displayName: '' })
      requestersAdapter.setAll(state, requesters)
      state.loading = false
      state.error = null
    },
    initRequesters(state) {
      requestersAdapter.setAll(state, [])
    },
  },
})

export const { fetchRequestersStart, fetchRequestersSuccess, initRequesters } =
  requesterSlice.actions
export default requesterSlice.reducer

export const fetchRequester =
  (requesterUserIds: string[]): AppThunk =>
  async (dispatch, getState, { graphAPI }) => {
    dispatch(fetchRequestersStart())
    if (requesterUserIds.length === 0) return dispatch(initRequesters())
    const res = await graphAPI.api(`/directoryObjects/getByIds`).post({
      ids: requesterUserIds,
      types: ['user'],
    })
    dispatch(fetchRequestersSuccess({ users: res.value }))
  }
