import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface ImagePreviewState {
  src: string | undefined // for show picture
  downloadData?: string // for download picture
  openUrl?: string // for open in a new window
  hidden: boolean
}

const initState: ImagePreviewState = {
  src: '',
  hidden: true,
}

export const imagePreviewStateSlice = createSlice({
  name: 'imagePreview',
  initialState: initState,
  reducers: {
    onImagePreviewClose: (state) => {
      state.hidden = true
    },
    onImagePreviewShow: (
      state,
      action: PayloadAction<{
        src: string | undefined
        downloadData?: string
        openUrl?: string
      }>
    ) => {
      state.src = action.payload.src
      state.downloadData = action.payload.downloadData
      state.openUrl = action.payload.openUrl
      state.hidden = false
    },
  },
})

export const { onImagePreviewClose, onImagePreviewShow } =
  imagePreviewStateSlice.actions

export default imagePreviewStateSlice.reducer
