import {
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js'
import { Map } from 'immutable'
import { v4 as uuidv4 } from 'uuid'

import { ChatInputEditorProperties } from '../../consts'
import { PastedImageEntityType } from './types'

const MaxDepth = 4
const MinDepth = 0
const UnorderedShortCutKeys = ['*', '-'] // element長さが一緒にkeepする必要があります
const OrderedShortCutKeys = ['1.'] // element長さが一緒にkeepする必要があります

export const ChatInputEditorUtils = {
  // ショットカートとblockの種類にマッピングします
  shortCutKeyToType: (
    char: string
  ): 'BOLD' | 'ITALIC' | 'STRIKETHROUGH' | '' => {
    if (char === '*') {
      return 'BOLD'
    }

    if (char === '~') {
      return 'STRIKETHROUGH'
    }

    if (char === '_') {
      return 'ITALIC'
    }

    return ''
  },

  // *bold*, _italic_, ~strikethrough~の動作に該当したら、**,_~~に囲まれた部分にスタイルを与えます
  commonStyleShortCutState: (
    state: EditorState,
    lastInputChar: string
  ): EditorState | undefined => {
    const shortCutType = ChatInputEditorUtils.shortCutKeyToType(lastInputChar)
    // 該当するshortcut keyではありません
    if (shortCutType === '') {
      return
    }
    const contentState = state.getCurrentContent()
    const selection = state.getSelection()
    const currentBlock = ChatInputEditorUtils.getCurrentBlock(state)
    const currentBlockText = currentBlock.getText()

    const right = selection.getFocusOffset() // shortcut keyの右側のindex
    let left = -1 // shortcut keyの左側のindex

    // shortcut keyの左側を探し出します
    for (let i = selection.getFocusOffset() - 1; i >= 0; i--) {
      if (currentBlockText[i] !== lastInputChar) {
        continue
      }

      if (i === 0) {
        // shortcut keyはの左側はblockの一番左側にあるなら、該当します
        left = 0
      }

      // shortcut keyの左側は空白の場合も該当します
      if (currentBlockText[i - 1] === ' ') {
        // find
        left = i
        break
      }
    }

    // shortcut keyの左側を見つけれていない場合
    if (left == -1) {
      return
    }

    if (right <= left) {
      return
    }

    if (right - left <= 1) {
      return
    }

    // 左側のshortcut keyを削除します
    let c = Modifier.removeRange(
      contentState,
      new SelectionState({
        anchorKey: currentBlock.getKey(),
        anchorOffset: left,
        focusKey: currentBlock.getKey(),
        focusOffset: left + 1,
      }),
      'forward'
    )
    let e = EditorState.push(state, c, 'remove-range')

    // 左側のshortcut keyの次から右側のshortcut keyの左までスタイルを付与します。
    c = Modifier.applyInlineStyle(
      e.getCurrentContent(),
      new SelectionState({
        anchorKey: currentBlock.getKey(),
        anchorOffset: left,
        focusKey: currentBlock.getKey(),
        focusOffset: right - 1,
      }),
      ChatInputEditorUtils.shortCutKeyToType(lastInputChar)
    )

    // カーソルを右側shortcut keyに移動します
    e = EditorState.forceSelection(
      EditorState.push(e, c, 'change-inline-style'),
      new SelectionState({
        anchorKey: currentBlock.getKey(),
        anchorOffset: right - 1,
        focusKey: currentBlock.getKey(),
        focusOffset: right - 1,
      })
    )

    e = EditorState.setInlineStyleOverride(
      e,
      e
        .getCurrentInlineStyle()
        .remove(ChatInputEditorUtils.shortCutKeyToType(lastInputChar))
    )
    return e
  },

  // `some code`により、ショットカートが存在したら、``で囲まれた部分にentityを与えます。
  inlineCodeShortCutState: (
    state: EditorState,
    lastInputChar: string
  ): EditorState | undefined => {
    // 最後入力したのは半角の`の場合のみ確認します
    if (lastInputChar !== '`') {
      return
    }

    const contentState = state.getCurrentContent()
    const selection = state.getSelection()
    const currentBlock = ChatInputEditorUtils.getCurrentBlock(state)
    const currentBlockText = currentBlock.getText()

    const right = selection.getFocusOffset()
    let left = -1

    for (let i = selection.getFocusOffset() - 1; i >= 0; i--) {
      if (currentBlockText[i] !== lastInputChar) {
        continue
      }

      const entityKey = currentBlock.getEntityAt(i)
      if (entityKey != null) {
        const entity = contentState.getEntity(entityKey)
        if (entity != null && entity.getType() === 'CODE') {
          continue
        }
      }

      if (i === 0) {
        // find
        left = 0
      }

      if (currentBlockText[i - 1] === ' ') {
        // find
        left = i
        break
      }
    }

    if (left == -1) {
      return
    }

    if (right <= left) {
      return
    }

    if (right - left <= 1) {
      return
    }

    let c = Modifier.removeRange(
      contentState,
      new SelectionState({
        anchorKey: currentBlock.getKey(),
        anchorOffset: left,
        focusKey: currentBlock.getKey(),
        focusOffset: left + 1,
      }),
      'forward'
    )
    let e = EditorState.push(state, c, 'remove-range')

    c = c.createEntity('CODE', 'MUTABLE', '')

    const k = c.getLastCreatedEntityKey()
    c = Modifier.applyEntity(
      c,
      new SelectionState({
        anchorKey: currentBlock.getKey(),
        anchorOffset: left,
        focusKey: currentBlock.getKey(),
        focusOffset: right - 1,
      }),
      k
    )

    e = EditorState.push(e, c, 'apply-entity')

    return EditorState.forceSelection(
      e,
      new SelectionState({
        anchorKey: selection.getAnchorKey(),
        anchorOffset: right - 1,
        focusKey: selection.getFocusKey(),
        focusOffset: right - 1,
      })
    )
  },

  // -space,*spaceにより、箇条書きモードに変換するショットカートキーであるかどうかを検知します
  currentInputContainsListShortCutKey: (
    state: EditorState,
    lastInputChar: string
  ): { isUnorderedList: boolean; isOrderedList: boolean } => {
    const currentBlock = ChatInputEditorUtils.getCurrentBlock(state)
    const currentBlockText = currentBlock.getText()

    if (currentBlockText.length < 1) {
      return { isUnorderedList: false, isOrderedList: false }
    }

    const isUnorderedList =
      UnorderedShortCutKeys.some((v) => v === currentBlockText) &&
      lastInputChar === ' '

    const isOrderedList =
      OrderedShortCutKeys.some((v) => v === currentBlockText) &&
      lastInputChar === ' '

    return { isUnorderedList: isUnorderedList, isOrderedList: isOrderedList }
  },

  // -spaceもしくは*spaceを検知したら、カーソル所在するblockを箇条書きモードに変換します
  onListShortCutKeyDetect: (
    state: EditorState,
    ordered: boolean
  ): EditorState | undefined => {
    const contentState = state.getCurrentContent()
    const selection = state.getSelection()
    const currentBlock = ChatInputEditorUtils.getCurrentBlock(state)
    if (ChatInputEditorUtils.isListBlock(currentBlock.getType())) {
      return
    }
    // まずは既存のハイフン(アスタリスク)を消します
    const newContentState = Modifier.removeRange(
      contentState,
      selection.merge({
        anchorKey: selection.getAnchorKey(),
        anchorOffset: 0,
        focusKey: selection.getFocusKey(),
        focusOffset: ordered
          ? OrderedShortCutKeys.length > 0
            ? OrderedShortCutKeys[0].length
            : 0
          : UnorderedShortCutKeys.length > 0
          ? UnorderedShortCutKeys[0].length
          : 0,
      }),
      'forward'
    )
    const s = EditorState.push(state, newContentState, 'delete-character')

    // そして、入力モードを箇条書きモードにします
    const c = Modifier.setBlockType(
      s.getCurrentContent(),
      selection,
      ordered ? 'ordered-list-item' : 'unordered-list-item'
    )

    const e = EditorState.push(s, c, 'change-block-type')
    return EditorState.forceSelection(
      e,
      new SelectionState({
        anchorKey: selection.getAnchorKey(),
        anchorOffset: 0,
        focusKey: selection.getFocusKey(),
        focusOffset: 0,
      })
    )
  },

  // ```により、カーソル所在するblockを同幅モードに変換するショットカートを検知します
  currentInputContainsCodeBlockShortKey: (
    state: EditorState,
    lastInputChar: string
  ): boolean => {
    const currentBlock = ChatInputEditorUtils.getCurrentBlock(state)

    if (
      currentBlock.getData().get(ChatInputEditorProperties.BlockQuoteLineKey) !=
      null
    ) {
      return false
    }

    const currentBlockText = currentBlock.getText()
    if (currentBlockText.length < 2) {
      return false
    }

    return currentBlockText === '``' && lastInputChar === '`'
  },

  // ```により、カーソル所在するblockを同幅モードに変換します
  onCodeBlockShortKeyDetect: (state: EditorState): EditorState => {
    const contentState = state.getCurrentContent()
    const selection = state.getSelection()
    // まずは既存の```を消します
    const newContentState = Modifier.removeRange(
      contentState,
      selection.merge({
        anchorKey: selection.getAnchorKey(),
        anchorOffset: 0,
        focusKey: selection.getFocusKey(),
        focusOffset: 2,
      }),
      'forward'
    )
    const s = EditorState.push(state, newContentState, 'delete-character')

    // そして、入力モードをcode-blockにします
    const c = Modifier.setBlockType(
      s.getCurrentContent(),
      selection,
      'code-block'
    )
    const e = EditorState.push(s, c, 'change-block-type')
    return EditorState.forceSelection(
      e,
      new SelectionState({
        anchorKey: selection.getAnchorKey(),
        anchorOffset: 0,
        focusKey: selection.getFocusKey(),
        focusOffset: 0,
      })
    )
  },

  // ##により、カーソル所在するblockをh1モードに変換するショットカートを検知します
  currentInputContainsH1ShortKey: (
    state: EditorState,
    lastInputChar: string
  ): boolean => {
    const currentBlock = ChatInputEditorUtils.getCurrentBlock(state)
    const currentBlockText = currentBlock.getText()
    return currentBlockText === '#' && lastInputChar === '#'
  },

  // ##により、カーソル所在するblockをh1モードに変換します
  onH1ShortKeyDetect: (state: EditorState): EditorState => {
    const contentState = state.getCurrentContent()
    const selection = state.getSelection()
    // まずは既存の##を消します
    const newContentState = Modifier.removeRange(
      contentState,
      selection.merge({
        anchorKey: selection.getAnchorKey(),
        anchorOffset: 0,
        focusKey: selection.getFocusKey(),
        focusOffset: 1,
      }),
      'forward'
    )
    const s = EditorState.push(state, newContentState, 'delete-character')

    // そして、入力モードをheader-oneにします
    const c = Modifier.setBlockType(
      s.getCurrentContent(),
      selection,
      'header-one'
    )
    const e = EditorState.push(s, c, 'change-block-type')
    return EditorState.forceSelection(
      e,
      new SelectionState({
        anchorKey: selection.getAnchorKey(),
        anchorOffset: 0,
        focusKey: selection.getFocusKey(),
        focusOffset: 0,
      })
    )
  },

  // カーソルはinline codeブロックの右沿にある時に入力されたら、既存のinline codeの中で入力し続けるように
  onInputAtInlineCodeBlockEdge: (
    editorState: EditorState,
    chars: string
  ): EditorState | undefined => {
    const selection = editorState.getSelection()

    if (!selection.isCollapsed()) {
      return
    }

    const focusOffset = selection.getFocusOffset()
    const contentState = editorState.getCurrentContent()
    const currentBlock = ChatInputEditorUtils.getCurrentBlock(editorState)
    const currentEntityKey = currentBlock.getEntityAt(focusOffset - 1)
    if (currentEntityKey == null) {
      return
    }

    const currentEntity = contentState.getEntity(currentEntityKey)
    if (currentEntity == null) {
      return
    }

    if (currentEntity.getType() !== 'CODE') {
      return
    }
    const currentInlineStyle = currentBlock.getInlineStyleAt(focusOffset - 1)
    const c = Modifier.insertText(
      contentState,
      selection,
      chars,
      currentInlineStyle,
      currentEntityKey
    )
    return EditorState.forceSelection(
      EditorState.push(editorState, c, 'insert-characters'),
      selection.merge({
        anchorOffset: selection.getAnchorOffset() + 1,
        focusOffset: selection.getFocusOffset() + 1,
      })
    )
  },

  // 右矢印の入力を検知した場合は、こちらで対応します
  // inline codeから飛び出すための処理です
  onArrowRight: (editorState: EditorState): EditorState | undefined => {
    const selection = editorState.getSelection()
    if (!selection.isCollapsed()) {
      return
    }
    const contentState = editorState.getCurrentContent()
    const currentBlock = ChatInputEditorUtils.getCurrentBlock(editorState)
    const anchorOffset = selection.getAnchorOffset()

    // カーソルは行の末にあることが条件です
    if (anchorOffset !== currentBlock.getLength()) {
      return
    }

    // カーソルの所在地はCODE entityが付きが条件です
    const currentEntityKey = currentBlock.getEntityAt(
      selection.getAnchorOffset() - 1
    )
    if (currentEntityKey == null) {
      return
    }
    const currentEntity = contentState.getEntity(currentEntityKey)
    if (currentEntity == null) {
      return
    }
    if (currentEntity.getType() !== 'CODE') {
      return
    }

    const c = Modifier.insertText(
      editorState.getCurrentContent(),
      new SelectionState({
        anchorKey: selection.getAnchorKey(),
        anchorOffset: selection.getAnchorOffset(),
        focusKey: selection.getFocusKey(),
        focusOffset: selection.getFocusOffset(),
      }),
      ' '
    )

    let e = EditorState.push(editorState, c, 'insert-characters')
    e = EditorState.forceSelection(
      e,
      new SelectionState({
        anchorKey: selection.getAnchorKey(),
        anchorOffset: selection.getAnchorOffset() + 1,
        focusKey: selection.getFocusKey(),
        focusOffset: selection.getFocusOffset() + 1,
      })
    )

    return e
  },

  onTab: (
    editorState: EditorState,
    shiftKey: boolean
  ): EditorState | undefined => {
    const selection = editorState.getSelection()
    if (!selection.isCollapsed()) {
      // 複数行選択されてある場合は、複雑ですので、別途で考えます
      return
    }
    if (!ChatInputEditorUtils.isFocusingOnListBlock(editorState)) {
      return
    }

    const currentBlockKey = editorState.getSelection().getAnchorKey()
    const currentBlock = ChatInputEditorUtils.getCurrentBlock(editorState)
    const currentBlockType = currentBlock.getType()

    const lastBlock = editorState
      .getCurrentContent()
      .getBlockBefore(currentBlockKey)
    // 箇条書きの一行目です、前進はできません
    if (lastBlock == null) {
      return
    }

    // 前のblockは箇条書きblockではなくため、箇条書きの一行目です、前進はできません
    const lastBlockType = lastBlock.getType()
    if (!ChatInputEditorUtils.isListBlock(lastBlockType)) {
      return
    }

    // 前のblockは箇条書きblockですが、現状はunOrderList、前のblockはorderListなら、現在の行のblockは一行目と見なし、前進ができません
    if (lastBlockType !== currentBlockType) {
      return
    }

    const lastBlockDepth = lastBlock?.getDepth()
    const currentBlockDepth = currentBlock?.getDepth()

    // indentを右へ前進する時に、前のブロックのindentより1レベルまで前進できます
    if (!shiftKey && currentBlockDepth - lastBlockDepth >= 1) {
      return
    }

    // カーソル所在するblockの下のblockはカーソル所在するblockと同じdepthの場合は、右また左へ移動できるようにします。
    const nextBlock = editorState
      .getCurrentContent()
      .getBlockAfter(currentBlockKey)
    const nextBlockType = nextBlock?.getType()
    if (
      nextBlockType != null &&
      ChatInputEditorUtils.isListBlock(nextBlockType) &&
      nextBlock?.getDepth() === currentBlockDepth
    ) {
      const blockMaps = editorState.getCurrentContent().getBlockMap()

      const a = blockMaps.set(
        currentBlockKey,
        blockMaps.get(currentBlockKey).merge({
          depth: shiftKey
            ? Math.max(MinDepth, currentBlockDepth - 1)
            : Math.min(MaxDepth, currentBlockDepth + 1),
        }) as ContentBlock
      )
      const c = editorState.getCurrentContent().merge({
        blockMap: a,
        selectionBefore: selection,
        selectionAfter: selection,
      }) as ContentState
      return EditorState.push(editorState, c, 'adjust-depth')
    }

    // カーソル所在地から下まで辿り、カーソル所在するblockよりdepthが高いを探しだす、カーソル所在するblockと同じdepthに辿り付いたら、stopします。
    const newBlocks = editorState
      .getCurrentContent()
      .getBlockMap()
      .toSeq()
      .skipUntil((b) => b?.getKey() === currentBlockKey)
      .takeUntil((b) =>
        TakeUntilCondition(
          currentBlockDepth,
          currentBlockType,
          currentBlockKey,
          b
        )
      )
      .toSeq()
      .filter((b) => b != null)

    let nextBlocks: Immutable.Iterable<string, Draft.ContentBlock>
    if (shiftKey) {
      nextBlocks = newBlocks.map((b) => {
        const block = b as ContentBlock
        const depth = block.getDepth()
        return block.merge({
          depth: Math.max(MinDepth, depth - 1),
        }) as ContentBlock
      })
    } else {
      nextBlocks = newBlocks.map((b) => {
        const block = b as ContentBlock
        const depth = block.getDepth()
        return block.merge({
          depth: Math.min(MaxDepth, depth + 1),
        }) as ContentBlock
      })
    }

    const newContentState = editorState.getCurrentContent().merge({
      blockMap: editorState.getCurrentContent().getBlockMap().merge(nextBlocks),
      selectionBefore: selection,
      selectionAfter: selection,
    }) as ContentState

    return EditorState.push(editorState, newContentState, 'adjust-depth')
  },
  isFocusingOnListBlock: (state: EditorState): boolean => {
    const currentBlockType = RichUtils.getCurrentBlockType(state)
    return ChatInputEditorUtils.isListBlock(currentBlockType)
  },
  isFocusingOnBlockquote: (state: EditorState): boolean => {
    return (
      ChatInputEditorUtils.getCurrentBlock(state)
        .getData()
        .get(ChatInputEditorProperties.BlockQuoteLineKey) != null
    )
  },
  isListBlock: (block: ContentBlock | string): boolean => {
    if (block instanceof ContentBlock) {
      return (
        block.getType() === 'unordered-list-item' ||
        block.getType() === 'ordered-list-item'
      )
    }
    return block === 'unordered-list-item' || block === 'ordered-list-item'
  },
  isBlockQuoteBlock: (block?: ContentBlock): boolean => {
    if (block == null) {
      return false
    }
    return (
      block.getData().get(ChatInputEditorProperties.BlockQuoteLineKey) != null
    )
  },
  modifySelectedBlocks: (
    editorState: EditorState,
    fn: (b?: ContentBlock) => ContentBlock
  ): ContentState | undefined => {
    const contentState = editorState.getCurrentContent()
    const blockMaps = contentState.getBlockMap()
    const newBlocks = ChatInputEditorUtils.getSelectedBlocks(editorState)
      .filter((b) => b != null)
      .map(fn)

    const newBlockMaps = blockMaps.merge(newBlocks)

    return contentState.merge({
      blockMap: newBlockMaps,
    }) as ContentState
  },
  toggleSelectionBlockQuote: (
    editorState: EditorState
  ): ContentState | undefined => {
    const contentState = editorState.getCurrentContent()
    const blockMaps = contentState.getBlockMap()
    const blocks = ChatInputEditorUtils.getSelectedBlocks(editorState).filter(
      (b) => b != null
    )
    const uuid = uuidv4()
    const newBlocks = blocks.map((b) => {
      const block = b as ContentBlock
      let blockData = block.getData()

      if (blockData.get(ChatInputEditorProperties.BlockIndentKey) != null) {
        blockData = blockData.remove(ChatInputEditorProperties.BlockIndentKey)
      }

      if (blockData.get(ChatInputEditorProperties.BlockQuoteLineKey) != null) {
        blockData = blockData.remove(
          ChatInputEditorProperties.BlockQuoteLineKey
        )
      } else {
        blockData = blockData.set(
          ChatInputEditorProperties.BlockQuoteLineKey,
          uuid
        )
      }

      return block.merge({
        type: 'unstyled',
        data: blockData,
      }) as ContentBlock
    })
    return contentState.merge({
      blockMap: blockMaps.merge(newBlocks),
    }) as ContentState
  },
  getSelectedBlocks: (
    editorState: EditorState
  ): Immutable.Iterable<string, ContentBlock> => {
    const selection = editorState.getSelection()
    const startKey = selection.getStartKey()
    const endKey = selection.getEndKey()
    const contentState = editorState.getCurrentContent()
    const selectedBlocks = contentState
      .getBlockMap()
      .toSeq()
      .skipUntil((b) => b?.getKey() === startKey)
      .takeUntil((b) => b?.getKey() === endKey)
      .concat(Map([[endKey, contentState.getBlockForKey(endKey)]]))
    return selectedBlocks
  },
  getCurrentBlock: (editorState: EditorState): ContentBlock => {
    const selection = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const key = selection.getStartKey()
    const block = contentState.getBlockForKey(key)
    return block
  },
  currentSelectionContainsImage: (editorState: EditorState): boolean => {
    const selectedBlocks =
      ChatInputEditorUtils.getSelectedBlocks(editorState).toArray()
    const contentState = editorState.getCurrentContent()
    return selectedBlocks.some((b) => {
      if (b.getType() !== 'atomic') {
        return false
      }
      const entityKey = b.getEntityAt(0)
      if (entityKey == null) {
        return false
      }
      const entity = contentState.getEntity(entityKey)
      return entity.getType() === PastedImageEntityType
    })
  },
  currentSelectionContainsList: (editorState: EditorState): boolean => {
    const selectedBlocks =
      ChatInputEditorUtils.getSelectedBlocks(editorState).toArray()
    return selectedBlocks.some((b) => {
      return ChatInputEditorUtils.isListBlock(b)
    })
  },
  currentSelectionContainsBlockQuote: (editorState: EditorState): boolean => {
    const selectedBlocks =
      ChatInputEditorUtils.getSelectedBlocks(editorState).toArray()
    return selectedBlocks.some((b) => {
      return ChatInputEditorUtils.isBlockQuoteBlock(b)
    })
  },
  singleLineSelected: (editorState: EditorState): boolean => {
    const selection = editorState.getSelection()
    return selection.getAnchorKey() === selection.getFocusKey()
  },
  holdSelection: (editorState: EditorState): EditorState => {
    return EditorState.forceSelection(editorState, editorState.getSelection())
  },
}

const TakeUntilCondition = (
  criterionDepth: number,
  criterionBlockType: string,
  currentBlockKey: string,
  block?: Draft.ContentBlock
): boolean => {
  if (block == null) {
    return true
  }

  if (block.getType() !== criterionBlockType) {
    return true
  }

  if (currentBlockKey === block.getKey()) {
    return false
  }

  const blockType = block.getType()
  if (!ChatInputEditorUtils.isListBlock(blockType)) {
    return true
  }

  const depth = block.getDepth()

  if (depth == null) {
    return true
  }

  return depth <= criterionDepth
}
