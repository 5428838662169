// package: 
// file: proto/thumbnails.proto

var proto_thumbnails_pb = require("../proto/thumbnails_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ThumbnailsAPI = (function () {
  function ThumbnailsAPI() {}
  ThumbnailsAPI.serviceName = "ThumbnailsAPI";
  return ThumbnailsAPI;
}());

ThumbnailsAPI.GetThumbnailByGroupID = {
  methodName: "GetThumbnailByGroupID",
  service: ThumbnailsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_thumbnails_pb.GetThumbnailByGroupIDRequest,
  responseType: proto_thumbnails_pb.Thumbnail
};

ThumbnailsAPI.GetThumbnailByUserID = {
  methodName: "GetThumbnailByUserID",
  service: ThumbnailsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_thumbnails_pb.GetThumbnailByUserIDRequest,
  responseType: proto_thumbnails_pb.Thumbnail
};

ThumbnailsAPI.GetThumbnailByDriveID = {
  methodName: "GetThumbnailByDriveID",
  service: ThumbnailsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_thumbnails_pb.GetThumbnailByDriveIDRequest,
  responseType: proto_thumbnails_pb.Thumbnail
};

exports.ThumbnailsAPI = ThumbnailsAPI;

function ThumbnailsAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ThumbnailsAPIClient.prototype.getThumbnailByGroupID = function getThumbnailByGroupID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ThumbnailsAPI.GetThumbnailByGroupID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ThumbnailsAPIClient.prototype.getThumbnailByUserID = function getThumbnailByUserID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ThumbnailsAPI.GetThumbnailByUserID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ThumbnailsAPIClient.prototype.getThumbnailByDriveID = function getThumbnailByDriveID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ThumbnailsAPI.GetThumbnailByDriveID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ThumbnailsAPIClient = ThumbnailsAPIClient;

