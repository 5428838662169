import { CloseIcon, InfoIcon } from '@fluentui/react-northstar'
import * as React from 'react'

import styles from './styles.module.css'
import { RecommendedFaqRedundantIntentEntity } from './types/RecommendedFaqRedundantIntentEntity'

type Props = {
  open: boolean
  loading: boolean
  intents: RecommendedFaqRedundantIntentEntity[]
  onClose: () => void
}

const RecommendedFaqRedundantIntentListPresenter: React.FC<Props> = (props) => {
  if (!props.open) {
    return <></>
  }
  if (props.loading) {
    return <></>
  }
  if (props.intents.length === 0) {
    return <></>
  }

  return (
    <div className={styles.container}>
      <CloseIcon
        className={styles.close}
        size="small"
        onClick={props.onClose}
      />
      <div className={styles.info}>
        <InfoIcon outline size="large" xSpacing="after" />
        <span>似た内容のインテントが登録されています</span>
      </div>
      <ul>
        {props.intents.map((intent) => (
          <li key={intent.id}>
            {`${intent.intentQuestion}(インテント識別子: ${intent.intentName})`}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default React.memo(RecommendedFaqRedundantIntentListPresenter)
