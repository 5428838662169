import { grpc } from '@improbable-eng/grpc-web'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { RootState } from '../../app/store'
import { consoleErrorWithAirbrake } from '../../utils'
import { Thumbnail } from './types'

export enum ThumbnailsStatus {
  NotFound = 'NotFound',
}

export declare type ThumbnailsData = { [name: string]: Thumbnail }

export interface ThumbnailsState {
  itemId: string
  thumbnails?: ThumbnailsData
  status?: ThumbnailsStatus
}

export const thumbnailsAdapter = createEntityAdapter<ThumbnailsState>({
  selectId: (e) => e.itemId,
})

export const thumbnailsSelectors = thumbnailsAdapter.getSelectors(
  (state: RootState) => {
    return state.thumbnails
  }
)

// このスライスは二つの役割があります。
// 役割1: チケット詳細画面でメッセージにある添付画像を表示させるため情報を持ちます。
// 役割2: チケット詳細画面でメッセージにあるオペレーターの貼り付け画像を表示させるための情報を持ちます。
export const ThumbnailsSlice = createSlice({
  name: 'thumbnails',
  initialState: thumbnailsAdapter.getInitialState(),
  reducers: {
    fetchThumbnailOnMessage: (
      state,
      action: PayloadAction<ThumbnailsState>
    ) => {
      thumbnailsAdapter.upsertOne(state, action.payload)
    },
    fetchThumbnailOnEnd: (
      state,
      action: PayloadAction<{ code: grpc.Code; error: string; id: string }>
    ) => {
      if (action.payload.code === grpc.Code.OK) {
        return
      }

      if (action.payload.code === grpc.Code.NotFound) {
        thumbnailsAdapter.updateOne(state, {
          id: action.payload.id,
          changes: {
            status: ThumbnailsStatus.NotFound,
          },
        })
        return
      }

      consoleErrorWithAirbrake(`fetchThumbnail failed=${action.payload.error}`)
    },
  },
})

export const { fetchThumbnailOnMessage, fetchThumbnailOnEnd } =
  ThumbnailsSlice.actions

export default ThumbnailsSlice.reducer
