import { useMonaco } from '@monaco-editor/react'
import * as React from 'react'

import styles from './styles.module.css'

type Props = {
  __dangerouslyHTML?: { __html: string }
}
const RequesterChatText: React.FC<Props> = (props) => {
  const monaco = useMonaco()
  const [html, setHtml] = React.useState(props.__dangerouslyHTML?.__html ?? '')

  // TODO この処理はinitMessage()で行うべき
  // コードブロックがあればmonacoで色をつける
  React.useEffect(() => {
    if (!props.__dangerouslyHTML) return
    // 再レンダリングでHTMLの更新がある場合、setHtmlで更新しておく
    setHtml(props.__dangerouslyHTML.__html)
    if (!monaco) return

    const parser = new DOMParser()
    const docEl = parser.parseFromString(
      props.__dangerouslyHTML.__html,
      'text/html'
    )
    const codeblockEl = docEl.querySelector('div.codeblock')
    if (!codeblockEl) return
    const preEl = codeblockEl.querySelector('pre')
    if (!preEl) return
    const language = preEl.getAttribute('data-language')
    monaco.editor
      .colorize(preEl.innerText, language ?? 'plaintext', {})
      .then((colorized) => {
        preEl.innerHTML = colorized
        setHtml(docEl.body.innerHTML)
      })
  }, [monaco, props.__dangerouslyHTML])

  return (
    <div
      className={styles.container}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default RequesterChatText
