import { ExclamationCircleIcon } from '@fluentui/react-icons-northstar'
import { Dialog, Flex, Label, Loader, Text } from '@fluentui/react-northstar'
import * as Graph from '@microsoft/microsoft-graph-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { Dropdown } from '../../components/Dropdown'
import PageViewLog from '../../components/PageViewLog'
import { PageName } from '../../consts'
import { getChannelDisplayName } from '../../utils'
import { fetchBotAddedGroups } from './botAddedGroupsSlice'
import { fetchGroups } from './groupSlice'
import { fetchTabAddedGroups } from './tabAddedGroupSlice'
import { fetchTabChannels } from './tabChannelsSlice'
import styles from './TicketDetail.module.css'

interface Props {
  open?: boolean
  onConfirm: (values: { groupId: string; channelId: string }) => void
  onCancel: () => void
}

const TicketChannelDialog: React.FC<Props> = ({
  open,
  onConfirm,
  onCancel,
}) => {
  const dispatch = useDispatch()

  const authState = useSelector((state: RootState) => state.auth)

  const currentGroupId = authState.context?.team?.groupId
  const currentChannelId = authState.context?.channel?.id ?? null

  const [groupId, setGroupId] = useState<string>(currentGroupId || '')
  const [channelId, setChannelId] = useState<string | null>(currentChannelId)

  const tabAddedGroupState = useSelector(
    (state: RootState) => state.tabAddedGroup
  )
  // botAddedGroupState.loading
  const groupState = useSelector((state: RootState) => state.group)
  const tabChannelsState = useSelector((state: RootState) => state.tabChannels)

  const groups = groupState.ids.map((id) => groupState.entities[id])
  const tabChannels = tabChannelsState.ids.map(
    (id) => tabChannelsState.entities[id]
  )

  useEffect(() => {
    if (!open) return
    dispatch(fetchBotAddedGroups())
    dispatch(fetchTabAddedGroups())
  }, [dispatch, open])

  useEffect(() => {
    if (tabAddedGroupState.ids.length === 0) return
    dispatch(fetchGroups(tabAddedGroupState.ids))
  }, [dispatch, tabAddedGroupState.ids])

  useEffect(() => {
    if (!open) return
    if (groupId.trim().length === 0) {
      return
    }
    dispatch(fetchTabChannels(groupId))
  }, [dispatch, groupId, open])

  return (
    <PageViewLog
      pageName={PageName.TicketChannelDialog}
      isOpenable
      isOpen={open}
    >
      <Dialog
        open={open}
        onConfirm={() =>
          onConfirm({
            groupId: groupId,
            channelId: channelId as string,
          })
        }
        onCancel={() => {
          onCancel()
          setGroupId(currentGroupId || '')
          setChannelId(currentChannelId)
        }}
        cancelButton="キャンセル"
        confirmButton={{
          content: '確定',
          disabled:
            channelId == null ||
            channelId === currentChannelId ||
            groupState.loading ||
            tabChannelsState.loading,
        }}
        content={
          tabAddedGroupState.loading ? (
            <Loader size="small" />
          ) : (
            <Flex column gap="gap.medium">
              <h2>移動先チャネルの選択</h2>
              <Text>
                この問い合わせを、別のチーム/チャネルに移動します。移動先を選択してください。
              </Text>
              <Flex column gap="gap.smaller">
                <Label color="white" className={styles.ticketChannelLabel}>
                  チーム
                  <Loader
                    inline={true}
                    size="small"
                    secondary={!groupState.loading}
                  />
                </Label>
                <Dropdown
                  fluid
                  disabled={groupState.loading}
                  className={styles.ticketChannelDropdown}
                  value={[groupId]}
                  items={groups.map((grp: Graph.Team | undefined) => ({
                    value: grp?.id || '',
                    header: grp?.displayName || '',
                  }))}
                  onValueChange={(values) => {
                    setGroupId(values[0])
                    setChannelId(null)
                  }}
                />
              </Flex>
              <Flex column gap="gap.smaller">
                <Label color="white" className={styles.ticketChannelLabel}>
                  チャネル
                  <Loader
                    inline={true}
                    size="small"
                    secondary={!groupState.loading && !tabChannelsState.loading}
                  />
                </Label>
                <Dropdown
                  fluid
                  disabled={groupState.loading || tabChannelsState.loading}
                  loading={tabChannelsState.loading}
                  loadingMessage="読込中..."
                  placeholder="選択してください"
                  className={styles.ticketChannelDropdown}
                  value={channelId == null ? null : [channelId]}
                  items={tabChannels.map((c: Graph.Channel | undefined) => ({
                    value: c?.id || '',
                    header: getChannelDisplayName(c),
                  }))}
                  onValueChange={(values) => {
                    setChannelId(values[0])
                  }}
                />
              </Flex>
              <Flex gap="gap.smaller">
                <ExclamationCircleIcon />
                <Text>
                  一度チャネルを変更すると、現在のチャネルから内容を確認したり操作することができなくなりますのでご注意ください。
                </Text>
              </Flex>
            </Flex>
          )
        }
      />
    </PageViewLog>
  )
}

export default TicketChannelDialog
