// package: 
// file: proto/tab_channel.proto

var proto_tab_channel_pb = require("../proto/tab_channel_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TabChannelAPI = (function () {
  function TabChannelAPI() {}
  TabChannelAPI.serviceName = "TabChannelAPI";
  return TabChannelAPI;
}());

TabChannelAPI.GetTabChannels = {
  methodName: "GetTabChannels",
  service: TabChannelAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_tab_channel_pb.GetTabChannelsRequest,
  responseType: proto_tab_channel_pb.GetTabChannelsResponse
};

exports.TabChannelAPI = TabChannelAPI;

function TabChannelAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TabChannelAPIClient.prototype.getTabChannels = function getTabChannels(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TabChannelAPI.GetTabChannels, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TabChannelAPIClient = TabChannelAPIClient;

