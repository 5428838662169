import { Chat, ChatItemProps, Loader } from '@fluentui/react-northstar'
import * as React from 'react'

import styles from './styles.module.css'

type Props = {
  isLoading: boolean
  title: string
  chatItems: ChatItemProps[]
}

const RecommendedFaqTicketPresenter: React.FC<Props> = (props) => {
  const chatRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (props.chatItems.length < 1) {
      return
    }
    if (chatRef.current === null) {
      return
    }

    chatRef.current.scrollTo(0, chatRef.current.scrollHeight)
  }, [props.chatItems])

  if (props.isLoading) {
    return (
      <div className={styles.info}>
        <Loader size="small" label="対話を読込中" labelPosition="below" />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.label}>参考の問い合わせ</p>
        <p className={styles.title}>{props.title}</p>
      </div>
      <div ref={chatRef} className={styles.content}>
        <Chat
          styles={{
            backgroundColor: 'transparent',
            border: 'none',
            padding: 0,
          }}
          items={props.chatItems}
        />
      </div>
    </div>
  )
}

export default React.memo(RecommendedFaqTicketPresenter)
