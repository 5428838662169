import {
  Box,
  Button,
  Divider,
  Flex,
  Menu,
  MenuProps,
} from '@fluentui/react-northstar'
import React, { Dispatch, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'

import { AppThunk, RootState } from '../../app/store'
import DialogWithCloseIcon from '../../components/DialogWithCloseIcon'
import {
  CustomFieldValue,
  TicketStatus,
  customFieldMaxLength,
} from '../../consts'
import { addAlert } from '../alert/alertsSlice'
import FaqCreate from '../faq/FaqCreate'
import { fetchCustomFields } from './customFieldSlice'
import {
  fetchCustomFieldValues,
  setCustomFieldValueInitialize,
} from './customFieldValueSlice'
import { RequesterContactInfo } from './RequesterContactInfo'
import TicketProperty from './TicketProperty'
import { deleteManualTicket, updateTicket } from './ticketSlice'

export interface TicketDetailSideColumnProps {
  statusDisable: boolean
  tabIndex: number
  setTabIndex: (tabIndex: number) => void
}

const TicketDetailSideColumn: React.FC<TicketDetailSideColumnProps> = (
  props: TicketDetailSideColumnProps
) => {
  const dispatch = useDispatch()
  const { ticketId } = useParams<{ ticketId: string }>()
  const ticketState = useSelector((state: RootState) => state.ticket)
  const memberState = useSelector((state: RootState) => state.member)
  const usersState = useSelector((state: RootState) => state.users)
  const authState = useSelector((state: RootState) => state.auth)
  const userPhotosState = useSelector((state: RootState) => state.userPhotos)
  const customFieldValueState = useSelector(
    (state: RootState) => state.customFieldValue
  )

  const tabItems = [
    { key: '0', content: '問い合わせ情報' },
    { key: '1', content: 'FAQの作成' },
  ]
  const onActiveIndexChangeTab = (data?: MenuProps) => {
    if (data?.activeIndex == 0) props.setTabIndex(0)
    else props.setTabIndex(1)
  }

  useEffect(() => {
    dispatch(setCustomFieldValueInitialize())
  }, [dispatch])

  // CustomFieldValueの共通アラート
  // カスタムフィールドの値のAPIのみがデグレなどの影響で異常状態の時にチケット詳細全てが閲覧不可にならないようにリダイレクトはせずAlert
  useEffect(() => {
    if (customFieldValueState.error != null) {
      dispatch(
        addAlert({
          id: parseInt(ticketId, 10),
          title: CustomFieldValue.ClientErrorMessage.Unknown,
          type: 'custom_field_value_error',
        })
      )
    }
  }, [dispatch, customFieldValueState.error, ticketId])

  useEffect(() => {
    if (customFieldValueState.isInitializing) {
      dispatch(fetchCustomFieldValues(parseInt(ticketId, 10)))
    }
  }, [dispatch, ticketId, customFieldValueState.isInitializing])

  useEffect(() => {
    dispatch(fetchCustomFields(0, customFieldMaxLength))
  }, [dispatch])

  const ticket = ticketState.entities[ticketId]
  const loginUserId = authState.context?.user?.id

  const [subject, setSubject] = useState<string>()

  const handleSubjectChanges = (
    dispatch: Dispatch<AppThunk<void>>,
    ticketId: number,
    subject: string
  ) => {
    dispatch(updateTicket({ id: ticketId, subject: subject }, true))
  }

  useEffect(() => {
    setSubject((s) => {
      // When subject is not initlized, try to retrieve the value from ticket
      if (s == null) {
        return ticket?.subject
      }
      // After subject is initilized, do nothing
      return s
    })
  }, [ticket?.subject])

  useEffect(() => {
    const handleUpdate = () => {
      if (!ticket?.id) return
      handleSubjectChanges(dispatch, ticket.id, String(subject))
    }
    // popstate(ブラウザバック)はrefがnullになり値が取得できないのでこちらでaddEventListenerしている
    window.addEventListener('popstate', handleUpdate)
    return () => {
      window.removeEventListener('popstate', handleUpdate)
    }
  }, [dispatch, subject, ticket?.id])

  if (memberState.error != null) {
    return <Redirect to={{ pathname: '/errors/unknown' }} />
  }

  if (!ticketState.error.isEmpty() && !ticketState.error.isNetworkErr()) {
    return <Redirect to={{ pathname: '/errors/unknown' }} />
  }

  if (ticket == null) return null
  if (loginUserId == null) return null
  const requester = usersState.entities[ticket.requesterUserId]
  const requesterPhoto =
    userPhotosState.entities[ticket.requesterUserId]?.avatarBlob || ''

  return (
    <Flex column gap="gap.smaller">
      <RequesterContactInfo
        requester={requester}
        requesterPhoto={requesterPhoto}
        ticket={ticket}
      />
      {authState.deskAvailableFeatures?.faq ? (
        <>
          <Menu
            activeIndex={props.tabIndex}
            items={tabItems}
            underlined
            primary
            onActiveIndexChange={(event, data) => onActiveIndexChangeTab(data)}
          />
          {props.tabIndex == 0 && (
            <TicketProperty
              deleteTicketButton={
                ticket?.isManual && <DeleteTicketButton ticketId={ticket.id} />
              }
              statusDisable={
                ticket?.isManual === false &&
                ticket?.status === TicketStatus.completed
              }
            />
          )}
          {props.tabIndex == 1 && (
            <>
              <Box>
                <FaqCreate />
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <Divider />
          <TicketProperty
            deleteTicketButton={
              ticket?.isManual && <DeleteTicketButton ticketId={ticket.id} />
            }
            statusDisable={
              ticket?.isManual === false &&
              ticket?.status === TicketStatus.completed
            }
          />
        </>
      )}
    </Flex>
  )
}

interface DeleteTicketButtonProps {
  ticketId: number
}

const DeleteTicketButton = (props: DeleteTicketButtonProps) => {
  const { ticketId } = props
  const dispatch = useDispatch()
  const history = useHistory()

  const handleDelete = () => {
    dispatch(deleteManualTicket(ticketId, history))
  }

  return (
    <Flex gap="gap.smaller">
      <DialogWithCloseIcon
        confirmButton="削除"
        onConfirm={handleDelete}
        content={
          'この問い合わせを削除します。一度削除すると復元することはできません。削除してよろしいでしょうか？'
        }
        header="問い合わせ記録の削除"
        trigger={
          <Button
            text
            styles={({ theme: { siteVariables } }) => ({
              color: siteVariables.colorScheme.red.foreground,
            })}
          >
            問い合わせを削除
          </Button>
        }
      />
    </Flex>
  )
}

export default TicketDetailSideColumn
