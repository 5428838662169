import { createAsyncThunk } from '@reduxjs/toolkit'
import formatISO from 'date-fns/formatISO'

import { consoleErrorWithAirbrake } from '../../utils'
import { deskApi } from '../deskApi'
import { CreateTicketRequestArgs } from '../deskApi/types/CreateTicketRequestArgs'
import { requesterTicketListApi } from '../RequesterTicketList/api'
import { actions } from '../RequesterTicketList/slice'

export const CreateConversation = createAsyncThunk<
  void,
  CreateTicketRequestArgs
>('createConversation', async (args, thunkApi) => {
  const createConversationResult = await thunkApi.dispatch(
    deskApi.endpoints.createRequesterTicket.initiate(args)
  )
  if ('error' in createConversationResult) {
    console.error(createConversationResult.error)
    consoleErrorWithAirbrake(
      'error at create conversation',
      createConversationResult.error
    )
    return
  }
  await thunkApi.dispatch(
    requesterTicketListApi.endpoints.getRequesterConversations.initiate({
      maxUpdatedAt: formatISO(new Date(new Date().getTime() + 1000)),
    })
  )
  thunkApi.dispatch(actions.setTriggerScrollToBottom())
})
