import { Avatar } from '@fluentui/react-northstar'
import * as React from 'react'

type Props = {
  name: string
  src: string
}
const ActivityMessagePlanListAvatarPresenter: React.FC<Props> = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        name={props.name}
        style={{ height: '28px', width: '28px', borderRadius: '9999px' }}
        image={props.src}
      />
      <div style={{ marginLeft: '16px' }}>{props.name}</div>
    </div>
  )
}

export default React.memo(ActivityMessagePlanListAvatarPresenter)
