// bedore-dashboardのairbrakeの設定例を参考に、必要最低限の設定状態で実装しています
// https://github.com/PKSHATechnology/bedore-dashboard/blob/7b57b6bf7d338d11410464da9ca773684d492129/src/lib/airbrake.js#L1

import { INotice, Notifier } from '@airbrake/browser'

const airbrake = new Notifier({
  projectId: Number(process.env.REACT_APP_AIRBREAK_PROJECT_ID),
  projectKey: String(process.env.REACT_APP_AIRBREAK_PROJECT_KEY),
  environment: process.env.REACT_APP_ENV,
})

const isDevelopment = (notice: INotice) => {
  return notice.context.environment === 'development'
}

airbrake.addFilter((notice: INotice) => {
  // dev環境は無効
  if (isDevelopment(notice)) return null
  return notice
})

export default airbrake
