import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { RootState } from '../app/store'
import { refreshToken } from '../features/auth/authSlice'

const ProtectedRoute: React.FC<RouteProps> = ({ ...props }) => {
  const dispatch = useDispatch()
  // 1分ごとにチェックして、前回取得時間から30分以上経ったら、tokenを更新します
  useEffect(() => {
    let time = Date.now()
    const id = setInterval(() => {
      if (Date.now() - time >= 30 * 60 * 1000) {
        dispatch(refreshToken())
        time = Date.now()
      }
    }, 60 * 1000)
    return () => {
      clearInterval(id)
    }
  }, [dispatch])

  const { accessToken, graphAccessToken, error } = useSelector(
    (state: RootState) => state.auth
  )

  if (error != null) {
    return <Redirect to={{ pathname: '/errors/unknown' }} />
  }

  if (accessToken != null && graphAccessToken != null) {
    return <Route {...props} />
  }

  return (
    <Redirect
      to={{ pathname: '/login', state: { from: props.location?.pathname } }}
    />
  )
}

export default ProtectedRoute
