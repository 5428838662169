import { CloseIcon, Text } from '@fluentui/react-northstar'
import * as React from 'react'

import styles from './styles.module.css'

type Props = {
  text: string
  showCloseButton?: boolean
  onClickCloseButton?: () => void
}
const DashboardBadgeContent: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <Text size="small" className={styles.text}>
        {props.text}
      </Text>
      {props.showCloseButton && (
        <CloseIcon
          size="small"
          onClick={props.onClickCloseButton}
          className={styles.closeIcon}
        />
      )}
    </div>
  )
}

export default DashboardBadgeContent
