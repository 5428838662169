import { EntityState } from '@reduxjs/toolkit'

import * as ActivityMessagePB from '../../proto/activity_message_pb'
import * as ActivityMessageServicePB from '../../proto/activity_message_pb_service'
import { deskOperatorApi } from '../deskApiOperator'
import { availableReceiverEntityAdapter } from './slice'
import { AvailableReceiver } from './types/AvailableReceiver'
import { AvailableReceiversRequestArgs } from './types/AvailableReceiversRequestArgs'

export const receiverSelectAPI = deskOperatorApi.injectEndpoints({
  endpoints: (build) => ({
    getAvailableReceivers: build.query<
      EntityState<AvailableReceiver>,
      AvailableReceiversRequestArgs
    >({
      query: (args) => ({
        service:
          ActivityMessageServicePB.ActivityMessageService
            .NotificationAvailableMembers,
        body: (() => {
          const req =
            new ActivityMessagePB.NotificationAvailableMembersRequest()
          req.setQuery(args.query)

          return req
        })(),
      }),
      transformResponse: (
        res: ActivityMessagePB.NotificationAvailableMembersResponse.AsObject
      ) => {
        return availableReceiverEntityAdapter.setAll(
          availableReceiverEntityAdapter.getInitialState(),
          res.membersList.map(protoIntoAvailableReceiver)
        )
      },
    }),
  }),
})

export const { useGetAvailableReceiversQuery } = receiverSelectAPI

function protoIntoAvailableReceiver(
  member: ActivityMessagePB.NotificationAvailableMember.AsObject
): AvailableReceiver {
  return {
    isAvailable: member.isavailable,
    displayName: member.displayname,
    email: member.email,
    id: member.id,
  }
}
