import { Box, Button, Flex, Text, Tooltip } from '@fluentui/react-northstar'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DialogWithCloseIcon from '../../components/DialogWithCloseIcon'
import { customFieldMaxLength } from '../../consts'
import { customFieldSelector, fetchCustomFields } from './customFieldSlice'
import {
  fetchNewFaqNotificationUsers,
  notificationFaqSettingSelector,
  updateNewFaqNotificationUsers,
} from './notificationFaqSettingSlice'
import styles from './NotificationSettingDialog.module.css'
import {
  fetchNewTicketNotificationUsers,
  notificationSetttingSelector,
  notificationTicketCustomFieldSettingSelector,
  updateNewTicketNotificationUsers,
} from './notificationSettingSlice'
import { NotificationTicketCustomFieldList } from './NotificationTicketCustomFieldList'

interface Props {
  open: boolean
  renderAssigneeSelection: (
    values: string[],
    onChange: (value: string[]) => void
  ) => React.ReactElement
  renderAssigneeFaqSelection: (
    values: string[],
    onChange: (value: string[]) => void
  ) => React.ReactElement
  onCancel: () => void
  onConfirm: () => void
}
export interface NotificationTicketCustomField {
  ticketCustomFieldId: number
  ticketCustomFieldName: string
}

const NotificationDialog: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()
  const [
    selectedNewTicketNotificationUserIds,
    setSelectedNewTicketNotificationUserIds,
  ] = useState<string[]>([])
  const notificationSettings = useSelector(
    notificationSetttingSelector.selectAll
  )

  const [customFieldDropdownOptions, setCustomFieldDropdownOptions] = useState<
    number[]
  >([])
  const customFields = useSelector(customFieldSelector.selectAll)

  const notificationTicketCustomFieldSettings = useSelector(
    notificationTicketCustomFieldSettingSelector.selectAll
  )
  const [
    selectedNewFaqNotificationUserIds,
    setSelectedNewFaqNotificationUserIds,
  ] = useState<string[]>([])
  const notificationFaqSettings = useSelector(
    notificationFaqSettingSelector.selectAll
  )

  // 既存の通知設定をDropBoxに反映させます
  useEffect(() => {
    setSelectedNewTicketNotificationUserIds(
      notificationSettings.map((n) => n.userId)
    )
  }, [notificationSettings])

  useEffect(() => {
    setSelectedNewFaqNotificationUserIds(
      notificationFaqSettings.map((n) => n.userId)
    )
  }, [notificationFaqSettings])

  useEffect(() => {
    setCustomFieldDropdownOptions(
      notificationTicketCustomFieldSettings.map((n) => n.ticketCustomFieldId)
    )
  }, [notificationTicketCustomFieldSettings])

  const selectedDropdownItems = useMemo(() => {
    return customFields.map((n) => ({
      ticketCustomFieldId: n.id,
      ticketCustomFieldName: n.name,
    }))
  }, [customFields])

  // 更新ボタンを押したタイミング
  const handleUpdate = () => {
    dispatch(
      updateNewTicketNotificationUsers(
        selectedNewTicketNotificationUserIds,
        customFieldDropdownOptions.map((n) => n)
      )
    )
    dispatch(updateNewFaqNotificationUsers(selectedNewFaqNotificationUserIds))

    props.onConfirm()
  }

  // ダイアログを開くときにリクエスト
  useEffect(() => {
    if (!props.open) {
      return
    }
    dispatch(fetchNewTicketNotificationUsers()) // 最新のメンションユーザーの情報を取得します
    dispatch(fetchNewFaqNotificationUsers()) // 最新のメンションユーザーの情報を取得します
    dispatch(fetchCustomFields(0, customFieldMaxLength)) // 最新のカスタムフィールドの情報を取得します
  }, [dispatch, props.open])

  return (
    <DialogWithCloseIcon
      open={props.open}
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
      content={
        <Box>
          <h3>新規問い合わせの通知</h3>
          <Box>
            <Text className={styles.notificationSettingDialogHeader}>
              下記リストに追加すると、新しい問い合わせを受け付けた際に、通知を受けることができます。
            </Text>
          </Box>
          <Flex className={styles.notificationSettingDialogContent}>
            {props.renderAssigneeSelection(
              selectedNewTicketNotificationUserIds,
              setSelectedNewTicketNotificationUserIds
            )}
          </Flex>
          {false && (
            <>
              <Flex gap="gap.small" vAlign="center">
                <h3>通知に含める内容</h3>
                <Tooltip
                  trigger={
                    <Button
                      text
                      iconOnly
                      icon={<img src="/assets/questionIcon.svg" />}
                    />
                  }
                  position="after"
                  pointing
                  content={
                    <div>
                      <Text content="通知のメッセージに表示する" />
                      <br />
                      <Text content="問い合わせの内容を設定出来ます" />
                    </div>
                  }
                />
              </Flex>
              <Flex>
                <NotificationTicketCustomFieldList
                  customFieldDropdownOptions={customFieldDropdownOptions}
                  setCustomFieldDropdownOptions={setCustomFieldDropdownOptions}
                  selectedDropdownItems={selectedDropdownItems}
                />
              </Flex>
            </>
          )}
          <h3>AIによるFAQ作成提案の通知</h3>
          <Box>
            <Text className={styles.notificationSettingDialogHeader}>
              下記リストに追加すると、AIが新しいFAQを作成提案する際に、通知を受けることができます。
            </Text>
          </Box>
          <Flex className={styles.notificationSettingDialogContent}>
            {props.renderAssigneeFaqSelection(
              selectedNewFaqNotificationUserIds,
              setSelectedNewFaqNotificationUserIds
            )}
          </Flex>
        </Box>
      }
      footer={
        <Button primary onClick={handleUpdate}>
          更新
        </Button>
      }
      header="通知設定"
      className={styles.dialog}
    />
  )
}

export default NotificationDialog
