import { Pill } from '@fluentui/react-northstar'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { TicketCustomField } from '../../../proto/ticket_custom_field_pb'
import { deleteOneCustomFieldFilter } from '../ticketSlice'
import styles from './Pill.module.css'

type Props = {
  customFields: TicketCustomField.AsObject[]
}

const TicketCustomSelectFilterPill: React.FC<Props> = ({ customFields }) => {
  const ticketState = useSelector((state: RootState) => state.ticket)
  const dispatch = useDispatch()
  return (
    <>
      {Object.keys(ticketState.filters.customFieldValues.selectFilters).map(
        (fieldId) => {
          const field = customFields.find((f) => f.id === Number(fieldId))
          if (field == null) return null
          if (
            ticketState.filters.customFieldValues.selectFilters[fieldId]
              .length === 0
          )
            return null
          return (
            <Pill
              key={fieldId}
              actionable={
                ticketState.filters.customFieldValues.selectFilters[fieldId]
                  .length > 0
              }
              onDismiss={() =>
                dispatch(
                  deleteOneCustomFieldFilter({
                    customFieldId: field.id,
                    customFieldType: field.type,
                  })
                )
              }
              className={styles.pill}
            >
              {field.name}：
              {ticketState.filters.customFieldValues.selectFilters[
                fieldId
              ].join('・')}
            </Pill>
          )
        }
      )}
    </>
  )
}

export default TicketCustomSelectFilterPill
