import { grpc } from '@improbable-eng/grpc-web'
import * as Graph from '@microsoft/microsoft-graph-types'
import {
  EntityId,
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk } from '../../app/store'
import group_pb from '../../proto/group_pb'
import group_pb_service from '../../proto/group_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from '../auth/authSlice'

export const groupsAdapter = createEntityAdapter<Graph.Team>({
  selectId: (group) => group.id || '',
  sortComparer: (a, b) => {
    if (a.displayName == null) return -1
    if (b.displayName == null) return 1
    return a.displayName.localeCompare(b.displayName)
  },
})

interface GroupState {
  loading: boolean
  error: string | null
}

export const groupSlice = createSlice({
  name: 'group',
  initialState: groupsAdapter.getInitialState<GroupState>({
    loading: false,
    error: null,
  }),
  reducers: {
    fetchGroupsStart(state) {
      state.loading = true
      state.error = null
    },
    fetchGroupsOnMessage(
      state,
      action: PayloadAction<{
        message: group_pb.GetGroupsResponse.AsObject
      }>
    ) {
      const { message } = action.payload
      groupsAdapter.setAll(state, message.groupsList)
    },
    fetchGroupsOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = null
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
  },
})

export const { fetchGroupsStart, fetchGroupsOnMessage, fetchGroupsOnEnd } =
  groupSlice.actions
export default groupSlice.reducer

export const fetchGroups =
  (tabAddedGroupIds: EntityId[]): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    dispatch(fetchGroupsStart())

    const client = grpcClient<
      group_pb.GetGroupsRequest,
      group_pb.GetGroupsResponse
    >(group_pb_service.GroupAPI.GetGroups)

    const req = new group_pb.GetGroupsRequest()
    req.setGroupIdsList(tabAddedGroupIds.map((id) => String(id)))

    client.onMessage((message) => {
      dispatch(fetchGroupsOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(fetchGroupsOnEnd({ code, message }))
    })
    dispatch(fetchGroupsStart())
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.start(meta)
    client.send(req)
    client.finishSend()
  }
