import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../app/store'

const stateSelector = (state: RootState) =>
  state.activityMessagePlanPreviewReceiverList
export const receiversSelector = createSelector(
  stateSelector,
  (state) => state.receivers
)
export const isShowSelector = createSelector(
  stateSelector,
  (state) => state.isShow
)
