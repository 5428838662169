import { Flex, Loader } from '@fluentui/react-northstar'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { consoleErrorWithAirbrake } from '../../utils'
import styles from './Auth.module.css'
import { consent, getSsoToken } from './authSlice'

const AuthConsent: React.FC = () => {
  const dispatch = useDispatch()
  const authState = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    dispatch(getSsoToken())
  }, [dispatch])

  useEffect(() => {
    if (authState.decodedSsoToken == null) return

    const { tid, aud } = authState.decodedSsoToken
    if (tid == null || aud == null) {
      consoleErrorWithAirbrake('tid or aud is not found in sso token')
      return
    }
    dispatch(consent(aud, tid))
  }, [dispatch, authState.decodedSsoToken])

  return (
    <Flex
      column
      className={styles.container}
      styles={({ theme: { siteVariables } }) => ({
        backgroundColor: siteVariables.colorScheme.default.background2,
      })}
    >
      <Flex gap="gap.small">
        <h1>同意の確認ページに移動しています</h1>
        <Loader size="small" />
      </Flex>
      <div>自動的に画面が切り替わりますので、しばらくお待ち下さい。</div>
    </Flex>
  )
}

export default AuthConsent
