import React from 'react'

import { Tickets } from '../../consts'
import type { PulldownItem } from '../../features/assigneeSelection/types/PulldownItem'
import { Actions } from './presenter'

export const updateAssigneeUserIds = (payload: string[] | null) => ({
  type: 'updateAssignee' as const,
  payload,
})

type Props = {
  assigneeUserIds: string[]
  formDispatch: React.Dispatch<Actions>
  renderAssigneeSelection: (
    values: string[],
    onChange: (values: string[]) => void,
    extendedItems: PulldownItem[]
  ) => React.ReactElement
}
const AssigneeFilter: React.FC<Props> = (props) => {
  const values = props.assigneeUserIds ?? []
  const onChange = (values: string[]): void => {
    props.formDispatch(updateAssigneeUserIds(values))
  }
  const extendedItems: PulldownItem[] = [
    {
      value: '',
      header: Tickets.Users.UserNotIndicated,
    },
  ]

  return <>{props.renderAssigneeSelection(values, onChange, extendedItems)}</>
}

export default AssigneeFilter
