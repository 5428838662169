import { Button, Popup, TableAddIcon } from '@fluentui/react-northstar'
import * as React from 'react'

import styles from './style.module.css'

type Props = {
  onClickInsertColumnOnLeft: () => void
  onClickInsertColumnOnRight: () => void
  onClickInsertRowOnTop: () => void
  onClickInsertRowOnBottom: () => void
}
const TableAddPopup: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Popup
      open={isOpen}
      onOpenChange={(e, data) => setIsOpen(data?.open ?? false)}
      trigger={<Button text iconOnly icon={<TableAddIcon />} />}
      content={<Content {...props} close={() => setIsOpen(false)} />}
    />
  )
}

const Content: React.FC<Props & { close: () => void }> = (props) => {
  const withClose =
    <T extends () => void>(fn: T) =>
    () => {
      props.close()
      fn()
    }
  return (
    <ul className={styles.list}>
      <li onClick={withClose(props.onClickInsertColumnOnLeft)}>左に列を挿入</li>
      <li onClick={withClose(props.onClickInsertColumnOnRight)}>
        右に列を挿入
      </li>
      <li onClick={withClose(props.onClickInsertRowOnTop)}>上に行を挿入</li>
      <li onClick={withClose(props.onClickInsertRowOnBottom)}>下に行を挿入</li>
    </ul>
  )
}

export default TableAddPopup
