import * as React from 'react'

import styles from './styles.module.css'

type Props = {
  renderRecommendedFaqTicketChat: () => React.ReactElement
}

const RecommendedFaqTicketPresenter: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      {props.renderRecommendedFaqTicketChat()}
    </div>
  )
}

export default React.memo(RecommendedFaqTicketPresenter)
