import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { createBrowserHistory } from 'history'
const browserHistory = createBrowserHistory({ basename: '' })
export const reactPlugin = new ReactPlugin()
const clickPluginInstance = new ClickAnalyticsPlugin()
// Click Analytics configuration
// https://docs.microsoft.com/ja-jp/azure/azure-monitor/app/javascript-click-analytics-plugin
const clickPluginConfig = {
  autoCapture: true,
  dataTags: {
    useDefaultContentNameOrId: true,
  },
}
export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY,
    autoTrackPageVisitTime: true,
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
  },
})
