import { Flex, Input, Text } from '@fluentui/react-northstar'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import ticket_custom_field from '../../../proto/ticket_custom_field_pb'
import { isSafeNumber } from '../../../utils'
import style from '../TabConfig.module.css'
import {
  clearTabConfigFilterError,
  isCustomNumberValueFilter,
  setCustomNumberFilterValue,
  setTabConfigFilterError,
} from '../tabConfigSlice'

type Props = {
  customField: ticket_custom_field.TicketCustomField.AsObject
  tabConfigFilterIndex: number
}
const TicketCustomFieldValueNumberFilter: React.FC<Props> = ({
  customField,
  tabConfigFilterIndex,
}) => {
  const tabConfigState = useSelector((state: RootState) => state.tabConfig)
  const filter = tabConfigState.filters[tabConfigFilterIndex].filter

  let initialFilterMinValue = ''
  let initialFilterMaxValue = ''
  if (isCustomNumberValueFilter(filter)) {
    if ('minValue' in filter) {
      initialFilterMinValue = String(filter.minValue)
    }
    if ('maxValue' in filter) {
      initialFilterMaxValue = String(filter.maxValue)
    }
  }
  const [minInputNumber, setMinInputNumber] = useState(initialFilterMinValue)
  const [maxInputNumber, setMaxInputNumber] = useState(initialFilterMaxValue)
  const [isError, setIsError] = useState(false)
  const [isInvalidMinNumber, setIsInvalidMinNumber] = useState(false)
  const [isInvalidMaxNumber, setIsInvalidMaxNumber] = useState(false)
  const isMountRef = useRef(true)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isError || isInvalidMinNumber || isInvalidMaxNumber) {
      dispatch(
        setTabConfigFilterError({
          identifierName:
            tabConfigState.filters[tabConfigFilterIndex].identifierName,
        })
      )
    } else {
      dispatch(
        clearTabConfigFilterError({
          identifierName:
            tabConfigState.filters[tabConfigFilterIndex].identifierName,
        })
      )
    }
  }, [
    dispatch,
    isError,
    isInvalidMinNumber,
    isInvalidMaxNumber,
    tabConfigFilterIndex,
    tabConfigState.filters,
  ])

  useEffect(() => {
    if (isMountRef.current) {
      isMountRef.current = false
      return
    }

    if (
      minInputNumber !== '' &&
      maxInputNumber !== '' &&
      Number(minInputNumber) > Number(maxInputNumber)
    ) {
      setIsError(true)
      return
    } else {
      setIsError(false)
    }

    if (minInputNumber !== '' && !isSafeNumber(Number(minInputNumber))) {
      setIsInvalidMinNumber(true)
    } else {
      setIsInvalidMinNumber(false)
    }

    if (maxInputNumber !== '' && !isSafeNumber(Number(maxInputNumber))) {
      setIsInvalidMaxNumber(true)
    } else {
      setIsInvalidMaxNumber(false)
    }

    const timeOutId = setTimeout(() => {
      const filterArg: {
        customFieldId: number
        tabConfigFilterIndex: number
        minValue?: number
        maxValue?: number
      } = { customFieldId: customField.id, tabConfigFilterIndex }
      // 入力値が空の時はフィルタからkey,valueを削除
      if (minInputNumber === '' && maxInputNumber === '') {
        dispatch(
          setCustomNumberFilterValue({
            customFieldId: customField.id,
            tabConfigFilterIndex,
          })
        )
        return
      }
      if (minInputNumber !== '') filterArg.minValue = Number(minInputNumber)
      if (maxInputNumber !== '') filterArg.maxValue = Number(maxInputNumber)
      dispatch(setCustomNumberFilterValue(filterArg))
    }, 300)
    return () => clearTimeout(timeOutId)
  }, [
    dispatch,
    customField.id,
    minInputNumber,
    maxInputNumber,
    tabConfigFilterIndex,
  ])

  return (
    <Flex column gap="gap.small" space="around" className={style.filter}>
      <Flex vAlign="center" gap="gap.small">
        <Text content="最小値" className={style.numberFilterSubject} />
        <Input
          fluid
          placeholder="入力する"
          type="number"
          error={isError || isInvalidMinNumber}
          value={String(minInputNumber)}
          onChange={(_, e) => {
            if (e?.value != null) setMinInputNumber(e.value)
          }}
          onBlur={() => {
            if (minInputNumber !== '')
              setMinInputNumber(String(Number(minInputNumber)))
          }}
        />
      </Flex>
      <Flex vAlign="center" gap="gap.small">
        <Text content="最大値" className={style.numberFilterSubject} />
        <Input
          fluid
          placeholder="入力する"
          type="number"
          error={isError || isInvalidMaxNumber}
          value={String(maxInputNumber)}
          onChange={(_, e) => {
            if (e?.value != null) setMaxInputNumber(e.value)
          }}
          onBlur={() => {
            if (maxInputNumber !== '')
              setMaxInputNumber(String(Number(maxInputNumber)))
          }}
        />
      </Flex>
      {isError && (
        <Text
          content="最大値は最小値よりも大きくしてください"
          styles={({ theme: { siteVariables } }) => ({
            color: siteVariables.colorScheme.red.foreground,
            fontSize: '12px',
          })}
        />
      )}
      {(isInvalidMinNumber || isInvalidMaxNumber) && (
        <Text
          content="最大桁数を超えています"
          styles={({ theme: { siteVariables } }) => ({
            color: siteVariables.colorScheme.red.foreground,
            fontSize: '12px',
          })}
        />
      )}
    </Flex>
  )
}

export default TicketCustomFieldValueNumberFilter
