import {
  BoldIcon,
  BulletsIcon,
  Button,
  CodeSnippetIcon,
  Dropdown,
  DropdownItemProps,
  FontColorIcon,
  HighlightIcon,
  HorizontalRuleIcon,
  IndentIcon,
  ItalicIcon,
  NumberListIcon,
  OutdentIcon,
  QuoteIcon,
  RedoIcon,
  RemoveFormatIcon,
  StrikeIcon,
  TrashCanIcon,
  UnderlineIcon,
  UndoIcon,
} from '@fluentui/react-northstar'
import { TOGGLE_LINK_COMMAND } from '@lexical/link'
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'
import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode'
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
} from '@lexical/rich-text'
import { $setBlocksType, $wrapNodes } from '@lexical/selection'
import { $patchStyleText, $selectAll } from '@lexical/selection'
import {
  $deleteTableColumn,
  $getElementGridForTableNode,
  $getTableCellNodeFromLexicalNode,
  $getTableColumnIndexFromTableCellNode,
  $getTableNodeFromLexicalNodeOrThrow,
  $getTableRowIndexFromTableCellNode,
  $insertTableColumn,
  $insertTableRow,
  $isTableCellNode,
  $removeTableRowAtIndex,
} from '@lexical/table'
import {
  $getNearestBlockElementAncestorOrThrow,
  mergeRegister,
} from '@lexical/utils'
import {
  $createParagraphNode,
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  $isTextNode,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  ElementNode,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  REDO_COMMAND,
  TextNode,
  UNDO_COMMAND,
} from 'lexical'
import * as React from 'react'

import RequesterChatCodeblockEdit from '../../../../components/RequesterChatCodeblockEdit'
import { INSERT_CODEBLOCK_COMMAND } from '../CodeblockPlugin'
import ColorPicker, { ColorPickerEvent } from './components/ColorPicker'
import Divider from './components/Divider'
import FontSizeSelect, { FontSize } from './components/FontSizeSelect'
import LinkInsertPopup from './components/LinkInsertPopup'
import TableAddPopup from './components/TableAddPopup'
import TableRemovePopup from './components/TableRemovePopup'
import TableSizeSelector from './components/TableSizeSelector'
import styles from './style.module.css'

const highlightColors = [
  'rgb(223, 146, 153)',
  'rgb(244, 165, 147)',
  'rgb(253, 212, 114)',
  'rgb(229, 241, 143)',
  'rgb(130, 205, 168)',
  'rgb(157, 217, 219)',
  'rgb(199, 212, 232)',
  'rgb(235, 211, 225)',
]

const fontColors = [
  'rgb(182, 66, 76)',
  'rgb(205, 89, 55)',
  'rgb(253, 192, 48)',
  'rgb(189, 203, 76)',
  'rgb(43, 155, 98)',
  'rgb(55, 121, 123)',
  'rgb(30, 83, 163)',
  'rgb(165, 57, 122)',
]

type HeadingBlockType = 'h1' | 'h2' | 'h3' | 'p'
const headingBlockTypeToString = (type: HeadingBlockType): string => {
  switch (type) {
    case 'h1':
      return '見出し1'
    case 'h2':
      return '見出し2'
    case 'h3':
      return '見出し3'
    case 'p':
      return '段落'
  }
}

const setBlockTypeFromLexicalNode =
  (setBlockType: (type: HeadingBlockType) => void) =>
  (node: TextNode | ElementNode) => {
    if ($isHeadingNode(node)) {
      const tag = node.getTag()
      setBlockType(tag as HeadingBlockType)
      return
    }

    if ($isParagraphNode(node)) {
      setBlockType('p')
      return
    }
  }

type Props = {
  onClickClose: () => void
  className?: string
}
const ToolbarPlugin: React.FC<Props> = (props) => {
  const [editor] = useLexicalComposerContext()
  const [blockType, setBlockType] = React.useState<HeadingBlockType>('p')
  const [isTableFocus, setIsTableFocus] = React.useState(false)
  const [canRedo, setCanRedo] = React.useState(false)
  const [canUndo, setCanUndo] = React.useState(false)

  React.useEffect(() => {
    return mergeRegister(
      // エディタ上で更新があった時にローカルステートとエディタの状態を同期する処理
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          const selection = $getSelection()
          if (!$isRangeSelection(selection)) return

          const setBlockTypeIntoState =
            setBlockTypeFromLexicalNode(setBlockType)

          const anchorNode = selection.anchor.getNode()
          if (anchorNode.getType() === 'text') {
            setBlockTypeIntoState(anchorNode.getParentOrThrow())
            return
          }

          setBlockTypeIntoState(anchorNode)
        })
      }),
      editor.registerUpdateListener(() => {
        editor.update(() => {
          const selection = $getSelection()
          if (!$isRangeSelection(selection)) return
          const anchorNode = selection.anchor.getNode()
          setIsTableFocus(
            $isTableCellNode($getTableCellNodeFromLexicalNode(anchorNode))
          )
        })
      }),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL
      )
    )
  }, [editor])

  const clickHeadingBlock = React.useCallback(
    (type: HeadingBlockType) => () => {
      setBlockType(type)
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          switch (type) {
            case 'p':
              return $wrapNodes(selection, () => $createParagraphNode())
            default:
              return $wrapNodes(selection, () => $createHeadingNode(type))
          }
        }
      })
    },
    [editor, setBlockType]
  )

  const dropdownItems = React.useMemo(() => {
    return (
      ['h1', 'h2', 'h3', 'p'] as HeadingBlockType[]
    ).map<DropdownItemProps>((type) => ({
      header: headingBlockTypeToString(type),
      onClick: clickHeadingBlock(type),
    }))
  }, [clickHeadingBlock])

  const applyBgColor = React.useCallback(
    (event: ColorPickerEvent) => {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          switch (event.type) {
            case 'color':
              $patchStyleText(selection, {
                'background-color': event.color,
              })
              return
            case 'reset':
              $patchStyleText(selection, {
                'background-color': 'unset',
              })
              return
          }
        }
      })
    },
    [editor]
  )

  const applyFontColor = React.useCallback(
    (e: ColorPickerEvent) => {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          switch (e.type) {
            case 'color':
              $patchStyleText(selection, {
                color: e.color,
              })
              return
            case 'reset':
              $patchStyleText(selection, {
                color: 'unset',
              })
              return
          }
        }
      })
    },
    [editor]
  )

  const applyFontSize = React.useCallback(
    (size: FontSize) => {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          switch (size) {
            case 'small':
              $patchStyleText(selection, {
                'font-size': '10px',
              })
              return
            case 'medium':
              $patchStyleText(selection, {
                'font-size': '14px',
              })
              return
            case 'large':
              $patchStyleText(selection, {
                'font-size': '24px',
              })
              return
          }
        }
      })
    },
    [editor]
  )

  const clearFormatting = React.useCallback(() => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        $selectAll(selection)
        selection.getNodes().forEach((node) => {
          if ($isTextNode(node)) {
            node.setFormat(0)
            node.setStyle('')
            $getNearestBlockElementAncestorOrThrow(node).setFormat('')
          }
          if ($isDecoratorBlockNode(node)) {
            node.setFormat('')
          }
        })
      }
    })
  }, [editor])

  const formatQuote = React.useCallback(() => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createQuoteNode())
      }
    })
  }, [editor])

  const insertLink = React.useCallback(
    (value: string) => {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, value)
        }
      })
    },
    [editor]
  )

  const removeTable = React.useCallback(() => {
    editor.update(() => {
      const selection = $getSelection()
      if (!$isRangeSelection(selection)) return
      const anchorNode = selection.anchor.getNode()
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(anchorNode)
      tableNode.remove()
    })
  }, [editor])

  const removeRow = React.useCallback(() => {
    editor.update(() => {
      const selection = $getSelection()
      if (!$isRangeSelection(selection)) return
      const anchorNode = selection.anchor.getNode()
      const tableCellNode = $getTableCellNodeFromLexicalNode(anchorNode)
      if (!$isTableCellNode(tableCellNode)) return
      const rowIndex = $getTableRowIndexFromTableCellNode(tableCellNode)
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)

      $removeTableRowAtIndex(tableNode, rowIndex)
    })
  }, [editor])

  const removeColumn = React.useCallback(() => {
    editor.update(() => {
      const selection = $getSelection()
      if (!$isRangeSelection(selection)) return
      const anchorNode = selection.anchor.getNode()
      const tableCellNode = $getTableCellNodeFromLexicalNode(anchorNode)
      if (!$isTableCellNode(tableCellNode)) return
      const columnIndex = $getTableColumnIndexFromTableCellNode(tableCellNode)
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)

      $deleteTableColumn(tableNode, columnIndex)
    })
  }, [editor])

  const insertColumnTo = React.useCallback(
    (direction: 'left' | 'right') => () => {
      editor.update(() => {
        const selection = $getSelection()
        if (!$isRangeSelection(selection)) return
        const anchorNode = selection.anchor.getNode()
        const tableCellNode = $getTableCellNodeFromLexicalNode(anchorNode)
        if (!$isTableCellNode(tableCellNode)) return
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)
        const columnIndex = $getTableColumnIndexFromTableCellNode(tableCellNode)
        const grid = $getElementGridForTableNode(editor, tableNode)
        $insertTableColumn(
          tableNode,
          columnIndex,
          direction === 'right',
          1,
          grid
        )
      })
    },
    [editor]
  )

  const insertRowTo = React.useCallback(
    (direction: 'top' | 'bottom') => () => {
      editor.update(() => {
        const selection = $getSelection()
        if (!$isRangeSelection(selection)) return
        const anchorNode = selection.anchor.getNode()
        const tableCellNode = $getTableCellNodeFromLexicalNode(anchorNode)
        if (!$isTableCellNode(tableCellNode)) return
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)
        const rowIndex = $getTableRowIndexFromTableCellNode(tableCellNode)
        const grid = $getElementGridForTableNode(editor, tableNode)
        $insertTableRow(tableNode, rowIndex, direction === 'bottom', 1, grid)
      })
    },
    [editor]
  )

  return (
    <div className={`${styles.container} ${props.className}`}>
      <div className={styles.left}>
        <Button
          icon={<BoldIcon />}
          text
          iconOnly
          title="Bold"
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
          }}
        />
        <Button
          icon={<ItalicIcon />}
          text
          iconOnly
          title="Italic"
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
        />
        <Button
          icon={<UnderlineIcon />}
          text
          iconOnly
          title="Underline"
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
          }}
        />
        <Button
          icon={<StrikeIcon />}
          text
          iconOnly
          title="Strike"
          onClick={() =>
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
          }
        />
        <Divider />
        <ColorPicker
          colors={highlightColors}
          resetButtonText="ハイライトなし"
          icon={<HighlightIcon />}
          onChange={applyBgColor}
        />
        <ColorPicker
          colors={fontColors}
          resetButtonText="自動"
          icon={<FontColorIcon />}
          onChange={applyFontColor}
        />
        <FontSizeSelect onChange={applyFontSize} />
        <Dropdown
          inline
          items={dropdownItems}
          value={headingBlockTypeToString(blockType)}
          className={styles.dropDown}
        />
        <Button
          icon={<RemoveFormatIcon />}
          text
          iconOnly
          title="ClearFormat"
          onClick={clearFormatting}
        />
        <Divider />
        <Button
          icon={<IndentIcon />}
          text
          iconOnly
          title="Indent"
          onClick={() =>
            editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined)
          }
        />
        <Button
          icon={<OutdentIcon />}
          text
          iconOnly
          title="Outdent"
          onClick={() =>
            editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)
          }
        />
        <Button
          icon={<BulletsIcon />}
          text
          iconOnly
          title="BulletList"
          onClick={() =>
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
          }
        />
        <Button
          icon={<NumberListIcon />}
          text
          iconOnly
          title="OrderedList"
          onClick={() =>
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
          }
        />
        <Divider />
        <Button
          icon={<QuoteIcon />}
          text
          iconOnly
          title="Quote"
          onClick={formatQuote}
        />
        <LinkInsertPopup onClick={insertLink} />
        {/* TODO: オペレータ側の表示実装が完了していない
        <Button
          icon={<RedbangIcon />}
          text
          iconOnly
          title="Important"
          onClick={() =>
            editor.dispatchCommand(TOGGLE_IMPORTANT_COMMAND, undefined)
          }
        /> */}
        <Button
          icon={<HorizontalRuleIcon />}
          text
          iconOnly
          title="HorizontalRule"
          onClick={() =>
            editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined)
          }
        />
        <RequesterChatCodeblockEdit
          trigger={<Button text iconOnly icon={<CodeSnippetIcon />} />}
          onSubmit={(title, value, language) => {
            editor.dispatchCommand(INSERT_CODEBLOCK_COMMAND, {
              title,
              value,
              language,
            })
          }}
        />
        <TableSizeSelector />
        {isTableFocus && (
          <>
            <Divider />
            <TableAddPopup
              onClickInsertRowOnTop={insertRowTo('top')}
              onClickInsertRowOnBottom={insertRowTo('bottom')}
              onClickInsertColumnOnLeft={insertColumnTo('left')}
              onClickInsertColumnOnRight={insertColumnTo('right')}
            />
            <TableRemovePopup
              onClickRemoveTable={removeTable}
              onClickRemoveRow={removeRow}
              onClickRemoveColumn={removeColumn}
            />
          </>
        )}
        <Divider />
        <Button
          icon={<UndoIcon />}
          text
          iconOnly
          title="Undo"
          onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
          disabled={!canUndo}
        />
        <Button
          icon={<RedoIcon />}
          text
          iconOnly
          title="Redo"
          onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
          disabled={!canRedo}
        />
      </div>
      <div className={styles.right}>
        <Button
          icon={<TrashCanIcon />}
          text
          iconOnly
          title="Clear"
          onClick={() => {
            props.onClickClose()
            editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
          }}
        />
      </div>
    </div>
  )
}

export default ToolbarPlugin
