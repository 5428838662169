import * as React from 'react'
import { debounce } from 'throttle-debounce'

const useFullHeight = (ref: React.RefObject<HTMLElement>): number => {
  const [height, setHeight] = React.useState(0)

  React.useEffect(() => {
    if (!ref.current) return

    const resize = debounce(100, () => {
      if (!ref.current) return
      setHeight(window.innerHeight - ref.current.getBoundingClientRect().y)
    })

    window.addEventListener('resize', resize)

    resize()

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [ref, setHeight])

  return height
}

export default useFullHeight
