import { Ref, Text, TextArea } from '@fluentui/react-northstar'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { TicketCustomField } from '../../../proto/ticket_custom_field_pb'
import { updateTicketCustomFieldValue } from '../customFieldValueSlice'
import styles from '../TicketDetail.module.css'
import { useErrorHandler } from './useErrorHandler'

type Props = {
  ticketId: number
  field: TicketCustomField.AsObject
  fieldValue?: string
}

const delayTime = 2000

const TicketPropertyCustomTextAreaField: React.FC<Props> = ({
  ticketId,
  field,
  fieldValue,
}) => {
  const dispatch = useDispatch()
  const [inputTextArea, setInputTextArea] = useState(fieldValue)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const customFieldValuesState = useSelector(
    (state: RootState) => state.customFieldValue
  )
  const customFieldValueEntity = customFieldValuesState.entities[field.id]
  const hasValue = customFieldValueEntity != null
  const isMountRef = useRef(true)

  useEffect(() => {
    if (!field.editable) return
    const handleUpdate = () => {
      if (textAreaRef.current != null) {
        dispatch(
          updateTicketCustomFieldValue(ticketId, field.id, field.type, [
            { textValue: textAreaRef.current.value },
          ])
        )
      }
    }
    window.addEventListener('beforeunload', handleUpdate)

    return () => {
      window.removeEventListener('beforeunload', handleUpdate)
    }
  }, [dispatch, textAreaRef, ticketId, field.id, field.type, field.editable])

  useEffect(() => {
    if (isMountRef.current) {
      isMountRef.current = false
      return
    }

    const handleUpdate = () => {
      dispatch(
        updateTicketCustomFieldValue(ticketId, field.id, field.type, [
          { textValue: inputTextArea },
        ])
      )
    }

    // popstate(ブラウザバック)はrefがnullになり値が取得できないのでこちらでaddEventListenerしている
    window.addEventListener('popstate', handleUpdate)

    const timeOutId = setTimeout(() => {
      handleUpdate()
    }, delayTime)
    return () => {
      window.removeEventListener('popstate', handleUpdate)
      clearTimeout(timeOutId)
    }
  }, [dispatch, inputTextArea, ticketId, field.id, field.type])

  useErrorHandler(customFieldValueEntity, field, dispatch, hasValue)

  return (
    <>
      <Text weight="bold" content={field.name} />
      {field.editable ? (
        <Ref innerRef={textAreaRef}>
          <TextArea
            fluid
            error={
              hasValue && customFieldValuesState.entities[field.id]?.isError
            }
            resize="vertical"
            className={styles.ticketCustomFieldTextArea}
            value={inputTextArea}
            onChange={(_, e) => {
              setInputTextArea(String(e?.value))
            }}
            onBlur={() => {
              dispatch(
                updateTicketCustomFieldValue(ticketId, field.id, field.type, [
                  { textValue: inputTextArea },
                ])
              )
            }}
            placeholder={`${field.name}を入力`}
          />
        </Ref>
      ) : (
        <Text content={inputTextArea ? inputTextArea : '--'} />
      )}
    </>
  )
}

export default TicketPropertyCustomTextAreaField
