import { Loader } from '@fluentui/react-northstar'
import format from 'date-fns/format'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import { ActivityMessagePlanStatusComponentType } from '../../components/ActivityMessagePlanStatus'
import { ActivityMessagePlansLimit } from '../../consts'
import { assertNever } from '../../utils'
import * as PaginatorSelectors from '../ActivityMessagePlansPaginator/selectors'
import * as ActivityMessagePlanAPI from './api'
import ActivityMessagePlanListAvatar from './features/ActivityMessagePlanListAvatar'
import ActivityMessagePlanListPresenter, {
  ActivityMessagePlanTableItem,
} from './presenter'
import * as Slice from './slice'
import { ActivityMessagePlanStatus } from './types/ActivityMessagePlanEntity'

const ActivityMessagePlanList: React.FC = () => {
  const page = ReactRedux.useSelector(PaginatorSelectors.pageSelector)
  const { data, isLoading } =
    ActivityMessagePlanAPI.useListActivityMessagePlansQuery({
      limit: ActivityMessagePlansLimit,
      page,
    })

  const tableData = React.useMemo<ActivityMessagePlanTableItem[]>(() => {
    if (!data) {
      return []
    }
    return Slice.activityMessagePlanEntityAdapter
      .getSelectors()
      .selectAll(data.entity)
      .map<ActivityMessagePlanTableItem>((plan) => ({
        id: plan.id.toString(),
        creatorId: plan.creatorID,
        body: plan.body,
        sentAt: plan.sentAt ? format(plan.sentAt, 'yyyy/MM/dd HH:mm') : null,
        status: entityIntoTableStatus(plan.status),
      }))
  }, [data])

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader />
      </div>
    )
  }
  return (
    <ActivityMessagePlanListPresenter
      data={tableData}
      renderAvatar={(id) => <ActivityMessagePlanListAvatar id={id} />}
    />
  )
}

export default React.memo(ActivityMessagePlanList)

function entityIntoTableStatus(
  status: ActivityMessagePlanStatus
): ActivityMessagePlanStatusComponentType {
  switch (status) {
    case ActivityMessagePlanStatus.Draft:
      return 'draft'
    case ActivityMessagePlanStatus.Scheduled:
      return 'scheduled'
    case ActivityMessagePlanStatus.Processing:
      return 'pending'
    case ActivityMessagePlanStatus.Done:
      return 'sent'
    default:
      assertNever(status)
      return 'draft'
  }
}
