import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import ActivityMessagePlanStatus, {
  ActivityMessagePlanStatusComponentType,
} from '../../components/ActivityMessagePlanStatus'
import styles from './styles.module.css'

export type ActivityMessagePlanTableItem = {
  id: string
  body: string
  status: ActivityMessagePlanStatusComponentType
  sentAt: string | null
  creatorId: string
}

const columnHelper = createColumnHelper<ActivityMessagePlanTableItem>()

const creatorWidth = 180
const sentAtWidth = 156
const statusWidth = 124
const bodyWidth = window.innerWidth - (creatorWidth + sentAtWidth + statusWidth)

type Props = {
  data: ActivityMessagePlanTableItem[]
  renderAvatar: (id: string) => React.ReactNode
}
const ActivityMessagePlanListPresenter: React.FC<Props> = (props) => {
  const history = useHistory()

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('body', {
        size: bodyWidth,
        header: '内容',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('status', {
        size: statusWidth,
        header: 'ステータス',
        cell: (info) => <ActivityMessagePlanStatus status={info.getValue()} />,
      }),
      columnHelper.accessor('sentAt', {
        size: sentAtWidth,
        header: '配信日',
        cell: (info) => info.getValue() ?? '-',
      }),
      columnHelper.accessor('creatorId', {
        size: creatorWidth,
        header: '配信者',
        cell: (info) => props.renderAvatar(info.getValue()),
      }),
    ],
    [props]
  )

  const table = useReactTable({
    data: props.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} style={{ width: `${header.getSize()}px` }}>
                  <span className={styles.tableHeadText}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              tabIndex={0}
              onClick={() =>
                history.push(`/activity-messages/${row.original.id}`)
              }
              key={row.id}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default React.memo(ActivityMessagePlanListPresenter)
