import { Input, Text } from '@fluentui/react-northstar'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { TicketCustomField } from '../../../proto/ticket_custom_field_pb'
import { addAlert } from '../../alert/alertsSlice'
import { updateTicketCustomFieldValue } from '../customFieldValueSlice'
import { useErrorHandler } from './useErrorHandler'

type Props = {
  ticketId: number
  field: TicketCustomField.AsObject
  fieldValue?: string
}

const delayTime = 500
const maxTextLength = 255

const TicketPropertyCustomTextField: React.FC<Props> = ({
  ticketId,
  field,
  fieldValue,
}) => {
  const dispatch = useDispatch()
  const customFieldValuesState = useSelector(
    (state: RootState) => state.customFieldValue
  )
  const customFieldValueEntity = customFieldValuesState.entities[field.id]
  const hasValue = customFieldValueEntity != null
  const [inputText, setInputText] = useState(fieldValue)
  const isMountRef = useRef(true)

  useEffect(() => {
    if (isMountRef.current) {
      isMountRef.current = false
      return
    }
    if (!field.editable) return
    const timeOutId = setTimeout(() => {
      dispatch(
        updateTicketCustomFieldValue(ticketId, field.id, field.type, [
          { stringValue: inputText },
        ])
      )
    }, delayTime)
    return () => clearTimeout(timeOutId)
  }, [dispatch, inputText, ticketId, field.id, field.type, field.editable])

  useErrorHandler(customFieldValueEntity, field, dispatch, hasValue)

  return (
    <>
      <Text weight="bold" content={field.name} />
      {field.editable ? (
        <Input
          fluid
          maxLength={maxTextLength}
          value={inputText}
          onChange={(_, e) => {
            const text = String(e?.value)
            setInputText(text)
            if (text && text.length >= maxTextLength) {
              dispatch(
                // UX向上のためのアラート
                addAlert({
                  id: Math.random() * 1000,
                  title: 'テキストフィールドの最大文字数は255文字です',
                  type: 'custom_field_value_error',
                })
              )
            }
          }}
          error={hasValue && customFieldValuesState.entities[field.id]?.isError}
          placeholder={`${field.name}を入力`}
        />
      ) : (
        <Text content={inputText ? inputText : '--'} />
      )}
    </>
  )
}

export default TicketPropertyCustomTextField
