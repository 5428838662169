import { grpc } from '@improbable-eng/grpc-web'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { History } from 'history'

import { AppThunk } from '../../app/store'
import {
  CustomFieldType,
  DefaultFields,
  FaqStatus,
  Sort,
  TicketStatus,
  Tickets,
} from '../../consts'
import { Status } from '../../proto/status_pb'
import tab_config_pb from '../../proto/tab_config_pb'
import tab_config_pb_service from '../../proto/tab_config_pb_service'
import { TicketCustomField } from '../../proto/ticket_custom_field_pb'
import {
  TicketCustomCheckboxFieldFilter,
  TicketCustomDateFieldFilter,
  TicketCustomFieldOneOfFilter,
  TicketCustomNumberFieldFilter,
  TicketCustomSelectFieldFilter,
  TicketCustomTextAreaFieldFilter,
  TicketCustomTextFieldFilter,
} from '../../proto/ticket_custom_field_value_pb'
import ticket_pb, {
  TicketErrorCode,
  TicketErrorDetail,
} from '../../proto/ticket_pb'
import ticket_pb_service from '../../proto/ticket_pb_service'
import {
  consoleErrorWithAirbrake,
  createFieldMaskPathsList,
  stringToUint8Array,
} from '../../utils'
import { resetTokens } from '../auth/authSlice'
import { SortConfig } from '../tabConfig/tabConfigSlice'
import { sortableColumnsValues } from './TicketList'

export declare type Ticket = ticket_pb.Ticket.AsObject & {
  requestResponses: RequestResponse[]
  showAllNoteRequestResponse: boolean
}

const parseRequestResponses = (requestResponses: string): RequestResponse[] => {
  return JSON.parse(requestResponses)
}

const convertIntoTicket = (ticket: ticket_pb.Ticket.AsObject): Ticket => {
  return {
    ...ticket,
    showAllNoteRequestResponse: false,
    requestResponses:
      ticket.noteRequestResponses.length > 0
        ? parseRequestResponses(ticket.noteRequestResponses)
        : [],
  }
}

const extractErrorDetails = (
  trailers: grpc.Metadata
): TicketErrorDetail.AsObject[] => {
  let details: TicketErrorDetail.AsObject[] = []
  const detailBinary = trailers.headersMap['grpc-status-details-bin']
  if (detailBinary != null && detailBinary.length !== 0) {
    const decodeMsg = atob(detailBinary[0])
    const status = Status.deserializeBinary(stringToUint8Array(decodeMsg))
    details = status.getDetailsList().map((detail) => {
      const uint8Array = detail.getValue_asU8()
      return TicketErrorDetail.deserializeBinary(uint8Array).toObject()
    })
  }
  return details
}

const dateTypeDefaultISOString = `1970-01-01T00:00:00.000Z`

export interface RequestResponse {
  id: string
  conversationId: string
  messages: Message[]
}

export interface Message {
  text: string
  userType: string
  time: number
  content: Content
}

export interface Content {
  type: string
}

export interface CustomFieldValueFilter {
  // multi select or select filters
  selectFilters: {
    [fieldId: string]: Array<string>
  }
  numberFilters: {
    [fieldId: string]: {
      minValue?: number
      maxValue?: number
    }
  }
  dateFilters: {
    [fieldId: string]: {
      startDate?: string
      endDate?: string
    }
  }
  textFilters: {
    [fieldId: string]: string
  }
  textAreaFilters: {
    [fieldId: string]: string
  }
  checkBoxFilters: {
    [fieldId: string]: Array<number>
  }
}

// カスタムフィールドでソートするタイプは「数値」と「日付」のみの仕様
export interface CustomFieldValueSort {
  number: {
    [fieldId: string]: string
  }
  date: {
    [fieldId: string]: string
  }
}

export const ticketsAdapter = createEntityAdapter<Ticket>()

interface sorts {
  keyId: string
  status: string
  createdAt: string
  receivedAt: string
  sentAt: string
  customFieldValue: CustomFieldValueSort
}

class TicketStateError {
  constructor(private error: string | null) {}

  public isEmpty(): boolean {
    return this.error === null
  }

  public isNotFound(): boolean {
    return this.error === 'not found'
  }

  public isNetworkErr(): boolean {
    return this.error === 'network_error'
  }
}

const initialFilter = {
  requesterUserIds: [],
  keyId: 0,
  subject: '',
  statuses: [],
  startHour: [],
  startMinutes: [],
  endHour: [],
  endMinutes: [],
  search: '',
  customFieldValues: {
    selectFilters: {},
    numberFilters: {},
    dateFilters: {},
    textFilters: {},
    textAreaFilters: {},
    checkBoxFilters: {},
  },
  faqStatuses: [],
}

export interface TicketState {
  totalCount: number
  pageCount: number
  pageIndex: number
  pageSize: number
  loading: boolean
  hasFetchedDefaultTabConfig: boolean
  error: TicketStateError
  filters: {
    assignedUserIds?: string[]
    requesterUserIds: string[]
    keyId: number
    subject: string
    statuses: TicketStatus[]
    startDate?: Date
    startHour: string[]
    startMinutes: string[]
    endDate?: Date
    endHour: string[]
    endMinutes: string[]
    search: string
    customFieldValues: CustomFieldValueFilter
    faqStatuses: FaqStatus[]
  }
  sorts: sorts
  isRequiredAssignedUserConfirm: boolean
  sideTabIndex: number
}

export const ticketSlice = createSlice({
  name: 'tickets',
  initialState: ticketsAdapter.getInitialState<TicketState>({
    totalCount: 0,
    pageCount: 0,
    pageIndex: 0,
    pageSize: 50,
    loading: false,
    hasFetchedDefaultTabConfig: false,
    error: new TicketStateError(null),
    filters: initialFilter,
    sorts: {
      keyId: '',
      status: '',
      createdAt: '',
      receivedAt: Sort.Desc.value,
      sentAt: '',
      customFieldValue: {
        number: {},
        date: {},
      },
    },
    isRequiredAssignedUserConfirm: false,
    sideTabIndex: 0,
  }),
  reducers: {
    setInitialFilter(state, action: PayloadAction<{ meId: string }>) {
      state.filters = {
        ...initialFilter,
        statuses: [TicketStatus.new, TicketStatus.working],
        assignedUserIds: ['', action.payload.meId],
        search: state.filters?.search ?? '',
        customFieldValues: {
          selectFilters: {},
          numberFilters: {},
          dateFilters: {},
          textFilters: {},
          textAreaFilters: {},
          checkBoxFilters: {},
        },
      }

      state.hasFetchedDefaultTabConfig = true
    },
    setFilter(
      state,
      action: PayloadAction<{ filters: TicketState['filters'] }>
    ) {
      state.filters = action.payload.filters
    },
    setStatuses(state, action: PayloadAction<{ statuses: TicketStatus[] }>) {
      state.filters.statuses = action.payload.statuses
    },
    setAssignedUserIds(
      state,
      action: PayloadAction<{ assignedUserIds: string[] }>
    ) {
      state.filters.assignedUserIds = action.payload.assignedUserIds
    },
    setRequesterUserIds(
      state,
      action: PayloadAction<{ requesterUserIds: string[] }>
    ) {
      state.filters.requesterUserIds = action.payload.requesterUserIds
    },
    setKeyId(state, action: PayloadAction<{ keyId: number }>) {
      const maxUint32 = 4294967295
      const minUint32 = 0
      state.filters.keyId =
        minUint32 < action.payload.keyId && action.payload.keyId < maxUint32
          ? action.payload.keyId
          : 0
    },
    setSubject(state, action: PayloadAction<{ subject: string }>) {
      state.filters.subject = action.payload.subject
    },
    setStartDate(state, action: PayloadAction<{ startDate: Date }>) {
      if (action.payload.startDate.toISOString() !== dateTypeDefaultISOString) {
        state.filters.startDate = action.payload.startDate
        return
      }
      delete state.filters.startDate
    },
    setStartHour(state, action: PayloadAction<{ startHour: string[] }>) {
      state.filters.startHour = action.payload.startHour
    },
    setStartMinutes(state, action: PayloadAction<{ startMinutes: string[] }>) {
      state.filters.startMinutes = action.payload.startMinutes
    },
    setEndDate(state, action: PayloadAction<{ endDate: Date }>) {
      if (action.payload.endDate.toISOString() !== dateTypeDefaultISOString) {
        state.filters.endDate = action.payload.endDate
        return
      }
      delete state.filters.endDate
    },
    setEndHour(state, action: PayloadAction<{ endHour: string[] }>) {
      state.filters.endHour = action.payload.endHour
    },
    setEndMinutes(state, action: PayloadAction<{ endMinutes: string[] }>) {
      state.filters.endMinutes = action.payload.endMinutes
    },
    clearCreatedAtFilter(state) {
      delete state.filters.startDate
      delete state.filters.endDate
      state.filters.startHour = []
      state.filters.startMinutes = []
      state.filters.endHour = []
      state.filters.endMinutes = []
    },
    setSortKeyId(state, action: PayloadAction<{ direction: string }>) {
      state.sorts.keyId = action.payload.direction
    },
    setSortStatus(state, action: PayloadAction<{ direction: string }>) {
      state.sorts.status = action.payload.direction
    },
    setSortCreatedAt(state, action: PayloadAction<{ direction: string }>) {
      state.sorts.createdAt = action.payload.direction
    },
    setSortRecievedAt(state, action: PayloadAction<{ direction: string }>) {
      state.sorts.receivedAt = action.payload.direction
    },
    setSortSentAt(state, action: PayloadAction<{ direction: string }>) {
      state.sorts.sentAt = action.payload.direction
    },
    setSortCustomFieldValueNumber(
      state,
      action: PayloadAction<{ fieldId: string; direction: string }>
    ) {
      state.sorts.customFieldValue.number[action.payload.fieldId] =
        action.payload.direction
    },
    setSortCustomFieldValueDate(
      state,
      action: PayloadAction<{ fieldId: string; direction: string }>
    ) {
      state.sorts.customFieldValue.date[action.payload.fieldId] =
        action.payload.direction
    },
    clearSort(state) {
      state.sorts = {
        keyId: '',
        status: '',
        createdAt: '',
        receivedAt: '',
        sentAt: '',
        customFieldValue: {
          number: {},
          date: {},
        },
      }
    },
    setSearch(state, action: PayloadAction<{ search: string }>) {
      state.filters.search = action.payload.search
    },
    // select系のフィルタをセットする
    setCustomSelectFieldValuesFilter(
      state,
      action: PayloadAction<{
        customFieldId: number
        values: Array<string>
      }>
    ) {
      const { customFieldId, values } = action.payload
      state.filters.customFieldValues.selectFilters[String(customFieldId)] =
        values
    },
    setCustomTextFieldValuesFilter(
      state,
      action: PayloadAction<{
        customFieldId: number
        value: string
      }>
    ) {
      const { customFieldId, value } = action.payload
      state.filters.customFieldValues.textFilters[String(customFieldId)] = value
    },
    setCustomTextAreaFieldValuesFilter(
      state,
      action: PayloadAction<{
        customFieldId: number
        value: string
      }>
    ) {
      const { customFieldId, value } = action.payload
      state.filters.customFieldValues.textAreaFilters[String(customFieldId)] =
        value
    },
    setCustomNumberFieldValuesFilter(
      state,
      action: PayloadAction<{
        customFieldId: number
        minValue?: number
        maxValue?: number
      }>
    ) {
      const { customFieldId, minValue, maxValue } = action.payload
      const numberFilterValue: {
        minValue?: number
        maxValue?: number
      } = {}
      if (minValue != null) numberFilterValue.minValue = minValue
      if (maxValue != null) numberFilterValue.maxValue = maxValue
      state.filters.customFieldValues.numberFilters[String(customFieldId)] =
        numberFilterValue
    },
    setCustomDateFieldValuesFilter(
      state,
      action: PayloadAction<{
        customFieldId: number
        startDate?: string
        endDate?: string
      }>
    ) {
      const { customFieldId, startDate, endDate } = action.payload
      const dateFilterValue: {
        startDate?: string
        endDate?: string
      } = {}
      if (startDate != null) dateFilterValue.startDate = startDate
      if (endDate != null) dateFilterValue.endDate = endDate
      state.filters.customFieldValues.dateFilters[String(customFieldId)] =
        dateFilterValue
    },
    setCustomCheckboxFieldValuesFilter(
      state,
      action: PayloadAction<{
        customFieldId: number
        values: Array<number>
      }>
    ) {
      const { customFieldId, values } = action.payload
      state.filters.customFieldValues.checkBoxFilters[String(customFieldId)] =
        values
    },
    deleteOneCustomFieldFilter(
      state,
      action: PayloadAction<{
        customFieldId: number
        customFieldType: string
      }>
    ) {
      const { customFieldId, customFieldType } = action.payload
      switch (customFieldType) {
        case CustomFieldType.select:
        case CustomFieldType.multiselect:
          delete state.filters.customFieldValues.selectFilters[
            String(customFieldId)
          ]
          break
        case CustomFieldType.text:
          delete state.filters.customFieldValues.textFilters[
            String(customFieldId)
          ]
          break
        case CustomFieldType.textarea:
          delete state.filters.customFieldValues.textAreaFilters[
            String(customFieldId)
          ]
          break
        case CustomFieldType.date:
          delete state.filters.customFieldValues.dateFilters[
            String(customFieldId)
          ]
          break
        case CustomFieldType.number:
          delete state.filters.customFieldValues.numberFilters[
            String(customFieldId)
          ]
          break
        case CustomFieldType.checkbox:
          delete state.filters.customFieldValues.checkBoxFilters[
            String(customFieldId)
          ]
          break
      }
    },
    clearCustomFieldFilter(state) {
      state.filters.customFieldValues = {
        selectFilters: {},
        numberFilters: {},
        dateFilters: {},
        textFilters: {},
        textAreaFilters: {},
        checkBoxFilters: {},
      }
    },
    getTicketStart(state) {
      state.loading = true
      state.error = new TicketStateError(null)
    },
    getTicketOnMessage(
      state,
      action: PayloadAction<{ message: ticket_pb.Ticket.AsObject }>
    ) {
      try {
        ticketsAdapter.upsertOne(
          state,
          convertIntoTicket(action.payload.message)
        )
      } catch (e) {
        state.error = e.toString()
      }
    },
    getTicketOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = new TicketStateError(null)
      } else if (
        code === grpc.Code.Unknown &&
        message === 'Response closed without headers'
      ) {
        state.error = new TicketStateError(null)
      } else {
        state.error = new TicketStateError(message)
        consoleErrorWithAirbrake(message)
      }
    },
    getTicketsStart(state) {
      state.loading = true
      state.error = new TicketStateError(null)
    },
    getTicketsOnMessage(
      state,
      action: PayloadAction<{
        message: ticket_pb.ListTicketsResponse.AsObject
        pageIndex: number
        pageSize: number
      }>
    ) {
      const { message, pageIndex, pageSize } = action.payload
      ticketsAdapter.setAll(
        state,
        message.ticketsList.map((t) => convertIntoTicket(t))
      )
      state.pageSize = pageSize
      state.pageIndex = pageIndex
      state.totalCount = message.totalCount
      state.pageCount = Math.ceil(message.totalCount / pageSize)
    },
    getTicketsOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = new TicketStateError(null)
      } else if (
        code === grpc.Code.Unknown &&
        message === 'Response closed without headers'
      ) {
        // networkがofflineになった場合は、grpc.code.unknownが返され、messageは'Response closed without headers'。この場合は、ネットワーク通信エラーとみなします
        state.error = new TicketStateError('network_error')
      } else {
        state.error = new TicketStateError(message)
        consoleErrorWithAirbrake(message)
      }
    },
    updateTicketStart(
      state,
      action: PayloadAction<{ ticket: Partial<ticket_pb.Ticket.AsObject> }>
    ) {
      state.loading = true
      state.error = new TicketStateError(null)
      const ticket = action.payload.ticket
      if (!ticket.id) return
      ticketsAdapter.updateOne(state, {
        id: ticket.id,
        changes: ticket,
      })
    },
    updateTicketOnMessage(
      state,
      action: PayloadAction<{ message: ticket_pb.Ticket.AsObject }>
    ) {
      const ticket = action.payload.message
      ticketsAdapter.updateOne(state, {
        id: ticket.id,
        changes: ticket,
      })
    },
    updateTicketOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
        trailers: grpc.Metadata
      }>
    ) {
      const { code, message, trailers } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = new TicketStateError(null)
        return
      }

      const detail = extractErrorDetails(trailers)[0]
      if (
        code === grpc.Code.AlreadyExists &&
        detail.code === TicketErrorCode.TICKET_OPERATOR_CONFIRM_REQUIRED
      ) {
        state.error = new TicketStateError(null)
        state.isRequiredAssignedUserConfirm = true
        return
      }
      state.error = new TicketStateError(message)
      consoleErrorWithAirbrake(message)
    },
    switchRequiredAssignedUserConfirm(state) {
      state.isRequiredAssignedUserConfirm = !state.isRequiredAssignedUserConfirm
    },
    getTabConfigOnMessage(
      state,
      action: PayloadAction<{
        message: tab_config_pb.TabConfig.AsObject
        customFields: TicketCustomField.AsObject[]
        meId: string
      }>
    ) {
      const { tabFilterConfigs, tabSortConfig } = action.payload.message

      state.filters = {
        ...initialFilter,
        assignedUserIds: [],
        search: state.filters.search, // tab configはsearchに影響しないのでsearchは残す
        customFieldValues: {
          selectFilters: {},
          numberFilters: {},
          dateFilters: {},
          textFilters: {},
          textAreaFilters: {},
          checkBoxFilters: {},
        },
      }

      if (tabFilterConfigs != null) {
        for (const tabFilter of tabFilterConfigs.tabFiltersList) {
          const customFieldId = tabFilter.ticketCustomFieldId
          const customField = action.payload.customFields.find(
            (cf) => cf.id === customFieldId
          )
          // DefaultFieldの場合は、customFieldがnullになる
          if (customField == null) {
            switch (tabFilter.ticketFieldName) {
              case DefaultFields.AssignedUserId.value:
                if (tabFilter.ticketFieldOneOfFilter?.stringFilter) {
                  state.filters.assignedUserIds =
                    tabFilter.ticketFieldOneOfFilter.stringFilter.valuesList.map(
                      (v) => {
                        if (v === Tickets.Users.loginUserId) {
                          return action.payload.meId
                        }
                        return v
                      }
                    )
                }
                break
              case DefaultFields.Status.value:
                if (tabFilter.ticketFieldOneOfFilter?.stringFilter != null)
                  state.filters.statuses = tabFilter.ticketFieldOneOfFilter
                    .stringFilter.valuesList as TicketStatus[]
                break
              case DefaultFields.CreatedAt.value:
                if (tabFilter.ticketFieldOneOfFilter?.dateFilter != null) {
                  const isoStartDateString =
                    tabFilter.ticketFieldOneOfFilter.dateFilter.startDate
                  const [startDate, startTime] = isoStartDateString.split('T')
                  state.filters.startDate = new Date(startDate)
                  state.filters.startHour = [startTime.split(':')[0]]
                  state.filters.startMinutes = [startTime.split(':')[1]]

                  const isoEndDateString =
                    tabFilter.ticketFieldOneOfFilter.dateFilter.endDate
                  const [endDate, endTime] = isoEndDateString.split('T')
                  state.filters.endDate = new Date(endDate)
                  state.filters.endHour = [endTime.split(':')[0]]
                  state.filters.endMinutes = [endTime.split(':')[1]]
                }
                if (tabFilter.ticketFieldOneOfFilter?.startDateFilter != null) {
                  const isoStartDateString =
                    tabFilter.ticketFieldOneOfFilter.startDateFilter.value
                  const [startDate, startTime] = isoStartDateString.split('T')
                  state.filters.startDate = new Date(startDate)
                  state.filters.startHour = [startTime.split(':')[0]]
                  state.filters.startMinutes = [startTime.split(':')[1]]
                }
                if (tabFilter.ticketFieldOneOfFilter?.endDateFilter != null) {
                  const isoEndDateString =
                    tabFilter.ticketFieldOneOfFilter.endDateFilter.value
                  const [endDate, endTime] = isoEndDateString.split('T')
                  state.filters.endDate = new Date(endDate)
                  state.filters.endHour = [endTime.split(':')[0]]
                  state.filters.endMinutes = [endTime.split(':')[1]]
                }
                break
              case DefaultFields.FaqStatus.value:
                if (tabFilter.ticketFieldOneOfFilter?.stringFilter != null)
                  state.filters.faqStatuses = tabFilter.ticketFieldOneOfFilter
                    .stringFilter.valuesList as FaqStatus[]
                break
            }
          }
          switch (customField?.type) {
            case CustomFieldType.select:
              if (tabFilter.ticketFieldOneOfFilter?.stringFilter != null) {
                state.filters.customFieldValues.selectFilters[
                  String(customFieldId)
                ] = tabFilter.ticketFieldOneOfFilter.stringFilter.valuesList
              }
              break
            case CustomFieldType.date:
              if (tabFilter.ticketFieldOneOfFilter?.dateFilter != null) {
                state.filters.customFieldValues.dateFilters[
                  String(customFieldId)
                ] = {
                  startDate:
                    tabFilter.ticketFieldOneOfFilter.dateFilter.startDate
                      .split('T')[0]
                      .replace(/-/g, '/'),
                  endDate: tabFilter.ticketFieldOneOfFilter.dateFilter.endDate
                    .split('T')[0]
                    .replace(/-/g, '/'),
                }
              }
              if (tabFilter.ticketFieldOneOfFilter?.startDateFilter != null) {
                state.filters.customFieldValues.dateFilters[
                  String(customFieldId)
                ] = {
                  startDate:
                    tabFilter.ticketFieldOneOfFilter.startDateFilter.value
                      .split('T')[0]
                      .replace(/-/g, '/'),
                }
              }
              if (tabFilter.ticketFieldOneOfFilter?.endDateFilter != null) {
                state.filters.customFieldValues.dateFilters[
                  String(customFieldId)
                ] = {
                  endDate: tabFilter.ticketFieldOneOfFilter.endDateFilter.value
                    .split('T')[0]
                    .replace(/-/g, '/'),
                }
              }
              break
            case CustomFieldType.text:
              if (tabFilter.ticketFieldOneOfFilter?.stringFilter != null) {
                state.filters.customFieldValues.textFilters[
                  String(customFieldId)
                ] = tabFilter.ticketFieldOneOfFilter.stringFilter.valuesList[0]
              }
              break
            case CustomFieldType.textarea:
              if (tabFilter.ticketFieldOneOfFilter?.textFilter != null) {
                state.filters.customFieldValues.textAreaFilters[
                  String(customFieldId)
                ] = tabFilter.ticketFieldOneOfFilter.textFilter.stringValue
              }
              break
            case CustomFieldType.number:
              if (tabFilter.ticketFieldOneOfFilter?.numberFilter != null) {
                state.filters.customFieldValues.numberFilters[
                  String(customFieldId)
                ] = {
                  minValue:
                    tabFilter.ticketFieldOneOfFilter.numberFilter.minValue,
                  maxValue:
                    tabFilter.ticketFieldOneOfFilter.numberFilter.maxValue,
                }
              }
              if (tabFilter.ticketFieldOneOfFilter?.minNumberFilter != null) {
                state.filters.customFieldValues.numberFilters[
                  String(customFieldId)
                ] = {
                  minValue:
                    tabFilter.ticketFieldOneOfFilter.minNumberFilter.value,
                }
              }
              if (tabFilter.ticketFieldOneOfFilter?.maxNumberFilter != null) {
                state.filters.customFieldValues.numberFilters[
                  String(customFieldId)
                ] = {
                  maxValue:
                    tabFilter.ticketFieldOneOfFilter.maxNumberFilter.value,
                }
              }
              break
            case CustomFieldType.checkbox:
              if (tabFilter.ticketFieldOneOfFilter?.checkboxFilter != null) {
                state.filters.customFieldValues.checkBoxFilters[
                  String(customFieldId)
                ] = tabFilter.ticketFieldOneOfFilter.checkboxFilter.valuesList
              }
              break
            default:
              break
          }
        }
      }

      // ソート条件初期化
      // sortプロパティが存在しても付随する各種の値が空になっているケースの発生を念のため想定している
      // その場合は「受信日」の「降順」となるようにしている
      if (tabSortConfig != null) {
        const identifierName =
          tabSortConfig.ticketFieldName !== ''
            ? tabSortConfig.ticketFieldName
            : ''
        const ticketCustomFieldId =
          tabSortConfig.ticketCustomFieldId === 0
            ? null
            : tabSortConfig.ticketCustomFieldId
        const direction = tabSortConfig.isAsc ? Sort.Asc.value : Sort.Desc.value

        state.sorts = initializeSorts(
          {
            identifierName: identifierName,
            ticketCustomFieldId: ticketCustomFieldId,
            direction: direction,
          },
          action.payload.customFields
        )
      }
    },
    getTabConfigOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      if (code === grpc.Code.OK) {
        state.hasFetchedDefaultTabConfig = true
        return
      }
      consoleErrorWithAirbrake(message)
    },
    toggleShowAllAutoMessage(state, actions: PayloadAction<{ id: number }>) {
      const ticket = state.entities[actions.payload.id]
      if (ticket == null) {
        return
      }
      ticket.showAllNoteRequestResponse = !ticket.showAllNoteRequestResponse
      ticketsAdapter.updateOne(state, { id: ticket.id, changes: ticket })
    },
    createManualTicketOnMessage(
      state,
      action: PayloadAction<{ message: ticket_pb.Ticket.AsObject }>
    ) {
      ticketsAdapter.upsertOne(state, convertIntoTicket(action.payload.message))
    },
    createManualTicketOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = new TicketStateError(null)
        return
      }
      state.error = new TicketStateError(message)
      consoleErrorWithAirbrake(message)
    },
    showTicketPropertyTab(state) {
      state.sideTabIndex = 0
    },
    showCreateFaqTab(state) {
      state.sideTabIndex = 1
    },
    setFaqStatuses(state, action: PayloadAction<{ statuses: FaqStatus[] }>) {
      state.filters.faqStatuses = action.payload.statuses
    },
  },
})

export const {
  setInitialFilter,
  setFilter,
  setStatuses,
  setAssignedUserIds,
  setRequesterUserIds,
  setKeyId,
  setSubject,
  setStartDate,
  setStartHour,
  setStartMinutes,
  setEndDate,
  setEndHour,
  setEndMinutes,
  clearCreatedAtFilter,
  setSortKeyId,
  setSortStatus,
  setSortCreatedAt,
  setSortRecievedAt,
  setSortSentAt,
  setSortCustomFieldValueNumber,
  setSortCustomFieldValueDate,
  clearSort,
  setSearch,
  setCustomSelectFieldValuesFilter,
  setCustomTextFieldValuesFilter,
  setCustomTextAreaFieldValuesFilter,
  setCustomNumberFieldValuesFilter,
  setCustomDateFieldValuesFilter,
  setCustomCheckboxFieldValuesFilter,
  deleteOneCustomFieldFilter,
  clearCustomFieldFilter,
  getTicketStart,
  getTicketOnMessage,
  getTicketOnEnd,
  getTicketsStart,
  getTicketsOnMessage,
  getTicketsOnEnd,
  updateTicketStart,
  updateTicketOnMessage,
  updateTicketOnEnd,
  switchRequiredAssignedUserConfirm,
  getTabConfigOnMessage,
  getTabConfigOnEnd,
  toggleShowAllAutoMessage,
  createManualTicketOnMessage,
  createManualTicketOnEnd,
  showTicketPropertyTab,
  showCreateFaqTab,
  setFaqStatuses,
} = ticketSlice.actions
export default ticketSlice.reducer

export const fetchDefaultConfig =
  (
    uuid: string,
    customFields: TicketCustomField.AsObject[],
    meId: string
  ): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      tab_config_pb.GetNewTabConfigRequest,
      tab_config_pb.TabConfig
    >(tab_config_pb_service.TabConfigNewAPI.GetNewTabConfig)
    const req = new tab_config_pb.GetNewTabConfigRequest()

    req.setTabUuid(uuid)

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      dispatch(
        getTabConfigOnMessage({
          message: message.toObject(),
          customFields: customFields,
          meId: meId,
        })
      )
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(getTabConfigOnEnd({ code, message }))
    })

    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const fetchTicket =
  (id: number): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<ticket_pb.GetTicketRequest, ticket_pb.Ticket>(
      ticket_pb_service.TicketAPI.GetTicket
    )
    const req = new ticket_pb.GetTicketRequest()
    req.setId(id)

    client.onMessage((message) => {
      dispatch(getTicketOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(getTicketOnEnd({ code, message }))
    })

    dispatch(getTicketStart())
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const fetchTickets =
  (
    pageIndex: number,
    pageSize: number,
    filters: {
      statuses: TicketStatus[]
      assignedUserIds: string[]
      requesterUserIds: string[]
      subject: string
      keyId: number
      startDate?: Date
      startHour: string[]
      startMinutes: string[]
      endDate?: Date
      endHour: string[]
      endMinutes: string[]
      search: string
      faqStatuses: FaqStatus[]
      customFieldValues: CustomFieldValueFilter
    },
    sorts: {
      keyId: string
      status: string
      createdAt: string
      receivedAt: string
      sentAt: string
      customFieldValue: CustomFieldValueSort
    }
  ): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      ticket_pb.ListTicketsRequest,
      ticket_pb.ListTicketsResponse
    >(ticket_pb_service.TicketAPI.ListTickets)
    const req = new ticket_pb.ListTicketsRequest()
    req.setLimit(pageSize)
    req.setOffset(pageIndex * pageSize)
    req.setStatusesList(filters.statuses)
    req.setAssignedUserIdsList(filters.assignedUserIds)
    req.setRequesterUserIdsList(filters.requesterUserIds)
    req.setKeyId(filters.keyId)
    req.setSubject(filters.subject)
    req.setSearch(filters.search)
    req.setFaqstatus(
      filters.faqStatuses.length > 0
        ? filters.faqStatuses[0]
        : FaqStatus.nothing
    )

    const sort = buildSort(sorts)
    req.setSort(sort)

    if (
      filters.startDate &&
      filters.startHour.length > 0 &&
      filters.startMinutes.length > 0
    ) {
      const startDatetime = new Date(
        filters.startDate.getFullYear(),
        filters.startDate.getMonth(),
        filters.startDate.getDate(),
        Number(filters.startHour[0]),
        Number(filters.startMinutes[0]),
        0,
        0
      )
      const timestamp = new Timestamp()
      timestamp.fromDate(startDatetime)
      req.setCreatedAtStart(timestamp)
    }

    if (
      filters.endDate &&
      filters.endHour.length > 0 &&
      filters.endMinutes.length > 0
    ) {
      const endDatetime = new Date(
        filters.endDate.getFullYear(),
        filters.endDate.getMonth(),
        filters.endDate.getDate(),
        Number(filters.endHour[0]),
        Number(filters.endMinutes[0]),
        0,
        0
      )
      const timestamp = new Timestamp()
      timestamp.fromDate(endDatetime)
      req.setCreatedAtEnd(timestamp)
    }

    const customFieldFilters = new Array<TicketCustomFieldOneOfFilter>()

    setSelectFilterToRequest(
      filters.customFieldValues.selectFilters,
      customFieldFilters
    )
    setNumberFilterToRequest(
      filters.customFieldValues.numberFilters,
      customFieldFilters
    )
    setDateFilterToRequest(
      filters.customFieldValues.dateFilters,
      customFieldFilters
    )
    setTextFilterToRequest(
      filters.customFieldValues.textFilters,
      customFieldFilters
    )
    setTextAreaFilterToRequest(
      filters.customFieldValues.textAreaFilters,
      customFieldFilters
    )
    setCheckboxFilterToRequest(
      filters.customFieldValues.checkBoxFilters,
      customFieldFilters
    )

    req.setTicketCustomFieldFilterList(customFieldFilters)

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      dispatch(
        getTicketsOnMessage({
          message: message.toObject(),
          pageIndex,
          pageSize,
        })
      )
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }

      dispatch(getTicketsOnEnd({ code, message }))
    })

    dispatch(getTicketsStart())
    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const updateTicket =
  (props: Partial<ticket_pb.Ticket.AsObject>, force: boolean): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<ticket_pb.UpdateTicketRequest, ticket_pb.Ticket>(
      ticket_pb_service.TicketAPI.UpdateTicket
    )
    const req = createUpdateTicketRequest(props, force)

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      dispatch(updateTicketOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code, message, trailers) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(updateTicketOnEnd({ code, message, trailers }))
    })
    dispatch(updateTicketStart({ ticket: props }))
    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const createManualTicket =
  (
    requesterUserId: string,
    requesterUserDisplayName: string,
    subject: string,
    history: History<unknown> // TODO: put history into service
  ): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      ticket_pb.CreateManualTicketRequest,
      ticket_pb.Ticket
    >(ticket_pb_service.TicketAPI.CreateManualTicket)

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)
    const req = new ticket_pb.CreateManualTicketRequest()
    req.setRequesterUserId(requesterUserId)
    req.setSubject(subject)

    client.onMessage((message) => {
      dispatch(createManualTicketOnMessage({ message: message.toObject() }))
      history.push('/tickets/' + message.getId())
    })

    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(createManualTicketOnEnd({ code, message }))
    })

    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const deleteManualTicket =
  (ticketId: number, history: History<unknown>): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<ticket_pb.DeleteManualTicketRequest, Empty>(
      ticket_pb_service.TicketAPI.DeleteManualTicket
    )

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken

    if (token != null) meta.append('authorization', 'bearer ' + token)
    const req = new ticket_pb.DeleteManualTicketRequest()
    req.setId(ticketId)

    client.onMessage(() => {
      history.push('/tickets/')
    })

    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(createManualTicketOnEnd({ code, message }))
    })

    client.start(meta)
    client.send(req)
    client.finishSend()
  }

const initializeSorts = (
  sort: SortConfig,
  customFields: TicketCustomField.AsObject[]
) => {
  const customFieldValue: CustomFieldValueSort = {
    number: {},
    date: {},
  }

  const sorts: sorts = {
    keyId: '',
    status: '',
    createdAt: '',
    receivedAt: '',
    sentAt: '',
    customFieldValue: customFieldValue,
  }

  // 設定値がなければデフォルト値を返して終了
  if (sort == null) {
    sorts.receivedAt = Sort.Desc.value
    return sorts
  }

  // デフォルトフィールド
  if (sortableColumnsValues.includes(sort.identifierName)) {
    switch (sort.identifierName) {
      case DefaultFields.KeyId.value: {
        sorts.keyId = sort.direction
        break
      }
      case DefaultFields.Status.value: {
        sorts.status = sort.direction
        break
      }
      case DefaultFields.CreatedAt.value: {
        sorts.createdAt = sort.direction
        break
      }
      case DefaultFields.ReceivedAt.value: {
        sorts.receivedAt = sort.direction
        break
      }
      case DefaultFields.SentAt.value: {
        sorts.sentAt = sort.direction
        break
      }
      default: {
        break
      }
    }
  }
  // カスタムフィールド
  const sortType = customFields.find(
    (cf) => sort.ticketCustomFieldId === cf.id
  )?.type
  if (sortType) {
    switch (sortType) {
      case CustomFieldType.number:
        sorts.customFieldValue.number = {
          [String(sort.ticketCustomFieldId)]: sort.direction,
        }
        break
      case CustomFieldType.date:
        sorts.customFieldValue.date = {
          [String(sort.ticketCustomFieldId)]: sort.direction,
        }
        break
      default:
        break
    }
  }

  return sorts
}

const createUpdateTicketRequest = (
  props: Partial<ticket_pb.Ticket.AsObject>,
  force: boolean
) => {
  const req = new ticket_pb.UpdateTicketRequest()
  const ticket = new ticket_pb.Ticket()
  if (props.id != null) ticket.setId(props.id)
  if (props.status != null) ticket.setStatus(props.status)
  if (props.assignedUserId != null)
    ticket.setAssignedUserId(props.assignedUserId)
  if (props.subject != null) ticket.setSubject(props.subject)
  if (props.channel != null) {
    const ticketChannel = new ticket_pb.TicketChannel()
    if (props.channel.groupId != null)
      ticketChannel.setGroupId(props.channel.groupId || '')
    if (props.channel.channelId != null)
      ticketChannel.setChannelId(props.channel.channelId || '')
    ticket.setChannel(ticketChannel)
  }
  if (props.note != null) {
    ticket.setNote(props.note)
  }
  req.setForce(force)
  req.setTicket(ticket)

  const mask = new FieldMask()
  const pathsList = createFieldMaskPathsList(props)
  mask.setPathsList(pathsList)
  req.setUpdateMask(mask)
  return req
}

const setSelectFilterToRequest = (
  selectFilters: {
    [fieldId: string]: string[]
  },
  customFieldFilters: TicketCustomFieldOneOfFilter[]
) => {
  if (Object.keys(selectFilters).length > 0) {
    const fieldIds = Object.keys(selectFilters)
    fieldIds.forEach((id) => {
      if (selectFilters[id].length === 0) return
      const oneOfFilter = new TicketCustomFieldOneOfFilter()
      const selectFieldFilter = new TicketCustomSelectFieldFilter()
      selectFieldFilter.setTicketCustomFieldId(parseInt(id, 10))
      selectFieldFilter.setValuesList(selectFilters[id])
      oneOfFilter.setSelectFilter(selectFieldFilter)
      customFieldFilters.push(oneOfFilter)
    })
  }
}

const setNumberFilterToRequest = (
  numberFilters: {
    [fieldId: string]: {
      minValue?: number
      maxValue?: number
    }
  },
  customFieldFilters: TicketCustomFieldOneOfFilter[]
) => {
  if (Object.keys(numberFilters).length > 0) {
    const fieldIds = Object.keys(numberFilters)
    fieldIds.forEach((id) => {
      const minValue = numberFilters[id]?.minValue
      const maxValue = numberFilters[id]?.maxValue
      if (minValue == null && maxValue == null) return
      const oneOfFilter = new TicketCustomFieldOneOfFilter()
      const numberFieldFilter = new TicketCustomNumberFieldFilter()
      numberFieldFilter.setTicketCustomFieldId(parseInt(id, 10))
      if (minValue != null) {
        numberFieldFilter.setMinValue(minValue)
      }
      if (maxValue != null) {
        numberFieldFilter.setMaxValue(maxValue)
      }
      oneOfFilter.setNumberFilter(numberFieldFilter)
      customFieldFilters.push(oneOfFilter)
    })
  }
}

const setDateFilterToRequest = (
  dateFilters: {
    [fieldId: string]: {
      startDate?: string
      endDate?: string
    }
  },
  customFieldFilters: TicketCustomFieldOneOfFilter[]
) => {
  if (Object.keys(dateFilters).length > 0) {
    const fieldIds = Object.keys(dateFilters)
    fieldIds.forEach((id) => {
      const startDate = dateFilters[id]?.startDate
      const endDate = dateFilters[id]?.endDate
      if (startDate == null && endDate == null) return
      const oneOfFilter = new TicketCustomFieldOneOfFilter()
      const dateFieldFilter = new TicketCustomDateFieldFilter()
      dateFieldFilter.setTicketCustomFieldId(parseInt(id, 10))
      // 2022/03/02 → 2022-03-02に変換し、サーバーでRFC3339の形式で処理可能にしている
      if (startDate != null) {
        dateFieldFilter.setStartDate(startDate.replace(/\//g, '-'))
      }
      if (endDate != null) {
        dateFieldFilter.setEndDate(endDate.replace(/\//g, '-'))
      }
      oneOfFilter.setDateFilter(dateFieldFilter)
      customFieldFilters.push(oneOfFilter)
    })
  }
}

const setTextFilterToRequest = (
  textFilters: {
    [fieldId: string]: string
  },
  customFieldFilters: TicketCustomFieldOneOfFilter[]
) => {
  if (Object.keys(textFilters).length > 0) {
    const fieldIds = Object.keys(textFilters)
    fieldIds.forEach((id) => {
      if (textFilters[id] === '') return
      const oneOfFilter = new TicketCustomFieldOneOfFilter()
      const textFieldFilter = new TicketCustomTextFieldFilter()
      textFieldFilter.setTicketCustomFieldId(parseInt(id, 10))
      textFieldFilter.setStringValue(textFilters[id].trim())
      oneOfFilter.setTextFilter(textFieldFilter)
      customFieldFilters.push(oneOfFilter)
    })
  }
}

const setTextAreaFilterToRequest = (
  textAreaFilters: {
    [fieldId: string]: string
  },
  customFieldFilters: TicketCustomFieldOneOfFilter[]
) => {
  if (Object.keys(textAreaFilters).length > 0) {
    const fieldIds = Object.keys(textAreaFilters)
    fieldIds.forEach((id) => {
      if (textAreaFilters[id] === '') return
      const oneOfFilter = new TicketCustomFieldOneOfFilter()
      const textAreaFieldFilter = new TicketCustomTextAreaFieldFilter()
      textAreaFieldFilter.setTicketCustomFieldId(parseInt(id, 10))
      textAreaFieldFilter.setTextValue(textAreaFilters[id].trim())
      oneOfFilter.setTextAreaFilter(textAreaFieldFilter)
      customFieldFilters.push(oneOfFilter)
    })
  }
}

const setCheckboxFilterToRequest = (
  checkBoxFilters: {
    [fieldId: string]: number[]
  },
  customFieldFilters: TicketCustomFieldOneOfFilter[]
) => {
  if (Object.keys(checkBoxFilters).length > 0) {
    const fieldIds = Object.keys(checkBoxFilters)
    fieldIds.forEach((id) => {
      if (checkBoxFilters[id].length === 0) return
      const oneOfFilter = new TicketCustomFieldOneOfFilter()
      const checkBoxFieldFilter = new TicketCustomCheckboxFieldFilter()
      checkBoxFieldFilter.setTicketCustomFieldId(parseInt(id, 10))
      checkBoxFieldFilter.setValuesList(checkBoxFilters[id])
      oneOfFilter.setCheckboxFilter(checkBoxFieldFilter)
      customFieldFilters.push(oneOfFilter)
    })
  }
}

const buildSort = (sorts: sorts): ticket_pb.TicketOneOfSort => {
  const sort = new ticket_pb.TicketOneOfSort()

  if (sorts.keyId != '') {
    sort.setKeyId(sorts.keyId)
    return sort
  }

  if (sorts.status != '') {
    sort.setStatus(sorts.status)
    return sort
  }

  if (sorts.createdAt != '') {
    sort.setCreatedAt(sorts.createdAt)
    return sort
  }

  if (sorts.receivedAt != '') {
    sort.setReceivedAt(sorts.receivedAt)
    return sort
  }

  if (sorts.sentAt != '') {
    sort.setSentAt(sorts.sentAt)
    return sort
  }

  const customFieldSort = buildCustomFieldSort(sorts.customFieldValue)
  sort.setTicketCustomFieldSort(customFieldSort)

  return sort
}

const buildCustomFieldSort = (
  customFieldValue: CustomFieldValueSort
): ticket_pb.TicketCustomFieldOneOfSort => {
  const customFieldSort = new ticket_pb.TicketCustomFieldOneOfSort()

  if (!isEmpty(customFieldValue.number)) {
    const number = new ticket_pb.TicketCustomNumberFieldSort()
    number.setTicketCustomFieldId(
      Number(Object.keys(customFieldValue.number)[0])
    )
    number.setStringValue(Object.values(customFieldValue.number)[0])
    customFieldSort.setNumber(number)
    return customFieldSort
  }

  if (!isEmpty(customFieldValue.date)) {
    const date = new ticket_pb.TicketCustomDateFieldSort()
    date.setTicketCustomFieldId(Number(Object.keys(customFieldValue.date)[0]))
    date.setStringValue(Object.values(customFieldValue.date)[0])
    customFieldSort.setDate(date)
    return customFieldSort
  }

  return customFieldSort
}

export const isEmpty = (obj: Record<string, unknown>): boolean => {
  return !Object.keys(obj).length
}
