import { Client } from '@microsoft/microsoft-graph-client'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

import { RootState } from '../../../app/store'

export const graphBaseQuery =
  ({
    useBeta = false,
  }): BaseQueryFn<
    { request: (client: Client) => Promise<unknown> },
    unknown,
    unknown
  > =>
  async (args, baseQueryAPI) => {
    const state = baseQueryAPI.getState() as RootState

    if (!state.requesterAuth.azureADToken && !state.auth.graphAccessToken) {
      return {
        error: new Error('azure ad token has not been set'),
      }
    }

    const baseUrl = useBeta
      ? 'https://graph.microsoft.com/beta'
      : 'https://graph.microsoft.com/v1.0'

    const client = Client.init({
      authProvider: (done) => {
        const accessToken = state.requesterAuth.entity.graphToken.accessToken
          ? state.requesterAuth.entity.graphToken.accessToken
          : state.auth.graphAccessToken ?? ''
        done(null, accessToken)
      },
      baseUrl,
      defaultVersion: '',
    })

    let res: unknown
    try {
      res = await args.request(client)
    } catch (e) {
      return {
        error: e,
      }
    }

    return {
      data: res,
    }
  }
