import * as microsoftTeams from '@microsoft/teams-js'
import { wait } from '@testing-library/user-event/dist/utils'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import { useHistory } from 'react-router-dom'
import { debounce } from 'throttle-debounce'
import * as uuid from 'uuid'

import { RootState, ThunkDispatchType } from '../../app/store'
import ActivityMessagePlanForm, {
  FormData,
} from '../../components/ActivityMessagePlanForm'
import ActivityMessagePlanFormReceiverSelect from '../ActivityMessagePlanFormReceiverSelect'
import * as Operations from './operations'
import * as Selectors from './selectors'
import * as Slice from './slice'

const ActivityMessagePlanNewForm: React.FC = () => {
  const history = useHistory()
  const dispatch = ReactRedux.useDispatch<ThunkDispatchType>()

  const [receiverIDs, setReceiverIDs] = React.useState<string[]>([])
  const [groupID, setGroupID] = React.useState<string | null>(null)
  const [isSubmitLoading, setIsSubmitLoading] = React.useState<boolean>(false)

  const attachments = ReactRedux.useSelector(Selectors.attachmentsSelector)

  const isAvailableAttachment = ReactRedux.useSelector(
    (state: RootState) => state.auth?.availableFeatures?.fileAttachment ?? false
  )

  const submitAndPreview = React.useCallback(
    async (data: FormData) => {
      setIsSubmitLoading(true)
      const res = await dispatch(
        Operations.createDraftPlan({ data, receiverIDs })
      ).unwrap()
      // レプリケーションを待つため少し待つ
      await wait(1500)
      history.push(`/activity-messages/${res.id}`)
    },
    [history, receiverIDs, dispatch]
  )

  const submitAndBack = React.useCallback(
    async (data: FormData) => {
      setIsSubmitLoading(true)
      await dispatch(Operations.createDraftPlan({ data, receiverIDs })).unwrap()
      // レプリケーションを待つため少し待つ
      await wait(1500)
      history.push(`/activity-messages`)
    },
    [history, receiverIDs, dispatch]
  )

  const changeReceiverIDs = React.useCallback(
    (value: string[]) => {
      setReceiverIDs(value)
    },
    [setReceiverIDs]
  )

  const renderReceiverSelect = React.useCallback(() => {
    return (
      <ActivityMessagePlanFormReceiverSelect
        defaultReceiverIDs={receiverIDs}
        onChange={changeReceiverIDs}
      />
    )
  }, [receiverIDs, changeReceiverIDs])

  const appendAttachment = React.useCallback(
    (file: File) => {
      dispatch(
        Operations.uploadFile({
          id: uuid.v4(),
          file,
          groupID: groupID ?? '',
        })
      )
    },
    [dispatch, groupID]
  )

  const removeAttachment = React.useCallback(
    (id: string) => {
      dispatch(Operations.removeFile({ id }))
    },
    [dispatch]
  )

  React.useEffect(() => {
    microsoftTeams.app.getContext().then((context) => {
      setGroupID(context.team?.groupId ?? '')
    })
  }, [setGroupID])

  React.useEffect(() => {
    return () => {
      dispatch(Slice.actions.reset())
    }
  }, [dispatch])

  return (
    <ActivityMessagePlanForm
      title="新規配信の作成"
      attachments={attachments}
      isSubmitLoading={isSubmitLoading}
      isShowAttachment={isAvailableAttachment}
      receiversAreEmpty={receiverIDs.length <= 0}
      onClickRemoveAttachment={removeAttachment}
      onClickAppendAttachment={appendAttachment}
      onSubmitAndPreview={debounce(300, submitAndPreview)}
      onSubmitAndBack={debounce(300, submitAndBack)}
      renderReceiverSelect={renderReceiverSelect}
    />
  )
}

export default React.memo(ActivityMessagePlanNewForm)
