import { Button, ChevronStartIcon, Text } from '@fluentui/react-northstar'
import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'

import styles from './styles.module.css'

type Props = {
  id: string
  isShowBackButton: boolean
  renderPreviewBody: () => React.ReactElement
  renderPreviewSidebar: () => React.ReactElement
  renderReceiverList: () => React.ReactElement
}
const ActivityMessagePlanPreviewPresenter: React.FC<Props> = (props) => {
  const history = useHistory()
  return (
    <>
      {props.renderReceiverList()}
      <div className={styles.container}>
        <div className={styles.header}>
          <Button
            onClick={() => history.push('/activity-messages')}
            text
            icon={<ChevronStartIcon />}
            content="お知らせの配信一覧"
          />
        </div>
        <div className={styles.body}>
          <div className={styles.bodyTitleRow}>
            <Text size="large" weight="bold" content="配信内容の確認" />
            {props.isShowBackButton && (
              <span className={styles.bodyTitleRowBackText}>
                <Link to={`/activity-messages/${props.id}/edit`}>
                  (内容の編集に戻る)
                </Link>
              </span>
            )}
          </div>
          <div className={styles.bodyContentRow}>
            <div className={styles.bodyContentLeft}>
              {props.renderPreviewBody()}
            </div>
            <div className={styles.bodyContentRight}>
              {props.renderPreviewSidebar()}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ActivityMessagePlanPreviewPresenter
