import { grpc } from '@improbable-eng/grpc-web'
import type { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { retry } from '@reduxjs/toolkit/dist/query/react'

import type { RootState } from '../../../app/store'
import { RequesterExchangeTokenRequest } from '../../../proto/requester_auth_pb'
import { RequesterAuthAPI } from '../../../proto/requester_auth_pb_service'
import {
  GRPCErrorResponseObject,
  authorizedGrpcRequest,
  grpcStandardRequestPromise,
} from '../../deskApiCommon/common'
import * as RequesterAuthActions from '../../RequesterAuth/actions'
import { getAzureADToken } from '../../RequesterProtectedRoute/operations'
import requiredScope from './graphScopes'

/**
 * RequesterAPIのためのgRPCクライアント
 * Unauthenticatedのエラー時は1度だけトークンリフレッシュを行う
 */
export const grpcBaseQuery =
  <
    TMessage extends grpc.ProtobufMessage,
    TResponse extends grpc.ProtobufMessage
  >(): BaseQueryFn<
    {
      service: grpc.MethodDefinition<TMessage, TResponse>
      body: TMessage
      disableAuth?: boolean
    },
    ReturnType<TResponse['toObject']>,
    GRPCErrorResponseObject
  > =>
  async (args, baseQueryAPI) => {
    let state = baseQueryAPI.getState() as RootState

    // 認証が必要なAPIで認証されていない時
    if (
      !args.disableAuth &&
      !state.requesterAuth.isInitialized &&
      state.requesterAuth.entity.accessToken === ''
    ) {
      throw new Error('access token has not been set')
    }

    const originalRes = args.disableAuth
      ? await grpcStandardRequestPromise(args.service, args.body)
      : await authorizedGrpcRequest(
          args.service,
          args.body,
          state.requesterAuth.entity.accessToken
        )

    // Unauthenticated が返ってきた時に一度だけトークンリフレッシュを行う
    if (
      originalRes.error &&
      originalRes.error.code === grpc.Code.Unauthenticated
    ) {
      // トークンリフレッシュのためにAzureADTokenを取得する
      await baseQueryAPI.dispatch(getAzureADToken())
      state = baseQueryAPI.getState() as RootState

      // トークンリフレッシュのリクエストを行う
      const req = new RequesterExchangeTokenRequest()
      req.setSsoToken(state.requesterAuth.azureADToken ?? '')
      req.setGraphScope(requiredScope.join(' '))
      const refreshTokenRes = await grpcStandardRequestPromise(
        RequesterAuthAPI.RequesterExchangeToken,
        req
      )
      // トークンリフレッシュがエラーだったらオリジナルのレスポンスを返す
      // この時リトライを行わないようにBailOutする https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#bailing-out-of-error-re-tries
      if (refreshTokenRes.error) {
        return retry.fail(originalRes.error)
      }

      // 新しいトークンをStoreに反映
      baseQueryAPI.dispatch(
        RequesterAuthActions.tokenRefreshAction({
          accessToken: refreshTokenRes.data.accessToken,
        })
      )

      // 新しいトークンでリトライする
      return await authorizedGrpcRequest(
        args.service,
        args.body,
        refreshTokenRes.data.accessToken
      )
    }

    return originalRes
  }
