// source: proto/tab_config.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.CheckboxFilter', null, global);
goog.exportSymbol('proto.CreateNewTabConfigRequest', null, global);
goog.exportSymbol('proto.DateFilter', null, global);
goog.exportSymbol('proto.EndDateFilter', null, global);
goog.exportSymbol('proto.GetNewTabConfigRequest', null, global);
goog.exportSymbol('proto.MaxNumberFilter', null, global);
goog.exportSymbol('proto.MinNumberFilter', null, global);
goog.exportSymbol('proto.NumberFilter', null, global);
goog.exportSymbol('proto.StartDateFilter', null, global);
goog.exportSymbol('proto.StringFilter', null, global);
goog.exportSymbol('proto.TabConfig', null, global);
goog.exportSymbol('proto.TabConfigField', null, global);
goog.exportSymbol('proto.TabFilter', null, global);
goog.exportSymbol('proto.TabFilterConfigs', null, global);
goog.exportSymbol('proto.TabSortConfig', null, global);
goog.exportSymbol('proto.TextFilter', null, global);
goog.exportSymbol('proto.TicketFieldOneOfFilter', null, global);
goog.exportSymbol('proto.TicketFieldOneOfFilter.FilterCase', null, global);
goog.exportSymbol('proto.UpdateNewTabConfigRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TabConfigField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TabConfigField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TabConfigField.displayName = 'proto.TabConfigField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TabFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TabFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TabFilter.displayName = 'proto.TabFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TabFilterConfigs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TabFilterConfigs.repeatedFields_, null);
};
goog.inherits(proto.TabFilterConfigs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TabFilterConfigs.displayName = 'proto.TabFilterConfigs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TabSortConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TabSortConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TabSortConfig.displayName = 'proto.TabSortConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TabConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TabConfig.repeatedFields_, null);
};
goog.inherits(proto.TabConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TabConfig.displayName = 'proto.TabConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StringFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.StringFilter.repeatedFields_, null);
};
goog.inherits(proto.StringFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StringFilter.displayName = 'proto.StringFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CheckboxFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CheckboxFilter.repeatedFields_, null);
};
goog.inherits(proto.CheckboxFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CheckboxFilter.displayName = 'proto.CheckboxFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NumberFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NumberFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NumberFilter.displayName = 'proto.NumberFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MaxNumberFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MaxNumberFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MaxNumberFilter.displayName = 'proto.MaxNumberFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MinNumberFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MinNumberFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MinNumberFilter.displayName = 'proto.MinNumberFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DateFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DateFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DateFilter.displayName = 'proto.DateFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StartDateFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StartDateFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StartDateFilter.displayName = 'proto.StartDateFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EndDateFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EndDateFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EndDateFilter.displayName = 'proto.EndDateFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TextFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TextFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TextFilter.displayName = 'proto.TextFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketFieldOneOfFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.TicketFieldOneOfFilter.oneofGroups_);
};
goog.inherits(proto.TicketFieldOneOfFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketFieldOneOfFilter.displayName = 'proto.TicketFieldOneOfFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetNewTabConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetNewTabConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetNewTabConfigRequest.displayName = 'proto.GetNewTabConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateNewTabConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateNewTabConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateNewTabConfigRequest.displayName = 'proto.UpdateNewTabConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateNewTabConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateNewTabConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateNewTabConfigRequest.displayName = 'proto.CreateNewTabConfigRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TabConfigField.prototype.toObject = function(opt_includeInstance) {
  return proto.TabConfigField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TabConfigField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TabConfigField.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ticketCustomFieldId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TabConfigField}
 */
proto.TabConfigField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TabConfigField;
  return proto.TabConfigField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TabConfigField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TabConfigField}
 */
proto.TabConfigField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketCustomFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TabConfigField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TabConfigField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TabConfigField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TabConfigField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.TabConfigField.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TabConfigField} returns this
 */
proto.TabConfigField.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 ticket_custom_field_id = 2;
 * @return {number}
 */
proto.TabConfigField.prototype.getTicketCustomFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.TabConfigField} returns this
 */
proto.TabConfigField.prototype.setTicketCustomFieldId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TabConfigField} returns this
 */
proto.TabConfigField.prototype.clearTicketCustomFieldId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TabConfigField.prototype.hasTicketCustomFieldId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TabFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.TabFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TabFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TabFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketFieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ticketFieldOneOfFilter: (f = msg.getTicketFieldOneOfFilter()) && proto.TicketFieldOneOfFilter.toObject(includeInstance, f),
    ticketCustomFieldId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TabFilter}
 */
proto.TabFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TabFilter;
  return proto.TabFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TabFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TabFilter}
 */
proto.TabFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketFieldName(value);
      break;
    case 2:
      var value = new proto.TicketFieldOneOfFilter;
      reader.readMessage(value,proto.TicketFieldOneOfFilter.deserializeBinaryFromReader);
      msg.setTicketFieldOneOfFilter(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketCustomFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TabFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TabFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TabFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TabFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTicketFieldOneOfFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.TicketFieldOneOfFilter.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string ticket_field_name = 1;
 * @return {string}
 */
proto.TabFilter.prototype.getTicketFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TabFilter} returns this
 */
proto.TabFilter.prototype.setTicketFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TicketFieldOneOfFilter ticket_field_one_of_filter = 2;
 * @return {?proto.TicketFieldOneOfFilter}
 */
proto.TabFilter.prototype.getTicketFieldOneOfFilter = function() {
  return /** @type{?proto.TicketFieldOneOfFilter} */ (
    jspb.Message.getWrapperField(this, proto.TicketFieldOneOfFilter, 2));
};


/**
 * @param {?proto.TicketFieldOneOfFilter|undefined} value
 * @return {!proto.TabFilter} returns this
*/
proto.TabFilter.prototype.setTicketFieldOneOfFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TabFilter} returns this
 */
proto.TabFilter.prototype.clearTicketFieldOneOfFilter = function() {
  return this.setTicketFieldOneOfFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TabFilter.prototype.hasTicketFieldOneOfFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 ticket_custom_field_id = 3;
 * @return {number}
 */
proto.TabFilter.prototype.getTicketCustomFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.TabFilter} returns this
 */
proto.TabFilter.prototype.setTicketCustomFieldId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TabFilter} returns this
 */
proto.TabFilter.prototype.clearTicketCustomFieldId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TabFilter.prototype.hasTicketCustomFieldId = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TabFilterConfigs.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TabFilterConfigs.prototype.toObject = function(opt_includeInstance) {
  return proto.TabFilterConfigs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TabFilterConfigs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TabFilterConfigs.toObject = function(includeInstance, msg) {
  var f, obj = {
    tabFiltersList: jspb.Message.toObjectList(msg.getTabFiltersList(),
    proto.TabFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TabFilterConfigs}
 */
proto.TabFilterConfigs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TabFilterConfigs;
  return proto.TabFilterConfigs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TabFilterConfigs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TabFilterConfigs}
 */
proto.TabFilterConfigs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TabFilter;
      reader.readMessage(value,proto.TabFilter.deserializeBinaryFromReader);
      msg.addTabFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TabFilterConfigs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TabFilterConfigs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TabFilterConfigs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TabFilterConfigs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTabFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.TabFilter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TabFilter tab_filters = 1;
 * @return {!Array<!proto.TabFilter>}
 */
proto.TabFilterConfigs.prototype.getTabFiltersList = function() {
  return /** @type{!Array<!proto.TabFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TabFilter, 1));
};


/**
 * @param {!Array<!proto.TabFilter>} value
 * @return {!proto.TabFilterConfigs} returns this
*/
proto.TabFilterConfigs.prototype.setTabFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TabFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TabFilter}
 */
proto.TabFilterConfigs.prototype.addTabFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TabFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TabFilterConfigs} returns this
 */
proto.TabFilterConfigs.prototype.clearTabFiltersList = function() {
  return this.setTabFiltersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TabSortConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.TabSortConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TabSortConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TabSortConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    isAsc: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    ticketFieldName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ticketCustomFieldId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TabSortConfig}
 */
proto.TabSortConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TabSortConfig;
  return proto.TabSortConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TabSortConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TabSortConfig}
 */
proto.TabSortConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAsc(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketFieldName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketCustomFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TabSortConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TabSortConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TabSortConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TabSortConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsAsc();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getTicketFieldName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional bool is_asc = 1;
 * @return {boolean}
 */
proto.TabSortConfig.prototype.getIsAsc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.TabSortConfig} returns this
 */
proto.TabSortConfig.prototype.setIsAsc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string ticket_field_name = 2;
 * @return {string}
 */
proto.TabSortConfig.prototype.getTicketFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TabSortConfig} returns this
 */
proto.TabSortConfig.prototype.setTicketFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 ticket_custom_field_id = 3;
 * @return {number}
 */
proto.TabSortConfig.prototype.getTicketCustomFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.TabSortConfig} returns this
 */
proto.TabSortConfig.prototype.setTicketCustomFieldId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TabSortConfig} returns this
 */
proto.TabSortConfig.prototype.clearTicketCustomFieldId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TabSortConfig.prototype.hasTicketCustomFieldId = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TabConfig.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TabConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.TabConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TabConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TabConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    tabFilterConfigs: (f = msg.getTabFilterConfigs()) && proto.TabFilterConfigs.toObject(includeInstance, f),
    tabConfigFieldsList: jspb.Message.toObjectList(msg.getTabConfigFieldsList(),
    proto.TabConfigField.toObject, includeInstance),
    tabSortConfig: (f = msg.getTabSortConfig()) && proto.TabSortConfig.toObject(includeInstance, f),
    isDefaultTab: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TabConfig}
 */
proto.TabConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TabConfig;
  return proto.TabConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TabConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TabConfig}
 */
proto.TabConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TabFilterConfigs;
      reader.readMessage(value,proto.TabFilterConfigs.deserializeBinaryFromReader);
      msg.setTabFilterConfigs(value);
      break;
    case 2:
      var value = new proto.TabConfigField;
      reader.readMessage(value,proto.TabConfigField.deserializeBinaryFromReader);
      msg.addTabConfigFields(value);
      break;
    case 3:
      var value = new proto.TabSortConfig;
      reader.readMessage(value,proto.TabSortConfig.deserializeBinaryFromReader);
      msg.setTabSortConfig(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefaultTab(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TabConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TabConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TabConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TabConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTabFilterConfigs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.TabFilterConfigs.serializeBinaryToWriter
    );
  }
  f = message.getTabConfigFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.TabConfigField.serializeBinaryToWriter
    );
  }
  f = message.getTabSortConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.TabSortConfig.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional TabFilterConfigs tab_filter_configs = 1;
 * @return {?proto.TabFilterConfigs}
 */
proto.TabConfig.prototype.getTabFilterConfigs = function() {
  return /** @type{?proto.TabFilterConfigs} */ (
    jspb.Message.getWrapperField(this, proto.TabFilterConfigs, 1));
};


/**
 * @param {?proto.TabFilterConfigs|undefined} value
 * @return {!proto.TabConfig} returns this
*/
proto.TabConfig.prototype.setTabFilterConfigs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TabConfig} returns this
 */
proto.TabConfig.prototype.clearTabFilterConfigs = function() {
  return this.setTabFilterConfigs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TabConfig.prototype.hasTabFilterConfigs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TabConfigField tab_config_fields = 2;
 * @return {!Array<!proto.TabConfigField>}
 */
proto.TabConfig.prototype.getTabConfigFieldsList = function() {
  return /** @type{!Array<!proto.TabConfigField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TabConfigField, 2));
};


/**
 * @param {!Array<!proto.TabConfigField>} value
 * @return {!proto.TabConfig} returns this
*/
proto.TabConfig.prototype.setTabConfigFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.TabConfigField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TabConfigField}
 */
proto.TabConfig.prototype.addTabConfigFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.TabConfigField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TabConfig} returns this
 */
proto.TabConfig.prototype.clearTabConfigFieldsList = function() {
  return this.setTabConfigFieldsList([]);
};


/**
 * optional TabSortConfig tab_sort_config = 3;
 * @return {?proto.TabSortConfig}
 */
proto.TabConfig.prototype.getTabSortConfig = function() {
  return /** @type{?proto.TabSortConfig} */ (
    jspb.Message.getWrapperField(this, proto.TabSortConfig, 3));
};


/**
 * @param {?proto.TabSortConfig|undefined} value
 * @return {!proto.TabConfig} returns this
*/
proto.TabConfig.prototype.setTabSortConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TabConfig} returns this
 */
proto.TabConfig.prototype.clearTabSortConfig = function() {
  return this.setTabSortConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TabConfig.prototype.hasTabSortConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_default_tab = 4;
 * @return {boolean}
 */
proto.TabConfig.prototype.getIsDefaultTab = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.TabConfig} returns this
 */
proto.TabConfig.prototype.setIsDefaultTab = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TabConfig} returns this
 */
proto.TabConfig.prototype.clearIsDefaultTab = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TabConfig.prototype.hasIsDefaultTab = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.StringFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StringFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.StringFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StringFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StringFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StringFilter}
 */
proto.StringFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StringFilter;
  return proto.StringFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StringFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StringFilter}
 */
proto.StringFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StringFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StringFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StringFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StringFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string values = 1;
 * @return {!Array<string>}
 */
proto.StringFilter.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.StringFilter} returns this
 */
proto.StringFilter.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.StringFilter} returns this
 */
proto.StringFilter.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.StringFilter} returns this
 */
proto.StringFilter.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CheckboxFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CheckboxFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.CheckboxFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CheckboxFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CheckboxFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CheckboxFilter}
 */
proto.CheckboxFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CheckboxFilter;
  return proto.CheckboxFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CheckboxFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CheckboxFilter}
 */
proto.CheckboxFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addValues(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CheckboxFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CheckboxFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CheckboxFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CheckboxFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
};


/**
 * repeated double values = 1;
 * @return {!Array<number>}
 */
proto.CheckboxFilter.prototype.getValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.CheckboxFilter} returns this
 */
proto.CheckboxFilter.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.CheckboxFilter} returns this
 */
proto.CheckboxFilter.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CheckboxFilter} returns this
 */
proto.CheckboxFilter.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NumberFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.NumberFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NumberFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NumberFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    minValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    maxValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NumberFilter}
 */
proto.NumberFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NumberFilter;
  return proto.NumberFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NumberFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NumberFilter}
 */
proto.NumberFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NumberFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NumberFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NumberFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NumberFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double min_value = 1;
 * @return {number}
 */
proto.NumberFilter.prototype.getMinValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NumberFilter} returns this
 */
proto.NumberFilter.prototype.setMinValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.NumberFilter} returns this
 */
proto.NumberFilter.prototype.clearMinValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NumberFilter.prototype.hasMinValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double max_value = 2;
 * @return {number}
 */
proto.NumberFilter.prototype.getMaxValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NumberFilter} returns this
 */
proto.NumberFilter.prototype.setMaxValue = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.NumberFilter} returns this
 */
proto.NumberFilter.prototype.clearMaxValue = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NumberFilter.prototype.hasMaxValue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MaxNumberFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.MaxNumberFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MaxNumberFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MaxNumberFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MaxNumberFilter}
 */
proto.MaxNumberFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MaxNumberFilter;
  return proto.MaxNumberFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MaxNumberFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MaxNumberFilter}
 */
proto.MaxNumberFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MaxNumberFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MaxNumberFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MaxNumberFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MaxNumberFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.MaxNumberFilter.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MaxNumberFilter} returns this
 */
proto.MaxNumberFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MinNumberFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.MinNumberFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MinNumberFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MinNumberFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MinNumberFilter}
 */
proto.MinNumberFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MinNumberFilter;
  return proto.MinNumberFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MinNumberFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MinNumberFilter}
 */
proto.MinNumberFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MinNumberFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MinNumberFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MinNumberFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MinNumberFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.MinNumberFilter.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MinNumberFilter} returns this
 */
proto.MinNumberFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DateFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.DateFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DateFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DateFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    startDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DateFilter}
 */
proto.DateFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DateFilter;
  return proto.DateFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DateFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DateFilter}
 */
proto.DateFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DateFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DateFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DateFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DateFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string start_date = 1;
 * @return {string}
 */
proto.DateFilter.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DateFilter} returns this
 */
proto.DateFilter.prototype.setStartDate = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.DateFilter} returns this
 */
proto.DateFilter.prototype.clearStartDate = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DateFilter.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string end_date = 2;
 * @return {string}
 */
proto.DateFilter.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DateFilter} returns this
 */
proto.DateFilter.prototype.setEndDate = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.DateFilter} returns this
 */
proto.DateFilter.prototype.clearEndDate = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DateFilter.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StartDateFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.StartDateFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StartDateFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StartDateFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StartDateFilter}
 */
proto.StartDateFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StartDateFilter;
  return proto.StartDateFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StartDateFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StartDateFilter}
 */
proto.StartDateFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StartDateFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StartDateFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StartDateFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StartDateFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.StartDateFilter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.StartDateFilter} returns this
 */
proto.StartDateFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EndDateFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.EndDateFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EndDateFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EndDateFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EndDateFilter}
 */
proto.EndDateFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EndDateFilter;
  return proto.EndDateFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EndDateFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EndDateFilter}
 */
proto.EndDateFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EndDateFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EndDateFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EndDateFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EndDateFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.EndDateFilter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EndDateFilter} returns this
 */
proto.EndDateFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TextFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.TextFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TextFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TextFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    stringValue: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TextFilter}
 */
proto.TextFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TextFilter;
  return proto.TextFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TextFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TextFilter}
 */
proto.TextFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TextFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TextFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TextFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TextFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStringValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string string_value = 1;
 * @return {string}
 */
proto.TextFilter.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TextFilter} returns this
 */
proto.TextFilter.prototype.setStringValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.TicketFieldOneOfFilter.oneofGroups_ = [[1,2,3,4,5,6,7,8,9]];

/**
 * @enum {number}
 */
proto.TicketFieldOneOfFilter.FilterCase = {
  FILTER_NOT_SET: 0,
  STRING_FILTER: 1,
  TEXT_FILTER: 2,
  NUMBER_FILTER: 3,
  CHECKBOX_FILTER: 4,
  DATE_FILTER: 5,
  MAX_NUMBER_FILTER: 6,
  MIN_NUMBER_FILTER: 7,
  START_DATE_FILTER: 8,
  END_DATE_FILTER: 9
};

/**
 * @return {proto.TicketFieldOneOfFilter.FilterCase}
 */
proto.TicketFieldOneOfFilter.prototype.getFilterCase = function() {
  return /** @type {proto.TicketFieldOneOfFilter.FilterCase} */(jspb.Message.computeOneofCase(this, proto.TicketFieldOneOfFilter.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketFieldOneOfFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketFieldOneOfFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketFieldOneOfFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketFieldOneOfFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    stringFilter: (f = msg.getStringFilter()) && proto.StringFilter.toObject(includeInstance, f),
    textFilter: (f = msg.getTextFilter()) && proto.TextFilter.toObject(includeInstance, f),
    numberFilter: (f = msg.getNumberFilter()) && proto.NumberFilter.toObject(includeInstance, f),
    checkboxFilter: (f = msg.getCheckboxFilter()) && proto.CheckboxFilter.toObject(includeInstance, f),
    dateFilter: (f = msg.getDateFilter()) && proto.DateFilter.toObject(includeInstance, f),
    maxNumberFilter: (f = msg.getMaxNumberFilter()) && proto.MaxNumberFilter.toObject(includeInstance, f),
    minNumberFilter: (f = msg.getMinNumberFilter()) && proto.MinNumberFilter.toObject(includeInstance, f),
    startDateFilter: (f = msg.getStartDateFilter()) && proto.StartDateFilter.toObject(includeInstance, f),
    endDateFilter: (f = msg.getEndDateFilter()) && proto.EndDateFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketFieldOneOfFilter}
 */
proto.TicketFieldOneOfFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketFieldOneOfFilter;
  return proto.TicketFieldOneOfFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketFieldOneOfFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketFieldOneOfFilter}
 */
proto.TicketFieldOneOfFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.StringFilter;
      reader.readMessage(value,proto.StringFilter.deserializeBinaryFromReader);
      msg.setStringFilter(value);
      break;
    case 2:
      var value = new proto.TextFilter;
      reader.readMessage(value,proto.TextFilter.deserializeBinaryFromReader);
      msg.setTextFilter(value);
      break;
    case 3:
      var value = new proto.NumberFilter;
      reader.readMessage(value,proto.NumberFilter.deserializeBinaryFromReader);
      msg.setNumberFilter(value);
      break;
    case 4:
      var value = new proto.CheckboxFilter;
      reader.readMessage(value,proto.CheckboxFilter.deserializeBinaryFromReader);
      msg.setCheckboxFilter(value);
      break;
    case 5:
      var value = new proto.DateFilter;
      reader.readMessage(value,proto.DateFilter.deserializeBinaryFromReader);
      msg.setDateFilter(value);
      break;
    case 6:
      var value = new proto.MaxNumberFilter;
      reader.readMessage(value,proto.MaxNumberFilter.deserializeBinaryFromReader);
      msg.setMaxNumberFilter(value);
      break;
    case 7:
      var value = new proto.MinNumberFilter;
      reader.readMessage(value,proto.MinNumberFilter.deserializeBinaryFromReader);
      msg.setMinNumberFilter(value);
      break;
    case 8:
      var value = new proto.StartDateFilter;
      reader.readMessage(value,proto.StartDateFilter.deserializeBinaryFromReader);
      msg.setStartDateFilter(value);
      break;
    case 9:
      var value = new proto.EndDateFilter;
      reader.readMessage(value,proto.EndDateFilter.deserializeBinaryFromReader);
      msg.setEndDateFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketFieldOneOfFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketFieldOneOfFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketFieldOneOfFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketFieldOneOfFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStringFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.StringFilter.serializeBinaryToWriter
    );
  }
  f = message.getTextFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.TextFilter.serializeBinaryToWriter
    );
  }
  f = message.getNumberFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.NumberFilter.serializeBinaryToWriter
    );
  }
  f = message.getCheckboxFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.CheckboxFilter.serializeBinaryToWriter
    );
  }
  f = message.getDateFilter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.DateFilter.serializeBinaryToWriter
    );
  }
  f = message.getMaxNumberFilter();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.MaxNumberFilter.serializeBinaryToWriter
    );
  }
  f = message.getMinNumberFilter();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.MinNumberFilter.serializeBinaryToWriter
    );
  }
  f = message.getStartDateFilter();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.StartDateFilter.serializeBinaryToWriter
    );
  }
  f = message.getEndDateFilter();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.EndDateFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional StringFilter string_filter = 1;
 * @return {?proto.StringFilter}
 */
proto.TicketFieldOneOfFilter.prototype.getStringFilter = function() {
  return /** @type{?proto.StringFilter} */ (
    jspb.Message.getWrapperField(this, proto.StringFilter, 1));
};


/**
 * @param {?proto.StringFilter|undefined} value
 * @return {!proto.TicketFieldOneOfFilter} returns this
*/
proto.TicketFieldOneOfFilter.prototype.setStringFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.TicketFieldOneOfFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketFieldOneOfFilter} returns this
 */
proto.TicketFieldOneOfFilter.prototype.clearStringFilter = function() {
  return this.setStringFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketFieldOneOfFilter.prototype.hasStringFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TextFilter text_filter = 2;
 * @return {?proto.TextFilter}
 */
proto.TicketFieldOneOfFilter.prototype.getTextFilter = function() {
  return /** @type{?proto.TextFilter} */ (
    jspb.Message.getWrapperField(this, proto.TextFilter, 2));
};


/**
 * @param {?proto.TextFilter|undefined} value
 * @return {!proto.TicketFieldOneOfFilter} returns this
*/
proto.TicketFieldOneOfFilter.prototype.setTextFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.TicketFieldOneOfFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketFieldOneOfFilter} returns this
 */
proto.TicketFieldOneOfFilter.prototype.clearTextFilter = function() {
  return this.setTextFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketFieldOneOfFilter.prototype.hasTextFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NumberFilter number_filter = 3;
 * @return {?proto.NumberFilter}
 */
proto.TicketFieldOneOfFilter.prototype.getNumberFilter = function() {
  return /** @type{?proto.NumberFilter} */ (
    jspb.Message.getWrapperField(this, proto.NumberFilter, 3));
};


/**
 * @param {?proto.NumberFilter|undefined} value
 * @return {!proto.TicketFieldOneOfFilter} returns this
*/
proto.TicketFieldOneOfFilter.prototype.setNumberFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.TicketFieldOneOfFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketFieldOneOfFilter} returns this
 */
proto.TicketFieldOneOfFilter.prototype.clearNumberFilter = function() {
  return this.setNumberFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketFieldOneOfFilter.prototype.hasNumberFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CheckboxFilter checkbox_filter = 4;
 * @return {?proto.CheckboxFilter}
 */
proto.TicketFieldOneOfFilter.prototype.getCheckboxFilter = function() {
  return /** @type{?proto.CheckboxFilter} */ (
    jspb.Message.getWrapperField(this, proto.CheckboxFilter, 4));
};


/**
 * @param {?proto.CheckboxFilter|undefined} value
 * @return {!proto.TicketFieldOneOfFilter} returns this
*/
proto.TicketFieldOneOfFilter.prototype.setCheckboxFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.TicketFieldOneOfFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketFieldOneOfFilter} returns this
 */
proto.TicketFieldOneOfFilter.prototype.clearCheckboxFilter = function() {
  return this.setCheckboxFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketFieldOneOfFilter.prototype.hasCheckboxFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DateFilter date_filter = 5;
 * @return {?proto.DateFilter}
 */
proto.TicketFieldOneOfFilter.prototype.getDateFilter = function() {
  return /** @type{?proto.DateFilter} */ (
    jspb.Message.getWrapperField(this, proto.DateFilter, 5));
};


/**
 * @param {?proto.DateFilter|undefined} value
 * @return {!proto.TicketFieldOneOfFilter} returns this
*/
proto.TicketFieldOneOfFilter.prototype.setDateFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.TicketFieldOneOfFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketFieldOneOfFilter} returns this
 */
proto.TicketFieldOneOfFilter.prototype.clearDateFilter = function() {
  return this.setDateFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketFieldOneOfFilter.prototype.hasDateFilter = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional MaxNumberFilter max_number_filter = 6;
 * @return {?proto.MaxNumberFilter}
 */
proto.TicketFieldOneOfFilter.prototype.getMaxNumberFilter = function() {
  return /** @type{?proto.MaxNumberFilter} */ (
    jspb.Message.getWrapperField(this, proto.MaxNumberFilter, 6));
};


/**
 * @param {?proto.MaxNumberFilter|undefined} value
 * @return {!proto.TicketFieldOneOfFilter} returns this
*/
proto.TicketFieldOneOfFilter.prototype.setMaxNumberFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.TicketFieldOneOfFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketFieldOneOfFilter} returns this
 */
proto.TicketFieldOneOfFilter.prototype.clearMaxNumberFilter = function() {
  return this.setMaxNumberFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketFieldOneOfFilter.prototype.hasMaxNumberFilter = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MinNumberFilter min_number_filter = 7;
 * @return {?proto.MinNumberFilter}
 */
proto.TicketFieldOneOfFilter.prototype.getMinNumberFilter = function() {
  return /** @type{?proto.MinNumberFilter} */ (
    jspb.Message.getWrapperField(this, proto.MinNumberFilter, 7));
};


/**
 * @param {?proto.MinNumberFilter|undefined} value
 * @return {!proto.TicketFieldOneOfFilter} returns this
*/
proto.TicketFieldOneOfFilter.prototype.setMinNumberFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.TicketFieldOneOfFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketFieldOneOfFilter} returns this
 */
proto.TicketFieldOneOfFilter.prototype.clearMinNumberFilter = function() {
  return this.setMinNumberFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketFieldOneOfFilter.prototype.hasMinNumberFilter = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional StartDateFilter start_date_filter = 8;
 * @return {?proto.StartDateFilter}
 */
proto.TicketFieldOneOfFilter.prototype.getStartDateFilter = function() {
  return /** @type{?proto.StartDateFilter} */ (
    jspb.Message.getWrapperField(this, proto.StartDateFilter, 8));
};


/**
 * @param {?proto.StartDateFilter|undefined} value
 * @return {!proto.TicketFieldOneOfFilter} returns this
*/
proto.TicketFieldOneOfFilter.prototype.setStartDateFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.TicketFieldOneOfFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketFieldOneOfFilter} returns this
 */
proto.TicketFieldOneOfFilter.prototype.clearStartDateFilter = function() {
  return this.setStartDateFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketFieldOneOfFilter.prototype.hasStartDateFilter = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EndDateFilter end_date_filter = 9;
 * @return {?proto.EndDateFilter}
 */
proto.TicketFieldOneOfFilter.prototype.getEndDateFilter = function() {
  return /** @type{?proto.EndDateFilter} */ (
    jspb.Message.getWrapperField(this, proto.EndDateFilter, 9));
};


/**
 * @param {?proto.EndDateFilter|undefined} value
 * @return {!proto.TicketFieldOneOfFilter} returns this
*/
proto.TicketFieldOneOfFilter.prototype.setEndDateFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.TicketFieldOneOfFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketFieldOneOfFilter} returns this
 */
proto.TicketFieldOneOfFilter.prototype.clearEndDateFilter = function() {
  return this.setEndDateFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketFieldOneOfFilter.prototype.hasEndDateFilter = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetNewTabConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetNewTabConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetNewTabConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetNewTabConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tabUuid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetNewTabConfigRequest}
 */
proto.GetNewTabConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetNewTabConfigRequest;
  return proto.GetNewTabConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetNewTabConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetNewTabConfigRequest}
 */
proto.GetNewTabConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTabUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetNewTabConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetNewTabConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetNewTabConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetNewTabConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTabUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tab_uuid = 1;
 * @return {string}
 */
proto.GetNewTabConfigRequest.prototype.getTabUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetNewTabConfigRequest} returns this
 */
proto.GetNewTabConfigRequest.prototype.setTabUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateNewTabConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateNewTabConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateNewTabConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateNewTabConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tabUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tabConfig: (f = msg.getTabConfig()) && proto.TabConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateNewTabConfigRequest}
 */
proto.UpdateNewTabConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateNewTabConfigRequest;
  return proto.UpdateNewTabConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateNewTabConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateNewTabConfigRequest}
 */
proto.UpdateNewTabConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTabUuid(value);
      break;
    case 2:
      var value = new proto.TabConfig;
      reader.readMessage(value,proto.TabConfig.deserializeBinaryFromReader);
      msg.setTabConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateNewTabConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateNewTabConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateNewTabConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateNewTabConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTabUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTabConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.TabConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tab_uuid = 1;
 * @return {string}
 */
proto.UpdateNewTabConfigRequest.prototype.getTabUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateNewTabConfigRequest} returns this
 */
proto.UpdateNewTabConfigRequest.prototype.setTabUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TabConfig tab_config = 2;
 * @return {?proto.TabConfig}
 */
proto.UpdateNewTabConfigRequest.prototype.getTabConfig = function() {
  return /** @type{?proto.TabConfig} */ (
    jspb.Message.getWrapperField(this, proto.TabConfig, 2));
};


/**
 * @param {?proto.TabConfig|undefined} value
 * @return {!proto.UpdateNewTabConfigRequest} returns this
*/
proto.UpdateNewTabConfigRequest.prototype.setTabConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateNewTabConfigRequest} returns this
 */
proto.UpdateNewTabConfigRequest.prototype.clearTabConfig = function() {
  return this.setTabConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateNewTabConfigRequest.prototype.hasTabConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateNewTabConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateNewTabConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateNewTabConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateNewTabConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tabUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tabConfig: (f = msg.getTabConfig()) && proto.TabConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateNewTabConfigRequest}
 */
proto.CreateNewTabConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateNewTabConfigRequest;
  return proto.CreateNewTabConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateNewTabConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateNewTabConfigRequest}
 */
proto.CreateNewTabConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTabUuid(value);
      break;
    case 2:
      var value = new proto.TabConfig;
      reader.readMessage(value,proto.TabConfig.deserializeBinaryFromReader);
      msg.setTabConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateNewTabConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateNewTabConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateNewTabConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateNewTabConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTabUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTabConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.TabConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tab_uuid = 1;
 * @return {string}
 */
proto.CreateNewTabConfigRequest.prototype.getTabUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateNewTabConfigRequest} returns this
 */
proto.CreateNewTabConfigRequest.prototype.setTabUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TabConfig tab_config = 2;
 * @return {?proto.TabConfig}
 */
proto.CreateNewTabConfigRequest.prototype.getTabConfig = function() {
  return /** @type{?proto.TabConfig} */ (
    jspb.Message.getWrapperField(this, proto.TabConfig, 2));
};


/**
 * @param {?proto.TabConfig|undefined} value
 * @return {!proto.CreateNewTabConfigRequest} returns this
*/
proto.CreateNewTabConfigRequest.prototype.setTabConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateNewTabConfigRequest} returns this
 */
proto.CreateNewTabConfigRequest.prototype.clearTabConfig = function() {
  return this.setTabConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateNewTabConfigRequest.prototype.hasTabConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto);
