export type ImageTag = `<img${string}src="${string}"${string}>`

export const searchImageTags = (str: string): null | ImageTag[] =>
  str.match(/<img.*?src=".*?".*?>/g) as null | ImageTag[]

export const searchSrcFromImageTag = (str: ImageTag): string | null => {
  const result = str.match(/src="(.*)"/)
  if (!result) return null
  if (!result[1]) return null
  return result[1]
}
