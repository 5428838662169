import { Dialog } from '@fluentui/react-northstar'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import PageViewLog from '../../components/PageViewLog'
import { PageName } from '../../consts'
import TenantAppCSVExportDialogContent from './TenantAppCSVExportDialogContent'
import { closeFetchExportLogs, fetchExportLogs } from './tenantAppCSVSlice'
import styles from './TicketList.module.css'

interface Props {
  open?: boolean
  onCancel: () => void
}

const TenantAppCSVExportDialog: React.FC<Props> = ({ open, onCancel }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!open) {
      closeFetchExportLogs()
      return
    }

    dispatch(fetchExportLogs())
    return () => {
      closeFetchExportLogs()
    }
  }, [dispatch, open])

  return (
    <PageViewLog
      pageName={PageName.TenantAppCSVExportDialog}
      isOpenable
      isOpen={open}
    >
      <Dialog
        open={open}
        onCancel={onCancel}
        content={<TenantAppCSVExportDialogContent />}
        header="全データのエクスポート"
        className={styles.csvExportDialogOuter}
      />
    </PageViewLog>
  )
}

export default React.memo(TenantAppCSVExportDialog)
