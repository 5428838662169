import {
  Box,
  Dialog,
  Dropdown,
  DropdownProps,
  Input,
} from '@fluentui/react-northstar'
import Editor from '@monaco-editor/react'
import { editor } from 'monaco-editor'
import * as React from 'react'

export const Languages = {
  Text: 'plaintext',
  'ASP.NET': 'csharp',
  C: 'c',
  'C++': 'cpp',
  CSS: 'css',
  'C#': 'csharp',
  Clojure: 'clojure',
  CoffeeScript: 'coffeescript',
  Dart: 'dart',
  Django: 'python',
  'Docker File': 'dockerfile',
  Elm: 'elm',
  'F#': 'fsharp',
  Go: 'go',
  HTML: 'html',
  Handlebars: 'handlebars',
  Haskell: 'haskell',
  JSX: 'javascript',
  Java: 'java',
  JavaScript: 'javascript',
  JSON: 'json',
  Kotlin: 'kotlin',
  LESS: 'less',
  LISP: 'lisp',
  Lua: 'lua',
  'Markdown (GitHub)': 'markdown',
  OCaml: 'ocaml',
  'Objective C': 'objective-c',
  PHP: 'php',
  Perl: 'perl',
  PowerShell: 'powershell',
  Python: 'python',
  R: 'r',
  Ruby: 'ruby',
  Rust: 'rust',
  SCSS: 'scss',
  SQL: 'sql',
  Scala: 'scala',
  Shell: 'shell',
  Swift: 'swift',
  TypeScript: 'typescript',
  VB: 'vb',
  XML: 'xml',
  YAML: 'yaml',
  // 以下 monaco 非対応
  Ceylon: 'ceylon',
  D: 'd',
  EJS: 'ejs',
  HAML: 'haml',
  HTTP: 'http',
  JSP: 'jsp',
  MATLAB: 'matlab',
  'Pig (Apache)': 'pig',
  Squirrel: 'squirrel',
  VBScript: 'vbscript',
} as const

type Props = {
  defaultTitle?: string
  defaultValue?: string
  defaultLanguage?: typeof Languages[keyof typeof Languages]
  trigger: React.ReactElement
  onSubmit: (title: string, value: string, language: string) => void
}
const RequesterChatCodeblockEdit: React.FC<Props> = (props) => {
  const [title, setTitle] = React.useState(props.defaultTitle ?? '')
  const [value, setValue] = React.useState(props.defaultValue ?? '')
  const [language, setLanguage] = React.useState<
    typeof Languages[keyof typeof Languages]
  >(props.defaultLanguage ?? Languages.Text)

  const submit = React.useCallback(() => {
    props.onSubmit(title, value, language)
    setValue('')
    setTitle('')
    setLanguage('plaintext')
  }, [title, value, props, language])

  return (
    <Dialog
      cancelButton="キャンセル"
      confirmButton="挿入"
      onConfirm={submit}
      trigger={props.trigger}
      header="コードを編集"
      content={
        <Content
          value={value}
          title={title}
          language={language}
          onChangeTitle={setTitle}
          onChangeValue={setValue}
          onChangeLanguage={setLanguage}
        />
      }
    />
  )
}

type ContentProps = {
  title: string
  value: string
  language: string
  onChangeTitle: (title: string) => void
  onChangeValue: (value: string) => void
  onChangeLanguage: (lang: typeof Languages[keyof typeof Languages]) => void
}
const Content: React.FC<ContentProps> = (props) => {
  const editorRef = React.useRef<null | editor.IStandaloneCodeEditor>(null)

  const languages = React.useMemo<string[]>(() => {
    return Object.keys(Languages)
  }, [])

  const changeLanguage = React.useCallback(
    (_: React.MouseEvent | React.KeyboardEvent | null, item: DropdownProps) => {
      const langCode = Languages[item.value as keyof typeof Languages]
      props.onChangeLanguage(langCode)
    },
    [props]
  )

  const languageName = React.useMemo<string>(() => {
    return (
      Object.entries(Languages).find(
        ([, value]) => value === props.language
      )?.[0] ?? ''
    )
  }, [props])

  return (
    <>
      <Box styles={{ width: '100%', display: 'flex', columnGap: '8px' }}>
        <Input
          fluid
          placeholder="タイトルを入力"
          value={props.title}
          onChange={(_, p) => props.onChangeTitle(p?.value ?? '')}
        />
        <Dropdown
          fluid
          items={languages}
          value={languageName}
          onChange={changeLanguage}
        />
      </Box>
      <Box styles={{ marginTop: '16px' }}>
        <Editor
          height="40vh"
          language={props.language}
          value={props.value}
          onChange={(v) => props.onChangeValue(v ?? '')}
          onMount={(editor) => (editorRef.current = editor)}
        />
      </Box>
    </>
  )
}

export default React.memo(RequesterChatCodeblockEdit)
