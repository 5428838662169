import * as React from 'react'
import * as ReactRedux from 'react-redux'

import TicketPagenation from '../../features/ticket/TicketPagenation'
import * as Selectors from './selectors'
import * as Slice from './slice'

type Props = {
  isLoading: boolean
  lastPage: number
  total: number
}
const ActivityMessagePlansPaginator: React.FC<Props> = (props) => {
  const dispatch = ReactRedux.useDispatch()
  const page = ReactRedux.useSelector(Selectors.pageSelector)

  const canNextPage = React.useMemo(() => {
    return page < props.lastPage
  }, [page, props.lastPage])
  const canPreviousPage = React.useMemo(() => {
    return page > 1
  }, [page])
  const pageIndex = React.useMemo(() => {
    const result = page - 1
    if (result < 0) {
      return 1
    }
    return result
  }, [page])
  const lastPage = React.useMemo(() => {
    if (props.lastPage < 0) {
      return 1
    }
    return props.lastPage
  }, [props.lastPage])

  const clickFirst = React.useCallback(() => {
    dispatch(Slice.actions.setFirstPage())
  }, [dispatch])
  const clickLast = React.useCallback(() => {
    dispatch(Slice.actions.setLastPage({ lastPage: props.lastPage }))
  }, [dispatch, props.lastPage])
  const clickNext = React.useCallback(() => {
    dispatch(Slice.actions.incrementPage({ lastPage: props.lastPage }))
  }, [dispatch, props.lastPage])
  const clickPrevious = React.useCallback(() => {
    dispatch(Slice.actions.decrementPage())
  }, [dispatch])

  return (
    <TicketPagenation
      loading={props.isLoading}
      totalCount={props.total}
      pageLength={lastPage}
      pageIndex={pageIndex}
      canNextPage={canNextPage}
      canPreviousPage={canPreviousPage}
      gotoPage={() => null}
      onClickFirst={clickFirst}
      onClickLast={clickLast}
      onClickNext={clickNext}
      onClickPrevious={clickPrevious}
    />
  )
}

export default React.memo(ActivityMessagePlansPaginator)
