import { SearchIcon } from '@fluentui/react-icons-northstar'
import { Input } from '@fluentui/react-northstar'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import styles from './TicketFilterSearchText.module.css'
import { setSearch } from './ticketSlice'

const debounceDelay = 500
const debounce = (
  timeoutId: NodeJS.Timeout,
  previousTime: number,
  currentTime: number
) => {
  if (currentTime - previousTime <= debounceDelay) {
    clearTimeout(timeoutId)
  }
}
let doDebounce: (currentTime: number) => void

const TicketFilterSearchText: React.FC = () => {
  const dispatch = useDispatch()
  const ticketState = useSelector((state: RootState) => state.ticket)
  const [inputText, setInputText] = useState(ticketState.filters.search)
  return (
    <Input
      icon={<SearchIcon />}
      fluid
      inverted
      clearable
      value={inputText}
      placeholder="タイトル・送受信メッセージ・テキストフィールドで検索"
      iconPosition="start"
      className={styles.filterSearchText}
      onChange={(_, data) => {
        setInputText(data?.value || '')

        const currentTime = new Date().getTime()
        if (doDebounce != null) {
          doDebounce(currentTime)
        }
        const timeOutId = setTimeout(() => {
          dispatch(setSearch({ search: data?.value || '' }))
        }, debounceDelay)

        doDebounce = debounce.bind(null, timeOutId, currentTime)
      }}
    />
  )
}

export default TicketFilterSearchText
