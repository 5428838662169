import { Dialog, DialogProps } from '@fluentui/react-northstar'
import { CloseIcon } from '@fluentui/react-northstar'
import { FC, useState } from 'react'

const DialogWithCloseIcon: FC<DialogProps> = (props) => {
  const [open, setOpen] = useState(false)
  return (
    <Dialog
      open={open}
      onOpen={() => setOpen(true)}
      onCancel={() => setOpen(false)}
      headerAction={{
        icon: <CloseIcon />,
        title: 'Close',
        onClick: props.onCancel ?? (() => setOpen(false)),
      }}
      {...props}
    />
  )
}

export default DialogWithCloseIcon
