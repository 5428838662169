import * as ActivityMessagePB from '../../proto/activity_message_pb'
import * as ActivityMessageServicePB from '../../proto/activity_message_pb_service'
import { deskOperatorApi } from '../deskApiOperator'

export const ActivityMessagePlanPreviewSidebarAPI =
  deskOperatorApi.injectEndpoints({
    endpoints: (build) => ({
      publishPlan: build.mutation<void, { id: number }>({
        query: (args) => ({
          service:
            ActivityMessageServicePB.ActivityMessageService
              .PublishActivityMessagePlan,
          body: (() => {
            const req =
              new ActivityMessagePB.PublishActivityMessagePlanRequest()
            req.setId(args.id)

            return req
          })(),
        }),
        invalidatesTags: (_, __, arg) => [
          'ActivityMessagePlans',
          { id: arg.id, type: 'ActivityMessagePlanDetail' },
        ],
      }),
      removePlan: build.mutation<void, { id: number }>({
        query: (args) => ({
          service:
            ActivityMessageServicePB.ActivityMessageService
              .DeleteActivityMessagePlan,
          body: (() => {
            const req = new ActivityMessagePB.DeleteActivityMessagePlanRequest()
            req.setId(args.id)

            return req
          })(),
        }),
        invalidatesTags: ['ActivityMessagePlans'],
      }),
    }),
  })

export const { usePublishPlanMutation, useRemovePlanMutation } =
  ActivityMessagePlanPreviewSidebarAPI

export default ActivityMessagePlanPreviewSidebarAPI
