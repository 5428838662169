import { Flex, Input, Text } from '@fluentui/react-northstar'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import ticket_custom_field from '../../../proto/ticket_custom_field_pb'
import { isSafeNumber } from '../../../utils'
import {
  deleteOneCustomFieldFilter,
  setCustomNumberFieldValuesFilter,
} from '../ticketSlice'
import styles from './TicketCustomFieldFilter.module.css'

type Props = {
  customField: ticket_custom_field.TicketCustomField.AsObject
}
const TicketCustomFieldValueNumberFilter: React.FC<Props> = ({
  customField,
}) => {
  const ticketState = useSelector((state: RootState) => state.ticket)

  let numberFilterValue: {
    minValue?: number
    maxValue?: number
  }
  numberFilterValue = {}
  let initialFilterMinValue = ''
  let initialFilterMaxValue = ''
  if (
    ticketState.filters.customFieldValues.numberFilters[customField.id] != null
  ) {
    numberFilterValue =
      ticketState.filters.customFieldValues.numberFilters[customField.id]
    if ('minValue' in numberFilterValue) {
      initialFilterMinValue = String(numberFilterValue.minValue)
    }
    if ('maxValue' in numberFilterValue) {
      initialFilterMaxValue = String(numberFilterValue.maxValue)
    }
  }
  const [minInputNumber, setMinInputNumber] = useState(initialFilterMinValue)
  const [maxInputNumber, setMaxInputNumber] = useState(initialFilterMaxValue)
  const [isError, setIsError] = useState(false)
  const [isInvalidMinNumber, setIsInvalidMinNumber] = useState(false)
  const [isInvalidMaxNumber, setIsInvalidMaxNumber] = useState(false)
  const isMountRef = useRef(true)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isMountRef.current) {
      isMountRef.current = false
      return
    }

    if (
      minInputNumber !== '' &&
      maxInputNumber !== '' &&
      Number(minInputNumber) > Number(maxInputNumber)
    ) {
      setIsError(true)
      return
    } else {
      setIsError(false)
    }

    if (minInputNumber !== '' && !isSafeNumber(Number(minInputNumber))) {
      setIsInvalidMinNumber(true)
    } else {
      setIsInvalidMinNumber(false)
    }

    if (maxInputNumber !== '' && !isSafeNumber(Number(maxInputNumber))) {
      setIsInvalidMaxNumber(true)
    } else {
      setIsInvalidMaxNumber(false)
    }

    const timeOutId = setTimeout(() => {
      const filterArg: {
        customFieldId: number
        minValue?: number
        maxValue?: number
      } = { customFieldId: customField.id }
      // 入力値が空の時はフィルタからkey,valueを削除
      if (minInputNumber === '' && maxInputNumber === '') {
        dispatch(
          deleteOneCustomFieldFilter({
            customFieldId: customField.id,
            customFieldType: customField.type,
          })
        )
        return
      }
      if (minInputNumber !== '') {
        filterArg.minValue = Number(minInputNumber)
      }
      if (maxInputNumber !== '') {
        filterArg.maxValue = Number(maxInputNumber)
      }
      dispatch(setCustomNumberFieldValuesFilter(filterArg))
    }, 300)
    return () => clearTimeout(timeOutId)
  }, [
    dispatch,
    customField.id,
    customField.type,
    minInputNumber,
    maxInputNumber,
  ])
  return (
    <Flex column gap="gap.small" hAlign="start" space="around">
      <Text content="絞り込み" weight="bold" className={styles.headingText} />
      {isError && (
        <Text
          content="最大値は最小値よりも大きくしてください"
          styles={({ theme: { siteVariables } }) => ({
            color: siteVariables.colorScheme.red.foreground,
            fontSize: '12px',
          })}
        />
      )}
      {(isInvalidMinNumber || isInvalidMaxNumber) && (
        <Text
          content="最大桁数を超えています"
          styles={({ theme: { siteVariables } }) => ({
            color: siteVariables.colorScheme.red.foreground,
            fontSize: '12px',
          })}
        />
      )}
      <Flex vAlign="center">
        <Text
          content="最小値"
          weight="bold"
          className={styles.inputAreaHeading}
        />
        <Input
          placeholder="入力する"
          type="number"
          error={isError || isInvalidMinNumber}
          value={String(minInputNumber)}
          onChange={(_, e) => {
            if (e?.value != null) setMinInputNumber(e.value)
          }}
          onBlur={() => {
            if (minInputNumber !== '')
              setMinInputNumber(String(Number(minInputNumber)))
          }}
        />
      </Flex>
      <Flex vAlign="center">
        <Text
          content="最大値"
          weight="bold"
          className={styles.inputAreaHeading}
        />
        <Input
          placeholder="入力する"
          type="number"
          error={isError || isInvalidMaxNumber}
          value={String(maxInputNumber)}
          onChange={(_, e) => {
            if (e?.value != null) setMaxInputNumber(e.value)
          }}
          onBlur={() => {
            if (maxInputNumber !== '')
              setMaxInputNumber(String(Number(maxInputNumber)))
          }}
        />
      </Flex>
    </Flex>
  )
}

export default TicketCustomFieldValueNumberFilter
