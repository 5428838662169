import { Button, Divider, Flex } from '@fluentui/react-northstar'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { RootState } from '../../app/store'
import { createDraftFaq, fetchDraftFaq } from './faqSlice'

const FaqDraft: React.FC = () => {
  const dispatch = useDispatch()
  const { ticketId } = useParams<{ ticketId: string }>()
  const authState = useSelector((state: RootState) => state.auth)
  const ticketState = useSelector((state: RootState) => state.ticket)
  const ticket = ticketState.entities[ticketId]
  const faqState = useSelector((state: RootState) => state.faq)
  const faq = faqState.entities[ticketId]

  React.useEffect(() => {
    // 5秒に一度ポーリングを行い、OpenAIの生成が完了しているか確認する
    const interval = setInterval(() => {
      if (faqState.generateLoading) {
        dispatch(fetchDraftFaq(parseInt(ticketId, 10)))
      } else {
        clearInterval(interval)
      }
    }, 5000)
    return () => clearInterval(interval)
  }, [dispatch, ticketId, faqState.generateLoading])

  return (
    <>
      {authState?.deskAvailableFeatures?.draftFaq && (
        <Flex column gap="gap.small">
          <Button
            content="対話からFAQを自動生成（β）"
            onClick={() => {
              dispatch(createDraftFaq(parseInt(ticketId)))
            }}
            disabled={
              faq != null || ticket?.isManual || faqState.generateLoading
            }
            loading={faqState.generateLoading}
            tinted
          />
          <Divider />
        </Flex>
      )}
    </>
  )
}

export default FaqDraft
