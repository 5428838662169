// namespaceを入れ子にする記法を許可したいのでこのファイルではignoreする
// 1次ソースではないが参考: https://qiita.com/yuki153/items/a51878ad6a1ce913af48
/* eslint @typescript-eslint/no-namespace: 0 */
export enum TicketStatus {
  new = 'new',
  working = 'working',
  pending = 'pending',
  completed = 'completed',
}

export const TicketStatusLabels: Record<TicketStatus, string> = {
  [TicketStatus.new]: '新規',
  [TicketStatus.working]: '対応中',
  [TicketStatus.pending]: '保留中',
  [TicketStatus.completed]: '解決済み',
}

export const TicketStatusColors: Record<TicketStatus, string> = {
  [TicketStatus.new]: 'colorScheme.red.background',
  [TicketStatus.working]: 'colorScheme.brand.background',
  [TicketStatus.pending]: 'naturalColors.grey.400',
  [TicketStatus.completed]: 'colorScheme.default.background3',
}

export enum EntityId {
  Tickets = 'jp.bedore.workplace.desk.tickets',
}

export enum AttachmentType {
  onedrive = 'onedrive',
  textHTML = 'text_html',
}

export namespace Tickets {
  export namespace Users {
    export const UserNotIndicated = '(未設定)'
    export const UserNotExists = '(削除済み)'
    export const SelfAssignSuffix = '\n(自分に割り当てる)'
    export const loginUserId = 'loginUserId'
    export const AutoAssignUser = '自動割り当て機能'
    export const System = 'システム'
  }

  export namespace Messages {
    export const SenderTypeOperator = 'operator'
    export const SenderTypeRequester = 'requester'
    export const SenderTypeAutoAssign = 'auto_assign'
    export const SenderTypeSystem = 'system'
  }

  export namespace RequestedAssignedUser {
    export const InitialState = 'initial'
  }
  export namespace ClientErrorMessage {
    export const MessageStateError =
      '通信に失敗しました。再接続を試みています...'
  }

  export namespace RequesterType {
    export const RequesterTypeTeams = 'teams'
    export const RequesterTypeWebagent = 'webagent'
  }
}

export enum CustomFieldType {
  select = 'select',
  multiselect = 'multiselect',
  text = 'text',
  textarea = 'textarea',
  number = 'number',
  checkbox = 'checkbox',
  date = 'date',
}

export const CustomFieldTypeLabels: Record<CustomFieldType, string> = {
  [CustomFieldType.select]: '選択リスト',
  [CustomFieldType.multiselect]: '複数選択リスト',
  [CustomFieldType.text]: 'テキスト',
  [CustomFieldType.textarea]: '複数行テキスト',
  [CustomFieldType.number]: '数値',
  [CustomFieldType.checkbox]: 'チェックボックス',
  [CustomFieldType.date]: '日付',
}

export const customFieldMaxLength = 30

export namespace CustomFieldValue {
  export const DisplayedNullValue = '--'
  export namespace ServerErrorMessage {
    export const ValidOption = 'invalid option'
    export const ValidCheckbox = 'invalid checkbox'
    export const ValidText = 'invalid text'
    export const ValidDate = 'invalid date'
    export const ValidNumber = 'invalid number'
  }
  export namespace ClientErrorMessage {
    export const ValidOption =
      'セレクトフィールドの選択肢に含まれない値は保存できません。'
    export const ValidCheckbox =
      'チェックボックスに不正な値が入力されています。'
    export const ValidText = 'テキストフィールドは255文字までです。'
    export const ValidDate = '日付のフォーマットが正しくありません。'
    export const ValidNumber = '整数は12桁まで. 小数点以下は6桁までです。'
    export const ValidExists = '既に存在するフィールド名では登録できません。'
    export const Default = 'カスタムフィールドの値の更新に失敗しました。'
    export const Unknown =
      'カスタムフィールドにエラーが発生しました。再読み込みを行なってください。'
  }
}

/*
リクエスタ側Teamsのリンク機能がftpも対応していたのでそこに合わせている
本家と同じくプロトコルの確認程度の簡易なバリデーション（http, https, ftpのみOK）
*/
export const regExpAutoLink = '(https?|ftp)://\\S+'
export namespace ChatInputProps {
  export const EditorContentStoragePrefix = 'draft.'
}
export namespace ChatInputEditorProperties {
  export const BlockIndentKey = 'indent'
  export const BlockQuoteLineKey = 'blockquote_line_key'
}
export namespace PageName {
  export const TicketList = 'ticket_list'
  export const TicketDetail = 'ticket_detail'
  export const TicketTransfer = 'ticket_transfer'
  export const TicketCustomFieldList = 'ticket_custom_field_list'
  export const TicketChannelDialog = 'ticket_channel_dialog'
  export const TicketAssignedUserDialog = 'ticket_assigned_user_dialog'
  export const TicketCustomFieldDialog = 'ticket_custom_field_dialog'
  export const CSVExportDialog = 'csv_export_dialog'
  export const TenantAppCSVExportDialog = 'tenant_app_csv_export_dialog'
  export const TabConfig = 'tab_config'
}

export namespace Sort {
  export namespace Asc {
    export const name = '昇順'
    export const value = 'asc'
  }

  export namespace Desc {
    export const name = '降順'
    export const value = 'desc'
  }
}

export namespace DefaultFields {
  export namespace KeyId {
    export const name = 'ID'
    export const value = 'keyId'
  }
  export namespace Requester {
    export const name = '依頼人'
    export const value = 'requester'
  }
  export namespace Subject {
    export const name = 'タイトル'
    export const value = 'subject'
  }
  export namespace AssignedUserId {
    export const name = '担当者'
    export const value = 'assignedUserId'
  }
  export namespace Status {
    export const name = 'ステータス'
    export const value = 'status'
  }
  export namespace ReceivedAt {
    export const name = '受信日'
    export const value = 'receivedAt'
  }
  export namespace SentAt {
    export const name = '返信日'
    export const value = 'sentAt'
  }
  export namespace CreatedAt {
    export const name = '依頼日'
    export const value = 'createdAt'
  }
  export namespace Channel {
    export const name = 'チャネル'
  }

  export namespace Note {
    export const name = '用件'
  }

  export namespace FaqStatus {
    export const name = 'FAQ作成ステータス'
    export const value = 'faqStatus'
  }
  export namespace FaqCreator {
    export const name = 'FAQ作成者'
    export const value = 'faqCreator'
  }
}

export enum DraftFaqStatus {
  waiting = 'waiting',
  failed = 'failed',
  completed = 'completed',
}

export enum FaqStatus {
  nothing = '',
  created = 'created',
}
export const FaqStatusLabels: Record<FaqStatus, string> = {
  [FaqStatus.nothing]: '',
  [FaqStatus.created]: '作成済み',
}
export const FaqStatusColors: Record<FaqStatus, string> = {
  [FaqStatus.nothing]: '',
  [FaqStatus.created]: 'colorScheme.brand.background',
}

export const defaultAppName = 'AIヘルプデスク'
export const defaultAppIcon = '/ai_helpdesk_logo192.png'

export enum RecommendedFaqImpact {
  high = 'high',
  medium = 'medium',
  low = 'low',
}

export const RecommendedFaqImpactLabels: Record<RecommendedFaqImpact, string> =
  {
    [RecommendedFaqImpact.high]: '大',
    [RecommendedFaqImpact.medium]: '中',
    [RecommendedFaqImpact.low]: '小',
  }

export const ActivityMessagePlansLimit = 10
