import { grpc } from '@improbable-eng/grpc-web'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk, RootState } from '../../app/store'
import faqLabel_pb, { FaqLabel } from '../../proto/faq_label_pb'
import faqLabel_pb_service from '../../proto/faq_label_pb_service'

export const faqLabelAdapter = createEntityAdapter<FaqLabel.AsObject>()

export interface FaqLabelState {
  loading: boolean
  error: boolean
}

export const FaqLabelSelector = faqLabelAdapter.getSelectors(
  (state: RootState) => state.faqLabel
)

export const faqLabelSlice = createSlice({
  name: 'faqLabel',
  initialState: faqLabelAdapter.getInitialState<FaqLabelState>({
    loading: false,
    error: false,
  }),
  reducers: {
    getFaqLabelsStart(state) {
      state.loading = true
      state.error = false
    },
    getFaqLabelsOnMessage(
      state,
      action: PayloadAction<{
        message: faqLabel_pb.GetFaqLabelsResponse.AsObject
      }>
    ) {
      const { message } = action.payload
      faqLabelAdapter.setAll(state, message.faqlabelsList)
    },
    getFaqLabelsOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
      }>
    ) {
      const { code } = action.payload
      state.loading = false
      state.error = code !== grpc.Code.OK
    },
  },
})

export const { getFaqLabelsStart, getFaqLabelsOnMessage, getFaqLabelsOnEnd } =
  faqLabelSlice.actions
export default faqLabelSlice.reducer

export const getFaqLabels =
  (): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      faqLabel_pb.GetFaqLabelsRequest,
      faqLabel_pb.GetFaqLabelsResponse
    >(faqLabel_pb_service.FaqLabelAPI.GetFaqLabels)

    const req = new faqLabel_pb.GetFaqLabelsRequest()

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      dispatch(getFaqLabelsOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code) => {
      dispatch(getFaqLabelsOnEnd({ code }))
    })

    dispatch(getFaqLabelsStart())
    client.start(meta)
    client.send(req)
    client.finishSend()
  }
