/**
 * 認証状態をを管理するSlice
 * 認証は初期化やリフレッシュなどの状態を扱うため、RTKQueryだけでなくSliceでも管理する
 */

import * as RTK from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'

import { DecodedSsoToken } from '../../features/auth/authSlice'
import { getAzureADToken } from '../RequesterProtectedRoute/operations'
import * as Actions from './actions'
import { extendedApi as requesterAuthApi } from './api'
import { RequesterAuth } from './types/RequesterAuth'

export type State = {
  isInitialized: boolean
  error: null | Error
  entity: RequesterAuth
  azureADToken: string | null
  decodedSsoToken?: DecodedSsoToken
}
const authSlice = RTK.createSlice({
  initialState: {
    error: null,
    isInitialized: false,
    azureADToken: null,
    entity: {
      accessToken: '',
      graphToken: {
        accessToken: '',
        refreshToken: '',
      },
      availableFeatures: {
        fileAttachment: false,
      },
    },
  } as State,
  name: 'renewfeatures/RequesterAuth',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Actions.tokenRefreshAction, (state, action) => {
      state.entity.accessToken = action.payload.accessToken
    })
    builder.addCase(getAzureADToken.fulfilled, (state, action) => {
      state.azureADToken = action.payload
      state.decodedSsoToken = jwt_decode(action.payload)
    })
    builder.addMatcher(
      requesterAuthApi.endpoints.getRequesterAuth.matchFulfilled,
      (state, action) => {
        state.isInitialized = true
        state.error = null
        state.entity = action.payload
      }
    )
  },
})

export const { reducer, actions } = authSlice
