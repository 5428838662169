// source: proto/recommended_faq.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.ListRecommendedFaqRedundantIntentsRequest', null, global);
goog.exportSymbol('proto.ListRecommendedFaqRedundantIntentsResponse', null, global);
goog.exportSymbol('proto.ListRecommendedFaqsRequest', null, global);
goog.exportSymbol('proto.ListRecommendedFaqsResponse', null, global);
goog.exportSymbol('proto.RecommendedFaq', null, global);
goog.exportSymbol('proto.RecommendedFaqRedundantIntent', null, global);
goog.exportSymbol('proto.RecommendedFaqSchedule', null, global);
goog.exportSymbol('proto.UpdateRecommendedFaqRequest', null, global);
goog.exportSymbol('proto.UpdateRecommendedFaqResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RecommendedFaq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RecommendedFaq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RecommendedFaq.displayName = 'proto.RecommendedFaq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RecommendedFaqSchedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RecommendedFaqSchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RecommendedFaqSchedule.displayName = 'proto.RecommendedFaqSchedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RecommendedFaqRedundantIntent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RecommendedFaqRedundantIntent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RecommendedFaqRedundantIntent.displayName = 'proto.RecommendedFaqRedundantIntent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListRecommendedFaqsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListRecommendedFaqsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListRecommendedFaqsRequest.displayName = 'proto.ListRecommendedFaqsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListRecommendedFaqsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListRecommendedFaqsResponse.repeatedFields_, null);
};
goog.inherits(proto.ListRecommendedFaqsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListRecommendedFaqsResponse.displayName = 'proto.ListRecommendedFaqsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListRecommendedFaqRedundantIntentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListRecommendedFaqRedundantIntentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListRecommendedFaqRedundantIntentsRequest.displayName = 'proto.ListRecommendedFaqRedundantIntentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListRecommendedFaqRedundantIntentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListRecommendedFaqRedundantIntentsResponse.repeatedFields_, null);
};
goog.inherits(proto.ListRecommendedFaqRedundantIntentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListRecommendedFaqRedundantIntentsResponse.displayName = 'proto.ListRecommendedFaqRedundantIntentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateRecommendedFaqRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateRecommendedFaqRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateRecommendedFaqRequest.displayName = 'proto.UpdateRecommendedFaqRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateRecommendedFaqResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateRecommendedFaqResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateRecommendedFaqResponse.displayName = 'proto.UpdateRecommendedFaqResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RecommendedFaq.prototype.toObject = function(opt_includeInstance) {
  return proto.RecommendedFaq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RecommendedFaq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RecommendedFaq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    request: jspb.Message.getFieldWithDefault(msg, 3, ""),
    response: jspb.Message.getFieldWithDefault(msg, 4, ""),
    impact: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    representativeTicketId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    relatedTicketCount: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RecommendedFaq}
 */
proto.RecommendedFaq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RecommendedFaq;
  return proto.RecommendedFaq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RecommendedFaq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RecommendedFaq}
 */
proto.RecommendedFaq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequest(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponse(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImpact(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRepresentativeTicketId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRelatedTicketCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RecommendedFaq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RecommendedFaq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RecommendedFaq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RecommendedFaq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequest();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getResponse();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImpact();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRepresentativeTicketId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getRelatedTicketCount();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.RecommendedFaq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RecommendedFaq} returns this
 */
proto.RecommendedFaq.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.RecommendedFaq.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RecommendedFaq} returns this
 */
proto.RecommendedFaq.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string request = 3;
 * @return {string}
 */
proto.RecommendedFaq.prototype.getRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.RecommendedFaq} returns this
 */
proto.RecommendedFaq.prototype.setRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string response = 4;
 * @return {string}
 */
proto.RecommendedFaq.prototype.getResponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.RecommendedFaq} returns this
 */
proto.RecommendedFaq.prototype.setResponse = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string impact = 5;
 * @return {string}
 */
proto.RecommendedFaq.prototype.getImpact = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.RecommendedFaq} returns this
 */
proto.RecommendedFaq.prototype.setImpact = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.RecommendedFaq.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.RecommendedFaq} returns this
*/
proto.RecommendedFaq.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RecommendedFaq} returns this
 */
proto.RecommendedFaq.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RecommendedFaq.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 representative_ticket_id = 7;
 * @return {number}
 */
proto.RecommendedFaq.prototype.getRepresentativeTicketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.RecommendedFaq} returns this
 */
proto.RecommendedFaq.prototype.setRepresentativeTicketId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 related_ticket_count = 8;
 * @return {number}
 */
proto.RecommendedFaq.prototype.getRelatedTicketCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.RecommendedFaq} returns this
 */
proto.RecommendedFaq.prototype.setRelatedTicketCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RecommendedFaqSchedule.prototype.toObject = function(opt_includeInstance) {
  return proto.RecommendedFaqSchedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RecommendedFaqSchedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RecommendedFaqSchedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    executedAt: (f = msg.getExecutedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    scheduledAt: (f = msg.getScheduledAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RecommendedFaqSchedule}
 */
proto.RecommendedFaqSchedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RecommendedFaqSchedule;
  return proto.RecommendedFaqSchedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RecommendedFaqSchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RecommendedFaqSchedule}
 */
proto.RecommendedFaqSchedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExecutedAt(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RecommendedFaqSchedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RecommendedFaqSchedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RecommendedFaqSchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RecommendedFaqSchedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExecutedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getScheduledAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp executed_at = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.RecommendedFaqSchedule.prototype.getExecutedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.RecommendedFaqSchedule} returns this
*/
proto.RecommendedFaqSchedule.prototype.setExecutedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RecommendedFaqSchedule} returns this
 */
proto.RecommendedFaqSchedule.prototype.clearExecutedAt = function() {
  return this.setExecutedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RecommendedFaqSchedule.prototype.hasExecutedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp scheduled_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.RecommendedFaqSchedule.prototype.getScheduledAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.RecommendedFaqSchedule} returns this
*/
proto.RecommendedFaqSchedule.prototype.setScheduledAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RecommendedFaqSchedule} returns this
 */
proto.RecommendedFaqSchedule.prototype.clearScheduledAt = function() {
  return this.setScheduledAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RecommendedFaqSchedule.prototype.hasScheduledAt = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RecommendedFaqRedundantIntent.prototype.toObject = function(opt_includeInstance) {
  return proto.RecommendedFaqRedundantIntent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RecommendedFaqRedundantIntent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RecommendedFaqRedundantIntent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    intentName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    intentQuestion: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RecommendedFaqRedundantIntent}
 */
proto.RecommendedFaqRedundantIntent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RecommendedFaqRedundantIntent;
  return proto.RecommendedFaqRedundantIntent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RecommendedFaqRedundantIntent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RecommendedFaqRedundantIntent}
 */
proto.RecommendedFaqRedundantIntent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntentName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntentQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RecommendedFaqRedundantIntent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RecommendedFaqRedundantIntent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RecommendedFaqRedundantIntent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RecommendedFaqRedundantIntent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getIntentName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIntentQuestion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.RecommendedFaqRedundantIntent.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RecommendedFaqRedundantIntent} returns this
 */
proto.RecommendedFaqRedundantIntent.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string intent_name = 2;
 * @return {string}
 */
proto.RecommendedFaqRedundantIntent.prototype.getIntentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RecommendedFaqRedundantIntent} returns this
 */
proto.RecommendedFaqRedundantIntent.prototype.setIntentName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string intent_question = 3;
 * @return {string}
 */
proto.RecommendedFaqRedundantIntent.prototype.getIntentQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.RecommendedFaqRedundantIntent} returns this
 */
proto.RecommendedFaqRedundantIntent.prototype.setIntentQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListRecommendedFaqsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ListRecommendedFaqsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListRecommendedFaqsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRecommendedFaqsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListRecommendedFaqsRequest}
 */
proto.ListRecommendedFaqsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListRecommendedFaqsRequest;
  return proto.ListRecommendedFaqsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListRecommendedFaqsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListRecommendedFaqsRequest}
 */
proto.ListRecommendedFaqsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListRecommendedFaqsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListRecommendedFaqsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListRecommendedFaqsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRecommendedFaqsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ListRecommendedFaqsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListRecommendedFaqsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ListRecommendedFaqsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListRecommendedFaqsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRecommendedFaqsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recommendedFaqsList: jspb.Message.toObjectList(msg.getRecommendedFaqsList(),
    proto.RecommendedFaq.toObject, includeInstance),
    schedule: (f = msg.getSchedule()) && proto.RecommendedFaqSchedule.toObject(includeInstance, f),
    isConfigured: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListRecommendedFaqsResponse}
 */
proto.ListRecommendedFaqsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListRecommendedFaqsResponse;
  return proto.ListRecommendedFaqsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListRecommendedFaqsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListRecommendedFaqsResponse}
 */
proto.ListRecommendedFaqsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RecommendedFaq;
      reader.readMessage(value,proto.RecommendedFaq.deserializeBinaryFromReader);
      msg.addRecommendedFaqs(value);
      break;
    case 2:
      var value = new proto.RecommendedFaqSchedule;
      reader.readMessage(value,proto.RecommendedFaqSchedule.deserializeBinaryFromReader);
      msg.setSchedule(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsConfigured(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListRecommendedFaqsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListRecommendedFaqsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListRecommendedFaqsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRecommendedFaqsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecommendedFaqsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.RecommendedFaq.serializeBinaryToWriter
    );
  }
  f = message.getSchedule();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.RecommendedFaqSchedule.serializeBinaryToWriter
    );
  }
  f = message.getIsConfigured();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated RecommendedFaq recommended_faqs = 1;
 * @return {!Array<!proto.RecommendedFaq>}
 */
proto.ListRecommendedFaqsResponse.prototype.getRecommendedFaqsList = function() {
  return /** @type{!Array<!proto.RecommendedFaq>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.RecommendedFaq, 1));
};


/**
 * @param {!Array<!proto.RecommendedFaq>} value
 * @return {!proto.ListRecommendedFaqsResponse} returns this
*/
proto.ListRecommendedFaqsResponse.prototype.setRecommendedFaqsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.RecommendedFaq=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RecommendedFaq}
 */
proto.ListRecommendedFaqsResponse.prototype.addRecommendedFaqs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.RecommendedFaq, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListRecommendedFaqsResponse} returns this
 */
proto.ListRecommendedFaqsResponse.prototype.clearRecommendedFaqsList = function() {
  return this.setRecommendedFaqsList([]);
};


/**
 * optional RecommendedFaqSchedule schedule = 2;
 * @return {?proto.RecommendedFaqSchedule}
 */
proto.ListRecommendedFaqsResponse.prototype.getSchedule = function() {
  return /** @type{?proto.RecommendedFaqSchedule} */ (
    jspb.Message.getWrapperField(this, proto.RecommendedFaqSchedule, 2));
};


/**
 * @param {?proto.RecommendedFaqSchedule|undefined} value
 * @return {!proto.ListRecommendedFaqsResponse} returns this
*/
proto.ListRecommendedFaqsResponse.prototype.setSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListRecommendedFaqsResponse} returns this
 */
proto.ListRecommendedFaqsResponse.prototype.clearSchedule = function() {
  return this.setSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListRecommendedFaqsResponse.prototype.hasSchedule = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_configured = 3;
 * @return {boolean}
 */
proto.ListRecommendedFaqsResponse.prototype.getIsConfigured = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ListRecommendedFaqsResponse} returns this
 */
proto.ListRecommendedFaqsResponse.prototype.setIsConfigured = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListRecommendedFaqRedundantIntentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ListRecommendedFaqRedundantIntentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListRecommendedFaqRedundantIntentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRecommendedFaqRedundantIntentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    recommendedFaqId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListRecommendedFaqRedundantIntentsRequest}
 */
proto.ListRecommendedFaqRedundantIntentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListRecommendedFaqRedundantIntentsRequest;
  return proto.ListRecommendedFaqRedundantIntentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListRecommendedFaqRedundantIntentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListRecommendedFaqRedundantIntentsRequest}
 */
proto.ListRecommendedFaqRedundantIntentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecommendedFaqId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListRecommendedFaqRedundantIntentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListRecommendedFaqRedundantIntentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListRecommendedFaqRedundantIntentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRecommendedFaqRedundantIntentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecommendedFaqId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 recommended_faq_id = 1;
 * @return {number}
 */
proto.ListRecommendedFaqRedundantIntentsRequest.prototype.getRecommendedFaqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ListRecommendedFaqRedundantIntentsRequest} returns this
 */
proto.ListRecommendedFaqRedundantIntentsRequest.prototype.setRecommendedFaqId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ListRecommendedFaqRedundantIntentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListRecommendedFaqRedundantIntentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ListRecommendedFaqRedundantIntentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListRecommendedFaqRedundantIntentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRecommendedFaqRedundantIntentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recommendedFaqRedundantIntentsList: jspb.Message.toObjectList(msg.getRecommendedFaqRedundantIntentsList(),
    proto.RecommendedFaqRedundantIntent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListRecommendedFaqRedundantIntentsResponse}
 */
proto.ListRecommendedFaqRedundantIntentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListRecommendedFaqRedundantIntentsResponse;
  return proto.ListRecommendedFaqRedundantIntentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListRecommendedFaqRedundantIntentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListRecommendedFaqRedundantIntentsResponse}
 */
proto.ListRecommendedFaqRedundantIntentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RecommendedFaqRedundantIntent;
      reader.readMessage(value,proto.RecommendedFaqRedundantIntent.deserializeBinaryFromReader);
      msg.addRecommendedFaqRedundantIntents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListRecommendedFaqRedundantIntentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListRecommendedFaqRedundantIntentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListRecommendedFaqRedundantIntentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListRecommendedFaqRedundantIntentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecommendedFaqRedundantIntentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.RecommendedFaqRedundantIntent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RecommendedFaqRedundantIntent recommended_faq_redundant_intents = 1;
 * @return {!Array<!proto.RecommendedFaqRedundantIntent>}
 */
proto.ListRecommendedFaqRedundantIntentsResponse.prototype.getRecommendedFaqRedundantIntentsList = function() {
  return /** @type{!Array<!proto.RecommendedFaqRedundantIntent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.RecommendedFaqRedundantIntent, 1));
};


/**
 * @param {!Array<!proto.RecommendedFaqRedundantIntent>} value
 * @return {!proto.ListRecommendedFaqRedundantIntentsResponse} returns this
*/
proto.ListRecommendedFaqRedundantIntentsResponse.prototype.setRecommendedFaqRedundantIntentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.RecommendedFaqRedundantIntent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RecommendedFaqRedundantIntent}
 */
proto.ListRecommendedFaqRedundantIntentsResponse.prototype.addRecommendedFaqRedundantIntents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.RecommendedFaqRedundantIntent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListRecommendedFaqRedundantIntentsResponse} returns this
 */
proto.ListRecommendedFaqRedundantIntentsResponse.prototype.clearRecommendedFaqRedundantIntentsList = function() {
  return this.setRecommendedFaqRedundantIntentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateRecommendedFaqRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateRecommendedFaqRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateRecommendedFaqRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateRecommendedFaqRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    recommendedFaqId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isSkip: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateRecommendedFaqRequest}
 */
proto.UpdateRecommendedFaqRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateRecommendedFaqRequest;
  return proto.UpdateRecommendedFaqRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateRecommendedFaqRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateRecommendedFaqRequest}
 */
proto.UpdateRecommendedFaqRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecommendedFaqId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSkip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateRecommendedFaqRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateRecommendedFaqRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateRecommendedFaqRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateRecommendedFaqRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecommendedFaqId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getIsSkip();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional uint32 recommended_faq_id = 1;
 * @return {number}
 */
proto.UpdateRecommendedFaqRequest.prototype.getRecommendedFaqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateRecommendedFaqRequest} returns this
 */
proto.UpdateRecommendedFaqRequest.prototype.setRecommendedFaqId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_skip = 2;
 * @return {boolean}
 */
proto.UpdateRecommendedFaqRequest.prototype.getIsSkip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateRecommendedFaqRequest} returns this
 */
proto.UpdateRecommendedFaqRequest.prototype.setIsSkip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateRecommendedFaqResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateRecommendedFaqResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateRecommendedFaqResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateRecommendedFaqResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateRecommendedFaqResponse}
 */
proto.UpdateRecommendedFaqResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateRecommendedFaqResponse;
  return proto.UpdateRecommendedFaqResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateRecommendedFaqResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateRecommendedFaqResponse}
 */
proto.UpdateRecommendedFaqResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateRecommendedFaqResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateRecommendedFaqResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateRecommendedFaqResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateRecommendedFaqResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto);
