import { Box, Dialog, DialogProps } from '@fluentui/react-northstar'
import Draft, {
  AtomicBlockUtils,
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  RichUtils,
} from 'draft-js'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import RichTextToolBar from '../../components/RichTextToolBar'
import { ChatInputEditorProperties } from '../../consts'
import { setEditorState } from '../editor/editorStateSlice'
import { setTrashOpen } from '../editor/editorToolSlice'
import ChatInputEditor, {
  backgroundColorStyleMaps,
  colorStyleMaps,
  fontSizeStyleMaps,
  styleMaps,
} from './ChatInputEditor'
import styles from './ChatInputEditor.module.css'
import { ChatInputEditorUtils } from './ChatInputEditorUtils'
import { HorizontalLineEntityType } from './types'

export interface ChatInputEditorAreaProps {
  setEditorFocus: React.Dispatch<React.SetStateAction<boolean>>
  onClick: () => void
  styleEdit: boolean
  onStyleEditToggle: () => void
}

export const ChatInputEditorArea: React.FC<ChatInputEditorAreaProps> = (
  props
) => {
  const editorState = useSelector(
    (state: RootState) => state.editorState
  ).editorState
  const trashOpen = useSelector(
    (state: RootState) => state.editorToolState
  ).trashOpen
  const { setEditorFocus, onStyleEditToggle } = props
  const dispatch = useDispatch()
  const handleStyleToggled = (
    style: 'BOLD' | 'ITALIC' | 'UNDERLINE' | 'STRIKETHROUGH'
  ) => {
    dispatch(
      setEditorState({ state: RichUtils.toggleInlineStyle(editorState, style) })
    )
  }

  const handleBackgroundColorToggled = (color: string) => {
    handleCustomStyleToggled(color, backgroundColorStyleMaps)
  }

  const handleColorToggled = (color: string) => {
    handleCustomStyleToggled(color, colorStyleMaps)
  }

  const handleFontSizeToggled = (style: string) => {
    handleCustomStyleToggled(style, fontSizeStyleMaps)
  }

  const handleRichStyleToggled = (type: Draft.DraftBlockType) => {
    if (ChatInputEditorUtils.isListBlock(type)) {
      // 上限箇条書きリストと引用は兼用できないため、選択範囲に引用がある場合は、箇条書きに変換できなようにします
      if (
        ChatInputEditorUtils.currentSelectionContainsBlockQuote(editorState)
      ) {
        return
      }
      // 箇条書きに変換したい場合は事前に設定されたindentを一番左からにします
      const newEditorState = RichUtils.toggleBlockType(editorState, type)
      const c = ChatInputEditorUtils.modifySelectedBlocks(
        newEditorState,
        (b) => {
          const block = b as ContentBlock
          const data = block.getData()
          const newData = data.remove(ChatInputEditorProperties.BlockIndentKey)
          return block.merge({ data: newData }) as ContentBlock
        }
      )

      if (c == null) {
        return
      }
      const e = EditorState.push(editorState, c, 'change-block-data')
      dispatch(setEditorState({ state: e }))
      return
    }

    // remove selected block's blockquote properties
    if (type === 'code-block') {
      const e = RichUtils.toggleBlockType(editorState, type)
      const c = ChatInputEditorUtils.modifySelectedBlocks(e, (b) => {
        const block = b as ContentBlock
        const data = block.getData()
        const newData = data.remove(ChatInputEditorProperties.BlockQuoteLineKey)
        return block.merge({ data: newData }) as ContentBlock
      })

      if (c == null) {
        return
      }

      dispatch(
        setEditorState({ state: EditorState.push(e, c, 'change-block-data') })
      )
      return
    }

    // ツールバーから引用ボタンを押した時に
    if (type === 'blockquote') {
      // 上限箇条書きリストと引用は兼用できないため、選択範囲に箇条書きがある場合は、引用に変換できなようにします
      if (ChatInputEditorUtils.currentSelectionContainsList(editorState)) {
        return false
      }

      const contentStateWithBlockQuoteToggled =
        ChatInputEditorUtils.toggleSelectionBlockQuote(editorState)
      if (contentStateWithBlockQuoteToggled == null) {
        return
      }
      const e = EditorState.forceSelection(
        EditorState.push(
          editorState,
          contentStateWithBlockQuoteToggled,
          'change-block-data'
        ),
        editorState.getSelection()
      )
      dispatch(setEditorState({ state: e }))
      return
    }

    dispatch(
      setEditorState({ state: RichUtils.toggleBlockType(editorState, type) })
    )
  }

  const handleIndentStyleSelected = (indent: boolean) => {
    const currentBlockType = RichUtils.getCurrentBlockType(editorState)
    if (ChatInputEditorUtils.isListBlock(currentBlockType)) {
      const res = ChatInputEditorUtils.onTab(editorState, !indent)
      if (res) {
        dispatch(setEditorState({ state: res }))
      }
      return
    }

    if (currentBlockType === 'code-block') {
      return
    }

    if (ChatInputEditorUtils.isFocusingOnBlockquote(editorState)) {
      return
    }

    const selection = editorState.getSelection()
    const newContentState = modifyBlocksIndent(editorState, indent)

    if (newContentState == null) {
      return
    }

    const nextEditorState = EditorState.push(
      editorState,
      newContentState,
      'change-block-data'
    )
    dispatch(
      setEditorState({
        state: EditorState.forceSelection(nextEditorState, selection),
      })
    )
  }

  const handleHorizonalLineAdded = () => {
    const currentContent = editorState.getCurrentContent()
    const newContentState = currentContent.createEntity(
      HorizontalLineEntityType,
      'IMMUTABLE',
      {}
    )
    const newEditorState = EditorState.set(editorState, {
      currentContent: newContentState,
    })
    dispatch(
      setEditorState({
        state: AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          newContentState.getLastCreatedEntityKey(),
          ' '
        ),
      })
    )
  }

  const handleFormatClear = () => {
    const selection = editorState.getSelection()

    // 何も選択されてない状態の場合
    if (selection.isCollapsed()) {
      const newEditorState = [
        ...Object.keys(styleMaps),
        'BOLD',
        'ITALIC',
        'UNDERLINE',
        'STRIKETHROUGH',
      ].reduce((st, s) => {
        const inlineStyle = s as string
        const state = st as EditorState
        const currentStyle = state.getCurrentInlineStyle()
        return EditorState.setInlineStyleOverride(
          state,
          currentStyle.remove(inlineStyle)
        )
      }, editorState)
      dispatch(setEditorState({ state: newEditorState }))
      return
    }

    const newContent = [
      ...Object.keys(styleMaps),
      'BOLD',
      'ITALIC',
      'UNDERLINE',
      'STRIKETHROUGH',
    ].reduce(
      (content, style) => Modifier.removeInlineStyle(content, selection, style),
      editorState.getCurrentContent()
    )
    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'change-inline-style'
    )
    dispatch(setEditorState({ state: newEditorState }))
  }

  const handleCustomStyleToggled = (
    style: string,
    styleRanges:
      | typeof backgroundColorStyleMaps
      | typeof colorStyleMaps
      | typeof fontSizeStyleMaps
  ) => {
    const selection = editorState.getSelection()
    // 選択範囲内にあるスタイルを全部消します。同じカテゴリのスタイルだけをクリアします、他のカテゴリのスタイルをクリアしません。
    const nextContentState = Object.keys(styleRanges).reduce(
      (contentState, color) => {
        return Modifier.removeInlineStyle(contentState, selection, color)
      },
      editorState.getCurrentContent()
    )

    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      'change-inline-style'
    )

    const currentStyle = editorState.getCurrentInlineStyle()

    // このカテゴリのスタイルを全部消すという命令を受けた場合です。
    if (style === 'clear') {
      nextEditorState = Object.keys(styleRanges).reduce((st, s) => {
        const inlineStyle = s as string
        const state = st as EditorState
        const currentStyle = state.getCurrentInlineStyle()
        return EditorState.setInlineStyleOverride(
          state,
          currentStyle.has(inlineStyle)
            ? currentStyle.remove(inlineStyle)
            : currentStyle
        )
      }, nextEditorState)
      dispatch(setEditorState({ state: nextEditorState }))
      return
    }

    // 何も選択されてない状態の場合
    if (selection.isCollapsed()) {
      nextEditorState = Object.keys(styleRanges).reduce((st, s) => {
        const inlineStyle = s as string
        const state = st as EditorState
        const currentStyle = state.getCurrentInlineStyle()
        return EditorState.setInlineStyleOverride(
          state,
          currentStyle.has(inlineStyle)
            ? currentStyle.remove(inlineStyle)
            : inlineStyle === style
            ? currentStyle.add(style)
            : currentStyle
        )
      }, nextEditorState)

      dispatch(setEditorState({ state: nextEditorState }))
      return
    }

    // このスタイルは振っていない場合
    if (!currentStyle.has(style)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, style)
    }

    dispatch(setEditorState({ state: nextEditorState }))
  }

  const holdSelection = () => {
    dispatch(
      setEditorState({ state: ChatInputEditorUtils.holdSelection(editorState) })
    )
  }

  return (
    <Box>
      <RichTextToolBar
        hidden={!props.styleEdit}
        onStyleToggled={handleStyleToggled}
        onHighLightToggle={handleBackgroundColorToggled}
        onColorToggle={handleColorToggled}
        holdSelection={holdSelection}
        onFontSizeToggle={handleFontSizeToggled}
        onRichStyleToggle={handleRichStyleToggled}
        currentBlockType={RichUtils.getCurrentBlockType(editorState)}
        onFormatClear={handleFormatClear}
        onIndentStyleToggle={handleIndentStyleSelected}
        onHorizonalLineAdded={handleHorizonalLineAdded}
      />
      <TrashDialog
        open={trashOpen}
        onCancel={() => {
          dispatch(setTrashOpen(false))
          dispatch(
            setEditorState({
              state: ChatInputEditorUtils.holdSelection(editorState),
            })
          )
        }}
        onConfirm={() => {
          const newEditorState = EditorState.push(
            editorState,
            ContentState.createFromText(''),
            'remove-range'
          )
          dispatch(setEditorState({ state: newEditorState }))
          dispatch(setTrashOpen(false))
        }}
      />
      <Box className={styles.editorArea}>
        <ChatInputEditor
          onStyleToggled={handleStyleToggled}
          setEditorFocus={setEditorFocus}
          onClick={props.onClick}
          styleEdit={props.styleEdit}
          onStyleEditToggle={onStyleEditToggle}
        />
      </Box>
    </Box>
  )
}

const TrashDialog: React.FC<DialogProps> = (props) => {
  return (
    <Dialog
      confirmButton={`破棄`}
      cancelButton={`キャンセル`}
      header={`この下書きを破棄しますか?
  `}
      {...props}
    />
  )
}

// See: Modifier.setBlockData related souce code
// https://github.com/facebook/draft-js/blob/4a13ca40d19a669c4c1c7ae4bb6d72f48e0949a6/src/model/transaction/modifyBlockForContentState.js#L22
const modifyBlocksIndent = (
  editorState: EditorState,
  indent: boolean
): ContentState | undefined => {
  const c = ChatInputEditorUtils.modifySelectedBlocks(editorState, (b) => {
    const block = b as ContentBlock
    const nextEntityType = indent
      ? determinNextIndent(block)
      : determinNextOutdent(block)

    const blockData = block
      .getData()
      .set(ChatInputEditorProperties.BlockIndentKey, nextEntityType)

    return block.merge({
      data: blockData,
    }) as ContentBlock
  })
  return c
}

const determinNextIndent = (block: Draft.ContentBlock): number => {
  const indentType = block
    .getData()
    .get(ChatInputEditorProperties.BlockIndentKey)

  if (indentType == null) {
    return 1
  }

  try {
    const value = parseInt(indentType, 10)

    if (value === 6) {
      return value
    }

    return value + 1
  } catch (error) {
    return 0
  }
}

const determinNextOutdent = (block: Draft.ContentBlock): number => {
  const indentType = block
    .getData()
    .get(ChatInputEditorProperties.BlockIndentKey)

  if (indentType == null) {
    return 0
  }
  try {
    const value = parseInt(indentType, 10)
    if (value === 0) {
      return value
    }
    return value - 1
  } catch (error) {
    return 0
  }
}
