import { TrashCanIcon } from '@fluentui/react-icons-northstar'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  Flex,
  Input,
  Loader,
  Text,
} from '@fluentui/react-northstar'
import * as microsoftTeams from '@microsoft/teams-js'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { RootState } from '../../app/store'
import { Dropdown } from '../../components/Dropdown'
import PageViewLog from '../../components/PageViewLog'
import {
  DefaultFields,
  EntityId,
  PageName,
  Sort,
  customFieldMaxLength,
} from '../../consts'
import { consoleErrorWithAirbrake, getQueryParamsByKey } from '../../utils'
import AssigneeSelection from '../assigneeSelection'
import {
  customFieldSelector,
  fetchCustomFields,
} from '../ticket/customFieldSlice'
import { createTabAddedGroup } from '../ticket/tabAddedGroupSlice'
import { sortableCustomFieldsTypes } from '../ticket/TicketList'
import {
  defaultTabSettingsSelector,
  fetchDefaultTabSettings,
} from './defaultTabSettingsSlice'
import style from './TabConfig.module.css'
import CustomFilter from './TabConfigCustomFilter'
import TabConfigFields from './tabConfigFields'
import {
  FilterConfig,
  createDefaultTabConfig,
  fetchTabConfig,
  removeTabConfigName,
  setInitialState,
  setTabConfigName,
  setTabConfigSortDirection,
  setTabConfigSortIdentifierName,
  setTabConfigSortTicketCustomFieldId,
  updateDefaultTabConfig,
} from './tabConfigSlice'

const TabConfig: React.FC = (): JSX.Element => {
  // Stateの初期化
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCustomFields(0, customFieldMaxLength))
    dispatch(fetchDefaultTabSettings())
  }, [dispatch])

  const customFields = useSelector(customFieldSelector.selectAll)
  const usersState = useSelector((state: RootState) => state.users)
  const tabConfigState = useSelector((state: RootState) => state.tabConfig)
  const authState = useSelector((state: RootState) => state.auth)
  const groupId = authState.context?.team?.groupId ?? ''
  const defaultTabSettings = useSelector(defaultTabSettingsSelector.selectAll)
  const [isDefaultTab, setIsDefaultTab] = useState(false)
  const [alert, setAlert] = useState(false)

  const defaultTabAlreadyExistsInChannel = useMemo(() => {
    return defaultTabSettings.length > 0
  }, [defaultTabSettings.length])

  useEffect(() => {
    if (usersState.meId == null) return
    microsoftTeams.pages
      .getConfig()
      .then((settings) => {
        // 新規タブの時のみタブの名前が変更できる
        setIsNew(settings.contentUrl == null || settings.contentUrl === '')
        // 新規タブの場合
        if (
          settings.contentUrl ==
            `${new URL('/tickets', window.location.href).href}` ||
          settings.contentUrl == null ||
          settings.contentUrl == ''
        ) {
          setTabName('問い合わせ')
          dispatch(setInitialState({ customFields: customFields }))
          return
        }
        // 設定が存在する場合
        if (
          (settings.contentUrl != null || settings.contentUrl !== '') &&
          getQueryParamsByKey(settings.contentUrl, 'tab_id')[0] != null
        ) {
          setTabName(settings.suggestedDisplayName ?? '問い合わせ')
          dispatch(
            fetchTabConfig(
              getQueryParamsByKey(settings.contentUrl, 'tab_id')[0],
              customFields
            )
          )
          return
        }
      })
      .catch((e) => {
        consoleErrorWithAirbrake(`unexpected tab config error: ${e}`)
      })
  }, [dispatch, usersState.meId, customFields])

  const defaultSelectableFilterOptions = [
    {
      value: DefaultFields.Status.value,
      header: DefaultFields.Status.name,
      ticket_custom_field_id: null,
    },
    {
      value: DefaultFields.AssignedUserId.value,
      header: DefaultFields.AssignedUserId.name,
      ticket_custom_field_id: null,
    },
    {
      value: DefaultFields.CreatedAt.value,
      header: DefaultFields.CreatedAt.name,
      ticket_custom_field_id: null,
    },
    {
      value: DefaultFields.FaqStatus.value,
      header: DefaultFields.FaqStatus.name,
      ticket_custom_field_id: null,
    },
    ...customFields.map((customField) => {
      return {
        value: `custom.${customField.id}.${customField.name}`,
        header: customField.name,
        ticket_custom_field_id: customField.id,
      }
    }),
  ]
  const selectedFilterOptions = tabConfigState.filters.map(
    (filter) => filter.identifierName
  )
  const selectableFilterOptions = defaultSelectableFilterOptions.filter(
    (option) => !selectedFilterOptions.includes(option.value)
  )

  const sortableOptions = useMemo(() => {
    return [
      {
        value: DefaultFields.KeyId.value,
        header: DefaultFields.KeyId.name,
        ticket_custom_field_id: null,
      },
      {
        value: DefaultFields.Status.value,
        header: DefaultFields.Status.name,
        ticket_custom_field_id: null,
      },
      {
        value: DefaultFields.CreatedAt.value,
        header: DefaultFields.CreatedAt.name,
        ticket_custom_field_id: null,
      },
      {
        value: DefaultFields.ReceivedAt.value,
        header: DefaultFields.ReceivedAt.name,
        ticket_custom_field_id: null,
      },
      {
        value: DefaultFields.SentAt.value,
        header: DefaultFields.SentAt.name,
        ticket_custom_field_id: null,
      },
      ...customFields
        .filter((customField) => {
          return sortableCustomFieldsTypes.includes(customField?.type || '')
        })
        .map((customField) => {
          return {
            value: `custom.${customField.id}.${customField.name}`,
            header: customField.name,
            ticket_custom_field_id: customField.id,
          }
        }),
    ]
  }, [customFields])

  const [filterName, setFilterName] = useState('')
  const [tabName, setTabName] = useState('')
  const [isNew, setIsNew] = useState(true)
  const [sortIdentifierNameValue, setSortIdentifierNameValue] = useState(
    tabConfigState.sort.identifierName
  )
  const [sortDirection, setSortDirection] = useState(Sort.Desc.value)

  // 読み取れたタブ設定の中にソート条件があればそれを表示順序ドロップダウンメニューの初期値とする
  useEffect(() => {
    if (tabConfigState.sort) {
      // 項目
      if (tabConfigState.sort.identifierName !== '') {
        const found = sortableOptions.find((obj) => {
          return obj.value === tabConfigState.sort.identifierName
        })
        if (!found) {
          consoleErrorWithAirbrake(
            `could not find proper option ${
              tabConfigState.sort.identifierName
            } from ${JSON.stringify(sortableOptions)}`
          )
          return
        }
        setSortIdentifierNameValue(found.value)
      }
      // 順序
      if (tabConfigState.sort.direction !== '') {
        setSortDirection(tabConfigState.sort.direction)
      }
    }
  }, [sortableOptions, tabConfigState.sort])

  // 保存ボタンを押すことができるようにする設定
  useEffect(() => {
    try {
      microsoftTeams.pages.config.setValidityState(
        tabConfigState.error.filterIdentifierNames.length === 0 &&
          tabName !== '' &&
          tabConfigState.fields.length > 0
      )
    } catch (e) {
      consoleErrorWithAirbrake(`failed setValidityState: ${e}`)
    }
  }, [
    tabConfigState.error.filterIdentifierNames,
    tabName,
    tabConfigState.fields,
  ])

  /*
    既にデフォルトタブとして設定されている場合は、その設定を有効にします。
  */
  useEffect(() => {
    if (tabConfigState.isDefaultTab) {
      setIsDefaultTab(true)
    }
  }, [tabConfigState.isDefaultTab])

  /*
    新しいタブを追加する際、所属するチャンネルにデフォルトタブ候補がない場合、ユーザーにその設定を有効にするよう促す表示を行います。
  */
  useEffect(() => {
    // 既存のタブはそのままにしておきます。
    setIsDefaultTab(isNew && !defaultTabAlreadyExistsInChannel)
  }, [isNew, defaultTabAlreadyExistsInChannel])

  try {
    microsoftTeams.pages.config.registerOnSaveHandler((saveEvent) => {
      let suggestedDisplayName = ''
      // idが存在しない場合は新規作成
      if (tabConfigState.id === '') {
        const uuid = uuidv4()

        // register Microsoft
        microsoftTeams.pages
          .getConfig()
          .then((settings) => {
            suggestedDisplayName =
              tabName || (settings.suggestedDisplayName ?? '問い合わせ')
            microsoftTeams.pages.config
              .setConfig({
                contentUrl: `${
                  new URL('/tickets', window.location.href).href
                }?tab_id=${uuid}`,
                removeUrl: `${
                  new URL('/tab/delete', window.location.href).href
                }`,
                entityId: isDefaultTab ? uuid : EntityId.Tickets, // 新規タブを設定する場合はデフォルトタブの場合はentityIdをuuidに設定、それ以外はEntityId.Ticketsに設定
                suggestedDisplayName,
              })
              .catch((e) => {
                consoleErrorWithAirbrake(`failed setting config: ${e}`)
              })
          })
          .catch((e) => {
            consoleErrorWithAirbrake(`failed getting config: ${e}`)
          })
        // save tab added group
        // FIXME: リファクタで逐次的に処理できるようにした。groupID追加に失敗したら本来タブ設定作成は失敗にした方がよい
        dispatch(createTabAddedGroup(groupId))
        dispatch(
          createDefaultTabConfig(
            uuid,
            tabConfigState.fields,
            tabConfigState.filters,
            tabConfigState.sort,
            customFields,
            saveEvent,
            isDefaultTab
          )
        )
        return
      }
      // idが存在する場合はレコードをUpdate
      // register Microsoft
      microsoftTeams.pages
        .getConfig()
        .then((settings) => {
          suggestedDisplayName = settings.suggestedDisplayName ?? '問い合わせ'
          const config: microsoftTeams.pages.InstanceConfig = {
            contentUrl: settings.contentUrl,
            removeUrl: `${new URL('/tab/delete', window.location.href).href}`,
            suggestedDisplayName,
          }

          // デフォルトタブの場合はentityIdを設定
          if (isDefaultTab) {
            config.entityId = tabConfigState.id
          }

          microsoftTeams.pages.config.setConfig(config).catch((e) => {
            consoleErrorWithAirbrake(`failed setting config: ${e}`)
          })
        })
        .catch((e) => {
          consoleErrorWithAirbrake(`failed getting config: ${e}`)
        })
      // update DB
      // TODO: 以下のGithub issueが解決したら、updateDefaultTabConfigを呼び出した後にタブがリロードするようになるか確認する
      // https://github.com/OfficeDev/microsoft-teams-library-js/issues/1963
      dispatch(
        updateDefaultTabConfig(
          tabConfigState.id,
          tabConfigState.fields,
          tabConfigState.filters,
          tabConfigState.sort,
          customFields,
          saveEvent,
          isDefaultTab
        )
      )
    })
  } catch (e) {
    consoleErrorWithAirbrake(`failed handling tab config data: ${e}`)
  }

  if (!tabConfigState.initialize)
    return (
      <Loader
        styles={() => ({
          height: '100vh',
        })}
        label="読み込み中..."
      />
    )

  const handleIsDefaultTabCheck = (checked: boolean) => {
    setAlert(checked)
    setIsDefaultTab(checked)
  }

  return (
    <PageViewLog pageName={PageName.TabConfig}>
      <Box className={style.padding5}>
        <Flex column gap="gap.smaller">
          <Text weight="bold" content="タブの名前" />
          {isNew ? (
            <Input
              fluid
              placeholder="問い合わせ"
              type="text"
              value={tabName}
              onChange={(_, e) => {
                setTabName(String(e?.value))
              }}
            />
          ) : (
            <>
              <Text content={tabName} />
              <Text
                size="small"
                color="grey"
                content="ここでは名前の変更ができません。タブをクリックして「名前の変更」から変更してください。"
              />
            </>
          )}
        </Flex>

        <Flex column gap="gap.smaller">
          <Text
            weight="bold"
            content="デフォルトタブの設定"
            className={style.configTitle}
          />
          <Flex>
            <Flex vAlign="center">
              <Checkbox
                toggle
                disabled={tabConfigState.isDefaultTab} // すでにONに設定された場合には、デフォルトタブの場合はチェックを外せないことにします。
                checked={isDefaultTab}
                onChange={(e, d) =>
                  handleIsDefaultTabCheck(d?.checked || false)
                }
              />
              <Text content="デフォルトで開くタブに設定する" />
            </Flex>
          </Flex>
          <Text>
            通知メッセージなどに含まれているリンクをクリックすると、常にこのタブで問い合わせ内容が開かれるように設定します。
          </Text>
          <Text>有効にすると、他のタブからはチェックが外れます。</Text>
        </Flex>
        <Flex column>
          <Text
            weight="bold"
            content="問い合わせ一覧の表示設定"
            className={style.configTitle}
          />
          <Divider />
          <Text
            weight="bold"
            content="表示項目"
            className={style.configTitle}
          />
          <TabConfigFields
            customFields={customFields}
            tabConfigState={tabConfigState}
          />
          <Text
            weight="bold"
            content="絞り込み条件"
            className={style.configSubTitle}
          />
          <Flex
            column
            hAlign="start"
            className={style.tabConfigContainer}
            gap="gap.smaller"
            styles={{ width: '100%' }}
          >
            {tabConfigState.error.filterIdentifierNames.length > 0 && (
              <Text
                content="入力にエラーがあります。正しい値を入力してください。"
                styles={({ theme: { siteVariables } }) => ({
                  color: siteVariables.colorScheme.red.foreground,
                  fontSize: '12px',
                })}
              />
            )}
            {tabConfigState.filters.map((filter, index) => {
              return (
                <Flex
                  hAlign="start"
                  className={style.tabConfigFilterContainer}
                  key={`${filter.identifierName}-${index}`}
                  gap="gap.medium"
                >
                  <Flex column>
                    <Text
                      content={handleLabel(filter)}
                      align="end"
                      className={style.tabConfigFilterLabel}
                    />
                    {filter.identifierName.startsWith('custom.') && (
                      <Text
                        content="(カスタムフィールド)"
                        className={style.tabConfigFilterCustomFieldLabel}
                        align="end"
                      />
                    )}
                  </Flex>
                  <Flex className={style.filterRowRight}>
                    <Box className={style.customFilter}>
                      <CustomFilter
                        tabConfigFilterIndex={index}
                        tabConfigState={tabConfigState}
                        customFields={customFields}
                        renderAssigneeSelection={(
                          values,
                          extendedItems,
                          onChange
                        ) => (
                          <AssigneeSelection
                            multiple
                            placeholder="選択してください"
                            values={values}
                            extendedItems={extendedItems}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Box>
                  </Flex>
                  <Button
                    icon={<TrashCanIcon />}
                    text
                    iconOnly
                    onClick={() =>
                      dispatch(
                        removeTabConfigName({ tabConfigFilterIndex: index })
                      )
                    }
                  />
                </Flex>
              )
            })}
          </Flex>
          <Flex gap="gap.smaller" className={style.tabConfigContainer}>
            <Dropdown
              fluid
              value={[filterName]}
              items={[
                {
                  value: '',
                  header: '選択する',
                },
                ...selectableFilterOptions,
              ]}
              onValueChange={(values) => {
                setFilterName(values[0])
              }}
            />
            <Button
              content="条件を追加"
              primary
              onClick={() => {
                if (filterName === '') return
                const ticketCustomFieldId =
                  defaultSelectableFilterOptions.find((obj) => {
                    return obj.value === filterName
                  })?.ticket_custom_field_id ?? null
                dispatch(
                  setTabConfigName({
                    identifierName: filterName,
                    ticketCustomFieldId: ticketCustomFieldId,
                  })
                )
                setFilterName('')
              }}
            />
          </Flex>
        </Flex>
        <Flex column>
          <Text
            weight="bold"
            content="表示順序"
            className={style.configTitle}
          />
          {/* TODO: このあたりはtabConfigSort.tsxとして切り出しを検討する */}
          <Flex gap="gap.smaller" className={style.tabConfigContainer}>
            <Dropdown
              fluid
              value={[sortIdentifierNameValue]}
              items={[...sortableOptions]}
              onValueChange={(values) => {
                setSortIdentifierNameValue(values[0])
                dispatch(setTabConfigSortIdentifierName(values[0]))
                const ticketCustomFieldId =
                  sortableOptions.find((obj) => {
                    return obj.value === values[0]
                  })?.ticket_custom_field_id ?? null
                dispatch(
                  setTabConfigSortTicketCustomFieldId(ticketCustomFieldId)
                )
              }}
            />
            <Dropdown
              fluid
              checkable
              value={[sortDirection]}
              items={[
                {
                  value: Sort.Asc.value,
                  header: Sort.Asc.name,
                },
                {
                  value: Sort.Desc.value,
                  header: Sort.Desc.name,
                },
              ]}
              onValueChange={(values) => {
                setSortDirection(values[0])
                dispatch(setTabConfigSortDirection(values[0]))
              }}
            />
          </Flex>
        </Flex>
      </Box>
      <Dialog
        header={'デフォルトタブの設定'}
        confirmButton={'確定'}
        cancelButton={'キャンセル'}
        onCancel={() => {
          setIsDefaultTab(false)
          setAlert(false)
        }}
        onConfirm={() => {
          setAlert(false)
        }}
        overlay={{ className: style.defaultTabAlert }}
        open={alert}
        content={
          <div>
            <p>
              通知メッセージなどに含まれているリンクをクリックすると、常にこのタブで問い合わせ内容が開かれるように設定します。
            </p>
            <p>
              過去に受けた通知メッセージなどに含まれているリンクがこのタブを指している場合には、リンクが動作しなくなりますのでご注意ください。
            </p>
          </div>
        }
      />
    </PageViewLog>
  )
}

const buildDefaultFieldLabel = (defaultFieldName: string): string => {
  if (defaultFieldName === DefaultFields.Status.value)
    return DefaultFields.Status.name
  if (defaultFieldName === DefaultFields.AssignedUserId.value)
    return DefaultFields.AssignedUserId.name
  if (defaultFieldName === DefaultFields.CreatedAt.value)
    return DefaultFields.CreatedAt.name
  if (defaultFieldName === DefaultFields.FaqStatus.value)
    return DefaultFields.FaqStatus.name
  return ''
}
const handleLabel = (filter: FilterConfig) => {
  if (filter.ticketCustomFieldId != null) {
    return filter.identifierName.replace(
      `custom.${filter.ticketCustomFieldId}.`,
      ''
    )
  }
  return buildDefaultFieldLabel(filter.identifierName)
}

export default TabConfig
