import { GripperBarHorizontalIcon } from '@fluentui/react-icons-mdl2'
import {
  Box,
  Button,
  ChevronStartIcon,
  Flex,
  Loader,
  Text,
} from '@fluentui/react-northstar'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Column, Row, useTable } from 'react-table'

import { RootState } from '../../app/store'
import PageViewLog from '../../components/PageViewLog'
import {
  CustomFieldType,
  CustomFieldTypeLabels,
  PageName,
  customFieldMaxLength,
} from '../../consts'
import { DragItem, hoverFunc } from '../../draggableRow'
import { ellipsis } from '../../utils'
import { addAlert } from '../alert/alertsSlice'
import {
  customFieldSelector,
  fetchCustomFields,
  setCustomFields,
  updateFieldSequences,
} from './customFieldSlice'
import TicketCustomFieldDialog from './TicketCustomFieldDialog'
import styles from './TicketCustomFieldList.module.css'

interface CustomFieldRow {
  id: number
  name: string
  type: CustomFieldType
  options: string[]
  slot: string
  editable: boolean
}
const dndItemType = 'customFieldRow'

const DraggableRow: FC<{
  row: Row<CustomFieldRow>
  index: number
  moveRow: (i: number, j: number) => void
  openDialog: (id: number | null) => void
  onDragEnd: () => void
}> = ({ row, index, moveRow, openDialog, onDragEnd }) => {
  const dropRef = useRef<HTMLTableRowElement>(null)
  const dragRef = useRef(null)

  const [, drop] = useDrop({
    accept: dndItemType,
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      hoverFunc(index, dropRef, moveRow, item, monitor)
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: dndItemType,
    item: { type: dndItemType, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      onDragEnd()
    },
  })

  const opacity = isDragging ? 0.3 : 1

  preview(drop(dropRef))
  drag(dragRef)

  return (
    <tr
      ref={dropRef}
      className={styles.tr}
      {...row.getRowProps()}
      onClick={() => {
        openDialog(row.original.id)
      }}
      style={{ opacity }}
    >
      <td className={styles.td} ref={dragRef}>
        <GripperBarHorizontalIcon className={styles.icon} />
      </td>
      {row.cells.map((cell) => {
        return (
          <td
            className={styles.td}
            {...cell.getCellProps()}
            key={cell.getCellProps().key}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}

const TicketCustomFieldList: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const customFieldsState = useSelector((state: RootState) => state.customField)
  const customFields = useSelector(customFieldSelector.selectAll)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedId, setSelectedId] = useState<number | null>(null)

  useEffect(() => {
    dispatch(fetchCustomFields(0, customFieldMaxLength))
  }, [dispatch])

  useEffect(() => {
    if (!customFieldsState.error) {
      return
    }
    if (customFieldsState.error === 'invalid argument') {
      dispatch(
        addAlert({
          id: Math.random() * 1000,
          title: 'データの不整合が発生したため、再読み込みしました',
          type: 'custom_field_error',
        })
      )
      dispatch(fetchCustomFields(0, customFieldMaxLength))
    } else {
      dispatch(
        addAlert({
          id: Math.random() * 1000,
          title: 'エラーが発生しました',
          type: 'custom_field_error',
        })
      )
    }
  }, [dispatch, customFieldsState.error])

  const columns: Column<CustomFieldRow>[] = useMemo(
    () => [
      {
        Header: 'フィールド名',
        accessor: 'name',
        Cell: ({ cell: { value } }) => <Box>{ellipsis(value, 40)}</Box>,
      },
      {
        Header: 'タイプ',
        accessor: 'type',
        Cell: ({ cell: { value } }) => (
          <Box>{CustomFieldTypeLabels[value]}</Box>
        ),
      },
      {
        Header: '選択肢',
        accessor: 'options',
        Cell: ({ cell: { value } }) => (
          <Box>{ellipsis(value.join('/'), 50)}</Box>
        ),
      },
      {
        Header: '編集',
        accessor: 'editable',
        Cell: ({ cell: { value } }) => <Box>{value ? '有効' : '無効'}</Box>,
      },
      {
        Header: '対応スロット',
        accessor: 'slot',
        Cell: ({ cell: { value } }) => <Box>{ellipsis(value, 30)}</Box>,
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<CustomFieldRow>({
      columns,
      data: customFields.map((r) => ({
        id: r.id,
        name: r.name,
        type: r.type as CustomFieldType,
        options: r.optionsList.map((o) => o.value),
        slot: r.slotContextName,
        editable: r.editable,
      })),
      getRowId: (row) => row.id.toString(),
    })

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const a = customFields[hoverIndex]
    const b = customFields[dragIndex]
    customFields[dragIndex] = a
    customFields[hoverIndex] = b
    dispatch(setCustomFields(customFields))
  }

  const openDialog = (id: number | null) => {
    setSelectedId(id)
    setDialogOpen(true)
  }
  return (
    <PageViewLog pageName={PageName.TicketCustomFieldList}>
      <Box className={styles.container}>
        <Flex>
          <Button
            icon={<ChevronStartIcon />}
            content="問い合わせ一覧"
            text
            onClick={() => {
              history.push('/tickets')
            }}
          />
          <Flex.Item push>
            <Flex gap="gap.medium" vAlign="center">
              {customFieldsState.loading && <Loader size="small" />}
              {customFields.length >= customFieldMaxLength && (
                <Text error>フィールド数の上限に達しています</Text>
              )}
              <Button
                content="新規フィールド"
                onClick={() => {
                  openDialog(null)
                }}
                disabled={
                  customFieldsState.loading ||
                  customFields.length >= customFieldMaxLength
                }
              />
            </Flex>
          </Flex.Item>
        </Flex>
      </Box>
      <Box className={styles.tableWrap}>
        <table {...getTableProps()} className={styles.table}>
          <thead className={styles.thead}>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps().key}
              >
                <th className={styles.th}></th>
                {headerGroup.headers.map((column) => (
                  <th
                    className={styles.th}
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <DraggableRow
                  index={index}
                  row={row}
                  moveRow={moveRow}
                  openDialog={openDialog}
                  onDragEnd={() => {
                    dispatch(
                      updateFieldSequences(customFields.map((r) => r.id))
                    )
                  }}
                  {...row.getRowProps()}
                  key={row.getRowProps().key}
                />
              )
            })}
          </tbody>
        </table>
      </Box>

      <TicketCustomFieldDialog
        open={dialogOpen}
        onCancel={() => {
          setDialogOpen(false)
        }}
        onOk={() => {
          setDialogOpen(false)
        }}
        id={selectedId}
      ></TicketCustomFieldDialog>
    </PageViewLog>
  )
}

export default TicketCustomFieldList
