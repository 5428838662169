import {
  BanIcon,
  CloseIcon,
  ExclamationTriangleIcon,
} from '@fluentui/react-icons-northstar'

interface Props {
  hover: boolean
  type?: string
  onClick: () => void
}

const AlertBoxIcon: React.FC<Props> = ({ hover, type, onClick }) => {
  if (!hover) {
    switch (type) {
      case 'file_num_exceed':
        return <BanIcon outline={true} />
      case 'upload_error':
        return <ExclamationTriangleIcon outline={true} />
      case 'custom_field_error':
        return <ExclamationTriangleIcon outline={true} />
    }
  }
  return <CloseIcon outline={true} onClick={onClick} />
}

export default AlertBoxIcon
