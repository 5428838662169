import {
  Button,
  Flex,
  Image,
  Loader,
  PopupIcon,
} from '@fluentui/react-northstar'
import * as microsoftTeams from '@microsoft/teams-js'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'

import { RootState } from '../../app/store'
import { fetchUploadFolderName } from '../auth/authSlice'
import { fetchTeamsApp } from '../auth/infoSlice'
import { fetchMembers } from '../auth/memberSlice'
import { fetchMe } from '../auth/usersSlice'
import styles from './Auth.module.css'
import {
  authenticate,
  exchangeToken,
  getContext,
  getSsoToken,
  setupGraphAPI,
} from './authSlice'

const AuthLogin: React.FC = () => {
  if (!microsoftTeams.app.isInitialized()) {
    return <AuthLoginWithoutTeamsAppInitilized />
  }
  return <AuthLoginWithTeamsAppInitilized />
}

const AuthLoginWithoutTeamsAppInitilized = () => {
  return loading
}

const AuthLoginWithTeamsAppInitilized: React.FC = () => {
  const location = useLocation<{ from: string | undefined }>()
  const dispatch = useDispatch()
  const {
    context,
    consentRequired,
    consentProvided,
    ssoToken,
    accessToken,
    graphAccessToken,
    error,
    needsInitialGuidance,
  } = useSelector((state: RootState) => state.auth)
  const memberState = useSelector((state: RootState) => state.member)
  const usersState = useSelector((state: RootState) => state.users)
  const infoState = useSelector((state: RootState) => state.info)
  const authState = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    dispatch(getContext())
    dispatch(getSsoToken())
  }, [dispatch])

  useEffect(() => {
    if (ssoToken == null) return
    dispatch(exchangeToken(ssoToken))
  }, [dispatch, ssoToken])

  useEffect(() => {
    if (graphAccessToken == null) return
    dispatch(setupGraphAPI(graphAccessToken))
  }, [dispatch, graphAccessToken])

  useEffect(() => {
    // 必須データ取得
    // 投げるだけ
    if (graphAccessToken == null) return
    dispatch(fetchMe())
    dispatch(fetchMembers())
    dispatch(fetchTeamsApp())
  }, [dispatch, graphAccessToken])

  useEffect(() => {
    /* ファイルアップロード先フォルダ名を取得 */
    if (graphAccessToken == null) return
    if (authState.availableFeatures == null) return
    if (authState.availableFeatures?.fileAttachment === false) return
    if (authState.uploadFolder != null) return
    const groupId = authState.context?.team?.groupId || ''
    const channelId = authState.context?.channel?.id || ''
    dispatch(fetchUploadFolderName(groupId, channelId))
  }, [dispatch, graphAccessToken, authState])

  if (
    error != null ||
    memberState.error != null ||
    usersState.error != null ||
    infoState.error != null
  ) {
    return <Redirect to={{ pathname: '/errors/unknown' }} />
  }
  if (needsInitialGuidance) {
    return (
      <Flex column gap="gap.large" hAlign="center" className={styles.container}>
        <div>
          <p>PKSHA AI Helpdeskをご利用ありがとうございます。</p>
          <p>
            ご利用中のMicrosoft TeamsとPKSHA AI
            Helpdeskのアカウントは接続されていないようです。弊社担当者にご連絡いただきますか、
            下記Webページからご利用開始についてお問い合わせください。
          </p>
        </div>
        <div>
          <Button
            onClick={() =>
              microsoftTeams.app.openLink(
                'https://site.workplace.bedore.jp/contact/'
              )
            }
          >
            お問い合わせ
            <PopupIcon className={styles.openCustomFieldIcon} />
          </Button>
        </div>
      </Flex>
    )
  }
  if (consentRequired && !consentProvided) {
    return (
      <Flex column gap="gap.large" hAlign="center" className={styles.container}>
        <div>
          ご利用頂くためには、組織で発行しているMicrosoftアカウントでサインインしてください。
        </div>
        <div>
          <Image
            className={styles.login}
            onClick={() => {
              dispatch(authenticate())
            }}
            width={'320px'}
            src="/assets/login.svg"
          />
        </div>
      </Flex>
    )
  }

  if (context == null) {
    return loading
  }

  if (graphAccessToken == null) {
    return loading
  }

  if (accessToken == null) {
    return loading
  }

  /*
    FAQ作成提案画面に直接遷移する場合です。
  */
  if (IsForRecommendedFAQs(context)) {
    // NOTE: リクエストパラメータのsubEntityIdに"faq"が指定された場合に、FAQ作成提案ページへ遷移する実装とした
    // 例: https://teams.microsoft.com/l/entity/b77a07c8-5e75-4dd3-b122-586b1854e561/3c7f0ac0-6962-479f-873c-dd8b3019f980?context={"subEntityId":"faq","channelId":"19:xNF3pLttmcaHlDRkH-HtaIbHvg0V7KV0fGQdXYtKgLU1@thread.tacv2"}
    return <Redirect to="recommended_faqs" />
  }

  /*
    チケット詳細画面に直接遷移場合です。
  */
  if (isForTicketDetail(context)) {
    const to = `/tickets/${context.page.subPageId}`
    return <Redirect to={to} />
  }

  /*
    直接チケット一覧画面にいく場合です。
  */
  const to = location.state?.from ?? '/'
  return <Redirect to={to} />
}

const isForTicketDetail = (context: microsoftTeams.app.Context): boolean => {
  return !!context.page.subPageId && context.page.subPageId !== ''
}

const IsForRecommendedFAQs = (context: microsoftTeams.app.Context): boolean => {
  return !!context.page.subPageId && context.page.subPageId === 'faq'
}

const loading = (
  <Loader
    styles={() => ({
      height: '100vh',
    })}
    label="読み込み中..."
  />
)

export default AuthLogin
