import { Datepicker, Flex, Text } from '@fluentui/react-northstar'
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import ticket_custom_field from '../../../proto/ticket_custom_field_pb'
import { customFormatter, displayDate } from '../../../utils'
import {
  deleteOneCustomFieldFilter,
  setCustomDateFieldValuesFilter,
} from '../ticketSlice'
import styles from './TicketCustomFieldFilter.module.css'

type Props = {
  customField: ticket_custom_field.TicketCustomField.AsObject
}

const datePattern = 'yyyy/MM/dd'

const TicketCustomFieldValueDateFilter: React.FC<Props> = ({ customField }) => {
  const ticketState = useSelector((state: RootState) => state.ticket)

  let dateFilterValue: {
    startDate?: string
    endDate?: string
  }
  dateFilterValue = {}
  let initialFilterStartDate = undefined
  let initialFilterEndDate = undefined
  if (
    ticketState.filters.customFieldValues.dateFilters[customField.id] != null
  ) {
    dateFilterValue =
      ticketState.filters.customFieldValues.dateFilters[customField.id]
    if ('startDate' in dateFilterValue) {
      initialFilterStartDate = String(dateFilterValue.startDate)
    }
    if ('endDate' in dateFilterValue) {
      initialFilterEndDate = String(dateFilterValue.endDate)
    }
  }
  const [startInputDate, setStartInputDate] = useState(initialFilterStartDate)
  const [endInputDate, setEndInputDate] = useState(initialFilterEndDate)
  const [isError, setIsError] = useState(false)
  const isMountRef = useRef(true)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isMountRef.current) {
      isMountRef.current = false
      return
    }

    if (
      startInputDate != null &&
      endInputDate != null &&
      new Date(startInputDate) > new Date(endInputDate)
    ) {
      setIsError(true)
      return
    } else {
      setIsError(false)
    }

    if (startInputDate == null && endInputDate == null) {
      dispatch(
        deleteOneCustomFieldFilter({
          customFieldId: customField.id,
          customFieldType: customField.type,
        })
      )
      return
    }

    const filterArg: {
      customFieldId: number
      startDate?: string
      endDate?: string
    } = { customFieldId: customField.id }
    if (startInputDate !== '' && startInputDate != null) {
      filterArg.startDate = startInputDate
    }
    if (endInputDate !== '' && endInputDate != null) {
      filterArg.endDate = endInputDate
    }
    dispatch(setCustomDateFieldValuesFilter(filterArg))
  }, [dispatch, customField.id, startInputDate, endInputDate, customField.type])

  return (
    <Flex column gap="gap.small" hAlign="start" space="around">
      <Text content="絞り込み" weight="bold" className={styles.headingText} />
      {isError && (
        <Text
          content="開始日は終了日より前の日付に設定してください"
          styles={({ theme: { siteVariables } }) => ({
            color: siteVariables.colorScheme.red.foreground,
            fontSize: '12px',
          })}
        />
      )}
      <Flex vAlign="center">
        <Text
          content="開始日"
          weight="bold"
          className={styles.inputAreaHeading}
        />
        <Datepicker
          allowManualInput={false}
          inputPlaceholder="日付を選択"
          formatMonthDayYear={customFormatter}
          selectedDate={displayDate(startInputDate)}
          onDateChange={(_, date) => {
            if (date?.value == null) return setStartInputDate(undefined)
            const formattedDateString = format(date.value, datePattern)
            setStartInputDate(formattedDateString)
          }}
          input={{
            clearable: true,
          }}
        />
      </Flex>
      <Flex vAlign="center">
        <Text
          content="終了日"
          weight="bold"
          className={styles.inputAreaHeading}
        />
        <Datepicker
          allowManualInput={false}
          inputPlaceholder="日付を選択"
          formatMonthDayYear={customFormatter}
          selectedDate={displayDate(endInputDate)}
          onDateChange={(_, date) => {
            if (date?.value == null) return setEndInputDate(undefined)
            const formattedDateString = format(date.value, datePattern)
            setEndInputDate(formattedDateString)
          }}
          input={{
            clearable: true,
          }}
        />
      </Flex>
    </Flex>
  )
}

export default TicketCustomFieldValueDateFilter
