import { Filter } from '@cubejs-client/core'
import { useCubeQuery } from '@cubejs-client/react'
import addDays from 'date-fns/addDays'
import differenceInDays from 'date-fns/differenceInDays'
import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as DashboardFilterSidepanelSelectors from '../../../DashboardFilterSidepanel/selectors'
import WeekdayTicketsChartPresenter, { ChartRow, Weekday } from './presenter'

function weekNumToJP(weeknum: number): Weekday {
  switch (weeknum) {
    case 0:
      return '日'
    case 1:
      return '月'
    case 2:
      return '火'
    case 3:
      return '水'
    case 4:
      return '木'
    case 5:
      return '金'
    case 6:
      return '土'
    default:
      return '日'
  }
}

/**
 * Cubeのクエリデータ(1日ごとの件数)からグラフ用のデータを作成します
 */
function weekdayAveragePerMonthQueryRowsToChartData(
  rows: { [key: string]: string | number | boolean | null }[],
  startDate: string,
  endDate: string
): ChartRow[] {
  // 連続したstartDateからendDateまでの日付を作成
  const sequentialDate = Array.from(
    Array(differenceInDays(new Date(endDate), new Date(startDate))).keys()
  ).map((key) => {
    const currentDate = addDays(new Date(startDate), key)
    return {
      date: format(currentDate, 'yyyy-MM-dd'),
      weekday: getDay(currentDate),
    }
  })
  type SequentialTicketCount = {
    date: string
    weekday: number
    count: number
  }

  // 連続した日付にクエリデータの'チケット数'をマージ
  const sequentialTicketCounts: SequentialTicketCount[] = sequentialDate.map(
    (date) => {
      const ticketCount = rows.find((data) => {
        return data['Tickets.date'] === date.date
      })

      return {
        date: date.date,
        weekday: date.weekday,
        count: (ticketCount?.['Tickets.count'] as number | undefined) ?? 0,
      }
    }
  )

  // 曜日のリストを作っておく
  // exp. --> [0, 1, 2, 3, 4, 5, 6, 7]
  const uniqueWeekday = Array.from(
    new Set(sequentialTicketCounts.map((stc) => stc.weekday))
  ).sort((a, b) => (a > b ? 1 : -1))
  const foldRowToTotal = (acc: number, cur: SequentialTicketCount) =>
    acc + cur.count

  const rowEqWeekday = (weekday: number) => (row: SequentialTicketCount) =>
    row.weekday === weekday

  // 月曜日から日曜日までのチケット数の平均を計算
  return uniqueWeekday.map((weekday) => {
    const currentWeekdayRows = sequentialTicketCounts.filter(
      rowEqWeekday(weekday as number)
    )

    return {
      weekday: weekNumToJP(weekday as number),
      ['平均問い合わせ数']:
        currentWeekdayRows.reduce(foldRowToTotal, 0) /
        currentWeekdayRows.length,
    }
  })
}

const WeekdayTicketsChart: React.FC = () => {
  const startDate = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.startDateForQuery
  )
  const endDate = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.endDateForQuery
  )
  const assignedUserIdsFilter: Filter | null = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.assigneeUserIdsFilterForTickets
  )

  const filters: Filter[] | undefined = assignedUserIdsFilter
    ? [assignedUserIdsFilter]
    : undefined

  const query = useCubeQuery({
    dimensions: ['Tickets.weekday', 'Tickets.date'],
    measures: ['Tickets.count'],
    timeDimensions: [
      {
        dimension: 'Tickets.createdAt',
        dateRange: [startDate, endDate],
      },
    ],
    filters: filters,
    order: [['Tickets.date', 'asc']],
    timezone: 'Asia/Tokyo',
  })

  const data = React.useMemo(
    () =>
      weekdayAveragePerMonthQueryRowsToChartData(
        query.resultSet?.rawData() ?? [],
        startDate,
        endDate
      ),
    [query.resultSet, startDate, endDate]
  )

  return <WeekdayTicketsChartPresenter data={data} />
}

export default React.memo(WeekdayTicketsChart)
