import {
  BoldIcon,
  Box,
  BulletsIcon,
  ChevronDownIcon,
  Flex,
  FontColorIcon,
  FontSizeIcon,
  HighlightIcon,
  HorizontalRuleIcon,
  IndentIcon,
  ItalicIcon,
  LinkIcon,
  NumberListIcon,
  OutdentIcon,
  QuoteIcon,
  RemoveFormatIcon,
  ShorthandCollection,
  StrikeIcon,
  Toolbar,
  ToolbarItemProps,
  ToolbarItemShorthandKinds,
  ToolbarProps,
  TrashCanIcon,
  UnderlineIcon,
} from '@fluentui/react-northstar'
import React, { useRef } from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../app/store'
import { ChatInputEditorUtils } from '../features/chat/ChatInputEditorUtils'
import { ChatLink } from '../features/chat/ChatLink'
import {
  setLinkMenuOpen,
  setTrashOpen,
} from '../features/editor/editorToolSlice'
import styles from './RichTextToolBar.module.css'
import {
  FontColorToolBarItem,
  FontSizeToolBarItem,
  HighLightToolBarItem,
  RichStyleToolBarItem,
} from './toolbar_items'
import { FontColorToolBarContent } from './toolbar_items/FontColorToolBarItem'
import { FontSizeToolBarContent } from './toolbar_items/FontSizeIconToolBarItem'
import { HightLightToolBarContent } from './toolbar_items/HighLightToolBarItem'
import {
  RichStyleToolBarContent,
  richTextItems,
} from './toolbar_items/RichStyleToolBarItem'

export interface RichTextToolBarProps {
  hidden: boolean
  onStyleToggled: (
    type: 'BOLD' | 'ITALIC' | 'UNDERLINE' | 'STRIKETHROUGH'
  ) => void
  onHighLightToggle: (color: string) => void
  holdSelection: () => void
  onColorToggle: (color: string) => void
  onFontSizeToggle: (fontSize: string) => void
  onRichStyleToggle: (type: string) => void
  currentBlockType: string
  onFormatClear: () => void
  onIndentStyleToggle: (indent: boolean) => void
  onHorizonalLineAdded: () => void
}

const RichTextToolBar: React.FC<RichTextToolBarProps> = (props) => {
  const {
    onStyleToggled,
    onHighLightToggle,
    onColorToggle,
    holdSelection,
    onFontSizeToggle,
    onRichStyleToggle,
    onFormatClear,
    onIndentStyleToggle,
    onHorizonalLineAdded,
    hidden,
  } = props

  const [richTextIndex, setRichTextIndex] = useState<number>(3)
  const editorState = useSelector(
    (state: RootState) => state.editorState
  ).editorState

  const linkPopUpTarget = useRef<HTMLDivElement>(null)
  const hasImageSelected =
    ChatInputEditorUtils.currentSelectionContainsImage(editorState)
  const singleLineSelected =
    ChatInputEditorUtils.singleLineSelected(editorState)
  useEffect(() => {
    const currentBlockType = props.currentBlockType
    const index = richTextItems.findIndex(
      (item) => item.key === currentBlockType
    )
    if (index >= 0) {
      setRichTextIndex(index)
    }
  }, [props.currentBlockType])

  const dispatch = useDispatch()
  const items: ShorthandCollection<
    ToolbarItemProps,
    ToolbarItemShorthandKinds
  > = [
    {
      icon: (
        <BoldIcon
          onMouseDown={(e) => e.preventDefault()}
          {...{
            outline: true,
          }}
        />
      ),
      key: 'bold',
      kind: 'toggle',
      title: 'Toggle bold',
      onClick: () => {
        onStyleToggled('BOLD')
      },
    },
    {
      icon: (
        <ItalicIcon
          onMouseDown={(e) => e.preventDefault()}
          {...{
            outline: true,
          }}
        />
      ),
      key: 'italic',
      kind: 'toggle',
      title: 'Toggle italic',
      onClick: () => {
        onStyleToggled('ITALIC')
      },
    },
    {
      icon: (
        <UnderlineIcon
          onMouseDown={(e) => e.preventDefault()}
          {...{
            outline: true,
          }}
        />
      ),
      key: 'underline',
      kind: 'toggle',
      title: 'Toggle underline',
      onClick: () => {
        onStyleToggled('UNDERLINE')
      },
    },

    {
      icon: (
        <StrikeIcon
          onMouseDown={(e) => e.preventDefault()}
          {...{
            outline: true,
          }}
        />
      ),
      key: 'strike',
      kind: 'toggle',
      title: 'Toggle strike',
      onClick: () => {
        onStyleToggled('STRIKETHROUGH')
      },
    },

    {
      key: 'divider-1',
      kind: 'divider',
    },
    {
      icon: (
        <HighLightToolBarItem
          onClick={onHighLightToggle}
          holdSelection={holdSelection}
        />
      ),
      key: 'highLight',
      title: 'highLight',
    },
    {
      icon: (
        <FontColorToolBarItem
          onClick={onColorToggle}
          holdSelection={holdSelection}
        />
      ),
      key: 'fontColor',
      title: 'fontColor',
    },
    {
      icon: (
        <FontSizeToolBarItem
          onClick={onFontSizeToggle}
          holdSelection={holdSelection}
        />
      ),
      key: 'fontSize',
      title: 'fontSize',
    },
    {
      icon: (
        <RichStyleToolBarItem
          onClick={onRichStyleToggle}
          currentBlockType={props.currentBlockType}
          icon={true}
        />
      ),
      key: 'richStyle',
      title: 'richStyle',
      className: styles.richStyle,
    },
    {
      icon: (
        <RemoveFormatIcon
          onMouseDown={(e) => e.preventDefault()}
          outline={true}
        />
      ),
      key: 'remove-format',
      title: 'Remove formatting',
      onClick: () => onFormatClear(),
    },
    {
      key: 'divider-2',
      kind: 'divider',
    },
    {
      icon: <OutdentIcon outline={true}></OutdentIcon>,
      key: 'outdent',
      title: 'Outdent',
      onClick: () => onIndentStyleToggle(false),
    },
    {
      icon: <IndentIcon outline={true}></IndentIcon>,
      key: 'indent',
      title: 'Indent',
      onClick: () => onIndentStyleToggle(true),
    },
    {
      icon: <BulletsIcon outline={true} />,
      key: 'unorderedList',
      title: 'unorderedList',
      onClick: () => onRichStyleToggle('unordered-list-item'),
    },
    {
      icon: <NumberListIcon outline={true} />,
      key: 'orderedList',
      title: 'orderedList',
      onClick: () => onRichStyleToggle('ordered-list-item'),
    },
    {
      icon: <QuoteIcon outline={true} />,
      key: 'quotation',
      title: 'quotation',
      onClick: () => onRichStyleToggle('blockquote'),
    },
    {
      icon: (
        <ChatLink editorState={editorState} popUpTarget={linkPopUpTarget} />
      ),
      key: 'chatLink',
      title: 'chatLink',
    },
    {
      icon: <HorizontalRuleIcon outline={true} />,
      key: 'horizontalRule',
      content: '水平線を挿入',
      title: 'horizontalRule',
      onClick: () => onHorizonalLineAdded(),
    },
  ]

  const [overflowOpen, setOverflowOpen] = React.useState(false)

  const overflowItems: ToolbarItemProps['menu'] = [
    {
      icon: <HighlightIcon outline={true} />,
      key: 'highLight',
      title: 'highLight',
      content: 'テキストのハイライト カラー',
      popup: (
        <HightLightToolBarContent
          setOpen={setOverflowOpen}
          onClick={onHighLightToggle}
        />
      ),
    },

    {
      icon: <FontColorIcon outline={true} />,
      key: 'fontColor',
      title: 'fontColor',
      content: 'フォントの色',
      popup: (
        <FontColorToolBarContent
          setOpen={setOverflowOpen}
          onClick={onColorToggle}
        />
      ),
    },
    {
      icon: <FontSizeIcon outline={true} />,
      key: 'fontSize',
      title: 'fontSize',
      content: 'フォント サイズ',
      popup: (
        <FontSizeToolBarContent
          setOpen={setOverflowOpen}
          onClick={onFontSizeToggle}
        />
      ),
    },
    {
      icon: <ChevronDownIcon size="smaller" />,
      key: 'richStyle',
      title: 'richStyle',
      content: richTextItems[richTextIndex].header,
      className: styles.richStyle,
      popup: (
        <RichStyleToolBarContent
          onClick={onRichStyleToggle}
          setOpen={setOverflowOpen}
          setIndex={setRichTextIndex}
        />
      ),
    },
    {
      icon: (
        <RemoveFormatIcon
          onMouseDown={(e) => e.preventDefault()}
          outline={true}
        />
      ),
      key: 'remove-format',
      title: 'Remove formatting',
      content: 'すべての書式設定をクリア',
      onClick: () => onFormatClear(),
    },
    {
      key: 'divider-2',
      kind: 'divider',
    },
    {
      icon: <OutdentIcon outline={true}></OutdentIcon>,
      key: 'outdent',
      title: 'Outdent',
      content: 'インデントを減らす',
      onClick: () => onIndentStyleToggle(false),
    },
    {
      icon: <IndentIcon outline={true}></IndentIcon>,
      key: 'indent',
      title: 'Indent',
      content: 'インデントを増やす',
      onClick: () => onIndentStyleToggle(true),
    },
    {
      icon: <BulletsIcon outline={true} />,
      key: 'unorderedList',
      title: 'unorderedList',
      content: '箇条書き',
      onClick: () => onRichStyleToggle('unordered-list-item'),
    },
    {
      icon: <NumberListIcon outline={true} />,
      key: 'orderedList',
      content: '番号付きリスト',
      title: 'orderedList',
      onClick: () => onRichStyleToggle('ordered-list-item'),
    },
    {
      key: 'divider-3',
      kind: 'divider',
    },
    {
      icon: <QuoteIcon outline={true} />,
      key: 'quotation',
      title: 'quotation',
      content: '引用',
      onClick: () => onRichStyleToggle('blockquote'),
    },
    {
      icon: <LinkIcon />,
      key: 'chatLink',
      title: 'chatLink',
      content: 'リンクを挿入',
      disabled: hasImageSelected || !singleLineSelected,
      onClick: (e: React.SyntheticEvent<HTMLElement>) => {
        e.stopPropagation()
        dispatch(setLinkMenuOpen(true))
      },
    },
    {
      icon: <HorizontalRuleIcon outline={true} />,
      key: 'horizontalRule',
      content: '水平線を挿入',
      title: 'horizontalRule',
      onClick: () => onHorizonalLineAdded(),
    },
  ]

  return (
    <Box>
      <Box className={styles.linkPopUpTarget} ref={linkPopUpTarget}></Box>
      <Flex className={styles.container} hidden={hidden}>
        <Flex className={styles.toolbarContainer}>
          <Toolbar
            className={styles.toolbar}
            aria-label="Default"
            items={items}
            overflow
            overflowOpen={overflowOpen}
            overflowItem={{
              title: 'More',
            }}
            onOverflowOpenChange={(e, data?: ToolbarProps) => {
              const overflowOpen = data?.overflowOpen
              if (overflowOpen == null) {
                return
              }
              setOverflowOpen(overflowOpen)
            }}
            getOverflowItems={(startIndex) =>
              overflowItems.slice(startIndex - 5)
            }
          />
        </Flex>

        <Box className={styles.trash}>
          <TrashCanIcon
            outline={true}
            onClick={() => dispatch(setTrashOpen(true))}
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default RichTextToolBar
