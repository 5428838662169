import {
  Button,
  Chat,
  ChatItemProps,
  Flex,
  Text,
} from '@fluentui/react-northstar'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import { RootState } from '../../../../app/store'
import { AttachmentType, Tickets } from '../../../../consts'
import { fetchUsers } from '../../../../features/auth/usersSlice'
import { parseRichText } from '../../../../features/chat/Chat'
import chatImageStyles from '../../../../features/chat/ChatImage.module.css'
import ChatItemAttachments from '../../../../features/chat/ChatItemAttachments'
import { ChatItemRichText } from '../../../../features/chat/ChatItemRichText'
import chatItemsStyles from '../../../../features/chat/ChatItems.module.css'
import message_pb from '../../../../proto/message_pb'
import {
  formatShort,
  getDisplayName,
  getWebagentDisplayName,
} from '../../../../utils'
import { useGetTicketQuery, useListMessagesQuery } from '../../api'
import * as Selectors from '../../selector'
import * as Slice from '../../slice'
import {
  RecommendedFaqTicketEntity,
  RecommendedFaqTicketManualMessageEntity,
} from '../../types/RecommendedFaqTicketEntity'
import RecommendedFaqTicketChatPastAutoChatToggle from '../RecommendedFaqTicketChatPastAutoChatToggle'
import RecommendedFaqTicketChatPresenter from './presenter'
import styles from './styles.module.css'

type Props = {
  title: string
  ticketId: number
}

type ChatItemPropsWithKey = ChatItemProps & { key: string }

const RecommendedFaqTicketChat: React.FC<Props> = (props) => {
  const dispatch = ReactRedux.useDispatch()

  const { data: ticketData, isLoading: ticketLoading } = useGetTicketQuery({
    id: props.ticketId,
  })
  const { data: messagesData, isLoading: messagesLoading } =
    useListMessagesQuery({ ticketId: props.ticketId })

  const showAllAutoMessage = ReactRedux.useSelector((state: RootState) =>
    Selectors.showAllAutoMessageSelector(state)
  )
  const usersState = ReactRedux.useSelector((state: RootState) => state.users)
  const teamsAppInstallationState = ReactRedux.useSelector(
    (state: RootState) => state.info.teamsAppInstallation
  )
  const appName = teamsAppInstallationState?.teamsApp?.displayName || ''

  React.useEffect(() => {
    dispatch(Slice.actions.resetShowAllAutoMessage())
  }, [dispatch, props.ticketId])

  const handlePastAutoChatToggleClick = React.useCallback(() => {
    dispatch(Slice.actions.toggleShowAllAutoMessage())
  }, [dispatch])

  const pastAutoChatToggle: ChatItemPropsWithKey = React.useMemo(() => {
    return {
      key: 'pastAutoChatToggleButton',
      styles: {
        padding: 0,
        '& > div': {
          margin: 0,
          width: '100%',
        },
      },
      density: 'compact',
      message: (
        <RecommendedFaqTicketChatPastAutoChatToggle
          showAllAutoMessage={showAllAutoMessage}
          onClick={handlePastAutoChatToggleClick}
        />
      ),
    }
  }, [showAllAutoMessage, handlePastAutoChatToggleClick])

  // 自動応答
  const createAutoChatItems = React.useCallback(
    (ticket: RecommendedFaqTicketEntity): ChatItemPropsWithKey[] => {
      const { requestResponses, requesterType, requesterUserId } = ticket
      if (ticket.requestResponses.length === 0) {
        return []
      }

      // idの付与
      const list = requestResponses
        .map(({ messages, conversationId }, rIndex) =>
          messages.map((message, mIndex) => ({
            ...message,
            conversationId: conversationId,
            id: `a-${rIndex}-${mIndex}`,
          }))
        )
        .flat()

      // 最新の自動応答情報
      const lastRequestResponse = requestResponses[requestResponses.length - 1]

      const requesterName =
        requesterType === Tickets.RequesterType.RequesterTypeWebagent
          ? getWebagentDisplayName(requesterUserId)
          : getDisplayName(usersState.entities[requesterUserId])

      return (
        showAllAutoMessage
          ? list
          : list.filter(
              (l) => l.conversationId === lastRequestResponse.conversationId
            )
      ).map((message, index) => {
        const userName =
          message.userType === 'auto' || message.userType === 'manual'
            ? appName
            : requesterName
        const time = formatShort(message.time / 1000)
        const key = message.id
        const mine =
          message.userType === 'auto' || message.userType === 'manual'
        const withButton = message?.content?.type === 'buttons'
        return createChatItem(
          message.text,
          withButton,
          mine ? `${appName} ${time}` : time,
          key,
          index === 0,
          mine,
          userName,
          []
        )
      })
    },
    [usersState.entities, appName, showAllAutoMessage]
  )

  // 有人連携
  const createManualChatItems = React.useCallback(
    (
      ticket: RecommendedFaqTicketEntity,
      messages: RecommendedFaqTicketManualMessageEntity[]
    ): ChatItemPropsWithKey[] => {
      return messages
        .map((message, mIndex) => {
          return message.eventsList.map((event, eIndex) => {
            const time = formatShort(event.timestamp)
            const mine =
              message.senderType === Tickets.Messages.SenderTypeOperator
            const text = event.message?.text || ''
            const userName =
              ticket.requesterType ===
              Tickets.RequesterType.RequesterTypeWebagent
                ? message.senderType === Tickets.Messages.SenderTypeRequester
                  ? getWebagentDisplayName(ticket.requesterUserId)
                  : getDisplayName(usersState.entities[message.userId])
                : getDisplayName(usersState.entities[message.userId])
            const key = `m-${mIndex}-${eIndex}`
            return createChatItem(
              text,
              false,
              mine ? `${userName} ${time}` : time,
              key,
              false,
              mine,
              userName,
              event.attachmentsList,
              ticket.requesterUserId
            )
          })
        })
        .filter((e) => e != null)
        .flat()
    },
    [usersState.entities]
  )

  const createChatItem = (
    text: string,
    withButton: boolean,
    timeStamp: string,
    key: string,
    isFirst: boolean,
    mine: boolean,
    userName: string,
    attachments: Array<message_pb.ResponseAttachment.AsObject>,
    userId?: string
  ): ChatItemPropsWithKey => {
    const richTextData = attachments.find(
      (s) => s.type === AttachmentType.textHTML
    )
    const richText = parseRichText(richTextData)
    const hasAttachments = attachments.some(
      (attachment) => attachment.type === AttachmentType.onedrive
    )

    return {
      key: key,
      className: `${mine ? styles.rightChatItem : styles.leftChatItem} ${
        isFirst ? styles.firstChatItem : ''
      }`,
      contentPosition: mine ? 'end' : 'start',
      message: (
        <Chat.Message
          key={key}
          className={mine ? styles.rightChatMessage : styles.leftChatMessage}
          styles={{
            [`& .${chatImageStyles.imageContainer} img`]: {
              maxWidth: '100%',
            },
            '& .chat-item-attachment-bar': {
              width: '100%',
            },
          }}
          content={
            <Flex column gap="gap.small">
              {richText ? (
                <div className={chatItemsStyles.richText}>
                  <ChatItemRichText id={key} content={richText} />
                </div>
              ) : (
                <Text>
                  {text &&
                    text.trim().length > 0 &&
                    text.split('\n').map((str, index) => (
                      <React.Fragment key={index}>
                        {str}
                        <br />
                      </React.Fragment>
                    ))}
                </Text>
              )}
              {withButton ? (
                <Button
                  size={'small'}
                  fluid
                  inverted={true}
                  content="ボタンを表示"
                  disabled
                />
              ) : null}
              {hasAttachments ? (
                <ChatItemAttachments
                  attachments={attachments}
                  userId={userId}
                  id={key}
                />
              ) : null}
            </Flex>
          }
          author={userName}
          mine={mine}
          timestamp={timeStamp}
        />
      ),
    }
  }

  const chatItems: ChatItemPropsWithKey[] = React.useMemo(() => {
    if (ticketLoading || messagesLoading) {
      return []
    }
    if (!ticketData?.entity || !messagesData) {
      return []
    }

    const ticket = ticketData.entity
    return [
      pastAutoChatToggle,
      ...createAutoChatItems(ticket),
      ...createManualChatItems(ticket, messagesData.entities),
    ]
  }, [
    ticketData,
    messagesData,
    ticketLoading,
    messagesLoading,
    pastAutoChatToggle,
    createAutoChatItems,
    createManualChatItems,
  ])

  React.useEffect(() => {
    if (ticketLoading || messagesLoading) {
      return
    }
    if (!ticketData?.entity || !messagesData) {
      return
    }

    const userIds = [...new Set(messagesData?.entities.map((m) => m.userId))]
    const ticket = ticketData.entity
    if (ticket.requesterType === Tickets.RequesterType.RequesterTypeWebagent) {
      return
    }
    dispatch(fetchUsers(userIds))
  }, [
    dispatch,
    ticketLoading,
    messagesLoading,
    ticketData?.entity,
    messagesData,
  ])

  return (
    <RecommendedFaqTicketChatPresenter
      isLoading={ticketLoading || messagesLoading}
      title={props.title}
      chatItems={chatItems}
    />
  )
}

export default React.memo(RecommendedFaqTicketChat)
