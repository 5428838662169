import { Flex, Form, FormButton, Loader, Text } from '@fluentui/react-northstar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { RootState } from '../../app/store'
import { useCreateFaqMutation } from '../../renewfeatures/deskApiOperator'
import { addAlert } from '../alert/alertsSlice'
import { updateTicketOnMessage } from '../ticket/ticketSlice'
import FaqDetail from './FaqDetail'
import FaqDraft from './FaqDraft'
import FaqForm from './FaqForm'
import {
  clearDraftFaqCompleted,
  clearFaqError,
  clearGeneratedDraftFaq,
} from './faqSlice'

const FaqCreate: React.FC = () => {
  const dispatch = useDispatch()

  const { ticketId } = useParams<{ ticketId: string }>()

  const faqState = useSelector((state: RootState) => state.faq)
  const ticketState = useSelector((state: RootState) => state.ticket)

  const ticket = ticketState.entities[ticketId]

  const [requestValue, setRequestValue] = useState('')
  const [responseValue, setResponseValue] = useState('')
  const [publish, setPublish] = useState(true)
  const [name, setName] = useState('')
  const [labels, setLabels] = useState<string[]>([])
  const [createFaq, { isLoading }] = useCreateFaqMutation()

  const handleCreateFaq = () => {
    createFaq({
      ticketId: parseInt(ticketId, 10),
      request: requestValue || '',
      response: responseValue || '',
      publish: publish || false,
      name: name || '',
      labelsList: labels.map((it) => parseInt(it, 10)),
      from: 'ticket_detail',
    })
      .unwrap()
      .then((res) => {
        dispatch(dispatch(updateTicketOnMessage({ message: res })))
      })
  }

  useEffect(() => {
    dispatch(clearGeneratedDraftFaq())
    dispatch(clearDraftFaqCompleted())
    dispatch(clearFaqError())

    setRequestValue('')
    setResponseValue('')
    setPublish(true)
    setName('')
    setLabels([])
  }, [dispatch])

  useEffect(() => {
    setRequestValue(faqState.generatedRequest)
    setResponseValue(faqState.generatedResponse)
  }, [faqState.generatedRequest, faqState.generatedResponse])

  useEffect(() => {
    if (faqState.error !== null) {
      dispatch(
        addAlert({
          id: Math.random() * 1000,
          title:
            'FAQの作成タブで問題が発生しました。しばらくお待ちいただいてから、もう一度お試しください。',
          type: 'faq_create_error',
        })
      )
    }
  }, [dispatch, faqState.error])

  useEffect(() => {
    if (faqState.draftFaqCompleted) {
      dispatch(
        addAlert({
          id: Math.random() * 1000,
          title: 'FAQの自動作成が完了しました',
          type: 'success_announcement',
        })
      )
      dispatch(clearDraftFaqCompleted())
    }
  }, [dispatch, faqState.draftFaqCompleted])

  return (
    <>
      {ticket?.faqCreated ? (
        <FaqDetail />
      ) : (
        <Flex column>
          <FaqDraft />
          <Form onSubmit={() => handleCreateFaq()}>
            <Flex column gap="gap.large">
              <FaqForm
                disabled={false}
                requestValue={requestValue}
                setRequestValue={setRequestValue}
                responseValue={responseValue}
                setResponseValue={setResponseValue}
                publish={publish}
                setPublish={setPublish}
                name={name}
                setName={setName}
                labels={labels}
                setLabels={setLabels}
              />
              <Flex column gap="gap.smaller">
                {faqState.loading || isLoading ? (
                  <Loader size="small" />
                ) : (
                  <FormButton
                    fluid
                    primary
                    content="チャットボットに登録"
                    disabled={!requestValue || !responseValue}
                  />
                )}
                <Text align={'center'} disabled>
                  作成後、自動で学習を行います
                </Text>
                <Text align={'center'} disabled>
                  FAQを作成後も編集ができます
                </Text>
              </Flex>
            </Flex>
          </Form>
        </Flex>
      )}
    </>
  )
}

export default FaqCreate
