import {
  HelpIcon,
  OpenEnrollmentIcon,
  TransitionIcon,
} from '@fluentui/react-icons-mdl2'
import {
  BellIcon,
  DownloadIcon,
  MoreIcon,
  NotesIcon,
  PollIcon,
  PopupIcon,
  ReplyIcon,
  SendIcon,
} from '@fluentui/react-icons-northstar'
import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  Flex,
  Loader,
  MenuButton,
  Popup,
  Provider,
  Text,
} from '@fluentui/react-northstar'
import * as microsoftTeams from '@microsoft/teams-js'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as ReactRedux from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import {
  CellProps,
  Column,
  useColumnOrder,
  usePagination,
  useTable,
} from 'react-table'
import { debounce } from 'throttle-debounce'

import { RootState } from '../../app/store'
import PageViewLog from '../../components/PageViewLog'
import {
  CustomFieldType,
  CustomFieldValue,
  DefaultFields,
  FaqStatus,
  PageName,
  Sort,
  Tickets as TicketConsts,
  TicketStatus,
  Tickets,
  customFieldMaxLength,
} from '../../consts'
import { useIsAvailableActivityMessageQuery } from '../../renewfeatures/deskApiOperator'
import { useGetRecommendedFaqListQuery } from '../../renewfeatures/RecommendedFaqList/api'
import * as RecommendedFaqSelector from '../../renewfeatures/RecommendedFaqList/selector'
import {
  consoleErrorWithAirbrake,
  ellipsis,
  getQueryParamsByKey,
} from '../../utils'
import AssigneeSelection from '../assigneeSelection'
import { fetchUserPhotos } from '../auth/userPhotosSlice'
import { fetchUsers } from '../auth/usersSlice'
import {
  DefaultTabConfig,
  FieldConfig,
  fetchTabConfig,
} from '../tabConfig/tabConfigSlice'
import AutoAssignConfigDialog from './AutoAssignConfigDialog'
import CSVExportDialog from './CSVExportDialog'
import { customFieldSelector, fetchCustomFields } from './customFieldSlice'
import FaqStatusLabel from './FaqStatusLabel'
import ManualTicketSettingDialog from './ManualTicketSettingDialog'
import NotificationDialog from './NotificationSettingDialog'
import TenantAppCSVExportDialog from './TenantAppCSVExportDialog'
import TicketCustomFieldFilterButton from './ticketCustomFieldValueFilter/TicketCustomFieldFilterButton'
import TicketCustomFieldValueFilter from './ticketCustomFieldValueFilter/TicketCustomFieldValueFilter'
import TicketFieldFilter from './TicketDefaultFieldFilter'
import TicketFieldFilterButton from './TicketFieldFilterButton'
import TicketFilterPills from './TicketFilterPills'
import TicketFilterSearchText from './TicketFilterSearchText'
import styles from './TicketList.module.css'
import TicketPagination from './TicketPagenation'
import { createTicketItems } from './tickets'
import { Ticket, fetchTickets, isEmpty, setSortRecievedAt } from './ticketSlice'
import TicketSort from './TicketSorts'
import TicketStatusLabel from './TicketStatusLabel'

export interface Subject {
  text: string
  emphasis: boolean
  isManual?: boolean
}

export interface Data {
  inquiry: {
    id: number
    avatar: {
      image: string
      name: string
    }
    name: string
    subject: Subject
  }
  requester: {
    name: string
    avatar: {
      image: string
      name: string
    }
  }
  subject: Subject
  assignedUserId: string | null
  status: TicketStatus
  createdAt: string
  receivedAt: string
  sentAt: string
  keyId: number
  faqStatus: FaqStatus
  faqCreator: {
    name: string
    avatar: {
      image: string
      name: string
    }
  } | null
  [key: number]: string | number
}

const fetchInterval = 10_000

export const sortableColumnsNames: string[] = [
  DefaultFields.KeyId.name,
  DefaultFields.Status.name,
  DefaultFields.CreatedAt.name,
  DefaultFields.ReceivedAt.name,
  DefaultFields.SentAt.name,
]

export const sortableColumnsValues: string[] = [
  DefaultFields.KeyId.value,
  DefaultFields.Status.value,
  DefaultFields.CreatedAt.value,
  DefaultFields.ReceivedAt.value,
  DefaultFields.SentAt.value,
]

export const sortableCustomFieldsTypes: string[] = [
  CustomFieldType.date,
  CustomFieldType.number,
]

const TicketList: React.FC = () => {
  const history = useHistory()

  const ticketState = useSelector((state: RootState) => state.ticket)
  const usersState = useSelector((state: RootState) => state.users)
  const memberState = useSelector((state: RootState) => state.member)
  const userPhotosState = useSelector((state: RootState) => state.userPhotos)
  const tabConfigState = useSelector((state: RootState) => state.tabConfig)

  const [csvExportDialogOpen, setCsvExportDialogOpen] = useState(false)
  const [tenantAppCsvExportDialogOpen, setTenantAppCsvExportDialogOpen] =
    useState(false)
  const [notificationSettingOpen, setNotificationSettingOpen] = useState(false)
  const [autoAssignSettingOpen, setAutoAssignSettingOpen] = useState(false)
  const [manualTicketSettingOpen, setManualTicketSettingOpen] = useState(false)
  const recommendedFaqResponse = useGetRecommendedFaqListQuery({})
  const { data: recommendedFaqData } = useGetRecommendedFaqListQuery({})
  const recommendedFaqs = recommendedFaqData?.recommendedFaqEntities || []
  const isRecommendedFaqConfigured = ReactRedux.useSelector(
    (state: RootState) => RecommendedFaqSelector.isConfigured(state)
  )
  const authState = useSelector((state: RootState) => state.auth)
  const customFields = useSelector(customFieldSelector.selectAll)

  const isAvailableActivityMessageQuery = useIsAvailableActivityMessageQuery()
  const dispatch = useDispatch()

  const tickets = ticketState.ids.map(
    (id) => ticketState.entities[id] as Ticket
  )

  const groupId = authState.context?.team?.groupId || ''
  const channelId = authState.context?.channel?.id || ''

  const initializing = usersState.meId == null || memberState.ids.length === 0

  useEffect(() => {
    dispatch(fetchCustomFields(0, customFieldMaxLength))
  }, [dispatch])

  const memoizedCustomFields = useMemo(() => customFields, [customFields])

  const data = createTicketItems(
    tickets,
    usersState.entities,
    memberState.entities,
    userPhotosState.entities,
    memoizedCustomFields
  )

  const columns: Column<Data>[] = useMemo((): Column<Data>[] => {
    return [
      {
        Header: DefaultFields.KeyId.name,
        id: DefaultFields.KeyId.name,
        accessor: DefaultFields.KeyId.value,
        Cell: ({ cell: { value } }) => <Text content={value} />,
      },
      {
        Header: DefaultFields.Requester.name,
        id: DefaultFields.Requester.name,
        accessor: DefaultFields.Requester.value,
        Cell: ({ cell: { value } }) => (
          <Box className={styles.requesterCell}>
            <Avatar name={value.avatar.name} image={value.avatar.image} />
            <Text
              content={ellipsis(value.name, 14)}
              className={styles.requesterCellText}
            />
          </Box>
        ),
      },
      {
        Header: DefaultFields.Subject.name,
        id: DefaultFields.Subject.name,
        accessor: DefaultFields.Subject.value,
        Cell: ({ cell: { value } }) => (
          <Flex gap="gap.small" vAlign={'center'}>
            {value.isManual && (
              <NotesIcon outline className={styles.manualIcon} />
            )}
            <Text
              weight={value.emphasis ? 'bold' : 'regular'}
              important={value.emphasis}
              content={ellipsis(value.text, 150)}
              className={styles.title}
              // Comment: ellipsisの役割cssの文字制限機能は効かない時に、jsで文字数を確保します、たくさん文字を全部出ないように
            />{' '}
            <ReplyIcon hidden={!value.emphasis} color={'red'} outline={true} />{' '}
          </Flex>
        ),
      },
      {
        Header: DefaultFields.AssignedUserId.name,
        id: DefaultFields.AssignedUserId.name,
        accessor: DefaultFields.AssignedUserId.value,
        Cell: ({ cell: { value } }) => (
          <Text
            disabled={value == null}
            content={
              value == null
                ? Tickets.Users.UserNotIndicated
                : ellipsis(value, 14)
            }
          />
        ),
      },
      {
        Header: DefaultFields.Status.name,
        id: DefaultFields.Status.name,
        accessor: DefaultFields.Status.value,
        Cell: ({ cell: { value } }) => {
          return (
            <Provider.Consumer
              render={() => {
                return <TicketStatusLabel ticketStatus={value} />
              }}
            />
          )
        },
      },
      {
        Header: DefaultFields.CreatedAt.name,
        id: DefaultFields.CreatedAt.name,
        accessor: DefaultFields.CreatedAt.value,
      },
      {
        Header: DefaultFields.ReceivedAt.name,
        id: DefaultFields.ReceivedAt.name,
        accessor: DefaultFields.ReceivedAt.value,
      },
      {
        Header: DefaultFields.SentAt.name,
        id: DefaultFields.SentAt.name,
        accessor: DefaultFields.SentAt.value,
      },
      {
        Header: DefaultFields.FaqStatus.name,
        id: DefaultFields.FaqStatus.name,
        accessor: DefaultFields.FaqStatus.value,
        Cell: ({ cell: { value } }) => {
          return (
            <Provider.Consumer
              render={() => {
                return <FaqStatusLabel faqStatus={value} />
              }}
            />
          )
        },
      },
      {
        Header: DefaultFields.FaqCreator.name,
        id: DefaultFields.FaqCreator.name,
        accessor: DefaultFields.FaqCreator.value,
        Cell: ({ cell: { value } }) =>
          value ? (
            <Box className={styles.faqCreatorCell}>
              <Avatar name={value.avatar.name} image={value.avatar.image} />
              <Text
                content={ellipsis(value.name, 14)}
                className={styles.faqCreatorCellText}
              />
            </Box>
          ) : (
            <></>
          ),
      },
      // accessorの型がstring or functionなのでnumber型のキーにアクセスするために以下のように実装している
      ...memoizedCustomFields.map((cf, idx) => {
        return {
          Header: ellipsis(cf.name, 20),
          id: cf.name,
          accessor: (d: Data) => d[idx],
          Cell: ({
            cell: { value },
          }: React.PropsWithChildren<
            CellProps<Data, string | number | boolean>
          >) => {
            if (
              cf.type === CustomFieldType.checkbox &&
              value !== CustomFieldValue.DisplayedNullValue &&
              (value === 1 || value === 0)
            ) {
              // チェックボックスがfalseの時は「--」が表示されるようにしている
              return value ? (
                <Box>
                  <Checkbox
                    disabled
                    checked={Boolean(value)}
                    className={styles.checkbox}
                  />
                </Box>
              ) : (
                <Text content={`${CustomFieldValue.DisplayedNullValue}`}></Text>
              )
            }
            return <Text content={value}></Text>
          },
        }
      }),
    ]
    // memo化したStateに依存させる. memo化しない場合はreact tableで多重のレンダリングが発生し例外が発生する
  }, [memoizedCustomFields])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setColumnOrder,
    setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useTable<Data>(
    {
      columns,
      data,
      initialState: {
        pageIndex: ticketState.pageIndex,
        pageSize: ticketState.pageSize,
      },
      manualPagination: true,
      pageCount: ticketState.pageCount,
    },
    usePagination,
    useColumnOrder
  )

  // 10秒ごとにポーリングを行いチケット一覧を最新の状態にする
  // ユーザーがフィルタ・表示件数・ページインデックスを変更した際もその状態をインターバル関数に反映する
  const pageIndexRef = useRef(pageIndex)
  const pageSizeRef = useRef(pageSize)
  const filterRef = useRef(ticketState.filters)
  const sortRef = useRef(ticketState.sorts)

  useEffect(() => {
    pageIndexRef.current = pageIndex
    pageSizeRef.current = pageSize
    filterRef.current = ticketState.filters
    sortRef.current = ticketState.sorts
  }, [pageIndex, pageSize, ticketState.filters, ticketState.sorts])

  useEffect(() => {
    /* フィルターのローディングが完了してから、fetchTicketsを実行します */
    // window.setIntervalはNumber型だがsetIntervalはNode.Timeout型なので
    const intervalId = window.setInterval(() => {
      /* ticketフィルターはまた初期化されてない段階なら、リクエストを投げません */
      const assignedUserIds = filterRef.current.assignedUserIds
      const requesterUserIds = filterRef.current.requesterUserIds
      if (
        assignedUserIds == null ||
        requesterUserIds == null ||
        !ticketState.hasFetchedDefaultTabConfig
      ) {
        return
      }
      const keyId = filterRef.current.keyId
      const subject = filterRef.current.subject
      dispatch(
        fetchTickets(
          pageIndexRef.current,
          pageSizeRef.current,
          {
            ...filterRef.current,
            assignedUserIds: assignedUserIds,
            requesterUserIds: requesterUserIds,
            keyId: keyId,
            subject: subject,
          },
          sortRef.current
        )
      )
    }, fetchInterval)
    return () => {
      window.clearInterval(intervalId)
    }
  }, [dispatch, ticketState.hasFetchedDefaultTabConfig])

  useEffect(() => {
    const assignedUserIds = ticketState.filters.assignedUserIds
    const requesterUserIds = ticketState.filters.requesterUserIds
    /* ticketフィルターはまた初期化されてない段階なら、リクエストを投げません */
    if (
      assignedUserIds == null ||
      requesterUserIds == null ||
      !ticketState.hasFetchedDefaultTabConfig
    ) {
      return
    }
    dispatch(
      fetchTickets(
        pageIndex,
        pageSize,
        {
          ...ticketState.filters,
          assignedUserIds: assignedUserIds,
          requesterUserIds: requesterUserIds,
        },
        ticketState.sorts
      )
    )
  }, [
    groupId,
    channelId,
    dispatch,
    gotoPage,
    pageIndex,
    pageSize,
    pageCount,
    ticketState.filters,
    ticketState.sorts,
    ticketState.hasFetchedDefaultTabConfig,
    initializing,
  ])

  // カスタムフィールド一覧画面でソート対象の項目が削除した後でチケット一覧画面に戻ってきた場合、
  // デフォルトソートの「受信日」の「降順」を指定する
  useEffect(() => {
    const dateFieldIds = Object.keys(ticketState.sorts.customFieldValue.date)
    const numberFieldIds = Object.keys(
      ticketState.sorts.customFieldValue.number
    )
    const margedFieldIds = dateFieldIds.concat(numberFieldIds)
    if (margedFieldIds.length === 0) return

    const sortTarget = Number(margedFieldIds[0])

    const currentFields = customFields.map((c) => c.id)

    if (!currentFields.includes(sortTarget)) {
      dispatch(setSortRecievedAt({ direction: Sort.Desc.value }))
    }
  }, [dispatch, customFields, ticketState.sorts])

  // ソート条件が何も指定されていない状態が発生しないようにする
  // （ソート条件にしていたカスタムフィールドを削除し、タブを再読み込みした場合などに発生する）
  useEffect(() => {
    if (
      ticketState.sorts.keyId === '' &&
      ticketState.sorts.status === '' &&
      ticketState.sorts.createdAt === '' &&
      ticketState.sorts.receivedAt === '' &&
      ticketState.sorts.sentAt === '' &&
      isEmpty(ticketState.sorts.customFieldValue.date) &&
      isEmpty(ticketState.sorts.customFieldValue.number)
    ) {
      dispatch(setSortRecievedAt({ direction: Sort.Desc.value }))
    }
  }, [dispatch, ticketState.sorts])

  const [tableHeight, setTableHeight] = useState(0)
  const headMenuRef = useRef<HTMLDivElement>(null)

  // テーブルの高さを計算する
  useEffect(() => {
    const onResize = debounce(300, () => {
      if (!headMenuRef.current) return
      const headMenuHeight = headMenuRef.current.clientHeight
      const windowHeight = window.innerHeight
      // テーブルの高さ = ウィンドウの高さ - ヘッダーメニューの高さ
      setTableHeight(windowHeight - headMenuHeight)
    })
    // 初回実行
    onResize()
    window.addEventListener('resize', onResize)

    // ヘッダーメニューの高さ変更（レンダリング等による）を検知しResizeを発火する
    const resizeObserver = new ResizeObserver(onResize)

    if (!headMenuRef.current) return
    resizeObserver.observe(headMenuRef.current)

    return () => {
      window.removeEventListener('resize', onResize)
      resizeObserver.disconnect()
    }
  }, [headMenuRef])

  const setThTdClassName = (columnId: string, isTh: boolean): string => {
    switch (columnId) {
      case DefaultFields.KeyId.name:
        return isTh
          ? `${styles.th} ${styles.thKeyId}`
          : `${styles.td} ${styles.tdKeyId}`
      case DefaultFields.Requester.name:
        return isTh
          ? `${styles.th} ${styles.thRequester}`
          : `${styles.td} ${styles.tdRequester}`
      case DefaultFields.Subject.name:
        return isTh
          ? `${styles.th} ${styles.thSubject}`
          : `${styles.td} ${styles.tdSubject}`
      case DefaultFields.Status.name:
        return isTh
          ? `${styles.th} ${styles.thStatus}`
          : `${styles.td} ${styles.tdStatus}`
      case DefaultFields.CreatedAt.name:
      case DefaultFields.ReceivedAt.name:
      case DefaultFields.SentAt.name:
        return isTh
          ? `${styles.th} ${styles.thDate}`
          : `${styles.td} ${styles.tdDate}`
      default:
        return isTh ? styles.th : styles.td
    }
  }

  const menuItemKeys = React.useMemo<string[]>(() => {
    return [
      'csvExport',
      authState.availableFeatures?.tenantAppCsvExport
        ? 'tenantAppCsvExport'
        : null,
      'customFieldSettings',
      'notificationSettings',
      'autoAssignSettings',
      'report',
      isAvailableActivityMessageQuery.data ? 'activityMessage' : null,
      'help',
    ].filter((x) => x) as string[]
  }, [
    isAvailableActivityMessageQuery,
    authState.availableFeatures?.tenantAppCsvExport,
  ])

  // タブ設定情報の取得
  useEffect(() => {
    microsoftTeams.pages
      .getConfig()
      .then((settings) => {
        const configId = getQueryParamsByKey(settings.contentUrl, 'tab_id')[0]
        if (configId != null) {
          dispatch(fetchTabConfig(configId, customFields))
        }
      })
      .catch((e) => {
        consoleErrorWithAirbrake(`failed getting tab config info ${e}`)
      })
  }, [customFields, dispatch])

  // タブ設定・表示項目の設定内容の反映
  useEffect(() => {
    const setTabConfigFields = (
      tabConfigState: DefaultTabConfig,
      columns: Column<Data>[]
    ) => {
      // タブ設定画面で未設定の場合はなにもしない
      if (tabConfigState.fields.length === 0) return

      const tabConfigIdentifierNames: string[] = tabConfigState.fields.map(
        (field: FieldConfig) => field.identifierName
      )

      // 固定列・カスタムフィールド列ともにcolumnのidプロジェクトにはカラム名が設定してある
      const columnIds: string[] = columns.map((column: Column<Data>) =>
        column.id ? column.id : ''
      )

      // タブ設定画面で指定されていないフィールドは非表示にする
      const hiddenColumns: string[] = columnIds.filter(
        (columnId) => !tabConfigIdentifierNames.includes(columnId)
      )
      setHiddenColumns(hiddenColumns)

      // タブ設定画面で並べたとおりの順序に並びかえる
      setColumnOrder(tabConfigIdentifierNames)
    }

    // タブ設定画面で指定したフィールドの並び順と表示・非表示を反映させる
    setTabConfigFields(tabConfigState, columns)
  }, [tabConfigState, columns, setHiddenColumns, setColumnOrder])

  // fetch ms user info
  useEffect(() => {
    const userIds = ticketState.ids
      .filter(
        (id) =>
          ticketState.entities[id]?.requesterType !=
          Tickets.RequesterType.RequesterTypeWebagent
      )
      .map((id) => ticketState.entities[id]?.requesterUserId)
      .filter((id) => id)
      .map((id) => id as string)
    dispatch(fetchUsers(userIds))
    dispatch(fetchUserPhotos(userIds))
  }, [dispatch, ticketState.entities, ticketState.ids])

  const handleTenantAppCSVExportDialogCancel = useCallback(() => {
    setTenantAppCsvExportDialogOpen(false)
  }, [])

  if (memberState.error != null) {
    return <Redirect to={{ pathname: '/errors/unknown' }} />
  }

  if (!ticketState.error.isEmpty() && !ticketState.error.isNetworkErr()) {
    return <Redirect to={{ pathname: '/errors/unknown' }} />
  }

  return (
    <PageViewLog pageName={PageName.TicketList}>
      <Box className={styles.backgroundColorGrey}>
        <div ref={headMenuRef}>
          <Box
            className={styles.error}
            hidden={!ticketState.error.isNetworkErr()}
          >
            <Alert
              styles={{
                textAlign: 'center',
              }}
              warning
              content={TicketConsts.ClientErrorMessage.MessageStateError}
            />
          </Box>

          <Box
            id="ticketListHeader"
            className={styles.ticketListHeaderBoxContainer}
          >
            <Flex space="between">
              <Flex.Item grow>
                <TicketFilterPills />
              </Flex.Item>
              <Box>
                <Button
                  iconPosition={'before'}
                  content={
                    <>
                      {recommendedFaqResponse.isLoading ||
                      recommendedFaqResponse.isUninitialized ? (
                        <Loader />
                      ) : (
                        <>
                          <Text
                            className={styles.recommendedFaqText}
                            content={'AIによるFAQ作成提案（β）'}
                          />
                          {recommendedFaqs.length > 0 &&
                            isRecommendedFaqConfigured && (
                              <Text
                                className={styles.recommendedFaqCount}
                                content={recommendedFaqs.length}
                              />
                            )}
                        </>
                      )}
                    </>
                  }
                  className={styles.recommendedFaqButton}
                  onClick={() => history.push('/recommended_faqs')}
                />
              </Box>
              <MenuButton
                trigger={
                  <Button
                    text
                    iconOnly
                    icon={<MoreIcon />}
                    aria-label="More button"
                  />
                }
                menu={menuItemKeys.map((key) => {
                  switch (key) {
                    case 'csvExport':
                      return (
                        <Box key={'exportBox'}>
                          <DownloadIcon className={styles.exportIcon} />
                          データのエクスポート
                        </Box>
                      )
                    case 'tenantAppCsvExport':
                      return (
                        <Box key={'tenantAppCsvExportBox'}>
                          <DownloadIcon className={styles.exportIcon} />
                          テナント内の全データのエクスポート
                        </Box>
                      )
                    case 'customFieldSettings':
                      return (
                        <Box key={'customFieldSettingBox'}>
                          <OpenEnrollmentIcon
                            className={styles.openCustomFieldIcon}
                          />
                          カスタムフィールド設定
                        </Box>
                      )
                    case 'notificationSettings':
                      return (
                        <Box key={'notificationSettingBox'}>
                          <BellIcon
                            outline
                            className={styles.openCustomFieldIcon}
                          />
                          通知設定
                        </Box>
                      )
                    case 'autoAssignSettings':
                      return (
                        <Box key={'autoAssignSettingBox'}>
                          <TransitionIcon
                            className={styles.openCustomFieldIcon}
                          />
                          担当者自動割り当て設定
                        </Box>
                      )
                    case 'report':
                      return (
                        <Box key={'reportBox'}>
                          <PollIcon
                            outline
                            className={styles.openCustomFieldIcon}
                          />
                          レポート
                        </Box>
                      )
                    case 'help':
                      return (
                        <Box key={'helpBox'}>
                          <HelpIcon className={styles.openCustomFieldIcon} />
                          ヘルプ
                          <PopupIcon className={styles.openCustomFieldIcon} />
                        </Box>
                      )
                    case 'activityMessage':
                      return (
                        <Box key={'activityMessagePlans'}>
                          <SendIcon
                            outline
                            className={styles.openCustomFieldIcon}
                          />
                          お知らせの配信 (β)
                        </Box>
                      )
                    default:
                      return <></>
                  }
                })}
                on="click"
                onMenuItemClick={(_, data) => {
                  if (!data || data.index === undefined) return
                  const key = menuItemKeys[data.index]
                  switch (key) {
                    case 'csvExport':
                      setCsvExportDialogOpen(true)
                      return
                    case 'tenantAppCsvExport':
                      setTenantAppCsvExportDialogOpen(true)
                      return
                    case 'customFieldSettings':
                      history.push('/customfields')
                      return
                    case 'notificationSettings':
                      setNotificationSettingOpen(true)
                      return
                    case 'autoAssignSettings':
                      setAutoAssignSettingOpen(true)
                      return
                    case 'report':
                      history.push('/reports')
                      return
                    case 'activityMessage':
                      history.push('/activity-messages')
                      return
                    case 'help':
                      // Box側にaリンクを置いてもクリックの判定が端まで伸ばせないためJSで処理する
                      microsoftTeams.app.openLink(
                        'https://teams-help.bedore.jp/hc/ja'
                      )
                      return
                    default:
                      return
                  }
                }}
              />
            </Flex>
            <Flex space="between" wrap>
              <Flex.Item grow>
                <TicketFilterSearchText />
              </Flex.Item>
              <Flex vAlign="center" hAlign="end">
                <Button
                  text
                  iconPosition={'before'}
                  content="＋ 問い合わせ記録の追加"
                  onClick={() => setManualTicketSettingOpen(true)}
                />
              </Flex>
            </Flex>
            <Flex
              hAlign={'end'}
              vAlign={'end'}
              className={styles.ticketDisplayInfoContainer}
            >
              <Box className={styles.pagination}>
                <TicketPagination
                  loading={ticketState.loading || initializing}
                  totalCount={ticketState.totalCount}
                  pageIndex={pageIndex}
                  pageLength={pageOptions.length}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  gotoPage={gotoPage}
                  onClickFirst={() => gotoPage(0)}
                  onClickPrevious={() => previousPage()}
                  onClickNext={() => nextPage()}
                  onClickLast={() => gotoPage(pageCount - 1)}
                />
              </Box>
            </Flex>
          </Box>
        </div>
        <Box
          style={{
            width: '100%',
            height: `${tableHeight}px`,
            overflowX: 'scroll',
            backgroundColor: 'white',
          }}
        >
          <table {...getTableProps()} className={styles.table}>
            <thead className={styles.thead}>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={headerGroup.getHeaderGroupProps().key}
                >
                  {headerGroup.headers.map((column) => {
                    const customField = memoizedCustomFields.find(
                      (cf) => cf.name === column.id
                    )
                    const isCustomFieldHeader = customField != null

                    // ソートがかけられるカラムか判定
                    const isSortableFieldHeader =
                      (isCustomFieldHeader &&
                        sortableCustomFieldsTypes.includes(
                          customField?.type || ''
                        )) ||
                      sortableColumnsNames.includes(column.id)

                    return (
                      <th
                        className={setThTdClassName(column.id, true)}
                        {...column.getHeaderProps()}
                        key={column.getHeaderProps().key}
                        style={{
                          borderBottom: 'solid 1px rgb(241, 233, 225)',
                        }}
                      >
                        <Flex vAlign="center">
                          {isSortableFieldHeader ? (
                            <TicketSort
                              column={column}
                              customField={customField}
                              isCustomFieldHeader={isCustomFieldHeader}
                            />
                          ) : (
                            <Text content={column.render('Header')} />
                          )}
                          {column.id !== DefaultFields.CreatedAt.name &&
                            column.id !== DefaultFields.ReceivedAt.name &&
                            column.id !== DefaultFields.SentAt.name &&
                            column.id !== DefaultFields.FaqCreator.name && (
                              <Popup
                                trapFocus
                                align="start"
                                position="below"
                                offset={() => [0, 10]} // offsetはPopupの位置を指定する
                                content={
                                  isCustomFieldHeader ? (
                                    <TicketCustomFieldValueFilter
                                      customField={customField}
                                    />
                                  ) : (
                                    <TicketFieldFilter
                                      fieldName={column.id}
                                      renderAssigneeSelection={(
                                        values,
                                        onChange,
                                        extendedItems
                                      ) => (
                                        <AssigneeSelection
                                          multiple
                                          values={values}
                                          onChange={onChange}
                                          extendedItems={extendedItems}
                                        />
                                      )}
                                    />
                                  )
                                }
                                trigger={
                                  <Flex vAlign="center">
                                    {isCustomFieldHeader ? (
                                      <TicketCustomFieldFilterButton
                                        customField={customField}
                                        customFieldValueFilters={
                                          ticketState.filters.customFieldValues
                                        }
                                      />
                                    ) : (
                                      <TicketFieldFilterButton
                                        fieldName={column.id}
                                      />
                                    )}
                                  </Flex>
                                }
                              />
                            )}
                        </Flex>
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <tr
                    className={styles.tr}
                    {...row.getRowProps()}
                    onClick={() => {
                      history.push(`/tickets/${row.original.inquiry.id}`)
                    }}
                    key={row.original.inquiry.id}
                  >
                    {row.cells.map((cell) => (
                      <td
                        className={setThTdClassName(cell.column.id, false)}
                        {...cell.getCellProps()}
                        key={cell.getCellProps().key}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Box>
        <CSVExportDialog
          open={csvExportDialogOpen}
          onCancel={() => setCsvExportDialogOpen(false)}
        />
        <TenantAppCSVExportDialog
          open={tenantAppCsvExportDialogOpen}
          onCancel={handleTenantAppCSVExportDialogCancel}
        />
        <NotificationDialog
          open={notificationSettingOpen}
          renderAssigneeSelection={(values, onChange) => (
            <AssigneeSelection multiple values={values} onChange={onChange} />
          )}
          renderAssigneeFaqSelection={(values, onChange) => (
            <AssigneeSelection multiple values={values} onChange={onChange} />
          )}
          onCancel={() => setNotificationSettingOpen(false)}
          onConfirm={() => setNotificationSettingOpen(false)}
        />
        <AutoAssignConfigDialog
          open={autoAssignSettingOpen}
          renderAsigneeSelection={(values, disabled, onChange) => (
            <AssigneeSelection
              multiple
              values={values}
              disabled={disabled}
              onChange={onChange}
            />
          )}
          onCancel={() => setAutoAssignSettingOpen(false)}
          onConfirm={() => setAutoAssignSettingOpen(false)}
        />
        <ManualTicketSettingDialog
          open={manualTicketSettingOpen}
          onCancel={() => setManualTicketSettingOpen(false)}
          onConfirm={() => setManualTicketSettingOpen(false)}
        />
      </Box>
    </PageViewLog>
  )
}

export default TicketList
