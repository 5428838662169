import { grpc } from '@improbable-eng/grpc-web'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk, RootState } from '../../app/store'
import default_tab_setting_pb from '../../proto/default_tab_setting_pb'
import default_tab_setting_pb_service from '../../proto/default_tab_setting_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'

const adapter =
  createEntityAdapter<default_tab_setting_pb.DefaultTabSetting.AsObject>()

export const defaultTabSettingsSelector = adapter.getSelectors<RootState>(
  (state) => state.defaultTabSettings
)

export const defaultTabSettingsSlice = createSlice({
  name: 'defaultTabSettings',
  initialState: adapter.getInitialState(),
  reducers: {
    defaultTabSettingsOnMessage: (
      state,
      action: PayloadAction<default_tab_setting_pb.DefaultTabSetting.AsObject[]>
    ) => {
      adapter.setAll(state, action.payload)
    },
    defaultTabSettingsOnError: (state) => {
      adapter.removeAll(state)
    },
  },
})

export const { defaultTabSettingsOnMessage, defaultTabSettingsOnError } =
  defaultTabSettingsSlice.actions

export default defaultTabSettingsSlice.reducer

export const fetchDefaultTabSettings =
  (): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      default_tab_setting_pb.ListDefaultTabSettingsRequest,
      default_tab_setting_pb.ListDefaultTabSettingsResponse
    >(
      default_tab_setting_pb_service.DefaulTabSettingsAPI.ListDefaultTabSettings
    )

    const req = new default_tab_setting_pb.ListDefaultTabSettingsRequest()

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      dispatch(
        defaultTabSettingsOnMessage(message.toObject().defaultTabSettingsList)
      )
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(defaultTabSettingsOnError())
        return
      }
      if (code !== grpc.Code.OK) {
        consoleErrorWithAirbrake(message)
        return
      }
    })

    client.start(meta)
    client.send(req)
    client.finishSend()
  }
