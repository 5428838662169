import { EntityId } from '@reduxjs/toolkit'
import * as React from 'react'

type Props = {
  replyList: EntityId[]
  renderReplyItem: (id: EntityId) => React.ReactNode
}
const RequesterTicketReplyListPresenter: React.FC<Props> = (props) => {
  return <div>{props.replyList.map(props.renderReplyItem)}</div>
}

export default RequesterTicketReplyListPresenter
