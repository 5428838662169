import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../app/store'

const stateSelector = (state: RootState) =>
  state.recommendedFaqRedundantIntentList

export const open = createSelector(stateSelector, (state) => {
  return state.open
})
