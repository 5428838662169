import React, { ReactNode, useEffect, useRef } from 'react'

import { appInsights } from '../appInsights'

interface Props {
  children?: ReactNode
  pageName: string
  isOpenable?: true
  isOpen?: boolean
}

interface PageViewProps {
  name: string
  properties?: {
    duration?: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any // trackPageViewの引数の型にあわせてあえてanyで型指定
  }
}
const PageViewLog: React.FC<Props> = ({
  children,
  pageName,
  isOpenable,
  isOpen,
}) => {
  const isMountRef = useRef(false)
  useEffect(() => {
    const properties = {
      monitorSize: {
        width: window.screen.width,
        height: window.screen.height,
      },
      browserSize: {
        width: window.outerWidth,
        height: window.outerHeight,
      },
      deskIFrameSize: {
        width: document.body.clientWidth,
        height: document.body.clientHeight,
      },
    }

    const pageViewObj: PageViewProps = {
      name: pageName,
      properties: properties,
    }
    // TicketListやTicketDetailなどのコンポーネントはマウントされた時のみPageViewを送信したいので、refを使用して一度だけ送信する制御を行なっている。
    if (isOpenable == null && !isMountRef.current) {
      appInsights.trackPageView(pageViewObj)
      isMountRef.current = true
    } else {
      // ModalやPopupなど開閉可能なページは開く度にPageViewを送信する。
      if (isOpen) {
        appInsights.trackPageView(pageViewObj)
      }
    }
  }, [isOpenable, pageName, isOpen])
  return <>{children}</>
}

export default PageViewLog
