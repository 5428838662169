import { Box, Flex } from '@fluentui/react-northstar'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import styles from './AlertBox.module.css'
import AlertBoxIcon from './AlertBoxIcon'
import { Alert, removeAlert } from './alertsSlice'

const AUTO_CLOSE_TIME = 5000

interface Props {
  alert: Alert
}

const AlertBox: React.FC<Props> = ({ alert }) => {
  const dispatch = useDispatch()

  const [isMouseIn, setIsMouseIn] = useState(false)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    setTimeoutId(
      setTimeout(() => {
        dispatch(removeAlert({ id: alert.id }))
      }, AUTO_CLOSE_TIME)
    )
  }, [dispatch, alert.id])

  useEffect(() => {
    return () => {
      if (timeoutId == null) return
      clearTimeout(timeoutId)
    }
  }, [timeoutId])

  return (
    <div
      key={alert.id}
      className={styles.container}
      onMouseEnter={() => {
        setIsMouseIn(true)
        if (timeoutId == null) return
        clearTimeout(timeoutId)
      }}
      onMouseLeave={() => {
        dispatch(removeAlert({ id: alert.id }))
      }}
      style={
        alert.type !== 'success_announcement'
          ? { backgroundColor: '#CC4A31' }
          : {}
      }
    >
      <Flex column space="between" gap="gap.smaller" className={styles.content}>
        <Box className={styles.title} content={alert.title} />
        {alert.content && (
          <Box className={styles.message} content={alert.content} />
        )}
      </Flex>
      <AlertBoxIcon
        hover={isMouseIn}
        type={alert.type}
        onClick={() => {
          dispatch(removeAlert({ id: alert.id }))
        }}
      />
    </div>
  )
}

export default AlertBox
