import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  DraftDecoratorComponentProps,
  EditorState,
  SelectionState,
  convertFromRaw,
  convertToRaw,
} from 'draft-js'
import React from 'react'

import { AppThunk } from '../../app/store'
import { ChatInputProps, regExpAutoLink } from '../../consts'
import { consoleErrorWithAirbrake } from '../../utils'
import { PastedImageEntityType } from '../chat/types'

const Link = (
  props: DraftDecoratorComponentProps & { decoratedText: string }
): JSX.Element => {
  // aタグの後ろにIMEから日本語を入力するには変な挙動になるため、spanタグでaタグを模擬します。
  return React.createElement(
    'span',
    {
      style: {
        cursor: 'pointer',
        color: 'blue',
        textDecoration: 'underline',
      },
    },
    props.children
  )
}

const codeStyle = {
  display: 'inline-block',
  'font-size': 'inherit',
  padding: '0 0.4rem',
  'vertical-align': 'baseline',

  border: 'none',
  'line-height': '2rem',
  'background-color': '#f5f5f5',
  color: '#424242',
  background: '#f5f5f5',
  'border-radius': '0.2rem',
}

const InlineCodeComponent = (
  props: DraftDecoratorComponentProps & { decoratedText: string }
): JSX.Element => {
  return React.createElement('code', { style: codeStyle }, props.children)
}

const linkDecorator = new CompositeDecorator([
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges((charactor) => {
        const entityKey = charactor.getEntity()
        return (
          entityKey != null &&
          contentState.getEntity(entityKey).getType() === 'LINK'
        )
      }, callback)

      // エディタに入力中の文字列がURLの形式であればオートリンクを付与
      findWithRegex(new RegExp(regExpAutoLink, 'g'), contentBlock, callback)
    },
    // 対象となる文字列にaタグを付与
    component: Link,
  },
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges((charactor) => {
        const entityKey = charactor.getEntity()
        return (
          entityKey != null &&
          contentState.getEntity(entityKey).getType() === 'CODE'
        )
      }, callback)
    },
    component: InlineCodeComponent,
  },
])

const findWithRegex = (
  regex: RegExp,
  contentBlock: Draft.ContentBlock,
  callback: (start: number, end: number) => void
) => {
  const text = contentBlock.getText()
  let matchArr, start
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index
    callback(start, start + matchArr[0].length)
  }
}

interface EditorStateState {
  editorState: Draft.EditorState
}

const initState: EditorStateState = {
  editorState: EditorState.createEmpty(linkDecorator),
}
export const editorStateSlice = createSlice({
  name: 'editorStateSlice',
  initialState: initState,
  reducers: {
    setEditorState(state, action: PayloadAction<{ state: EditorState }>) {
      state.editorState = action.payload.state
    },
    clearEditorState(state) {
      state.editorState = EditorState.createEmpty(linkDecorator)
    },
    resetEditorState(state) {
      state.editorState = EditorState.moveFocusToEnd(
        EditorState.createEmpty(linkDecorator)
      )
    },
  },
})

export const { setEditorState, resetEditorState, clearEditorState } =
  editorStateSlice.actions

export default editorStateSlice.reducer

export const loadEditorStateDraft = (
  ticketId: string,
  userId: string
): AppThunk => {
  return (dispatch, getState, { localStorage }) => {
    const editorState = getState().editorState.editorState
    const res = loadFromLocalStorage(
      editorState,
      ticketId,
      userId,
      localStorage
    )
    if (res == null) {
      return
    }
    dispatch(setEditorState({ state: res }))
  }
}

export const clearEditorStateDraft = (
  ticketId: string,
  userId: string
): AppThunk => {
  return (dispatch, getState, { localStorage }) => {
    const key = createDraftLocalStorageKey(ticketId, userId)
    localStorage.removeItem(key)
  }
}

export const storeEditorStateDraft = (
  newState: Draft.EditorState,
  ticketId: string,
  userId: string
): AppThunk => {
  return (dispatch, getState, { localStorage }) => {
    const key = createDraftLocalStorageKey(ticketId, userId)
    const message = JSON.stringify(convertToRaw(newState.getCurrentContent()))
    const data = JSON.stringify(
      createContentStateDraft(message, Date.now().toString())
    )

    try {
      localStorage.setItem(key, data)
    } catch (e) {
      // LocalStorageのサイズ制限を達している場合は、エラーをcatchします。
      consoleErrorWithAirbrake(
        `failed at saving into localstorage,${e}, data length: ${data.length}`
      )
      dispatch(clearEditorStateDraft(ticketId, userId))
    }
  }
}

const loadFromLocalStorage = (
  editorState: EditorState,
  ticketId: string,
  userId: string,
  localStorage: Storage
): EditorState | undefined => {
  const key = createDraftLocalStorageKey(ticketId, userId)
  const raw = localStorage.getItem(key)
  if (raw == null) {
    return
  }

  let data: ContentStateDraft
  try {
    data = JSON.parse(raw) as ContentStateDraft
  } catch (e) {
    // データ形式が間違いなら,LocalStorageから消します
    localStorage.removeItem(key)
    consoleErrorWithAirbrake(
      `error at recovering ContentStateDraft, ${e}, storage: ${raw}`
    )
    return
  }

  if (data == null) {
    return
  }

  const message = data.message
  if (message == null) {
    return
  }

  const content = message.content
  if (content == null) {
    return
  }

  let savedContentState: ContentState
  try {
    savedContentState = convertFromRaw(JSON.parse(data.message.content))
  } catch (e) {
    // データ形式が間違いなら,LocalStorageから消します
    localStorage.removeItem(key)
    consoleErrorWithAirbrake(
      `error at recovering Draft.ContentState, ${e}, storage: ${raw}`
    )
    return
  }

  if (savedContentState == null) {
    return
  }

  const blockMaps = savedContentState.getBlockMap()
  let lastIsPasteImage = false
  const newBlockMaps = blockMaps.filter((b) => {
    if (b == null) {
      return true
    }

    // 貼り付け画像はデフォルトで後ろに空行を残します。
    // 空行は前の行の貼り付け画像により残される場合は、その自体も必要ではなく、削除しておきます。
    if (b.getType() === 'unstyled' && b.getLength() === 0 && lastIsPasteImage) {
      lastIsPasteImage = false
      return false
    }

    if (isPastedImageBlock(b, savedContentState)) {
      lastIsPasteImage = true
      return false
    }

    lastIsPasteImage = false
    return true
  })

  const newContentState = savedContentState.merge({
    blockMap: newBlockMaps,
  }) as ContentState

  if (newBlockMaps.toArray().length === 0) {
    return EditorState.createEmpty(linkDecorator)
  }

  // 最初のブラックにカーソルを移動させます。
  const firstNewBlock = newBlockMaps.toArray()[0]

  if (firstNewBlock == null) {
    return EditorState.createEmpty(linkDecorator)
  }

  return EditorState.forceSelection(
    EditorState.push(editorState, newContentState, 'insert-fragment'),
    SelectionState.createEmpty(firstNewBlock.getKey())
  )
}

const isPastedImageBlock = (b: ContentBlock, c: ContentState): boolean => {
  if (b.getType() !== 'atomic') {
    return false
  }
  const entityKey = b.getEntityAt(0)
  if (entityKey == null) {
    return false
  }
  return c.getEntity(entityKey).getType() === PastedImageEntityType
}

interface ContentStateDraft {
  message: { content: string }
  lastEditTimestamp: string
}

const createContentStateDraft = (
  message: string,
  timeStamp: string
): ContentStateDraft => {
  return {
    message: { content: message },
    lastEditTimestamp: timeStamp,
  }
}

const createDraftLocalStorageKey = (
  ticketId: string,
  userId: string
): string => {
  return `${ChatInputProps.EditorContentStoragePrefix}${ticketId}.${userId}`
}

export interface LinkEntityData {
  url: string
}

export const createLinkEntityData = (url: string): LinkEntityData => {
  return {
    url: url,
  }
}
