import React from 'react'

import { CustomFieldType } from '../../../consts'
import { TicketCustomField } from '../../../proto/ticket_custom_field_pb'
import { TicketCustomFieldValue } from '../../../proto/ticket_custom_field_value_pb'
import TicketPropertyCustomCheckboxField from './TicketPropertyCustomCheckboxField'
import TicketPropertyCustomDateField from './TicketPropertyCustomDateField'
import TicketPropertyCustomMultipleSelectField from './TicketPropertyCustomMultipleSelectField'
import TicketPropertyCustomNumberField from './TicketPropertyCustomNumberField'
import TicketPropertyCustomSelectField from './TicketPropertyCustomSelectField'
import TicketPropertyCustomTextAreaField from './TicketPropertyCustomTextAreaField'
import TicketPropertyCustomTextField from './TicketPropertyCustomTextField'

interface Props {
  ticketId: number
  field: TicketCustomField.AsObject
  fieldValues: Array<TicketCustomFieldValue.AsObject>
}

// 仕様： https://pkshatech.atlassian.net/wiki/spaces/WOR/pages/12902301936/Desk
const TicketPropertyCustomFieldComponent: React.FC<Props> = ({
  ticketId,
  field,
  fieldValues,
}) => {
  const fieldOneOfValues = fieldValues.find(
    (fieldValue) => fieldValue.ticketCustomFieldId === field.id
  )

  const isDropdown = (): boolean => {
    return field.type === CustomFieldType.select
  }
  const isDropdownMultiple = (): boolean => {
    return field.type === CustomFieldType.multiselect
  }
  const isDatepicker = (): boolean => {
    return field.type === CustomFieldType.date
  }
  const isCheckbox = (): boolean => {
    return field.type === CustomFieldType.checkbox
  }
  const isText = (): boolean => {
    return field.type === CustomFieldType.text
  }
  const isNumber = (): boolean => {
    return field.type === CustomFieldType.number
  }
  const isTextarea = (): boolean => {
    return field.type === CustomFieldType.textarea
  }

  const handleNumberFieldValue = () => {
    const fieldValue = fieldOneOfValues?.valueList.map((v) => v.numberValue)[0]
    return fieldValue == null ? '' : String(fieldValue)
  }

  return (
    <>
      {isDropdown() && (
        <TicketPropertyCustomSelectField
          ticketId={ticketId}
          field={field}
          fieldValue={fieldOneOfValues?.valueList.map((v) => v.stringValue)[0]}
        />
      )}
      {isDropdownMultiple() && (
        <TicketPropertyCustomMultipleSelectField
          ticketId={ticketId}
          field={field}
          fieldValue={fieldOneOfValues?.valueList.map((v) => v.stringValue)}
        />
      )}
      {isText() && (
        <TicketPropertyCustomTextField
          ticketId={ticketId}
          field={field}
          fieldValue={fieldOneOfValues?.valueList.map((v) => v.stringValue)[0]}
        />
      )}
      {isNumber() && (
        <TicketPropertyCustomNumberField
          ticketId={ticketId}
          field={field}
          fieldValue={handleNumberFieldValue()}
        />
      )}
      {isTextarea() && (
        <TicketPropertyCustomTextAreaField
          ticketId={ticketId}
          field={field}
          fieldValue={fieldOneOfValues?.valueList.map((v) => v.textValue)[0]}
        />
      )}
      {isDatepicker() && (
        <TicketPropertyCustomDateField
          ticketId={ticketId}
          field={field}
          fieldValue={fieldOneOfValues?.valueList.map((v) => v.dateValue)[0]}
        />
      )}
      {isCheckbox() && (
        <TicketPropertyCustomCheckboxField
          ticketId={ticketId}
          field={field}
          fieldValue={fieldOneOfValues?.valueList.map((v) => v.numberValue)[0]}
        />
      )}
    </>
  )
}

export default TicketPropertyCustomFieldComponent
