import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getRoot, COMMAND_PRIORITY_EDITOR, createCommand } from 'lexical'
import * as React from 'react'

import { $createImportantNode, $isImportantNode, ImportantNode } from './node'

export const TOGGLE_IMPORTANT_COMMAND = createCommand('toggle-important')

/**
 * ImportantPlugin は ImportantNode を挿入するためのプラグインです
 * TOGGLE_IMPORTANT_COMMAND が実行された時に、すべてのNodeをImportantNodeで囲みます
 * すでにImportantNodeで囲まれている場合は、ImportantNodeを削除します
 *
 * ImportantNodeは必ずRootNodeの真下に入れるようにします
 * RootNode
 * └ ImportantNode
 *   └ TextNode
 *   └ ...
 */
const ImportantPlugin: React.FC = () => {
  const [editor] = useLexicalComposerContext()
  React.useEffect(() => {
    if (editor.hasNodes([ImportantNode]))
      return editor.registerCommand(
        TOGGLE_IMPORTANT_COMMAND,
        () => {
          const root = $getRoot()
          const children = root.getChildren()
          const firstNode = children[0]

          // 既にImportantNodeがある場合は削除
          if ($isImportantNode(firstNode)) {
            // 内容を取得
            const contents = firstNode.getChildren()
            // ルートに移動
            root.append(...contents)
            // ImportantNodeを削除
            firstNode.remove()
            return true
          }

          // ImportantNodeじゃない場合は一番親に追加する
          const importantNode = $createImportantNode()
          // 内容を移動
          importantNode.append(...children)
          // ルートをクリアしてまっさらにする
          root.clear()
          // そこにImportantNodeのみを追加
          root.append(importantNode)
          return true
        },
        COMMAND_PRIORITY_EDITOR
      )
  }, [editor])

  return <></>
}

export default ImportantPlugin
