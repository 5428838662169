// package: 
// file: proto/requester_image.proto

var proto_requester_image_pb = require("../proto/requester_image_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var RequesterImageAPI = (function () {
  function RequesterImageAPI() {}
  RequesterImageAPI.serviceName = "RequesterImageAPI";
  return RequesterImageAPI;
}());

RequesterImageAPI.RequesterImage = {
  methodName: "RequesterImage",
  service: RequesterImageAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_requester_image_pb.RequesterImageRequest,
  responseType: proto_requester_image_pb.RequesterImageResponse
};

exports.RequesterImageAPI = RequesterImageAPI;

function RequesterImageAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

RequesterImageAPIClient.prototype.requesterImage = function requesterImage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RequesterImageAPI.RequesterImage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.RequesterImageAPIClient = RequesterImageAPIClient;

