import * as React from 'react'

import RequesterNewTicket from '../../renewfeatures/RequesterNewTicket'
import RequesterTicketList from '../../renewfeatures/RequesterTicketList'
import RequesterChatPresenter from './presenter'

const RequesterChat: React.FC = () => {
  const [newTicketHeight, setNewTicketHeight] = React.useState(0)
  const newTicketRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const target = newTicketRef.current
    if (!target) {
      return
    }

    setNewTicketHeight(target.getBoundingClientRect().height)
    const resizeObserver = new ResizeObserver(() => {
      if (!newTicketRef.current) {
        return
      }

      setNewTicketHeight(target.getBoundingClientRect().height)
    })
    resizeObserver.observe(target)
    return () => {
      resizeObserver.unobserve(target)
    }
  }, [])

  return (
    <RequesterChatPresenter
      renderNewTicket={() => <RequesterNewTicket ref={newTicketRef} />}
      renderTicketList={() => (
        <RequesterTicketList otherElementHeight={newTicketHeight} />
      )}
    />
  )
}

export default RequesterChat
