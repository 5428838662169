import {
  Avatar,
  Button,
  Chat,
  ChatItemProps,
  Flex,
  Text,
} from '@fluentui/react-northstar'
import { User } from '@microsoft/microsoft-graph-types'
import * as Graph from '@microsoft/microsoft-graph-types'
import { Dictionary } from '@reduxjs/toolkit'
import React from 'react'

import { AttachmentType, Tickets } from '../../consts'
import message_pb from '../../proto/message_pb'
import ticket_custom_field_pb from '../../proto/ticket_custom_field_pb'
import { formatShort } from '../../utils'
import { getDisplayName, getWebagentDisplayName } from '../../utils'
import { UserPhoto } from '../auth/userPhotosSlice'
import { RequestResponse, Ticket } from '../ticket/ticketSlice'
import { parseRichText } from './Chat'
import {
  createActivityLogItemProps,
  createChatActivityLogItem,
} from './ChatItemActivityLog'
import ChatItemAttachments from './ChatItemAttachments'
import { ChatItemRichText } from './ChatItemRichText'
import styles from './ChatItems.module.css'
import bot from './images/bot.png'

export declare type ChatItemPropsWithKey = ChatItemProps & { key: string }

export const createManualChatItems = ({
  messages,
  userEntities,
  memberEntities,
  userPhotosEntities,
  ticket,
  channelEntities,
  customFields,
  excludesEvent,
}: {
  messages: (message_pb.Message.AsObject | undefined)[]
  userEntities: Dictionary<User>
  memberEntities: Dictionary<User>
  userPhotosEntities: Dictionary<UserPhoto>
  ticket: Ticket
  channelEntities: Dictionary<Graph.Channel>
  customFields: ticket_custom_field_pb.TicketCustomField.AsObject[]
  excludesEvent: boolean
}): ChatItemPropsWithKey[] => {
  return messages
    .map((message, mIndex) => {
      // activityログの場合
      if (message?.activity) {
        const item = createActivityLogItemProps({
          message: message,
          memberEntities: memberEntities,
          userEntities: userEntities,
          customFields: customFields,
          channelEntities: channelEntities,
          index: mIndex,
        })
        if (item == null) {
          return []
        }
        return [createChatActivityLogItem(item)]
      }

      if (excludesEvent) return []

      return message?.eventsList.map((event, eIndex) => {
        const time = formatShort(event.timestamp)
        const mine = message.senderType === Tickets.Messages.SenderTypeOperator
        const text = event.message?.text || ''
        const userName =
          ticket.requesterType === Tickets.RequesterType.RequesterTypeWebagent
            ? message.senderType === Tickets.Messages.SenderTypeRequester
              ? getWebagentDisplayName(ticket.requesterUserId)
              : getDisplayName(userEntities[message.userId])
            : getDisplayName(userEntities[message.userId])
        const userPhoto = userPhotosEntities[message.userId]?.avatarBlob || ''
        const key = `m-${mIndex}-${eIndex}`
        return createChatItem(
          text,
          false,
          mine ? `${userName} ${time}` : time,
          key,
          false,
          mine,
          userName,
          userPhoto,
          event.attachmentsList,
          ticket.requesterUserId
        )
      })
    })
    .filter((e) => e != null)
    .flat()
    .map((e) => e as ChatItemPropsWithKey)
}

export const createAutoChatItems = (
  requestResponses: RequestResponse[],
  requesterName: string,
  requesterPhoto: string,
  operatorName: string,
  showAllAutoMessage: boolean
): ChatItemPropsWithKey[] => {
  if (requestResponses.length === 0) {
    return []
  }

  // 全ての自動応答情報、idを事前に付けます。
  const list = requestResponses
    .map(({ messages, conversationId }, rIndex) =>
      messages.map((message, mIndex) => ({
        ...message,
        conversationId: conversationId,
        id: `a-${rIndex}-${mIndex}`, //idを事前にfixします。
      }))
    )
    .flat()

  // 最新の自動応答情報
  const lastRequestResponse = requestResponses[requestResponses.length - 1]

  return (
    showAllAutoMessage
      ? list
      : list.filter(
          (l) => l.conversationId === lastRequestResponse.conversationId
        )
  ).map((message, index) => {
    const userPhoto =
      message.userType === 'auto' || message.userType === 'manual'
        ? bot
        : requesterPhoto
    const userName =
      message.userType === 'auto' || message.userType === 'manual'
        ? operatorName
        : requesterName
    const time = formatShort(message.time / 1000)
    const key = message.id
    const mine = message.userType === 'auto' || message.userType === 'manual'
    const withButton = message?.content?.type === 'buttons'
    return createChatItem(
      message.text,
      withButton,
      mine ? `${operatorName} ${time}` : time,
      key,
      index === 0,
      mine,
      userName,
      userPhoto,
      []
    )
  })
}

const createChatItem = (
  text: string,
  withButton: boolean,
  timeStamp: string,
  key: string,
  isFirst: boolean,
  mine: boolean,
  userName: string,
  userPhoto: string,
  attachments: Array<message_pb.ResponseAttachment.AsObject>,
  userId?: string
): ChatItemPropsWithKey => {
  const richTextData = attachments.find(
    (s) => s.type === AttachmentType.textHTML
  )
  // richTextは存在すれば、プレーンテキストを差し替えます
  const richText = parseRichText(richTextData)

  // 添付ファイル、添付画像は存在するかどうか。
  const hasAttachments =
    attachments.filter((e) => e.type === AttachmentType.onedrive).length > 0

  return {
    gutter: <Avatar name={userName} image={userPhoto} />,
    styles: { marginTop: isFirst ? '1.2rem' : '' },
    message: (
      <Chat.Message
        content={
          <Flex column gap="gap.small">
            {/*richTextを優先的に表示させます*/}
            {/*リッチテキスト(html)*/}
            {/*リッチテキストを全部ChatItemRichTextComponentに任せます*/}
            {richText && (
              <div className={styles.richText}>
                <ChatItemRichText id={key} content={richText} />
              </div>
            )}
            {/*プレーンテキスト(text)*/}
            {richText === null && (
              <Text>
                {text &&
                  text.trim().length > 0 &&
                  text.split('\n').map((str, index) => (
                    <React.Fragment key={index}>
                      {str}
                      <br />
                    </React.Fragment>
                  ))}
              </Text>
            )}

            {withButton ? (
              <Button
                size={'small'}
                fluid
                inverted={true}
                content="ボタンを表示"
                disabled
              />
            ) : null}
            {hasAttachments ? (
              <ChatItemAttachments
                attachments={attachments}
                userId={userId}
                id={key}
              />
            ) : null}
          </Flex>
        }
        author={userName}
        mine={mine}
        timestamp={timeStamp}
        key={key}
      />
    ),
    contentPosition: mine ? 'end' : 'start',
    key: key,
  }
}
