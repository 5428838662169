import {
  Faq,
  ListFaqsRequest,
  ListFaqsResponse,
  Pagination,
} from '../../proto/faq_pb'
import { FaqAPI } from '../../proto/faq_pb_service'
import { deskOperatorApi } from '../deskApiOperator'
import { ListRecommendedFaqsArgs } from './types/ListRecommendedFaqsArgs'
import {
  PaginationEntity,
  RecommendedFaqEntity,
} from './types/RecommendedFaqEntity'

export const recommendedFaqApi = deskOperatorApi.injectEndpoints({
  endpoints: (build) => ({
    listRecommendedFaqs: build.query<
      {
        entities: RecommendedFaqEntity[]
        pagination: PaginationEntity
      },
      ListRecommendedFaqsArgs
    >({
      query: (arg) => ({
        service: FaqAPI.ListFaqs,
        body: (() => {
          const req = new ListFaqsRequest()
          req.setLimit(arg.limit)
          req.setPage(arg.page)
          if (arg.name) {
            req.setName(arg.name)
          }
          return req
        })(),
      }),
      transformResponse: (res: ListFaqsResponse.AsObject) => {
        return {
          entities: protoIntoRecommendedFaqsEntity(res.faqsList),
          pagination: protoIntoPaginationEntity(res.pagination),
        }
      },
    }),
  }),
})

export const { useListRecommendedFaqsQuery } = recommendedFaqApi

const protoIntoRecommendedFaqEntity = (
  proto: Faq.AsObject
): RecommendedFaqEntity => {
  return {
    id: proto.id,
    displayName: proto.displayName,
    request: proto.request,
  }
}

const protoIntoRecommendedFaqsEntity = (
  proto: Faq.AsObject[]
): RecommendedFaqEntity[] => {
  return proto.map((faq) => protoIntoRecommendedFaqEntity(faq))
}

const protoIntoPaginationEntity = (
  proto?: Pagination.AsObject
): PaginationEntity => {
  if (!proto) {
    return {
      perPage: 0,
      totalCount: 0,
      isFirstPage: false,
      isLastPage: false,
      currentPage: 0,
      nextPage: 0,
      totalPages: 0,
    }
  }
  return {
    perPage: proto.perpage,
    totalCount: proto.totalcount,
    isFirstPage: proto.isfirstpage,
    isLastPage: proto.islastpage,
    currentPage: proto.currentpage,
    nextPage: proto.nextpage,
    totalPages: proto.totalpages,
  }
}
