import * as React from 'react'

import styles from './styles.module.css'

export type ActivityMessagePlanStatusComponentType =
  | 'draft'
  | 'scheduled'
  | 'pending'
  | 'sent'
type Props = {
  status: ActivityMessagePlanStatusComponentType
}
const ActivityMessagePlanStatus: React.FC<Props> = (props) => {
  return (
    <div className={`${styles.container} ${props.status}`}>
      {statusToText(props.status)}
    </div>
  )
}

function statusToText(status: ActivityMessagePlanStatusComponentType): string {
  switch (status) {
    case 'draft':
      return '下書き'
    case 'scheduled':
      return '配信中'
    case 'pending':
      return '配信中'
    case 'sent':
      return '配信済み'
    default:
      return ''
  }
}

export default ActivityMessagePlanStatus
