import { Filter } from '@cubejs-client/core'
import { createSelector } from '@reduxjs/toolkit'
import format from 'date-fns/format'

import { RootState } from '../../app/store'

const stateSelector = (state: RootState) => state.filterSidepanel
export const startDate = createSelector(
  stateSelector,
  (state) => new Date(state.startDate)
)
export const startDateForQuery = createSelector(stateSelector, (state) =>
  format(new Date(state.startDate), 'yyyy-MM-dd')
)
export const endDate = createSelector(
  stateSelector,
  (state) => new Date(state.endDate)
)
export const endDateForQuery = createSelector(stateSelector, (state) =>
  format(new Date(state.endDate), 'yyyy-MM-dd')
)
export const dateRangeString = createSelector(
  stateSelector,
  (state) =>
    `${format(new Date(state.startDate), 'yyyy/MM/dd')} ~ ${format(
      new Date(state.endDate),
      'yyyy/MM/dd'
    )}`
)
export const assigneeUserIds = createSelector(
  stateSelector,
  (state) => state.assigneeUserIds
)
export const assigneeUserIdsFilterForTickets = createSelector(
  stateSelector,
  (state) => {
    const filter: Filter | null =
      state.assigneeUserIds.length > 0
        ? {
            member: 'Tickets.assignedUserId',
            operator: 'contains',
            values: state.assigneeUserIds,
          }
        : null
    return filter
  }
)
export const assigneeUserIdsFilterForTicketRateHourly = createSelector(
  stateSelector,
  (state) => {
    const filter: Filter | null =
      state.assigneeUserIds.length > 0
        ? {
            member: 'TicketRateHourly.assigned_user_id',
            operator: 'contains',
            values: state.assigneeUserIds,
          }
        : null
    return filter
  }
)
export const isOpen = createSelector(stateSelector, (state) => state.isOpen)
