import { useMonaco } from '@monaco-editor/react'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import { consoleErrorWithAirbrake } from '../../utils'
import ChatItemPastedImage from './ChatItemPastedImage'
import styles from './ChatItemRichText.module.css'

export interface RichTextProps {
  content: string
  id: string
}

const isPastedImage = (src: string): boolean => {
  let url: URL
  try {
    url = new URL(src)
  } catch (err) {
    // どのurlがエラーになったかを特定するために、エラーを出力します
    consoleErrorWithAirbrake(`failed to parse url ${src}`, err)
    return false
  }
  // オペレータ側から貼り付け画像
  if (url.pathname === '/api/v1/images') {
    return true
  }

  if (url.pathname === '/transform/thumbnail') {
    return true
  }

  // リクエスター側からきた貼り付け画像のURLの汎用正規表現(BotChannelStorageImage保存先)
  return new RegExp(`https://.*?/objects/.+?/views/.*?`, 'i').test(src)
}

const MentionItemType = `http://schema.skype.com/Mention`

export const ChatItemRichText: React.FC<RichTextProps> = (
  props: RichTextProps
) => {
  const text = props.content

  return (
    <div className={styles.richText}>
      {ReactHtmlParser(text, {
        transform: (node) => {
          switch (node.name) {
            case 'a':
              if (node != null && node.attribs != null && node.type === 'tag') {
                node.attribs['target'] = '_blank'
                node.attribs['rel'] = 'noopener noreferrer'
                return
              }
              // メンションを除外します
              if (node.attribs && node.attribs.itemtype === MentionItemType) {
                return <></>
              }
              return

            case 'img':
              // imgタグの属性はない場合は、処理しないです
              if (!node.attribs) {
                return
              }

              // imgタグのsrc属性はない場合は、処理しないです
              if (!node.attribs.src) {
                return
              }

              // オペレーター側から来てきた貼り付け画像の場合
              // リクエスターからのきた貼り付け画像の場合
              if (isPastedImage(node.attribs.src)) {
                return (
                  <ChatItemPastedImage
                    key={props.id}
                    src={node.attribs.src}
                    driveId={node.attribs['data-drive-id']}
                    itemId={node.attribs['data-item-id']}
                  />
                )
              }
              return
            case 'pre': {
              // Language の設定がない pre は無視
              if (!node.attribs['data-language']) return
              const child = node.children[0]
              if (!child || child.type !== 'text') return
              return (
                <Codeblock
                  language={node.attribs['data-language']}
                  data={child.data}
                />
              )
            }
          }
        },
      })}
    </div>
  )
}

const Codeblock: React.FC<{ language: string; data: string }> = (props) => {
  const monaco = useMonaco()
  const [html, setHtml] = React.useState('')

  console.log(props, html)

  React.useEffect(() => {
    if (!monaco) return
    monaco.editor.colorize(props.data, props.language, {}).then((result) => {
      setHtml(result)
    })
  }, [monaco, setHtml, props])

  return <pre dangerouslySetInnerHTML={{ __html: html }} />
}
