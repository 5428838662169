// package: 
// file: proto/draft_faq.proto

var proto_draft_faq_pb = require("../proto/draft_faq_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var DraftFaqAPI = (function () {
  function DraftFaqAPI() {}
  DraftFaqAPI.serviceName = "DraftFaqAPI";
  return DraftFaqAPI;
}());

DraftFaqAPI.CreateDraftFaq = {
  methodName: "CreateDraftFaq",
  service: DraftFaqAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_draft_faq_pb.CreateDraftFaqRequest,
  responseType: google_protobuf_empty_pb.Empty
};

DraftFaqAPI.GetDraftFaq = {
  methodName: "GetDraftFaq",
  service: DraftFaqAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_draft_faq_pb.GetDraftFaqRequest,
  responseType: proto_draft_faq_pb.DraftFaq
};

exports.DraftFaqAPI = DraftFaqAPI;

function DraftFaqAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

DraftFaqAPIClient.prototype.createDraftFaq = function createDraftFaq(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DraftFaqAPI.CreateDraftFaq, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DraftFaqAPIClient.prototype.getDraftFaq = function getDraftFaq(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DraftFaqAPI.GetDraftFaq, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.DraftFaqAPIClient = DraftFaqAPIClient;

