import { Loader } from '@fluentui/react-northstar'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import { RootState } from '../../app/store'
import { FileProp } from '../../components/RequesterChatFileList'
import * as GraphAPI from '../graphApi'
import * as ActivityMessagePlanPreviewAPI from './api'
import ActivityMessagePlanPreviewBodyPresenter from './presenter'

type Props = {
  id: string
}
const ActivityMessagePreviewBody: React.FC<Props> = (props) => {
  const {
    data: plan,
    isLoading: isPlanLoading,
    isFetching: isPlanFetching,
  } = ActivityMessagePlanPreviewAPI.usePlanDetailQuery({
    id: Number(props.id),
  })

  const appID = ReactRedux.useSelector(
    (state: RootState) => state.auth.decodedSsoToken?.aud ?? ''
  )
  const teamsAppInfoQuery = GraphAPI.useGetTeamsCustomApplicationInfoQuery({
    externalId: appID,
  })

  const attachments = React.useMemo((): FileProp[] => {
    if (!plan) return []
    return plan.attachments.map((a) => ({
      id: a.id.toString(),
      status: 'done',
      url: a.url,
      fileName: a.fileName,
      mimeType: a.mimeType,
    }))
  }, [plan])

  if (isPlanLoading || isPlanFetching || !plan) {
    return <Loader />
  }
  return (
    <ActivityMessagePlanPreviewBodyPresenter
      body={plan.body}
      publisherName={teamsAppInfoQuery.data?.displayName ?? ''}
      publisherSrc={teamsAppInfoQuery.data?.iconURL ?? ''}
      attachments={attachments}
    />
  )
}

export default React.memo(ActivityMessagePreviewBody)
