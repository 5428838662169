// package: 
// file: proto/message.proto

var proto_message_pb = require("../proto/message_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var MessageAPI = (function () {
  function MessageAPI() {}
  MessageAPI.serviceName = "MessageAPI";
  return MessageAPI;
}());

MessageAPI.CreateMessage = {
  methodName: "CreateMessage",
  service: MessageAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_message_pb.CreateMessageRequest,
  responseType: proto_message_pb.Message
};

MessageAPI.ListMessages = {
  methodName: "ListMessages",
  service: MessageAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_message_pb.ListMessagesRequest,
  responseType: proto_message_pb.ListMessagesResponse
};

exports.MessageAPI = MessageAPI;

function MessageAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

MessageAPIClient.prototype.createMessage = function createMessage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MessageAPI.CreateMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MessageAPIClient.prototype.listMessages = function listMessages(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MessageAPI.ListMessages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.MessageAPIClient = MessageAPIClient;

