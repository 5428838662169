import { Flex, Loader } from '@fluentui/react-northstar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { RootState } from '../../app/store'
import PageViewLog from '../../components/PageViewLog'
import { PageName } from '../../consts'
import { updateTicket } from './ticketSlice'
import styles from './TicketTransfer.module.css'

const TicketTransfer: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const urlSearchParams = new URLSearchParams(useLocation().search)
  const groupId = urlSearchParams.get('group_id')
  const channelId = urlSearchParams.get('channel_id')
  const { ticketId } = useParams<{ ticketId: string }>()

  const [updating, setUpdating] = useState(false)

  const ticketState = useSelector((state: RootState) => state.ticket)

  useEffect(() => {
    if (ticketId == null || groupId == null || channelId == null) return
    setUpdating(true)
    dispatch(
      updateTicket(
        {
          id: parseInt(ticketId, 10),
          channel: {
            channelId: channelId,
            groupId: groupId,
          },
        },
        true
      )
    )
  }, [dispatch, ticketId, groupId, channelId])

  useEffect(() => {
    if (!updating || ticketState.loading) return
    history.push('/tickets')
  }, [history, updating, ticketState.loading])

  return (
    <PageViewLog pageName={PageName.TicketTransfer}>
      <Flex column className={styles.container}>
        <Flex gap="gap.small">
          <h1>問い合わせを移動中</h1>
          <Loader size="small" />
        </Flex>
        <div>自動的に画面が切り替わりますので、しばらくお待ち下さい。</div>
      </Flex>
    </PageViewLog>
  )
}

export default TicketTransfer
