import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { RootState } from '../../app/store'
import { OneDriveData } from './types'

export interface MessageAttachment {
  id: number
  progress: number
  attachmentData: OneDriveData
  pastedImage?: boolean
  pastedImageSize?: { width: number; height: number } //貼り付け画像がリクエスター側でレンダリングする時に、使うサムネールの本当なサイズ。
}
interface MessageAttachmentsState {
  loading: boolean
  error: string | null
}

export const messageAttachmentsAdapter = createEntityAdapter<MessageAttachment>(
  {
    selectId: (m) => m.id,
  }
)

// このスライスはオペレーター側から添付したファイルの情報を管理しています。
// The slice is based on the attachments in db
export const messageAttachmentsSlice = createSlice({
  name: 'messageAttachments',
  initialState:
    messageAttachmentsAdapter.getInitialState<MessageAttachmentsState>({
      loading: false,
      error: null,
    }),
  reducers: {
    uploadAttachmentProcessOnStart(
      state,
      action: PayloadAction<{
        id: number
        filename: string
        progress: number
        pastedImage: boolean
      }>
    ) {
      messageAttachmentsAdapter.addOne(state, {
        id: action.payload.id,
        attachmentData: {
          name: action.payload.filename,
          webUrl: '',
          downloadUrl: '',
          itemId: '',
          uniqueId: '',
          size: 0,
          mimeType: '',
          driveId: '',
        },
        progress: action.payload.progress,
        pastedImage: action.payload.pastedImage,
      })
    },
    uploadAttachmentProcessOnProgress(
      state,
      action: PayloadAction<{ id: number; progress: number }>
    ) {
      messageAttachmentsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          progress: action.payload.progress,
        },
      })
    },
    uploadAttachmentOnEnd(
      state,
      action: PayloadAction<{ id: number; data: OneDriveData }>
    ) {
      const data = messageAttachmentsAdapter
        .getSelectors()
        .selectById(state, action.payload.id)
      if (!data) {
        return
      }
      messageAttachmentsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          attachmentData: { ...data.attachmentData, ...action.payload.data },
        },
      })
    },
    uploadAttachmentOnThumbnailSizedFetched(
      state,
      action: PayloadAction<{
        id: number
        size: { width: number; height: number }
      }>
    ) {
      messageAttachmentsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          pastedImageSize: { ...action.payload.size },
        },
      })
    },
    uploadAttachmentProcessOnEnd(
      state,
      action: PayloadAction<{ file: MessageAttachment }>
    ) {
      messageAttachmentsAdapter.upsertOne(state, action.payload.file)
    },
    uploadAttachmentProcessOnRemove(
      state,
      action: PayloadAction<{ id: number }>
    ) {
      messageAttachmentsAdapter.removeOne(state, action.payload.id)
    },
    uploadAttachmentProcessesOnClear(state) {
      messageAttachmentsAdapter.removeAll(state)
    },
  },
})

export const {
  uploadAttachmentProcessOnStart,
  uploadAttachmentProcessOnEnd,
  uploadAttachmentProcessOnProgress,
  uploadAttachmentProcessOnRemove,
  uploadAttachmentProcessesOnClear,
  uploadAttachmentOnEnd,
  uploadAttachmentOnThumbnailSizedFetched,
} = messageAttachmentsSlice.actions
export default messageAttachmentsSlice.reducer

export const messageAttachmentsSelectors =
  messageAttachmentsAdapter.getSelectors(
    (state: RootState) => state.messageAttachments
  )
