import {
  Box,
  Button,
  Flex,
  Form,
  FormButton,
  Loader,
  Text,
} from '@fluentui/react-northstar'
import React, { Dispatch, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { AppThunk, RootState } from '../../app/store'
import { addAlert } from '../alert/alertsSlice'
import FaqDeleted from './FaqDeleted'
import FaqForm from './FaqForm'
import { getFaq, updateFaq } from './faqSlice'

const FaqDetail: React.FC = () => {
  const dispatch = useDispatch()

  const { ticketId } = useParams<{ ticketId: string }>()

  const faqState = useSelector((state: RootState) => state.faq)

  const faq = faqState.entities[ticketId]

  const [requestValue, setRequestValue] = useState('')
  const [responseValue, setResponseValue] = useState('')
  const [publish, setPublish] = useState(true)
  const [name, setName] = useState('')
  const [labels, setLabels] = useState<string[]>([])

  const [disabled, setDisabled] = useState(true)

  const initializeField = () => {
    setDisabled(true)
    if (faq != null) {
      setRequestValue(faq.request)
      setResponseValue(faq.response)
      setPublish(faq.publish)
      setName(faq.name)
      setLabels(faq.labelsList.map((it) => it.toString()))
    }
  }
  const handleUpdateFaq = (dispatch: Dispatch<AppThunk<void>>) => {
    dispatch(
      updateFaq(parseInt(ticketId, 10), {
        request: requestValue || '',
        response: responseValue || '',
        publish: publish || false,
        name: name || '',
        labelsList: labels.map((it) => parseInt(it, 10)),
      })
    )
  }

  useEffect(() => {
    dispatch(getFaq(parseInt(ticketId, 10)))
  }, [dispatch, ticketId])

  useEffect(initializeField, [faq])

  useEffect(() => {
    if (faqState.error !== null) {
      dispatch(
        addAlert({
          id: Math.random() * 1000,
          title:
            'FAQの作成タブで問題が発生しました。しばらくお待ちいただいてから、もう一度お試しください。',
          type: 'faq_detail_error',
        })
      )
    }
  }, [dispatch, faqState.error])

  return (
    <>
      {faqState.loading ? (
        <Loader size="small" />
      ) : faq?.deleted ? (
        <FaqDeleted />
      ) : (
        <Form onSubmit={() => handleUpdateFaq(dispatch)}>
          <Flex column gap="gap.large">
            <FaqForm
              disabled={disabled}
              requestValue={requestValue}
              setRequestValue={setRequestValue}
              responseValue={responseValue}
              setResponseValue={setResponseValue}
              publish={publish}
              setPublish={setPublish}
              name={name}
              labels={labels}
              setLabels={setLabels}
            />
            {disabled ? (
              <>
                <Flex column hAlign={'center'}>
                  <Text>FAQ作成済</Text>
                  <Button
                    primary
                    text
                    content="(内容を編集する)"
                    onClick={() => setDisabled(false)}
                  />
                </Flex>
                <Box />
              </>
            ) : (
              <Flex column gap="gap.smaller">
                {faqState.loading ? (
                  <Loader size="small" />
                ) : (
                  <>
                    <FormButton
                      fluid
                      primary
                      content="この内容で更新"
                      disabled={!requestValue || !responseValue}
                    />
                    <Button
                      tinted
                      content="キャンセル"
                      onClick={() => initializeField()}
                    />
                  </>
                )}
                <Text align={'center'} disabled>
                  更新後、自動で学習を行います
                </Text>
                <Text align={'center'} disabled>
                  FAQを更新後も編集ができます
                </Text>
              </Flex>
            )}
          </Flex>
        </Form>
      )}
    </>
  )
}

export default FaqDetail
