import React, { useEffect, useMemo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import placeholder from '../../images/placeholder.png'
import {
  BotChannelStorageImageData,
  botChannelStorageImagesSelectors,
} from './botChannelStorageImagesSlice'
import { ChatImage } from './ChatImage'
import styles from './ChatItemPastedImage.module.css'
import { onImagePreviewShow } from './imagePreviewSlice'
import {
  BotChannelStorageImageResource,
  ImageResourceResult,
  OneDriveImageResource,
  PastedImageResource,
  createPastedImageResource,
} from './PastedImageResource'
import { ThumbnailsState, thumbnailsSelectors } from './thumbnailsSlice'

interface ChatItemPastedImageProps {
  src: string
  key: string
  driveId?: string
  itemId?: string
}
const ChatItemPastedImage: React.FC<ChatItemPastedImageProps> = (props) => {
  const { driveId, itemId } = props
  const imgInquiry = useMemo(() => {
    if (driveId != null && itemId != null) {
      return new OneDriveImageResource('', itemId, { driveId: driveId })
    }

    return createPastedImageResource(props.src)
  }, [props.src, driveId, itemId])

  if (imgInquiry == null) {
    return <></>
  }

  if (imgInquiry instanceof BotChannelStorageImageResource) {
    return (
      <LazyLoadComponent>
        <BotChannelStoragePastedImage imgInquiry={imgInquiry} />
      </LazyLoadComponent>
    )
  }

  if (imgInquiry instanceof OneDriveImageResource) {
    return (
      <LazyLoadComponent>
        <OneDrivePastedImage imgInquiry={imgInquiry} />
      </LazyLoadComponent>
    )
  }

  return <></>
}

const OneDrivePastedImage = (props: {
  imgInquiry: PastedImageResource<ThumbnailsState>
}) => {
  const { imgInquiry } = props
  const dispatch = useDispatch()
  useEffect(() => {
    imgInquiry.query(dispatch)
  }, [imgInquiry, dispatch])
  const imageItems = useSelector(thumbnailsSelectors.selectAll)
  const imgResult = useMemo(
    () => imgInquiry.find(imageItems),
    [imgInquiry, imageItems]
  )
  return <PasteImageResult imgResult={imgResult} />
}

const BotChannelStoragePastedImage = (props: {
  imgInquiry: PastedImageResource<BotChannelStorageImageData>
}) => {
  const { imgInquiry } = props
  const dispatch = useDispatch()
  useEffect(() => {
    imgInquiry.query(dispatch)
  }, [imgInquiry, dispatch])
  const imageItems = useSelector(botChannelStorageImagesSelectors.selectAll)
  const imgResult = useMemo(
    () => imgInquiry.find(imageItems),
    [imgInquiry, imageItems]
  )
  return <PasteImageResult imgResult={imgResult} />
}

const ChatImagePlaceHolderMaxWidth = 250
const ChatImagePlaceHolderMaxRatio = 0.45

const PasteImageResult = (props: { imgResult: ImageResourceResult }) => {
  const { imgResult } = props
  const dispatch = useDispatch()
  const layoutState = useSelector((state: RootState) => state.layout)
  // サムネールは取得できなかった(404)の場合は、画像が見つかりませんでしたを表示させます。
  const isNotFound = imgResult.errorMessage === 'NotFound'

  const isLoading = imgResult.errorMessage === 'NotReady' && !isNotFound

  const src = imgResult.src

  const imageText = useMemo(() => {
    if (isLoading) {
      return '画像を読み込んでいます...'
    }

    if (isNotFound) {
      return '画像が見つかりませんでした'
    }

    return
  }, [isLoading, isNotFound])

  const imageSrc = useMemo(() => {
    if (isLoading || isNotFound) {
      return placeholder
    }

    if (src != null) {
      return src
    }

    return ''
  }, [src, isLoading, isNotFound])

  const obj: { src: string; downloadData?: string; openUrl?: string } = {
    src: src ?? '',
  }

  if (imgResult.type === 'base64') {
    obj.downloadData = src
  }

  if (imgResult.type === 'url') {
    // サムネールリンクにするか、shareLinkにするか、未定
    obj.openUrl = src
  }

  const placeHolderSize = layoutState?.chatLayout?.clientWidth
    ? Math.min(
        layoutState?.chatLayout?.clientWidth * ChatImagePlaceHolderMaxRatio,
        ChatImagePlaceHolderMaxWidth
      )
    : ChatImagePlaceHolderMaxWidth

  const imageStyle = useMemo(() => {
    if (isLoading === true) {
      return {
        width: `${placeHolderSize}px`,
        height: `${placeHolderSize}px`,
      }
    }
    return {}
  }, [placeHolderSize, isLoading])

  return (
    <ChatImage
      src={imageSrc}
      styles={imageStyle}
      className={styles.pastedImage}
      onClick={() => dispatch(onImagePreviewShow(obj))}
      text={imageText}
    />
  )
}

export default ChatItemPastedImage
