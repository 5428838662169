import { createSelector } from '@reduxjs/toolkit'

import { ChatButtonsProp } from '../../../../components/RequesterChatButtonGroup'
import { requesterTicketListApiDataSelector } from '../../selectors'
import { hasButtonsMessage } from '../../types/RequesterMessage'

export const ticketMessageButtonsSelector = createSelector(
  [requesterTicketListApiDataSelector, (_, id: string) => id],
  (ticketEntity, id): ChatButtonsProp => {
    if (!ticketEntity) return []
    const ticket = ticketEntity.entities[id]
    if (!ticket) return []

    const buttonMessages = ticket.message

    if (!hasButtonsMessage(buttonMessages)) return []

    if (buttonMessages.type === 'buttons') {
      return buttonMessages.buttons.map((b) => [
        {
          key: b.key,
          label: b.label,
          url: b.url,
          type: b.type,
          data: b.data,
        },
      ])
    }
    return [
      buttonMessages.buttons.map((b) => ({
        key: b.key,
        label: b.label,
        url: b.url,
        type: b.type,
      })),
    ]
  }
)
