import { Avatar, Box, Flex, Text } from '@fluentui/react-northstar'
import {
  ContentState,
  DraftHandleValue,
  Editor,
  EditorState,
  Modifier,
} from 'draft-js'
import { useCallback, useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { Message } from '../../proto/message_pb'
import { formatShort } from '../../utils'
import { fetchUserPhotos } from '../auth/userPhotosSlice'
import { fetchUsers } from '../auth/usersSlice'
import { addSection } from '../chat/ChatItemActivityLog'
import {
  closeAllTimers,
  fetchMessages,
  messagesSelectors,
  removeAllMessages,
  setVisibleActivityLog,
  setVisibleEvent,
} from '../chat/messagesSlice'
import { activityLogItemsSelector } from '../chat/messagesSlice'
import styles from './ManualTicketNote.module.css'
import { Ticket, updateTicket } from './ticketSlice'

const PlaceHolderText = '用件を入力してください'
const MAX_LENGTH = 10000
const ManualTicketNote = (props: { ticket: Ticket }) => {
  const { ticket } = props

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [noteInited, setNoteInited] = useReducer(() => true, false)
  const dispatch = useDispatch()

  const messageState = useSelector((root: RootState) => root.messages)

  // リクエスター情報とphotoを取得します, 投稿タブから直接入る場合はあります
  useEffect(() => {
    dispatch(fetchUsers([ticket.requesterUserId]))
    dispatch(fetchUserPhotos([ticket.requesterUserId]))
  }, [dispatch, ticket.requesterUserId])

  useEffect(() => {
    if (noteInited) {
      return
    }

    // またnoteを取得できてない段階
    // 保存されてあるnoteはからの値の場合
    if (ticket.note === '') {
      // 初期化しません
      return
    }

    // 保存されてある値がある場合には、保存します。
    const contentState = ContentState.createFromText(ticket.note)
    const nextEditorState = EditorState.createWithContent(contentState)
    setEditorState(
      EditorState.forceSelection(
        nextEditorState,
        nextEditorState.getSelection()
      )
    )
    setNoteInited()
  }, [ticket.note, noteInited])

  // open switch for fetching activity log
  useEffect(() => {
    dispatch(setVisibleActivityLog({ visible: true }))
    dispatch(setVisibleEvent({ visible: false }))
    return () => {
      dispatch(setVisibleActivityLog({ visible: false }))
      dispatch(setVisibleEvent({ visible: true }))
    }
  }, [dispatch, ticket.id])

  // when the switch condition is satisfied, fetch messages(activity log)
  useEffect(() => {
    if (messageState.includesActivityLog === false) {
      return
    }

    if (messageState.excludesEvent === false) {
      return
    }

    // TODO: ここfetchMessagesをdelayさせずに、投げます。
    // 手動チケットを作成したら、すぐ詳細画面に入って、activityLogを取得します。
    // ローカル環境では、なんかの原因ですぐESからactivityLogを取り出そうとしたら、空白な結果が返されます。
    // 一時的な対策は画面に入って、一時的に待ってからactivityLogを取り出します。
    setTimeout(() => {
      dispatch(fetchMessages(ticket.id))
    }, 1000)

    return () => {
      dispatch(removeAllMessages())
      dispatch(closeAllTimers())
    }
  }, [
    messageState.includesActivityLog,
    messageState.excludesEvent,
    dispatch,
    ticket.id,
  ])

  const messages = useSelector(messagesSelectors.selectAll)
  const doUpdateNote = useCallback(
    (value: string) => {
      dispatch(
        updateTicket({ id: ticket.id, note: value.slice(0, MAX_LENGTH) }, true)
      )
    },
    [dispatch, ticket.id]
  )

  const handleChange = (value?: EditorState) => {
    if (value == null) {
      return
    }
    setEditorState(value)
  }

  const handleOnBlur = () => {
    if (editorState == null) {
      return
    }

    doUpdateNote(editorState.getCurrentContent().getPlainText())
  }

  // エディターにフォーカスさせます
  // エディター実際の高さが低いなので、白い部分をクリックすることによって、エディターをクリックできるように
  const handleClick = () => {
    const selection = editorState.getSelection()
    if (!selection.isCollapsed()) {
      return
    }
    setEditorState(
      EditorState.forceSelection(editorState, editorState.getSelection())
    )
  }

  // IME入力はここでハンドリングしません、アルファベットのみハンドリングできます
  const handleBeforeInput = useCallback(
    (chars: string, editorState: EditorState): DraftHandleValue => {
      const content = editorState.getCurrentContent()
      const selection = editorState.getSelection()

      // 何か選択されてある状態で何か入力したら、必ず、長さが短縮されますので、ハンドリングしません
      if (!selection.isCollapsed()) {
        return 'not-handled'
      }

      // 現状の文字数は上限まで至っていますから、それ以上入力できないように制限をかけます
      if (content.getPlainText().length >= MAX_LENGTH) {
        return 'handled'
      }
      return 'not-handled'
    },
    []
  )

  const handlePastedText = (
    text: string,
    html: string | undefined,
    editorState: EditorState
  ): DraftHandleValue => {
    const content = editorState.getCurrentContent()
    const selection = editorState.getSelection()

    // 長さはすでに超えてある場合は、何も貼り付けられないように制限をかけます。
    if (content.getPlainText().length > MAX_LENGTH) {
      return 'handled'
    }

    // 何も選択されてない場合
    if (selection.isCollapsed()) {
      if (content.getPlainText().length + text.length <= MAX_LENGTH) {
        return 'not-handled'
      }

      // 貼り付け後の文字数は制限を超えてある場合は、一部分だけ貼り付けられるように制限をかけます。
      const nextContent = Modifier.insertText(
        content,
        selection,
        text.slice(0, MAX_LENGTH - content.getPlainText().length)
      )
      setEditorState(
        EditorState.push(editorState, nextContent, 'insert-characters')
      )
      return 'handled'
    }

    // 何か選択されてある時に、文字を貼り付ける場合
    const newContent = Modifier.removeRange(content, selection, 'backward')
    // 貼り付け後文字数は制限を超えてない場合
    if (newContent.getPlainText().length + text.length <= MAX_LENGTH) {
      return 'not-handled'
    }

    // 貼り付け後文字数は制限を超えてある場合
    const nextContent = Modifier.replaceText(
      content,
      selection,
      text.slice(0, MAX_LENGTH - newContent.getPlainText().length)
    )

    // stateを手動で更新します
    setEditorState(
      EditorState.push(editorState, nextContent, 'insert-characters')
    )
    return 'handled'
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.textAreaContainerTitle}>
        <Text>用件</Text>
      </Box>
      <Flex className={styles.textAreaContainer}>
        <Box className={styles.chatInputWrapper} onClick={handleClick}>
          <Box className={styles.chatInputInner}>
            <Editor
              handlePastedText={handlePastedText}
              handleBeforeInput={handleBeforeInput}
              editorState={editorState}
              onChange={(e) => handleChange(e)}
              onBlur={() => handleOnBlur()}
              placeholder={PlaceHolderText}
            />
          </Box>
        </Box>
      </Flex>
      <Box className={styles.activityLogContainer}>
        <Box className={styles.activityLogContainerTitle}>
          <Text>操作履歴</Text>
        </Box>
        <Flex column gap="gap.small">
          {messages.map((d, k) => (
            <Box key={k}>
              {d.activity && (
                <ManualTicketNoteActivityLogItem
                  message={d}
                  userId={d.userId}
                  index={k}
                />
              )}
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}

const ManualTicketNoteActivityLogItem = (props: {
  message: Message.AsObject
  userId: string
  index: number
}) => {
  const { message, userId, index } = props
  const activity = message.activity
  const userPhotoEntities = useSelector((root: RootState) => root.userPhotos)
  const userPhototEntity = userPhotoEntities.entities[userId]
  const activityLogItem = useSelector((root: RootState) =>
    activityLogItemsSelector(root, message, index)
  )

  if (activity == null) {
    return <></>
  }

  if (activityLogItem == null) {
    return <></>
  }

  const displayName = activityLogItem.changeUser

  return (
    <>
      <Flex className={styles.activityLogItem} gap={'gap.small'}>
        <Flex>
          <Box>
            <Avatar name={displayName} image={userPhototEntity?.avatarBlob} />
          </Box>
        </Flex>
        <Flex column gap="gap.small" className={styles.activityLogItemTitle}>
          <Flex column gap="gap.medium">
            <Box className={styles.head}>
              <Text
                content={displayName}
                className={styles.activityLogEmphasisText}
              ></Text>
              {addSection(displayName)}
              <Text
                content={activityLogItem.type}
                className={styles.activityLogEmphasisText}
              ></Text>
              を{titleType(activity.actionType)}しました
              <Text>{formatShort(activityLogItem.timestamp)}</Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <Flex className={styles.activityLogItem} gap={'gap.small'}>
        {activity.actionType === 'change' && (
          <Box className={styles.activityLogBody}>
            {typeof activityLogItem.preventData === 'string' ? (
              <Text
                content={activityLogItem.preventData}
                className={styles.activityLogEmphasisText}
              ></Text>
            ) : (
              activityLogItem.preventData
            )}
            <Text content={' → '}></Text>
            {typeof activityLogItem.changedData === 'string' ? (
              <Text
                content={activityLogItem.changedData}
                className={styles.activityLogEmphasisText}
              ></Text>
            ) : (
              activityLogItem.changedData
            )}
          </Box>
        )}
      </Flex>
    </>
  )
}

const titleType = (type: string): string => {
  switch (type) {
    case 'create':
      return '作成'
    case 'change':
      return '変更'
    default:
      return ''
  }
}

export default ManualTicketNote
