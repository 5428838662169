import { CloseIcon } from '@fluentui/react-icons-northstar'
import { Button, Text } from '@fluentui/react-northstar'
import { useVirtualizer } from '@tanstack/react-virtual'
import * as React from 'react'

import styles from './styles.module.css'

type Props = {
  isActive?: boolean
  receivers: string[]
  renderReceiverItem: (id: string) => React.ReactElement
  onClickClose: () => void
}
const ActivityMessagePlanPreviewReceiverListPresenter: React.FC<Props> = (
  props
) => {
  const parentRef = React.useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: props.receivers.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,
  })

  if (!props.isActive) return <></>

  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Text size="large" weight="bold">
            配信先リスト ({props.receivers.length}件)
          </Text>
          <Button
            text
            iconOnly
            icon={<CloseIcon />}
            onClick={props.onClickClose}
          />
        </div>
        <div ref={parentRef} className={styles.body}>
          <div
            style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
            className={styles.bodyInner}
          >
            {rowVirtualizer.getVirtualItems().map((virtualItem) => (
              <div
                key={virtualItem.key}
                style={{
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`,
                }}
                className={styles.listItem}
              >
                {props.renderReceiverItem(props.receivers[virtualItem.index])}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.footer}>
          <Button onClick={props.onClickClose} content="閉じる" primary />
        </div>
      </div>
    </div>
  )
}

export default ActivityMessagePlanPreviewReceiverListPresenter
