import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk } from '../../app/store'
import { unique } from '../../utils'
import { resetTokens } from './authSlice'

export interface UserPhoto {
  userId: string
  loading: boolean
  avatarBlob?: string
}

export const userPhotosAdapter = createEntityAdapter<UserPhoto>({
  selectId: (u) => u.userId,
})

export const userPhotosSlice = createSlice({
  name: 'userPhotos',
  initialState: userPhotosAdapter.getInitialState(),
  reducers: {
    fetchUserPhotoStart(state, action: PayloadAction<{ userId: string }>) {
      userPhotosAdapter.upsertOne(state, {
        ...action.payload,
        loading: true,
      })
    },
    fetchUserPhotoSuccess(
      state,
      action: PayloadAction<{
        userId: string
        avatarBlob: string
      }>
    ) {
      userPhotosAdapter.upsertOne(state, {
        ...action.payload,
        loading: false,
      })
    },
  },
})

export const fetchUserPhotos =
  (ids: string[]): AppThunk =>
  async (dispatch, getState, { graphAPI }) => {
    const graphAccessToken = getState().auth.graphAccessToken
    if (!graphAccessToken) return

    const userIds = unique(
      ids.filter((userId) => !getState().userPhotos.entities[userId])
    )
      .filter((id) => id != null)
      .filter((id) => id.trim() !== '')
    if (userIds.length === 0) return

    await Promise.all(
      userIds.map(async (userId) => {
        dispatch(fetchUserPhotoStart({ userId }))
        const picBlob = await graphAPI.api(`users/${userId}/photo/$value`).get()
        dispatch(
          fetchUserPhotoSuccess({
            userId: userId,
            avatarBlob: URL.createObjectURL(picBlob),
          })
        )
      })
    ).catch((e) => {
      if (e.statusCode === 401) {
        dispatch(resetTokens())
        return
      }
      // Exchangeが利用できないMSサービスプランなどで、画像が取得できないケースが存在しそう。
      // エラーとするのではなく、画像がなくてもアプリケーションが動くように実装する
      console.warn(`fetchUserPhoto failure: ${e}`)
    })
  }

export const { fetchUserPhotoSuccess, fetchUserPhotoStart } =
  userPhotosSlice.actions
export default userPhotosSlice.reducer
