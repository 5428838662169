import { grpc } from '@improbable-eng/grpc-web'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk, RootState } from '../../app/store'
import notification_setting_pb, {
  NewFaqNotificationUser,
} from '../../proto/notification_setting_pb'
import notification_setting_pb_service from '../../proto/notification_setting_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'

interface NotificationFaqSettingState {
  loading: boolean
  error?: string
}

const notificationFaqSettingAdapter =
  createEntityAdapter<notification_setting_pb.NewFaqNotificationUser.AsObject>({
    selectId: (m) => m.userId || '',
    sortComparer: (a, b) => {
      return a.userId.localeCompare(b.userId)
    },
  })

export const notificationFaqSettingSelector =
  notificationFaqSettingAdapter.getSelectors(
    (rootState: RootState) => rootState.notificationFaqSetting
  )

const notificationFaqSettingSlice = createSlice({
  name: 'notificationFaqSetting',
  initialState:
    notificationFaqSettingAdapter.getInitialState<NotificationFaqSettingState>({
      loading: false,
    }),
  reducers: {
    fetchNotificationFaqSettingOnStart: (state) => {
      state.loading = true
      delete state.error
    },
    fetchNotificationFaqSettingOnMessage: (
      state,
      action: PayloadAction<{
        message: notification_setting_pb.ListNewFaqNotificationUsersResponse.AsObject
      }>
    ) => {
      notificationFaqSettingAdapter.setAll(
        state,
        action.payload.message.newFaqNotificationUsersList
      )
    },
    fetchNotificationFaqSettingOnEnd: (
      state,
      action: PayloadAction<{ code: grpc.Code; message: string }>
    ) => {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        delete state.error
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
    updateNotificationFaqSettingOnStart: (state) => {
      state.loading = true
      delete state.error
    },
    updateNotificationFaqSettingOnEnd: (
      state,
      action: PayloadAction<{ code: grpc.Code; message: string }>
    ) => {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        delete state.error
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
  },
})

export const {
  fetchNotificationFaqSettingOnStart,
  fetchNotificationFaqSettingOnMessage,
  fetchNotificationFaqSettingOnEnd,
  updateNotificationFaqSettingOnStart,
  updateNotificationFaqSettingOnEnd,
} = notificationFaqSettingSlice.actions

export const fetchNewFaqNotificationUsers =
  (): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      notification_setting_pb.ListNewFaqNotificationUsersRequest,
      notification_setting_pb.ListNewFaqNotificationUsersResponse
    >(
      notification_setting_pb_service.NotificationSettingsAPI
        .ListNewFaqNotificationUsers
    )
    const req = new notification_setting_pb.ListNewFaqNotificationUsersRequest()

    client.onMessage((message) => {
      dispatch(
        fetchNotificationFaqSettingOnMessage({ message: message.toObject() })
      )
    })

    client.onEnd((code, message) => {
      dispatch(fetchNotificationFaqSettingOnEnd({ code, message }))
    })

    dispatch(fetchNotificationFaqSettingOnStart())
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const updateNewFaqNotificationUsers =
  (userIds: string[]): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      notification_setting_pb.UpdateNewFaqNotificationUsersRequest,
      notification_setting_pb.UpdateNewFaqNotificationUsersResponse
    >(
      notification_setting_pb_service.NotificationSettingsAPI
        .UpdateNewFaqNotificationUsers
    )
    const req =
      new notification_setting_pb.UpdateNewFaqNotificationUsersRequest()

    req.setNewFaqNotificationUsersList(
      userIds.map((id) => {
        const obj = new NewFaqNotificationUser()
        obj.setUserId(id)
        return obj
      })
    )
    client.onEnd((code, message) => {
      dispatch(updateNotificationFaqSettingOnEnd({ code, message }))
    })

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export default notificationFaqSettingSlice.reducer
