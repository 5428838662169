import {
  ArrowDownIcon,
  ArrowSortIcon,
  ArrowUpIcon,
  Button,
  Props,
  Text,
} from '@fluentui/react-northstar'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { CustomFieldType, DefaultFields, Sort } from '../../consts'
import { consoleErrorWithAirbrake } from '../../utils'
import {
  clearSort,
  setSortCreatedAt,
  setSortCustomFieldValueDate,
  setSortCustomFieldValueNumber,
  setSortKeyId,
  setSortRecievedAt,
  setSortSentAt,
  setSortStatus,
} from './ticketSlice'
import styles from './TicketSorts.module.css'

const TicketSort: React.FC<Props> = ({
  column,
  customField,
  isCustomFieldHeader,
}): JSX.Element => {
  const dispatch = useDispatch()

  const ticketState = useSelector((state: RootState) => state.ticket)

  const isMatchedCustomFieldWithDirection = (
    properties: Record<string, string>,
    direction: string
  ): boolean => {
    for (const val of Object.keys(properties)) {
      if (Number(val) === customField.id && properties[val] === direction) {
        return true
      }
    }
    return false
  }

  // エンドユーザーによるソートの指定がかかっているカラムか判定
  const isSorted = (direction: string): boolean => {
    if (isCustomFieldHeader) {
      switch (customField.type) {
        case CustomFieldType.number:
          return isMatchedCustomFieldWithDirection(
            ticketState.sorts.customFieldValue.number,
            direction
          )
        case CustomFieldType.date:
          return isMatchedCustomFieldWithDirection(
            ticketState.sorts.customFieldValue.date,
            direction
          )
        default:
          return false
      }
    }

    switch (column.id) {
      case DefaultFields.KeyId.name: {
        return ticketState.sorts.keyId === direction
      }
      case DefaultFields.Status.name: {
        return ticketState.sorts.status === direction
      }
      case DefaultFields.CreatedAt.name: {
        return ticketState.sorts.createdAt === direction
      }
      case DefaultFields.ReceivedAt.name: {
        return ticketState.sorts.receivedAt === direction
      }
      case DefaultFields.SentAt.name: {
        return ticketState.sorts.sentAt === direction
      }
      default: {
        return false
      }
    }
  }

  const notSorted = (): boolean => {
    return (
      isSorted(Sort.Asc.value) === false && isSorted(Sort.Desc.value) === false
    )
  }

  const onClickSort = (direction: string): void => {
    dispatch(clearSort())

    if (isCustomFieldHeader) {
      const type = customField?.type || ''
      switch (type) {
        case CustomFieldType.date:
          dispatch(
            setSortCustomFieldValueDate({
              fieldId: String(customField.id),
              direction: direction,
            })
          )
          return
        case CustomFieldType.number:
          dispatch(
            setSortCustomFieldValueNumber({
              fieldId: String(customField.id),
              direction: direction,
            })
          )
          return
        default:
          consoleErrorWithAirbrake(
            `invalid custom field type was tried to sort: ${type}`
          )
          return
      }
    }
    switch (column.id) {
      case DefaultFields.KeyId.name:
        dispatch(setSortKeyId({ direction: direction }))
        return
      case DefaultFields.Status.name:
        dispatch(setSortStatus({ direction: direction }))
        return
      case DefaultFields.CreatedAt.name:
        dispatch(setSortCreatedAt({ direction: direction }))
        return
      case DefaultFields.ReceivedAt.name:
        dispatch(setSortRecievedAt({ direction: direction }))
        return
      case DefaultFields.SentAt.name:
        dispatch(setSortSentAt({ direction: direction }))
        return
      default:
        consoleErrorWithAirbrake(`unsortable column.id: ${column.id}`)
        return
    }
  }

  return (
    <>
      <Text
        content={column.render('Header')}
        styles={{
          cursor: 'pointer',
        }}
        onClick={() => {
          if (isSorted(Sort.Asc.value)) return onClickSort(Sort.Desc.value)
          if (isSorted(Sort.Desc.value)) return onClickSort(Sort.Asc.value)
          if (notSorted()) return onClickSort(Sort.Asc.value)
          return
        }}
      />
      <div className={styles.sortIcon}>
        {isSorted(Sort.Asc.value) && (
          <Button
            text
            iconOnly
            className={styles.columnOptionIcon}
            styles={({ theme: { siteVariables } }) => ({
              color: siteVariables.colorScheme.red.foreground,
            })}
            icon={<ArrowUpIcon size="small" />}
            onClick={() => onClickSort(Sort.Desc.value)}
          />
        )}
        {isSorted(Sort.Desc.value) && (
          <Button
            text
            iconOnly
            className={styles.columnOptionIcon}
            styles={({ theme: { siteVariables } }) => ({
              color: siteVariables.colorScheme.red.foreground,
            })}
            icon={<ArrowDownIcon size="small" />}
            onClick={() => onClickSort(Sort.Asc.value)}
          />
        )}
        {notSorted() && (
          <Button
            text
            iconOnly
            className={styles.columnOptionIcon}
            styles={({ theme: { siteVariables } }) => ({
              color: siteVariables.naturalColors.grey[350],
            })}
            icon={<ArrowSortIcon size="small" />}
            onClick={() => onClickSort(Sort.Asc.value)}
          />
        )}
      </div>
    </>
  )
}

export default TicketSort
