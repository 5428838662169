import { ChevronStartIcon } from '@fluentui/react-icons-northstar'
import { Alert, Box, Button, Flex, Grid } from '@fluentui/react-northstar'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'

import { RootState } from '../../app/store'
import PageViewLog from '../../components/PageViewLog'
import { PicturePreview } from '../../components/PicturePreview'
import { EntityId, PageName, TicketStatus } from '../../consts'
import { Tickets as TicketConsts } from '../../consts'
import Chat from '../chat/Chat'
import { uploadAttachmentProcessesOnClear } from '../chat/messageAttachmentsSlice'
import { setVisibleActivityLog, setVisibleEvent } from '../chat/messagesSlice'
import { fetchChannels } from './channelsSlice'
import ManualTicketNote from './ManualTicketNote'
import styles from './TicketDetail.module.css'
import TicketDetailSideColumn from './TicketDetailSideColumn'
import TicketShare from './TicketShare'
import { fetchTicket } from './ticketSlice'
import TicketVisibleActivityLog from './TicketVisibleActivityLog'
import TicketVisibleEvent from './TicketVisibleEvent'

const TicketDetail: React.FC = () => {
  const ticketState = useSelector((state: RootState) => state.ticket)
  const messagesState = useSelector((state: RootState) => state.messages)
  const authState = useSelector((state: RootState) => state.auth)

  const imagePreviewState = useSelector(
    (state: RootState) => state.imagePreview
  )
  const { ticketId } = useParams<{ ticketId: string }>()
  const ticket = ticketState.entities[ticketId]
  const isDeleted = ticket?.deletedAt != null
  const dispatch = useDispatch()

  const [containerHeight, setContainerHeight] = useState(0)
  const alertRef = useRef<HTMLDivElement>(null)

  const [sideTabIndex, setSideTabIndex] = useState(0)
  const [sideWidth, setSideWidth] = useState(0)
  const sideRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    dispatch(fetchTicket(parseInt(ticketId, 10)))
  }, [dispatch, ticketId])

  useEffect(() => {
    return () => {
      dispatch(uploadAttachmentProcessesOnClear())
    }
  }, [dispatch])

  const onClickVisibleActivityLog = useCallback(
    (visible: boolean) => {
      dispatch(setVisibleActivityLog({ visible }))
    },
    [dispatch]
  )

  const onClickVisibleEvent = useCallback(
    (visible: boolean) => {
      dispatch(setVisibleEvent({ visible }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (!alertRef.current) {
      // アラートコンポーネントが無い時はウィンドウサイズそのままになる
      setContainerHeight(window.innerHeight)
      return
    }

    const onResize = () => {
      if (!alertRef.current) {
        setContainerHeight(window.innerHeight)
        return
      }
      // オペレーター対応画面の高さ = ウィンドウの高さ - アラートの高さ
      setContainerHeight(
        window.innerHeight - alertRef.current.getBoundingClientRect().height
      )
    }

    // 初期化
    onResize()

    // アラートが消えたり増えたりして高さが変わる可能性があるため、高さを監視しておく
    const alertObserver = new ResizeObserver(onResize)

    alertObserver.observe(alertRef.current)
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
      alertObserver.disconnect()
    }
  }, [alertRef])

  useEffect(() => {
    if (!sideRef.current) {
      return
    }

    const onResizeEffectsSide = () => {
      if (sideRef.current) {
        setSideWidth(
          280 + (sideRef.current.offsetWidth - sideRef.current.clientWidth)
        )
      }
    }
    onResizeEffectsSide()

    const sideObserver = new ResizeObserver(onResizeEffectsSide)
    sideObserver.observe(sideRef.current)
    window.addEventListener('resize', onResizeEffectsSide)
    return () => {
      window.removeEventListener('resize', onResizeEffectsSide)
      sideObserver.disconnect()
    }
  }, [sideRef])

  const groupId = authState.context?.team?.groupId || ''
  const entityId = authState.context?.page.id

  React.useEffect(() => {
    if (groupId.trim().length === 0) {
      return
    }
    dispatch(fetchChannels(groupId))
  }, [dispatch, groupId])

  if (isDeleted) {
    return <Redirect to={{ pathname: '/errors/not_found' }} />
  }

  if (ticketState.error != null) {
    if (ticketState.error.isNotFound()) {
      return <Redirect to={{ pathname: '/errors/not_found' }} />
    }

    if (!ticketState.error.isEmpty() && !ticketState.error.isNetworkErr()) {
      return <Redirect to={{ pathname: '/errors/unknown' }} />
    }
  }

  return (
    <PageViewLog pageName={PageName.TicketDetail}>
      <Box className={styles.backgroundColorGrey}>
        <Box className={styles.error} hidden={messagesState.error === null}>
          <Alert
            ref={alertRef}
            styles={{
              textAlign: 'center',
            }}
            warning
            content={TicketConsts.ClientErrorMessage.MessageStateError}
          />
        </Box>
        <Grid
          style={{ height: `${containerHeight}px` }}
          className={styles.container}
          styles={{
            gridTemplateColumns: `1fr ${sideWidth}px !important`,
          }}
        >
          {/* メッセージー取得リクエストが５秒ごとに一回投げています、最後のレスポンスがエラーの場合はエラー文言を表示 */}
          <PicturePreview
            hidden={imagePreviewState.hidden}
            src={imagePreviewState.src || ''}
            downloadData={imagePreviewState.downloadData}
            openUrl={imagePreviewState.openUrl}
          />
          <DetailHeader
            ticketId={ticketId}
            entityId={entityId}
            isManual={ticket?.isManual}
            excludesEvent={messagesState.excludesEvent}
            includesActivityLog={messagesState.includesActivityLog}
            onClickVisibleEvent={onClickVisibleEvent}
            onClickVisibleActivityLog={onClickVisibleActivityLog}
          />
          <Box
            className={styles.side}
            styles={({ theme: { siteVariables } }) => ({
              backgroundColor: siteVariables.colorScheme.default.background,
            })}
            ref={sideRef}
          >
            <TicketDetailSideColumn
              statusDisable={
                ticket?.isManual === false &&
                ticket?.status === TicketStatus.completed
              }
              tabIndex={sideTabIndex}
              setTabIndex={setSideTabIndex}
            />
          </Box>
          <Box className={styles.main}>
            {ticket && !ticket.isManual && (
              <Chat
                ticket={ticket}
                onClickFaqCreate={() => setSideTabIndex(1)}
              />
            )}
            {ticket && ticket.isManual && <ManualTicketNote ticket={ticket} />}
          </Box>
        </Grid>
      </Box>
    </PageViewLog>
  )
}

interface DetailHeaderProps {
  excludesEvent: boolean
  includesActivityLog: boolean
  ticketId: string
  entityId?: string
  isManual?: boolean
  onClickVisibleEvent: (visible: boolean) => void
  onClickVisibleActivityLog: (visible: boolean) => void
}

const DetailHeader = (props: DetailHeaderProps) => {
  const history = useHistory()
  const {
    onClickVisibleEvent,
    onClickVisibleActivityLog,
    excludesEvent,
    includesActivityLog,
    ticketId,
    entityId,
    isManual,
  } = props

  return (
    <Box className={styles.header}>
      <Flex className={styles.headerFlex}>
        <Button
          icon={<ChevronStartIcon />}
          content="問い合わせ一覧"
          text
          onClick={() => {
            history.push('/tickets')
          }}
        />
        <Flex.Item push>
          <Flex className={styles.headerToolsFlex} gap={'gap.small'}>
            {!isManual && (
              <>
                <Box>
                  <TicketVisibleEvent
                    visible={!excludesEvent}
                    onClick={onClickVisibleEvent}
                  />
                </Box>
                <Box>
                  <TicketVisibleActivityLog
                    visible={includesActivityLog}
                    onClick={onClickVisibleActivityLog}
                  />
                </Box>
              </>
            )}
            <TicketShare
              entityId={entityId || EntityId.Tickets}
              subEntityId={ticketId}
            />
          </Flex>
        </Flex.Item>
      </Flex>
    </Box>
  )
}

export default TicketDetail
