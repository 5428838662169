import { createSelector } from '@reduxjs/toolkit'
import compareAsc from 'date-fns/compareAsc'
import formatISO9075 from 'date-fns/formatISO9075'
import format from 'date-fns/fp/format'
import parseISO from 'date-fns/parseISO'

import type { RootState } from '../../app/store'
import { requesterTicketListApi } from './api'
import { TicketDisplay } from './presenter'
import { requesterTicketEntityAdapter } from './slice'
import { RequesterTicketEntity } from './types/RequesterTicketEntity'

const stateSelector = (state: RootState) => state.requesterTicketList

export const requesterTicketListApiDataSelector = (state: RootState) => {
  const ticketListState = stateSelector(state)
  return requesterTicketListApi.endpoints.getRequesterConversations.select({
    maxUpdatedAt: ticketListState.latestUpdate,
  })(state).data
}

export const requesterReplyListApiDataSelector = (
  state: RootState,
  ticketId: string
) => {
  return requesterTicketListApi.endpoints.getRequesterConversationReplies.select(
    {
      ticketId,
    }
  )(state).data
}

export const queryArgMaxUpdatedAt = createSelector(
  stateSelector,
  (state) => state.queryArgMaxUpdatedAt
)

export const triggerScrollToBottom = createSelector(
  stateSelector,
  (state) => state.triggerScrollToBottom
)

export const ticketDisplays = createSelector(
  [stateSelector, requesterTicketListApiDataSelector],
  (state, ticketListResult): TicketDisplay[] => {
    if (!ticketListResult) return []
    const entityArray = requesterTicketEntityAdapter
      .getSelectors()
      .selectAll(ticketListResult)
      .sort(
        (a, b) => a.lastReplyUpdatedAt.seconds - b.lastReplyUpdatedAt.seconds
      )

    return entityArray.reduce<TicketDisplay[]>(
      foldTicketEntitiesToTicketDisplays,
      []
    )
  }
)

export const ticketOrderDependsOnViewportDisplays = createSelector(
  [stateSelector],
  (state): TicketDisplay[] => {
    const entityArray = state.ticketOrders
    const result = entityArray.reduce<TicketDisplay[]>(
      foldTicketEntitiesToTicketDisplays,
      []
    )
    return result
  }
)

const foldTicketEntitiesToTicketDisplays = (
  acc: TicketDisplay[],
  ticket: RequesterTicketEntity,
  currentIndex: number,
  array: RequesterTicketEntity[]
) => {
  const previousTicket = array[currentIndex - 1]
  if (
    !previousTicket ||
    isTicketCreatedDifferenceDateTime(previousTicket)(ticket)
  )
    return [
      ...acc,
      {
        type: 'divider' as const,
        label: formatDateJP(parseISO(ticket.createdAt)),
      },
      { type: 'ticket' as const, id: ticket.id },
    ]
  return [...acc, { type: 'ticket' as const, id: ticket.id }]
}

const isTicketCreatedDifferenceDateTime =
  (previous: RequesterTicketEntity) =>
  (current: RequesterTicketEntity): boolean => {
    const createDateWithoutTime = (str: string) =>
      parseISO(formatISO9075(parseISO(str), { representation: 'date' }))
    switch (
      compareAsc(
        createDateWithoutTime(previous.createdAt),
        createDateWithoutTime(current.createdAt)
      )
    ) {
      case -1:
      case 1:
        return true
      case 0:
      default:
        return false
    }
  }

const formatDateJP = format('yyyy年MM月dd日')

export const linkedConversationID = createSelector(
  stateSelector,
  (state) => state.linkedConversationID
)
export const linkedReplyMessageID = createSelector(
  stateSelector,
  (state) => state.linkedReplyMessageID
)
