import { Text } from '@fluentui/react-northstar'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { Dropdown } from '../../../components/Dropdown'
import { Tickets } from '../../../consts'
import { TicketCustomField } from '../../../proto/ticket_custom_field_pb'
import { updateTicketCustomFieldValue } from '../customFieldValueSlice'
import styles from '../TicketDetail.module.css'
import { useErrorHandler } from './useErrorHandler'

type Props = {
  ticketId: number
  field: TicketCustomField.AsObject
  fieldValue?: string
}

const TicketPropertyCustomSelectField: React.FC<Props> = ({
  ticketId,
  field,
  fieldValue,
}) => {
  const dispatch = useDispatch()
  const customFieldValuesState = useSelector(
    (state: RootState) => state.customFieldValue
  )
  const customFieldValueEntity = customFieldValuesState.entities[field.id]
  const hasValue = customFieldValueEntity != null
  const [selectedItem, setSelectedItem] = useState(fieldValue)
  useErrorHandler(customFieldValueEntity, field, dispatch, hasValue)
  return (
    <>
      <Text weight="bold" content={field.name} />
      {field.editable ? (
        <Dropdown
          fluid
          error={hasValue && customFieldValuesState.entities[field.id]?.isError}
          className={styles.ticketCustomFieldSelectDropdown}
          items={[
            {
              value: '',
              header: Tickets.Users.UserNotIndicated,
            },
            ...field.optionsList.map((o) => ({
              value: o.value,
              header: o.value,
            })),
          ]}
          value={[selectedItem ?? '']}
          onValueChange={(values) => {
            if (!field.editable) return
            // 未設定にする場合以外
            if (values[0] !== '') {
              setSelectedItem(values[0])
              dispatch(
                updateTicketCustomFieldValue(
                  ticketId,
                  field.id,
                  field.type,
                  values.map((v) => {
                    return { stringValue: v }
                  })
                )
              )
              return
            }
            // 未設定にする場合
            setSelectedItem('')
            dispatch(
              updateTicketCustomFieldValue(ticketId, field.id, field.type, [])
            )
          }}
        />
      ) : (
        <Text content={selectedItem ? selectedItem : '--'} />
      )}
    </>
  )
}

export default TicketPropertyCustomSelectField
