import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../app/store'
import {
  FileProp,
  FilePropStatus,
} from '../../components/RequesterChatFileList'
import { attachmentEntityAdapter } from '../ActivityMessageAttachment/lib/entityAdapter'
import { AttachmentItemStatus } from '../ActivityMessageAttachment/types/AttachmentItem'
import PreviewBodyAPI from '../ActivityMessagePlanPreviewBody/api'
import { ActivityMessagePlanDetailEntity } from '../ActivityMessagePlanPreviewBody/types/ActivityMessagePlanDetailEntity'

export const stateSelector = (state: RootState) =>
  state.activityMessagePlanEditFrom
export const currentAttachments = createSelector(
  [
    stateSelector,
    (state, planID: number) =>
      PreviewBodyAPI.endpoints.planDetail.select({ id: planID })(state),
  ],
  (state, planDetailQuery): FileProp[] => {
    const data = planDetailQuery.data as
      | ActivityMessagePlanDetailEntity
      | undefined
    if (!data) return []

    return [
      ...data.attachments,
      ...attachmentEntityAdapter
        .getSelectors()
        .selectAll(state.creatingAttachments),
    ]
      .map((a) => ({
        id: a.id,
        url: a.url,
        mimeType: a.mimeType,
        fileName: a.fileName,
        status: statusIntoFilePropStatus(a.status),
      }))
      .filter((a) => !state.removedAttachmentIDs.includes(a.id))
  }
)

function statusIntoFilePropStatus(
  status: AttachmentItemStatus
): FilePropStatus {
  switch (status) {
    case 'pending':
      return 'pending'
    case 'success':
      return 'done'
    case 'error':
      return 'error'
  }
}
