import { DropTargetMonitor } from 'react-dnd'

export interface DragItem {
  index: number
  type: string
}

export const hoverFunc = (
  index: number,
  dropRef: React.RefObject<HTMLTableRowElement>,
  moveRow: (i: number, j: number) => void,
  item: DragItem,
  monitor: DropTargetMonitor
): void => {
  if (!dropRef.current) {
    return
  }
  const dragIndex = item.index
  const hoverIndex = index
  if (dragIndex === hoverIndex) {
    return
  }
  const hoverBoundingRect = dropRef.current.getBoundingClientRect()
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
  const clientOffset = monitor.getClientOffset()
  if (!clientOffset) {
    return
  }
  const hoverClientY = clientOffset.y - hoverBoundingRect.top
  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    return
  }
  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    return
  }
  moveRow(dragIndex, hoverIndex)
  item.index = hoverIndex
}
