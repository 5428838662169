import * as React from 'react'
import * as ReactRedux from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ActivityMessagePlansLimit } from '../../consts'
import ActivityMessagePlanList from '../../renewfeatures/ActivityMessagePlanList'
import * as ActivityMessagePlanListAPI from '../../renewfeatures/ActivityMessagePlanList/api'
import ActivityMessagePlansPaginator from '../../renewfeatures/ActivityMessagePlansPaginator'
import * as PaginatorSelectors from '../../renewfeatures/ActivityMessagePlansPaginator/selectors'
import Presenter from './presenter'

const ActivityMessagePlans: React.FC = () => {
  const history = useHistory()

  const page = ReactRedux.useSelector(PaginatorSelectors.pageSelector)
  const planListQuery =
    ActivityMessagePlanListAPI.useListActivityMessagePlansQuery({
      page,
      limit: ActivityMessagePlansLimit,
    })

  const lastPage = React.useMemo<number>(() => {
    if (!planListQuery.data) return 0
    return Math.ceil(planListQuery.data.total / ActivityMessagePlansLimit)
  }, [planListQuery])

  const clickNewMessageButton = React.useCallback(() => {
    history.push('/activity-messages/new')
  }, [history])
  const renderPagination = React.useCallback(() => {
    return (
      <ActivityMessagePlansPaginator
        isLoading={planListQuery.isLoading}
        total={planListQuery.data?.total ?? 0}
        lastPage={lastPage}
      />
    )
  }, [planListQuery, lastPage])
  const renderList = React.useCallback(() => {
    return <ActivityMessagePlanList />
  }, [])

  return (
    <Presenter
      renderPagination={renderPagination}
      renderList={renderList}
      onClickNewMessageButton={clickNewMessageButton}
    />
  )
}

export default ActivityMessagePlans
