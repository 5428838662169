import { CloseIcon } from '@fluentui/react-icons-northstar'
import { Attachment, Box } from '@fluentui/react-northstar'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import styles from './ChatInputAttachments.module.css'
import { attachmentIcon } from './common'
import {
  messageAttachmentsSelectors,
  uploadAttachmentProcessOnRemove,
} from './messageAttachmentsSlice'
import { deleteFile } from './upload'

export const ChatInputAttachments: React.FC = () => {
  const dispatch = useDispatch()
  const messageAttachments = useSelector(messageAttachmentsSelectors.selectAll)
  const authState = useSelector((state: RootState) => state.auth)
  const layoutState = useSelector((state: RootState) => state.layout)
  const cancelFile = (id: number, itemId: string) => {
    dispatch(uploadAttachmentProcessOnRemove({ id: id }))
    // case 1 uploading : itemId is null
    if (!itemId) {
      return
    }
    // アップロード済
    if (!authState.context || !authState.context.team?.groupId) {
      return
    }
    dispatch(deleteFile(authState.context.team?.groupId, itemId))
  }

  if (messageAttachments.filter((f) => !f.pastedImage).length === 0) {
    return <></>
  }

  return (
    <Box className={styles.boxStyle}>
      {messageAttachments
        .filter((f) => !f.pastedImage)
        .map((f) => (
          <Box
            key={f.id}
            className={messageAttachmentStyle(
              layoutState?.chatLayout?.clientWidth
            )}
          >
            <Attachment
              header={
                <Box className={styles.messageAttachmentHeader}>
                  {f.attachmentData.name}
                </Box>
              }
              icon={attachmentIcon(f.attachmentData.name)}
              actionable
              action={{
                icon: <CloseIcon />,
                title: 'Close',
                onClick: () => cancelFile(f.id, f.attachmentData.itemId),
              }}
              progress={f.progress}
            />
          </Box>
        ))}
    </Box>
  )
}

const messageAttachmentStyle = (width?: number): string => {
  if (width == null) {
    return styles.messageAttachmentBoxStyleOne
  }

  if (width < 400) {
    return styles.messageAttachmentBoxStyleOne
  }

  if (width < 800) {
    return styles.messageAttachmentBoxStyleTwo
  }

  return styles.messageAttachmentBoxStyleThree
}
