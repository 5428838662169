import {
  Event,
  ListMessagesRequest,
  ListMessagesResponse,
  Message,
  ResponseAttachment,
} from '../../proto/message_pb'
import { MessageAPI } from '../../proto/message_pb_service'
import { GetTicketRequest, Ticket } from '../../proto/ticket_pb'
import { TicketAPI } from '../../proto/ticket_pb_service'
import { deskOperatorApi } from '../deskApiOperator'
import { GetTicketArgs } from './types/GetTicketArgs'
import { ListMessagesArgs } from './types/ListMessagesArgs'
import {
  RecommendedFaqTicketEntity,
  RecommendedFaqTicketManualMessageEntity,
  RecommendedFaqTicketManualMessageEventAttachmentEntity,
  RecommendedFaqTicketManualMessageEventEntity,
  RecommendedFaqTicketManualMessageEventMessageEntity,
} from './types/RecommendedFaqTicketEntity'

export const recommendedFaqTicketApi = deskOperatorApi.injectEndpoints({
  endpoints: (build) => ({
    getTicket: build.query<
      {
        entity: RecommendedFaqTicketEntity | null
      },
      GetTicketArgs
    >({
      query: (arg) => ({
        service: TicketAPI.GetTicket,
        body: (() => {
          const req = new GetTicketRequest()
          req.setId(arg.id)
          return req
        })(),
      }),
      transformResponse: (res: Ticket.AsObject) => {
        return {
          entity: protoIntoRecommendedFaqTicketEntity(res),
        }
      },
      providesTags: (_, __, arg) => [
        { type: 'RecommendedFaqTickets', id: arg.id },
      ],
    }),
    listMessages: build.query<
      {
        entities: RecommendedFaqTicketManualMessageEntity[]
      },
      ListMessagesArgs
    >({
      query: (arg) => ({
        service: MessageAPI.ListMessages,
        body: (() => {
          const req = new ListMessagesRequest()
          req.setTicketId(arg.ticketId)
          req.setIncludesActivityLog(false)
          req.setExcludesEvent(false)
          return req
        })(),
      }),
      transformResponse: (res: ListMessagesResponse.AsObject) => {
        const messages = sortMessages(res.messagesList)
        return {
          entities: protoIntoRecommendedFaqTicketManualMessagesEntity(messages),
        }
      },
      providesTags: (_, __, arg) => [
        { type: 'RecommendedFaqTicketManualMessages', id: arg.ticketId },
      ],
    }),
  }),
})

export const { useGetTicketQuery, useListMessagesQuery } =
  recommendedFaqTicketApi

const sortMessages = (messages: Message.AsObject[]): Message.AsObject[] => {
  return messages.sort((a, b) => {
    if (!a.activity && a.eventsList.length === 0) {
      return -1
    }
    if (!b.activity && b.eventsList.length === 0) {
      return 1
    }
    const aTimestamp = a.activity?.timestamp || a.eventsList[0].timestamp || 0
    const bTimestamp = b.activity?.timestamp || b.eventsList[0].timestamp || 0
    return aTimestamp < bTimestamp ? -1 : 1
  })
}

const protoIntoRecommendedFaqTicketEntity = (
  proto: Ticket.AsObject
): RecommendedFaqTicketEntity | null => {
  const entity = {
    id: proto.id,
    requesterUserId: proto.requesterUserId,
    requesterType: proto.requesterType,
    requestResponses: [],
  }
  if (proto.noteRequestResponses.length > 0) {
    entity.requestResponses = JSON.parse(proto.noteRequestResponses)
  }
  return entity
}

const protoIntoRecommendedFaqTicketManualMessageEventMessageEntity = (
  proto: Event.Message.AsObject
): RecommendedFaqTicketManualMessageEventMessageEntity => {
  return {
    text: proto.text,
  }
}

const protoIntoRecommendedFaqTicketManualMessageEventAttachmentEntity = (
  proto: ResponseAttachment.AsObject
): RecommendedFaqTicketManualMessageEventAttachmentEntity => {
  return {
    name: proto.name,
    type: proto.type,
    data: proto.data,
  }
}

const protoIntoRecommendedFaqTicketManualMessageEventAttachmentsEntity = (
  proto: ResponseAttachment.AsObject[]
): RecommendedFaqTicketManualMessageEventAttachmentEntity[] => {
  return proto.map((a) =>
    protoIntoRecommendedFaqTicketManualMessageEventAttachmentEntity(a)
  )
}

const protoIntoRecommendedFaqTicketManualMessageEventEntity = (
  proto: Event.AsObject
): RecommendedFaqTicketManualMessageEventEntity => {
  return {
    message:
      proto.message &&
      protoIntoRecommendedFaqTicketManualMessageEventMessageEntity(
        proto.message
      ),
    attachmentsList:
      protoIntoRecommendedFaqTicketManualMessageEventAttachmentsEntity(
        proto.attachmentsList
      ),
    timestamp: proto.timestamp,
  }
}

const protoIntoRecommendedFaqTicketManualMessageEventsEntity = (
  proto: Event.AsObject[]
): RecommendedFaqTicketManualMessageEventEntity[] => {
  return proto.map((e) =>
    protoIntoRecommendedFaqTicketManualMessageEventEntity(e)
  )
}

const protoIntoRecommendedFaqTicketManualMessageEntity = (
  proto: Message.AsObject
): RecommendedFaqTicketManualMessageEntity => {
  return {
    userId: proto.userId,
    senderType: proto.senderType,
    eventsList: protoIntoRecommendedFaqTicketManualMessageEventsEntity(
      proto.eventsList
    ),
  }
}

const protoIntoRecommendedFaqTicketManualMessagesEntity = (
  proto: Message.AsObject[]
): RecommendedFaqTicketManualMessageEntity[] => {
  return proto.map((m) => protoIntoRecommendedFaqTicketManualMessageEntity(m))
}
