import { Divider, Image } from '@fluentui/react-northstar'
import * as React from 'react'

import {
  RecommendedFaqImpact,
  RecommendedFaqImpactLabels,
} from '../../../../consts'
import { RecommendedFaqEntity } from '../../types/RecommendedFaqEntity'
import styles from './styles.module.css'

type AccordionProps = {
  recommendedFaqItem: RecommendedFaqEntity
  content: React.ReactElement
  open: boolean
  onClick: (faq: RecommendedFaqEntity) => void
}

const Accordion: React.FC<AccordionProps> = (props) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.accordionHeader}
        onClick={() => props.onClick(props.recommendedFaqItem)}
      >
        <div className={styles.leftAccordionHeader}>
          <div
            className={`${styles.label} ${
              styles[props.recommendedFaqItem.impact]
            }`}
          >
            影響度{' '}
            {
              RecommendedFaqImpactLabels[
                props.recommendedFaqItem.impact as RecommendedFaqImpact
              ]
            }
          </div>
          <div className={styles.title}>{props.recommendedFaqItem.title}</div>
        </div>
        <Image
          className={
            props.open
              ? styles.accordionToggleOpen
              : styles.accordionToggleClose
          }
          onClick={() => props.onClick(props.recommendedFaqItem)}
          src="/assets/accordionToggle.svg"
        />
      </div>
      {props.open && (
        <>
          <Divider />
          {props.content}
        </>
      )}
    </div>
  )
}

type Props = {
  recommendedFaqItem: RecommendedFaqEntity
  renderRecommendedFaqItemContent: () => React.ReactElement
  open: boolean
  onClick: (faq: RecommendedFaqEntity) => void
}

// 表示に関するロジック以外置かない
const RecommendedFaqItemPresenter: React.FC<Props> = (props) => {
  return (
    <Accordion
      recommendedFaqItem={props.recommendedFaqItem}
      content={props.renderRecommendedFaqItemContent()}
      open={props.open}
      onClick={props.onClick}
    />
  )
}

export default RecommendedFaqItemPresenter
