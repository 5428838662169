import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../../../app/store'
import {
  requesterReplyListApiDataSelector,
  requesterTicketListApiDataSelector,
} from '../../selectors'
import { requesterTicketEntityAdapter } from '../../slice'
import {
  RequesterMessageImage,
  RequesterMessageVideo,
  hasTextMessage,
} from '../../types/RequesterMessage'
import type { MessageKey } from './slice'

const stateSelector = (state: RootState) =>
  state['requesterTicketList/requesterChatText']

export const messageSelector = createSelector(
  [stateSelector, (_, key: MessageKey) => key],
  (state, key) => {
    const message = state[key]
    if (!message) return ''
    return message
  }
)

export const ticketEntityMessageSelector = createSelector(
  [requesterTicketListApiDataSelector, (_, id: string) => id],
  (ticketEntity, id): string => {
    if (!ticketEntity) return ''
    const ticket = ticketEntity.entities[id]
    if (!ticket) return ''
    if (!hasTextMessage(ticket.message)) {
      return replaceContentMessage(ticket.message)
    }

    const text = replaceUrlTextToHyperlink(ticket.message.text)
    if (ticket.senderType == 'auto') {
      return replaceWithBr(text)
    }
    return text
  }
)

export const replyEntityMessageSelector = createSelector(
  [requesterReplyListApiDataSelector, (_, __, replyId: string) => replyId],
  (replyEntity, replyId): string => {
    if (!replyEntity) return ''
    const reply = replyEntity.entities[replyId]
    if (!reply) return ''
    if (!hasTextMessage(reply.message)) {
      return replaceContentMessage(reply.message)
    }
    const text = replaceUrlTextToHyperlink(reply.message.text)
    if (reply.senderType == 'auto') {
      return replaceWithBr(text)
    }
    return text
  }
)

export const lastReplyMessageSelector = createSelector(
  [requesterTicketListApiDataSelector, (_, ticketId: string) => ticketId],
  (ticketEntity, ticketId) => {
    if (!ticketEntity) return ''
    const ticket = requesterTicketEntityAdapter
      .getSelectors()
      .selectById(ticketEntity, ticketId)
    if (!ticket || !ticket.lastReplyMessage) return ''
    if (!hasTextMessage(ticket.lastReplyMessage.message)) {
      return replaceContentMessage(ticket.lastReplyMessage.message)
    }
    const text = replaceUrlTextToHyperlink(ticket.lastReplyMessage.message.text)
    if (ticket.lastReplyMessage.senderType == 'auto') {
      return replaceWithBr(text)
    }
    return text
  }
)
export const lastReplyId = createSelector(
  [requesterTicketListApiDataSelector, (_, ticketId: string) => ticketId],
  (ticketEntity, ticketId) => {
    if (!ticketEntity) return ''
    const ticket = requesterTicketEntityAdapter
      .getSelectors()
      .selectById(ticketEntity, ticketId)
    if (!ticket || !ticket.lastReplyMessage) return ''
    return ticket.lastReplyMessage.id
  }
)

const replaceContentMessage = (
  message: RequesterMessageVideo | RequesterMessageImage
) => {
  if (message.type === 'image') {
    if (message.to?.url != null) {
      return `<img src="${message.src}" to="${message.to?.url}" />`
    }
    return `<img src="${message.src}" />`
  }
  if (message.type === 'video') {
    const movieId = videoIdFromShortUrl(message.src)
    if (movieId) {
      // Chatbotダッシュボードのシミュレーターのサイズに合わせる
      return `<iframe width="300" height="200" src="https://www.youtube.com/embed/${movieId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
    }
    return ''
  }

  return ''
}

export const replaceWithBr = (input: string): string =>
  input.split('\n').join('<br>')

export const videoIdFromShortUrl = (shortUrl: string): string | null => {
  // short url pattern: https://youtu.be/xxxxxxxxxx Chatbotと同様にロングURLには対応しない
  const pattern = /https:\/\/youtu.be\/([A-Za-z0-9_-]+)/
  const match = shortUrl.match(pattern)
  return match && match[1]
}
export const replaceUrlTextToHyperlink = (input: string): string => {
  const regex = /%%%urltext:(.*?),(.*?)%%%/g
  let match
  let result = input

  while ((match = regex.exec(input)) !== null) {
    const displayText = match[1]
    const url = match[2]
    const hyperlink = `<a href="${url}" target="_blank">${displayText}</a>`
    result = result.replace(match[0], hyperlink)
  }
  return result
}
