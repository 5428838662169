// package: 
// file: proto/faq_label.proto

var proto_faq_label_pb = require("../proto/faq_label_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var FaqLabelAPI = (function () {
  function FaqLabelAPI() {}
  FaqLabelAPI.serviceName = "FaqLabelAPI";
  return FaqLabelAPI;
}());

FaqLabelAPI.GetFaqLabels = {
  methodName: "GetFaqLabels",
  service: FaqLabelAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_faq_label_pb.GetFaqLabelsRequest,
  responseType: proto_faq_label_pb.GetFaqLabelsResponse
};

exports.FaqLabelAPI = FaqLabelAPI;

function FaqLabelAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

FaqLabelAPIClient.prototype.getFaqLabels = function getFaqLabels(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FaqLabelAPI.GetFaqLabels, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.FaqLabelAPIClient = FaqLabelAPIClient;

