import {
  ListRecommendedFaqRedundantIntentsRequest,
  ListRecommendedFaqRedundantIntentsResponse,
  RecommendedFaqRedundantIntent,
} from '../../proto/recommended_faq_pb'
import { RecommendedFaqAPI } from '../../proto/recommended_faq_pb_service'
import { deskOperatorApi } from '../deskApiOperator'
import { ListRecommendedFaqRedundantIntentsArgs } from './types/ListRecommendedFaqRedundantIntentsArgs'
import { RecommendedFaqRedundantIntentEntity } from './types/RecommendedFaqRedundantIntentEntity'

export const recommendedFaqRedundantIntentApi = deskOperatorApi.injectEndpoints(
  {
    endpoints: (build) => ({
      listRecommendedFaqRedundantIntents: build.query<
        {
          entities: RecommendedFaqRedundantIntentEntity[]
        },
        ListRecommendedFaqRedundantIntentsArgs
      >({
        query: (arg) => ({
          service: RecommendedFaqAPI.ListRecommendedFaqRedundantIntents,
          body: (() => {
            const req = new ListRecommendedFaqRedundantIntentsRequest()
            req.setRecommendedFaqId(arg.recommendedFaqId)
            return req
          })(),
        }),
        transformResponse: (
          res: ListRecommendedFaqRedundantIntentsResponse.AsObject
        ) => {
          return {
            entities: protoIntoRecommendedFaqRedundantIntentsEntity(
              res.recommendedFaqRedundantIntentsList
            ),
          }
        },
        providesTags: (_, __, arg) => [
          {
            type: 'RecommendedFaqRedundantIntents',
            recommendedFaqId: arg.recommendedFaqId,
          },
        ],
      }),
    }),
  }
)

export const { useListRecommendedFaqRedundantIntentsQuery } =
  recommendedFaqRedundantIntentApi

const protoIntoRecommendedFaqRedundantIntentEntity = (
  proto: RecommendedFaqRedundantIntent.AsObject
): RecommendedFaqRedundantIntentEntity => {
  return {
    id: proto.id,
    intentName: proto.intentName,
    intentQuestion: proto.intentQuestion,
  }
}

const protoIntoRecommendedFaqRedundantIntentsEntity = (
  proto: RecommendedFaqRedundantIntent.AsObject[]
): RecommendedFaqRedundantIntentEntity[] => {
  return proto.map((intent) =>
    protoIntoRecommendedFaqRedundantIntentEntity(intent)
  )
}
