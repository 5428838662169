import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { DRAG_DROP_PASTE } from '@lexical/rich-text'
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils'
import {
  $createParagraphNode,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
} from 'lexical'
import * as React from 'react'

import { $createImageNode, ImageNode } from './node'

export type UploadImageResponse = {
  driveId: string
  itemId: string
  src: string
}
type Props = {
  uploadImage: (file: File) => Promise<UploadImageResponse>
}
const ImagePlugin: React.FC<Props> = (props) => {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error('ImagePlugin: ImageNode not registered on editor')
    }

    return mergeRegister(
      editor.registerCommand(
        DRAG_DROP_PASTE,
        (files: File[]) => {
          files.forEach(async (file) => {
            if (!file.type.startsWith('image/')) return

            let result: UploadImageResponse
            try {
              result = await props.uploadImage(file)
            } catch (e) {
              console.error(e)
              return
            }

            editor.update(() => {
              const imageNode = $createImageNode({
                driveId: result.driveId,
                itemId: result.itemId,
                src: result.src,
              })
              $insertNodes([imageNode])
              if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
                $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd()
              }
            })
          })
          return true
        },
        COMMAND_PRIORITY_EDITOR
      )
    )
  }, [editor, props])

  return <></>
}

export default ImagePlugin
