// package: 
// file: proto/ticket_custom_field.proto

var proto_ticket_custom_field_pb = require("../proto/ticket_custom_field_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TicketCustomFieldAPI = (function () {
  function TicketCustomFieldAPI() {}
  TicketCustomFieldAPI.serviceName = "TicketCustomFieldAPI";
  return TicketCustomFieldAPI;
}());

TicketCustomFieldAPI.ListTicketCustomFields = {
  methodName: "ListTicketCustomFields",
  service: TicketCustomFieldAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_ticket_custom_field_pb.ListTicketCustomFieldsRequest,
  responseType: proto_ticket_custom_field_pb.ListTicketCustomFieldsResponse
};

TicketCustomFieldAPI.CreateTicketCustomField = {
  methodName: "CreateTicketCustomField",
  service: TicketCustomFieldAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_ticket_custom_field_pb.TicketCustomField,
  responseType: proto_ticket_custom_field_pb.TicketCustomField
};

TicketCustomFieldAPI.UpdateTicketCustomField = {
  methodName: "UpdateTicketCustomField",
  service: TicketCustomFieldAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_ticket_custom_field_pb.TicketCustomField,
  responseType: proto_ticket_custom_field_pb.TicketCustomField
};

TicketCustomFieldAPI.DeleteTicketCustomField = {
  methodName: "DeleteTicketCustomField",
  service: TicketCustomFieldAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_ticket_custom_field_pb.DeleteTicketCustomFieldRequest,
  responseType: google_protobuf_empty_pb.Empty
};

TicketCustomFieldAPI.UpdateFieldSequences = {
  methodName: "UpdateFieldSequences",
  service: TicketCustomFieldAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_ticket_custom_field_pb.UpdateFieldSequencesRequest,
  responseType: google_protobuf_empty_pb.Empty
};

exports.TicketCustomFieldAPI = TicketCustomFieldAPI;

function TicketCustomFieldAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TicketCustomFieldAPIClient.prototype.listTicketCustomFields = function listTicketCustomFields(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TicketCustomFieldAPI.ListTicketCustomFields, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TicketCustomFieldAPIClient.prototype.createTicketCustomField = function createTicketCustomField(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TicketCustomFieldAPI.CreateTicketCustomField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TicketCustomFieldAPIClient.prototype.updateTicketCustomField = function updateTicketCustomField(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TicketCustomFieldAPI.UpdateTicketCustomField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TicketCustomFieldAPIClient.prototype.deleteTicketCustomField = function deleteTicketCustomField(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TicketCustomFieldAPI.DeleteTicketCustomField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TicketCustomFieldAPIClient.prototype.updateFieldSequences = function updateFieldSequences(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TicketCustomFieldAPI.UpdateFieldSequences, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TicketCustomFieldAPIClient = TicketCustomFieldAPIClient;

