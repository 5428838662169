import {
  AcceptIcon,
  Flex,
  Input,
  List,
  ListItemProps,
  Loader,
  SearchIcon,
} from '@fluentui/react-northstar'
import React, { useMemo, useState } from 'react'

import useDebounce from '../hooks/useDebounce'
import styles from './SearchableDropdown.module.css'

type Props = {
  onInputChange: (value: string) => void
  handleUpdateOnChange?: (input: string) => void
  listItems?: ListItemProps[]
  placeholder?: string
  value: string
  loading?: boolean
  listTop?: string
  width?: string
}

const SearchableDropdown: React.FC<Props> = ({
  onInputChange,
  handleUpdateOnChange: updateSuggestList,
  listItems,
  placeholder,
  value,
  loading,
  listTop,
  width,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const doDebounce = useDebounce(600)

  const items = ((): ListItemProps[] => {
    if (listItems != null) return listItems
    return []
  })()

  return (
    <Flex column>
      <Input
        fluid
        icon={loading ? <Loader size="small" /> : <SearchIcon />}
        placeholder={placeholder}
        onChange={(_, data) => {
          if (data?.value != null && onInputChange != null) {
            onInputChange(data.value)
          }
          if (updateSuggestList != null && data != null) {
            doDebounce(() => {
              updateSuggestList(data.value)
              setIsOpen(data.value !== '')
            })
          }
        }}
        styles={{ width }}
        value={value}
        onBlur={() => {
          setTimeout(() => {
            setIsOpen(false)
          }, 400)
        }}
        onClick={() => setIsOpen(value !== '')}
      />
      <div className={styles.pulldown} style={{ top: listTop }}>
        {isOpen && (
          <List
            className={styles.list}
            selectable
            styles={{ width }}
            items={items}
          />
        )}
      </div>
    </Flex>
  )
}

interface SearchableDropdownListGenericProps {
  id: string
  header: string
  key: string
  content: string
}

export interface SearchableDropdownListProps {
  loading: boolean
  list: SearchableDropdownListGenericProps[]
  handleUpdateOnInputChange: (value: string) => void
  isSelected: (id: string) => boolean
  inputText: string
  setInputText: React.Dispatch<React.SetStateAction<string>>
  handleOptionSelected: (data: ListItemProps) => void
  listTopMargin?: string | undefined
}

export function SearchableDropdownList(props: SearchableDropdownListProps) {
  const {
    loading,
    inputText,
    handleUpdateOnInputChange: handleUpdateOnChange,
    setInputText,
    list,
    handleOptionSelected,
    isSelected,
    listTopMargin,
  } = props

  const items = useMemo(() => {
    const result = list.map((r) => ({
      header: r.header,
      key: r.key,
      content: r.content,
      className: styles.requesterListItem,
      endMedia: isSelected(r.id) ? <AcceptIcon /> : <></>,
      onClick: (
        _: React.SyntheticEvent<HTMLElement, Event>,
        data?: ListItemProps
      ) => {
        if (data?.index == null) {
          return
        }
        handleOptionSelected(data)
      },
    }))

    if (result.length === 0 && inputText !== '' && !loading) {
      result.push({
        header: '該当するユーザーが見つかりませんでした',
        key: 'no_user',
        content: '',
        className: styles.requesterListItem,
        endMedia: <></>,
        onClick: () => {
          return
        },
      })
    }
    return result
  }, [handleOptionSelected, inputText, isSelected, loading, list])

  return (
    <SearchableDropdown
      loading={loading}
      listTop={listTopMargin}
      width="300px"
      value={inputText}
      placeholder={'名前・メールアドレスで検索'}
      handleUpdateOnChange={handleUpdateOnChange}
      onInputChange={(value) => {
        if (value != null) {
          setInputText(value)
        }
      }}
      listItems={items}
    />
  )
}

export default SearchableDropdown
