// source: proto/ticket_custom_field_value.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.DeleteTicketCustomFieldValueRequest', null, global);
goog.exportSymbol('proto.ListTicketCustomFieldValuesRequest', null, global);
goog.exportSymbol('proto.ListTicketCustomFieldValuesResponse', null, global);
goog.exportSymbol('proto.TicketCustomCheckboxFieldFilter', null, global);
goog.exportSymbol('proto.TicketCustomDateFieldFilter', null, global);
goog.exportSymbol('proto.TicketCustomFieldOneOfFilter', null, global);
goog.exportSymbol('proto.TicketCustomFieldOneOfFilter.ValuesCase', null, global);
goog.exportSymbol('proto.TicketCustomFieldOneOfValue', null, global);
goog.exportSymbol('proto.TicketCustomFieldOneOfValue.ValuesCase', null, global);
goog.exportSymbol('proto.TicketCustomFieldValue', null, global);
goog.exportSymbol('proto.TicketCustomNumberFieldFilter', null, global);
goog.exportSymbol('proto.TicketCustomSelectFieldFilter', null, global);
goog.exportSymbol('proto.TicketCustomTextAreaFieldFilter', null, global);
goog.exportSymbol('proto.TicketCustomTextFieldFilter', null, global);
goog.exportSymbol('proto.UpdateTicketCustomFieldValueRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketCustomFieldValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TicketCustomFieldValue.repeatedFields_, null);
};
goog.inherits(proto.TicketCustomFieldValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketCustomFieldValue.displayName = 'proto.TicketCustomFieldValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketCustomFieldOneOfValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.TicketCustomFieldOneOfValue.oneofGroups_);
};
goog.inherits(proto.TicketCustomFieldOneOfValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketCustomFieldOneOfValue.displayName = 'proto.TicketCustomFieldOneOfValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketCustomFieldOneOfFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.TicketCustomFieldOneOfFilter.oneofGroups_);
};
goog.inherits(proto.TicketCustomFieldOneOfFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketCustomFieldOneOfFilter.displayName = 'proto.TicketCustomFieldOneOfFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketCustomSelectFieldFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TicketCustomSelectFieldFilter.repeatedFields_, null);
};
goog.inherits(proto.TicketCustomSelectFieldFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketCustomSelectFieldFilter.displayName = 'proto.TicketCustomSelectFieldFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketCustomCheckboxFieldFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TicketCustomCheckboxFieldFilter.repeatedFields_, null);
};
goog.inherits(proto.TicketCustomCheckboxFieldFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketCustomCheckboxFieldFilter.displayName = 'proto.TicketCustomCheckboxFieldFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketCustomNumberFieldFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TicketCustomNumberFieldFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketCustomNumberFieldFilter.displayName = 'proto.TicketCustomNumberFieldFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketCustomDateFieldFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TicketCustomDateFieldFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketCustomDateFieldFilter.displayName = 'proto.TicketCustomDateFieldFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketCustomTextFieldFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TicketCustomTextFieldFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketCustomTextFieldFilter.displayName = 'proto.TicketCustomTextFieldFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TicketCustomTextAreaFieldFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TicketCustomTextAreaFieldFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TicketCustomTextAreaFieldFilter.displayName = 'proto.TicketCustomTextAreaFieldFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListTicketCustomFieldValuesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListTicketCustomFieldValuesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListTicketCustomFieldValuesRequest.displayName = 'proto.ListTicketCustomFieldValuesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListTicketCustomFieldValuesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListTicketCustomFieldValuesResponse.repeatedFields_, null);
};
goog.inherits(proto.ListTicketCustomFieldValuesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListTicketCustomFieldValuesResponse.displayName = 'proto.ListTicketCustomFieldValuesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateTicketCustomFieldValueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateTicketCustomFieldValueRequest.repeatedFields_, null);
};
goog.inherits(proto.UpdateTicketCustomFieldValueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateTicketCustomFieldValueRequest.displayName = 'proto.UpdateTicketCustomFieldValueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteTicketCustomFieldValueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteTicketCustomFieldValueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteTicketCustomFieldValueRequest.displayName = 'proto.DeleteTicketCustomFieldValueRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TicketCustomFieldValue.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketCustomFieldValue.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketCustomFieldValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketCustomFieldValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomFieldValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketCustomFieldId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    valueList: jspb.Message.toObjectList(msg.getValueList(),
    proto.TicketCustomFieldOneOfValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketCustomFieldValue}
 */
proto.TicketCustomFieldValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketCustomFieldValue;
  return proto.TicketCustomFieldValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketCustomFieldValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketCustomFieldValue}
 */
proto.TicketCustomFieldValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketCustomFieldId(value);
      break;
    case 2:
      var value = new proto.TicketCustomFieldOneOfValue;
      reader.readMessage(value,proto.TicketCustomFieldOneOfValue.deserializeBinaryFromReader);
      msg.addValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketCustomFieldValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketCustomFieldValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketCustomFieldValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomFieldValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketCustomFieldId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getValueList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.TicketCustomFieldOneOfValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 ticket_custom_field_id = 1;
 * @return {number}
 */
proto.TicketCustomFieldValue.prototype.getTicketCustomFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TicketCustomFieldValue} returns this
 */
proto.TicketCustomFieldValue.prototype.setTicketCustomFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated TicketCustomFieldOneOfValue value = 2;
 * @return {!Array<!proto.TicketCustomFieldOneOfValue>}
 */
proto.TicketCustomFieldValue.prototype.getValueList = function() {
  return /** @type{!Array<!proto.TicketCustomFieldOneOfValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TicketCustomFieldOneOfValue, 2));
};


/**
 * @param {!Array<!proto.TicketCustomFieldOneOfValue>} value
 * @return {!proto.TicketCustomFieldValue} returns this
*/
proto.TicketCustomFieldValue.prototype.setValueList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.TicketCustomFieldOneOfValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TicketCustomFieldOneOfValue}
 */
proto.TicketCustomFieldValue.prototype.addValue = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.TicketCustomFieldOneOfValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TicketCustomFieldValue} returns this
 */
proto.TicketCustomFieldValue.prototype.clearValueList = function() {
  return this.setValueList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.TicketCustomFieldOneOfValue.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.TicketCustomFieldOneOfValue.ValuesCase = {
  VALUES_NOT_SET: 0,
  STRING_VALUE: 1,
  TEXT_VALUE: 2,
  NUMBER_VALUE: 3,
  DATE_VALUE: 4
};

/**
 * @return {proto.TicketCustomFieldOneOfValue.ValuesCase}
 */
proto.TicketCustomFieldOneOfValue.prototype.getValuesCase = function() {
  return /** @type {proto.TicketCustomFieldOneOfValue.ValuesCase} */(jspb.Message.computeOneofCase(this, proto.TicketCustomFieldOneOfValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketCustomFieldOneOfValue.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketCustomFieldOneOfValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketCustomFieldOneOfValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomFieldOneOfValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    stringValue: jspb.Message.getFieldWithDefault(msg, 1, ""),
    textValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    numberValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    dateValue: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketCustomFieldOneOfValue}
 */
proto.TicketCustomFieldOneOfValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketCustomFieldOneOfValue;
  return proto.TicketCustomFieldOneOfValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketCustomFieldOneOfValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketCustomFieldOneOfValue}
 */
proto.TicketCustomFieldOneOfValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNumberValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketCustomFieldOneOfValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketCustomFieldOneOfValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketCustomFieldOneOfValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomFieldOneOfValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string string_value = 1;
 * @return {string}
 */
proto.TicketCustomFieldOneOfValue.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketCustomFieldOneOfValue} returns this
 */
proto.TicketCustomFieldOneOfValue.prototype.setStringValue = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.TicketCustomFieldOneOfValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TicketCustomFieldOneOfValue} returns this
 */
proto.TicketCustomFieldOneOfValue.prototype.clearStringValue = function() {
  return jspb.Message.setOneofField(this, 1, proto.TicketCustomFieldOneOfValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomFieldOneOfValue.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string text_value = 2;
 * @return {string}
 */
proto.TicketCustomFieldOneOfValue.prototype.getTextValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketCustomFieldOneOfValue} returns this
 */
proto.TicketCustomFieldOneOfValue.prototype.setTextValue = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.TicketCustomFieldOneOfValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TicketCustomFieldOneOfValue} returns this
 */
proto.TicketCustomFieldOneOfValue.prototype.clearTextValue = function() {
  return jspb.Message.setOneofField(this, 2, proto.TicketCustomFieldOneOfValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomFieldOneOfValue.prototype.hasTextValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double number_value = 3;
 * @return {number}
 */
proto.TicketCustomFieldOneOfValue.prototype.getNumberValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TicketCustomFieldOneOfValue} returns this
 */
proto.TicketCustomFieldOneOfValue.prototype.setNumberValue = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.TicketCustomFieldOneOfValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TicketCustomFieldOneOfValue} returns this
 */
proto.TicketCustomFieldOneOfValue.prototype.clearNumberValue = function() {
  return jspb.Message.setOneofField(this, 3, proto.TicketCustomFieldOneOfValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomFieldOneOfValue.prototype.hasNumberValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string date_value = 4;
 * @return {string}
 */
proto.TicketCustomFieldOneOfValue.prototype.getDateValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketCustomFieldOneOfValue} returns this
 */
proto.TicketCustomFieldOneOfValue.prototype.setDateValue = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.TicketCustomFieldOneOfValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TicketCustomFieldOneOfValue} returns this
 */
proto.TicketCustomFieldOneOfValue.prototype.clearDateValue = function() {
  return jspb.Message.setOneofField(this, 4, proto.TicketCustomFieldOneOfValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomFieldOneOfValue.prototype.hasDateValue = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.TicketCustomFieldOneOfFilter.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.TicketCustomFieldOneOfFilter.ValuesCase = {
  VALUES_NOT_SET: 0,
  SELECT_FILTER: 1,
  CHECKBOX_FILTER: 2,
  NUMBER_FILTER: 3,
  DATE_FILTER: 4,
  TEXT_FILTER: 5,
  TEXT_AREA_FILTER: 6
};

/**
 * @return {proto.TicketCustomFieldOneOfFilter.ValuesCase}
 */
proto.TicketCustomFieldOneOfFilter.prototype.getValuesCase = function() {
  return /** @type {proto.TicketCustomFieldOneOfFilter.ValuesCase} */(jspb.Message.computeOneofCase(this, proto.TicketCustomFieldOneOfFilter.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketCustomFieldOneOfFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketCustomFieldOneOfFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketCustomFieldOneOfFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomFieldOneOfFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectFilter: (f = msg.getSelectFilter()) && proto.TicketCustomSelectFieldFilter.toObject(includeInstance, f),
    checkboxFilter: (f = msg.getCheckboxFilter()) && proto.TicketCustomCheckboxFieldFilter.toObject(includeInstance, f),
    numberFilter: (f = msg.getNumberFilter()) && proto.TicketCustomNumberFieldFilter.toObject(includeInstance, f),
    dateFilter: (f = msg.getDateFilter()) && proto.TicketCustomDateFieldFilter.toObject(includeInstance, f),
    textFilter: (f = msg.getTextFilter()) && proto.TicketCustomTextFieldFilter.toObject(includeInstance, f),
    textAreaFilter: (f = msg.getTextAreaFilter()) && proto.TicketCustomTextAreaFieldFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketCustomFieldOneOfFilter}
 */
proto.TicketCustomFieldOneOfFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketCustomFieldOneOfFilter;
  return proto.TicketCustomFieldOneOfFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketCustomFieldOneOfFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketCustomFieldOneOfFilter}
 */
proto.TicketCustomFieldOneOfFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TicketCustomSelectFieldFilter;
      reader.readMessage(value,proto.TicketCustomSelectFieldFilter.deserializeBinaryFromReader);
      msg.setSelectFilter(value);
      break;
    case 2:
      var value = new proto.TicketCustomCheckboxFieldFilter;
      reader.readMessage(value,proto.TicketCustomCheckboxFieldFilter.deserializeBinaryFromReader);
      msg.setCheckboxFilter(value);
      break;
    case 3:
      var value = new proto.TicketCustomNumberFieldFilter;
      reader.readMessage(value,proto.TicketCustomNumberFieldFilter.deserializeBinaryFromReader);
      msg.setNumberFilter(value);
      break;
    case 4:
      var value = new proto.TicketCustomDateFieldFilter;
      reader.readMessage(value,proto.TicketCustomDateFieldFilter.deserializeBinaryFromReader);
      msg.setDateFilter(value);
      break;
    case 5:
      var value = new proto.TicketCustomTextFieldFilter;
      reader.readMessage(value,proto.TicketCustomTextFieldFilter.deserializeBinaryFromReader);
      msg.setTextFilter(value);
      break;
    case 6:
      var value = new proto.TicketCustomTextAreaFieldFilter;
      reader.readMessage(value,proto.TicketCustomTextAreaFieldFilter.deserializeBinaryFromReader);
      msg.setTextAreaFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketCustomFieldOneOfFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketCustomFieldOneOfFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketCustomFieldOneOfFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomFieldOneOfFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.TicketCustomSelectFieldFilter.serializeBinaryToWriter
    );
  }
  f = message.getCheckboxFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.TicketCustomCheckboxFieldFilter.serializeBinaryToWriter
    );
  }
  f = message.getNumberFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.TicketCustomNumberFieldFilter.serializeBinaryToWriter
    );
  }
  f = message.getDateFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.TicketCustomDateFieldFilter.serializeBinaryToWriter
    );
  }
  f = message.getTextFilter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.TicketCustomTextFieldFilter.serializeBinaryToWriter
    );
  }
  f = message.getTextAreaFilter();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.TicketCustomTextAreaFieldFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional TicketCustomSelectFieldFilter select_filter = 1;
 * @return {?proto.TicketCustomSelectFieldFilter}
 */
proto.TicketCustomFieldOneOfFilter.prototype.getSelectFilter = function() {
  return /** @type{?proto.TicketCustomSelectFieldFilter} */ (
    jspb.Message.getWrapperField(this, proto.TicketCustomSelectFieldFilter, 1));
};


/**
 * @param {?proto.TicketCustomSelectFieldFilter|undefined} value
 * @return {!proto.TicketCustomFieldOneOfFilter} returns this
*/
proto.TicketCustomFieldOneOfFilter.prototype.setSelectFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.TicketCustomFieldOneOfFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketCustomFieldOneOfFilter} returns this
 */
proto.TicketCustomFieldOneOfFilter.prototype.clearSelectFilter = function() {
  return this.setSelectFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomFieldOneOfFilter.prototype.hasSelectFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TicketCustomCheckboxFieldFilter checkbox_filter = 2;
 * @return {?proto.TicketCustomCheckboxFieldFilter}
 */
proto.TicketCustomFieldOneOfFilter.prototype.getCheckboxFilter = function() {
  return /** @type{?proto.TicketCustomCheckboxFieldFilter} */ (
    jspb.Message.getWrapperField(this, proto.TicketCustomCheckboxFieldFilter, 2));
};


/**
 * @param {?proto.TicketCustomCheckboxFieldFilter|undefined} value
 * @return {!proto.TicketCustomFieldOneOfFilter} returns this
*/
proto.TicketCustomFieldOneOfFilter.prototype.setCheckboxFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.TicketCustomFieldOneOfFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketCustomFieldOneOfFilter} returns this
 */
proto.TicketCustomFieldOneOfFilter.prototype.clearCheckboxFilter = function() {
  return this.setCheckboxFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomFieldOneOfFilter.prototype.hasCheckboxFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TicketCustomNumberFieldFilter number_filter = 3;
 * @return {?proto.TicketCustomNumberFieldFilter}
 */
proto.TicketCustomFieldOneOfFilter.prototype.getNumberFilter = function() {
  return /** @type{?proto.TicketCustomNumberFieldFilter} */ (
    jspb.Message.getWrapperField(this, proto.TicketCustomNumberFieldFilter, 3));
};


/**
 * @param {?proto.TicketCustomNumberFieldFilter|undefined} value
 * @return {!proto.TicketCustomFieldOneOfFilter} returns this
*/
proto.TicketCustomFieldOneOfFilter.prototype.setNumberFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.TicketCustomFieldOneOfFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketCustomFieldOneOfFilter} returns this
 */
proto.TicketCustomFieldOneOfFilter.prototype.clearNumberFilter = function() {
  return this.setNumberFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomFieldOneOfFilter.prototype.hasNumberFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TicketCustomDateFieldFilter date_filter = 4;
 * @return {?proto.TicketCustomDateFieldFilter}
 */
proto.TicketCustomFieldOneOfFilter.prototype.getDateFilter = function() {
  return /** @type{?proto.TicketCustomDateFieldFilter} */ (
    jspb.Message.getWrapperField(this, proto.TicketCustomDateFieldFilter, 4));
};


/**
 * @param {?proto.TicketCustomDateFieldFilter|undefined} value
 * @return {!proto.TicketCustomFieldOneOfFilter} returns this
*/
proto.TicketCustomFieldOneOfFilter.prototype.setDateFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.TicketCustomFieldOneOfFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketCustomFieldOneOfFilter} returns this
 */
proto.TicketCustomFieldOneOfFilter.prototype.clearDateFilter = function() {
  return this.setDateFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomFieldOneOfFilter.prototype.hasDateFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TicketCustomTextFieldFilter text_filter = 5;
 * @return {?proto.TicketCustomTextFieldFilter}
 */
proto.TicketCustomFieldOneOfFilter.prototype.getTextFilter = function() {
  return /** @type{?proto.TicketCustomTextFieldFilter} */ (
    jspb.Message.getWrapperField(this, proto.TicketCustomTextFieldFilter, 5));
};


/**
 * @param {?proto.TicketCustomTextFieldFilter|undefined} value
 * @return {!proto.TicketCustomFieldOneOfFilter} returns this
*/
proto.TicketCustomFieldOneOfFilter.prototype.setTextFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.TicketCustomFieldOneOfFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketCustomFieldOneOfFilter} returns this
 */
proto.TicketCustomFieldOneOfFilter.prototype.clearTextFilter = function() {
  return this.setTextFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomFieldOneOfFilter.prototype.hasTextFilter = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional TicketCustomTextAreaFieldFilter text_area_filter = 6;
 * @return {?proto.TicketCustomTextAreaFieldFilter}
 */
proto.TicketCustomFieldOneOfFilter.prototype.getTextAreaFilter = function() {
  return /** @type{?proto.TicketCustomTextAreaFieldFilter} */ (
    jspb.Message.getWrapperField(this, proto.TicketCustomTextAreaFieldFilter, 6));
};


/**
 * @param {?proto.TicketCustomTextAreaFieldFilter|undefined} value
 * @return {!proto.TicketCustomFieldOneOfFilter} returns this
*/
proto.TicketCustomFieldOneOfFilter.prototype.setTextAreaFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.TicketCustomFieldOneOfFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TicketCustomFieldOneOfFilter} returns this
 */
proto.TicketCustomFieldOneOfFilter.prototype.clearTextAreaFilter = function() {
  return this.setTextAreaFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomFieldOneOfFilter.prototype.hasTextAreaFilter = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TicketCustomSelectFieldFilter.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketCustomSelectFieldFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketCustomSelectFieldFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketCustomSelectFieldFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomSelectFieldFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketCustomFieldId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    valuesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketCustomSelectFieldFilter}
 */
proto.TicketCustomSelectFieldFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketCustomSelectFieldFilter;
  return proto.TicketCustomSelectFieldFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketCustomSelectFieldFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketCustomSelectFieldFilter}
 */
proto.TicketCustomSelectFieldFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketCustomFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketCustomSelectFieldFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketCustomSelectFieldFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketCustomSelectFieldFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomSelectFieldFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketCustomFieldId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional uint32 ticket_custom_field_id = 1;
 * @return {number}
 */
proto.TicketCustomSelectFieldFilter.prototype.getTicketCustomFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TicketCustomSelectFieldFilter} returns this
 */
proto.TicketCustomSelectFieldFilter.prototype.setTicketCustomFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string values = 2;
 * @return {!Array<string>}
 */
proto.TicketCustomSelectFieldFilter.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.TicketCustomSelectFieldFilter} returns this
 */
proto.TicketCustomSelectFieldFilter.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.TicketCustomSelectFieldFilter} returns this
 */
proto.TicketCustomSelectFieldFilter.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TicketCustomSelectFieldFilter} returns this
 */
proto.TicketCustomSelectFieldFilter.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TicketCustomCheckboxFieldFilter.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketCustomCheckboxFieldFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketCustomCheckboxFieldFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketCustomCheckboxFieldFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomCheckboxFieldFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketCustomFieldId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    valuesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketCustomCheckboxFieldFilter}
 */
proto.TicketCustomCheckboxFieldFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketCustomCheckboxFieldFilter;
  return proto.TicketCustomCheckboxFieldFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketCustomCheckboxFieldFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketCustomCheckboxFieldFilter}
 */
proto.TicketCustomCheckboxFieldFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketCustomFieldId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addValues(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketCustomCheckboxFieldFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketCustomCheckboxFieldFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketCustomCheckboxFieldFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomCheckboxFieldFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketCustomFieldId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      2,
      f
    );
  }
};


/**
 * optional uint32 ticket_custom_field_id = 1;
 * @return {number}
 */
proto.TicketCustomCheckboxFieldFilter.prototype.getTicketCustomFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TicketCustomCheckboxFieldFilter} returns this
 */
proto.TicketCustomCheckboxFieldFilter.prototype.setTicketCustomFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated double values = 2;
 * @return {!Array<number>}
 */
proto.TicketCustomCheckboxFieldFilter.prototype.getValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.TicketCustomCheckboxFieldFilter} returns this
 */
proto.TicketCustomCheckboxFieldFilter.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.TicketCustomCheckboxFieldFilter} returns this
 */
proto.TicketCustomCheckboxFieldFilter.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TicketCustomCheckboxFieldFilter} returns this
 */
proto.TicketCustomCheckboxFieldFilter.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketCustomNumberFieldFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketCustomNumberFieldFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketCustomNumberFieldFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomNumberFieldFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketCustomFieldId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    maxValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketCustomNumberFieldFilter}
 */
proto.TicketCustomNumberFieldFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketCustomNumberFieldFilter;
  return proto.TicketCustomNumberFieldFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketCustomNumberFieldFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketCustomNumberFieldFilter}
 */
proto.TicketCustomNumberFieldFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketCustomFieldId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketCustomNumberFieldFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketCustomNumberFieldFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketCustomNumberFieldFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomNumberFieldFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketCustomFieldId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional uint32 ticket_custom_field_id = 1;
 * @return {number}
 */
proto.TicketCustomNumberFieldFilter.prototype.getTicketCustomFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TicketCustomNumberFieldFilter} returns this
 */
proto.TicketCustomNumberFieldFilter.prototype.setTicketCustomFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double min_value = 2;
 * @return {number}
 */
proto.TicketCustomNumberFieldFilter.prototype.getMinValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TicketCustomNumberFieldFilter} returns this
 */
proto.TicketCustomNumberFieldFilter.prototype.setMinValue = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TicketCustomNumberFieldFilter} returns this
 */
proto.TicketCustomNumberFieldFilter.prototype.clearMinValue = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomNumberFieldFilter.prototype.hasMinValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double max_value = 3;
 * @return {number}
 */
proto.TicketCustomNumberFieldFilter.prototype.getMaxValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TicketCustomNumberFieldFilter} returns this
 */
proto.TicketCustomNumberFieldFilter.prototype.setMaxValue = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TicketCustomNumberFieldFilter} returns this
 */
proto.TicketCustomNumberFieldFilter.prototype.clearMaxValue = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomNumberFieldFilter.prototype.hasMaxValue = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketCustomDateFieldFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketCustomDateFieldFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketCustomDateFieldFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomDateFieldFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketCustomFieldId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketCustomDateFieldFilter}
 */
proto.TicketCustomDateFieldFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketCustomDateFieldFilter;
  return proto.TicketCustomDateFieldFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketCustomDateFieldFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketCustomDateFieldFilter}
 */
proto.TicketCustomDateFieldFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketCustomFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketCustomDateFieldFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketCustomDateFieldFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketCustomDateFieldFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomDateFieldFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketCustomFieldId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 ticket_custom_field_id = 1;
 * @return {number}
 */
proto.TicketCustomDateFieldFilter.prototype.getTicketCustomFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TicketCustomDateFieldFilter} returns this
 */
proto.TicketCustomDateFieldFilter.prototype.setTicketCustomFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string start_date = 2;
 * @return {string}
 */
proto.TicketCustomDateFieldFilter.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketCustomDateFieldFilter} returns this
 */
proto.TicketCustomDateFieldFilter.prototype.setStartDate = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TicketCustomDateFieldFilter} returns this
 */
proto.TicketCustomDateFieldFilter.prototype.clearStartDate = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomDateFieldFilter.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string end_date = 3;
 * @return {string}
 */
proto.TicketCustomDateFieldFilter.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketCustomDateFieldFilter} returns this
 */
proto.TicketCustomDateFieldFilter.prototype.setEndDate = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TicketCustomDateFieldFilter} returns this
 */
proto.TicketCustomDateFieldFilter.prototype.clearEndDate = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TicketCustomDateFieldFilter.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketCustomTextFieldFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketCustomTextFieldFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketCustomTextFieldFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomTextFieldFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketCustomFieldId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stringValue: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketCustomTextFieldFilter}
 */
proto.TicketCustomTextFieldFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketCustomTextFieldFilter;
  return proto.TicketCustomTextFieldFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketCustomTextFieldFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketCustomTextFieldFilter}
 */
proto.TicketCustomTextFieldFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketCustomFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketCustomTextFieldFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketCustomTextFieldFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketCustomTextFieldFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomTextFieldFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketCustomFieldId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStringValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 ticket_custom_field_id = 1;
 * @return {number}
 */
proto.TicketCustomTextFieldFilter.prototype.getTicketCustomFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TicketCustomTextFieldFilter} returns this
 */
proto.TicketCustomTextFieldFilter.prototype.setTicketCustomFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string string_value = 2;
 * @return {string}
 */
proto.TicketCustomTextFieldFilter.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketCustomTextFieldFilter} returns this
 */
proto.TicketCustomTextFieldFilter.prototype.setStringValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TicketCustomTextAreaFieldFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.TicketCustomTextAreaFieldFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TicketCustomTextAreaFieldFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomTextAreaFieldFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketCustomFieldId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    textValue: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TicketCustomTextAreaFieldFilter}
 */
proto.TicketCustomTextAreaFieldFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TicketCustomTextAreaFieldFilter;
  return proto.TicketCustomTextAreaFieldFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TicketCustomTextAreaFieldFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TicketCustomTextAreaFieldFilter}
 */
proto.TicketCustomTextAreaFieldFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketCustomFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TicketCustomTextAreaFieldFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TicketCustomTextAreaFieldFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TicketCustomTextAreaFieldFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TicketCustomTextAreaFieldFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketCustomFieldId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTextValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 ticket_custom_field_id = 1;
 * @return {number}
 */
proto.TicketCustomTextAreaFieldFilter.prototype.getTicketCustomFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TicketCustomTextAreaFieldFilter} returns this
 */
proto.TicketCustomTextAreaFieldFilter.prototype.setTicketCustomFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text_value = 2;
 * @return {string}
 */
proto.TicketCustomTextAreaFieldFilter.prototype.getTextValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TicketCustomTextAreaFieldFilter} returns this
 */
proto.TicketCustomTextAreaFieldFilter.prototype.setTextValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListTicketCustomFieldValuesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ListTicketCustomFieldValuesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListTicketCustomFieldValuesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTicketCustomFieldValuesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListTicketCustomFieldValuesRequest}
 */
proto.ListTicketCustomFieldValuesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListTicketCustomFieldValuesRequest;
  return proto.ListTicketCustomFieldValuesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListTicketCustomFieldValuesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListTicketCustomFieldValuesRequest}
 */
proto.ListTicketCustomFieldValuesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListTicketCustomFieldValuesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListTicketCustomFieldValuesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListTicketCustomFieldValuesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTicketCustomFieldValuesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 ticket_id = 1;
 * @return {number}
 */
proto.ListTicketCustomFieldValuesRequest.prototype.getTicketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ListTicketCustomFieldValuesRequest} returns this
 */
proto.ListTicketCustomFieldValuesRequest.prototype.setTicketId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ListTicketCustomFieldValuesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListTicketCustomFieldValuesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ListTicketCustomFieldValuesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListTicketCustomFieldValuesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTicketCustomFieldValuesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketCustomFieldValuesList: jspb.Message.toObjectList(msg.getTicketCustomFieldValuesList(),
    proto.TicketCustomFieldValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListTicketCustomFieldValuesResponse}
 */
proto.ListTicketCustomFieldValuesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListTicketCustomFieldValuesResponse;
  return proto.ListTicketCustomFieldValuesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListTicketCustomFieldValuesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListTicketCustomFieldValuesResponse}
 */
proto.ListTicketCustomFieldValuesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TicketCustomFieldValue;
      reader.readMessage(value,proto.TicketCustomFieldValue.deserializeBinaryFromReader);
      msg.addTicketCustomFieldValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListTicketCustomFieldValuesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListTicketCustomFieldValuesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListTicketCustomFieldValuesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTicketCustomFieldValuesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketCustomFieldValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.TicketCustomFieldValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TicketCustomFieldValue ticket_custom_field_values = 1;
 * @return {!Array<!proto.TicketCustomFieldValue>}
 */
proto.ListTicketCustomFieldValuesResponse.prototype.getTicketCustomFieldValuesList = function() {
  return /** @type{!Array<!proto.TicketCustomFieldValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TicketCustomFieldValue, 1));
};


/**
 * @param {!Array<!proto.TicketCustomFieldValue>} value
 * @return {!proto.ListTicketCustomFieldValuesResponse} returns this
*/
proto.ListTicketCustomFieldValuesResponse.prototype.setTicketCustomFieldValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TicketCustomFieldValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TicketCustomFieldValue}
 */
proto.ListTicketCustomFieldValuesResponse.prototype.addTicketCustomFieldValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TicketCustomFieldValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListTicketCustomFieldValuesResponse} returns this
 */
proto.ListTicketCustomFieldValuesResponse.prototype.clearTicketCustomFieldValuesList = function() {
  return this.setTicketCustomFieldValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UpdateTicketCustomFieldValueRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateTicketCustomFieldValueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateTicketCustomFieldValueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateTicketCustomFieldValueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTicketCustomFieldValueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ticketCustomFieldId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    valueList: jspb.Message.toObjectList(msg.getValueList(),
    proto.TicketCustomFieldOneOfValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateTicketCustomFieldValueRequest}
 */
proto.UpdateTicketCustomFieldValueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateTicketCustomFieldValueRequest;
  return proto.UpdateTicketCustomFieldValueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateTicketCustomFieldValueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateTicketCustomFieldValueRequest}
 */
proto.UpdateTicketCustomFieldValueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketCustomFieldId(value);
      break;
    case 3:
      var value = new proto.TicketCustomFieldOneOfValue;
      reader.readMessage(value,proto.TicketCustomFieldOneOfValue.deserializeBinaryFromReader);
      msg.addValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateTicketCustomFieldValueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateTicketCustomFieldValueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateTicketCustomFieldValueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTicketCustomFieldValueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTicketCustomFieldId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getValueList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.TicketCustomFieldOneOfValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 ticket_id = 1;
 * @return {number}
 */
proto.UpdateTicketCustomFieldValueRequest.prototype.getTicketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateTicketCustomFieldValueRequest} returns this
 */
proto.UpdateTicketCustomFieldValueRequest.prototype.setTicketId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 ticket_custom_field_id = 2;
 * @return {number}
 */
proto.UpdateTicketCustomFieldValueRequest.prototype.getTicketCustomFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateTicketCustomFieldValueRequest} returns this
 */
proto.UpdateTicketCustomFieldValueRequest.prototype.setTicketCustomFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated TicketCustomFieldOneOfValue value = 3;
 * @return {!Array<!proto.TicketCustomFieldOneOfValue>}
 */
proto.UpdateTicketCustomFieldValueRequest.prototype.getValueList = function() {
  return /** @type{!Array<!proto.TicketCustomFieldOneOfValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TicketCustomFieldOneOfValue, 3));
};


/**
 * @param {!Array<!proto.TicketCustomFieldOneOfValue>} value
 * @return {!proto.UpdateTicketCustomFieldValueRequest} returns this
*/
proto.UpdateTicketCustomFieldValueRequest.prototype.setValueList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.TicketCustomFieldOneOfValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TicketCustomFieldOneOfValue}
 */
proto.UpdateTicketCustomFieldValueRequest.prototype.addValue = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.TicketCustomFieldOneOfValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateTicketCustomFieldValueRequest} returns this
 */
proto.UpdateTicketCustomFieldValueRequest.prototype.clearValueList = function() {
  return this.setValueList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteTicketCustomFieldValueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteTicketCustomFieldValueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteTicketCustomFieldValueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteTicketCustomFieldValueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ticketCustomFieldId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteTicketCustomFieldValueRequest}
 */
proto.DeleteTicketCustomFieldValueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteTicketCustomFieldValueRequest;
  return proto.DeleteTicketCustomFieldValueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteTicketCustomFieldValueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteTicketCustomFieldValueRequest}
 */
proto.DeleteTicketCustomFieldValueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTicketCustomFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteTicketCustomFieldValueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteTicketCustomFieldValueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteTicketCustomFieldValueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteTicketCustomFieldValueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTicketCustomFieldId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 ticket_id = 1;
 * @return {number}
 */
proto.DeleteTicketCustomFieldValueRequest.prototype.getTicketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeleteTicketCustomFieldValueRequest} returns this
 */
proto.DeleteTicketCustomFieldValueRequest.prototype.setTicketId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 ticket_custom_field_id = 2;
 * @return {number}
 */
proto.DeleteTicketCustomFieldValueRequest.prototype.getTicketCustomFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeleteTicketCustomFieldValueRequest} returns this
 */
proto.DeleteTicketCustomFieldValueRequest.prototype.setTicketCustomFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto);
