import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Input,
  Loader,
  Text,
  TextArea,
} from '@fluentui/react-northstar'
import * as React from 'react'

import DialogWithCloseIcon from '../../../../components/DialogWithCloseIcon'
import { Dropdown } from '../../../../components/Dropdown'
import {
  FaqStateError,
  FaqStateErrorObject,
} from '../../../../features/faq/faqSlice'
import { FaqLabel } from '../../../../proto/faq_label_pb'
import RecommendedFaqList from '../../../AlreadyRecommendedFaqList/index'
import styles from './styles.module.css'

type Props = {
  renderFaq: () => React.ReactElement
  renderRedundantIntents: () => React.ReactElement
  renderButtons: () => React.ReactElement
  relatedTicketCount: number
}

type ButtonsPresentationProps = {
  isLoadingSkipRecommendFaq: boolean
  isLoadingCreateFaq: boolean
  onSkipClick: () => void
  onCreateClick: () => void
}

export const RecommendedFaqButtonsPresenter: React.FC<
  ButtonsPresentationProps
> = (props) => {
  return (
    <Flex className={styles.faqButtonFlexBox}>
      <Button
        content={
          props.isLoadingSkipRecommendFaq ? (
            <Loader size="small" />
          ) : (
            'スキップ（保留）'
          )
        }
        className={styles.skipButton}
        onClick={props.onSkipClick}
      />
      <Button
        className={styles.faqCreateButton}
        content={
          props.isLoadingCreateFaq ? (
            <Loader size="small" />
          ) : (
            'この内容でFAQを作成'
          )
        }
        primary
        onClick={props.onCreateClick}
      />
    </Flex>
  )
}

type RecommendedFaqPresentationProps = {
  request: string
  setRequest: (request: string) => void
  response: string
  setResponse: (response: string) => void
  intentName: string
  seIntentName: (intentName: string) => void
  labels: string[]
  setLabels: (labels: string[]) => void
  publish: boolean
  setPublish: (publish: boolean) => void
  registeredFaqLabels: FaqLabel.AsObject[]
  error: FaqStateError | null
}

export const RecommendedFaqPresenter: React.FC<
  RecommendedFaqPresentationProps
> = (props) => {
  return (
    <>
      <Flex column className={styles.faqFlexBox}>
        <Text>
          FAQの質問&nbsp;<Text color="red">*</Text>
        </Text>
        <TextArea
          placeholder={'FAQの質問'}
          required
          rows={4}
          resize="vertical"
          className={styles.faqTextArea}
          value={props.request}
          onChange={(e) => {
            props.setRequest((e.target as HTMLInputElement).value)
          }}
        />
      </Flex>
      <Flex column className={styles.faqFlexBox}>
        <Text>
          FAQの回答&nbsp;<Text color="red">*</Text>
        </Text>
        <TextArea
          placeholder={'FAQの回答'}
          required
          rows={9}
          resize="vertical"
          className={styles.faqTextArea}
          value={props.response}
          onChange={(e) => {
            props.setResponse((e.target as HTMLInputElement).value)
          }}
        />
      </Flex>
      <Flex column className={styles.faqFlexBox}>
        <Text>FAQ識別子（インテント識別子）</Text>
        <Input
          fluid
          placeholder={'未入力の場合は自動で設定されます'}
          value={props.intentName}
          className={styles.faqPropertyInput}
          onChange={(e) => {
            props.seIntentName((e.target as HTMLInputElement).value)
          }}
        />
        {FaqStateErrorObject.isFieldViolationError(props.error, 'name') ? (
          <Text align={'start'} size="small" color="red">
            {props.error?.message}
          </Text>
        ) : (
          ''
        )}
        {false && (
          <Text>
            <DialogWithCloseIcon
              header="既存のインテント表示名を確認"
              content={
                <Box
                  styles={() => ({
                    height: '650px',
                  })}
                >
                  <RecommendedFaqList />
                </Box>
              }
              trigger={
                <Button
                  text
                  styles={() => ({
                    color: '#6264a7',
                  })}
                >
                  登録済みのインテントを確認
                </Button>
              }
            />
          </Text>
        )}
      </Flex>
      <Flex column className={styles.faqFlexBox}>
        <Text>分類ラベル</Text>
        <Dropdown
          fluid
          multiple
          search
          placeholder={'選択してください'}
          noResultsMessage={'選択肢がありません'}
          value={props.labels}
          onValueChange={(values) => {
            props.setLabels(values)
          }}
          items={props.registeredFaqLabels.map((it) => {
            return {
              header: it.value,
              value: it.id.toString(),
            }
          })}
          className={styles.faqPropertyInput}
        />
      </Flex>
      <Flex column className={styles.faqFlexBox}>
        <Text>FAQの公開設定</Text>
        <Checkbox
          disabled={false}
          checked={props.publish}
          onChange={() => props.setPublish(!props.publish)}
          label="公開する"
        />
      </Flex>
    </>
  )
}

const RecommendedFaqItemContentPresenter: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <Flex column className={styles.faqItemContentFlexBox}>
        {props.renderFaq()}
        {props.renderRedundantIntents()}
        <Divider />
        <Flex className={styles.contentFooter}>
          <Text weight="bold" className={styles.effectMessage}>
            FAQ化で月{props.relatedTicketCount}件の有人問い合わせが減ります
          </Text>
          {props.renderButtons()}
        </Flex>
      </Flex>
    </div>
  )
}

export default RecommendedFaqItemContentPresenter
