import { Box, Chat, Checkbox, Flex, Text } from '@fluentui/react-northstar'
import { User } from '@microsoft/microsoft-graph-types'
import * as Graph from '@microsoft/microsoft-graph-types'
import { Dictionary } from '@reduxjs/toolkit'
import { ReactNode } from 'react'

import {
  DefaultFields,
  TicketStatus,
  TicketStatusLabels,
  Tickets,
} from '../../consts'
import message_pb from '../../proto/message_pb'
import ticket_custom_field_pb from '../../proto/ticket_custom_field_pb'
import { formatShort, getChannelDisplayName, getDisplayName } from '../../utils'
import styles from './ChatItemActivityLog.module.css'
import { ChatItemPropsWithKey } from './ChatItems'

// TODO 共通化
export const addSection = (changeUser: string): string => {
  switch (changeUser) {
    case Tickets.Users.AutoAssignUser:
    case Tickets.Users.System:
      return 'が'
    default:
      return 'さんが'
  }
}

export interface createActivityLogItemProps {
  changeUser: string
  type: string
  preventData: ReactNode
  changedData: ReactNode
  timestamp: number
  key: string
}

export interface createActivityLogItemArgs {
  message: message_pb.Message.AsObject
  memberEntities: Dictionary<User>
  userEntities: Dictionary<User>
  customFields: ticket_custom_field_pb.TicketCustomField.AsObject[]
  channelEntities: Dictionary<Graph.Channel>
  index: number
}

export const createActivityLogItemProps = (
  props: createActivityLogItemArgs
): createActivityLogItemProps | undefined => {
  const {
    message,
    memberEntities,
    userEntities,
    customFields,
    index,
    channelEntities,
  } = props

  const userName = defineUserName(message, memberEntities, userEntities)
  const activity = message.activity
  if (activity == null) {
    return
  }
  const customField = customFields.find(
    (field) => field.id === activity.customFieldId
  )
  let type = activity.type
  let preventData = activity.preventData
  let changedData = activity.changedData
  switch (type) {
    case 'assigned_user_id':
      type = DefaultFields.AssignedUserId.name
      preventData = preventData
        ? getDisplayName(memberEntities[preventData])
        : Tickets.Users.UserNotIndicated
      changedData = changedData
        ? getDisplayName(memberEntities[changedData])
        : Tickets.Users.UserNotIndicated
      break
    case 'subject':
      type = DefaultFields.Subject.name
      break
    case 'note':
      type = DefaultFields.Note.name
      break
    case 'status':
      type = DefaultFields.Status.name
      preventData = TicketStatusLabels[preventData as TicketStatus]
      changedData = TicketStatusLabels[changedData as TicketStatus]
      break
    case 'channel_id':
      type = DefaultFields.Channel.name
      preventData = getChannelDisplayName(channelEntities[preventData])
      changedData = getChannelDisplayName(channelEntities[changedData])
      break
    case 'custom_field':
      type = customField?.name || Tickets.Users.UserNotExists
      preventData = preventData || Tickets.Users.UserNotIndicated
      changedData = changedData || Tickets.Users.UserNotIndicated

      if (customField?.type === 'checkbox') {
        return {
          key: `m-${index}-activity`,
          timestamp: activity.timestamp,
          type: type,
          changeUser: userName,
          changedData:
            changedData === 'true' ? (
              <Checkbox
                className={`${styles.activityLogCheckbox} ${styles.activityLogCheckboxOn}`}
                checked={true}
              />
            ) : (
              <Checkbox
                className={styles.activityLogCheckbox}
                checked={false}
              />
            ),
          preventData:
            preventData === 'true' ? (
              <Checkbox
                className={`${styles.activityLogCheckbox} ${styles.activityLogCheckboxOn}`}
                checked={true}
              />
            ) : (
              <Checkbox
                className={styles.activityLogCheckbox}
                checked={false}
              />
            ),
        }
      }
      break
    default:
      break
  }

  return {
    key: `m-${index}-activity`,
    type: type,
    timestamp: activity.timestamp,
    changeUser: userName,
    changedData,
    preventData,
  }
}

const defineUserName = (
  message: message_pb.Message.AsObject,
  memberEntities: Dictionary<User>,
  userEntities: Dictionary<User>
): string => {
  if (
    message.userId === '' &&
    message.senderType === Tickets.Messages.SenderTypeAutoAssign
  ) {
    return Tickets.Users.AutoAssignUser
  } else if (message.senderType === Tickets.Messages.SenderTypeSystem) {
    return Tickets.Users.System
  }

  const mergedEntities = Object.assign({}, memberEntities, userEntities)
  return getDisplayName(mergedEntities[message.userId])
}

export const createChatActivityLogItem = (
  args: createActivityLogItemProps
): ChatItemPropsWithKey => {
  const timestampLabel = formatShort(args.timestamp)
  const message = (
    <Chat.Item key={args.key} attached={true} className={styles.activityLog}>
      <Flex className={styles.activityLogInner}>
        <Box className={styles.activityLogDecoration}>
          <hr />
        </Box>
        <Box className={styles.activityLogLabel}>
          {args.type == 'faq' ? (
            <>
              <Box className={styles.head}>
                <Text
                  content={args.changeUser}
                  className={styles.activityLogEmphasisText}
                ></Text>
                {addSection(args.changeUser)}
                が今回の対応を
                <Text content={timestampLabel}></Text>
              </Box>
              <Box>
                <Text className={styles.activityLogEmphasisText}>
                  FAQ化しました
                </Text>
              </Box>
            </>
          ) : (
            <>
              <Box className={styles.head}>
                <Text
                  content={args.changeUser}
                  className={styles.activityLogEmphasisText}
                ></Text>
                {addSection(args.changeUser)}
                <Text
                  content={args.type}
                  className={styles.activityLogEmphasisText}
                ></Text>
                を変更しました
                <Text content={timestampLabel}></Text>
              </Box>
              <Box>
                {typeof args.preventData === 'string' ? (
                  <Text
                    content={args.preventData}
                    className={styles.activityLogEmphasisText}
                  ></Text>
                ) : (
                  args.preventData
                )}
                <Text content={' → '}></Text>
                {typeof args.changedData === 'string' ? (
                  <Text
                    content={args.changedData}
                    className={styles.activityLogEmphasisText}
                  ></Text>
                ) : (
                  args.changedData
                )}
              </Box>
            </>
          )}
        </Box>
        <Box className={styles.activityLogDecoration}>
          <hr />
        </Box>
      </Flex>
    </Chat.Item>
  )
  return {
    density: 'compact',
    message: message,
    key: args.key,
  }
}
