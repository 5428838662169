import * as React from 'react'

import { useGetMemberPhotoQuery, useGetMemberQuery } from '../../../graphApi'
import ActivityMessagePlanPreviewReceiverListItemPresenter from './presenter'

type Props = {
  id: string
}
const ActivityMessagePlanPreviewReceiverListItem: React.FC<Props> = (props) => {
  const memberQuery = useGetMemberQuery({
    id: props.id,
  })
  const photoQuery = useGetMemberPhotoQuery({
    id: props.id,
  })

  return (
    <ActivityMessagePlanPreviewReceiverListItemPresenter
      isLoading={memberQuery.isLoading || photoQuery.isLoading}
      name={memberQuery.data?.name ?? ''}
      src={photoQuery.data ?? ''}
    />
  )
}

export default React.memo(ActivityMessagePlanPreviewReceiverListItem)
