import { Button, ChevronStartIcon, Text } from '@fluentui/react-northstar'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import styles from './styles.module.css'

type Props = {
  onClickNewMessageButton: () => void
  renderPagination: () => React.ReactNode
}
const ActivityMessagePlanHeader: React.FC<Props> = (props) => {
  const history = useHistory()

  const clickBackButton = React.useCallback(() => {
    history.push('/tickets')
  }, [history])

  return (
    <div className={styles.container}>
      <div className={styles.navigatorRow}>
        <Button
          text
          icon={<ChevronStartIcon />}
          content="問い合わせの一覧"
          onClick={clickBackButton}
        />
      </div>
      <div className={styles.titleRow}>
        <div>
          <Text size="large" weight="bold" content="お知らせの配信" />
        </div>
        <div>
          <Button
            onClick={props.onClickNewMessageButton}
            primary
            icon={
              <img
                src={
                  process.env.PUBLIC_URL + '/assets/document_bullet_list.svg'
                }
                style={{
                  width: '16px',
                  height: '16px',
                }}
              />
            }
            content="新規配信の作成"
          />
        </div>
      </div>
      <div className={styles.paginatorRow}>{props.renderPagination()}</div>
    </div>
  )
}

export default React.memo(ActivityMessagePlanHeader)
