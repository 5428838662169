import { Datepicker, Flex, Text } from '@fluentui/react-northstar'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { Dropdown } from '../../components/Dropdown'
import { hourItems, minuteItems } from '../../utils'
import styles from './TicketDefaultFieldFilter.module.css'
import {
  setEndDate,
  setEndHour,
  setEndMinutes,
  setStartDate,
  setStartHour,
  setStartMinutes,
} from './ticketSlice'

const TicketCreatedAtFilter: React.FC = () => {
  const ticketState = useSelector((state: RootState) => state.ticket)
  const noResultsMessageHour = '00〜23の範囲で指定してください'
  const noResultsMessageMinutes = '00〜59の範囲で指定してください'
  const dispatch = useDispatch()
  const formatter = (d: Date) =>
    `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`

  return (
    <Flex column gap="gap.small">
      <Text content="絞り込み" weight="bold" className={styles.headingText} />
      <Flex gap="gap.smaller" vAlign="center">
        <Text content="開始日" className={styles.createdAtTitle} />
        <Datepicker
          allowManualInput={false}
          formatMonthDayYear={formatter}
          inputPlaceholder="日"
          inputOnly
          className={styles.createdAtDatePicker}
          input={{
            clearable: true,
            fluid: true,
          }}
          defaultSelectedDate={ticketState.filters.startDate}
          selectedDate={ticketState.filters.startDate}
          onDateChange={(_, newDate) => {
            if (newDate) {
              dispatch(setStartDate({ startDate: new Date(newDate.value) }))
            }
          }}
        />
        <Dropdown
          fluid
          className={styles.createdAtDatePicker}
          placeholder={'時'}
          search
          noResultsMessage={noResultsMessageHour}
          searchQuery={ticketState.filters.startHour[0]}
          checkable
          clearable={true}
          items={hourItems}
          value={ticketState.filters.startHour}
          onValueChange={(values: string[]) => {
            dispatch(setStartHour({ startHour: values }))
          }}
        />
        <Dropdown
          fluid
          className={styles.createdAtDatePicker}
          placeholder={'分'}
          search
          noResultsMessage={noResultsMessageMinutes}
          searchQuery={ticketState.filters.startMinutes[0]}
          checkable
          clearable={true}
          value={ticketState.filters.startMinutes}
          items={minuteItems}
          onValueChange={(values: string[]) => {
            dispatch(setStartMinutes({ startMinutes: values }))
          }}
        />
      </Flex>
      <Flex gap="gap.smaller" vAlign="center">
        <Text content="終了日" className={styles.createdAtTitle} />
        <Datepicker
          allowManualInput={false}
          formatMonthDayYear={formatter}
          inputPlaceholder="日"
          inputOnly
          className={styles.createdAtDatePicker}
          input={{
            clearable: true,
            fluid: true,
          }}
          defaultSelectedDate={ticketState.filters.endDate}
          selectedDate={ticketState.filters.endDate}
          onDateChange={(_, newDate) => {
            if (newDate) {
              dispatch(setEndDate({ endDate: new Date(newDate.value) }))
            }
          }}
        />
        <Dropdown
          fluid
          className={styles.createdAtDatePicker}
          placeholder={'時'}
          search
          noResultsMessage={noResultsMessageHour}
          searchQuery={ticketState.filters.endHour[0]}
          checkable
          clearable={true}
          items={hourItems}
          value={ticketState.filters.endHour}
          onValueChange={(values: string[]) => {
            dispatch(setEndHour({ endHour: values }))
          }}
        />
        <Dropdown
          fluid
          className={styles.createdAtDatePicker}
          placeholder={'分'}
          search
          noResultsMessage={noResultsMessageMinutes}
          searchQuery={ticketState.filters.endMinutes[0]}
          checkable
          clearable={true}
          value={ticketState.filters.endMinutes}
          items={minuteItems}
          onValueChange={(values: string[]) => {
            dispatch(setEndMinutes({ endMinutes: values }))
          }}
        />
      </Flex>
    </Flex>
  )
}

export default TicketCreatedAtFilter
