import './App.css'

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'

import ProtectedRoute from '../components/ProtectedRoute'
import AlertContainer from '../features/alert/AlertContainer'
import AuthAdminConsent from '../features/auth/AuthAdminConsent'
import AuthClose from '../features/auth/AuthClose'
import AuthConsent from '../features/auth/AuthConsent'
import AuthLogin from '../features/auth/AuthLogin'
import { setAppInsightsUser } from '../features/auth/authSlice'
import NotFoundError from '../features/error/NotFoundError'
import UnknownError from '../features/error/UnknownError'
import HealthCheck from '../features/health/HealthCheck'
import Config from '../features/tabConfig/TabConfig'
import { TabDelete } from '../features/tabConfig/TabDelete'
import TicketCustomFieldList from '../features/ticket/TicketCustomFieldList'
import TicketDetail from '../features/ticket/TicketDetail'
import TicketList from '../features/ticket/TicketList'
import TicketTransfer from '../features/ticket/TicketTransfer'
import ActivityMessagePlanEdit from '../pages/ActivityMessagePlanEdit'
import ActivityMessagePlanNew from '../pages/ActivityMessagePlanNew'
import ActivityMessagePlanPreview from '../pages/ActivityMessagePlanPreview'
import ActivityMessagePlans from '../pages/ActivityMessagePlans'
import Dashboard from '../pages/Dashboard'
import RequesterChat from '../pages/RequesterChat'
import RecommendedFaqList from '../renewfeatures/RecommendedFaqList'
import RequesterProtectedRoute from '../renewfeatures/RequesterProtectedRoute'
import { RootState } from './store'

function App(): JSX.Element {
  // Azure Application InsightsのuserIdをログインユーザーIDにする
  // 全コンポーネント共通処理としてコンポーネントツリーの最上部で行う
  const authState = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setAppInsightsUser())
  }, [dispatch, authState.context?.user?.id])

  return (
    <React.StrictMode>
      <div id="App" className="App">
        <Router>
          <Switch>
            <Route exact path="/health" component={HealthCheck} />
            <Route exact path="/login" component={AuthLogin} />
            <Route exact path="/auth/consent" component={AuthConsent} />
            <Route
              exact
              path="/auth/adminconsent"
              component={AuthAdminConsent}
            />
            <Route exact path="/auth/close" component={AuthClose} />
            <Route exact path="/errors/unknown" component={UnknownError} />
            <Route exact path="/errors/not_found" component={NotFoundError} />
            <RequesterProtectedRoute
              exact
              path="/requester/chat"
              component={RequesterChat}
            />
            <ProtectedRoute exact path="/config" component={Config} />
            <ProtectedRoute exact path="/tickets" component={TicketList} />
            <ProtectedRoute exact path="/tab/delete" component={TabDelete} />
            <ProtectedRoute
              exact
              path="/tickets/:ticketId"
              component={TicketDetail}
            />
            <ProtectedRoute
              exact
              path="/tickets/:ticketId/transfer"
              component={TicketTransfer}
            />
            <ProtectedRoute
              exact
              path="/customfields"
              component={TicketCustomFieldList}
            />
            <ProtectedRoute exact path="/reports" component={Dashboard} />
            <ProtectedRoute
              exact
              path="/recommended_faqs"
              component={RecommendedFaqList}
            />
            <ProtectedRoute
              exact
              path="/activity-messages"
              component={ActivityMessagePlans}
            />
            <ProtectedRoute
              exact
              path="/activity-messages/new"
              component={ActivityMessagePlanNew}
            />
            <ProtectedRoute
              exact
              path="/activity-messages/:id/edit"
              component={ActivityMessagePlanEdit}
            />
            <ProtectedRoute
              exact
              path="/activity-messages/:id"
              component={ActivityMessagePlanPreview}
            />
            <Route exact path="/">
              <Redirect to={{ pathname: '/tickets' }} />
            </Route>
            <Route>
              <Redirect to={{ pathname: '/errors/not_found' }} />
            </Route>
          </Switch>
        </Router>
        <AlertContainer />
      </div>
    </React.StrictMode>
  )
}

export default App
