import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../app/store'

const stateSelector = (state: RootState) => state.recommendedFaqList

export const scheduledAt = createSelector(stateSelector, (state) => {
  return state.scheduledAt
})

export const executedAt = createSelector(stateSelector, (state) => {
  return state.executedAt
})

export const recommendedFaqListError = createSelector(
  stateSelector,
  (state) => {
    return state.error
  }
)

export const isConfigured = createSelector(stateSelector, (state) => {
  return state.isConfigured
})

export const selectedRecommendedFaqSelector = createSelector(
  stateSelector,
  (state) => {
    return state.selectedRecommendedFaq
  }
)
