import { createSelector } from '@reduxjs/toolkit'

import { ChatButtonsProp } from '../../../../components/RequesterChatButtonGroup'
import { FileProp } from '../../../../components/RequesterChatFileList'
import { Tickets } from '../../../../consts'
import { graphApi } from '../../../graphApi'
import { dateToTicketDateTime } from '../../lib/dateToTicketDateTime'
import { requesterTicketListApiDataSelector } from '../../selectors'
import { requesterTicketEntityAdapter } from '../../slice'
import { hasButtonsMessage } from '../../types/RequesterMessage'

export const lastReplyId = createSelector(
  [requesterTicketListApiDataSelector, (_, id: string) => id],
  (ticketEntity, id) => {
    if (!ticketEntity) return ''
    const ticket = requesterTicketEntityAdapter
      .getSelectors()
      .selectById(ticketEntity, id)
    if (!ticket) return ''

    return ticket.lastReplyMessage?.id ?? ''
  }
)

export const isRequesterMessage = createSelector(
  [requesterTicketListApiDataSelector, (_, ticketId: string) => ticketId],
  (ticketEntity, ticketId: string) => {
    if (!ticketEntity) return false
    const ticket = requesterTicketEntityAdapter
      .getSelectors()
      .selectById(ticketEntity, ticketId)
    if (!ticket || !ticket.lastReplyMessage) return false
    return (
      ticket.lastReplyMessage.senderType ===
      Tickets.Messages.SenderTypeRequester
    )
  }
)

export const authorId = createSelector(
  [requesterTicketListApiDataSelector, (_, ticketId: string) => ticketId],
  (ticketEntity, ticketId) => {
    if (!ticketEntity) return ''
    const ticket = requesterTicketEntityAdapter
      .getSelectors()
      .selectById(ticketEntity, ticketId)
    if (!ticket || !ticket.lastReplyMessage) return ''
    return ticket.lastReplyMessage.authorId
  }
)

export const time = createSelector(
  [requesterTicketListApiDataSelector, (_, ticketId: string) => ticketId],
  (ticketEntity, ticketId) => {
    if (!ticketEntity) return ''
    const ticket = requesterTicketEntityAdapter
      .getSelectors()
      .selectById(ticketEntity, ticketId)
    if (!ticket || !ticket.lastReplyMessage) return ''
    return dateToTicketDateTime(new Date(ticket.lastReplyMessage.createdAt))
  }
)

export const isOwnMessage = createSelector(
  [
    requesterTicketListApiDataSelector,
    (_, ticketId: string) => ticketId,
    (state) => graphApi.endpoints.getProfileMe.select()(state).data,
  ],
  (ticketEntity, ticketId, profileMe): boolean => {
    if (!ticketEntity) return false
    if (!profileMe) return false
    const ticket = requesterTicketEntityAdapter
      .getSelectors()
      .selectById(ticketEntity, ticketId)
    if (!ticket || !ticket.lastReplyMessage) return false

    return ticket.lastReplyMessage.authorId === profileMe.id
  }
)

export const fileProps = createSelector(
  [requesterTicketListApiDataSelector, (_, ticketId: string) => ticketId],
  (ticketEntity, ticketId): FileProp[] => {
    if (!ticketEntity) return []
    const ticket = requesterTicketEntityAdapter
      .getSelectors()
      .selectById(ticketEntity, ticketId)
    if (!ticket || !ticket.lastReplyMessage) return []
    return ticket.lastReplyMessage.files.map((f) => ({
      id: f.id,
      fileName: f.name,
      url: f.url,
      mimeType: f.mimeType,
      status: 'done',
    }))
  }
)

export const buttonGroup = createSelector(
  [requesterTicketListApiDataSelector, (_, ticketId: string) => ticketId],
  (ticketEntity, ticketId): ChatButtonsProp => {
    if (!ticketEntity) return []
    const ticket = requesterTicketEntityAdapter
      .getSelectors()
      .selectById(ticketEntity, ticketId)
    if (!ticket || !ticket.lastReplyMessage) return []
    if (!hasButtonsMessage(ticket.lastReplyMessage.message)) return []

    if (ticket.lastReplyMessage.message.type === 'buttons') {
      return ticket.lastReplyMessage.message.buttons.map((b) => [
        {
          key: b.key,
          label: b.label,
          url: b.url,
          type: b.type,
          data: b.data,
        },
      ])
    }
    return [
      ticket.lastReplyMessage.message.buttons.map((b) => ({
        key: b.key,
        label: b.label,
        url: b.url,
        type: b.type,
      })),
    ]
  }
)
export const isRenderButtonGroup = createSelector(
  [requesterTicketListApiDataSelector, (_, ticketId: string) => ticketId],
  (ticketEntity, ticketId): boolean => {
    if (!ticketEntity) return false
    const ticket = requesterTicketEntityAdapter
      .getSelectors()
      .selectById(ticketEntity, ticketId)
    if (!ticket || !ticket.lastReplyMessage) return false
    return hasButtonsMessage(ticket.lastReplyMessage.message)
  }
)
