import { grpc } from '@improbable-eng/grpc-web'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk, RootState } from '../../app/store'
import {
  GetImageTokenRequest,
  GetImageTokenResponse,
} from '../../proto/image_token_pb'
import { ImageToken } from '../../proto/image_token_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from '../auth/authSlice'

export interface ImageTokenState {
  id: number
  itemId?: string
  token?: string
}

export const imageTokensAdapter = createEntityAdapter<ImageTokenState>({
  selectId: (token) => token.id,
})

export const imageTokensSlice = createSlice({
  name: 'imageTokens',
  initialState: imageTokensAdapter.getInitialState(),
  reducers: {
    onTokenIn(state, action: PayloadAction<ImageTokenState>) {
      imageTokensAdapter.upsertOne(state, action.payload)
    },
    fetchImageTokenOnEnd(
      state,
      action: PayloadAction<{ code: number; message: string }>
    ) {
      if (action.payload.code !== grpc.Code.OK) {
        consoleErrorWithAirbrake(action.payload.message)
      }
    },
  },
})

export const fetchImageToken = (id: number, itemID: string): AppThunk => {
  return async (dispatch, getState, { grpcClient }) => {
    // create request
    const req = new GetImageTokenRequest()
    req.setItemId(itemID)

    // create client
    const client = grpcClient<GetImageTokenRequest, GetImageTokenResponse>(
      ImageToken.GetToken
    )
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      dispatch(
        onTokenIn({
          id: id,
          itemId: itemID,
          token: message.getToken(),
        })
      )
    })

    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(fetchImageTokenOnEnd({ code, message }))
    })

    client.start(meta)
    client.send(req)
    client.finishSend()
  }
}

export const { onTokenIn, fetchImageTokenOnEnd } = imageTokensSlice.actions

export const imageTokensSelectors = imageTokensAdapter.getSelectors(
  (state: RootState) => {
    return state.imageTokens
  }
)

export default imageTokensSlice.reducer
