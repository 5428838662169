import * as RTK from '@reduxjs/toolkit'
import addMonth from 'date-fns/fp/addMonths'
import formatISO from 'date-fns/fp/formatISO'
import setDate from 'date-fns/fp/setDate'
import { compose } from 'redux'

export type State = {
  startDate: string
  endDate: string
  assigneeUserIds: string[]
  isOpen: boolean
}
const initialState: State = {
  startDate: compose(formatISO, setDate(1))(new Date()),
  endDate: compose(formatISO, setDate(0), addMonth(1))(new Date()),
  assigneeUserIds: [],
  isOpen: false,
}
const slice = RTK.createSlice({
  name: 'dashboardFilterSidepanel',
  initialState,
  reducers: {
    updateStateDate: (state, action: RTK.PayloadAction<Date | null>) => {
      if (action.payload === null) return
      state.startDate = action.payload.toISOString()
    },
    updateEndDate: (state, action: RTK.PayloadAction<Date | null>) => {
      if (action.payload === null) return
      state.endDate = action.payload.toISOString()
    },
    updateAssigneeUserIds: (
      state,
      action: RTK.PayloadAction<string[] | null>
    ) => {
      if (action.payload === null) return
      state.assigneeUserIds = action.payload
    },
    clearDate: (state) => {
      state.startDate = initialState.startDate
      state.endDate = initialState.endDate
    },
    clearAssigneeUserIds: (state) => {
      state.assigneeUserIds = initialState.assigneeUserIds
    },
    open: (state) => {
      state.isOpen = true
    },
    close: (state) => {
      state.isOpen = false
    },
  },
})

export const { reducer, actions } = slice
