import { SearchIcon } from '@fluentui/react-icons-northstar'
import { Flex, Input, Text } from '@fluentui/react-northstar'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { CustomFieldType } from '../../../consts'
import ticket_custom_field from '../../../proto/ticket_custom_field_pb'
import { ellipsis } from '../../../utils'
import {
  deleteOneCustomFieldFilter,
  setCustomTextAreaFieldValuesFilter,
  setCustomTextFieldValuesFilter,
} from '../ticketSlice'
import styles from './TicketCustomFieldFilter.module.css'

type Props = {
  customField: ticket_custom_field.TicketCustomField.AsObject
}

const TicketCustomFieldValueTextFilter: React.FC<Props> = ({ customField }) => {
  const ticketState = useSelector((state: RootState) => state.ticket)
  const isExistIdInFilter =
    customField.type === CustomFieldType.text
      ? ticketState.filters.customFieldValues.textFilters[customField.id] !=
        null
      : ticketState.filters.customFieldValues.textAreaFilters[customField.id] !=
        null
  let fieldFilterValue = ''
  if (customField.type === CustomFieldType.text) {
    fieldFilterValue = isExistIdInFilter
      ? ticketState.filters.customFieldValues.textFilters[customField.id]
      : ''
  } else {
    fieldFilterValue = isExistIdInFilter
      ? ticketState.filters.customFieldValues.textAreaFilters[customField.id]
      : ''
  }
  const dispatch = useDispatch()
  const [inputText, setInputText] = useState(fieldFilterValue)
  const isMountRef = useRef(true)

  useEffect(() => {
    if (isMountRef.current) {
      isMountRef.current = false
      return
    }
    const timeOutId = setTimeout(() => {
      if (customField.type === CustomFieldType.textarea) {
        inputText === ''
          ? dispatch(
              deleteOneCustomFieldFilter({
                customFieldId: customField.id,
                customFieldType: customField.type,
              })
            )
          : dispatch(
              setCustomTextAreaFieldValuesFilter({
                customFieldId: customField.id,
                value: inputText,
              })
            )
      } else {
        inputText === ''
          ? dispatch(
              deleteOneCustomFieldFilter({
                customFieldId: customField.id,
                customFieldType: customField.type,
              })
            )
          : dispatch(
              setCustomTextFieldValuesFilter({
                customFieldId: customField.id,
                value: inputText,
              })
            )
      }
    }, 500)
    return () => clearTimeout(timeOutId)
  }, [dispatch, inputText, customField])
  return (
    <Flex column hAlign="start" gap="gap.smaller">
      <Text content="絞り込み" weight="bold" className={styles.headingText} />
      <Input
        icon={<SearchIcon />}
        placeholder={`${ellipsis(customField.name, 10)}を検索`}
        value={inputText}
        onChange={(_, e) => {
          setInputText(String(e?.value))
        }}
        onBlur={() => {
          if (inputText === '') return
          if (customField.type === CustomFieldType.textarea) {
            dispatch(
              setCustomTextAreaFieldValuesFilter({
                customFieldId: customField.id,
                value: inputText,
              })
            )
          } else {
            dispatch(
              setCustomTextFieldValuesFilter({
                customFieldId: customField.id,
                value: inputText,
              })
            )
          }
        }}
      />
    </Flex>
  )
}

export default TicketCustomFieldValueTextFilter
