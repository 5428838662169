import { Flex } from '@fluentui/react-northstar'
import * as microsoftTeams from '@microsoft/teams-js'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { RootState } from '../../app/store'
import { TicketCustomField } from '../../proto/ticket_custom_field_pb'
import { consoleErrorWithAirbrake, getQueryParamsByKey } from '../../utils'
import { customFieldSelector } from './customFieldSlice'
import { fetchRequester } from './requestersSlice'
import styles from './TicketFilterPills.module.css'
import AssignedUsersFilterPill from './ticketFilterPills/AssignedUsersFilterPill'
import CreatedAtFilterPill, {
  isFilteredByCreatedAtEnd,
  isFilteredByCreatedAtStart,
} from './ticketFilterPills/CreatedAtFilterPill'
import FaqStatusFilterPill from './ticketFilterPills/FaqStatusFilterPill'
import KeyIdFilterPill from './ticketFilterPills/KeyIdFilterPill'
import NoFilterPill from './ticketFilterPills/NoFilterPill'
import RequestersFilterPill from './ticketFilterPills/RequestersFilterPill'
import ResetFilterButton from './ticketFilterPills/ResetFilterButton'
import StatusFilterPill from './ticketFilterPills/StatusFilterPill'
import SubjectFilterPill from './ticketFilterPills/SubjectFilterPill'
import TicketCustomCheckBoxFilterPill from './ticketFilterPills/TicketCustomCheckBoxFilterPill'
import TicketCustomDateFilterPill from './ticketFilterPills/TicketCustomDateFilterPill'
import TicketCustomNumberFilterPill from './ticketFilterPills/TicketCustomNumberFilterPill'
import TicketCustomSelectFilterPill from './ticketFilterPills/TicketCustomSelectFilterPill'
import TicketCustomTextAreaFilterPill from './ticketFilterPills/TicketCustomTextAreaFilterPill'
import TicketCustomTextFilterPill from './ticketFilterPills/TicketCustomTextFilterPill'
import {
  TicketState,
  fetchDefaultConfig,
  setInitialFilter,
} from './ticketSlice'

const TicketFilterPills: React.FC = () => {
  const dispatch = useDispatch()
  const ticketState = useSelector((state: RootState) => state.ticket)
  const memberState = useSelector((state: RootState) => state.member)
  const usersState = useSelector((state: RootState) => state.users)
  const customFields = useSelector(customFieldSelector.selectAll)

  useEffect(() => {
    const meId = usersState.meId
    /* meId is not fetched */
    if (meId == null) return

    microsoftTeams.pages
      .getConfig()
      .then((settings) => {
        const configId = getQueryParamsByKey(settings.contentUrl, 'tab_id')[0]
        if (configId != null && !ticketState.hasFetchedDefaultTabConfig) {
          dispatch(fetchDefaultConfig(configId, customFields, meId))
          return
        }
        // カスタムフィルタ設定が存在しない場合はステータス：新規・対応中、担当者：未設定、ログインユーザーを指定して初期化する
        if (!ticketState.hasFetchedDefaultTabConfig) {
          dispatch(setInitialFilter({ meId }))
          return
        }
      })
      .catch((e) => {
        consoleErrorWithAirbrake(`failed getting config: ${e}`)
      })
  }, [
    dispatch,
    customFields,
    usersState.meId,
    ticketState.hasFetchedDefaultTabConfig,
  ])

  useEffect(() => {
    dispatch(fetchRequester(ticketState.filters.requesterUserIds))
  }, [dispatch, ticketState.filters.requesterUserIds])

  const renderPills = (
    ticketState: TicketState,
    customFields: TicketCustomField.AsObject[]
  ) => {
    return (
      <>
        {isFilteredByStatus(ticketState) && <StatusFilterPill />}
        {isFilteredByAssignedUser(ticketState) && <AssignedUsersFilterPill />}
        {isFilteredByRequester(ticketState) && <RequestersFilterPill />}
        {isFilteredByKeyId(ticketState) && <KeyIdFilterPill />}
        {isFilteredBySubject(ticketState) && <SubjectFilterPill />}
        {isFilteredByCreatedAt(
          ticketState.filters.startHour,
          ticketState.filters.startMinutes,
          ticketState.filters.endHour,
          ticketState.filters.endMinutes,
          ticketState.filters.startDate,
          ticketState.filters.endDate
        ) && <CreatedAtFilterPill />}
        {isFilteredByTicketCustomSelectField(ticketState) && (
          <TicketCustomSelectFilterPill customFields={customFields} />
        )}
        {isFilteredByTicketCustomTextField(ticketState) && (
          <TicketCustomTextFilterPill customFields={customFields} />
        )}
        {isFilteredByTicketCustomTextAreaField(ticketState) && (
          <TicketCustomTextAreaFilterPill customFields={customFields} />
        )}
        {isFilteredByTicketCustomDateField(ticketState) && (
          <TicketCustomDateFilterPill customFields={customFields} />
        )}
        {isFilteredByTicketCustomNumberField(ticketState) && (
          <TicketCustomNumberFilterPill customFields={customFields} />
        )}
        {isFilteredByTicketCustomCheckBoxField(ticketState) && (
          <TicketCustomCheckBoxFilterPill customFields={customFields} />
        )}
        {isFilteredByFaqStatus(ticketState) && <FaqStatusFilterPill />}
      </>
    )
  }

  if (memberState.error != null) {
    return <Redirect to={{ pathname: '/errors/unknown' }} />
  }

  if (!ticketState.error.isEmpty() && !ticketState.error.isNetworkErr()) {
    return <Redirect to={{ pathname: '/errors/unknown' }} />
  }

  return (
    <Flex className={styles.filterPillsContainer} gap="gap.smaller" wrap>
      {ticketState.hasFetchedDefaultTabConfig && isFiltered(ticketState) ? (
        renderPills(ticketState, customFields)
      ) : (
        <NoFilterPill />
      )}
      <ResetFilterButton />
    </Flex>
  )
}

export default TicketFilterPills

const isFiltered = (ticketState: TicketState) => {
  return (
    isFilteredByAssignedUser(ticketState) ||
    isFilteredByRequester(ticketState) ||
    isFilteredBySubject(ticketState) ||
    isFilteredByKeyId(ticketState) ||
    isFilteredByStatus(ticketState) ||
    isFilteredByCreatedAt(
      ticketState.filters.startHour,
      ticketState.filters.startMinutes,
      ticketState.filters.endHour,
      ticketState.filters.endMinutes,
      ticketState.filters.startDate,
      ticketState.filters.endDate
    ) ||
    isFilteredByFaqStatus(ticketState) ||
    isFilteredByTicketCustomField(ticketState)
  )
}

const isFilteredByAssignedUser = (ticketState: TicketState) => {
  return (
    ticketState.filters.assignedUserIds != null &&
    ticketState.filters.assignedUserIds.length > 0
  )
}

const isFilteredByRequester = (ticketState: TicketState) => {
  return ticketState.filters.requesterUserIds.length > 0
}

const isFilteredByStatus = (ticketState: TicketState) => {
  return ticketState.filters.statuses.length > 0
}

const isFilteredByKeyId = (ticketState: TicketState) => {
  return ticketState.filters.keyId !== 0
}

const isFilteredBySubject = (ticketState: TicketState) => {
  return ticketState.filters.subject !== ''
}

const isFilteredByCreatedAt = (
  startHour: string[],
  startMinutes: string[],
  endHour: string[],
  endMinutes: string[],
  startDate?: Date,
  endDate?: Date
) => {
  return (
    isFilteredByCreatedAtStart(startHour, startMinutes, startDate) ||
    isFilteredByCreatedAtEnd(endHour, endMinutes, endDate)
  )
}

const isFilteredByTicketCustomField = (ticketState: TicketState) => {
  return (
    isFilteredByTicketCustomCheckBoxField(ticketState) ||
    isFilteredByTicketCustomDateField(ticketState) ||
    isFilteredByTicketCustomNumberField(ticketState) ||
    isFilteredByTicketCustomTextField(ticketState) ||
    isFilteredByTicketCustomTextAreaField(ticketState) ||
    isFilteredByTicketCustomSelectField(ticketState)
  )
}

const isFilteredByTicketCustomCheckBoxField = (ticketState: TicketState) => {
  return (
    Object.keys(ticketState.filters.customFieldValues.checkBoxFilters).length >
    0
  )
}

const isFilteredByTicketCustomDateField = (ticketState: TicketState) => {
  return (
    Object.keys(ticketState.filters.customFieldValues.dateFilters).length > 0
  )
}

const isFilteredByTicketCustomNumberField = (ticketState: TicketState) => {
  return (
    Object.keys(ticketState.filters.customFieldValues.numberFilters).length > 0
  )
}

const isFilteredByTicketCustomTextField = (ticketState: TicketState) => {
  return (
    Object.keys(ticketState.filters.customFieldValues.textFilters).length > 0
  )
}

const isFilteredByTicketCustomTextAreaField = (ticketState: TicketState) => {
  return (
    Object.keys(ticketState.filters.customFieldValues.textAreaFilters).length >
    0
  )
}

const isFilteredByTicketCustomSelectField = (ticketState: TicketState) => {
  const ticketCustomFieldIds = Object.keys(
    ticketState.filters.customFieldValues.selectFilters
  )
  return (
    ticketCustomFieldIds.length > 0 &&
    ticketCustomFieldIds.some((id) => {
      return ticketState.filters.customFieldValues.selectFilters[id].length > 0
    })
  )
}

const isFilteredByFaqStatus = (ticketState: TicketState) => {
  return ticketState.filters.faqStatuses.length > 0
}
