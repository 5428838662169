import { Flex, Text } from '@fluentui/react-northstar'
import React from 'react'

const FaqDeleted: React.FC = () => {
  return (
    <Flex column gap="gap.large">
      <Text align={'center'}>作成したFAQは削除されました</Text>
    </Flex>
  )
}

export default FaqDeleted
