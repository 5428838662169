import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../app/store'

const stateSelector = (state: RootState) => state.requesterAuth

export const azureADToken = createSelector(
  stateSelector,
  (state) => state.azureADToken
)

export const fileUploadAvailable = createSelector(
  stateSelector,
  (state): boolean => state.entity.availableFeatures.fileAttachment
)

export const appId = createSelector(
  stateSelector,
  (state): string => state.decodedSsoToken?.aud ?? ''
)
