import { Avatar, Text } from '@fluentui/react-northstar'
import { EntityId } from '@reduxjs/toolkit'
import * as React from 'react'

import RequesterChatFileList, {
  FileProp,
} from '../../components/RequesterChatFileList'
import styles from './style.module.css'

type Props = {
  id: EntityId
  name: string
  avatarImage: string
  time: string
  files: FileProp[]
  isOwnMessage?: boolean
  isSimpleMessage?: boolean
  isHighlighted?: boolean
  renderButtonGroups?: () => React.ReactElement
  renderChatText: () => React.ReactElement
}

// Thanks for god, this is a non-state component, keep it non-state
const RequesterTicketReplyItemPresenter = React.forwardRef<
  HTMLDivElement,
  Props
>((props, ref) => {
  return (
    <div
      ref={ref}
      className={`${styles.container} ${
        props.isHighlighted
          ? styles.highlightBorder
          : props.isOwnMessage
          ? styles.containerBorder
          : ``
      }`}
      key={props.id}
    >
      <div className={styles.avatarBox}>
        {!props.isSimpleMessage && (
          <Avatar size="medium" image={props.avatarImage} />
        )}
      </div>
      <div className={styles.messageBox}>
        {!props.isSimpleMessage && (
          <div className={styles.infoRow}>
            <Text size="small" weight="bold">
              {props.name}
            </Text>
            <Text className={styles.infoRowTime} size="small">
              {props.time}
            </Text>
          </div>
        )}
        <div className={styles.messageBody}>
          {props.renderChatText()}
          {props.renderButtonGroups?.()}
          {props.files.length > 0 && (
            <RequesterChatFileList files={props.files} />
          )}
        </div>
      </div>
    </div>
  )
})

export default RequesterTicketReplyItemPresenter
