import {
  Dropdown as BaseDropdown,
  DropdownItemProps as BaseDropdownItemProps,
  DropdownProps as BaseDropdownProps,
  Flex,
  Skeleton,
} from '@fluentui/react-northstar'
import React from 'react'

interface DropdownProps extends BaseDropdownProps {
  value?: string[] | null
  initializing?: boolean
  items?: DropdownItemProps[]
  onValueChange?: (values: string[]) => void
  onListOpenChange?: () => void
  onInputFocus?: () => void
  onClick?: () => void
}

interface DropdownItemProps extends BaseDropdownItemProps {
  value: string
}

export const Dropdown: React.FC<DropdownProps> = ({
  value,
  initializing,
  items,
  onValueChange,
  onInputFocus,
  onClick,
  ...props
}) => {
  if (initializing === true) {
    return (
      <Skeleton animation="pulse">
        <Flex gap="gap.medium" column>
          <Skeleton.Input fluid />
        </Flex>
      </Skeleton>
    )
  }

  return (
    <BaseDropdown
      {...props}
      value={
        value != null ? items?.filter((i) => value.includes(i.value)) : null
      }
      items={items}
      // fluentUIでの型をうまく扱えずanyを残している
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(_, data: any) => {
        let values: string[] = []
        if (data.multiple) {
          values = data.value.map((d: { value: string }) => d.value)
        } else {
          if (data.value != null) {
            values = [data.value?.value]
          }
        }
        if (onValueChange != null) onValueChange(values)
      }}
      onFocus={() => {
        if (onInputFocus != null) onInputFocus()
      }}
      onClick={() => {
        if (onClick != null) onClick()
      }}
    />
  )
}
