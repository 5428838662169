import * as React from 'react'

import styles from './styles.module.css'

const RequesterNoTickets: React.FC = () => {
  return (
    <div className={styles.outer}>
      <img
        src="/assets/no-tickets.svg"
        alt="no-tickets"
        className={styles.image}
      />
    </div>
  )
}

export default RequesterNoTickets
