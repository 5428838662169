// package: 
// file: proto/image_token.proto

var proto_image_token_pb = require("../proto/image_token_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ImageToken = (function () {
  function ImageToken() {}
  ImageToken.serviceName = "ImageToken";
  return ImageToken;
}());

ImageToken.GetToken = {
  methodName: "GetToken",
  service: ImageToken,
  requestStream: false,
  responseStream: false,
  requestType: proto_image_token_pb.GetImageTokenRequest,
  responseType: proto_image_token_pb.GetImageTokenResponse
};

exports.ImageToken = ImageToken;

function ImageTokenClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ImageTokenClient.prototype.getToken = function getToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImageToken.GetToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ImageTokenClient = ImageTokenClient;

