// source: proto/activity_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.ActivityMessage', null, global);
goog.exportSymbol('proto.ActivityMessagePlanAttachment', null, global);
goog.exportSymbol('proto.ActivityMessagePlanDetail', null, global);
goog.exportSymbol('proto.ActivityMessagePlanPublishLog', null, global);
goog.exportSymbol('proto.ActivityMessageReceiver', null, global);
goog.exportSymbol('proto.ActivityMessageReceiver.GroupCase', null, global);
goog.exportSymbol('proto.ActivityMessageReceiverGroup', null, global);
goog.exportSymbol('proto.ActivityMessageReceiverUsers', null, global);
goog.exportSymbol('proto.ActivityMessageReceiversPreviewRequest', null, global);
goog.exportSymbol('proto.ActivityMessageReceiversPreviewResponse', null, global);
goog.exportSymbol('proto.ActivityMessageSchedule', null, global);
goog.exportSymbol('proto.ActivityMessageSchedule.ScheduleCase', null, global);
goog.exportSymbol('proto.ActivityMessageStatus', null, global);
goog.exportSymbol('proto.AvailableActivityMessageRequest', null, global);
goog.exportSymbol('proto.AvailableActivityMessageResponse', null, global);
goog.exportSymbol('proto.CreateActivityMessagePlanAttachment', null, global);
goog.exportSymbol('proto.CreateActivityMessagePlanRequest', null, global);
goog.exportSymbol('proto.CreateActivityMessagePlanResponse', null, global);
goog.exportSymbol('proto.DeleteActivityMessagePlanRequest', null, global);
goog.exportSymbol('proto.DeleteActivityMessagePlanResponse', null, global);
goog.exportSymbol('proto.EditActivityMessagePlanRequest', null, global);
goog.exportSymbol('proto.EditActivityMessagePlanResponse', null, global);
goog.exportSymbol('proto.GetActivityMessagePlanRequest', null, global);
goog.exportSymbol('proto.GetActivityMessagePlanResponse', null, global);
goog.exportSymbol('proto.ListActivityMessageRequest', null, global);
goog.exportSymbol('proto.ListActivityMessageResponse', null, global);
goog.exportSymbol('proto.NotificationAvailableMember', null, global);
goog.exportSymbol('proto.NotificationAvailableMembersRequest', null, global);
goog.exportSymbol('proto.NotificationAvailableMembersResponse', null, global);
goog.exportSymbol('proto.PublishActivityMessagePlanRequest', null, global);
goog.exportSymbol('proto.PublishActivityMessagePlanResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetActivityMessagePlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetActivityMessagePlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetActivityMessagePlanRequest.displayName = 'proto.GetActivityMessagePlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetActivityMessagePlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetActivityMessagePlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetActivityMessagePlanResponse.displayName = 'proto.GetActivityMessagePlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListActivityMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListActivityMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListActivityMessageRequest.displayName = 'proto.ListActivityMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListActivityMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListActivityMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.ListActivityMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListActivityMessageResponse.displayName = 'proto.ListActivityMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateActivityMessagePlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CreateActivityMessagePlanRequest.repeatedFields_, null);
};
goog.inherits(proto.CreateActivityMessagePlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateActivityMessagePlanRequest.displayName = 'proto.CreateActivityMessagePlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateActivityMessagePlanAttachment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateActivityMessagePlanAttachment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateActivityMessagePlanAttachment.displayName = 'proto.CreateActivityMessagePlanAttachment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateActivityMessagePlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateActivityMessagePlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateActivityMessagePlanResponse.displayName = 'proto.CreateActivityMessagePlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PublishActivityMessagePlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PublishActivityMessagePlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PublishActivityMessagePlanRequest.displayName = 'proto.PublishActivityMessagePlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PublishActivityMessagePlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PublishActivityMessagePlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PublishActivityMessagePlanResponse.displayName = 'proto.PublishActivityMessagePlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EditActivityMessagePlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EditActivityMessagePlanRequest.repeatedFields_, null);
};
goog.inherits(proto.EditActivityMessagePlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EditActivityMessagePlanRequest.displayName = 'proto.EditActivityMessagePlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteActivityMessagePlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteActivityMessagePlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteActivityMessagePlanRequest.displayName = 'proto.DeleteActivityMessagePlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteActivityMessagePlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteActivityMessagePlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteActivityMessagePlanResponse.displayName = 'proto.DeleteActivityMessagePlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EditActivityMessagePlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EditActivityMessagePlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EditActivityMessagePlanResponse.displayName = 'proto.EditActivityMessagePlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AvailableActivityMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AvailableActivityMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AvailableActivityMessageRequest.displayName = 'proto.AvailableActivityMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AvailableActivityMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AvailableActivityMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AvailableActivityMessageResponse.displayName = 'proto.AvailableActivityMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ActivityMessageReceiversPreviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ActivityMessageReceiversPreviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ActivityMessageReceiversPreviewRequest.displayName = 'proto.ActivityMessageReceiversPreviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ActivityMessageReceiversPreviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ActivityMessageReceiversPreviewResponse.repeatedFields_, null);
};
goog.inherits(proto.ActivityMessageReceiversPreviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ActivityMessageReceiversPreviewResponse.displayName = 'proto.ActivityMessageReceiversPreviewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NotificationAvailableMembersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NotificationAvailableMembersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NotificationAvailableMembersRequest.displayName = 'proto.NotificationAvailableMembersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NotificationAvailableMembersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.NotificationAvailableMembersResponse.repeatedFields_, null);
};
goog.inherits(proto.NotificationAvailableMembersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NotificationAvailableMembersResponse.displayName = 'proto.NotificationAvailableMembersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ActivityMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ActivityMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ActivityMessage.displayName = 'proto.ActivityMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ActivityMessageReceiver = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.ActivityMessageReceiver.oneofGroups_);
};
goog.inherits(proto.ActivityMessageReceiver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ActivityMessageReceiver.displayName = 'proto.ActivityMessageReceiver';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ActivityMessageReceiverGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ActivityMessageReceiverGroup.repeatedFields_, null);
};
goog.inherits(proto.ActivityMessageReceiverGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ActivityMessageReceiverGroup.displayName = 'proto.ActivityMessageReceiverGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ActivityMessageReceiverUsers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ActivityMessageReceiverUsers.repeatedFields_, null);
};
goog.inherits(proto.ActivityMessageReceiverUsers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ActivityMessageReceiverUsers.displayName = 'proto.ActivityMessageReceiverUsers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ActivityMessageSchedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.ActivityMessageSchedule.oneofGroups_);
};
goog.inherits(proto.ActivityMessageSchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ActivityMessageSchedule.displayName = 'proto.ActivityMessageSchedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ActivityMessagePlanPublishLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ActivityMessagePlanPublishLog.repeatedFields_, null);
};
goog.inherits(proto.ActivityMessagePlanPublishLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ActivityMessagePlanPublishLog.displayName = 'proto.ActivityMessagePlanPublishLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ActivityMessagePlanAttachment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ActivityMessagePlanAttachment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ActivityMessagePlanAttachment.displayName = 'proto.ActivityMessagePlanAttachment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ActivityMessagePlanDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ActivityMessagePlanDetail.repeatedFields_, null);
};
goog.inherits(proto.ActivityMessagePlanDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ActivityMessagePlanDetail.displayName = 'proto.ActivityMessagePlanDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NotificationAvailableMember = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NotificationAvailableMember, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NotificationAvailableMember.displayName = 'proto.NotificationAvailableMember';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetActivityMessagePlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetActivityMessagePlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetActivityMessagePlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetActivityMessagePlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetActivityMessagePlanRequest}
 */
proto.GetActivityMessagePlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetActivityMessagePlanRequest;
  return proto.GetActivityMessagePlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetActivityMessagePlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetActivityMessagePlanRequest}
 */
proto.GetActivityMessagePlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetActivityMessagePlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetActivityMessagePlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetActivityMessagePlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetActivityMessagePlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.GetActivityMessagePlanRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GetActivityMessagePlanRequest} returns this
 */
proto.GetActivityMessagePlanRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetActivityMessagePlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetActivityMessagePlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetActivityMessagePlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetActivityMessagePlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityMessageDetail: (f = msg.getActivityMessageDetail()) && proto.ActivityMessagePlanDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetActivityMessagePlanResponse}
 */
proto.GetActivityMessagePlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetActivityMessagePlanResponse;
  return proto.GetActivityMessagePlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetActivityMessagePlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetActivityMessagePlanResponse}
 */
proto.GetActivityMessagePlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ActivityMessagePlanDetail;
      reader.readMessage(value,proto.ActivityMessagePlanDetail.deserializeBinaryFromReader);
      msg.setActivityMessageDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetActivityMessagePlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetActivityMessagePlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetActivityMessagePlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetActivityMessagePlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityMessageDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ActivityMessagePlanDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional ActivityMessagePlanDetail activity_message_detail = 1;
 * @return {?proto.ActivityMessagePlanDetail}
 */
proto.GetActivityMessagePlanResponse.prototype.getActivityMessageDetail = function() {
  return /** @type{?proto.ActivityMessagePlanDetail} */ (
    jspb.Message.getWrapperField(this, proto.ActivityMessagePlanDetail, 1));
};


/**
 * @param {?proto.ActivityMessagePlanDetail|undefined} value
 * @return {!proto.GetActivityMessagePlanResponse} returns this
*/
proto.GetActivityMessagePlanResponse.prototype.setActivityMessageDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetActivityMessagePlanResponse} returns this
 */
proto.GetActivityMessagePlanResponse.prototype.clearActivityMessageDetail = function() {
  return this.setActivityMessageDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetActivityMessagePlanResponse.prototype.hasActivityMessageDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListActivityMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ListActivityMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListActivityMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListActivityMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListActivityMessageRequest}
 */
proto.ListActivityMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListActivityMessageRequest;
  return proto.ListActivityMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListActivityMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListActivityMessageRequest}
 */
proto.ListActivityMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListActivityMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListActivityMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListActivityMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListActivityMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 limit = 1;
 * @return {number}
 */
proto.ListActivityMessageRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ListActivityMessageRequest} returns this
 */
proto.ListActivityMessageRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 page = 2;
 * @return {number}
 */
proto.ListActivityMessageRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ListActivityMessageRequest} returns this
 */
proto.ListActivityMessageRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ListActivityMessageResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListActivityMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ListActivityMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListActivityMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListActivityMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityMessagesList: jspb.Message.toObjectList(msg.getActivityMessagesList(),
    proto.ActivityMessage.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListActivityMessageResponse}
 */
proto.ListActivityMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListActivityMessageResponse;
  return proto.ListActivityMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListActivityMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListActivityMessageResponse}
 */
proto.ListActivityMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ActivityMessage;
      reader.readMessage(value,proto.ActivityMessage.deserializeBinaryFromReader);
      msg.addActivityMessages(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListActivityMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListActivityMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListActivityMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListActivityMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ActivityMessage.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * repeated ActivityMessage activity_messages = 1;
 * @return {!Array<!proto.ActivityMessage>}
 */
proto.ListActivityMessageResponse.prototype.getActivityMessagesList = function() {
  return /** @type{!Array<!proto.ActivityMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ActivityMessage, 1));
};


/**
 * @param {!Array<!proto.ActivityMessage>} value
 * @return {!proto.ListActivityMessageResponse} returns this
*/
proto.ListActivityMessageResponse.prototype.setActivityMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ActivityMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ActivityMessage}
 */
proto.ListActivityMessageResponse.prototype.addActivityMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ActivityMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListActivityMessageResponse} returns this
 */
proto.ListActivityMessageResponse.prototype.clearActivityMessagesList = function() {
  return this.setActivityMessagesList([]);
};


/**
 * optional uint32 total = 2;
 * @return {number}
 */
proto.ListActivityMessageResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ListActivityMessageResponse} returns this
 */
proto.ListActivityMessageResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CreateActivityMessagePlanRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateActivityMessagePlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateActivityMessagePlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateActivityMessagePlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateActivityMessagePlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    body: jspb.Message.getFieldWithDefault(msg, 1, ""),
    receiver: (f = msg.getReceiver()) && proto.ActivityMessageReceiver.toObject(includeInstance, f),
    schedule: (f = msg.getSchedule()) && proto.ActivityMessageSchedule.toObject(includeInstance, f),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    proto.CreateActivityMessagePlanAttachment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateActivityMessagePlanRequest}
 */
proto.CreateActivityMessagePlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateActivityMessagePlanRequest;
  return proto.CreateActivityMessagePlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateActivityMessagePlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateActivityMessagePlanRequest}
 */
proto.CreateActivityMessagePlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 2:
      var value = new proto.ActivityMessageReceiver;
      reader.readMessage(value,proto.ActivityMessageReceiver.deserializeBinaryFromReader);
      msg.setReceiver(value);
      break;
    case 3:
      var value = new proto.ActivityMessageSchedule;
      reader.readMessage(value,proto.ActivityMessageSchedule.deserializeBinaryFromReader);
      msg.setSchedule(value);
      break;
    case 4:
      var value = new proto.CreateActivityMessagePlanAttachment;
      reader.readMessage(value,proto.CreateActivityMessagePlanAttachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateActivityMessagePlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateActivityMessagePlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateActivityMessagePlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateActivityMessagePlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReceiver();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ActivityMessageReceiver.serializeBinaryToWriter
    );
  }
  f = message.getSchedule();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ActivityMessageSchedule.serializeBinaryToWriter
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.CreateActivityMessagePlanAttachment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string body = 1;
 * @return {string}
 */
proto.CreateActivityMessagePlanRequest.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateActivityMessagePlanRequest} returns this
 */
proto.CreateActivityMessagePlanRequest.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ActivityMessageReceiver receiver = 2;
 * @return {?proto.ActivityMessageReceiver}
 */
proto.CreateActivityMessagePlanRequest.prototype.getReceiver = function() {
  return /** @type{?proto.ActivityMessageReceiver} */ (
    jspb.Message.getWrapperField(this, proto.ActivityMessageReceiver, 2));
};


/**
 * @param {?proto.ActivityMessageReceiver|undefined} value
 * @return {!proto.CreateActivityMessagePlanRequest} returns this
*/
proto.CreateActivityMessagePlanRequest.prototype.setReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateActivityMessagePlanRequest} returns this
 */
proto.CreateActivityMessagePlanRequest.prototype.clearReceiver = function() {
  return this.setReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateActivityMessagePlanRequest.prototype.hasReceiver = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ActivityMessageSchedule schedule = 3;
 * @return {?proto.ActivityMessageSchedule}
 */
proto.CreateActivityMessagePlanRequest.prototype.getSchedule = function() {
  return /** @type{?proto.ActivityMessageSchedule} */ (
    jspb.Message.getWrapperField(this, proto.ActivityMessageSchedule, 3));
};


/**
 * @param {?proto.ActivityMessageSchedule|undefined} value
 * @return {!proto.CreateActivityMessagePlanRequest} returns this
*/
proto.CreateActivityMessagePlanRequest.prototype.setSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateActivityMessagePlanRequest} returns this
 */
proto.CreateActivityMessagePlanRequest.prototype.clearSchedule = function() {
  return this.setSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateActivityMessagePlanRequest.prototype.hasSchedule = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated CreateActivityMessagePlanAttachment attachments = 4;
 * @return {!Array<!proto.CreateActivityMessagePlanAttachment>}
 */
proto.CreateActivityMessagePlanRequest.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.CreateActivityMessagePlanAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.CreateActivityMessagePlanAttachment, 4));
};


/**
 * @param {!Array<!proto.CreateActivityMessagePlanAttachment>} value
 * @return {!proto.CreateActivityMessagePlanRequest} returns this
*/
proto.CreateActivityMessagePlanRequest.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.CreateActivityMessagePlanAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.CreateActivityMessagePlanAttachment}
 */
proto.CreateActivityMessagePlanRequest.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.CreateActivityMessagePlanAttachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateActivityMessagePlanRequest} returns this
 */
proto.CreateActivityMessagePlanRequest.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateActivityMessagePlanAttachment.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateActivityMessagePlanAttachment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateActivityMessagePlanAttachment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateActivityMessagePlanAttachment.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    itemId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    mimeType: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateActivityMessagePlanAttachment}
 */
proto.CreateActivityMessagePlanAttachment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateActivityMessagePlanAttachment;
  return proto.CreateActivityMessagePlanAttachment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateActivityMessagePlanAttachment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateActivityMessagePlanAttachment}
 */
proto.CreateActivityMessagePlanAttachment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMimeType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateActivityMessagePlanAttachment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateActivityMessagePlanAttachment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateActivityMessagePlanAttachment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateActivityMessagePlanAttachment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMimeType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.CreateActivityMessagePlanAttachment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateActivityMessagePlanAttachment} returns this
 */
proto.CreateActivityMessagePlanAttachment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.CreateActivityMessagePlanAttachment.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateActivityMessagePlanAttachment} returns this
 */
proto.CreateActivityMessagePlanAttachment.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.CreateActivityMessagePlanAttachment.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateActivityMessagePlanAttachment} returns this
 */
proto.CreateActivityMessagePlanAttachment.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string item_id = 4;
 * @return {string}
 */
proto.CreateActivityMessagePlanAttachment.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateActivityMessagePlanAttachment} returns this
 */
proto.CreateActivityMessagePlanAttachment.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string mime_type = 5;
 * @return {string}
 */
proto.CreateActivityMessagePlanAttachment.prototype.getMimeType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateActivityMessagePlanAttachment} returns this
 */
proto.CreateActivityMessagePlanAttachment.prototype.setMimeType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateActivityMessagePlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateActivityMessagePlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateActivityMessagePlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateActivityMessagePlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityMessage: (f = msg.getActivityMessage()) && proto.ActivityMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateActivityMessagePlanResponse}
 */
proto.CreateActivityMessagePlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateActivityMessagePlanResponse;
  return proto.CreateActivityMessagePlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateActivityMessagePlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateActivityMessagePlanResponse}
 */
proto.CreateActivityMessagePlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ActivityMessage;
      reader.readMessage(value,proto.ActivityMessage.deserializeBinaryFromReader);
      msg.setActivityMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateActivityMessagePlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateActivityMessagePlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateActivityMessagePlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateActivityMessagePlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ActivityMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ActivityMessage activity_message = 1;
 * @return {?proto.ActivityMessage}
 */
proto.CreateActivityMessagePlanResponse.prototype.getActivityMessage = function() {
  return /** @type{?proto.ActivityMessage} */ (
    jspb.Message.getWrapperField(this, proto.ActivityMessage, 1));
};


/**
 * @param {?proto.ActivityMessage|undefined} value
 * @return {!proto.CreateActivityMessagePlanResponse} returns this
*/
proto.CreateActivityMessagePlanResponse.prototype.setActivityMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateActivityMessagePlanResponse} returns this
 */
proto.CreateActivityMessagePlanResponse.prototype.clearActivityMessage = function() {
  return this.setActivityMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateActivityMessagePlanResponse.prototype.hasActivityMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PublishActivityMessagePlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.PublishActivityMessagePlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PublishActivityMessagePlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PublishActivityMessagePlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PublishActivityMessagePlanRequest}
 */
proto.PublishActivityMessagePlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PublishActivityMessagePlanRequest;
  return proto.PublishActivityMessagePlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PublishActivityMessagePlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PublishActivityMessagePlanRequest}
 */
proto.PublishActivityMessagePlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PublishActivityMessagePlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PublishActivityMessagePlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PublishActivityMessagePlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PublishActivityMessagePlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.PublishActivityMessagePlanRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PublishActivityMessagePlanRequest} returns this
 */
proto.PublishActivityMessagePlanRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PublishActivityMessagePlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.PublishActivityMessagePlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PublishActivityMessagePlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PublishActivityMessagePlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PublishActivityMessagePlanResponse}
 */
proto.PublishActivityMessagePlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PublishActivityMessagePlanResponse;
  return proto.PublishActivityMessagePlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PublishActivityMessagePlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PublishActivityMessagePlanResponse}
 */
proto.PublishActivityMessagePlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PublishActivityMessagePlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PublishActivityMessagePlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PublishActivityMessagePlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PublishActivityMessagePlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EditActivityMessagePlanRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EditActivityMessagePlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.EditActivityMessagePlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EditActivityMessagePlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EditActivityMessagePlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    body: jspb.Message.getFieldWithDefault(msg, 2, ""),
    receiver: (f = msg.getReceiver()) && proto.ActivityMessageReceiver.toObject(includeInstance, f),
    createAttachmentsList: jspb.Message.toObjectList(msg.getCreateAttachmentsList(),
    proto.CreateActivityMessagePlanAttachment.toObject, includeInstance),
    deleteAttachmentIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EditActivityMessagePlanRequest}
 */
proto.EditActivityMessagePlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EditActivityMessagePlanRequest;
  return proto.EditActivityMessagePlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EditActivityMessagePlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EditActivityMessagePlanRequest}
 */
proto.EditActivityMessagePlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 3:
      var value = new proto.ActivityMessageReceiver;
      reader.readMessage(value,proto.ActivityMessageReceiver.deserializeBinaryFromReader);
      msg.setReceiver(value);
      break;
    case 4:
      var value = new proto.CreateActivityMessagePlanAttachment;
      reader.readMessage(value,proto.CreateActivityMessagePlanAttachment.deserializeBinaryFromReader);
      msg.addCreateAttachments(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeleteAttachmentIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EditActivityMessagePlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EditActivityMessagePlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EditActivityMessagePlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EditActivityMessagePlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReceiver();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ActivityMessageReceiver.serializeBinaryToWriter
    );
  }
  f = message.getCreateAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.CreateActivityMessagePlanAttachment.serializeBinaryToWriter
    );
  }
  f = message.getDeleteAttachmentIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      5,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.EditActivityMessagePlanRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.EditActivityMessagePlanRequest} returns this
 */
proto.EditActivityMessagePlanRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string body = 2;
 * @return {string}
 */
proto.EditActivityMessagePlanRequest.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EditActivityMessagePlanRequest} returns this
 */
proto.EditActivityMessagePlanRequest.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ActivityMessageReceiver receiver = 3;
 * @return {?proto.ActivityMessageReceiver}
 */
proto.EditActivityMessagePlanRequest.prototype.getReceiver = function() {
  return /** @type{?proto.ActivityMessageReceiver} */ (
    jspb.Message.getWrapperField(this, proto.ActivityMessageReceiver, 3));
};


/**
 * @param {?proto.ActivityMessageReceiver|undefined} value
 * @return {!proto.EditActivityMessagePlanRequest} returns this
*/
proto.EditActivityMessagePlanRequest.prototype.setReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EditActivityMessagePlanRequest} returns this
 */
proto.EditActivityMessagePlanRequest.prototype.clearReceiver = function() {
  return this.setReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EditActivityMessagePlanRequest.prototype.hasReceiver = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated CreateActivityMessagePlanAttachment create_attachments = 4;
 * @return {!Array<!proto.CreateActivityMessagePlanAttachment>}
 */
proto.EditActivityMessagePlanRequest.prototype.getCreateAttachmentsList = function() {
  return /** @type{!Array<!proto.CreateActivityMessagePlanAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.CreateActivityMessagePlanAttachment, 4));
};


/**
 * @param {!Array<!proto.CreateActivityMessagePlanAttachment>} value
 * @return {!proto.EditActivityMessagePlanRequest} returns this
*/
proto.EditActivityMessagePlanRequest.prototype.setCreateAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.CreateActivityMessagePlanAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.CreateActivityMessagePlanAttachment}
 */
proto.EditActivityMessagePlanRequest.prototype.addCreateAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.CreateActivityMessagePlanAttachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EditActivityMessagePlanRequest} returns this
 */
proto.EditActivityMessagePlanRequest.prototype.clearCreateAttachmentsList = function() {
  return this.setCreateAttachmentsList([]);
};


/**
 * repeated uint32 delete_attachment_ids = 5;
 * @return {!Array<number>}
 */
proto.EditActivityMessagePlanRequest.prototype.getDeleteAttachmentIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.EditActivityMessagePlanRequest} returns this
 */
proto.EditActivityMessagePlanRequest.prototype.setDeleteAttachmentIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.EditActivityMessagePlanRequest} returns this
 */
proto.EditActivityMessagePlanRequest.prototype.addDeleteAttachmentIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EditActivityMessagePlanRequest} returns this
 */
proto.EditActivityMessagePlanRequest.prototype.clearDeleteAttachmentIdsList = function() {
  return this.setDeleteAttachmentIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteActivityMessagePlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteActivityMessagePlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteActivityMessagePlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteActivityMessagePlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteActivityMessagePlanRequest}
 */
proto.DeleteActivityMessagePlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteActivityMessagePlanRequest;
  return proto.DeleteActivityMessagePlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteActivityMessagePlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteActivityMessagePlanRequest}
 */
proto.DeleteActivityMessagePlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteActivityMessagePlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteActivityMessagePlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteActivityMessagePlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteActivityMessagePlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.DeleteActivityMessagePlanRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeleteActivityMessagePlanRequest} returns this
 */
proto.DeleteActivityMessagePlanRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteActivityMessagePlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteActivityMessagePlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteActivityMessagePlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteActivityMessagePlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteActivityMessagePlanResponse}
 */
proto.DeleteActivityMessagePlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteActivityMessagePlanResponse;
  return proto.DeleteActivityMessagePlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteActivityMessagePlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteActivityMessagePlanResponse}
 */
proto.DeleteActivityMessagePlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteActivityMessagePlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteActivityMessagePlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteActivityMessagePlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteActivityMessagePlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EditActivityMessagePlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.EditActivityMessagePlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EditActivityMessagePlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EditActivityMessagePlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EditActivityMessagePlanResponse}
 */
proto.EditActivityMessagePlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EditActivityMessagePlanResponse;
  return proto.EditActivityMessagePlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EditActivityMessagePlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EditActivityMessagePlanResponse}
 */
proto.EditActivityMessagePlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EditActivityMessagePlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EditActivityMessagePlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EditActivityMessagePlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EditActivityMessagePlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AvailableActivityMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AvailableActivityMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AvailableActivityMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AvailableActivityMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AvailableActivityMessageRequest}
 */
proto.AvailableActivityMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AvailableActivityMessageRequest;
  return proto.AvailableActivityMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AvailableActivityMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AvailableActivityMessageRequest}
 */
proto.AvailableActivityMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AvailableActivityMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AvailableActivityMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AvailableActivityMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AvailableActivityMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AvailableActivityMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AvailableActivityMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AvailableActivityMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AvailableActivityMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AvailableActivityMessageResponse}
 */
proto.AvailableActivityMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AvailableActivityMessageResponse;
  return proto.AvailableActivityMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AvailableActivityMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AvailableActivityMessageResponse}
 */
proto.AvailableActivityMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAvailable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AvailableActivityMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AvailableActivityMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AvailableActivityMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AvailableActivityMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsAvailable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_available = 1;
 * @return {boolean}
 */
proto.AvailableActivityMessageResponse.prototype.getIsAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AvailableActivityMessageResponse} returns this
 */
proto.AvailableActivityMessageResponse.prototype.setIsAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ActivityMessageReceiversPreviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ActivityMessageReceiversPreviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ActivityMessageReceiversPreviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessageReceiversPreviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    planId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ActivityMessageReceiversPreviewRequest}
 */
proto.ActivityMessageReceiversPreviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ActivityMessageReceiversPreviewRequest;
  return proto.ActivityMessageReceiversPreviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ActivityMessageReceiversPreviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ActivityMessageReceiversPreviewRequest}
 */
proto.ActivityMessageReceiversPreviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPlanId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ActivityMessageReceiversPreviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ActivityMessageReceiversPreviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ActivityMessageReceiversPreviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessageReceiversPreviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 plan_id = 1;
 * @return {number}
 */
proto.ActivityMessageReceiversPreviewRequest.prototype.getPlanId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ActivityMessageReceiversPreviewRequest} returns this
 */
proto.ActivityMessageReceiversPreviewRequest.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ActivityMessageReceiversPreviewResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ActivityMessageReceiversPreviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ActivityMessageReceiversPreviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ActivityMessageReceiversPreviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessageReceiversPreviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    cachedTime: (f = msg.getCachedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ActivityMessageReceiversPreviewResponse}
 */
proto.ActivityMessageReceiversPreviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ActivityMessageReceiversPreviewResponse;
  return proto.ActivityMessageReceiversPreviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ActivityMessageReceiversPreviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ActivityMessageReceiversPreviewResponse}
 */
proto.ActivityMessageReceiversPreviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCachedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ActivityMessageReceiversPreviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ActivityMessageReceiversPreviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ActivityMessageReceiversPreviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessageReceiversPreviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCachedTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string user_ids = 1;
 * @return {!Array<string>}
 */
proto.ActivityMessageReceiversPreviewResponse.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ActivityMessageReceiversPreviewResponse} returns this
 */
proto.ActivityMessageReceiversPreviewResponse.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ActivityMessageReceiversPreviewResponse} returns this
 */
proto.ActivityMessageReceiversPreviewResponse.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ActivityMessageReceiversPreviewResponse} returns this
 */
proto.ActivityMessageReceiversPreviewResponse.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};


/**
 * optional google.protobuf.Timestamp cached_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ActivityMessageReceiversPreviewResponse.prototype.getCachedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ActivityMessageReceiversPreviewResponse} returns this
*/
proto.ActivityMessageReceiversPreviewResponse.prototype.setCachedTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ActivityMessageReceiversPreviewResponse} returns this
 */
proto.ActivityMessageReceiversPreviewResponse.prototype.clearCachedTime = function() {
  return this.setCachedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActivityMessageReceiversPreviewResponse.prototype.hasCachedTime = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NotificationAvailableMembersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.NotificationAvailableMembersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NotificationAvailableMembersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NotificationAvailableMembersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NotificationAvailableMembersRequest}
 */
proto.NotificationAvailableMembersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NotificationAvailableMembersRequest;
  return proto.NotificationAvailableMembersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NotificationAvailableMembersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NotificationAvailableMembersRequest}
 */
proto.NotificationAvailableMembersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NotificationAvailableMembersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NotificationAvailableMembersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NotificationAvailableMembersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NotificationAvailableMembersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.NotificationAvailableMembersRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.NotificationAvailableMembersRequest} returns this
 */
proto.NotificationAvailableMembersRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.NotificationAvailableMembersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NotificationAvailableMembersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.NotificationAvailableMembersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NotificationAvailableMembersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NotificationAvailableMembersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    membersList: jspb.Message.toObjectList(msg.getMembersList(),
    proto.NotificationAvailableMember.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NotificationAvailableMembersResponse}
 */
proto.NotificationAvailableMembersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NotificationAvailableMembersResponse;
  return proto.NotificationAvailableMembersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NotificationAvailableMembersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NotificationAvailableMembersResponse}
 */
proto.NotificationAvailableMembersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NotificationAvailableMember;
      reader.readMessage(value,proto.NotificationAvailableMember.deserializeBinaryFromReader);
      msg.addMembers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NotificationAvailableMembersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NotificationAvailableMembersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NotificationAvailableMembersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NotificationAvailableMembersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.NotificationAvailableMember.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NotificationAvailableMember members = 1;
 * @return {!Array<!proto.NotificationAvailableMember>}
 */
proto.NotificationAvailableMembersResponse.prototype.getMembersList = function() {
  return /** @type{!Array<!proto.NotificationAvailableMember>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.NotificationAvailableMember, 1));
};


/**
 * @param {!Array<!proto.NotificationAvailableMember>} value
 * @return {!proto.NotificationAvailableMembersResponse} returns this
*/
proto.NotificationAvailableMembersResponse.prototype.setMembersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.NotificationAvailableMember=} opt_value
 * @param {number=} opt_index
 * @return {!proto.NotificationAvailableMember}
 */
proto.NotificationAvailableMembersResponse.prototype.addMembers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.NotificationAvailableMember, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.NotificationAvailableMembersResponse} returns this
 */
proto.NotificationAvailableMembersResponse.prototype.clearMembersList = function() {
  return this.setMembersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ActivityMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.ActivityMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ActivityMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    body: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    schedule: (f = msg.getSchedule()) && proto.ActivityMessageSchedule.toObject(includeInstance, f),
    receiver: (f = msg.getReceiver()) && proto.ActivityMessageReceiver.toObject(includeInstance, f),
    creatorId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 8, ""),
    sentat: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ActivityMessage}
 */
proto.ActivityMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ActivityMessage;
  return proto.ActivityMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ActivityMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ActivityMessage}
 */
proto.ActivityMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 3:
      var value = /** @type {!proto.ActivityMessageStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.ActivityMessageSchedule;
      reader.readMessage(value,proto.ActivityMessageSchedule.deserializeBinaryFromReader);
      msg.setSchedule(value);
      break;
    case 5:
      var value = new proto.ActivityMessageReceiver;
      reader.readMessage(value,proto.ActivityMessageReceiver.deserializeBinaryFromReader);
      msg.setReceiver(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatorId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSentat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ActivityMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ActivityMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ActivityMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSchedule();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.ActivityMessageSchedule.serializeBinaryToWriter
    );
  }
  f = message.getReceiver();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.ActivityMessageReceiver.serializeBinaryToWriter
    );
  }
  f = message.getCreatorId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.ActivityMessage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ActivityMessage} returns this
 */
proto.ActivityMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string body = 2;
 * @return {string}
 */
proto.ActivityMessage.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActivityMessage} returns this
 */
proto.ActivityMessage.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ActivityMessageStatus status = 3;
 * @return {!proto.ActivityMessageStatus}
 */
proto.ActivityMessage.prototype.getStatus = function() {
  return /** @type {!proto.ActivityMessageStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ActivityMessageStatus} value
 * @return {!proto.ActivityMessage} returns this
 */
proto.ActivityMessage.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ActivityMessageSchedule schedule = 4;
 * @return {?proto.ActivityMessageSchedule}
 */
proto.ActivityMessage.prototype.getSchedule = function() {
  return /** @type{?proto.ActivityMessageSchedule} */ (
    jspb.Message.getWrapperField(this, proto.ActivityMessageSchedule, 4));
};


/**
 * @param {?proto.ActivityMessageSchedule|undefined} value
 * @return {!proto.ActivityMessage} returns this
*/
proto.ActivityMessage.prototype.setSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ActivityMessage} returns this
 */
proto.ActivityMessage.prototype.clearSchedule = function() {
  return this.setSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActivityMessage.prototype.hasSchedule = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ActivityMessageReceiver receiver = 5;
 * @return {?proto.ActivityMessageReceiver}
 */
proto.ActivityMessage.prototype.getReceiver = function() {
  return /** @type{?proto.ActivityMessageReceiver} */ (
    jspb.Message.getWrapperField(this, proto.ActivityMessageReceiver, 5));
};


/**
 * @param {?proto.ActivityMessageReceiver|undefined} value
 * @return {!proto.ActivityMessage} returns this
*/
proto.ActivityMessage.prototype.setReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ActivityMessage} returns this
 */
proto.ActivityMessage.prototype.clearReceiver = function() {
  return this.setReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActivityMessage.prototype.hasReceiver = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string creator_id = 6;
 * @return {string}
 */
proto.ActivityMessage.prototype.getCreatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActivityMessage} returns this
 */
proto.ActivityMessage.prototype.setCreatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string created_at = 7;
 * @return {string}
 */
proto.ActivityMessage.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActivityMessage} returns this
 */
proto.ActivityMessage.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string updated_at = 8;
 * @return {string}
 */
proto.ActivityMessage.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActivityMessage} returns this
 */
proto.ActivityMessage.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string sentAt = 9;
 * @return {string}
 */
proto.ActivityMessage.prototype.getSentat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActivityMessage} returns this
 */
proto.ActivityMessage.prototype.setSentat = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ActivityMessage} returns this
 */
proto.ActivityMessage.prototype.clearSentat = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActivityMessage.prototype.hasSentat = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ActivityMessageReceiver.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.ActivityMessageReceiver.GroupCase = {
  GROUP_NOT_SET: 0,
  ALL: 1,
  GROUP_IDS: 2,
  USER_IDS: 3
};

/**
 * @return {proto.ActivityMessageReceiver.GroupCase}
 */
proto.ActivityMessageReceiver.prototype.getGroupCase = function() {
  return /** @type {proto.ActivityMessageReceiver.GroupCase} */(jspb.Message.computeOneofCase(this, proto.ActivityMessageReceiver.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ActivityMessageReceiver.prototype.toObject = function(opt_includeInstance) {
  return proto.ActivityMessageReceiver.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ActivityMessageReceiver} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessageReceiver.toObject = function(includeInstance, msg) {
  var f, obj = {
    all: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    groupIds: (f = msg.getGroupIds()) && proto.ActivityMessageReceiverGroup.toObject(includeInstance, f),
    userIds: (f = msg.getUserIds()) && proto.ActivityMessageReceiverUsers.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ActivityMessageReceiver}
 */
proto.ActivityMessageReceiver.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ActivityMessageReceiver;
  return proto.ActivityMessageReceiver.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ActivityMessageReceiver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ActivityMessageReceiver}
 */
proto.ActivityMessageReceiver.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAll(value);
      break;
    case 2:
      var value = new proto.ActivityMessageReceiverGroup;
      reader.readMessage(value,proto.ActivityMessageReceiverGroup.deserializeBinaryFromReader);
      msg.setGroupIds(value);
      break;
    case 3:
      var value = new proto.ActivityMessageReceiverUsers;
      reader.readMessage(value,proto.ActivityMessageReceiverUsers.deserializeBinaryFromReader);
      msg.setUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ActivityMessageReceiver.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ActivityMessageReceiver.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ActivityMessageReceiver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessageReceiver.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getGroupIds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ActivityMessageReceiverGroup.serializeBinaryToWriter
    );
  }
  f = message.getUserIds();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ActivityMessageReceiverUsers.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool all = 1;
 * @return {boolean}
 */
proto.ActivityMessageReceiver.prototype.getAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ActivityMessageReceiver} returns this
 */
proto.ActivityMessageReceiver.prototype.setAll = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.ActivityMessageReceiver.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ActivityMessageReceiver} returns this
 */
proto.ActivityMessageReceiver.prototype.clearAll = function() {
  return jspb.Message.setOneofField(this, 1, proto.ActivityMessageReceiver.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActivityMessageReceiver.prototype.hasAll = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ActivityMessageReceiverGroup group_ids = 2;
 * @return {?proto.ActivityMessageReceiverGroup}
 */
proto.ActivityMessageReceiver.prototype.getGroupIds = function() {
  return /** @type{?proto.ActivityMessageReceiverGroup} */ (
    jspb.Message.getWrapperField(this, proto.ActivityMessageReceiverGroup, 2));
};


/**
 * @param {?proto.ActivityMessageReceiverGroup|undefined} value
 * @return {!proto.ActivityMessageReceiver} returns this
*/
proto.ActivityMessageReceiver.prototype.setGroupIds = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.ActivityMessageReceiver.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ActivityMessageReceiver} returns this
 */
proto.ActivityMessageReceiver.prototype.clearGroupIds = function() {
  return this.setGroupIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActivityMessageReceiver.prototype.hasGroupIds = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ActivityMessageReceiverUsers user_ids = 3;
 * @return {?proto.ActivityMessageReceiverUsers}
 */
proto.ActivityMessageReceiver.prototype.getUserIds = function() {
  return /** @type{?proto.ActivityMessageReceiverUsers} */ (
    jspb.Message.getWrapperField(this, proto.ActivityMessageReceiverUsers, 3));
};


/**
 * @param {?proto.ActivityMessageReceiverUsers|undefined} value
 * @return {!proto.ActivityMessageReceiver} returns this
*/
proto.ActivityMessageReceiver.prototype.setUserIds = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.ActivityMessageReceiver.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ActivityMessageReceiver} returns this
 */
proto.ActivityMessageReceiver.prototype.clearUserIds = function() {
  return this.setUserIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActivityMessageReceiver.prototype.hasUserIds = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ActivityMessageReceiverGroup.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ActivityMessageReceiverGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.ActivityMessageReceiverGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ActivityMessageReceiverGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessageReceiverGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ActivityMessageReceiverGroup}
 */
proto.ActivityMessageReceiverGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ActivityMessageReceiverGroup;
  return proto.ActivityMessageReceiverGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ActivityMessageReceiverGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ActivityMessageReceiverGroup}
 */
proto.ActivityMessageReceiverGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ActivityMessageReceiverGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ActivityMessageReceiverGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ActivityMessageReceiverGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessageReceiverGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string group_ids = 1;
 * @return {!Array<string>}
 */
proto.ActivityMessageReceiverGroup.prototype.getGroupIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ActivityMessageReceiverGroup} returns this
 */
proto.ActivityMessageReceiverGroup.prototype.setGroupIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ActivityMessageReceiverGroup} returns this
 */
proto.ActivityMessageReceiverGroup.prototype.addGroupIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ActivityMessageReceiverGroup} returns this
 */
proto.ActivityMessageReceiverGroup.prototype.clearGroupIdsList = function() {
  return this.setGroupIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ActivityMessageReceiverUsers.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ActivityMessageReceiverUsers.prototype.toObject = function(opt_includeInstance) {
  return proto.ActivityMessageReceiverUsers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ActivityMessageReceiverUsers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessageReceiverUsers.toObject = function(includeInstance, msg) {
  var f, obj = {
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ActivityMessageReceiverUsers}
 */
proto.ActivityMessageReceiverUsers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ActivityMessageReceiverUsers;
  return proto.ActivityMessageReceiverUsers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ActivityMessageReceiverUsers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ActivityMessageReceiverUsers}
 */
proto.ActivityMessageReceiverUsers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ActivityMessageReceiverUsers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ActivityMessageReceiverUsers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ActivityMessageReceiverUsers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessageReceiverUsers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string user_ids = 1;
 * @return {!Array<string>}
 */
proto.ActivityMessageReceiverUsers.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ActivityMessageReceiverUsers} returns this
 */
proto.ActivityMessageReceiverUsers.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ActivityMessageReceiverUsers} returns this
 */
proto.ActivityMessageReceiverUsers.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ActivityMessageReceiverUsers} returns this
 */
proto.ActivityMessageReceiverUsers.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ActivityMessageSchedule.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.ActivityMessageSchedule.ScheduleCase = {
  SCHEDULE_NOT_SET: 0,
  NOW: 1,
  SCHEDULED: 2,
  REPEAT: 3
};

/**
 * @return {proto.ActivityMessageSchedule.ScheduleCase}
 */
proto.ActivityMessageSchedule.prototype.getScheduleCase = function() {
  return /** @type {proto.ActivityMessageSchedule.ScheduleCase} */(jspb.Message.computeOneofCase(this, proto.ActivityMessageSchedule.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ActivityMessageSchedule.prototype.toObject = function(opt_includeInstance) {
  return proto.ActivityMessageSchedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ActivityMessageSchedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessageSchedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    now: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    scheduled: jspb.Message.getFieldWithDefault(msg, 2, ""),
    repeat: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ActivityMessageSchedule}
 */
proto.ActivityMessageSchedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ActivityMessageSchedule;
  return proto.ActivityMessageSchedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ActivityMessageSchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ActivityMessageSchedule}
 */
proto.ActivityMessageSchedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNow(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScheduled(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ActivityMessageSchedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ActivityMessageSchedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ActivityMessageSchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessageSchedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool now = 1;
 * @return {boolean}
 */
proto.ActivityMessageSchedule.prototype.getNow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ActivityMessageSchedule} returns this
 */
proto.ActivityMessageSchedule.prototype.setNow = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.ActivityMessageSchedule.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ActivityMessageSchedule} returns this
 */
proto.ActivityMessageSchedule.prototype.clearNow = function() {
  return jspb.Message.setOneofField(this, 1, proto.ActivityMessageSchedule.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActivityMessageSchedule.prototype.hasNow = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string scheduled = 2;
 * @return {string}
 */
proto.ActivityMessageSchedule.prototype.getScheduled = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActivityMessageSchedule} returns this
 */
proto.ActivityMessageSchedule.prototype.setScheduled = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.ActivityMessageSchedule.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ActivityMessageSchedule} returns this
 */
proto.ActivityMessageSchedule.prototype.clearScheduled = function() {
  return jspb.Message.setOneofField(this, 2, proto.ActivityMessageSchedule.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActivityMessageSchedule.prototype.hasScheduled = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string repeat = 3;
 * @return {string}
 */
proto.ActivityMessageSchedule.prototype.getRepeat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActivityMessageSchedule} returns this
 */
proto.ActivityMessageSchedule.prototype.setRepeat = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.ActivityMessageSchedule.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ActivityMessageSchedule} returns this
 */
proto.ActivityMessageSchedule.prototype.clearRepeat = function() {
  return jspb.Message.setOneofField(this, 3, proto.ActivityMessageSchedule.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActivityMessageSchedule.prototype.hasRepeat = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ActivityMessagePlanPublishLog.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ActivityMessagePlanPublishLog.prototype.toObject = function(opt_includeInstance) {
  return proto.ActivityMessagePlanPublishLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ActivityMessagePlanPublishLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessagePlanPublishLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    publishedAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    planedReceiverIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    succeedReceiverIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    failedReceiverIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ActivityMessagePlanPublishLog}
 */
proto.ActivityMessagePlanPublishLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ActivityMessagePlanPublishLog;
  return proto.ActivityMessagePlanPublishLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ActivityMessagePlanPublishLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ActivityMessagePlanPublishLog}
 */
proto.ActivityMessagePlanPublishLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublishedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addPlanedReceiverIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSucceedReceiverIds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addFailedReceiverIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ActivityMessagePlanPublishLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ActivityMessagePlanPublishLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ActivityMessagePlanPublishLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessagePlanPublishLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPublishedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlanedReceiverIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSucceedReceiverIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getFailedReceiverIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.ActivityMessagePlanPublishLog.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ActivityMessagePlanPublishLog} returns this
 */
proto.ActivityMessagePlanPublishLog.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string published_at = 2;
 * @return {string}
 */
proto.ActivityMessagePlanPublishLog.prototype.getPublishedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActivityMessagePlanPublishLog} returns this
 */
proto.ActivityMessagePlanPublishLog.prototype.setPublishedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string planed_receiver_ids = 3;
 * @return {!Array<string>}
 */
proto.ActivityMessagePlanPublishLog.prototype.getPlanedReceiverIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ActivityMessagePlanPublishLog} returns this
 */
proto.ActivityMessagePlanPublishLog.prototype.setPlanedReceiverIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ActivityMessagePlanPublishLog} returns this
 */
proto.ActivityMessagePlanPublishLog.prototype.addPlanedReceiverIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ActivityMessagePlanPublishLog} returns this
 */
proto.ActivityMessagePlanPublishLog.prototype.clearPlanedReceiverIdsList = function() {
  return this.setPlanedReceiverIdsList([]);
};


/**
 * repeated string succeed_receiver_ids = 4;
 * @return {!Array<string>}
 */
proto.ActivityMessagePlanPublishLog.prototype.getSucceedReceiverIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ActivityMessagePlanPublishLog} returns this
 */
proto.ActivityMessagePlanPublishLog.prototype.setSucceedReceiverIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ActivityMessagePlanPublishLog} returns this
 */
proto.ActivityMessagePlanPublishLog.prototype.addSucceedReceiverIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ActivityMessagePlanPublishLog} returns this
 */
proto.ActivityMessagePlanPublishLog.prototype.clearSucceedReceiverIdsList = function() {
  return this.setSucceedReceiverIdsList([]);
};


/**
 * repeated string failed_receiver_ids = 5;
 * @return {!Array<string>}
 */
proto.ActivityMessagePlanPublishLog.prototype.getFailedReceiverIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ActivityMessagePlanPublishLog} returns this
 */
proto.ActivityMessagePlanPublishLog.prototype.setFailedReceiverIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ActivityMessagePlanPublishLog} returns this
 */
proto.ActivityMessagePlanPublishLog.prototype.addFailedReceiverIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ActivityMessagePlanPublishLog} returns this
 */
proto.ActivityMessagePlanPublishLog.prototype.clearFailedReceiverIdsList = function() {
  return this.setFailedReceiverIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ActivityMessagePlanAttachment.prototype.toObject = function(opt_includeInstance) {
  return proto.ActivityMessagePlanAttachment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ActivityMessagePlanAttachment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessagePlanAttachment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    itemId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    mimeType: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ActivityMessagePlanAttachment}
 */
proto.ActivityMessagePlanAttachment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ActivityMessagePlanAttachment;
  return proto.ActivityMessagePlanAttachment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ActivityMessagePlanAttachment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ActivityMessagePlanAttachment}
 */
proto.ActivityMessagePlanAttachment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMimeType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ActivityMessagePlanAttachment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ActivityMessagePlanAttachment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ActivityMessagePlanAttachment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessagePlanAttachment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMimeType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.ActivityMessagePlanAttachment.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ActivityMessagePlanAttachment} returns this
 */
proto.ActivityMessagePlanAttachment.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ActivityMessagePlanAttachment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActivityMessagePlanAttachment} returns this
 */
proto.ActivityMessagePlanAttachment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.ActivityMessagePlanAttachment.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActivityMessagePlanAttachment} returns this
 */
proto.ActivityMessagePlanAttachment.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string group_id = 4;
 * @return {string}
 */
proto.ActivityMessagePlanAttachment.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActivityMessagePlanAttachment} returns this
 */
proto.ActivityMessagePlanAttachment.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string item_id = 5;
 * @return {string}
 */
proto.ActivityMessagePlanAttachment.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActivityMessagePlanAttachment} returns this
 */
proto.ActivityMessagePlanAttachment.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string mime_type = 6;
 * @return {string}
 */
proto.ActivityMessagePlanAttachment.prototype.getMimeType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActivityMessagePlanAttachment} returns this
 */
proto.ActivityMessagePlanAttachment.prototype.setMimeType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ActivityMessagePlanDetail.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ActivityMessagePlanDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.ActivityMessagePlanDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ActivityMessagePlanDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessagePlanDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityMessage: (f = msg.getActivityMessage()) && proto.ActivityMessage.toObject(includeInstance, f),
    latestPublishLog: (f = msg.getLatestPublishLog()) && proto.ActivityMessagePlanPublishLog.toObject(includeInstance, f),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    proto.ActivityMessagePlanAttachment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ActivityMessagePlanDetail}
 */
proto.ActivityMessagePlanDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ActivityMessagePlanDetail;
  return proto.ActivityMessagePlanDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ActivityMessagePlanDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ActivityMessagePlanDetail}
 */
proto.ActivityMessagePlanDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ActivityMessage;
      reader.readMessage(value,proto.ActivityMessage.deserializeBinaryFromReader);
      msg.setActivityMessage(value);
      break;
    case 2:
      var value = new proto.ActivityMessagePlanPublishLog;
      reader.readMessage(value,proto.ActivityMessagePlanPublishLog.deserializeBinaryFromReader);
      msg.setLatestPublishLog(value);
      break;
    case 3:
      var value = new proto.ActivityMessagePlanAttachment;
      reader.readMessage(value,proto.ActivityMessagePlanAttachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ActivityMessagePlanDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ActivityMessagePlanDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ActivityMessagePlanDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActivityMessagePlanDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ActivityMessage.serializeBinaryToWriter
    );
  }
  f = message.getLatestPublishLog();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ActivityMessagePlanPublishLog.serializeBinaryToWriter
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ActivityMessagePlanAttachment.serializeBinaryToWriter
    );
  }
};


/**
 * optional ActivityMessage activity_message = 1;
 * @return {?proto.ActivityMessage}
 */
proto.ActivityMessagePlanDetail.prototype.getActivityMessage = function() {
  return /** @type{?proto.ActivityMessage} */ (
    jspb.Message.getWrapperField(this, proto.ActivityMessage, 1));
};


/**
 * @param {?proto.ActivityMessage|undefined} value
 * @return {!proto.ActivityMessagePlanDetail} returns this
*/
proto.ActivityMessagePlanDetail.prototype.setActivityMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ActivityMessagePlanDetail} returns this
 */
proto.ActivityMessagePlanDetail.prototype.clearActivityMessage = function() {
  return this.setActivityMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActivityMessagePlanDetail.prototype.hasActivityMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ActivityMessagePlanPublishLog latest_publish_log = 2;
 * @return {?proto.ActivityMessagePlanPublishLog}
 */
proto.ActivityMessagePlanDetail.prototype.getLatestPublishLog = function() {
  return /** @type{?proto.ActivityMessagePlanPublishLog} */ (
    jspb.Message.getWrapperField(this, proto.ActivityMessagePlanPublishLog, 2));
};


/**
 * @param {?proto.ActivityMessagePlanPublishLog|undefined} value
 * @return {!proto.ActivityMessagePlanDetail} returns this
*/
proto.ActivityMessagePlanDetail.prototype.setLatestPublishLog = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ActivityMessagePlanDetail} returns this
 */
proto.ActivityMessagePlanDetail.prototype.clearLatestPublishLog = function() {
  return this.setLatestPublishLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActivityMessagePlanDetail.prototype.hasLatestPublishLog = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ActivityMessagePlanAttachment attachments = 3;
 * @return {!Array<!proto.ActivityMessagePlanAttachment>}
 */
proto.ActivityMessagePlanDetail.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.ActivityMessagePlanAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ActivityMessagePlanAttachment, 3));
};


/**
 * @param {!Array<!proto.ActivityMessagePlanAttachment>} value
 * @return {!proto.ActivityMessagePlanDetail} returns this
*/
proto.ActivityMessagePlanDetail.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ActivityMessagePlanAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ActivityMessagePlanAttachment}
 */
proto.ActivityMessagePlanDetail.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ActivityMessagePlanAttachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ActivityMessagePlanDetail} returns this
 */
proto.ActivityMessagePlanDetail.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NotificationAvailableMember.prototype.toObject = function(opt_includeInstance) {
  return proto.NotificationAvailableMember.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NotificationAvailableMember} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NotificationAvailableMember.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NotificationAvailableMember}
 */
proto.NotificationAvailableMember.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NotificationAvailableMember;
  return proto.NotificationAvailableMember.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NotificationAvailableMember} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NotificationAvailableMember}
 */
proto.NotificationAvailableMember.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NotificationAvailableMember.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NotificationAvailableMember.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NotificationAvailableMember} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NotificationAvailableMember.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.NotificationAvailableMember.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.NotificationAvailableMember} returns this
 */
proto.NotificationAvailableMember.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string displayName = 2;
 * @return {string}
 */
proto.NotificationAvailableMember.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.NotificationAvailableMember} returns this
 */
proto.NotificationAvailableMember.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.NotificationAvailableMember.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.NotificationAvailableMember} returns this
 */
proto.NotificationAvailableMember.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool isAvailable = 4;
 * @return {boolean}
 */
proto.NotificationAvailableMember.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.NotificationAvailableMember} returns this
 */
proto.NotificationAvailableMember.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.ActivityMessageStatus = {
  DRAFT: 0,
  SCHEDULED: 1,
  PROCESSING: 2,
  DONE: 3
};

goog.object.extend(exports, proto);
