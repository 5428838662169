import * as React from 'react'

import styles from './styles.module.css'

const RecommendedFaqTicketUnselectedPresenter: React.FC = () => {
  return (
    <div className={styles.container}>
      <span>FAQを選択すると</span>
      <span>対話が表示されます</span>
    </div>
  )
}

export default React.memo(RecommendedFaqTicketUnselectedPresenter)
