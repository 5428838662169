import { Flex, Input, Text } from '@fluentui/react-northstar'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import styles from './TicketDefaultFieldFilter.module.css'
import { setKeyId } from './ticketSlice'

const TicketKeyIdFilter: React.FC = () => {
  const delayTime = 500
  const maxTextLength = 15
  const ticketState = useSelector((state: RootState) => state.ticket)
  const [inputText, setInputText] = useState(ticketState.filters.keyId)
  const dispatch = useDispatch()
  const isMountRef = useRef(true)
  useEffect(() => {
    if (isMountRef.current) {
      isMountRef.current = false
      return
    }
    const timeOutId = setTimeout(
      (text: number) => {
        dispatch(setKeyId({ keyId: text }))
      },
      delayTime,
      inputText
    )
    return () => clearTimeout(timeOutId)
  }, [dispatch, inputText])
  return (
    <Flex column gap="gap.small">
      <Text content="絞り込み" weight="bold" className={styles.headingText} />
      <Input
        fluid
        maxLength={maxTextLength}
        value={inputText}
        placeholder={'IDで絞り込み'}
        type="number"
        onChange={(_, e) => {
          const text = Number(e?.value)
          setInputText(text)
        }}
      />
    </Flex>
  )
}

export default TicketKeyIdFilter
