import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { RootState } from '../../app/store'

export interface Alert {
  id: number
  title?: string
  content?: string
  type?:
    | 'upload_error'
    | 'file_num_exceed'
    | 'custom_field_error'
    | 'custom_field_value_error'
    | 'faq_label_error'
    | 'faq_create_error'
    | 'faq_detail_error'
    | 'success_announcement'
}

export const alertsAdapter = createEntityAdapter<Alert>({
  selectId: (alert) => alert.id,
})

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState: alertsAdapter.getInitialState(),
  reducers: {
    addAlert(state, action: PayloadAction<Alert>) {
      alertsAdapter.addOne(state, {
        id: action.payload.id,
        title: action.payload.title,
        content: action.payload.content,
        type: action.payload.type,
      })
    },
    removeAlert(state, action: PayloadAction<{ id: number }>) {
      alertsAdapter.removeOne(state, action.payload.id)
    },
  },
})

export const { addAlert, removeAlert } = alertsSlice.actions
export default alertsSlice.reducer

export const alertsSelectors = alertsAdapter.getSelectors(
  (state: RootState) => state.alerts
)
