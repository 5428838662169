import { Text } from '@fluentui/react-northstar'
import { AreaChart, Card } from '@tremor/react'
import * as React from 'react'

export type ChartRow = {
  date: string
  ['お問い合わせ']: number
  ['解決済みのお問い合わせ']: number
}
type Props = {
  data: ChartRow[]
}
const DailyTicketsChartPresenter: React.FC<Props> = (props) => {
  return (
    <Card>
      <Text size="small" weight="bold">
        日別のお問い合わせ
      </Text>
      <AreaChart
        data={props.data}
        dataKey="date"
        categories={['お問い合わせ', '解決済みのお問い合わせ']}
        marginTop="mt-8"
      />
    </Card>
  )
}

export default DailyTicketsChartPresenter
