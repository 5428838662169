import * as React from 'react'
import * as ReactRedux from 'react-redux'

import { useGetRequesterConversationsQuery } from '../api'
import * as Selectors from '../selectors'
import { actions } from '../slice'

export const useRequesterConversations = () => {
  const dispatch = ReactRedux.useDispatch()
  const maxUpdatedAt = ReactRedux.useSelector(Selectors.queryArgMaxUpdatedAt)
  const res = useGetRequesterConversationsQuery({ maxUpdatedAt })

  React.useEffect(() => {
    const interval = setInterval(() => {
      dispatch(actions.updateMaxUpdatedAtToNow())
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [dispatch])

  return res
}
