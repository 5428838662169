import * as React from 'react'

import useFullHeight from '../../hooks/useFullHeight'
import styles from './styles.module.css'

type Props = {
  showSidepanel: boolean
  renderHeader: () => React.ReactElement
  renderDashboardCharts: () => React.ReactElement
  renderSidepanel: () => React.ReactElement
}
const DashboardPresenter: React.FC<Props> = (props) => {
  const leftRef = React.useRef(null)
  const leftHeight = useFullHeight(leftRef)

  return (
    <div className={styles.container}>
      <div className={styles.header}>{props.renderHeader()}</div>
      <div className={styles.body}>
        <div
          ref={leftRef}
          style={{ height: leftHeight }}
          className={styles.bodyLeft}
        >
          {props.renderDashboardCharts()}
        </div>
        {props.showSidepanel && (
          <div className={styles.bodyRight}>{props.renderSidepanel()}</div>
        )}
      </div>
    </div>
  )
}

export default DashboardPresenter
