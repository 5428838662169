import { Checkbox, Text } from '@fluentui/react-northstar'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { TicketCustomField } from '../../../proto/ticket_custom_field_pb'
import { updateTicketCustomFieldValue } from '../customFieldValueSlice'
import { useErrorHandler } from './useErrorHandler'

type Props = {
  ticketId: number
  field: TicketCustomField.AsObject
  fieldValue?: number
}

type CheckBoxStyle = {
  borderWidth: string
  fontWeight: string
  color?: string
}

const TicketPropertyCustomCheckboxField: React.FC<Props> = ({
  ticketId,
  field,
  fieldValue,
}) => {
  const dispatch = useDispatch()
  const customFieldValuesState = useSelector(
    (state: RootState) => state.customFieldValue
  )
  const [number, setNumber] = useState(fieldValue)
  const customFieldValueEntity = customFieldValuesState.entities[field.id]
  const hasValue = customFieldValueEntity != null
  useErrorHandler(customFieldValueEntity, field, dispatch, hasValue)
  return (
    <>
      {field.editable ? (
        <Checkbox
          label={field.name}
          labelPosition="start"
          styles={({ theme: { siteVariables } }) => {
            const style: CheckBoxStyle = {
              borderWidth: '0.05rem',
              fontWeight: 'bold',
            }
            if (
              hasValue &&
              customFieldValuesState.entities[field.id]?.isError
            ) {
              style.color = siteVariables.colorScheme.red.foreground
            }
            return style
          }}
          defaultChecked={false}
          checked={Boolean(number)}
          onChange={(_, value) => {
            if (!field.editable) return
            setNumber(Number(value?.checked))
            dispatch(
              updateTicketCustomFieldValue(ticketId, field.id, field.type, [
                { numberValue: Number(value?.checked) },
              ])
            )
          }}
        />
      ) : (
        <>
          <Text weight="bold" content={field.name} />
          <Text content={number ? '✓' : '--'} />
        </>
      )}
    </>
  )
}

export default TicketPropertyCustomCheckboxField
