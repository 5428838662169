import { Filter } from '@cubejs-client/core'
import { useCubeQuery } from '@cubejs-client/react'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as DashboardFilterSidepanelSelectors from '../../../DashboardFilterSidepanel/selectors'
import DailyTicketsChartPresenter, { ChartRow } from './presenter'

const DailyTicketsChart = () => {
  const startDate = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.startDateForQuery
  )
  const endDate = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.endDateForQuery
  )
  const assignedUserIdsFilter: Filter | null = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.assigneeUserIdsFilterForTickets
  )

  const filters: Filter[] | undefined = assignedUserIdsFilter
    ? [assignedUserIdsFilter]
    : undefined

  const query = useCubeQuery({
    measures: ['Tickets.completedTickets', 'Tickets.count'],
    timeDimensions: [
      {
        dimension: 'Tickets.createdAt',
        dateRange: [startDate, endDate],
        granularity: 'day',
      },
    ],
    filters: filters,
    order: {
      'Tickets.createdAt': 'asc',
    },
    timezone: 'Asia/Tokyo',
  })

  const data = React.useMemo<ChartRow[]>(() => {
    return (
      query.resultSet?.tablePivot().map((row) => ({
        ...row,
        date: new Date(
          row['Tickets.createdAt.day'] as string
        ).toLocaleDateString(),
        ['お問い合わせ']: row['Tickets.count'] as number,
        ['解決済みのお問い合わせ']: row['Tickets.completedTickets'] as number,
      })) ?? []
    )
  }, [query.resultSet])

  return <DailyTicketsChartPresenter data={data} />
}

export default React.memo(DailyTicketsChart)
