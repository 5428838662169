import { grpc } from '@improbable-eng/grpc-web'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk, RootState } from '../../app/store'
import image_pb, { GetImageRequest } from '../../proto/image_pb'
import image_pb_service from '../../proto/image_pb_service'
import { grpcClient } from '../../service/grpcClient'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from '../auth/authSlice'
import { BotChannelStorageImageInfo } from './types'

export enum BotChannelStorageState {
  NotFound = 'NotFound',
}

export interface BotChannelStorageImageData extends BotChannelStorageImageInfo {
  data?: string
  dataStatus?: BotChannelStorageState
}

export const botChannelStorageImageAdapter =
  createEntityAdapter<BotChannelStorageImageData>({
    selectId: (u) => u.attachmentId,
  })

export const botChannelStorageImagesSelectors =
  botChannelStorageImageAdapter.getSelectors(
    (state: RootState) => state.botChannelStorageImages
  )

export const botChannelStorageImagesSlice = createSlice({
  name: 'botChannelStorageImages',
  initialState: botChannelStorageImageAdapter.getInitialState(),
  reducers: {
    fetchImageOnStart(
      state,
      action: PayloadAction<{ data: BotChannelStorageImageData }>
    ) {
      botChannelStorageImageAdapter.upsertOne(state, action.payload.data)
    },

    fetchImageOnMessage(
      state,
      action: PayloadAction<{ id: string; data: string }>
    ) {
      botChannelStorageImageAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          data: action.payload.data,
        },
      })
    },
    fetchImageOnEnd(
      state,
      action: PayloadAction<{ code: grpc.Code; error: string; id: string }>
    ) {
      if (action.payload.code === grpc.Code.OK) {
        return
      }

      if (action.payload.code === grpc.Code.NotFound) {
        botChannelStorageImageAdapter.updateOne(state, {
          id: action.payload.id,
          changes: {
            dataStatus: BotChannelStorageState.NotFound,
          },
        })
        return
      }

      consoleErrorWithAirbrake(
        `fetch BotChannelStorageImage failed, error=${action.payload.error}`
      )
    },
  },
})

export const fetchBotChannelStorageImage =
  (attachmentId: string, viewId: string): AppThunk =>
  async (dispatch, getState) => {
    const client = grpcClient<
      image_pb.GetImageRequest,
      image_pb.GetImageResponse
    >(image_pb_service.ImageAPI.GetImage)

    const item = getState().botChannelStorageImages.entities[attachmentId]
    // already existed
    if (item) {
      return
    }

    client.onMessage((message: image_pb.GetImageResponse) => {
      dispatch(
        fetchImageOnMessage({ id: attachmentId, data: message.getData_asB64() })
      )
    })

    client.onEnd((code, error) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(fetchImageOnEnd({ code: code, error: error, id: attachmentId }))
    })

    const req = new GetImageRequest()
    req.setAttachmentId(attachmentId)
    req.setViewId(viewId)

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)
    dispatch(
      fetchImageOnStart({
        data: { attachmentId: attachmentId, viewId: viewId },
      })
    )
    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const { fetchImageOnStart, fetchImageOnMessage, fetchImageOnEnd } =
  botChannelStorageImagesSlice.actions
export default botChannelStorageImagesSlice.reducer
