import { grpc } from '@improbable-eng/grpc-web'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'

import { AppThunk } from '../../app/store'
import tab_added_group_pb from '../../proto/tab_added_group_pb'
import tab_added_group_pb_service from '../../proto/tab_added_group_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from '../auth/authSlice'

export declare type TabAddedGroup = tab_added_group_pb.TabAddedGroup.AsObject

export const tabAddedGroupsAdapter = createEntityAdapter<TabAddedGroup>({
  selectId: (tabAddedGroup) => tabAddedGroup.groupId || '',
})

interface TabAddedGroupState {
  loading: boolean
  error: string | null
}

export const tabAddedGroupsSlice = createSlice({
  name: 'tab_added_groups',
  initialState: tabAddedGroupsAdapter.getInitialState<TabAddedGroupState>({
    loading: false,
    error: null,
  }),
  reducers: {
    fetchTabAddedGroupsStart(state) {
      state.loading = true
      state.error = null
    },
    fetchTabAddedGroupsOnMessage(
      state,
      action: PayloadAction<{
        message: tab_added_group_pb.ListTabAddedGroupResponse.AsObject
      }>
    ) {
      const { message } = action.payload
      tabAddedGroupsAdapter.setAll(state, message.tabAddedGroupsList)
    },
    fetchTabAddedGroupsOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = null
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
    createTabAddedGroupStart(state) {
      state.loading = true
      state.error = null
    },
    createTabAddedGroupOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = null
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
  },
})

export const {
  fetchTabAddedGroupsStart,
  fetchTabAddedGroupsOnMessage,
  fetchTabAddedGroupsOnEnd,
  createTabAddedGroupStart,
  createTabAddedGroupOnEnd,
} = tabAddedGroupsSlice.actions
export default tabAddedGroupsSlice.reducer

export const fetchTabAddedGroups =
  (): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    dispatch(fetchTabAddedGroupsStart())

    const client = grpcClient<
      tab_added_group_pb.ListTabAddedGroupRequest,
      tab_added_group_pb.ListTabAddedGroupResponse
    >(tab_added_group_pb_service.TabAddedGroupAPI.ListTabAddedGroup)
    const req = new tab_added_group_pb.ListTabAddedGroupRequest()

    client.onMessage((message) => {
      dispatch(fetchTabAddedGroupsOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(fetchTabAddedGroupsOnEnd({ code, message }))
    })

    dispatch(fetchTabAddedGroupsStart())
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const createTabAddedGroup =
  (groupId: string): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    dispatch(createTabAddedGroupStart())

    const client = grpcClient<
      tab_added_group_pb.CreateTabAddedGroupRequest,
      Empty
    >(tab_added_group_pb_service.TabAddedGroupAPI.CreateTabAddedGroup)
    const req = new tab_added_group_pb.CreateTabAddedGroupRequest()
    req.setGroupId(groupId)

    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(createTabAddedGroupOnEnd({ code, message }))
    })

    dispatch(fetchTabAddedGroupsStart())
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.start(meta)
    client.send(req)
    client.finishSend()
  }
