import {
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Loader,
  Tooltip,
} from '@fluentui/react-northstar'
import flow from 'lodash/fp/flow'
import * as React from 'react'
import { debounce } from 'throttle-debounce'

import {
  Member,
  memberEntityAdapter,
  useGetMembersByBatchQuery,
} from '../graphApi'
import { useGetAvailableReceiversQuery } from './api'
import { memberNameInDropdownItem } from './lib/memberNameInDropdownItem'
import { availableReceiverEntityAdapter } from './slice'
import { AvailableReceiver } from './types/AvailableReceiver'

type Props = {
  defaultReceiverIDs: string[]
  onChange: (ids: string[]) => void
}
const Index: React.FC<Props> = (props) => {
  const [receiverIDs, setReceiverIDs] = React.useState<string[]>([])
  const [searchQuery, setSearchQuery] = React.useState<string>('')

  const memberSearchQuery = useGetAvailableReceiversQuery(
    {
      query: searchQuery,
    },
    {
      skip: searchQuery === '',
    }
  )
  const selectedMembersQuery = useGetMembersByBatchQuery(
    {
      ids: receiverIDs,
    },
    {
      skip: receiverIDs.length === 0,
    }
  )

  const mergedMembersData = React.useMemo(() => {
    return availableReceiverEntityAdapter.addMany(
      memberSearchQuery.data ??
        availableReceiverEntityAdapter.getInitialState(),
      memberEntityAdapter
        .getSelectors()
        .selectAll(
          selectedMembersQuery.data ?? memberEntityAdapter.getInitialState()
        )
        .map(graphMemberIntoAvailableReceiver)
    )
  }, [memberSearchQuery.data, selectedMembersQuery.data])

  const memberDropdownItems = React.useMemo<DropdownItemProps[]>(() => {
    const members = availableReceiverEntityAdapter
      .getSelectors()
      .selectAll(mergedMembersData)

    return members.map((m) => ({
      header: memberNameInDropdownItem.encode(m.displayName, m.email),
      disabled: !m.isAvailable,
    }))
  }, [mergedMembersData])

  const selectedReceiverNames = React.useMemo<string[]>(() => {
    if (!selectedMembersQuery.data) return []
    const members = memberEntityAdapter
      .getSelectors()
      .selectAll(selectedMembersQuery.data)
    return receiverIDs.map((id) => members.find((m) => m.id === id)?.name ?? '')
  }, [selectedMembersQuery.data, receiverIDs])

  const change = React.useCallback(
    (_: unknown, data: DropdownProps) => {
      const value = data.value as (string | DropdownItemProps)[]

      const ids = value.map(
        flow(
          (item) => {
            // 今回追加になっていないものはstringになっている
            if (typeof item === 'string') {
              return item
            }
            // 追加されたものはメールアドレスがサフィックスされているため取り除く
            return memberNameInDropdownItem.decode(item.header as string)
          },
          (d) =>
            availableReceiverEntityAdapter
              .getSelectors()
              .selectAll(mergedMembersData)
              .find((m) => m.displayName === d)?.id ?? ''
        )
      )

      const uniqueIDs = Array.from(new Set(ids))
      setReceiverIDs(uniqueIDs)
      props.onChange(uniqueIDs)
    },
    [setReceiverIDs, mergedMembersData, props]
  )
  const changeSearchQuery = React.useCallback(
    (_: unknown, data: DropdownProps) => {
      setSearchQuery(data.searchQuery ?? '')
    },
    [setSearchQuery]
  )

  React.useEffect(() => {
    setReceiverIDs(props.defaultReceiverIDs)
  }, [props.defaultReceiverIDs])

  if (selectedMembersQuery.isLoading) return <Loader />

  return (
    <Dropdown
      value={selectedReceiverNames}
      onChange={change}
      onSearchQueryChange={debounce(500, changeSearchQuery)}
      search
      placeholder="メンバーを検索"
      multiple
      items={memberDropdownItems}
      loading={memberSearchQuery.isLoading || memberSearchQuery.isFetching}
      loadingMessage="検索中..."
      renderItem={(SelectItem, p) =>
        p.disabled ? (
          <Tooltip
            trigger={<SelectItem {...p} />}
            content="アプリがインストールされているメンバーのみ選択できます"
          />
        ) : (
          <SelectItem {...p} />
        )
      }
    />
  )
}

export default React.memo(Index)

function graphMemberIntoAvailableReceiver(member: Member): AvailableReceiver {
  return {
    id: member.id,
    email: member.email,
    displayName: member.name,
    isAvailable: true,
  }
}
