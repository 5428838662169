// package: 
// file: proto/ticket_custom_field_value.proto

var proto_ticket_custom_field_value_pb = require("../proto/ticket_custom_field_value_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TicketCustomFieldValueAPI = (function () {
  function TicketCustomFieldValueAPI() {}
  TicketCustomFieldValueAPI.serviceName = "TicketCustomFieldValueAPI";
  return TicketCustomFieldValueAPI;
}());

TicketCustomFieldValueAPI.ListTicketCustomFieldValues = {
  methodName: "ListTicketCustomFieldValues",
  service: TicketCustomFieldValueAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_ticket_custom_field_value_pb.ListTicketCustomFieldValuesRequest,
  responseType: proto_ticket_custom_field_value_pb.ListTicketCustomFieldValuesResponse
};

TicketCustomFieldValueAPI.UpdateTicketCustomFieldValue = {
  methodName: "UpdateTicketCustomFieldValue",
  service: TicketCustomFieldValueAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_ticket_custom_field_value_pb.UpdateTicketCustomFieldValueRequest,
  responseType: proto_ticket_custom_field_value_pb.TicketCustomFieldValue
};

TicketCustomFieldValueAPI.DeleteTicketCustomFieldValue = {
  methodName: "DeleteTicketCustomFieldValue",
  service: TicketCustomFieldValueAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_ticket_custom_field_value_pb.DeleteTicketCustomFieldValueRequest,
  responseType: google_protobuf_empty_pb.Empty
};

exports.TicketCustomFieldValueAPI = TicketCustomFieldValueAPI;

function TicketCustomFieldValueAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TicketCustomFieldValueAPIClient.prototype.listTicketCustomFieldValues = function listTicketCustomFieldValues(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TicketCustomFieldValueAPI.ListTicketCustomFieldValues, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TicketCustomFieldValueAPIClient.prototype.updateTicketCustomFieldValue = function updateTicketCustomFieldValue(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TicketCustomFieldValueAPI.UpdateTicketCustomFieldValue, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TicketCustomFieldValueAPIClient.prototype.deleteTicketCustomFieldValue = function deleteTicketCustomFieldValue(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TicketCustomFieldValueAPI.DeleteTicketCustomFieldValue, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TicketCustomFieldValueAPIClient = TicketCustomFieldValueAPIClient;

