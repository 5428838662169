import { SearchIcon } from '@fluentui/react-icons-northstar'
import { Flex, Input } from '@fluentui/react-northstar'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { CustomFieldType } from '../../../consts'
import ticket_custom_field from '../../../proto/ticket_custom_field_pb'
import style from '../TabConfig.module.css'
import {
  isCustomTextValueFilter,
  setCustomTextAreaFilterValue,
  setCustomTextFilterValue,
} from '../tabConfigSlice'

type Props = {
  customField: ticket_custom_field.TicketCustomField.AsObject
  tabConfigFilterIndex: number
}

const TicketCustomFieldValueTextFilter: React.FC<Props> = ({
  customField,
  tabConfigFilterIndex,
}) => {
  const tabConfigState = useSelector((state: RootState) => state.tabConfig)
  const filter = tabConfigState.filters[tabConfigFilterIndex].filter
  let fieldFilterValue = ''
  if (customField.type === CustomFieldType.text)
    fieldFilterValue = isCustomTextValueFilter(filter) ? filter.value : ''
  if (customField.type === CustomFieldType.textarea)
    fieldFilterValue = isCustomTextValueFilter(filter) ? filter.value : ''
  const dispatch = useDispatch()
  const [inputText, setInputText] = useState(fieldFilterValue)
  const isMountRef = useRef(true)

  useEffect(() => {
    if (isMountRef.current) {
      isMountRef.current = false
      return
    }
    const timeOutId = setTimeout(() => {
      if (customField.type === CustomFieldType.textarea)
        dispatch(
          setCustomTextAreaFilterValue({
            customFieldId: customField.id,
            tabConfigFilterIndex,
            value: inputText,
          })
        )
      if (customField.type === CustomFieldType.text)
        dispatch(
          setCustomTextFilterValue({
            customFieldId: customField.id,
            tabConfigFilterIndex,
            value: inputText,
          })
        )
    }, 500)
    return () => clearTimeout(timeOutId)
  }, [dispatch, inputText, customField, tabConfigFilterIndex])
  return (
    <Flex column hAlign="start" gap="gap.smaller" className={style.filter}>
      <Input
        fluid
        icon={<SearchIcon />}
        placeholder={`検索する文字列を入力してください`}
        value={inputText}
        onChange={(_, e) => {
          setInputText(String(e?.value))
        }}
        onBlur={() => {
          if (inputText === '') return
          if (customField.type === CustomFieldType.textarea)
            dispatch(
              setCustomTextAreaFilterValue({
                customFieldId: customField.id,
                tabConfigFilterIndex,
                value: inputText,
              })
            )
          if (customField.type === CustomFieldType.text)
            dispatch(
              setCustomTextFilterValue({
                customFieldId: customField.id,
                tabConfigFilterIndex,
                value: inputText,
              })
            )
        }}
      />
    </Flex>
  )
}

export default TicketCustomFieldValueTextFilter
