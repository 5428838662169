import User from '@microsoft/microsoft-graph-types'
import { EntityState } from '@reduxjs/toolkit'
import Draft, {
  AtomicBlockUtils,
  ContentBlock,
  ContentState,
  DraftHandleValue,
  Editor,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js'
import React, { Dispatch, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { AppThunk, RootState } from '../../app/store'
import { ChatInputEditorProperties, Tickets } from '../../consts'
import { consoleErrorWithAirbrake } from '../../utils'
import { addAlert } from '../alert/alertsSlice'
import { AuthState } from '../auth/authSlice'
import { UserState } from '../auth/usersSlice'
import {
  setEditorState,
  storeEditorStateDraft,
} from '../editor/editorStateSlice'
import { setLinkMenuOpen, setTrashOpen } from '../editor/editorToolSlice'
import { Ticket } from '../ticket/ticketSlice'
import styles from './ChatInputEditor.module.css'
import { ChatInputEditorUtils } from './ChatInputEditorUtils'
import ChatInputPastedImage from './ChatInputPastedImage'
import { onTokenIn } from './imageTokensSlice'
import {
  MessageAttachment,
  messageAttachmentsSelectors,
  uploadAttachmentProcessOnRemove,
} from './messageAttachmentsSlice'
import { postTyping } from './messagesSlice'
import { HorizontalLineEntityType, PastedImageEntityType } from './types'
import {
  deleteFile,
  extractChannelName,
  onChannelNameNotFound,
  uploadFile,
} from './upload'

const AtomicComponent = (props: {
  block: ContentBlock
  contentState: ContentState
}): JSX.Element | null => {
  const entityKey = props.block.getEntityAt(0)
  if (!entityKey) {
    return null
  }
  const entity = props.contentState.getEntity(entityKey)
  const type = entity.getType()
  switch (type) {
    case PastedImageEntityType: {
      const { id } = entity.getData()
      return <ChatInputPastedImage id={id} />
    }
    case HorizontalLineEntityType: {
      return <hr />
    }
    default:
      return null
  }
}

const blockRendererFn = (block: ContentBlock) => {
  if (block.getType() === 'atomic') {
    return {
      component: AtomicComponent,
      editable: false,
    }
  }
  return null
}

// 貼り付け画像のファイル名を生成します。このファイル名はonedrive保存先ファイルのファイル名です。
const pasteImageName = (type: string | undefined): string => {
  let extension = 'png'
  if (type) {
    const arr = type.split('/')
    extension = arr.length > 1 ? arr[1] : 'png'
  }
  return `pasted-${uuidv4()}.${extension}`
}

export interface ChatInputEditorProps {
  setEditorFocus: React.Dispatch<React.SetStateAction<boolean>>
  onClick: () => void
  styleEdit: boolean
  onStyleEditToggle: () => void
  onStyleToggled: (
    style: 'BOLD' | 'ITALIC' | 'UNDERLINE' | 'STRIKETHROUGH'
  ) => void
}

const localStorageStoreDelay = 500

export const ChatInputEditor: React.FC<ChatInputEditorProps> = (props) => {
  const dispatch = useDispatch()
  const { ticketId } = useParams<{ ticketId: string }>()
  const authState = useSelector((state: RootState) => state.auth)
  const { setEditorFocus, styleEdit, onStyleEditToggle } = props
  const channelRelativeUrl = authState.context?.channel?.relativeUrl
  const groupId = authState.context?.team?.groupId
  const channelId = authState.context?.channel?.id
  const ticketState = useSelector((state: RootState) => state.ticket)
  const ticket = ticketState.entities[ticketId]
  const usersState = useSelector((state: RootState) => state.users)
  const attachments = useSelector(messageAttachmentsSelectors.selectAll)
  const [sentTyping, setSentTyping] = useState(false)

  const editorState = useSelector(
    (state: RootState) => state.editorState
  ).editorState
  const keyBindingFn = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      // 箇条書きの場合、enter keyだけを押したときに、draftjsのhandleに任せます、箇条書きとして、改行します。
      if (
        ChatInputEditorUtils.isFocusingOnListBlock(editorState) &&
        !e.shiftKey
      ) {
        return Draft.getDefaultKeyBinding(e)
      }

      // 箇条書きの場合、enter Keyとshiftを押したときに、手動でhandleします。改行せずに、\nの改行します。
      if (
        ChatInputEditorUtils.isFocusingOnListBlock(editorState) &&
        e.shiftKey
      ) {
        dispatch(
          setEditorState({ state: RichUtils.insertSoftNewline(editorState) })
        )
        return ''
      }

      // enterを押した時に、引用のところにカーソルがある場合
      if (ChatInputEditorUtils.isFocusingOnBlockquote(editorState)) {
        // テキストが入ってないなら、引用を解除します
        if (
          ChatInputEditorUtils.getCurrentBlock(editorState).getText().length ===
          0
        ) {
          const e = RichUtils.toggleBlockType(editorState, 'unstyled')
          const currentBlock = ChatInputEditorUtils.getCurrentBlock(editorState)
          const c = Modifier.setBlockData(
            e.getCurrentContent(),
            e.getSelection(),
            currentBlock
              .getData()
              .remove(ChatInputEditorProperties.BlockQuoteLineKey)
          )

          dispatch(
            setEditorState({
              state: EditorState.push(e, c, 'change-block-data'),
            })
          )
          return ''
        }

        // 送信せずに、引用のままで改行します。
        let newLineEditorState = EditorState.push(
          editorState,
          Modifier.splitBlock(
            editorState.getCurrentContent(),
            editorState.getSelection()
          ),
          'split-block'
        )

        // 改行した新しい行のkeyを取得します。
        const newLineKey = newLineEditorState
          .getCurrentContent()
          .getBlockAfter(editorState.getSelection().getStartKey())
          ?.getKey()

        if (newLineKey != null) {
          // 改行した新しい行はblockDataを上の行のblockDataを継承します。
          // カーソルを改行した行の頭に設置します。
          newLineEditorState = EditorState.forceSelection(
            EditorState.push(
              editorState,
              Modifier.setBlockData(
                newLineEditorState.getCurrentContent(),
                SelectionState.createEmpty(newLineKey),
                editorState
                  .getCurrentContent()
                  .getBlockForKey(editorState.getSelection().getStartKey())
                  .getData()
              ),
              'change-block-data'
            ),
            newLineEditorState.getSelection()
          )
        }

        dispatch(setEditorState({ state: newLineEditorState }))
        return ''
      }
    }

    // cmd + Enter || cmd + shift + Enterが押された場合にTeams準拠で送信します
    if (e.key === 'Enter' && e.metaKey) {
      e.preventDefault()
      return 'enter'
    }

    // Enterキーのみが押された場合の処理をTeams準拠にします
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      // ツールバーが開いている場合は改行、閉じている場合は送信します
      if (styleEdit) {
        return 'split-block'
      } else {
        return 'enter'
      }
    }

    // cmd + shift + x により、ツールバーを開きます
    if (e.metaKey && e.shiftKey && e.key.toLowerCase() == 'x') {
      onStyleEditToggle()
      e.preventDefault()
      return ''
    }

    // ctr + shift + x により、ツールバーを開きます
    if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() == 'x') {
      onStyleEditToggle()
      e.preventDefault()
      return ''
    }

    // ctr + b or cmd + b により、文字を太字にします
    if (
      (e.ctrlKey && e.key.toLowerCase() == 'b') ||
      (e.metaKey && e.key.toLowerCase() == 'b')
    ) {
      props.onStyleToggled('BOLD')
      e.preventDefault()
      return ''
    }

    // ctr + i or cmd + i により、文字を斜体にします
    if (
      (e.ctrlKey && e.key.toLowerCase() == 'i') ||
      (e.metaKey && e.key.toLowerCase() == 'i')
    ) {
      props.onStyleToggled('ITALIC')
      e.preventDefault()
      return ''
    }

    // ctr + u or cmd + u により、文字に下線を引きます
    if (
      (e.ctrlKey && e.key.toLowerCase() == 'u') ||
      (e.metaKey && e.key.toLowerCase() == 'u')
    ) {
      props.onStyleToggled('UNDERLINE')
      e.preventDefault()
      return ''
    }

    // 現状選択された状態の時のみ対応します
    if (
      (e.metaKey || e.ctrlKey) &&
      e.key.toLocaleLowerCase() === 'k' &&
      !ChatInputEditorUtils.currentSelectionContainsImage(editorState) &&
      ChatInputEditorUtils.singleLineSelected(editorState)
    ) {
      dispatch(setLinkMenuOpen(true))
      e.preventDefault()
      return ''
    }

    return Draft.getDefaultKeyBinding(e)
  }

  const handleKeyCommand = (command: string): DraftHandleValue => {
    if (command === 'enter') {
      props.onClick()
      return 'handled'
    }

    if (command === 'backspace' || command === 'delete') {
      const selection = editorState.getSelection()
      const contentState = editorState.getCurrentContent()
      const currentBlock = ChatInputEditorUtils.getCurrentBlock(editorState)
      const currentType = currentBlock.getType()
      const beforeBlock = contentState.getBlockBefore(selection.getStartKey())
      const beforeBlockType = beforeBlock?.getType()

      // 最後に引用の一行だけ残ってある場合に、その引用を削除します
      if (
        ChatInputEditorUtils.isBlockQuoteBlock(currentBlock) &&
        !contentState.hasText()
      ) {
        const newContentState = Modifier.setBlockData(
          contentState,
          SelectionState.createEmpty(currentBlock.getKey()),
          currentBlock
            .getData()
            .set(ChatInputEditorProperties.BlockQuoteLineKey, null)
        )
        dispatch(
          setEditorState({
            state: EditorState.push(
              editorState,
              newContentState,
              'change-block-data'
            ),
          })
        )
        return 'handled'
      }

      // 一行しかない状態 & その一行はindentが入ってあるなら、そのindentを削除します
      if (
        currentBlock.getData().get(ChatInputEditorProperties.BlockIndentKey) !=
          null &&
        !contentState.hasText()
      ) {
        const newContentState = Modifier.setBlockData(
          contentState,
          SelectionState.createEmpty(currentBlock.getKey()),
          currentBlock
            .getData()
            .set(ChatInputEditorProperties.BlockIndentKey, null)
        )
        dispatch(
          setEditorState({
            state: EditorState.push(
              editorState,
              newContentState,
              'change-block-data'
            ),
          })
        )
        return 'handled'
      }

      // 削除した画像をonedrive保存先からも削除します。
      if (
        beforeBlockType === 'atomic' &&
        currentType === 'unstyled' &&
        selection.isCollapsed() &&
        selection.getStartOffset() === 0 &&
        currentBlock.getLength() === 0
      ) {
        if (beforeBlock != null) {
          const beforeBlockEntity = contentState.getEntity(
            beforeBlock.getEntityAt(0)
          )
          if (
            beforeBlockEntity != null &&
            beforeBlockEntity.getType() === PastedImageEntityType
          ) {
            deleteFileByBlockKeys({ blockKey: beforeBlock.getKey() })

            const lastLastBlock = contentState.getBlockBefore(
              beforeBlock.getKey()
            )

            // TODO: コードネストを浅くします
            // 前の前のブロックは水平線もしくは貼り付け画像の場合は、APIを使ってblockを削除します。
            if (lastLastBlock == null || lastLastBlock.getType() === 'atomic') {
              const newState = RichUtils.handleKeyCommand(editorState, command) // insertAtomicBlockでinsertしたatomic blockを削除します。新しいStateを取得します。
              if (newState) {
                dispatch(setEditorState({ state: newState })) // 新しいStateを使って、EditorStateを更新します。
                return 'handled' // RichUtils.handleKeyCommandが効くため,draftJsのdefaultな処理を止めます。
              }
            }

            // RichUtils.handleKeyCommandAPIを使って削除するなら、空白が残されますので、手動で削除します。
            const editorStateWithRemovement = EditorState.push(
              editorState,
              Modifier.removeRange(
                contentState,
                new SelectionState({
                  anchorKey: lastLastBlock?.getKey(),
                  anchorOffset: lastLastBlock?.getLength(),
                  focusKey: currentBlock.getKey(),
                  focusOffset: currentBlock.getLength(),
                }),
                'forward'
              ),
              'remove-range'
            )

            const editorStateWithSelection = EditorState.forceSelection(
              editorStateWithRemovement,
              new SelectionState({
                anchorKey: lastLastBlock?.getKey(),
                anchorOffset: lastLastBlock?.getLength(),
                focusKey: lastLastBlock?.getKey(),
                focusOffset: lastLastBlock?.getLength(),
              })
            )

            dispatch(setEditorState({ state: editorStateWithSelection }))

            return 'handled'
          }
        }
      }

      /* 選択状態 */
      if (!selection.isCollapsed()) {
        deleteFileByBlockKeys({
          blockKeys: [selection.getStartKey(), selection.getEndKey()],
        })
      }

      const newState = RichUtils.handleKeyCommand(editorState, command) // insertAtomicBlockでinsertしたatomic blockを削除します。新しいStateを取得します。
      if (newState) {
        dispatch(setEditorState({ state: newState })) // 新しいStateを使って、EditorStateを更新します。
        return 'handled' // RichUtils.handleKeyCommandが効くため,draftJsのdefaultな処理を止めます。
      }
    }

    // そのほかの場合はdraftJsのdefaultな処理に任せます。
    return 'not-handled'
  }

  // 入力した貼り付け画像をbackspaceで削除した時に挙動。
  const cancelFile = (id: number, itemId: string, groupId: string) => {
    dispatch(uploadAttachmentProcessOnRemove({ id: id }))
    // またonedriveにアップロード完了していないケース。
    if (!itemId) {
      return
    }
    // onedriveにアップロード済みで、直ちに、ファイルをonedriveから削除します。
    dispatch(deleteFile(groupId, itemId))
  }

  const deleteFileByBlockKeys = (props: {
    blockKey?: string
    blockKeys?: string[]
  }) => {
    const { blockKey, blockKeys } = props
    const groupId = authState.context?.team?.groupId
    if (groupId == null) {
      return
    }
    let deletedInfos: DeletedImageInfo[] = []
    if (blockKey != null) {
      deletedInfos = createDeletedImageInfoFromBlockKey(
        blockKey,
        editorState.getCurrentContent(),
        attachments
      )
    }
    if (blockKeys != null && blockKeys.length == 2) {
      deletedInfos = createDeleteImageInfoFromStartToEndKey(
        blockKeys[0],
        blockKeys[1],
        editorState.getCurrentContent(),
        attachments
      )
    }

    deletedInfos.forEach((target) =>
      cancelFile(target.id, target.itemId, groupId)
    )
  }

  // 入力欄画像を貼り付けた時の挙動
  const onPastedFilesChange = async (blobs: Blob[]) => {
    uploadPastedFiles(blobs)
  }

  // 貼り付け画像をアップロードします
  const handlePastedImageBlobs = async (
    blobs: Blob[],
    groupId: string,
    channelName: string,
    requesterUserEmail: string
  ) => {
    for (const blob of blobs) {
      // Mime typeを読み込み: https://developer.mozilla.org/ja/docs/Web/API/Blob/type
      const uint8Array = await readBase64ToUint8Array(blob)
      const id = Math.random() * 10000
      // OneDriveにアップロードします
      dispatch(
        uploadFile(
          uint8Array,
          groupId,
          channelName,
          pasteImageName(blob.type),
          requesterUserEmail,
          id,
          true
        )
      )
      // 空白なファイルをアップロードされる場合にはあります、その時にはエラーメッセージを表示します
      if (uint8Array.byteLength === 0) {
        return
      }
      dispatch(onTokenIn({ id: id }))
      // 以下の処理は貼り付けた画像を入力欄に表示させます
      const currentContent = editorState.getCurrentContent()
      const newContent = currentContent.createEntity(
        PastedImageEntityType,
        'IMMUTABLE',
        {
          src: ' ', //空白にする必要があります
          id: id,
        }
      )
      const newState = EditorState.set(editorState, {
        currentContent: newContent,
      })

      const entityKey = newContent.getLastCreatedEntityKey()
      dispatch(
        setEditorState({
          state: AtomicBlockUtils.insertAtomicBlock(newState, entityKey, ' '),
        })
      )
    }
  }

  const uploadPastedFiles = (blobs: Blob[]) => {
    if (!groupId) {
      consoleErrorWithAirbrake(
        `groupId is empty when at uploading, blobs length: ${blobs.length}`
      )
      return
    }

    const channelName =
      extractChannelName(channelRelativeUrl) || authState.uploadFolder
    if (channelName == null) {
      onChannelNameNotFound(
        dispatch,
        groupId,
        channelId,
        authState.uploadFolder,
        channelRelativeUrl
      )
      return
    }

    if (!ticket) {
      consoleErrorWithAirbrake('ticket is empty when uploading files')
      return
    }

    const requester = usersState.entities[ticket.requesterUserId]

    if (!requester) {
      consoleErrorWithAirbrake('requester is empty when uploading files')
      return
    }

    const requesterUserEmail =
      requester.mail || requester.userPrincipalName || ''

    if (blobs.length === 0) {
      return
    }

    handlePastedImageBlobs(
      blobs,
      groupId,
      channelName,
      requesterUserEmail
    ).catch((e) => {
      consoleErrorWithAirbrake(
        `error occurred at handlePastedImageBlob. error: ${e}`
      )
    })
  }

  const handlePastedText = (
    text: string,
    html: string | undefined,
    editorState: EditorState
  ): DraftHandleValue => {
    const currentSelection = editorState.getSelection()
    let currentContent = editorState.getCurrentContent()
    const isCollapsed = currentSelection.isCollapsed()
    // 何か選択している状態
    if (!isCollapsed) {
      currentContent = Modifier.replaceText(
        currentContent,
        currentSelection,
        text
      )
    }

    // 何も選択していない状態
    if (isCollapsed) {
      currentContent = Modifier.insertText(
        currentContent,
        currentSelection,
        text
      )
    }

    const newEditorState = EditorState.push(
      editorState,
      currentContent,
      'insert-characters'
    )
    dispatch(setEditorState({ state: newEditorState }))
    return 'handled'
  }

  const onChange = (state: Draft.EditorState) => {
    if (ticket != null && state.getCurrentContent().hasText() && !sentTyping) {
      dispatch(postTyping(ticket.id))
      setSentTyping(true)
      // typingは最大でも3秒に1回だけ送信するべき、という仕様にのっとって3秒以内には送信しない
      // see: https://github.com/Microsoft/botframework-sdk/blob/main/specs/botframework-activity/botframework-activity.md#typing-activity
      // A6001
      setTimeout(() => {
        setSentTyping(false)
      }, 3000)
    }

    // 改行する時に前のblockはインデント情報が入ってある場合には、新しい行のintent情報も前のblockと同じにします
    const newStateWithIndent = implementPreviousBlockIndentStyle(state)
    dispatch(setEditorState({ state: newStateWithIndent }))
  }

  // editorStateが変化される度に、debounceをかけて、localStorageに保存します。
  useEffect(() => {
    const meId = usersState.meId
    if (meId == null) {
      return
    }
    const timeOutId = setTimeout(() => {
      dispatch(storeEditorStateDraft(editorState, ticketId, meId))
    }, localStorageStoreDelay)
    return () => {
      clearTimeout(timeOutId)
    }
  }, [dispatch, editorState, ticketId, usersState.meId])

  return (
    <Editor
      customStyleMap={styleMaps}
      blockStyleFn={(block: ContentBlock): string =>
        createBlockStyleFn(block, editorState)
      }
      blockRendererFn={blockRendererFn}
      placeholder={
        shouldShowPlaceHolder(editorState) ? '新しいメッセージの入力' : ''
      }
      editorState={editorState}
      keyBindingFn={keyBindingFn}
      handlePastedText={handlePastedText}
      handleKeyCommand={handleKeyCommand}
      handleReturn={(_, editorState) => handleReturn(editorState, dispatch)}
      handlePastedFiles={(e) => {
        if (!authState.availableFeatures?.fileAttachment) {
          return 'not-handled'
        }
        onPastedFilesChange(e).then()
        return 'handled'
      }}
      handleBeforeInput={(
        chars: string,
        editorState: EditorState
      ): Draft.DraftHandleValue => {
        if (
          ticket?.requesterType === Tickets.RequesterType.RequesterTypeWebagent
        ) {
          return 'not-handled'
        }
        const shortCutEditorState = detectShortCutKey(editorState, chars)
        if (shortCutEditorState != null) {
          dispatch(setEditorState({ state: shortCutEditorState }))
          return 'handled'
        }

        const inlineCodeBlockEdgeState =
          ChatInputEditorUtils.onInputAtInlineCodeBlockEdge(editorState, chars)
        if (inlineCodeBlockEdgeState != null) {
          dispatch(setEditorState({ state: inlineCodeBlockEdgeState }))
          return 'handled'
        }

        return 'not-handled'
      }}
      onChange={onChange}
      onFocus={() => setEditorFocus(true)}
      onBlur={() => {
        setEditorFocus(false)
        const meId = usersState.meId
        if (meId != null) {
          dispatch(storeEditorStateDraft(editorState, ticketId, meId))
        }
      }}
      onEscape={() => {
        dispatch(setTrashOpen(true))
      }}
      onRightArrow={() => {
        const res = ChatInputEditorUtils.onArrowRight(editorState)
        if (res != null) {
          dispatch(setEditorState({ state: res }))
        }
      }}
      onTab={(e) => {
        e.preventDefault()
        const res = ChatInputEditorUtils.onTab(editorState, e.shiftKey)
        if (res == null) {
          return
        }
        dispatch(setEditorState({ state: res }))
      }}
    />
  )
}

export default ChatInputEditor

declare type DeletedImageInfo = { id: number; itemId: string }

const createDeleteImageInfoFromStartToEndKey = (
  startKey: string,
  endKey: string,
  currentContent: Draft.ContentState,
  attachments: MessageAttachment[]
): DeletedImageInfo[] => {
  const results: DeletedImageInfo[] = []
  const allBlocks = currentContent.getBlocksAsArray()
  let isStarted = false
  for (let i = 0; i < allBlocks.length; i++) {
    const block = allBlocks[i]
    if (block.getKey() === startKey) {
      isStarted = true
    }

    // 選択する範囲以内かを判断します
    if (isStarted === false) {
      continue
    }

    const res = createDeleteImageInfoFromBlock(
      block,
      currentContent,
      attachments
    )

    if (res != null) {
      results.push(res)
    }

    if (block.getKey() === endKey) {
      break
    }
  }
  return results
}

const createDeletedImageInfoFromBlockKey = (
  blockKey: string,
  currentContent: Draft.ContentState,
  attachments: MessageAttachment[]
): DeletedImageInfo[] => {
  const contentBlock = currentContent.getBlockForKey(blockKey)
  if (contentBlock.getType() !== 'atomic') {
    return []
  }
  const result = createDeleteImageInfoFromBlock(
    contentBlock,
    currentContent,
    attachments
  )
  if (result == null) {
    return []
  }
  return [result]
}

const createDeleteImageInfoFromBlock = (
  block: Draft.ContentBlock,
  currentContent: Draft.ContentState,
  attachments: MessageAttachment[]
): DeletedImageInfo | undefined => {
  // はAtomicなBlockなのかを判断します
  if (block.getType() !== 'atomic') {
    return
  }

  const entity = currentContent.getEntity(block.getEntityAt(0))
  const type = entity.getType()
  const data = entity.getData()
  /* 貼り付け画像のAtomicComponentなのかを判断します */
  if (type !== PastedImageEntityType) {
    return
  }
  const target = attachments.find((attachment) => attachment.id === data.id)

  if (target == null) {
    return
  }

  return {
    id: target.id,
    itemId: target.attachmentData.itemId,
  }
}

const readBase64ToUint8Array = (blob: Blob): Promise<Uint8Array> => {
  return new Promise<Uint8Array>((resolve, reject) => {
    blob
      .arrayBuffer()
      .then((res) => {
        resolve(new Uint8Array(res))
      })
      .catch((e) => {
        reject(e)
      })
  })
}

// blockごとにどのスタイルをエディター上で表現するかを手動で決めます
// 注意：ここは入力した内容はエディターどのように表現するのかをコントロールする場所です。
// 送信後、チャット上でどのように表現するのかをコントロールする場所ではありません。
const createBlockStyleFn = (
  block: ContentBlock,
  editorState: EditorState
): string => {
  const blockType = block.getType()

  const blockIndentType = block
    .getData()
    .get(ChatInputEditorProperties.BlockIndentKey)

  if (blockIndentType != null) {
    const styleArr = [
      styles.Indent1,
      styles.Indent2,
      styles.Indent3,
      styles.Indent4,
      styles.Indent5,
      styles.Indent6,
    ]
    try {
      const indentVal = parseInt(blockIndentType, 10) - 1
      if (indentVal < 0) {
        return ''
      }
      if (indentVal > styleArr.length) {
        return ''
      }
      return styleArr[indentVal]
    } catch {
      return ''
    }
  }

  // デフォルトでは、draftjsのatomicというblockはfigureにレンダリングしますが、余計なpadding(margin-block-start,margin-block-end,margin-inline-start,margin-inline-end)が発生するため、divにレンダリングします。
  if (blockType === 'atomic') {
    return styles.atomic
  }

  // 引用に変換します
  if (ChatInputEditorUtils.isBlockQuoteBlock(block)) {
    const contentState = editorState.getCurrentContent()
    const beforeBlock = contentState.getBlockBefore(block.getKey())

    const blockQuoteStyles = [styles.blockquote]
    if (
      beforeBlock == null ||
      ChatInputEditorUtils.isBlockQuoteBlock(beforeBlock) === false
    ) {
      blockQuoteStyles.push(styles.blockquoteFirst)
    }

    if (
      beforeBlock != null &&
      ChatInputEditorUtils.isBlockQuoteBlock(beforeBlock) &&
      beforeBlock.getData().get(ChatInputEditorProperties.BlockQuoteLineKey) !==
        block.getData().get(ChatInputEditorProperties.BlockQuoteLineKey)
    ) {
      blockQuoteStyles.push(styles.blockquoteFirst)
    }

    const afterBlock = contentState.getBlockAfter(block.getKey())
    if (
      afterBlock == null ||
      ChatInputEditorUtils.isBlockQuoteBlock(afterBlock) === false
    ) {
      blockQuoteStyles.push(styles.blockquoteLast)
    }

    if (
      afterBlock != null &&
      ChatInputEditorUtils.isBlockQuoteBlock(afterBlock) &&
      afterBlock.getData().get(ChatInputEditorProperties.BlockQuoteLineKey) !==
        block.getData().get(ChatInputEditorProperties.BlockQuoteLineKey)
    ) {
      blockQuoteStyles.push(styles.blockquoteLast)
    }

    return blockQuoteStyles.join(' ')
  }

  // 同幅
  if (blockType === 'code-block') {
    return styles.codeBlock
  }

  if (blockType === 'unordered-list-item' && block.getDepth() === 1) {
    return styles.unOrderedList1
  }

  if (blockType === 'unordered-list-item' && block.getDepth() >= 2) {
    return styles.unOrderedList2
  }

  if (blockType === 'unordered-list-item') {
    return styles.unOrderedList
  }

  if (blockType === 'ordered-list-item' && block.getDepth() === 1) {
    return styles.orderedList1
  }

  if (blockType === 'ordered-list-item' && block.getDepth() === 2) {
    return styles.orderedList2
  }

  if (blockType === 'ordered-list-item' && block.getDepth() === 3) {
    return styles.orderedList3
  }

  if (blockType === 'ordered-list-item' && block.getDepth() === 4) {
    return styles.orderedList4
  }

  if (blockType === 'header-one') {
    return styles.headerOne
  }

  if (blockType === 'header-two') {
    return styles.headerTwo
  }

  if (blockType === 'header-three') {
    return styles.headerThree
  }

  return ''
}

const handleReturn = (
  editorState: Draft.EditorState,
  dispatch: Dispatch<{
    payload: {
      state: Draft.EditorState
    }
    type: string
  }>
): Draft.DraftHandleValue => {
  const currentBlockType = RichUtils.getCurrentBlockType(editorState)
  if (
    currentBlockType === 'code-block' &&
    editorState.getSelection().isCollapsed()
  ) {
    dispatch(
      setEditorState({ state: RichUtils.insertSoftNewline(editorState) })
    )
    return 'handled'
  }
  return 'not-handled'
}

const implementPreviousBlockIndentStyle = (state: EditorState): EditorState => {
  const selection = state.getSelection()

  if (!selection.isCollapsed()) {
    return state
  }

  const contentState = state.getCurrentContent()
  const currentBlock = ChatInputEditorUtils.getCurrentBlock(state)
  if (currentBlock.getLength() > 0) {
    return state
  }

  if (
    currentBlock.getData().get(ChatInputEditorProperties.BlockIndentKey) != null
  ) {
    return state
  }

  const beforeBlock = contentState.getBlockBefore(selection.getStartKey())

  const beforeBlockType = beforeBlock?.getType()
  if (
    beforeBlockType != null &&
    ChatInputEditorUtils.isListBlock(beforeBlockType)
  ) {
    return state
  }

  const beforeBlockIndentType = beforeBlock
    ?.getData()
    .get(ChatInputEditorProperties.BlockIndentKey)
  if (beforeBlockIndentType == null) {
    return state
  }

  const newContentState = Modifier.setBlockData(
    contentState,
    selection,
    currentBlock
      .getData()
      .set(ChatInputEditorProperties.BlockIndentKey, beforeBlockIndentType)
  )
  return EditorState.push(state, newContentState, 'change-block-data')
}

export const backgroundColorStyleMaps = {
  backgroundColor0: {
    background: 'rgb(223, 146, 153)',
  },
  backgroundColor1: {
    background: 'rgb(244, 165, 147)',
  },
  backgroundColor2: {
    background: 'rgb(253, 212, 114)',
  },
  backgroundColor3: {
    background: 'rgb(229, 241, 143)',
  },
  backgroundColor4: {
    background: 'rgb(130, 205, 168)',
  },
  backgroundColor5: {
    background: 'rgb(157, 217, 219)',
  },
  backgroundColor6: {
    background: 'rgb(199, 212, 232)',
  },
  backgroundColor7: {
    background: 'rgb(235, 211, 225)',
  },
}

export const colorStyleMaps = {
  color0: {
    color: 'rgb(182, 66, 76)',
  },

  color1: {
    color: 'rgb(205, 89, 55)',
  },

  color2: {
    color: 'rgb(253, 192, 48)',
  },

  color3: {
    color: 'rgb(189, 203, 76)',
  },

  color4: {
    color: 'rgb(43, 155, 98)',
  },

  color5: {
    color: 'rgb(55, 121, 123)',
  },

  color6: {
    color: 'rgb(30, 83, 163)',
  },

  color7: {
    color: 'rgb(165, 57, 122)',
  },
}

export const fontSizeStyleMaps = {
  largeFont: {
    fontSize: 'x-large',
  },
  mediumFont: {
    fontSize: 'inherit',
  },
  smallFont: {
    fontSize: 'xx-small',
  },
}

export const styleMaps = {
  ...backgroundColorStyleMaps,
  ...colorStyleMaps,
  ...fontSizeStyleMaps,
}

// 添付ファイルをアップロードします
const uploadFileData = (
  filesData: File[],
  groupId: string,
  channelName: string,
  requesterUserEmail: string,
  dispatch: Dispatch<AppThunk>
) => {
  for (let i = 0; i < filesData.length; i++) {
    const f = filesData[i]
    const reader = new FileReader()
    reader.readAsArrayBuffer(f)
    reader.onload = () => {
      if (reader.result === null) {
        return
      }
      dispatch(
        uploadFile(
          reader.result,
          groupId,
          channelName,
          filesData[i].name,
          requesterUserEmail,
          null,
          false
        )
      )
    }
  }
}

export const uploadAttachmentFiles = (
  files: File[],
  ticket: Ticket | undefined,
  groupId: string | undefined,
  channelId: string | undefined,
  channelRelativeUrl: string | undefined,
  authState: AuthState,
  usersState: EntityState<User.User> & UserState,
  dispatch: Dispatch<AppThunk | ReturnType<typeof addAlert>>
) => {
  // Microsoft Teams側では一回のアップロードする時に、一度に10ファイルのみアップロードできます。
  if (files.length > 10) {
    dispatch(
      addAlert({
        id: Math.random() * 1000,
        title: '一度に10ファイルのみアップロードできます',
        type: 'file_num_exceed',
      })
    )
    return
  }

  if (!groupId) {
    consoleErrorWithAirbrake(
      `groupId is empty when at uploading files length: ${files.length}`
    )
    return
  }

  const channelName =
    extractChannelName(channelRelativeUrl) || authState.uploadFolder

  if (channelName == null) {
    onChannelNameNotFound(
      dispatch,
      groupId,
      channelId,
      authState.uploadFolder,
      channelRelativeUrl
    )
    return
  }

  if (!ticket) {
    consoleErrorWithAirbrake('ticket is empty when uploading files')
    return
  }

  const requester = usersState.entities[ticket.requesterUserId]

  if (!requester) {
    consoleErrorWithAirbrake('requester is empty when uploading files')
    return
  }

  const requesterUserEmail = requester.mail || requester.userPrincipalName || ''

  if (files.length === 0) {
    return
  }
  uploadFileData(files, groupId, channelName, requesterUserEmail, dispatch)
}

const shouldShowPlaceHolder = (editorState: EditorState): boolean => {
  const currentContent = editorState.getCurrentContent()
  // 内容が入ったら、当然placeholderを表示しません
  if (currentContent.hasText()) {
    return false
  }

  const blocks = currentContent.getBlocksAsArray()
  if (blocks.length === 0) {
    return true
  }

  const firstBlock = blocks[0]
  const firstBlockType = firstBlock.getType()
  // 内容が入ってなければ、一行目のblockの種類により、placeholderを表示するかしないかを決めます
  switch (firstBlockType) {
    case 'blockquote':
      return false
    case 'code-block':
      return false
    case 'ordered-list-item':
      return false
    case 'unordered-list-item':
      return false
  }

  // indentが入ってあれば、placeholderを表示しません
  const indentType = firstBlock
    .getData()
    .get(ChatInputEditorProperties.BlockIndentKey)

  // indentTypeはundefinedもしくは0の場合は、indentはない状態です
  if (indentType != null && indentType > 0) {
    return false
  }

  return true
}

const detectShortCutKey = (
  state: EditorState,
  lastInputChar: string
): EditorState | undefined => {
  // ##による、h1モードに入るショートカットキー
  if (
    ChatInputEditorUtils.currentInputContainsH1ShortKey(state, lastInputChar)
  ) {
    return ChatInputEditorUtils.onH1ShortKeyDetect(state)
  }

  // ```による、同幅モードに入るショートカットキー
  if (
    ChatInputEditorUtils.currentInputContainsCodeBlockShortKey(
      state,
      lastInputChar
    )
  ) {
    return ChatInputEditorUtils.onCodeBlockShortKeyDetect(state)
  }

  // ハイフン(アスタリスク)+スペースにより箇条書きモードに入るショートカットキー
  const { isUnorderedList, isOrderedList } =
    ChatInputEditorUtils.currentInputContainsListShortCutKey(
      state,
      lastInputChar
    )
  if (isUnorderedList || isOrderedList) {
    // 引用の中に箇条書きを作成できないようにするため、引用があるかを見てスキップする
    if (ChatInputEditorUtils.currentSelectionContainsBlockQuote(state)) {
      return
    }
    return ChatInputEditorUtils.onListShortCutKeyDetect(state, isOrderedList)
  }

  // *bold*, _italic_, ~strikethrough~により、インラインスタイルの変換を処理します
  const res = ChatInputEditorUtils.commonStyleShortCutState(
    state,
    lastInputChar
  )

  if (res != null) {
    return res
  }

  // `code`により、インラインcodeを処理します
  const r = ChatInputEditorUtils.inlineCodeShortCutState(state, lastInputChar)
  if (r != null) {
    return r
  }

  return
}
