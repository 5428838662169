import * as React from 'react'

import style from './style.module.css'

type Props = {
  renderNewTicket: () => React.ReactElement
  renderTicketList: () => React.ReactElement
}
const RequesterChatPresenter: React.FC<Props> = (props) => {
  return (
    <div className={style.container}>
      <div className={style.ticketList}>{props.renderTicketList()}</div>
      <div className={style.newTicket}>{props.renderNewTicket()}</div>
    </div>
  )
}

export default RequesterChatPresenter
