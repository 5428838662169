import format from 'date-fns/format'
import * as React from 'react'

import RequesterChatFileList, {
  FileProp,
} from '../../components/RequesterChatFileList'
import styles from './styles.module.css'

type Props = {
  publisherSrc: string
  publisherName: string
  body: string
  attachments: FileProp[]
}
const ActivityMessagePlanPreviewBodyPresenter: React.FC<Props> = (props) => {
  const now = React.useMemo(() => {
    return format(new Date(), 'yyyy/MM/dd HH:mm')
  }, [])
  const displayBody = React.useMemo(() => {
    return props.body.split('\n').map((str, index) => {
      return (
        <React.Fragment key={index}>
          {str}
          <br />
        </React.Fragment>
      )
    })
  }, [props.body])
  return (
    <div className={styles.container}>
      <div className={styles.publisherTitle}>配信元</div>
      <div className={styles.publisherRow}>
        <img className={styles.publisherImage} src={props.publisherSrc} />
        <span className={styles.publisherName}>{props.publisherName}</span>
      </div>
      <div className={styles.previewName}>プレビュー</div>
      <div className={styles.previewCard}>
        <div className={styles.previewTitleRow}>
          <div className={styles.previewPublisherName}>
            {props.publisherName}
          </div>
          <div className={styles.previewPublishedDate}>{now}</div>
        </div>
        <div className={styles.previewBody}>{displayBody}</div>
        <div className={styles.previewFileList}>
          <RequesterChatFileList files={props.attachments} />
        </div>
      </div>
    </div>
  )
}

export default ActivityMessagePlanPreviewBodyPresenter
