import { Button, FontSizeIcon, Popup } from '@fluentui/react-northstar'
import * as React from 'react'

import styles from './style.module.css'

type ArrayInner<T> = T extends (infer U)[] ? U : never
export type FontSize = ArrayInner<typeof sizeSelection>['key']

const sizeSelection = [
  {
    key: 'small' as const,
    label: '小',
  },
  {
    key: 'medium' as const,
    label: '中',
  },
  {
    key: 'large' as const,
    label: '大',
  },
]

type Props = {
  onChange: (size: FontSize) => void
}
const FontSizeSelect = React.memo<Props>((props) => {
  const [open, setOpen] = React.useState(false)

  const change = React.useCallback(
    (size: FontSize) => {
      props.onChange(size)
      setOpen(false)
    },
    [props, setOpen]
  )

  return (
    <Popup
      open={open}
      onOpenChange={(_, props) => setOpen(props?.open ?? false)}
      trigger={<Button iconOnly text icon={<FontSizeIcon />} />}
      content={<Content onChange={change} />}
    />
  )
})

export default FontSizeSelect

const Content: React.FC<{ onChange: (size: FontSize) => void }> = (props) => {
  return (
    <div className={styles.menu}>
      {sizeSelection.map((size) => (
        <div
          key={size.key}
          onClick={() => props.onChange(size.key)}
          className={styles.menuItem}
        >
          {size.label}
        </div>
      ))}
    </div>
  )
}
