import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $wrapNodeInElement } from '@lexical/utils'
import { mergeRegister } from '@lexical/utils'
import {
  $createParagraphNode,
  $getNodeByKey,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  NodeKey,
  createCommand,
} from 'lexical'
import * as React from 'react'

import { $createCodeblockNode, $isCodeblockNode, CodeblockNode } from './node'

export type InsertCodeblockCommandPayload = {
  title: string
  value: string
  language: string
}
export const INSERT_CODEBLOCK_COMMAND =
  createCommand<InsertCodeblockCommandPayload>('insert-codeblock')

export type EditCodeblockCommandPayload = {
  key: NodeKey
  title: string
  value: string
  language: string
}
export const EDIT_CODEBLOCK_COMMAND =
  createCommand<EditCodeblockCommandPayload>('edit-codeblock')

const CodeblockPlugin: React.FC = () => {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    if (!editor.hasNodes([CodeblockNode])) {
      throw new Error('CodeblockPlugin: CodeblockNode not registered on editor')
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_CODEBLOCK_COMMAND,
        (payload) => {
          editor.update(() => {
            const codeblockNode = $createCodeblockNode({
              title: payload.title,
              value: payload.value,
              language: payload.language,
            })
            $insertNodes([codeblockNode])
            if ($isRootOrShadowRoot(codeblockNode.getParentOrThrow())) {
              $wrapNodeInElement(
                codeblockNode,
                $createParagraphNode
              ).selectEnd()
            }
          })
          return true
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        EDIT_CODEBLOCK_COMMAND,
        (payload) => {
          editor.update(() => {
            const codeblockNode = $getNodeByKey(payload.key)
            if (!$isCodeblockNode(codeblockNode)) {
              return
            }
            codeblockNode.setContent(
              payload.title,
              payload.value,
              payload.language
            )
          })
          return true
        },
        COMMAND_PRIORITY_EDITOR
      )
    )
  }, [editor])

  return <></>
}

export default CodeblockPlugin
