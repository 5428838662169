import { grpc } from '@improbable-eng/grpc-web'
import * as Graph from '@microsoft/microsoft-graph-types'
import {
  EntityId,
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk } from '../../app/store'
import channel_pb from '../../proto/channel_pb'
import channel_pb_service from '../../proto/channel_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from '../auth/authSlice'

export const channelsAdapter = createEntityAdapter<Graph.Channel>({
  selectId: (channel) => channel.id || '',
  sortComparer: (a, b) => {
    if (a.displayName == null) return -1
    if (b.displayName == null) return 1
    return a.displayName.localeCompare(b.displayName)
  },
})

interface ChannelState {
  loading: boolean
  error: string | null
}

export const channelsSlice = createSlice({
  name: 'channels',
  initialState: channelsAdapter.getInitialState<ChannelState>({
    loading: false,
    error: null,
  }),
  reducers: {
    fetchChannelsStart(state) {
      state.loading = true
      state.error = null
    },
    fetchChannelsOnMessage(
      state,
      action: PayloadAction<{
        message: channel_pb.GetChannelsResponse.AsObject
      }>
    ) {
      const { message } = action.payload
      channelsAdapter.setAll(state, message.channelsList)
    },
    fetchChannelsOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = null
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
  },
})

export const {
  fetchChannelsStart,
  fetchChannelsOnMessage,
  fetchChannelsOnEnd,
} = channelsSlice.actions
export default channelsSlice.reducer

/*
アプリケーション権限: multiChannel
ユーザー委任権限: delegateMultiChannel

FIXME: アプリケーション権限の実装は仕様バグの実装となっている.
groupId一つに紐づくチャネルを全て取得しているが、この方法ではチーム間をまたいだチャネルを取得できないのでアクティビティログのchannel_idからチャネル名を取得できない場合がある。
*/
export const fetchChannels =
  (groupId: EntityId): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    // 権限がないtenantも存在する場合は,fetch channelsリクエスト投げません。
    const availableFeatures = getState().auth.availableFeatures
    if (
      availableFeatures?.multiChannel === false &&
      availableFeatures?.delegateMultiChannel === false
    ) {
      return
    }

    dispatch(fetchChannelsStart())

    const client = grpcClient<
      channel_pb.GetChannelsRequest,
      channel_pb.GetChannelsResponse
    >(channel_pb_service.ChannelAPI.GetChannels)

    const req = new channel_pb.GetChannelsRequest()
    req.setGroupId(String(groupId))

    client.onMessage((message) => {
      dispatch(fetchChannelsOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(fetchChannelsOnEnd({ code, message }))
    })
    dispatch(fetchChannelsStart())
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.start(meta)
    client.send(req)
    client.finishSend()
  }
