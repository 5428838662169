import { FilterIcon } from '@fluentui/react-icons-mdl2'
import { Button } from '@fluentui/react-northstar'
import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { DefaultFields } from '../../consts'
import styles from './TicketList.module.css'
import { TicketState } from './ticketSlice'

type Props = {
  fieldName: string
}
const TicketFieldFilterButton: React.FC<Props> = ({ fieldName }) => {
  const ticketState = useSelector((state: RootState) => state.ticket)
  return (
    <Button
      text
      iconOnly
      styles={({ theme: { siteVariables } }) => ({
        color: isFiltered(fieldName, ticketState)
          ? siteVariables.colorScheme.red.foreground
          : siteVariables.naturalColors.grey[350],
      })}
      className={styles.columnOptionIcon}
      icon={<FilterIcon />}
    />
  )
}

export default TicketFieldFilterButton

const isFiltered = (fieldName: string, ticketState: TicketState): boolean => {
  switch (fieldName) {
    case DefaultFields.Requester.name:
      return ticketState.filters.requesterUserIds.length > 0
    case DefaultFields.Subject.name:
      return ticketState.filters.subject !== ''
    case DefaultFields.KeyId.name:
      return ticketState.filters.keyId !== 0
    case DefaultFields.Status.name:
      return ticketState.filters.statuses.length > 0
    case DefaultFields.AssignedUserId.name:
      return (
        ticketState.filters.assignedUserIds != null &&
        ticketState.filters.assignedUserIds.length > 0
      )
    case DefaultFields.CreatedAt.name: {
      const isStartFiltered = () => {
        return (
          ticketState.filters.startDate != null &&
          ticketState.filters.startHour !== [''] &&
          ticketState.filters.startHour.length > 0 &&
          ticketState.filters.startMinutes !== [''] &&
          ticketState.filters.startMinutes.length > 0
        )
      }
      const isEndFiltered = () => {
        return (
          ticketState.filters.endDate != null &&
          ticketState.filters.endHour !== [''] &&
          ticketState.filters.endHour.length > 0 &&
          ticketState.filters.endMinutes !== [''] &&
          ticketState.filters.endMinutes.length > 0
        )
      }
      return isStartFiltered() || isEndFiltered()
    }
    case DefaultFields.FaqStatus.name:
      return ticketState.filters.faqStatuses.length > 0
    default:
      return false
  }
}
