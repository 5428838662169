import * as Graph from '@microsoft/microsoft-graph-types'
import * as ReduxToolkit from '@reduxjs/toolkit'

import { membersSelector } from '../auth/memberSlice'
import type { PulldownItem } from './types/PulldownItem'

/**
 * Selector
 */
const assigneeIntoDropdownItem = (
  member: Graph.AadUserConversationMember
): PulldownItem => ({
  value: member.userId ?? '',
  header: member.displayName ?? '',
})
const matchNameOrEmail =
  (query: string) =>
  (member: Graph.AadUserConversationMember): boolean => {
    if (
      !isNotNullOrUndefined(member.displayName) ||
      !isNotNullOrUndefined(member.email)
    )
      return false
    return (
      member.displayName.toLowerCase().includes(query.toLowerCase()) ||
      member.email.toLowerCase().includes(query.toLowerCase())
    )
  }
const matchPulldownItemName =
  (query: string) =>
  (item: PulldownItem): boolean =>
    item.header.toLowerCase().includes(query)
const pulldownItemIsNotContain =
  (items: string[]) =>
  (value: PulldownItem): boolean =>
    !items.includes(value.value)

const isNotNullOrUndefined = <T>(a: T | null | undefined): a is T =>
  a !== null && a !== undefined

export const dropdownItemsSelector = ReduxToolkit.createSelector(
  membersSelector.selectAll,
  (assignees): PulldownItem[] => assignees.map(assigneeIntoDropdownItem)
)
export const searchWithQuerySelector = ReduxToolkit.createSelector(
  [
    membersSelector.selectAll,
    (_, query: string) => query,
    (_, __, values: string[]) => values,
    (_, __, ___, extendedItems?: PulldownItem[]) => extendedItems,
  ],
  (assignees, query, values, extendedItems): PulldownItem[] => {
    // クエリが未入力の場合はクエリに関する処理をエスケープし、queryで検索しせず全件返す
    if (query === '')
      return [
        ...(extendedItems ?? []),
        ...assignees.map(assigneeIntoDropdownItem),
      ].filter(pulldownItemIsNotContain(values))

    return [
      ...(extendedItems?.filter(matchPulldownItemName(query)) ?? []),
      ...assignees
        .filter(matchNameOrEmail(query))
        .map(assigneeIntoDropdownItem),
      // すでに選ばれているアイテムを弾く
    ].filter(pulldownItemIsNotContain(values))
  }
)
