import { PaperclipIcon } from '@fluentui/react-icons-northstar'
import * as React from 'react'
import { ChangeEvent } from 'react'

import styles from './styles.module.css'

type Props = {
  outline?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onChange: (files: File[]) => void
  className?: string
}
const FileInput: React.FC<Props> = (props) => {
  const change = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return
      props.onChange(Array.from(e.target.files))
      // onChangeを発火した後はファイルを保持する必要は無いため初期化しておく
      e.target.value = ''
    },
    [props]
  )

  return (
    <label className={[props.className, styles.outer].join(' ')}>
      <PaperclipIcon
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        outline={props.outline}
        className={props.className}
      />
      <input
        type="file"
        value={undefined}
        onChange={change}
        className={styles.input}
      />
    </label>
  )
}
export default FileInput
