import { Flex } from '@fluentui/react-northstar'
import React from 'react'

import styles from './Error.module.css'

interface Props {
  title: string
  message: string
}

const Error: React.FC<Props> = ({ title, message }) => {
  return (
    <Flex column className={styles.container}>
      <h1>{title}</h1>
      <div>{message}</div>
    </Flex>
  )
}

export default Error
