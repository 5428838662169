import '@tremor/react/dist/esm/tremor.css'

import './index.css'

import {
  Provider as FluentUIProvider,
  teamsTheme,
} from '@fluentui/react-northstar'
import * as microsoftTeams from '@microsoft/teams-js'
import { BackendFactory } from 'dnd-core'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import UAParser from 'ua-parser-js'

import airbrake from './airbrake'
import App from './app/App'
import { store } from './app/store'
import { appInsights } from './appInsights'
import { DeskCubeProvider } from './context/cubejs'
import { validateEnv } from './env'
import * as serviceWorker from './serviceWorker'
import { consoleErrorWithAirbrake } from './utils'

const main = async () => {
  try {
    await microsoftTeams.app.initialize()
  } catch (e) {
    consoleErrorWithAirbrake(
      `failed initialization of microsoftTeams app: ${e}`
    )
  }

  try {
    appInsights.loadAppInsights()
  } catch (e) {
    consoleErrorWithAirbrake(`failed at init application insights,err: ${e}`)
  }

  const container = document.getElementById('root')
  if (container == null) {
    return
  }
  const root = createRoot(container)

  if (!validateEnv()) {
    root.render(
      <div>
        <div>問題が発生しました。</div>
        <div>しばらくお待ちいただいてから、もう一度お試しください。</div>
        <div>状況が改善しない場合は、管理者にお問い合わせください。</div>
      </div>
    )
  } else {
    root.render(
      <FluentUIProvider
        theme={teamsTheme}
        styles={{
          background: 'transparent',
        }}
      >
        <Provider store={store}>
          <DeskCubeProvider>
            <DndProvider backend={dndBackendFrom(window.navigator)}>
              <App />
            </DndProvider>
          </DeskCubeProvider>
        </Provider>
      </FluentUIProvider>
    )

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister()
  }
}

function dndBackendFrom(navigator: Navigator): BackendFactory {
  // UA-CHが非対応のブラウザ(Teamsモバイルアプリ等)用
  if (!navigator.userAgentData) {
    const uaParser = new UAParser()

    switch (uaParser.getDevice().type) {
      case 'mobile':
      case 'tablet':
        return TouchBackend
      default:
        return HTML5Backend
    }
  }

  return navigator.userAgentData.mobile ? TouchBackend : HTML5Backend
}

// main関数を起動させ、その中でcatchされなかった例外エラーはairbrakeに通知
// See: https://airbrake.io/docs/installing-airbrake/installing-airbrake-in-a-javascript-application/
airbrake.call(main)
