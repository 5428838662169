import * as Graph from '@microsoft/microsoft-graph-types'
import { Dictionary } from '@reduxjs/toolkit'

import { Tickets } from '../../consts'
import { getDisplayName } from '../../utils'

export const createDisplayName = (
  userId: string,
  users: Dictionary<Graph.User>
): string => {
  if (userId.length === 0) {
    return Tickets.Users.UserNotIndicated
  }

  return getDisplayName(users[userId])
}
