import { ComposeIcon } from '@fluentui/react-icons-northstar'
import { Button } from '@fluentui/react-northstar'
import * as React from 'react'
import { useDispatch } from 'react-redux'

import * as Slice from '../RequesterChatInput/slice'
import { chatInputKey } from './lib/chatInputKey'
import style from './style.module.css'

type Props = {
  renderChatInput: (onClickClose: () => void) => React.ReactElement
}
const RequesterNewTicketPresenter = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const [isShowChatInput, setIsShowChatInput] = React.useState(false)
    const chatRef = React.useRef<HTMLDivElement>(null)

    const dispatch = useDispatch()
    return (
      <div ref={ref} className={style.container}>
        <div className={style.inner}>
          {isShowChatInput ? (
            <div ref={chatRef}>
              {props.renderChatInput(() => setIsShowChatInput(false))}
            </div>
          ) : (
            <Button
              icon={<ComposeIcon />}
              content="新しい問い合わせ"
              iconPosition="before"
              primary
              onClick={() => {
                setIsShowChatInput(true)
                // 新しい問い合わせを開始する前に、入力欄をリセットします。この手順を省略すると、新しい問い合わせボタンをクリックして入力欄を再度開いた際に、予期しない空白が表示される可能性があります。
                dispatch(Slice.actions.resetField({ key: chatInputKey }))
              }}
            />
          )}
        </div>
      </div>
    )
  }
)

export default RequesterNewTicketPresenter
