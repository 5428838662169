import { createAsyncThunk } from '@reduxjs/toolkit'

import { AsyncThunkConfig } from '../../app/store'
import { FormData } from '../../components/ActivityMessagePlanForm'
import { assertNever } from '../../utils'
import { attachmentEntityAdapter } from '../ActivityMessageAttachment/lib/entityAdapter'
import * as AttachmentOperations from '../ActivityMessageAttachment/operations'
import {
  ActivityMessagePlanDraftArgs,
  ActivityMessagePlanDraftReceiverArgs,
} from '../ActivityMessageAttachment/types/ActivityMessagePlanDraftArgs'
import { AttachmentItem } from '../ActivityMessageAttachment/types/AttachmentItem'
import PlanNewAPI from './api'
import { stateSelector } from './selectors'

export const createDraftPlan = createAsyncThunk<
  { id: number },
  { data: FormData; receiverIDs: string[] },
  AsyncThunkConfig
>('ActivityMessagePlanNew/createDraftPlan', async (args, thunkAPI) => {
  const attachments = attachmentEntityAdapter
    .getSelectors(stateSelector)
    .selectAll(thunkAPI.getState())
  const request = formDataToActivityMessagePlanDraftArgs(
    args.data,
    args.receiverIDs,
    attachments
  )
  const res = await thunkAPI
    .dispatch(
      PlanNewAPI.endpoints.createActivityMessagePlan.initiate(request, {
        track: false,
      })
    )
    .unwrap()

  return {
    id: res.id,
  }
})

function formDataToActivityMessagePlanDraftArgs(
  data: FormData,
  receiverIDs: string[],
  attachments: AttachmentItem[]
): ActivityMessagePlanDraftArgs {
  return {
    body: data.body,
    receiver: ((): ActivityMessagePlanDraftReceiverArgs => {
      switch (data.receiverType) {
        case 'all':
          return {
            type: 'all',
          }
        case 'userIDs':
          return {
            type: 'receiverIDs',
            receiverIDs,
          }
        default:
          assertNever(data.receiverType)
          return {
            type: 'all',
          }
      }
    })(),
    attachments,
  }
}

export const uploadFile = AttachmentOperations.uploadFileFactory(
  'ActivityMessagePlanNewForm/uploadFile'
)
export const removeFile = AttachmentOperations.removeFileFactory(
  'ActivityMessagePlanNewForm/removeFile'
)
