// Draft.jsのエディターで入力中の文字列にリンクを付与する
// 参考: https://so99ynoodles.com/blog/make-wysiwyg-editor-with-draft-js#%E3%83%AA%E3%83%B3%E3%82%AF%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B

import {
  Button,
  Grid,
  Input,
  LinkIcon,
  Popup,
  Text,
} from '@fluentui/react-northstar'
import { EditorState, Modifier, RichUtils } from 'draft-js'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { regExpAutoLink } from '../../consts'
import { consoleErrorWithAirbrake } from '../../utils'
import {
  LinkEntityData,
  createLinkEntityData,
  setEditorState,
} from '../editor/editorStateSlice'
import { setLinkMenuOpen } from '../editor/editorToolSlice'
import { ChatInputEditorUtils } from './ChatInputEditorUtils'
import styles from './ChatLink.module.css'

export interface ChatLinkProps {
  editorState: Draft.EditorState
  popUpTarget?: React.RefObject<HTMLElement>
}

const createLinkPopUpTarget = (ref?: React.RefObject<HTMLElement>) => {
  if (ref == null) {
    return
  }
  const res = ref.current
  if (res == null) {
    return
  }
  return res
}

// リッチテキストツールバー、cmd-kにより、リンク入れポップアップ
export const ChatLink: React.FC<ChatLinkProps> = ({
  editorState,
  popUpTarget,
}) => {
  const linkMenuOpen = useSelector(
    (state: RootState) => state.editorToolState.linkMenuOpen
  )
  const popupRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()

  const hasImageSelected =
    ChatInputEditorUtils.currentSelectionContainsImage(editorState)
  const singleLineSelected =
    ChatInputEditorUtils.singleLineSelected(editorState)

  const popUpClickEvent = useCallback(
    (e: MouseEvent) => {
      if (e == null) {
        return
      }
      const target = e.target
      if (target == null) {
        return
      }
      const t = target as Node

      if (popupRef == null) {
        return
      }

      if (popupRef.current == null) {
        return
      }

      if (popupRef.current.contains(t) === false) {
        dispatch(setLinkMenuOpen(false))
      }
    },
    [popupRef, dispatch]
  )

  useEffect(() => {
    window.addEventListener('click', popUpClickEvent)
    return () => {
      window.removeEventListener('click', popUpClickEvent)
    }
  }, [popUpClickEvent])

  return (
    <Popup
      open={linkMenuOpen}
      target={createLinkPopUpTarget(popUpTarget)}
      trigger={
        <LinkIcon
          outline
          disabled={hasImageSelected || !singleLineSelected}
          onClick={(e) => {
            e.stopPropagation()
            dispatch(setLinkMenuOpen(true))
          }}
        />
      }
      content={
        <div ref={popupRef}>
          <ChatLinkPopUp
            handleOpen={(e) => {
              dispatch(setLinkMenuOpen(e))
            }}
            editorState={editorState}
          />
        </div>
      }
      trapFocus
    />
  )
}

interface ChatLinkPopupProps {
  editorState: Draft.EditorState
  handleOpen: (open: boolean) => void
}
export const ChatLinkPopUp: React.FC<ChatLinkPopupProps> = ({
  editorState,
  handleOpen,
}) => {
  const [linkText, setLinkText] = useState('')
  const [linkURL, setLinkURL] = useState('')
  const [errLinkURL, setErrLinkURL] = useState(false)
  const dispatch = useDispatch()

  const handleAddLink = useCallback(() => {
    const selection = editorState.getSelection()
    if (!linkURL) {
      dispatch(
        setEditorState({
          state: RichUtils.toggleLink(editorState, selection, null),
        })
      )
      handleOpen(false)
      return
    }

    // replace selected text and apply link entity
    const content = editorState.getCurrentContent()
    const contentWithEntity = content.createEntity(
      'LINK',
      'MUTABLE',
      createLinkEntityData(linkURL)
    )
    const entityKey = contentWithEntity.getLastCreatedEntityKey()
    const insertedTextContent = Modifier.replaceText(
      content,
      selection,
      linkText,
      undefined,
      entityKey
    )
    const insertedTextEditorState = EditorState.push(
      editorState,
      insertedTextContent,
      'insert-characters'
    )

    dispatch(
      setEditorState({
        state: insertedTextEditorState,
      })
    )

    // 各種の状態をリセット
    setLinkURL('')
    setErrLinkURL(false)
    handleOpen(false)
  }, [dispatch, editorState, handleOpen, linkText, linkURL])

  const shotCutKeyDownEvent = useCallback(
    (e: KeyboardEvent) => {
      if (e.code.toLowerCase() === 'escape') {
        handleOpen(false)
        dispatch(
          setEditorState({
            state: ChatInputEditorUtils.holdSelection(editorState),
          })
        )
        return
      }

      if (e.code.toLowerCase() !== 'enter') {
        return
      }
      if (
        disableInsertLink({
          linkText: linkText,
          linkURL: linkURL,
          errLinkURL: errLinkURL,
        })
      ) {
        return
      }
      handleAddLink()
    },
    [
      dispatch,
      editorState,
      errLinkURL,
      linkText,
      linkURL,
      handleAddLink,
      handleOpen,
    ]
  )

  useEffect(() => {
    // 入力Inputを動作する時に、stopPropagationようです、入力欄にフォーカスするときもenterKeyを検知するようにevent captureにします。
    window.addEventListener('keydown', shotCutKeyDownEvent, true)
    return () => {
      window.removeEventListener('keydown', shotCutKeyDownEvent, true)
    }
  }, [shotCutKeyDownEvent])

  useEffect(() => {
    const selection = editorState.getSelection()
    const currentContent = editorState.getCurrentContent()
    if (selection.isCollapsed()) {
      return
    }
    const selectedBlocks =
      ChatInputEditorUtils.getSelectedBlocks(editorState).toArray()

    // 一つの行だけ選択されてあることを確保します
    if (selectedBlocks.length !== 1) {
      return
    }

    const selectedBlock = selectedBlocks[0]
    const text = selectedBlock.getText()
    const startOffset = selection.getStartOffset()
    const endOffset = selection.getEndOffset()
    setLinkText(text.slice(startOffset, endOffset))

    const pureLinks = new Set()
    for (let i = startOffset; i < endOffset; i++) {
      const entityKey = selectedBlock.getEntityAt(i)
      if (entityKey === null) {
        break
      }
      const entity = currentContent.getEntity(entityKey)
      if (entity.getType() !== 'LINK') {
        break
      }
      const data = entity.getData()
      try {
        const v = data as LinkEntityData
        pureLinks.add(v.url)
      } catch (e) {
        consoleErrorWithAirbrake(
          `error at parse entity data to LinkEntityData, data: `,
          data
        )
      }
    }
    if (pureLinks.size === 1) {
      setLinkURL(pureLinks.values().next().value)
    }
  }, [editorState])

  return (
    <Grid className={styles.popUpContentGrid} columns="1fr">
      <Text weight="bold" content="リンクを挿入" />
      <Input
        className={styles.linkURLInput}
        label="表示するテキスト"
        fluid
        title="表示するテキスト"
        placeholder="表示するテキスト"
        value={linkText}
        onChange={(_, e) => {
          if (e?.value != null) {
            setLinkText(e.value)
          }
        }}
      />
      <Input
        className={styles.linkURLInput}
        label="アドレス"
        fluid
        title="既存のファイルまたはWebページにリンクします"
        placeholder="既存のファイルまたはWebページにリンクします"
        error={errLinkURL}
        value={linkURL}
        onChange={(_, e) => {
          if (e?.value != null) {
            setLinkURL(e.value)
            validateLinkURL({ linkURL: linkURL, setErrLinkURL: setErrLinkURL })
          }
        }}
      />
      <Grid className={styles.buttonGrid}>
        <Button
          fluid
          content="キャンセル"
          styles={{ gridArea: 'box4' }}
          onClick={() => handleOpen(false)}
        />
        <Button
          primary
          fluid
          content="挿入"
          styles={{ gridArea: 'box5' }}
          disabled={disableInsertLink({
            linkText: linkText,
            linkURL: linkURL,
            errLinkURL: errLinkURL,
          })}
          onClick={handleAddLink}
        />
      </Grid>
    </Grid>
  )
}

// 表示するテキスト, アドレスが入力されていない、またはURLのフォーマットが不正な場合はリンクを挿入できない
const disableInsertLink = (props: {
  linkText: string
  linkURL: string
  errLinkURL: boolean
}) => {
  const { linkText, linkURL, errLinkURL } = props
  if (!linkText) {
    return true
  }
  return !linkURL || errLinkURL ? true : false
}

const validateLinkURL = (props: {
  linkURL: string
  setErrLinkURL: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { linkURL, setErrLinkURL } = props
  // 未入力時はtrue
  if (!linkURL) return true

  // リクエスタ側Teamsのリンク機能がftpも対応していたのでそこに合わせている
  // 本家と同じくプロトコルの確認程度の簡易なバリデーション（http, https, ftpのみOK）
  const regexp = new RegExp(`^${regExpAutoLink}$`)
  if (regexp.test(linkURL)) {
    setErrLinkURL(false)
    return true
  }
  setErrLinkURL(true)
  return false
}
