import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

import {
  ListRecommendedFaqsRequest,
  ListRecommendedFaqsResponse,
  RecommendedFaq,
  RecommendedFaqSchedule,
  UpdateRecommendedFaqRequest,
} from '../../proto/recommended_faq_pb'
import { RecommendedFaqAPI } from '../../proto/recommended_faq_pb_service'
import { dateFromTimestamp } from '../../utils'
import { deskOperatorApi } from '../deskApiOperator'
import {
  RecommendedFaqEntity,
  RecommendedFaqScheduleEntity,
} from './types/RecommendedFaqEntity'

export const recommendedFaqResponseIntoRecommendedFaqEntity = (
  recommendedFaq: RecommendedFaq.AsObject
): RecommendedFaqEntity => ({
  id: String(recommendedFaq.id),
  title: recommendedFaq.title,
  request: recommendedFaq.request,
  response: recommendedFaq.response,
  createdAt: dateFromTimestamp(
    recommendedFaq.createdAt ?? new Timestamp().toObject()
  ).toISOString(),
  impact: recommendedFaq.impact,
  representativeTicketId: recommendedFaq.representativeTicketId,
  relatedTicketCount: recommendedFaq.relatedTicketCount,
})

const recommendedFaqScheduleResponseIntoRecommendedFaqScheduleEntity = (
  recommendedFaqSchedule: RecommendedFaqSchedule.AsObject
): RecommendedFaqScheduleEntity => {
  let executedAt: string | undefined
  let scheduledAt: string | undefined
  if (recommendedFaqSchedule.executedAt != null) {
    const dateStringArray = dateFromTimestamp(recommendedFaqSchedule.executedAt)
      .toISOString()
      .split('T')[0]
      .split('-')
    executedAt = `${dateStringArray[0]}年${dateStringArray[1]}月${dateStringArray[2]}日`
  }
  if (recommendedFaqSchedule.scheduledAt != null) {
    const dateStringArray = dateFromTimestamp(
      recommendedFaqSchedule.scheduledAt
    )
      .toISOString()
      .split('T')[0]
      .split('-')
    scheduledAt = `${dateStringArray[0]}年${dateStringArray[1]}月${dateStringArray[2]}日`
  }
  return {
    executedAt,
    scheduledAt,
  }
}

export const recommendedFaqApi = deskOperatorApi.injectEndpoints({
  endpoints: (build) => ({
    getRecommendedFaqList: build.query<
      {
        recommendedFaqEntities: RecommendedFaqEntity[]
        recommendedFaqScheduleEntity: RecommendedFaqScheduleEntity
        isConfigured: boolean
      },
      ListRecommendedFaqsRequest.AsObject
    >({
      query: () => ({
        service: RecommendedFaqAPI.ListRecommendedFaqs,
        body: (() => {
          const req = new ListRecommendedFaqsRequest()
          return req
        })(),
      }),
      transformResponse: (res: ListRecommendedFaqsResponse.AsObject) => {
        return {
          recommendedFaqEntities: res.recommendedFaqsList.map(
            recommendedFaqResponseIntoRecommendedFaqEntity
          ),
          recommendedFaqScheduleEntity:
            recommendedFaqScheduleResponseIntoRecommendedFaqScheduleEntity(
              res.schedule as RecommendedFaqSchedule.AsObject
            ),
          isConfigured: res.isConfigured,
        }
      },
      providesTags: ['RecommendedFaqs'],
    }),
    skipRecommendedFaq: build.mutation<void, RecommendedFaqEntity>({
      query: (arg) => ({
        service: RecommendedFaqAPI.UpdateRecommendedFaq,
        body: (() => {
          const req = new UpdateRecommendedFaqRequest()
          req.setRecommendedFaqId(Number(arg.id))
          req.setIsSkip(true)
          return req
        })(),
      }),
      invalidatesTags: (result) => {
        return result ? ['RecommendedFaqs'] : []
      },
    }),
  }),
})

export const { useGetRecommendedFaqListQuery, useSkipRecommendedFaqMutation } =
  recommendedFaqApi
