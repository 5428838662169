import type { EntityState } from '@reduxjs/toolkit'

import * as ActivityMessagePB from '../../proto/activity_message_pb'
import * as ActivityMessagePBService from '../../proto/activity_message_pb_service'
import { deskOperatorApi } from '../deskApiOperator'
import { activityMessagePlanEntityAdapter } from './slice'
import {
  ActivityMessagePlanEntity,
  ActivityMessagePlanStatus,
} from './types/ActivityMessagePlanEntity'
import type { ListActivityMessageArgs } from './types/ListActivityMessageArgs'

const activityMessagePlanListAPI = deskOperatorApi.injectEndpoints({
  endpoints: (build) => ({
    listActivityMessagePlans: build.query<
      { entity: EntityState<ActivityMessagePlanEntity>; total: number },
      ListActivityMessageArgs
    >({
      query: (arg) => ({
        service:
          ActivityMessagePBService.ActivityMessageService
            .ListActivityMessagePlans,
        body: (() => {
          const req = new ActivityMessagePB.ListActivityMessageRequest()
          req.setLimit(arg.limit)
          req.setPage(arg.page)
          return req
        })(),
      }),
      transformResponse(
        result: ActivityMessagePB.ListActivityMessageResponse.AsObject
      ) {
        return {
          entity: activityMessagePlanEntityAdapter.setAll(
            activityMessagePlanEntityAdapter.getInitialState(),
            result.activityMessagesList.map(protoIntoActivityMessagePlanEntity)
          ),
          total: result.total,
        }
      },
      providesTags: ['ActivityMessagePlans'],
    }),
  }),
})

export const { useListActivityMessagePlansQuery } = activityMessagePlanListAPI

export default activityMessagePlanListAPI

export function protoIntoActivityMessagePlanEntity(
  proto: ActivityMessagePB.ActivityMessage.AsObject
): ActivityMessagePlanEntity {
  return {
    id: proto.id,
    status: protoIntoActivityMessagePlanStatus(proto.status),
    body: proto.body,
    createdAt: new Date(proto.createdAt),
    sentAt: proto.sentat ? new Date(proto.sentat) : null,
    creatorID: proto.creatorId,
  }
}

export function protoIntoActivityMessagePlanStatus(
  proto: ActivityMessagePB.ActivityMessageStatusMap[keyof ActivityMessagePB.ActivityMessageStatusMap]
): ActivityMessagePlanStatus {
  switch (proto) {
    case ActivityMessagePB.ActivityMessageStatus.DRAFT:
      return ActivityMessagePlanStatus.Draft
    case ActivityMessagePB.ActivityMessageStatus.SCHEDULED:
      return ActivityMessagePlanStatus.Scheduled
    case ActivityMessagePB.ActivityMessageStatus.PROCESSING:
      return ActivityMessagePlanStatus.Processing
    case ActivityMessagePB.ActivityMessageStatus.DONE:
      return ActivityMessagePlanStatus.Done
    default:
      return ActivityMessagePlanStatus.Draft
  }
}
