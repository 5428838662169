import * as ActivityMessagePB from '../../proto/activity_message_pb'
import * as ActivityMessageServicePB from '../../proto/activity_message_pb_service'
import { deskOperatorApi } from '../deskApiOperator'
import { EditActivityMessagePlanArgs } from './types/EditActivityMessagePlanArgs'

const ActivityMessagePlanEditAPI = deskOperatorApi.injectEndpoints({
  endpoints: (build) => ({
    editPlan: build.mutation<void, EditActivityMessagePlanArgs>({
      query: (args) => ({
        service:
          ActivityMessageServicePB.ActivityMessageService
            .EditActivityMessagePlan,
        body: (() => {
          const req = new ActivityMessagePB.EditActivityMessagePlanRequest()

          req.setId(args.id)
          req.setBody(args.body)

          const receiver = new ActivityMessagePB.ActivityMessageReceiver()
          if (args.receiver.type === 'all') {
            receiver.setAll(true)
          } else if (args.receiver.type === 'receiverIDs') {
            const receiverUser =
              new ActivityMessagePB.ActivityMessageReceiverUsers()
            receiverUser.setUserIdsList(args.receiver.receiverIDs)
            receiver.setUserIds(receiverUser)
          }

          req.setDeleteAttachmentIdsList(args.deletingAttachmentIDs.map(Number))
          req.setCreateAttachmentsList(
            args.creatingAttachments.map((a) => {
              const req =
                new ActivityMessagePB.CreateActivityMessagePlanAttachment()
              req.setUrl(a.url ?? '')
              req.setName(a.fileName)
              req.setMimeType(a.mimeType)
              req.setItemId(a.itemID ?? '')
              req.setGroupId(a.groupID ?? '')
              return req
            })
          )
          req.setReceiver(receiver)

          return req
        })(),
      }),
      invalidatesTags: (_, __, args) => [
        'ActivityMessagePlans',
        { type: 'ActivityMessagePlanDetail', id: args.id },
      ],
    }),
  }),
})

export const { useEditPlanMutation } = ActivityMessagePlanEditAPI

export default ActivityMessagePlanEditAPI
