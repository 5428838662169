import { Datepicker, Flex, Text } from '@fluentui/react-northstar'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Dropdown } from '../../components/Dropdown'
import {
  customFormatter,
  displayDate,
  hourItems,
  minuteItems,
} from '../../utils'
import style from './TabConfig.module.css'
import {
  DefaultTabConfig,
  isCreatedAtValueFilter,
  setStartDateTime,
} from './tabConfigSlice'

const noResultsMessageHour = '00〜23の範囲で指定してください'
const noResultsMessageMinutes = '00〜59の範囲で指定してください'

const datePattern = 'yyyy/MM/dd'

type Props = {
  tabConfigFilterIndex: number
  tabConfigFilterState: DefaultTabConfig
}

const TabConfigCreatedAtFilter: React.FC<Props> = ({
  tabConfigFilterIndex,
  tabConfigFilterState,
}) => {
  const filter = tabConfigFilterState.filters[tabConfigFilterIndex].filter

  const dispatch = useDispatch()

  // 日付・時間のState管理を行う
  const initialStartDate =
    isCreatedAtValueFilter(filter) && 'startDate' in filter
      ? filter.startDate
      : undefined
  const initialEndDate =
    isCreatedAtValueFilter(filter) && 'endDate' in filter
      ? filter.endDate
      : undefined
  const initialStartHour =
    isCreatedAtValueFilter(filter) && 'startHour' in filter
      ? filter.startHour ?? ''
      : ''
  const initialStartMinute =
    isCreatedAtValueFilter(filter) && 'startMinute' in filter
      ? filter.startMinute ?? ''
      : ''
  const initialEndHour =
    isCreatedAtValueFilter(filter) && 'endHour' in filter
      ? filter.endHour ?? ''
      : ''
  const initialEndMinute =
    isCreatedAtValueFilter(filter) && 'endMinute' in filter
      ? filter.endMinute ?? ''
      : ''
  const [startDate, setStartDate] = useState(initialStartDate)
  const [startHour, setStartHour] = useState(initialStartHour)
  const [startMinute, setStartMinute] = useState(initialStartMinute)
  const [endDate, setEndDate] = useState(initialEndDate)
  const [endHour, setEndHour] = useState(initialEndHour)
  const [endMinute, setEndMinute] = useState(initialEndMinute)

  useEffect(() => {
    // 依頼日のコンポーネントの入力を管理する
    // Dateが入力されたらコンポーネントのStateに保存し、
    // その値の変更を検知し、DispatchでSliceのcreatedAtのStart, Endを更新する
    const createdAtFilter: {
      startDate?: string
      startHour?: string
      startMinute?: string
      endDate?: string
      endHour?: string
      endMinute?: string
    } = {}
    if (startDate != null) createdAtFilter.startDate = startDate
    if (startHour != '') createdAtFilter.startHour = startHour
    if (startMinute != '') createdAtFilter.startMinute = startMinute
    if (endDate != null) createdAtFilter.endDate = endDate
    if (endHour != '') createdAtFilter.endHour = endHour
    if (endMinute != '') createdAtFilter.endMinute = endMinute
    dispatch(setStartDateTime({ tabConfigFilterIndex, createdAtFilter }))
  }, [
    dispatch,
    startDate,
    endDate,
    startHour,
    startMinute,
    endHour,
    endMinute,
    tabConfigFilterIndex,
  ])
  return (
    <Flex column gap="gap.small" className={style.filter}>
      <Text content={'開始日'} className={style.createdAtFilterSubject} />
      <Datepicker
        allowManualInput={false}
        formatMonthDayYear={customFormatter}
        inputPlaceholder="日付を選択する"
        inputOnly
        input={{
          clearable: true,
          fluid: true,
        }}
        defaultSelectedDate={
          isCreatedAtValueFilter(filter)
            ? displayDate(filter.startDate)
            : undefined
        }
        onDateChange={(_, date) => {
          if (date?.value == null) return setStartDate(undefined)
          const formattedDateString = format(date.value, datePattern)
          setStartDate(formattedDateString)
        }}
      />
      <Flex gap="gap.smaller">
        <Dropdown
          fluid
          placeholder={'時'}
          noResultsMessage={noResultsMessageHour}
          checkable
          clearable={true}
          items={hourItems}
          value={[startHour]}
          onValueChange={(values: string[]) => {
            setStartHour(values[0])
          }}
          className={style.filterTimeDropdown}
        />
        <div className={style.timeSymbol}>:</div>
        <Dropdown
          fluid
          placeholder={'分'}
          noResultsMessage={noResultsMessageMinutes}
          checkable
          clearable={true}
          value={[startMinute]}
          items={minuteItems}
          onValueChange={(values: string[]) => {
            setStartMinute(values[0])
          }}
          className={style.filterTimeDropdown}
        />
      </Flex>
      <Text content={'終了日'} className={style.createdAtFilterSubject} />
      <Datepicker
        allowManualInput={false}
        formatMonthDayYear={customFormatter}
        inputPlaceholder="日付を選択する"
        inputOnly
        input={{
          clearable: true,
          fluid: true,
        }}
        defaultSelectedDate={
          isCreatedAtValueFilter(filter)
            ? displayDate(filter.endDate)
            : undefined
        }
        onDateChange={(_, date) => {
          if (date?.value == null) return setEndDate(undefined)
          const formattedDateString = format(date.value, datePattern)
          setEndDate(formattedDateString)
        }}
      />
      <Flex gap="gap.smaller">
        <Dropdown
          fluid
          placeholder={'時'}
          noResultsMessage={noResultsMessageHour}
          checkable
          clearable={true}
          items={hourItems}
          value={[endHour]}
          onValueChange={(values: string[]) => {
            setEndHour(values[0])
          }}
          className={style.filterTimeDropdown}
        />
        <div className={style.timeSymbol}>:</div>
        <Dropdown
          fluid
          placeholder={'分'}
          noResultsMessage={noResultsMessageMinutes}
          checkable
          clearable={true}
          value={[endMinute]}
          items={minuteItems}
          onValueChange={(values: string[]) => {
            setEndMinute(values[0])
          }}
          className={style.filterTimeDropdown}
        />
      </Flex>
    </Flex>
  )
}

export default TabConfigCreatedAtFilter
