// package: 
// file: proto/tab_added_group.proto

var proto_tab_added_group_pb = require("../proto/tab_added_group_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TabAddedGroupAPI = (function () {
  function TabAddedGroupAPI() {}
  TabAddedGroupAPI.serviceName = "TabAddedGroupAPI";
  return TabAddedGroupAPI;
}());

TabAddedGroupAPI.CreateTabAddedGroup = {
  methodName: "CreateTabAddedGroup",
  service: TabAddedGroupAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_tab_added_group_pb.CreateTabAddedGroupRequest,
  responseType: google_protobuf_empty_pb.Empty
};

TabAddedGroupAPI.ListTabAddedGroup = {
  methodName: "ListTabAddedGroup",
  service: TabAddedGroupAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_tab_added_group_pb.ListTabAddedGroupRequest,
  responseType: proto_tab_added_group_pb.ListTabAddedGroupResponse
};

exports.TabAddedGroupAPI = TabAddedGroupAPI;

function TabAddedGroupAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TabAddedGroupAPIClient.prototype.createTabAddedGroup = function createTabAddedGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TabAddedGroupAPI.CreateTabAddedGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TabAddedGroupAPIClient.prototype.listTabAddedGroup = function listTabAddedGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TabAddedGroupAPI.ListTabAddedGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TabAddedGroupAPIClient = TabAddedGroupAPIClient;

