import { ElementNode, LexicalNode } from 'lexical'

export class ImportantNode extends ElementNode {
  static getType(): string {
    return 'important'
  }

  static clone(node: ElementNode): ImportantNode {
    return new ImportantNode(node.__key)
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div')
    dom.className = 'important'
    return dom
  }

  updateDOM(): boolean {
    return false
  }

  static importJSON(): ImportantNode {
    return $createImportantNode()
  }

  exportJSON() {
    return super.exportJSON()
  }
}

export function $createImportantNode(): ImportantNode {
  return new ImportantNode()
}

export function $isImportantNode(
  node: LexicalNode | null
): node is ImportantNode {
  if (!node) return false
  return node.__type === ImportantNode.getType()
}
