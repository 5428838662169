// package: 
// file: proto/activity_message.proto

var proto_activity_message_pb = require("../proto/activity_message_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ActivityMessageService = (function () {
  function ActivityMessageService() {}
  ActivityMessageService.serviceName = "ActivityMessageService";
  return ActivityMessageService;
}());

ActivityMessageService.GetActivityMessagePlan = {
  methodName: "GetActivityMessagePlan",
  service: ActivityMessageService,
  requestStream: false,
  responseStream: false,
  requestType: proto_activity_message_pb.GetActivityMessagePlanRequest,
  responseType: proto_activity_message_pb.GetActivityMessagePlanResponse
};

ActivityMessageService.ListActivityMessagePlans = {
  methodName: "ListActivityMessagePlans",
  service: ActivityMessageService,
  requestStream: false,
  responseStream: false,
  requestType: proto_activity_message_pb.ListActivityMessageRequest,
  responseType: proto_activity_message_pb.ListActivityMessageResponse
};

ActivityMessageService.CreateDraftActivityMessagePlan = {
  methodName: "CreateDraftActivityMessagePlan",
  service: ActivityMessageService,
  requestStream: false,
  responseStream: false,
  requestType: proto_activity_message_pb.CreateActivityMessagePlanRequest,
  responseType: proto_activity_message_pb.CreateActivityMessagePlanResponse
};

ActivityMessageService.PublishActivityMessagePlan = {
  methodName: "PublishActivityMessagePlan",
  service: ActivityMessageService,
  requestStream: false,
  responseStream: false,
  requestType: proto_activity_message_pb.PublishActivityMessagePlanRequest,
  responseType: proto_activity_message_pb.PublishActivityMessagePlanResponse
};

ActivityMessageService.EditActivityMessagePlan = {
  methodName: "EditActivityMessagePlan",
  service: ActivityMessageService,
  requestStream: false,
  responseStream: false,
  requestType: proto_activity_message_pb.EditActivityMessagePlanRequest,
  responseType: proto_activity_message_pb.EditActivityMessagePlanResponse
};

ActivityMessageService.DeleteActivityMessagePlan = {
  methodName: "DeleteActivityMessagePlan",
  service: ActivityMessageService,
  requestStream: false,
  responseStream: false,
  requestType: proto_activity_message_pb.DeleteActivityMessagePlanRequest,
  responseType: proto_activity_message_pb.DeleteActivityMessagePlanResponse
};

ActivityMessageService.ActivityMessageReceiversPreview = {
  methodName: "ActivityMessageReceiversPreview",
  service: ActivityMessageService,
  requestStream: false,
  responseStream: false,
  requestType: proto_activity_message_pb.ActivityMessageReceiversPreviewRequest,
  responseType: proto_activity_message_pb.ActivityMessageReceiversPreviewResponse
};

ActivityMessageService.AvailableActivityMessage = {
  methodName: "AvailableActivityMessage",
  service: ActivityMessageService,
  requestStream: false,
  responseStream: false,
  requestType: proto_activity_message_pb.AvailableActivityMessageRequest,
  responseType: proto_activity_message_pb.AvailableActivityMessageResponse
};

ActivityMessageService.NotificationAvailableMembers = {
  methodName: "NotificationAvailableMembers",
  service: ActivityMessageService,
  requestStream: false,
  responseStream: false,
  requestType: proto_activity_message_pb.NotificationAvailableMembersRequest,
  responseType: proto_activity_message_pb.NotificationAvailableMembersResponse
};

exports.ActivityMessageService = ActivityMessageService;

function ActivityMessageServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ActivityMessageServiceClient.prototype.getActivityMessagePlan = function getActivityMessagePlan(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ActivityMessageService.GetActivityMessagePlan, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ActivityMessageServiceClient.prototype.listActivityMessagePlans = function listActivityMessagePlans(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ActivityMessageService.ListActivityMessagePlans, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ActivityMessageServiceClient.prototype.createDraftActivityMessagePlan = function createDraftActivityMessagePlan(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ActivityMessageService.CreateDraftActivityMessagePlan, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ActivityMessageServiceClient.prototype.publishActivityMessagePlan = function publishActivityMessagePlan(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ActivityMessageService.PublishActivityMessagePlan, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ActivityMessageServiceClient.prototype.editActivityMessagePlan = function editActivityMessagePlan(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ActivityMessageService.EditActivityMessagePlan, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ActivityMessageServiceClient.prototype.deleteActivityMessagePlan = function deleteActivityMessagePlan(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ActivityMessageService.DeleteActivityMessagePlan, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ActivityMessageServiceClient.prototype.activityMessageReceiversPreview = function activityMessageReceiversPreview(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ActivityMessageService.ActivityMessageReceiversPreview, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ActivityMessageServiceClient.prototype.availableActivityMessage = function availableActivityMessage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ActivityMessageService.AvailableActivityMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ActivityMessageServiceClient.prototype.notificationAvailableMembers = function notificationAvailableMembers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ActivityMessageService.NotificationAvailableMembers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ActivityMessageServiceClient = ActivityMessageServiceClient;

