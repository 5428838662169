import * as React from 'react'
import * as ReactRedux from 'react-redux'

import { RootState } from '../../../../app/store'
import RequesterChatButtonGroup from '../../../../components/RequesterChatButtonGroup'
import RequesterReplyItem from '../../../../components/RequesterReplyItem'
import { defaultAppIcon, defaultAppName } from '../../../../consts'
import * as GraphApi from '../../../graphApi'
import * as AuthSelectors from '../../../RequesterAuth/selectors'
import { useSendRequesterReplyButtonMutation } from '../../api'
import { actions } from '../../slice'
import { LastReplyText } from '../RequesterChatText'
import * as Selectors from './selectors'

type Props = {
  ticketId: string
}
const RequesterLastReplyItem: React.FC<Props> = (props) => {
  const lastReplyId = ReactRedux.useSelector((state: RootState) =>
    Selectors.lastReplyId(state, props.ticketId)
  )
  const isRequesterMessage = ReactRedux.useSelector((state: RootState) =>
    Selectors.isRequesterMessage(state, props.ticketId)
  )
  const isOwnMessage = ReactRedux.useSelector((state: RootState) =>
    Selectors.isOwnMessage(state, props.ticketId)
  )
  const authorId = ReactRedux.useSelector((state: RootState) =>
    Selectors.authorId(state, props.ticketId)
  )
  const time = ReactRedux.useSelector((state: RootState) =>
    Selectors.time(state, props.ticketId)
  )
  const fileProps = ReactRedux.useSelector((state: RootState) =>
    Selectors.fileProps(state, props.ticketId)
  )
  const buttonGroup = ReactRedux.useSelector((state: RootState) =>
    Selectors.buttonGroup(state, props.ticketId)
  )
  const isRenderButtonGroup = ReactRedux.useSelector((state: RootState) =>
    Selectors.isRenderButtonGroup(state, props.ticketId)
  )
  const appId = ReactRedux.useSelector(AuthSelectors.appId)

  const { data: member } = GraphApi.useGetMemberQuery({ id: authorId })
  const { data: photo } = GraphApi.useGetMemberPhotoQuery({ id: authorId })
  const teamsCustomApplicationQuery =
    GraphApi.useGetTeamsCustomApplicationInfoQuery({ externalId: appId })
  const [sendRequesterReplyButton] = useSendRequesterReplyButtonMutation()
  const dispatch = ReactRedux.useDispatch()

  const clickButtonGroup = React.useCallback(
    (key: string) => {
      const button = buttonGroup
        .flatMap((row) => row)
        .find((b) => b.key === key)
      if (!button) return
      if (button.url) {
        window.open(button.url, '_blank')
        return
      }
      sendRequesterReplyButton({
        ticketId: props.ticketId,
        text: button.label,
        type: button.type,
        data: button.data,
      })

      // trigger handleReplies
      dispatch(
        actions.setTicketRepliesOpen({
          conversationID: props.ticketId,
          open: true,
        })
      )
    },
    [props, sendRequesterReplyButton, buttonGroup, dispatch]
  )
  const renderButtonGroup = React.useCallback(() => {
    if (!isRenderButtonGroup) return <></>
    return (
      <RequesterChatButtonGroup
        buttons={buttonGroup}
        onClick={clickButtonGroup}
      />
    )
  }, [buttonGroup, clickButtonGroup, isRenderButtonGroup])

  return (
    <RequesterReplyItem
      id={lastReplyId}
      name={
        isRequesterMessage && member != null
          ? member.name
          : teamsCustomApplicationQuery.isLoading
          ? ''
          : teamsCustomApplicationQuery.data?.displayName ?? defaultAppName
      }
      time={time}
      avatarImage={
        isRequesterMessage
          ? photo ?? ''
          : teamsCustomApplicationQuery.isLoading
          ? ''
          : teamsCustomApplicationQuery.data?.iconURL ?? defaultAppIcon
      }
      isOwnMessage={isOwnMessage}
      isSimpleMessage={false}
      isHighlighted={false}
      files={fileProps}
      renderButtonGroups={renderButtonGroup}
      renderChatText={() => <LastReplyText conversationId={props.ticketId} />}
    />
  )
}

export default React.memo(RequesterLastReplyItem)
