import { Checkbox, Flex } from '@fluentui/react-northstar'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import ticket_custom_field from '../../../proto/ticket_custom_field_pb' // FIXME: スネークケースになっているのでリファクタ
import style from '../TabConfig.module.css'
import {
  isCustomCheckboxValueFilter,
  resetFilter,
  setCustomCheckboxFilterValue,
} from '../tabConfigSlice'

type Props = {
  customField: ticket_custom_field.TicketCustomField.AsObject
  tabConfigFilterIndex: number
}

const TicketCustomFieldValueCheckboxFilter: React.FC<Props> = ({
  customField,
  tabConfigFilterIndex,
}) => {
  const tabConfigState = useSelector((state: RootState) => state.tabConfig)
  const filter = tabConfigState.filters[tabConfigFilterIndex].filter
  const checkBoxFilterValues = isCustomCheckboxValueFilter(filter)
    ? filter.values
    : []

  const dispatch = useDispatch()
  return (
    <Flex column hAlign="start" className={style.filter}>
      <Flex column hAlign="start">
        <Checkbox
          label="チェック済み"
          labelPosition="end"
          defaultChecked={false}
          checked={
            isCustomCheckboxValueFilter(filter)
              ? checkBoxFilterValues.includes(1)
              : false
          }
          onChange={(_, value) => {
            if (value?.checked) {
              dispatch(
                setCustomCheckboxFilterValue({
                  customFieldId: customField.id,
                  tabConfigFilterIndex,
                  values: [...checkBoxFilterValues, 1],
                })
              )
            } else {
              checkBoxFilterValues.length === 1
                ? dispatch(
                    resetFilter({
                      tabConfigFilterIndex,
                    })
                  )
                : dispatch(
                    setCustomCheckboxFilterValue({
                      customFieldId: customField.id,
                      tabConfigFilterIndex,
                      values: checkBoxFilterValues.filter((fv) => fv !== 1),
                    })
                  )
            }
          }}
        ></Checkbox>
        <Checkbox
          label="未チェック"
          labelPosition="end"
          defaultChecked={false}
          checked={
            isCustomCheckboxValueFilter(filter)
              ? checkBoxFilterValues.includes(0)
              : false
          }
          onChange={(_, value) => {
            if (value?.checked) {
              dispatch(
                setCustomCheckboxFilterValue({
                  customFieldId: customField.id,
                  tabConfigFilterIndex,
                  values: [...checkBoxFilterValues, 0],
                })
              )
            } else {
              checkBoxFilterValues.length === 1
                ? dispatch(
                    resetFilter({
                      tabConfigFilterIndex,
                    })
                  )
                : dispatch(
                    setCustomCheckboxFilterValue({
                      customFieldId: customField.id,
                      tabConfigFilterIndex,
                      values: checkBoxFilterValues.filter((fv) => fv !== 0),
                    })
                  )
            }
          }}
        ></Checkbox>
      </Flex>
    </Flex>
  )
}

export default TicketCustomFieldValueCheckboxFilter
