import { grpc } from '@improbable-eng/grpc-web'
import * as graph from '@microsoft/microsoft-graph-client'

import { grpcClient } from './grpcClient'

export interface Services {
  graphAPI: graph.Client
  grpcClient: <
    TRequest extends grpc.ProtobufMessage,
    TResponse extends grpc.ProtobufMessage
  >(
    methodDefinition: grpc.MethodDefinition<TRequest, TResponse>
  ) => grpc.Client<TRequest, TResponse>
  localStorage: Storage
}

export const services: Services = {
  graphAPI: graph.Client.init({
    authProvider: (done) => {
      done('access token has not been set', null)
    },
  }),
  grpcClient: grpcClient,
  localStorage: localStorage,
}
