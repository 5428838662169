import * as microsoftTeams from '@microsoft/teams-js'
import React from 'react'
import { useDispatch } from 'react-redux'

import { deleteTab } from './tabConfigSlice'

export const TabDelete: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    microsoftTeams.pages.config.setValidityState(true)
  }, [])

  React.useEffect(() => {
    microsoftTeams.pages.getConfig().then((config) => {
      microsoftTeams.pages.config.registerOnRemoveHandler((removeEvent) => {
        const entityId = config.entityId
        if (entityId != null) {
          dispatch(deleteTab(entityId, removeEvent))
        }
      })
    })
  }, [dispatch])

  return (
    <div style={{ overflow: 'hidden' }}>
      <p>
        タブを削除しても、問い合わせ内容が消えることはございません。別のタブや新しいタブを設定することで、これまでのこのチャネルで受けている問い合わせ内容を確認することが可能です。
      </p>
      <p>
        ただし、過去に受けた通知メッセージなどに含まれているリンクがこのタブを指している場合には、リンクが動作しなくなりますのでご注意ください。
      </p>
      <p>
        <b>タブを削除しますか？</b>
      </p>
    </div>
  )
}
