import { grpc } from '@improbable-eng/grpc-web'
import {
  EntityId,
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'

import { AppThunk, RootState } from '../../app/store'
import ticket_custom_field_pb from '../../proto/ticket_custom_field_pb'
import ticket_custom_field_pb_service from '../../proto/ticket_custom_field_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from '../auth/authSlice'

export declare type CustomField =
  ticket_custom_field_pb.TicketCustomField.AsObject
export const customFieldAdapter = createEntityAdapter<CustomField>({
  selectId: (customField) => customField.id,
})

export interface CustomFieldState {
  loading: boolean
  error: string | null
}

export const customFieldSlice = createSlice({
  name: 'customFields',
  initialState: customFieldAdapter.getInitialState<CustomFieldState>({
    loading: false,
    error: null,
  }),
  reducers: {
    requestStart(state) {
      state.error = null
      state.loading = true
    },
    fetchCustomFieldsOnMessage(
      state,
      action: PayloadAction<{
        message: ticket_custom_field_pb.ListTicketCustomFieldsResponse.AsObject
        pageIndex: number
        pageSize: number
      }>
    ) {
      customFieldAdapter.setAll(
        state,
        action.payload.message.ticketCustomFieldsList.map((f, index) => ({
          ...f,
          sequence: index,
        }))
      )
    },
    fetchCustomFieldsOnEnd(
      state,
      action: PayloadAction<{ code: grpc.Code; message: string }>
    ) {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = null
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
    createCustomFieldOnMessage(
      state,
      action: PayloadAction<{
        message: ticket_custom_field_pb.TicketCustomField.AsObject
      }>
    ) {
      try {
        customFieldAdapter.addOne(state, action.payload.message)
      } catch (e) {
        state.error = e.toString()
      }
    },
    updateCustomFieldOnMessage(
      state,
      action: PayloadAction<{
        message: ticket_custom_field_pb.TicketCustomField.AsObject
      }>
    ) {
      try {
        customFieldAdapter.upsertOne(state, action.payload.message)
      } catch (e) {
        state.error = e.toString()
      }
    },
    deleteCustomFieldOnMessage(
      state,
      action: PayloadAction<{
        key: EntityId
      }>
    ) {
      try {
        customFieldAdapter.removeOne(state, action.payload.key)
      } catch (e) {
        state.error = e.toString()
      }
    },
    modificationRequestOnEnd(
      state,
      action: PayloadAction<{ code: grpc.Code; message: string }>
    ) {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = null
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
    setCustomFields(state, action: PayloadAction<CustomField[]>) {
      customFieldAdapter.setAll(state, action.payload)
    },
  },
})

export const {
  requestStart,
  fetchCustomFieldsOnMessage,
  fetchCustomFieldsOnEnd,
  createCustomFieldOnMessage,
  updateCustomFieldOnMessage,
  deleteCustomFieldOnMessage,
  modificationRequestOnEnd,
  setCustomFields,
} = customFieldSlice.actions
export default customFieldSlice.reducer

export const customFieldSelector = customFieldAdapter.getSelectors(
  (state: RootState) => state.customField
)

export const fetchCustomFields =
  (pageIndex: number, pageSize: number): AppThunk =>
  (dispatch, getState, { grpcClient }) => {
    dispatch(customFieldSlice.actions.requestStart())
    const client = grpcClient<
      ticket_custom_field_pb.ListTicketCustomFieldsRequest,
      ticket_custom_field_pb.ListTicketCustomFieldsResponse
    >(
      ticket_custom_field_pb_service.TicketCustomFieldAPI.ListTicketCustomFields
    )
    const req = new ticket_custom_field_pb.ListTicketCustomFieldsRequest()
    req.setLimit(pageSize)
    req.setOffset(pageSize * pageIndex)

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      dispatch(
        fetchCustomFieldsOnMessage({
          message: message.toObject(),
          pageIndex,
          pageSize,
        })
      )
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(fetchCustomFieldsOnEnd({ code, message }))
    })
    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const createCustomField =
  (
    ticketCustomField: ticket_custom_field_pb.TicketCustomField.AsObject
  ): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    dispatch(customFieldSlice.actions.requestStart())
    const client = grpcClient<
      ticket_custom_field_pb.TicketCustomField,
      ticket_custom_field_pb.TicketCustomField
    >(
      ticket_custom_field_pb_service.TicketCustomFieldAPI
        .CreateTicketCustomField
    )
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)
    client.onMessage((message) => {
      dispatch(
        createCustomFieldOnMessage({
          message: message.toObject(),
        })
      )
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(modificationRequestOnEnd({ code, message }))
    })
    client.start(meta)
    client.send(convertIntoTicketCustomField(ticketCustomField))
    client.finishSend()
  }

export const updateCustomField =
  (
    ticketCustomField: ticket_custom_field_pb.TicketCustomField.AsObject
  ): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    dispatch(customFieldSlice.actions.requestStart())
    const client = grpcClient<
      ticket_custom_field_pb.TicketCustomField,
      ticket_custom_field_pb.TicketCustomField
    >(
      ticket_custom_field_pb_service.TicketCustomFieldAPI
        .UpdateTicketCustomField
    )
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)
    client.onMessage((message) => {
      dispatch(
        updateCustomFieldOnMessage({
          message: message.toObject(),
        })
      )
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(modificationRequestOnEnd({ code, message }))
    })
    client.start(meta)
    client.send(convertIntoTicketCustomField(ticketCustomField))
    client.finishSend()
  }

export const deleteCustomField =
  (
    ticketCustomField: ticket_custom_field_pb.TicketCustomField.AsObject
  ): AppThunk =>
  (dispatch, getState, { grpcClient }) => {
    dispatch(customFieldSlice.actions.requestStart())
    const client = grpcClient<
      ticket_custom_field_pb.DeleteTicketCustomFieldRequest,
      Empty
    >(
      ticket_custom_field_pb_service.TicketCustomFieldAPI
        .DeleteTicketCustomField
    )
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)
    const req = new ticket_custom_field_pb.DeleteTicketCustomFieldRequest()
    req.setId(ticketCustomField.id)
    client.onMessage(() => {
      dispatch(
        deleteCustomFieldOnMessage({
          key: ticketCustomField.id,
        })
      )
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(modificationRequestOnEnd({ code, message }))
    })
    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const updateFieldSequences =
  (ids: number[]): AppThunk =>
  (dispatch, getState, { grpcClient }) => {
    dispatch(requestStart())
    const client = grpcClient<
      ticket_custom_field_pb.UpdateFieldSequencesRequest,
      Empty
    >(ticket_custom_field_pb_service.TicketCustomFieldAPI.UpdateFieldSequences)
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(modificationRequestOnEnd({ code, message }))
    })
    client.start(meta)
    client.send(convertIntoUpdateFieldSequenceRequest(ids))
    client.finishSend()
  }

const convertIntoTicketCustomField = (
  obj: ticket_custom_field_pb.TicketCustomField.AsObject
) => {
  const customField = new ticket_custom_field_pb.TicketCustomField()
  customField.setId(obj.id)
  customField.setName(obj.name)
  customField.setType(obj.type)
  customField.setOptionsList(
    obj.optionsList.map((option) => convertIntoOption(option))
  )
  customField.setSlotContextName(obj.slotContextName)
  customField.setEditable(obj.editable)
  return customField
}

const convertIntoOption = (
  obj: ticket_custom_field_pb.TicketCustomFieldOption.AsObject
) => {
  const option = new ticket_custom_field_pb.TicketCustomFieldOption()
  option.setId(obj.id)
  option.setValue(obj.value)
  return option
}

const convertIntoUpdateFieldSequenceRequest = (ids: number[]) => {
  const req = new ticket_custom_field_pb.UpdateFieldSequencesRequest()
  req.setIdsList(ids)
  return req
}
