import * as React from 'react'

import * as GraphAPI from '../../../graphApi'
import ActivityMessagePlanListAvatarPresenter from './presenter'

type Props = {
  id: string
}
const ActivityMessagePlanListAvatar: React.FC<Props> = (props) => {
  const { data: member, isLoading: isMemberLoading } =
    GraphAPI.useGetMemberQuery({
      id: props.id,
    })
  const { data: photo, isLoading: isPhotoLoading } =
    GraphAPI.useGetMemberPhotoQuery({
      id: props.id,
    })

  if (isMemberLoading || !member || !photo || isPhotoLoading) {
    return <></>
  }

  return (
    <ActivityMessagePlanListAvatarPresenter name={member.name} src={photo} />
  )
}

export default React.memo(ActivityMessagePlanListAvatar)
