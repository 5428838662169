import * as RTK from '@reduxjs/toolkit'

import { attachmentEntityAdapter } from '../ActivityMessageAttachment/lib/entityAdapter'
import { AttachmentItem } from '../ActivityMessageAttachment/types/AttachmentItem'
import * as Operations from './operations'

export type State = RTK.EntityState<AttachmentItem>
const slice = RTK.createSlice({
  name: 'ActivityMessagePlanNewForm',
  initialState: attachmentEntityAdapter.getInitialState(),
  reducers: {
    reset: (state) => {
      return attachmentEntityAdapter.removeAll(state)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(Operations.uploadFile.pending, (state, action) => {
      return attachmentEntityAdapter.addOne(state, {
        id: action.meta.arg.id,
        status: 'pending',
        mimeType: action.meta.arg.file.type,
        fileName: action.meta.arg.file.name,
      })
    })
    builder.addCase(Operations.uploadFile.fulfilled, (state, action) => {
      return attachmentEntityAdapter.updateOne(state, {
        id: action.meta.arg.id,
        changes: {
          status: 'success',
          url: action.payload.url,
          itemID: action.payload.itemID,
          groupID: action.payload.groupID,
        },
      })
    })
    builder.addCase(Operations.uploadFile.rejected, (state, action) => {
      return attachmentEntityAdapter.updateOne(state, {
        id: action.meta.arg.id,
        changes: {
          status: 'error',
        },
      })
    })
    builder.addCase(Operations.removeFile.fulfilled, (state, action) => {
      return attachmentEntityAdapter.removeOne(state, action.meta.arg.id)
    })
  },
})

export const { actions, reducer } = slice
