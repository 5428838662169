import * as React from 'react'

import styles from './styles.module.css'

type Props = {
  showAllAutoMessage: boolean
  onClick: () => void
}

const RecommendedFaqTicketChatPastAutoChatTogglePresenter: React.FC<Props> = (
  props
) => {
  return (
    <div className={styles.container}>
      <div className={styles.content} onClick={props.onClick}>
        <div className={`${styles.arrow} ${styles.text}`}>
          {props.showAllAutoMessage ? '↓' : '↑'}
        </div>
        <div>
          <span className={styles.text}>
            {props.showAllAutoMessage
              ? '最新の自動応答履歴のみ表示'
              : '過去100件までの自動応答履歴を表示'}
          </span>
        </div>
      </div>
    </div>
  )
}

export default React.memo(RecommendedFaqTicketChatPastAutoChatTogglePresenter)
