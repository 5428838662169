import { Button } from '@fluentui/react-northstar'
import * as microsoftTeams from '@microsoft/teams-js'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { consoleErrorWithAirbrake, getQueryParamsByKey } from '../../../utils'
import { fetchDefaultConfig, setInitialFilter } from '.././ticketSlice'
import { customFieldSelector } from '../customFieldSlice'
import styles from './Pill.module.css'

const ResetFilterButton: React.FC = () => {
  const usersState = useSelector((state: RootState) => state.users)
  const customFields = useSelector(customFieldSelector.selectAll)
  const dispatch = useDispatch()

  const [tabUUID, setTabUUID] = useState('')

  useEffect(() => {
    microsoftTeams.pages
      .getConfig()
      .then((settings) => {
        const tabId = getQueryParamsByKey(settings.contentUrl, 'tab_id')[0]
        if (tabId != null) {
          setTabUUID(tabId)
        }
      })
      .catch((e) => {
        consoleErrorWithAirbrake(`failed set tab uuid: ${e}`)
      })
  }, [dispatch, customFields])

  const meId = usersState.meId
  /* meId is not fetched */
  if (meId == null) return null

  return (
    <Button
      className={styles.resetButton}
      onClick={() => {
        if (tabUUID != '') {
          dispatch(fetchDefaultConfig(tabUUID, customFields, meId))
        } else {
          dispatch(setInitialFilter({ meId }))
        }
      }}
      content="元の条件に戻す"
      text
    />
  )
}

export default ResetFilterButton
