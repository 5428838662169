import { grpc } from '@improbable-eng/grpc-web'
import * as RTK from '@reduxjs/toolkit'
import compareAsc from 'date-fns/compareAsc'
import parseISO from 'date-fns/fp/parseISO'

import { GRPCErrorResponseObject } from '../deskApiCommon/common'
import { deskOperatorApi } from '../deskApiOperator'
import { recommendedFaqApi } from './api'
import { RecommendedFaqEntity } from './types/RecommendedFaqEntity'

export const recommendedFaqEntityAdapter =
  RTK.createEntityAdapter<RecommendedFaqEntity>({
    sortComparer: (a, b) => {
      return compareAsc(parseISO(a.createdAt), parseISO(b.createdAt))
    },
  })

export type State = {
  isConfigured: boolean
  scheduledAt?: string
  executedAt?: string
  selectedRecommendedFaq: RecommendedFaqEntity | null
  error: { message: string } | null
}

const initialState: State = {
  isConfigured: false,
  scheduledAt: undefined,
  executedAt: undefined,
  selectedRecommendedFaq: null,
  error: null,
}

const slice = RTK.createSlice({
  name: 'recommendedFaqList',
  initialState:
    recommendedFaqEntityAdapter.getInitialState<State>(initialState),
  reducers: {
    selectRecommendedFaq: (
      state,
      action: RTK.PayloadAction<{ faq: RecommendedFaqEntity }>
    ) => {
      state.selectedRecommendedFaq = action.payload.faq
    },
    unselectRecommendedFaq: (state) => {
      state.selectedRecommendedFaq = null
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      recommendedFaqApi.endpoints.getRecommendedFaqList.matchFulfilled,
      (state, action) => {
        state.error = null
        state.scheduledAt =
          action.payload.recommendedFaqScheduleEntity.scheduledAt
        state.executedAt =
          action.payload.recommendedFaqScheduleEntity.executedAt
        state.isConfigured = action.payload.isConfigured
      }
    ),
      builder.addMatcher(
        recommendedFaqApi.endpoints.getRecommendedFaqList.matchRejected,
        (state, action) => {
          /* 
          RTK Queryのhooksが複数回呼び出される時(or それ以外の何らかの場合)にエラーではなくaction.payloadがundefinedになることが確認できた。
          エラーのレスポンスの時以外はerror stateにメッセージを設定すべきではないのでこのようにしている
          FIXME: 根本解決としてはreject時にundefinedになってしまうリクエストを送らないことなので修正したい（原因不明）
          */
          const error = action.payload as GRPCErrorResponseObject
          if (error != null && error.code !== grpc.Code.OK) {
            state.error = {
              message:
                'AIアシスタントが提案するFAQ一覧の取得で問題が発生しました。しばらくお待ちいただいてから、もう一度お試しください。',
            }
          }
        }
      )
    builder.addMatcher(
      deskOperatorApi.endpoints.createFaq.matchRejected,
      (state, action) => {
        const error = action.payload as GRPCErrorResponseObject
        if (error != null && error.code !== grpc.Code.OK) {
          state.error = {
            message:
              'FAQの作成で問題が発生しました。しばらくお待ちいただいてから、もう一度お試しください。',
          }
        }
      }
    ),
      builder.addMatcher(
        recommendedFaqApi.endpoints.skipRecommendedFaq.matchRejected,
        (state, action) => {
          const error = action.payload as GRPCErrorResponseObject
          if (error != null && error.code !== grpc.Code.OK) {
            state.error = {
              message:
                'FAQ作成のスキップで問題が発生しました。しばらくお待ちいただいてから、もう一度お試しください。',
            }
          }
        }
      )
  },
})

export const { reducer, actions } = slice
