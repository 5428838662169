// all of the following is defined on desk-server and stored in elasticSearch
export interface OneDriveData {
  name: string
  webUrl: string
  downloadUrl: string
  itemId: string
  uniqueId: string
  size: number
  mimeType: string
  driveId: string
}

export interface Thumbnail {
  height: number
  width: number
  url: string
}

export interface BotChannelStorageImageInfo {
  attachmentId: string
  viewId: string
}

export interface TextHtmlData {
  content: string
}

export const PastedImageEntityType = `image_pasted`
export const HorizontalLineEntityType = `hr`
