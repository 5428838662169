import {
  Flex,
  Pill,
  Skeleton,
  SkeletonShape,
  Text,
} from '@fluentui/react-northstar'
import { AadUserConversationMember } from '@microsoft/microsoft-graph-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import CompactUserNames, {
  getUserNamesFromMembers,
} from '../../../components/CompactUserNames'
import { setAssignedUserIds } from '../ticketSlice'
import styles from './Pill.module.css'

const AssignedUsersFilterPill: React.FC = () => {
  const ticketState = useSelector((state: RootState) => state.ticket)
  const memberState = useSelector((state: RootState) => state.member)
  const members = memberState.ids.map(
    (id) => memberState.entities[id] as AadUserConversationMember
  )
  const usersState = useSelector((state: RootState) => state.users)
  const initializing = usersState.meId == null || members.length === 0
  const dispatch = useDispatch()
  const assignedUserNames = () => {
    const assignedUserIds = ticketState.filters.assignedUserIds ?? []
    return (
      <CompactUserNames
        userNames={getUserNamesFromMembers(assignedUserIds, members)}
      />
    )
  }

  return (
    <>
      {initializing ? (
        <Skeleton
          animation="pulse"
          className={styles.skelton}
          content={
            <SkeletonShape
              className={styles.skeltonShape}
              content={<Pill className={styles.pillSkelton} />}
            />
          }
        />
      ) : (
        <Pill
          actionable={
            ticketState.filters.assignedUserIds != null &&
            ticketState.filters.assignedUserIds.length > 0
          }
          onDismiss={() =>
            dispatch(setAssignedUserIds({ assignedUserIds: [] }))
          }
          className={styles.pill}
        >
          <Flex vAlign="center">
            <Text content="担当者：" className={styles.pillTitle} />
            {assignedUserNames()}
          </Flex>
        </Pill>
      )}
    </>
  )
}

export default AssignedUsersFilterPill
