import { FilesEmptyIcon } from '@fluentui/react-icons-northstar'
import {
  CloseIcon,
  FilesPictureColoredIcon,
  Loader,
} from '@fluentui/react-northstar'
import * as React from 'react'

import styles from './styles.module.css'

export type FilePropStatus = 'pending' | 'done' | 'error'
export type FileProp = {
  id: string
  status: FilePropStatus
  fileName: string
  mimeType: string
  url?: string
}
type Props = {
  files: FileProp[]
  removable?: boolean
  onClickRemove?: (id: string) => void
  className?: string
}
const RequesterChatFileList: React.FC<Props> = (props) => {
  return (
    <div className={[styles.list, props.className].join(' ')}>
      {props.files.map((f) => (
        <div key={f.id} className={styles.fileWrap}>
          <a href={f.url} target="_blank" rel="noreferrer">
            <div className={styles.icon}>{fileIcon(f.mimeType, f.status)}</div>
            <div className={styles.fileName}>{f.fileName}</div>
            {props.removable && (
              <div
                onClick={(e) => {
                  e.preventDefault()
                  props.onClickRemove?.(f.id)
                }}
                className={styles.remove}
              >
                <CloseIcon size="small" />
              </div>
            )}
          </a>
        </div>
      ))}
    </div>
  )
}

const fileIcon = (mimeType: string, status: FilePropStatus) => {
  if (status === 'pending') return <Loader />
  if (mimeType.startsWith('image/'))
    return <FilesPictureColoredIcon size="larger" />
  return <FilesEmptyIcon size="larger" />
}

export default RequesterChatFileList
