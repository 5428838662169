import * as ActivityMessagePB from '../../proto/activity_message_pb'
import * as ActivityMessageServicePB from '../../proto/activity_message_pb_service'
import { ActivityMessagePlanDraftArgs } from '../ActivityMessageAttachment/types/ActivityMessagePlanDraftArgs'
import { AttachmentItem } from '../ActivityMessageAttachment/types/AttachmentItem'
import { protoIntoActivityMessagePlanEntity } from '../ActivityMessagePlanList/api'
import { ActivityMessagePlanEntity } from '../ActivityMessagePlanList/types/ActivityMessagePlanEntity'
import { deskOperatorApi } from '../deskApiOperator'

const ActivityMessagePlanNewAPI = deskOperatorApi.injectEndpoints({
  endpoints: (build) => ({
    createActivityMessagePlan: build.mutation<
      ActivityMessagePlanEntity,
      ActivityMessagePlanDraftArgs
    >({
      query: (args) => ({
        service:
          ActivityMessageServicePB.ActivityMessageService
            .CreateDraftActivityMessagePlan,
        body: (() => {
          const req = new ActivityMessagePB.CreateActivityMessagePlanRequest()
          const receiver = new ActivityMessagePB.ActivityMessageReceiver()

          if (args.receiver.type === 'all') {
            receiver.setAll(true)
          } else if (args.receiver.type === 'receiverIDs') {
            const receiverUserIDs =
              new ActivityMessagePB.ActivityMessageReceiverUsers()
            receiverUserIDs.setUserIdsList(args.receiver.receiverIDs)
            receiver.setUserIds(receiverUserIDs)
          }

          req.setAttachmentsList(args.attachments.map(attachmentItemIntoProto))

          req.setBody(args.body)
          req.setReceiver(receiver)

          return req
        })(),
      }),
      transformResponse(
        result: ActivityMessagePB.CreateActivityMessagePlanResponse.AsObject
      ): ActivityMessagePlanEntity {
        if (!result.activityMessage) {
          throw new Error("API didn't return activityMessage")
        }
        return protoIntoActivityMessagePlanEntity(result.activityMessage)
      },
      invalidatesTags: ['ActivityMessagePlans'],
    }),
  }),
})

function attachmentItemIntoProto(
  attachment: AttachmentItem
): ActivityMessagePB.CreateActivityMessagePlanAttachment {
  const result = new ActivityMessagePB.CreateActivityMessagePlanAttachment()
  result.setName(attachment.fileName)
  result.setUrl(attachment.url ?? '')
  result.setItemId(attachment.itemID ?? '')
  result.setGroupId(attachment.groupID ?? '')
  result.setMimeType(attachment.mimeType ?? '')

  return result
}

export const { useCreateActivityMessagePlanMutation } =
  ActivityMessagePlanNewAPI

export default ActivityMessagePlanNewAPI
