import { grpc } from '@improbable-eng/grpc-web'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

import { AppThunk } from '../../app/store'
import csv_pb from '../../proto/csv_pb'
import csv_pb_service from '../../proto/csv_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from '../auth/authSlice'

const csvAdapter = createEntityAdapter<csv_pb.CSV.AsObject>({
  selectId: (csvFile) => csvFile.id,
  sortComparer: (a, b) => {
    return a.id < b.id ? 1 : -1
  },
})

interface CSVState {
  loading: boolean
  error: string | null
}

export const csvSlice = createSlice({
  name: 'csv',
  initialState: csvAdapter.getInitialState<CSVState>({
    loading: false,
    error: null,
  }),
  reducers: {
    fetchCSVsStart(state) {
      state.error = null
    },
    fetchCSVsOnMessage: (
      state,
      action: PayloadAction<{
        message: csv_pb.ListCSVsResponse.AsObject
      }>
    ) => {
      csvAdapter.setAll(state, action.payload.message.csvsList)
    },
    createCSVStart(state) {
      state.loading = true
      state.error = null
    },
    createCSVOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = null
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
  },
})
export default csvSlice.reducer

const { fetchCSVsStart, fetchCSVsOnMessage, createCSVStart, createCSVOnEnd } =
  csvSlice.actions

export const createCSV =
  (startDate?: Date, endDate?: Date): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    dispatch(createCSVStart())
    const client = grpcClient<csv_pb.CreateCSVRequest, csv_pb.CSV>(
      csv_pb_service.CSVAPI.CreateCSV
    )
    const req = new csv_pb.CreateCSVRequest()
    let startTimestamp: Timestamp | undefined = undefined
    if (startDate) {
      startTimestamp = new Timestamp()
      startTimestamp.fromDate(startDate)
    }
    let endTimestamp: Timestamp | undefined = undefined
    if (endDate) {
      endTimestamp = new Timestamp()
      endTimestamp.fromDate(endDate)
    }
    req.setStartDate(startTimestamp)
    req.setEndDate(endTimestamp)

    client.onMessage(() => {
      dispatch(fetchCSVs(false))
    })
    client.onEnd((code, message) => {
      dispatch(createCSVOnEnd({ code, message }))
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
      }
    })
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)
    client.start(meta)
    client.send(req)
    client.finishSend()
  }

const fetchInterval = 5000
let closeFetchCSVsTimer: (() => void) | null

export const closeFetchCSVs = (): void => {
  if (!closeFetchCSVsTimer) {
    return
  }
  closeFetchCSVsTimer()
  closeFetchCSVsTimer = null
}

export const fetchCSVs =
  (needsLoop = true): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    dispatch(fetchCSVsStart())
    const client = grpcClient<Empty, csv_pb.ListCSVsResponse>(
      csv_pb_service.CSVAPI.ListCSVs
    )

    client.onMessage((message) => {
      dispatch(fetchCSVsOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      if (needsLoop) {
        const fetchCSVsId = setTimeout(() => {
          dispatch(fetchCSVs())
        }, fetchInterval)
        closeFetchCSVsTimer = () => {
          clearTimeout(fetchCSVsId)
        }
      }
    })
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)
    client.start(meta)
    client.send(new Empty())
    client.finishSend()
  }
