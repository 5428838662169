import { grpc } from '@improbable-eng/grpc-web'
import * as Graph from '@microsoft/microsoft-graph-types'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk } from '../../app/store'
import tab_channel_pb from '../../proto/tab_channel_pb'
import tab_channel_pb_service from '../../proto/tab_channel_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from '../auth/authSlice'

export const tabChannelsAdapter = createEntityAdapter<Graph.Channel>({
  selectId: (t) => t.id || '',
  sortComparer: (a, b) => {
    if (a.displayName == null) return -1
    if (b.displayName == null) return 1
    return a.displayName.localeCompare(b.displayName)
  },
})

interface TabChannelState {
  loading: boolean
  error: string | null
}

export const tabChannelsSlice = createSlice({
  name: 'tab_channels',
  initialState: tabChannelsAdapter.getInitialState<TabChannelState>({
    loading: false,
    error: null,
  }),
  reducers: {
    fetchTabChannelsStart(state) {
      state.loading = true
      state.error = null
    },
    fetchTabChannelsOnMessage(
      state,
      action: PayloadAction<{
        message: tab_channel_pb.GetTabChannelsResponse.AsObject
      }>
    ) {
      const { message } = action.payload
      // tabChannelListをGraph.Channelに変換してsetAllする
      const channels = message.tabChannelsList.map((t) => ({
        id: t.channelId,
        displayName: t.channelName,
      }))
      tabChannelsAdapter.setAll(state, channels)
    },
    fetchTabChannelsOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = null
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
  },
})

export const {
  fetchTabChannelsStart,
  fetchTabChannelsOnMessage,
  fetchTabChannelsOnEnd,
} = tabChannelsSlice.actions
export default tabChannelsSlice.reducer

export const fetchTabChannels =
  (groupId: string): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    // 権限によるバリデーション
    const availableFeatures = getState().auth.availableFeatures
    if (
      availableFeatures?.multiChannel === false &&
      availableFeatures?.delegateMultiChannel === false
    ) {
      return
    }
    dispatch(fetchTabChannelsStart())

    const client = grpcClient<
      tab_channel_pb.GetTabChannelsRequest,
      tab_channel_pb.GetTabChannelsResponse
    >(tab_channel_pb_service.TabChannelAPI.GetTabChannels)

    const req = new tab_channel_pb.GetTabChannelsRequest()
    req.setGroupId(groupId)

    client.onMessage((message) => {
      dispatch(fetchTabChannelsOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(fetchTabChannelsOnEnd({ code, message }))
    })
    dispatch(fetchTabChannelsStart())
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.start(meta)
    client.send(req)
    client.finishSend()
  }
