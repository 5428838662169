import { Text as FluentText } from '@fluentui/react-northstar'
import { Card } from '@tremor/react'
import * as React from 'react'

type Props = {
  title: string
  data: string
}
const NumberChart: React.FC<Props> = (props) => {
  return (
    <Card>
      <div>
        <FluentText size="small" weight="bold">
          {props.title}
        </FluentText>
      </div>
      <div>
        <FluentText size="largest" weight="bold">
          {props.data}
        </FluentText>
      </div>
    </Card>
  )
}

export default NumberChart
