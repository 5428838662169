import {
  Box,
  Button,
  Checkbox,
  Flex,
  Loader,
  Text,
} from '@fluentui/react-northstar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import DialogWithCloseIcon from '../../components/DialogWithCloseIcon'
import styles from './AutoAssignConfigDialog.module.css'
import {
  createAutoAssignConfig,
  fetchAutoAssignConfig,
  setIsActive,
  updateAutoAssignConfig,
} from './autoAssignConfigSlice'

type Props = {
  open: boolean

  onCancel: () => void
  onConfirm: () => void
  renderAsigneeSelection: (
    values: string[],
    disabled: boolean,
    onChange: (values: string[]) => void
  ) => React.ReactElement
}
const AutoAssignConfigDialog: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()
  const autoAssignConfig = useSelector(
    (state: RootState) => state.autoAssignConfigState
  )
  const [autoAssignConfigedUserIds, setAutoAssignConfigedUserIds] = useState<
    string[]
  >([])

  const handleUpdate = () => {
    dispatch(
      updateAutoAssignConfig(
        autoAssignConfig.isActive,
        autoAssignConfigedUserIds
      )
    )
    props.onConfirm()
  }

  const handleCreate = () => {
    dispatch(
      createAutoAssignConfig(
        autoAssignConfig.isActive,
        autoAssignConfigedUserIds
      )
    )
    props.onConfirm()
  }

  useEffect(() => {
    setAutoAssignConfigedUserIds(autoAssignConfig.userIds)
  }, [autoAssignConfig.userIds])

  useEffect(() => {
    if (!props.open) {
      return
    }
    dispatch(fetchAutoAssignConfig())
  }, [dispatch, props.open])

  return (
    <DialogWithCloseIcon
      open={props.open}
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
      content={
        <Box>
          <Flex vAlign="center" space="between">
            <h3>自動割り当て設定</h3>
            <Flex>
              {autoAssignConfig.loading && <Loader size="small" />}
              <Checkbox
                disabled={autoAssignConfig.loading}
                toggle
                checked={autoAssignConfig.isActive}
                onClick={() => {
                  dispatch(
                    setIsActive({ isActive: !autoAssignConfig.isActive })
                  )
                }}
              />
            </Flex>
          </Flex>
          <Box>
            <Text className={styles.autoAssignConfigDialogHeader}>
              下記リストに追加すると、新しい問い合わせを受け付けた際、問い合わせに担当者が自動で割り当てられます。
            </Text>
          </Box>
          <Flex className={styles.autoAssignConfigDialogContent}>
            {props.renderAsigneeSelection(
              autoAssignConfigedUserIds,
              !autoAssignConfig.isActive,
              setAutoAssignConfigedUserIds
            )}
          </Flex>
        </Box>
      }
      footer={
        autoAssignConfig.hasConfig ? (
          <Button primary onClick={handleUpdate}>
            更新
          </Button>
        ) : (
          <Button
            primary
            disabled={!autoAssignConfig.isActive}
            onClick={handleCreate}
          >
            保存
          </Button>
        )
      }
      header="担当者自動割り当て設定"
    />
  )
}

export default AutoAssignConfigDialog
