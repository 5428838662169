import * as React from 'react'

import { PicturePreview } from '../../../../components/PicturePreview'
import styles from './styles.module.css'

type Props = {
  src: string
  downloadData?: string
  openUrl?: string
  hidden: boolean
}

const RecommendedFaqListPicturePreviewPresenter: React.FC<Props> = (props) => {
  return (
    <PicturePreview
      className={!props.hidden ? styles.picturePreview : ''}
      hidden={props.hidden}
      src={props.src || ''}
      downloadData={props.downloadData}
      openUrl={props.openUrl}
    />
  )
}

export default React.memo(RecommendedFaqListPicturePreviewPresenter)
