import { Button, CloseIcon, Datepicker, Text } from '@fluentui/react-northstar'
import format from 'date-fns/fp/format'
import * as React from 'react'

import AssigneeSelection from '../../features/assigneeSelection'
import useFullHeight from '../../hooks/useFullHeight'
import AssigneeFilter, { updateAssigneeUserIds } from './assigneeFilter'
import styles from './styles.module.css'

const formatDate = format('yyyy/MM/dd')
const localizationStrings = {
  months: Array.from(Array(12).keys()).map((k) => `${k + 1}月`),
}

const updateStartDate = (payload: Date | null) => ({
  type: 'updateStartDate' as const,
  payload,
})
const updateEndDate = (payload: Date | null) => ({
  type: 'updateEndDate' as const,
  payload,
})

export type Actions =
  | ReturnType<typeof updateStartDate>
  | ReturnType<typeof updateEndDate>
  | ReturnType<typeof updateAssigneeUserIds>

type ReducerState = {
  startDate: Date
  endDate: Date
  assigneeUserIds: string[]
}
const reducer: React.Reducer<ReducerState, Actions> = (state, action) => {
  switch (action.type) {
    case 'updateEndDate':
      return {
        ...state,
        endDate: action.payload ?? state.endDate,
      }
    case 'updateStartDate':
      return {
        ...state,
        startDate: action.payload ?? state.startDate,
      }
    case 'updateAssignee':
      return {
        ...state,
        assigneeUserIds: action.payload ?? state.assigneeUserIds,
      }
  }
}

export type FormData = ReducerState

type Props = {
  startDate: Date
  endDate: Date
  assigneeUserIds: string[]
  onClickClose: () => void
  onClickClear: () => void
  onClickCancel: () => void
  onClickApply: (formData: FormData) => void
}
const DashboardFilterSidepanelPresenter: React.FC<Props> = (props) => {
  const ref = React.useRef(null)
  const height = useFullHeight(ref)

  const [formState, formDispatch] = React.useReducer(reducer, {
    endDate: new Date(),
    startDate: new Date(),
    assigneeUserIds: [],
  })

  const clickApply = React.useCallback(() => {
    props.onClickApply(formState)
  }, [props, formState])

  React.useEffect(() => {
    formDispatch(updateStartDate(props.startDate))
  }, [props.startDate])

  React.useEffect(() => {
    formDispatch(updateEndDate(props.endDate))
  }, [props.endDate])

  React.useEffect(() => {
    formDispatch(updateAssigneeUserIds(props.assigneeUserIds))
  }, [props.assigneeUserIds])

  return (
    <div ref={ref} style={{ height }} className={styles.container}>
      <div className={styles.item}>
        <div className={styles.header}>
          <Text size="large" weight="bold">
            Filter
          </Text>
          <CloseIcon
            onClick={props.onClickClose}
            className={styles.closeIcon}
          />
        </div>
        <div className={styles.body}>
          <div className={styles.item}>
            <Text weight="bold" className={styles.formRow}>
              表示範囲
            </Text>
            <div className={styles.formRow}>
              <Text size="small" color="#616161">
                開始日
              </Text>
              <Datepicker
                {...localizationStrings}
                onDateChange={(_, data) =>
                  formDispatch(updateStartDate(data?.value ?? null))
                }
                formatMonthDayYear={formatDate}
                selectedDate={formState.startDate}
                className={styles.datepicker}
              />
            </div>
            <div className={styles.formRow}>
              <Text size="small" color="#616161">
                終了日
              </Text>
              <Datepicker
                {...localizationStrings}
                onDateChange={(_, data) =>
                  formDispatch(updateEndDate(data?.value ?? null))
                }
                formatMonthDayYear={formatDate}
                selectedDate={formState.endDate}
                className={styles.datepicker}
              />
            </div>
          </div>
          <div className={styles.item}>
            <Text weight="bold" className={styles.formRow}>
              担当者
            </Text>
            <div className={styles.formRow}>
              <AssigneeFilter
                assigneeUserIds={formState.assigneeUserIds}
                formDispatch={formDispatch}
                renderAssigneeSelection={(values, onChange, extendedItems) => (
                  <AssigneeSelection
                    multiple
                    values={values}
                    onChange={onChange}
                    extendedItems={extendedItems}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerLeft}>
          <Button
            primary
            text
            onClick={props.onClickClear}
            className={styles.clearButton}
          >
            クリア
          </Button>
        </div>
        <div className={styles.footerRight}>
          <Button onClick={props.onClickCancel} className={styles.cancelButton}>
            キャンセル
          </Button>
          <Button primary onClick={clickApply} className={styles.applyButton}>
            適用
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DashboardFilterSidepanelPresenter
