import {
  DropdownItemProps,
  ShorthandCollection,
} from '@fluentui/react-northstar'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import type { RootState } from '../../app/store'
import { Dropdown } from '../../components/Dropdown'
import * as Slice from './slice'
import styles from './styles.module.css'
import type { PulldownItem } from './types/PulldownItem'

type Props = {
  values: string[]
  onChange: (values: string[]) => void
  multiple?: boolean
  placeholder?: string
  disabled?: boolean
  /** プルダウンの中身を追加したい時に使用します */
  extendedItems?: PulldownItem[]
  className?: string
}
const AssigneeSelection: React.FC<Props> = (props) => {
  const items = ReactRedux.useSelector(Slice.dropdownItemsSelector)
  const searchQuerySelector = ReactRedux.useSelector(
    (state: RootState) => (query: string) =>
      Slice.searchWithQuerySelector(
        state,
        query,
        props.values,
        props.extendedItems
      )
  )
  const [isOpenDropdownMenu, setIsOpenDropdownMenu] = React.useState(false)

  const search = React.useCallback(
    (
      items: ShorthandCollection<DropdownItemProps>,
      searchQuery: string
    ): ShorthandCollection<DropdownItemProps> => {
      return searchQuerySelector(searchQuery)
    },
    [searchQuerySelector]
  )

  const pulldownItems = React.useMemo(() => {
    return [...(props.extendedItems ?? []), ...items]
  }, [items, props])

  return (
    <Dropdown
      fluid
      multiple={props.multiple}
      clearable
      open={isOpenDropdownMenu}
      search={search}
      items={pulldownItems}
      searchInput={{ className: styles.innerSearchInput }}
      placeholder={props.placeholder ?? '選択してください'}
      onValueChange={props.onChange}
      onInputFocus={() => setIsOpenDropdownMenu(true)}
      onBlur={() => setIsOpenDropdownMenu(false)}
      value={props.values}
      disabled={props.disabled}
      className={[styles.filter, props.className].join(' ')}
    />
  )
}

export default React.memo(AssigneeSelection)
