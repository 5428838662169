import { grpc } from '@improbable-eng/grpc-web'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk } from '../../app/store'
import bot_added_group_pb from '../../proto/bot_added_group_pb'
import bot_added_group_pb_service from '../../proto/bot_added_group_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from '../auth/authSlice'

export declare type BotAddedGroup = bot_added_group_pb.BotAddedGroup.AsObject

export const botAddedGroupsAdapter = createEntityAdapter<BotAddedGroup>({
  selectId: (botAddedGroup) => botAddedGroup.groupId || '',
})

interface BotAddedGroupState {
  loading: boolean
  error: string | null
}

export const botAddedGroupsSlice = createSlice({
  name: 'bot_added_groups',
  initialState: botAddedGroupsAdapter.getInitialState<BotAddedGroupState>({
    loading: false,
    error: null,
  }),
  reducers: {
    fetchBotAddedGroupsStart(state) {
      state.loading = true
      state.error = null
    },
    fetchBotAddedGroupsOnMessage(
      state,
      action: PayloadAction<{
        message: bot_added_group_pb.ListBotAddedGroupsResponse.AsObject
      }>
    ) {
      const { message } = action.payload
      botAddedGroupsAdapter.setAll(state, message.botAddedGroupsList)
    },
    fetchBotAddedGroupsOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = null
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
  },
})

export const {
  fetchBotAddedGroupsStart,
  fetchBotAddedGroupsOnMessage,
  fetchBotAddedGroupsOnEnd,
} = botAddedGroupsSlice.actions
export default botAddedGroupsSlice.reducer

export const fetchBotAddedGroups =
  (): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    dispatch(fetchBotAddedGroupsStart())

    const client = grpcClient<
      bot_added_group_pb.ListBotAddedGroupsRequest,
      bot_added_group_pb.ListBotAddedGroupsResponse
    >(bot_added_group_pb_service.BotAddedGroupAPI.ListBotAddedGroups)
    const req = new bot_added_group_pb.ListBotAddedGroupsRequest()

    client.onMessage((message) => {
      dispatch(fetchBotAddedGroupsOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(fetchBotAddedGroupsOnEnd({ code, message }))
    })

    dispatch(fetchBotAddedGroupsStart())
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.start(meta)
    client.send(req)
    client.finishSend()
  }
