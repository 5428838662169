import * as Graph from '@microsoft/microsoft-graph-types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AppThunk } from '../../app/store'
import { resetTokens } from './authSlice'

export interface InfoState {
  teamsAppInstallation?: Graph.TeamsAppInstallation
  loading: boolean
  error: string | null
}

const initState: InfoState = {
  loading: false,
  error: null,
}

export const infoSlice = createSlice({
  name: 'infos',
  initialState: initState,
  reducers: {
    fetchTeamsAppStart(state) {
      state.loading = true
      state.error = null
    },
    fetchTeamsAppSuccess(
      state,
      action: PayloadAction<{
        teamsAppInstallation: Graph.TeamsAppInstallation
      }>
    ) {
      state.loading = false
      state.teamsAppInstallation = action.payload.teamsAppInstallation
    },
    fetchTeamsAppFailure(state, action: PayloadAction<{ error: string }>) {
      state.loading = false
      state.error = action.payload.error
    },
  },
})

export const fetchTeamsApp =
  (): AppThunk =>
  async (dispatch, getState, { graphAPI }) => {
    const context = getState().auth.context

    if (context == null) {
      return
    }

    const team = context.team

    if (team == null) {
      return
    }

    const groupId = team.groupId
    if (!groupId) {
      dispatch(fetchTeamsAppFailure({ error: 'groupId does not exist' }))
      return
    }
    const appId = getState().auth.decodedSsoToken?.aud

    try {
      const res = await graphAPI
        ?.api(
          `/teams/${groupId}/installedApps?$expand=teamsApp&$filter=teamsApp/externalId eq '${appId}'`
        )
        .get()
      if (res.value.length === 0) {
        throw new Error(`response of installedApps is empty`)
      }
      dispatch(fetchTeamsAppSuccess({ teamsAppInstallation: res.value[0] }))
    } catch (e) {
      if (e.statusCode === 401) {
        dispatch(resetTokens())
        return
      }
      dispatch(fetchTeamsAppFailure({ error: e.toString() }))
    }
  }

export const { fetchTeamsAppSuccess, fetchTeamsAppFailure } = infoSlice.actions
export default infoSlice.reducer
