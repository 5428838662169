import {
  DownloadIcon,
  FilesEmptyIcon,
  MoreIcon,
} from '@fluentui/react-icons-northstar'
import { Attachment, Box, Flex, Popup, Text } from '@fluentui/react-northstar'
import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import styles from './ChatItems.module.css'
import { attachmentIcon } from './common'
import { OneDriveData } from './types'

const AttachmentBarMaxWith = 300
const AttachmentBarMaxRatio = 0.45
const ChatItemAttachment: React.FC<{
  attachmentData: OneDriveData
}> = (props) => {
  const attachmentData = props.attachmentData
  const layoutState = useSelector((root: RootState) => root.layout)
  return (
    <Box
      className="chat-item-attachment-bar"
      styles={{
        width: layoutState?.chatLayout?.clientWidth
          ? Math.min(
              layoutState.chatLayout.clientWidth * AttachmentBarMaxRatio,
              AttachmentBarMaxWith
            ) + 'px'
          : AttachmentBarMaxWith + 'px',
      }}
    >
      <Attachment
        onClick={() => {
          window.open(attachmentData.webUrl)
        }}
        header={
          <div>
            <Box className={styles.header}>{attachmentData.name}</Box>
          </div>
        }
        actionable
        icon={attachmentIcon(attachmentData.name)}
        action={{
          icon: (
            <Popup
              position={'below'}
              align={'center'}
              trigger={<MoreIcon onClick={(e) => e.stopPropagation()} />}
              content={
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <Box className={styles.tooltip}>
                    <Flex className={styles.tooltipItem}>
                      <FilesEmptyIcon />
                      <Text onClick={() => window.open(attachmentData.webUrl)}>
                        ブラウザーで開く
                      </Text>
                    </Flex>
                    <Flex className={styles.tooltipItem}>
                      <DownloadIcon />
                      <Text
                        onClick={() =>
                          window.open(`${attachmentData.webUrl}?download=1`)
                        }
                      >
                        ダウンロード
                      </Text>
                    </Flex>
                  </Box>
                </div>
              }
            />
          ),
        }}
      />
    </Box>
  )
}

export default ChatItemAttachment
