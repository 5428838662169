import {
  Box,
  Button,
  Checkbox,
  DialogFooterProps,
  Flex,
  FlexItem,
  Input,
  QuestionCircleIcon,
  ShorthandRenderFunction,
  ShorthandValue,
  Text,
  Tooltip,
} from '@fluentui/react-northstar'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import DialogWithCloseIcon from '../../components/DialogWithCloseIcon'
import { Dropdown } from '../../components/Dropdown'
import PageViewLog from '../../components/PageViewLog'
import {
  CustomFieldType,
  CustomFieldTypeLabels,
  CustomFieldValue,
  DefaultFields,
  PageName,
} from '../../consts'
import ticket_custom_field_pb from '../../proto/ticket_custom_field_pb'
import {
  CustomField,
  createCustomField,
  customFieldSelector,
  deleteCustomField,
  updateCustomField,
} from './customFieldSlice'
import styles from './TicketCustomFieldDialog.module.css'
import { TicketCustomFieldOptionList } from './TicketCustomFieldOptionList'

interface Props {
  open?: boolean
  onOk: () => void
  onCancel: () => void
  id: number | null
}

const needsOptions = (t: string) => ['select', 'multiselect'].includes(t)

const createEmptyField = (): CustomField => {
  const newField = new ticket_custom_field_pb.TicketCustomField().toObject()
  newField.editable = true
  return newField
}

const isFieldNameValidAndUnique = (
  field: CustomField,
  customFields: CustomField[]
) => {
  if (!field) {
    return false
  }
  if (
    customFields
      .filter((f) => f.id !== field.id)
      .map((f) => f.name)
      .includes(field.name.trim())
  ) {
    return false
  }
  switch (field.name) {
    case DefaultFields.KeyId.name:
    case DefaultFields.Requester.name:
    case DefaultFields.Subject.name:
    case DefaultFields.AssignedUserId.name:
    case DefaultFields.Status.name:
    case DefaultFields.ReceivedAt.name:
    case DefaultFields.SentAt.name:
    case DefaultFields.CreatedAt.name:
    case DefaultFields.FaqStatus.name:
    case DefaultFields.FaqCreator.name:
      return false
  }

  return true
}

const canSubmit = (
  customField: CustomField,
  customFields: CustomField[],
  options: string[]
) => {
  if (!isFieldNameValidAndUnique(customField, customFields)) {
    return false
  }

  // optionsは新規追加用optionが常にあるので2個以上だと設定済みと判断
  return needsOptions(customField.type)
    ? Boolean(customField.name && customField.type && options.length > 1)
    : Boolean(customField.name && customField.type)
}

const TicketCustomFieldDialog: FC<Props> = ({ open, onOk, onCancel, id }) => {
  const dispatch = useDispatch()
  const customFieldsState = useSelector((state: RootState) => state.customField)
  const customFields = useSelector(customFieldSelector.selectAll)
  const [customField, setCustomField] = useState<CustomField>(createEmptyField)
  const [options, setOptions] = useState<string[]>([])
  const initOptions = () => {
    setOptions([...customField.optionsList.map((o) => o.value), ''])
  }
  const isNew = !id

  useEffect(initOptions, [customField.optionsList])

  useEffect(() => {
    if (!open) return
    if (id && customFieldsState.entities[id]) {
      setCustomField({ ...(customFieldsState.entities[id] as CustomField) })
    } else {
      setCustomField(createEmptyField())
    }
  }, [open, id, customFieldsState.entities])

  const content = (
    <Box className={styles.container}>
      <Flex column gap="gap.small">
        <Text weight="bold">フィールド名</Text>
        <Input
          fluid
          required
          placeholder="フィールド名を入力してください"
          defaultValue={customField.name}
          value={customField.name}
          onChange={(_, data) =>
            setCustomField({ ...customField, name: data?.value ?? '' })
          }
          onBlur={() =>
            setCustomField({ ...customField, name: customField.name.trim() })
          }
          error={!isFieldNameValidAndUnique(customField, customFields)}
          maxLength={255}
        />
        {!isFieldNameValidAndUnique(customField, customFields) && (
          <Text
            content={CustomFieldValue.ClientErrorMessage.ValidExists}
            style={{ color: 'red' }}
          />
        )}
        <Text weight="bold">タイプ</Text>
        {isNew ? (
          <Dropdown
            fluid
            placeholder="タイプを選択してください"
            defaultValue={[customField.type]}
            value={[customField.type]}
            onValueChange={(values) => {
              if (!needsOptions(values[0])) {
                initOptions()
              }
              setCustomField({ ...customField, type: values[0] })
            }}
            items={Object.entries(CustomFieldTypeLabels).map(
              ([value, header]) => ({ value, header })
            )}
          />
        ) : (
          <Text className={styles.fixedTypeLabel}>
            {CustomFieldTypeLabels[customField.type as CustomFieldType]}
          </Text>
        )}
        {TicketCustomFieldOptionList({
          options,
          setOptions,
          needsOptions: needsOptions(customField.type),
        })}
        <Text weight="bold">編集</Text>
        <Checkbox
          label="有効"
          toggle
          defaultChecked={customField.editable}
          checked={customField.editable}
          onChange={(_, data) =>
            setCustomField({ ...customField, editable: data?.checked ?? false })
          }
        />
        <div>
          <Text weight="bold">対応スロット</Text>
          <Tooltip
            align="bottom"
            position="after"
            content="自動応答から有人応答に切り替わる際に、指定したスロットの値を引き継ぎます。半角英数字とアンダースコアのみ使用できます"
            trigger={<Button text iconOnly icon={<QuestionCircleIcon />} />}
          />
        </div>
        <Input
          fluid
          required
          placeholder="スロット識別子を入力してください"
          defaultValue={customField.slotContextName}
          value={customField.slotContextName}
          onChange={(_, data) =>
            setCustomField({
              ...customField,
              slotContextName: data?.value ?? '',
            })
          }
          onBlur={() => {
            const matches =
              customField.slotContextName.matchAll(/[a-zA-Z0-9_]+/g)
            const cleanedName = Array.from(matches).join('')
            setCustomField({
              ...customField,
              slotContextName: cleanedName,
            })
          }}
          maxLength={191}
        />
      </Flex>
    </Box>
  )

  const footerChildren: ShorthandRenderFunction<DialogFooterProps> = (
    Component: React.ElementType<DialogFooterProps>,
    props: DialogFooterProps
  ) => {
    const { styles, ...rest } = props
    return (
      <Flex gap="gap.smaller" styles={styles}>
        {!isNew && (
          <DialogWithCloseIcon
            confirmButton="削除"
            onConfirm={() => {
              dispatch(deleteCustomField(customField))
              onOk()
            }}
            content={`このフィールド [${customField.name}] を削除します。よろしいですか？`}
            header="このフィールドを削除しますか？"
            trigger={
              <Button
                text
                styles={({ theme: { siteVariables } }) => ({
                  color: siteVariables.colorScheme.red.foreground,
                })}
              >
                フィールドを削除
              </Button>
            }
          />
        )}
        <FlexItem push>
          <Component {...rest} />
        </FlexItem>
      </Flex>
    )
  }

  // childrenはReactPortal.ReactNodeに推論されて、強制変換しないと、うまく下に代入できないため、強制変換します
  const footer: ShorthandValue<DialogFooterProps> = {
    children: footerChildren,
  } as ShorthandValue<DialogFooterProps>

  return (
    <PageViewLog
      pageName={PageName.TicketCustomFieldDialog}
      isOpenable
      isOpen={open}
    >
      <DialogWithCloseIcon
        onCancel={onCancel}
        open={open}
        confirmButton={{
          disabled: !canSubmit(customField, customFields, options),
          content: isNew ? '追加' : '更新',
        }}
        onConfirm={() => {
          customField.optionsList = options
            .filter((_, i) => i < options.length - 1)
            .map((o) => {
              const option =
                new ticket_custom_field_pb.TicketCustomFieldOption().toObject()
              option.value = o
              return option
            })
          if (id) {
            dispatch(updateCustomField(customField))
          } else {
            dispatch(createCustomField(customField))
          }
          onOk()
        }}
        content={content}
        header={isNew ? '新規フィールドの追加' : 'フィールドの編集'}
        className={styles.dialog}
        footer={footer}
      />
    </PageViewLog>
  )
}

export default TicketCustomFieldDialog
