// package: 
// file: proto/ticket.proto

var proto_ticket_pb = require("../proto/ticket_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TicketAPI = (function () {
  function TicketAPI() {}
  TicketAPI.serviceName = "TicketAPI";
  return TicketAPI;
}());

TicketAPI.GetTicket = {
  methodName: "GetTicket",
  service: TicketAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_ticket_pb.GetTicketRequest,
  responseType: proto_ticket_pb.Ticket
};

TicketAPI.ListTickets = {
  methodName: "ListTickets",
  service: TicketAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_ticket_pb.ListTicketsRequest,
  responseType: proto_ticket_pb.ListTicketsResponse
};

TicketAPI.UpdateTicket = {
  methodName: "UpdateTicket",
  service: TicketAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_ticket_pb.UpdateTicketRequest,
  responseType: proto_ticket_pb.Ticket
};

TicketAPI.CreateManualTicket = {
  methodName: "CreateManualTicket",
  service: TicketAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_ticket_pb.CreateManualTicketRequest,
  responseType: proto_ticket_pb.Ticket
};

TicketAPI.DeleteManualTicket = {
  methodName: "DeleteManualTicket",
  service: TicketAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_ticket_pb.DeleteManualTicketRequest,
  responseType: google_protobuf_empty_pb.Empty
};

exports.TicketAPI = TicketAPI;

function TicketAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TicketAPIClient.prototype.getTicket = function getTicket(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TicketAPI.GetTicket, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TicketAPIClient.prototype.listTickets = function listTickets(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TicketAPI.ListTickets, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TicketAPIClient.prototype.updateTicket = function updateTicket(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TicketAPI.UpdateTicket, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TicketAPIClient.prototype.createManualTicket = function createManualTicket(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TicketAPI.CreateManualTicket, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TicketAPIClient.prototype.deleteManualTicket = function deleteManualTicket(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TicketAPI.DeleteManualTicket, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TicketAPIClient = TicketAPIClient;

