import { createAsyncThunk } from '@reduxjs/toolkit'

import { AsyncThunkConfig } from '../../app/store'
import { groupApi } from '../../renewfeatures/Group/api'

export const upsertGroup = createAsyncThunk<void, void, AsyncThunkConfig>(
  'Group/upsertGroup',
  async (_, thunkAPI) => {
    await thunkAPI
      .dispatch(groupApi.endpoints.upsertGroup.initiate({}))
      .unwrap()
  }
)
