import { EntityId } from '@reduxjs/toolkit'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import { RootState } from '../../../../app/store'
import RequesterChatButtonGroup from '../../../../components/RequesterChatButtonGroup'
import RequsterTicketReplyItemPresenter from '../../../../components/RequesterReplyItem'
import { defaultAppIcon, defaultAppName } from '../../../../consts'
import * as GraphApi from '../../../graphApi'
import {
  useGetProfileMeBetaQuery,
  useGetTeamsCustomApplicationInfoQuery,
} from '../../../graphApi'
import * as AuthSelectors from '../../../RequesterAuth/selectors'
import {
  useGetRequesterConversationRepliesQuery,
  useSendRequesterReplyButtonMutation,
} from '../../api'
import { linkedReplyMessageID as linkedReplyMessageIDSelector } from '../../selectors'
import { ReplyText } from '../RequesterChatText'
import * as Selectors from './selectors'

type Props = {
  ticketId: string
  id: EntityId
}

let scrollFinished = false
const RequesterTicketReplyItem: React.FC<Props> = (props) => {
  const ref = React.useRef<HTMLDivElement | null>(null)
  const repliesQuery = useGetRequesterConversationRepliesQuery({
    ticketId: props.ticketId,
  })
  GraphApi.useGetProfileMeQuery()

  const authorId = ReactRedux.useSelector((state: RootState) =>
    Selectors.authorId(state, props.ticketId, props.id)
  )
  const time = ReactRedux.useSelector((state: RootState) =>
    Selectors.time(state, props.ticketId, props.id)
  )
  const isOwnMessage = ReactRedux.useSelector((state: RootState) =>
    Selectors.isOwnMessage(state, props.ticketId, props.id)
  )
  const isSimpleMessage = ReactRedux.useSelector((state: RootState) =>
    Selectors.isSimpleMessage(state, props.ticketId, props.id)
  )
  const isRenderButtonGroup = ReactRedux.useSelector((state: RootState) =>
    Selectors.isRenderButtonGroup(state, props.ticketId, props.id)
  )
  const buttonGroup = ReactRedux.useSelector((state: RootState) =>
    Selectors.buttonGroup(state, props.ticketId, props.id)
  )
  const fileProps = ReactRedux.useSelector((state: RootState) =>
    Selectors.fileProps(state, props.ticketId, props.id)
  )
  const appId = ReactRedux.useSelector((state: RootState) =>
    AuthSelectors.appId(state)
  )
  const isRequesterMessage = ReactRedux.useSelector((state: RootState) =>
    Selectors.isRequesterMessage(state, props.ticketId, props.id)
  )
  const linkedReplyMessageID = ReactRedux.useSelector(
    linkedReplyMessageIDSelector
  )

  const isLinked = React.useMemo(
    () => linkedReplyMessageID === props.id,
    [linkedReplyMessageID, props.id]
  )

  const { data: member } = GraphApi.useGetMemberQuery(
    { id: authorId },
    { skip: repliesQuery.isLoading }
  )
  const { data: photo } = GraphApi.useGetMemberPhotoQuery(
    { id: authorId },
    { skip: repliesQuery.isLoading }
  )
  const meBetaQuery = useGetProfileMeBetaQuery()
  const teamsCustomApplicationQuery = useGetTeamsCustomApplicationInfoQuery(
    {
      externalId: appId,
    },
    { skip: meBetaQuery.isLoading || meBetaQuery.data?.userType === 'Guest' }
  )
  const [sendRequesterReplyButton] = useSendRequesterReplyButtonMutation()

  const clickButtonGroup = React.useCallback(
    (key: string) => {
      const button = buttonGroup
        .flatMap((row) => row)
        .find((b) => b.key === key)
      if (!button) return
      if (button.url) {
        window.open(button.url, '_blank')
        return
      }
      sendRequesterReplyButton({
        ticketId: props.ticketId,
        text: button.label,
        type: button.type,
        data: button.data,
      })
    },
    [props, sendRequesterReplyButton, buttonGroup]
  )

  const renderButtonGroup = React.useCallback(() => {
    if (!isRenderButtonGroup) return <></>
    return (
      <RequesterChatButtonGroup
        buttons={buttonGroup}
        onClick={clickButtonGroup}
      />
    )
  }, [isRenderButtonGroup, buttonGroup, clickButtonGroup])

  React.useEffect(() => {
    if (!isLinked) {
      return
    }

    if (scrollFinished) {
      return
    }

    if (ref.current == null) {
      return
    }
    ref.current?.scrollIntoView({ block: 'center' })
    scrollFinished = true
  }, [isLinked])

  return (
    <RequsterTicketReplyItemPresenter
      ref={ref}
      id={props.id}
      name={
        isRequesterMessage && member != null
          ? member.name
          : teamsCustomApplicationQuery.isLoading
          ? ''
          : teamsCustomApplicationQuery.data?.displayName ?? defaultAppName
      }
      time={time}
      avatarImage={
        isRequesterMessage
          ? photo ?? ''
          : teamsCustomApplicationQuery.isLoading
          ? ''
          : teamsCustomApplicationQuery.data?.iconURL ?? defaultAppIcon
      }
      isOwnMessage={isOwnMessage}
      isSimpleMessage={isSimpleMessage}
      isHighlighted={isLinked}
      files={fileProps}
      renderButtonGroups={renderButtonGroup}
      renderChatText={() => (
        <ReplyText
          conversationId={props.ticketId}
          replyId={props.id as string}
        />
      )}
    />
  )
}

export default React.memo(RequesterTicketReplyItem)
