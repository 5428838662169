import * as React from 'react'
import * as ReactRedux from 'react-redux'

import { RootState } from '../../app/store'
import RequesterChatInput from '../RequesterChatInput'
import { attachmentOnedriveData as attachmentOnedriveDataSelector } from '../RequesterChatInput/selectors'
import { chatInputKey } from './lib/chatInputKey'
import { CreateConversation } from './operations'
import RequesterNewTicketPresenter from './presenter'

const RequesterNewTicket = React.forwardRef<HTMLDivElement>((_, ref) => {
  const attachmentOnedriveData = ReactRedux.useSelector((state: RootState) =>
    attachmentOnedriveDataSelector(state, chatInputKey)
  )

  const dispatch = ReactRedux.useDispatch()

  const clickSend = React.useCallback(
    async (message: string) => {
      dispatch(
        CreateConversation({
          attachments: attachmentOnedriveData,
          html: message,
          plainText: message.replace(/<[^>]*>/g, ''),
        })
      )
    },
    [attachmentOnedriveData, dispatch]
  )

  return (
    <RequesterNewTicketPresenter
      ref={ref}
      renderChatInput={(onClickClose) => (
        <RequesterChatInput
          chatKey={chatInputKey}
          placeholder="新しいメッセージの入力"
          onClickSend={async (msg) => {
            await clickSend(msg)
            onClickClose()
          }}
          onClickClose={onClickClose}
        />
      )}
    />
  )
})

export default React.memo(RequesterNewTicket)
