import { Pill } from '@fluentui/react-northstar'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { TicketStatusLabels } from '../../../consts'
import { setStatuses } from '../ticketSlice'
import styles from './Pill.module.css'

const StatusFilterPill: React.FC = () => {
  const ticketState = useSelector((state: RootState) => state.ticket)
  const dispatch = useDispatch()
  return (
    <Pill
      actionable={ticketState.filters.statuses.length > 0}
      onDismiss={() => dispatch(setStatuses({ statuses: [] }))}
      className={styles.pill}
    >
      ステータス：
      {ticketState.filters.statuses
        .map((st) => {
          return TicketStatusLabels[st]
        })
        .join('・')}
    </Pill>
  )
}

export default StatusFilterPill
