import { SearchIcon } from '@fluentui/react-icons-northstar'
import { Input } from '@fluentui/react-northstar'
import React, { useState } from 'react'
import { debounce } from 'throttle-debounce'

import styles from './FilterSearchText.module.css'

type FilterSearchTextProps = {
  setName: React.Dispatch<React.SetStateAction<string>>
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const debouncedChange = debounce(500, (value, setName, setPage) => {
  setName(value)
  setPage(1)
})

const FilterSearchText: React.FC<FilterSearchTextProps> = ({
  setName,
  setPage,
}) => {
  const [inputText, setInputText] = useState('')
  return (
    <Input
      icon={<SearchIcon />}
      fluid
      inverted
      clearable
      value={inputText}
      placeholder="既存のインテント識別子を検索"
      iconPosition="start"
      className={styles.filterSearchText}
      onChange={(_, data) => {
        setInputText(data?.value || '')
        debouncedChange(data?.value || '', setName, setPage)
      }}
    />
  )
}

export default FilterSearchText
