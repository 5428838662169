import { Button, Popup } from '@fluentui/react-northstar'
import * as React from 'react'

import styles from './style.module.css'

export type ColorPickerEvent =
  | {
      type: 'reset'
    }
  | {
      type: 'color'
      color: string
    }

type Props = {
  icon: JSX.Element
  colors: string[]
  resetButtonText: string
  onChange: (e: ColorPickerEvent) => void
}
const ColorPicker = React.memo<Props>((props) => {
  return (
    <Popup
      trigger={<Button iconOnly text icon={props.icon} />}
      content={<Content {...props} />}
    />
  )
})

export default ColorPicker

const Content: React.FC<
  Pick<Props, 'onChange' | 'colors' | 'resetButtonText'>
> = (props) => {
  return (
    <div className={styles.content}>
      <div>
        <Button
          flat
          content={props.resetButtonText}
          className={styles.resetButton}
          onClick={() => props.onChange({ type: 'reset' })}
        />
      </div>
      <div className={styles.colorPalette}>
        {props.colors.map((c) => (
          <div
            key={c}
            style={{ backgroundColor: c }}
            className={styles.colorPaletteItem}
            onClick={() => props.onChange({ type: 'color', color: c })}
          ></div>
        ))}
      </div>
    </div>
  )
}
