import { grpc } from '@improbable-eng/grpc-web'

import { ThunkDispatchType } from '../../app/store'
import { AppThunk } from '../../app/store'
import thumbnail_pb, {
  GetThumbnailByDriveIDRequest,
  GetThumbnailByGroupIDRequest,
  GetThumbnailByUserIDRequest,
} from '../../proto/thumbnails_pb'
import thumbnail_pb_service from '../../proto/thumbnails_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from '../auth/authSlice'
import {
  ThumbnailsData,
  fetchThumbnailOnEnd,
  fetchThumbnailOnMessage,
} from './thumbnailsSlice'

const clientOnMessage = (
  dispatch: ThunkDispatchType,
  itemId: string
): ((message: thumbnail_pb.Thumbnail) => void) => {
  return (message: thumbnail_pb.Thumbnail) => {
    let thumbnailsData = null
    try {
      thumbnailsData = JSON.parse(message.getData()) as ThumbnailsData
    } catch (e) {
      consoleErrorWithAirbrake(
        `error on convert response into ThumbnailsData, thumbnails data: ${message.getData()}`
      )
    }

    if (!thumbnailsData) {
      return
    }
    // リクエストを終了します
    dispatch(
      fetchThumbnailOnMessage({
        itemId: itemId,
        thumbnails: thumbnailsData,
      })
    )
  }
}

declare type ClientRequest =
  | thumbnail_pb.GetThumbnailByGroupIDRequest
  | thumbnail_pb.GetThumbnailByUserIDRequest
  | thumbnail_pb.GetThumbnailByDriveIDRequest

const clientSend = (
  req: ClientRequest,
  itemId: string,
  dispatch: ThunkDispatchType,
  client: grpc.Client<ClientRequest, thumbnail_pb.Thumbnail>,
  token?: string
) => {
  const meta = new grpc.Metadata()
  if (token != null) meta.append('authorization', 'bearer ' + token)
  // リクエストを開始します
  dispatch(
    fetchThumbnailOnMessage({
      itemId: itemId,
    })
  )
  client.start(meta)
  client.send(req)
  client.finishSend()
}

const clientOnEnd = (
  dispatch: ThunkDispatchType,
  itemId: string
): ((code: grpc.Code, error: string) => void) => {
  return (code, error) => {
    if (code === grpc.Code.Unauthenticated) {
      dispatch(resetTokens())
      return
    }
    dispatch(fetchThumbnailOnEnd({ code: code, error: error, id: itemId }))
  }
}

interface Inquiry {
  query: () => AppThunk
}

class QueryThumbnailByDriveId implements Inquiry {
  constructor(private driveId: string, private itemId: string) {}
  query(): AppThunk {
    return async (dispatch, getState, { grpcClient }) => {
      if (getState().thumbnails.entities[this.itemId]) {
        return
      }
      const client = grpcClient<
        thumbnail_pb.GetThumbnailByDriveIDRequest,
        thumbnail_pb.Thumbnail
      >(thumbnail_pb_service.ThumbnailsAPI.GetThumbnailByDriveID)
      client.onMessage(clientOnMessage(dispatch, this.itemId)) // register event
      client.onEnd(clientOnEnd(dispatch, this.itemId)) // register event

      const req = new GetThumbnailByDriveIDRequest()
      req.setDriveId(this.driveId)
      req.setItemId(this.itemId)
      const token = getState().auth.accessToken
      clientSend(req, this.itemId, dispatch, client, token)
    }
  }
}

class QueryThumbnailByUserId implements Inquiry {
  constructor(private userId: string, private itemId: string) {}
  query(): AppThunk {
    const itemId = this.itemId
    const userId = this.userId
    return async (dispatch, getState, { grpcClient }) => {
      if (getState().thumbnails.entities[itemId]) {
        return
      }
      const client = grpcClient<
        thumbnail_pb.GetThumbnailByUserIDRequest,
        thumbnail_pb.Thumbnail
      >(thumbnail_pb_service.ThumbnailsAPI.GetThumbnailByUserID)
      client.onMessage(clientOnMessage(dispatch, itemId)) // register event
      client.onEnd(clientOnEnd(dispatch, itemId)) // register event

      const req = new GetThumbnailByUserIDRequest()
      req.setUserId(userId)
      req.setItemId(itemId)
      const token = getState().auth.accessToken
      clientSend(req, itemId, dispatch, client, token)
    }
  }
}

class QueryThumbnailByGroupId implements Inquiry {
  constructor(private groupId: string, private itemId: string) {}
  query(): AppThunk {
    const itemId = this.itemId
    const groupId = this.groupId
    return async (dispatch, getState, { grpcClient }) => {
      // すでにリクエストを投げた場合は、再度リクエストを投げないようにします。
      if (getState().thumbnails.entities[itemId]) {
        return
      }
      const client = grpcClient<
        thumbnail_pb.GetThumbnailByGroupIDRequest,
        thumbnail_pb.Thumbnail
      >(thumbnail_pb_service.ThumbnailsAPI.GetThumbnailByGroupID)
      client.onMessage(clientOnMessage(dispatch, itemId)) // register event
      client.onEnd(clientOnEnd(dispatch, itemId)) // register event
      const req = new GetThumbnailByGroupIDRequest()
      req.setGroupId(groupId)
      req.setItemId(itemId)
      const token = getState().auth.accessToken
      clientSend(req, itemId, dispatch, client, token)
    }
  }
}

export const prepareInquiry = (parameter: {
  driveId?: string
  groupId?: string
  userId?: string
  itemId: string
}): Inquiry | undefined => {
  const { driveId, groupId, userId, itemId } = parameter
  if (driveId != null) {
    return new QueryThumbnailByDriveId(driveId, itemId)
  }

  if (userId != null) {
    return new QueryThumbnailByUserId(userId, itemId)
  }

  if (groupId != null) {
    return new QueryThumbnailByGroupId(groupId, itemId)
  }

  return
}
