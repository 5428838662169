import cubejs, { CubejsApi } from '@cubejs-client/core'
import { CubeProvider } from '@cubejs-client/react'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import { RootState } from '../app/store'

export const DeskCubeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [cubejsApiState, setCubejsApiState] = React.useState<null | CubejsApi>(
    null
  )
  const accessToken = ReactRedux.useSelector(
    (state: RootState) => state.auth.accessToken
  )

  React.useEffect(() => {
    setCubejsApiState(
      cubejs(accessToken ?? '', {
        apiUrl:
          `${process.env.REACT_APP_SERVER_URL}/cube/cubejs-api/v1` ??
          'http://localhost:8901/cubejs-api/v1',
      })
    )
  }, [accessToken])

  return <CubeProvider cubejsApi={cubejsApiState}>{children}</CubeProvider>
}
