import { grpc } from '@improbable-eng/grpc-web'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

import { AppThunk } from '../../app/store'
import csv_pb from '../../proto/csv_pb'
import csv_pb_service from '../../proto/csv_pb_service'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from '../auth/authSlice'

const tenantAppCSVAdapter =
  createEntityAdapter<csv_pb.TenantAppCSVExportLog.AsObject>({
    selectId: (csvFile) => csvFile.id,
    sortComparer: (a, b) => {
      return a.id < b.id ? 1 : -1
    },
  })

interface TenantAppCSVExportState {
  loading: boolean
  error: string | null
}

export const tenantAppCSVSlice = createSlice({
  name: 'tenantAppCSV',
  initialState: tenantAppCSVAdapter.getInitialState<TenantAppCSVExportState>({
    loading: false,
    error: null,
  }),
  reducers: {
    fetchExportLogsStart(state) {
      state.error = null
    },
    fetchExportLogsOnMessage: (
      state,
      action: PayloadAction<{
        message: csv_pb.ListTenantAppCSVExportLogsResponse.AsObject
      }>
    ) => {
      tenantAppCSVAdapter.setAll(state, action.payload.message.logsList)
    },
    createTenantAppCSVStart(state) {
      state.loading = true
      state.error = null
    },
    createTenantAppCSVOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      state.loading = false

      const { code, message } = action.payload
      if (code !== grpc.Code.OK) {
        state.error = message
        consoleErrorWithAirbrake(message)
        return
      }
      state.error = null
    },
  },
})
export default tenantAppCSVSlice.reducer

const {
  fetchExportLogsStart,
  fetchExportLogsOnMessage,
  createTenantAppCSVStart,
  createTenantAppCSVOnEnd,
} = tenantAppCSVSlice.actions

export const createTenantAppCSV =
  (startDate: Date, endDate: Date): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    dispatch(createTenantAppCSVStart())
    const client = grpcClient<
      csv_pb.CreateTenantAppCSVRequest,
      csv_pb.CreateTenantAppCSVResponse
    >(csv_pb_service.CSVAPI.CreateTenantAppCSV)

    const req = new csv_pb.CreateTenantAppCSVRequest()
    const startTimestamp = new Timestamp()
    startTimestamp.fromDate(startDate)
    req.setStartDate(startTimestamp)
    const endTimestamp = new Timestamp()
    endTimestamp.fromDate(endDate)
    req.setEndDate(endTimestamp)

    client.onMessage(() => {
      dispatch(fetchExportLogs(false))
    })
    client.onEnd((code, message) => {
      dispatch(createTenantAppCSVOnEnd({ code, message }))
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
      }
    })
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) {
      meta.append('authorization', 'bearer ' + token)
    }
    client.start(meta)
    client.send(req)
    client.finishSend()
  }

const fetchInterval = 5000
let closeFetchExportLogsTimer: (() => void) | null

export const closeFetchExportLogs = (): void => {
  if (!closeFetchExportLogsTimer) {
    return
  }
  closeFetchExportLogsTimer()
  closeFetchExportLogsTimer = null
}

export const fetchExportLogs =
  (needsLoop = true): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    dispatch(fetchExportLogsStart())
    const client = grpcClient<Empty, csv_pb.ListTenantAppCSVExportLogsResponse>(
      csv_pb_service.CSVAPI.ListTenantAppCSVExportLogs
    )

    client.onMessage((message) => {
      dispatch(fetchExportLogsOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      if (needsLoop) {
        const fetchExportLogsId = setTimeout(() => {
          dispatch(fetchExportLogs())
        }, fetchInterval)
        closeFetchExportLogsTimer = () => {
          clearTimeout(fetchExportLogsId)
        }
      }
    })
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) {
      meta.append('authorization', 'bearer ' + token)
    }
    client.start(meta)
    client.send(new Empty())
    client.finishSend()
  }
