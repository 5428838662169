/**
 * チケットのテキストを加工し表示する
 *
 * チケットのメッセージと返信のメッセージは処理の内容と表示物が同一なため、
 * データソースのみを分けたコンポーネントを2つエクスポートする
 */

import * as React from 'react'
import * as ReactRedux from 'react-redux'

import type { RootState } from '../../../../app/store'
import RequesterChatTextComponent from '../../../../components/RequesterChatText'
import * as Operations from './operations'
import * as Selectors from './selectors'

type ConversationTextProps = {
  conversationId: string
}
/**
 * チケットのメッセージテキストを加工し表示する
 */
const _ConversationText: React.FC<ConversationTextProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()

  const entityMessage = ReactRedux.useSelector((state: RootState) =>
    Selectors.ticketEntityMessageSelector(state, props.conversationId)
  )
  const replacedMessage = ReactRedux.useSelector((state: RootState) =>
    Selectors.messageSelector(state, props.conversationId)
  )

  React.useEffect(() => {
    replacedMessage === '' &&
      dispatch(
        Operations.initMessage({
          message: entityMessage,
          key: props.conversationId,
        })
      )
  }, [dispatch, entityMessage, props, replacedMessage])

  return (
    <RequesterChatTextComponent
      __dangerouslyHTML={{
        __html: replacedMessage === '' ? entityMessage : replacedMessage,
      }}
    />
  )
}
export const ConversationText = React.memo(_ConversationText)

type ReplyTextProps = {
  conversationId: string
  replyId: string
}
/**
 * チケットの返信のメッセージテキストを加工し表示する
 */
export const _ReplyText: React.FC<ReplyTextProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()

  const entityMessage = ReactRedux.useSelector((state: RootState) =>
    Selectors.replyEntityMessageSelector(
      state,
      props.conversationId,
      props.replyId
    )
  )
  const replacedMessage = ReactRedux.useSelector((state: RootState) =>
    Selectors.messageSelector(state, `${props.conversationId}-${props.replyId}`)
  )

  React.useEffect(() => {
    replacedMessage === '' &&
      dispatch(
        Operations.initMessage({
          key: `${props.conversationId}-${props.replyId}`,
          message: entityMessage,
        })
      )
  }, [dispatch, entityMessage, props, replacedMessage])

  return (
    <RequesterChatTextComponent
      __dangerouslyHTML={{
        __html: replacedMessage === '' ? entityMessage : replacedMessage,
      }}
    />
  )
}
export const ReplyText = React.memo(_ReplyText)

type LastReplyTextProps = {
  conversationId: string
}
const _LastReplyText: React.FC<LastReplyTextProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()

  const entityMessage = ReactRedux.useSelector((state: RootState) =>
    Selectors.lastReplyMessageSelector(state, props.conversationId)
  )
  const lastReplyId = ReactRedux.useSelector((state: RootState) =>
    Selectors.lastReplyId(state, props.conversationId)
  )
  const replacedMessage = ReactRedux.useSelector((state: RootState) =>
    Selectors.messageSelector(state, `${props.conversationId}-${lastReplyId}`)
  )

  React.useEffect(() => {
    replacedMessage === '' &&
      dispatch(
        Operations.initMessage({
          key: `${props.conversationId}-${lastReplyId}`,
          message: entityMessage,
        })
      )
  }, [dispatch, entityMessage, props, replacedMessage, lastReplyId])

  return (
    <RequesterChatTextComponent
      __dangerouslyHTML={{
        __html: replacedMessage === '' ? entityMessage : replacedMessage,
      }}
    />
  )
}
export const LastReplyText = React.memo(_LastReplyText)
