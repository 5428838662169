import React from 'react'

import Error from './Error'

const UnknownError: React.FC = () => {
  return (
    <Error
      title="問題が発生しました"
      message="しばらくお待ちいただいてから、もう一度お試しください。状況が改善しない場合は、管理者にお問い合わせください。"
    />
  )
}

export default UnknownError
