import * as RTK from '@reduxjs/toolkit'

import { attachmentEntityAdapter } from '../ActivityMessageAttachment/lib/entityAdapter'
import { AttachmentItem } from '../ActivityMessageAttachment/types/AttachmentItem'
import * as Operations from './operations'

export type State = {
  removedAttachmentIDs: string[]
  creatingAttachments: RTK.EntityState<AttachmentItem>
}
const slice = RTK.createSlice({
  name: 'ActivityMessagePlanEditForm',
  initialState: {
    removedAttachmentIDs: [],
    creatingAttachments: attachmentEntityAdapter.getInitialState(),
  } as State,
  reducers: {
    removeAttachment: (state, action: RTK.PayloadAction<{ id: string }>) => {
      const creatingAttachment = attachmentEntityAdapter
        .getSelectors()
        .selectById(state.creatingAttachments, action.payload.id)
      if (creatingAttachment) {
        attachmentEntityAdapter.removeOne(
          state.creatingAttachments,
          action.payload.id
        )
        return state
      }

      state.removedAttachmentIDs.push(action.payload.id)
      return state
    },
    reset: (state) => {
      state.removedAttachmentIDs = []
      state.creatingAttachments = attachmentEntityAdapter.getInitialState()
    },
  },
  extraReducers: (builder) => {
    builder.addCase(Operations.uploadFile.pending, (state, action) => {
      attachmentEntityAdapter.addOne(state.creatingAttachments, {
        id: action.meta.arg.id,
        status: 'pending',
        fileName: action.meta.arg.file.name,
        mimeType: action.meta.arg.file.type,
      })
    })
    builder.addCase(Operations.uploadFile.fulfilled, (state, action) => {
      attachmentEntityAdapter.updateOne(state.creatingAttachments, {
        id: action.meta.arg.id,
        changes: {
          status: 'success',
          itemID: action.payload.itemID,
          groupID: action.payload.groupID,
          url: action.payload.url,
        },
      })
    })
    builder.addCase(Operations.uploadFile.rejected, (state, action) => {
      attachmentEntityAdapter.updateOne(state.creatingAttachments, {
        id: action.meta.arg.id,
        changes: {
          status: 'error',
        },
      })
    })
  },
})

export const { actions, reducer } = slice
