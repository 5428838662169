import {
  Box,
  Button,
  DownloadIcon,
  ShareGenericIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from '@fluentui/react-northstar'
import { saveAs } from 'file-saver'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { onImagePreviewClose } from '../features/chat/imagePreviewSlice'
import { consoleErrorWithAirbrake } from '../utils'
import styles from './PicturePreview.module.css'

// プレビュー画面を開いた瞬間、画像は最大画面の70%を占めします。
const maxRatio = 0.7

// 画像を納めるhtmlのid。
const imageContainerId = `imgC`

export const PicturePreview: React.FC<{
  className?: string
  src: string //表示用のurl
  downloadData?: string //ダウンロード用のbase64
  openUrl?: string // 新しいタブで開けるかどうか
  hidden: boolean // プレビューを表示するかどうか
}> = ({ className, src, downloadData, openUrl, hidden }) => {
  const dispatch = useDispatch()

  // 画像の参照
  const imgRef = useRef<HTMLImageElement>(null)

  // プレビュー画面容器の参照
  const picturePreviewRef = useRef<HTMLDivElement>(null)

  // 虫眼鏡により、コントロールする画像の高さ
  const [height, setHeight] = useState<number>(0)

  // 虫眼鏡のコントロール変数をリセットします。
  const resetZoomStatus = () => setHeight(0)

  // 画像自体の高さ
  const [originalHeight, setOriginalHeight] = useState<number>(0)

  // 画像自体の幅さ
  const [originalWidth, setOriginalWidth] = useState<number>(0)

  // 画像自体のサイズと画面全体サイズをリセットします。
  const resetH = () => {
    setOriginalHeight(0)
    setOriginalWidth(0)
  }

  // srcが変化される時(クリックする画像が変わる時)。
  useEffect(() => {
    // 画像関連サイズをリセットします。
    resetH()

    // 画像のサイズ情報を取得します
    const img = new Image()
    img.src = src
    try {
      img.onload = () => {
        setOriginalHeight(img.height)
        setOriginalWidth(img.width)
      }
    } catch (e) {
      consoleErrorWithAirbrake(
        `Error during fetching the original size of image in preview: src=${src}`,
        e
      )
    }
  }, [src])

  // When image preview is closed or opened, reset zoom controller.
  useEffect(() => {
    resetZoomStatus()
  }, [hidden])

  // 初期段階だけ、キーボードListenerを登録します。
  useEffect(() => {
    // ESCを押した時には、プレビュー画面を閉じます
    const keyUpHandler = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        dispatch(onImagePreviewClose())
        return
      }
    }
    window.addEventListener('keyup', keyUpHandler)
    return () => {
      window.removeEventListener('keyup', keyUpHandler)
    }
  }, [dispatch])

  // +虫眼鏡クリック、スクロールによる画像を拡大動作
  function expandImage() {
    if (imgRef?.current?.height) {
      setHeight(Math.min(imgRef?.current?.height * 1.1, originalHeight * 3))
    }
  }

  // -虫眼鏡クリック、スクロールによる画像を縮小動作
  function reduceImage() {
    if (imgRef?.current?.height) {
      setHeight(Math.max(imgRef?.current?.height * 0.9, originalHeight * 0.1))
    }
  }

  const imageIsReady = (): boolean => {
    // プレビュー全体画面の高さまた取得できていない状態、画像を非表示にします。
    if (picturePreviewRef?.current?.offsetHeight == null) {
      return false
    }

    // プレビュー全体画面の幅さまた取得できていない状態、画像を非表示にします。
    if (picturePreviewRef?.current?.offsetWidth == null) {
      return false
    }

    // 画像自体の高さまた取得できていない状態、画像を非表示にします。
    if (originalHeight === 0) {
      return false
    }

    // 画像自体の幅さまた取得できていない状態、画像を非表示にします。
    return originalWidth !== 0
  }
  return (
    <div
      hidden={hidden}
      onClick={(e) => {
        // 灰色の背景をクリックして、プレビュー画面を閉じるように
        const element = e.target as HTMLElement
        if (element.id !== imageContainerId) {
          return
        }
        dispatch(onImagePreviewClose())
      }}
    >
      <Box
        ref={picturePreviewRef}
        className={`${styles.container} ${className ?? ''}`}
      >
        <Box className={styles.header}>
          <Box className={styles.tool}>
            <ZoomOutIcon
              onClick={() => reduceImage()}
              className={styles.toolZoom}
              outline={true}
            />
          </Box>

          <Box className={styles.tool}>
            <ZoomInIcon
              onClick={() => expandImage()}
              className={styles.toolZoom}
              outline={true}
            />
          </Box>

          <Box className={styles.tool}>
            {downloadData && (
              <DownloadIcon
                className={styles.toolDownload}
                outline={true}
                onClick={() => {
                  saveAs(downloadData, 'download')
                }}
              />
            )}
            {openUrl && (
              <ShareGenericIcon
                className={styles.toolDownload}
                outline={true}
                onClick={() => window.open(openUrl)}
              />
            )}
          </Box>
          <Button
            className={styles.closeButton}
            onClick={() => dispatch(onImagePreviewClose())}
            inverted={true}
            content={'閉じる'}
            iconOnly
          />
        </Box>
        <div
          className={styles.imageContainer}
          id={imageContainerId}
          onWheel={(e) => {
            if (e.deltaY < 0) {
              expandImage()
              return
            }
            reduceImage()
          }}
        >
          {imageIsReady() && (
            <img
              alt="preview"
              ref={imgRef}
              style={{
                height:
                  height === 0
                    ? calculateDefaultHeight(
                        originalHeight,
                        originalWidth,
                        picturePreviewRef?.current?.offsetHeight || 0,
                        picturePreviewRef?.current?.offsetWidth || 0
                      )
                    : `${height}px`,
              }}
              className={styles.image}
              src={src}
            />
          )}
        </div>
      </Box>
    </div>
  )
}

// プレビュー画面を開いた時に、画像のレンダリングサイズを計算します。
// 目的は最初表示される画像は横、縦はみ出さないように
const calculateDefaultHeight = (
  iHeight: number,
  iWidth: number,
  cHeight: number,
  cWidth: number
): string => {
  const ratioI = iHeight / iWidth //画像自体の比例
  const ratioC = cHeight / cWidth //画面全体の比例
  // 縦の方は横より先に画面の限界に当たります。
  if (ratioI > ratioC) {
    const adjustHeight = Math.min(maxRatio * cHeight, iHeight)
    return `${adjustHeight}px`
  }

  // 横の方は縦より先に画面の限界に当たります。
  const adjustWidth = Math.min(maxRatio * cWidth, iWidth)
  const adjustHeight = (iHeight / iWidth) * adjustWidth
  return `${adjustHeight}px`
}
