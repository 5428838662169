import { grpc } from '@improbable-eng/grpc-web'
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

import { grpcClient } from '../../service/grpcClient'

export type GRPCErrorResponseObject = {
  code: grpc.Code
  message: string
  trailers: grpc.Metadata
}

export const grpcStandardRequestPromise = <
  TRequest extends grpc.ProtobufMessage,
  TResponse extends grpc.ProtobufMessage
>(
  service: grpc.MethodDefinition<TRequest, TResponse>,
  body: TRequest,
  meta?: grpc.Metadata
): Promise<
  QueryReturnValue<
    ReturnType<TResponse['toObject']>,
    GRPCErrorResponseObject,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  >
> =>
  new Promise((resolve) => {
    const client = grpcClient(service)
    client.onMessage((message) => {
      resolve({ data: message.toObject() as ReturnType<TResponse['toObject']> })
    })
    client.onEnd((code, message, trailers) => {
      if (code !== grpc.Code.OK) {
        resolve({ error: { code, message, trailers } })
      }

      client.close()
    })

    client.start(meta)
    client.send(body)
    client.finishSend()
  })

export const authorizedGrpcRequest = async <
  TRequest extends grpc.ProtobufMessage,
  TResponse extends grpc.ProtobufMessage
>(
  service: grpc.MethodDefinition<TRequest, TResponse>,
  body: TRequest,
  accessToken: string
): Promise<
  QueryReturnValue<
    ReturnType<TResponse['toObject']>,
    GRPCErrorResponseObject,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  >
> => {
  const meta = new grpc.Metadata()
  meta.set('Authorization', `Bearer ${accessToken}`)

  return await grpcStandardRequestPromise(service, body, meta)
}
