import React from 'react'

import Error from './Error'

const NotFound: React.FC = () => {
  return (
    <Error
      title="このページはご利用いただけません"
      message="リンクに問題があるか、ページが削除された可能性があります。投稿タブからの画面遷移でこのページが表示された場合は、問い合わせが別チャネルに移動された可能性があります。"
    />
  )
}

export default NotFound
