import { grpc } from '@improbable-eng/grpc-web'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'

import { AppThunk } from '../../app/store'
import { DraftFaqStatus } from '../../consts'
import draft_faq_pb from '../../proto/draft_faq_pb'
import draft_faq_pb_service from '../../proto/draft_faq_pb_service'
import faq_pb, { FaqError, FaqErrorCode } from '../../proto/faq_pb'
import faq_pb_service from '../../proto/faq_pb_service'
import { Status } from '../../proto/status_pb'
import { GRPCErrorResponseObject } from '../../renewfeatures/deskApiCommon/common'
import { deskOperatorApi } from '../../renewfeatures/deskApiOperator'
import { consoleErrorWithAirbrake, stringToUint8Array } from '../../utils'
import { resetTokens } from '../auth/authSlice'

export declare type Faq = faq_pb.Faq.AsObject

export const faqAdapter = createEntityAdapter<Faq>({
  selectId: (faq) => faq.ticketId,
})

export interface FaqStateError {
  responseCode: number
  errorCode?: number
  message?: string
  param?: string
}
export class FaqStateErrorObject {
  static isInvalidArgumentError(err: FaqStateError | null): boolean {
    return err != null && err.responseCode == 3 && err.errorCode == undefined
  }
  static isFieldViolationError(
    err: FaqStateError | null,
    param: string
  ): boolean {
    return (
      err != null &&
      err.errorCode == FaqErrorCode.FAQ_FIELD_VIOLATION &&
      err.param == param
    )
  }
}

export interface FaqState {
  loading: boolean
  generateLoading: boolean
  generatedRequest: string
  generatedResponse: string
  draftFaqCompleted: boolean
  error: FaqStateError | null
}

export const faqSlice = createSlice({
  name: 'faq',
  initialState: faqAdapter.getInitialState<FaqState>({
    generateLoading: false,
    generatedRequest: '',
    generatedResponse: '',
    draftFaqCompleted: false,
    loading: false,
    error: null,
  }),
  reducers: {
    clearFaqError(state) {
      state.error = null
    },
    getFaqStart(state) {
      state.loading = true
    },
    getFaqOnMessage(
      state,
      action: PayloadAction<{ message: faq_pb.Faq.AsObject }>
    ) {
      faqAdapter.upsertOne(state, action.payload.message)
    },
    getFaqOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
      }>
    ) {
      const { code } = action.payload
      state.loading = false
      if (code !== grpc.Code.OK) {
        state.error = {
          responseCode: code,
        }
      }
    },
    updateFaqStart(state) {
      state.loading = true
    },
    updateFaqOnMessage(
      state,
      action: PayloadAction<{ message: faq_pb.Faq.AsObject }>
    ) {
      faqAdapter.upsertOne(state, action.payload.message)
    },
    updateFaqOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
      }>
    ) {
      const { code } = action.payload
      state.loading = false
      if (code !== grpc.Code.OK) {
        state.error = {
          responseCode: code,
        }
      }
    },
    createDraftFaqStart(state) {
      state.generateLoading = true
      state.draftFaqCompleted = false
    },
    createDraftFaqOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      if (code !== grpc.Code.OK) {
        consoleErrorWithAirbrake(`createDraftFaq failed=${message}`)
      }
    },
    fetchFaqOnMessage(
      state,
      action: PayloadAction<{ message: draft_faq_pb.DraftFaq.AsObject }>
    ) {
      const { message } = action.payload
      if (message.status === DraftFaqStatus.waiting) {
        return
      }
      state.generatedRequest = message.request
      state.generatedResponse = message.response
      state.generateLoading = false
      state.draftFaqCompleted = true
    },
    fetchDraftFaqOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      if (code == grpc.Code.NotFound) {
        return
      } else if (code !== grpc.Code.OK) {
        consoleErrorWithAirbrake(`fetchDraftFaq failed=${message}`)
      }
    },
    clearGeneratedDraftFaq(state) {
      state.generatedRequest = ''
      state.generatedResponse = ''
    },
    clearDraftFaqCompleted(state) {
      state.draftFaqCompleted = false
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      deskOperatorApi.endpoints.createFaq.matchRejected,
      (state, action) => {
        const error = action.payload as GRPCErrorResponseObject
        if (error.code !== grpc.Code.OK) {
          const errors = extractFaqError(error.trailers)
          if (errors.length > 0) {
            const err = errors[0]
            state.error = {
              responseCode: error.code,
              errorCode: err.code,
              message: err.message,
              param: err.param,
            }
          } else {
            state.error = {
              responseCode: error.code,
            }
          }
        }
      }
    )
  },
})

export const {
  clearFaqError,
  getFaqStart,
  getFaqOnMessage,
  getFaqOnEnd,
  updateFaqStart,
  updateFaqOnMessage,
  updateFaqOnEnd,
  createDraftFaqStart,
  createDraftFaqOnEnd,
  fetchFaqOnMessage,
  fetchDraftFaqOnEnd,
  clearGeneratedDraftFaq,
  clearDraftFaqCompleted,
} = faqSlice.actions
export default faqSlice.reducer

const createFaqRequestModel = (props: Partial<faq_pb.Faq.AsObject>) => {
  const faq = new faq_pb.Faq()
  if (props.request != null) faq.setRequest(props.request)
  if (props.response != null) faq.setResponse(props.response)
  if (props.publish != null) faq.setPublish(props.publish)
  if (props.name != null) faq.setName(props.name)
  if (props.labelsList != null) faq.setLabelsList(props.labelsList)
  return faq
}

export const extractFaqError = (
  trailers: grpc.Metadata
): FaqError.AsObject[] => {
  let details: FaqError.AsObject[] = []
  const detailBinary = trailers.headersMap['grpc-status-details-bin']
  if (detailBinary != null && detailBinary.length !== 0) {
    const decodeMsg = atob(detailBinary[0])
    const status = Status.deserializeBinary(stringToUint8Array(decodeMsg))
    details = status.getDetailsList().map((detail) => {
      const uint8Array = detail.getValue_asU8()
      return FaqError.deserializeBinary(uint8Array).toObject()
    })
  }
  return details
}

export const getFaq =
  (ticketId: number): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<faq_pb.GetFaqRequest, faq_pb.Faq>(
      faq_pb_service.FaqAPI.GetFaq
    )

    const req = new faq_pb.GetFaqRequest()
    req.setTicketId(ticketId)

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      dispatch(getFaqOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code) => {
      dispatch(getFaqOnEnd({ code }))
    })

    dispatch(clearFaqError())
    dispatch(getFaqStart())
    client.start(meta)
    client.send(req)
    client.finishSend()
  }

const createUpdateFaqRequest = (
  ticketId: number,
  props: Partial<faq_pb.Faq.AsObject>
) => {
  const faq = createFaqRequestModel(props)

  const req = new faq_pb.UpdateFaqRequest()
  req.setFaq(faq)
  req.setTicketId(ticketId)
  return req
}

export const updateFaq =
  (ticketId: number, props: Partial<faq_pb.Faq.AsObject>): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<faq_pb.UpdateFaqRequest, faq_pb.Faq>(
      faq_pb_service.FaqAPI.UpdateFaq
    )

    const req = createUpdateFaqRequest(ticketId, props)

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      dispatch(updateFaqOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code) => {
      dispatch(updateFaqOnEnd({ code }))
    })

    dispatch(clearFaqError())
    dispatch(updateFaqStart())
    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const fetchDraftFaq =
  (ticketId: number): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<
      draft_faq_pb.GetDraftFaqRequest,
      draft_faq_pb.DraftFaq
    >(draft_faq_pb_service.DraftFaqAPI.GetDraftFaq)

    const req = new draft_faq_pb.GetDraftFaqRequest()
    req.setTicketId(ticketId)

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onMessage((message) => {
      dispatch(fetchFaqOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(fetchDraftFaqOnEnd({ code, message }))
    })

    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const createDraftFaq =
  (ticketId: number): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const client = grpcClient<draft_faq_pb.CreateDraftFaqRequest, Empty>(
      draft_faq_pb_service.DraftFaqAPI.CreateDraftFaq
    )

    const req = new draft_faq_pb.CreateDraftFaqRequest()
    req.setTicketId(ticketId)

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(createDraftFaqOnEnd({ code, message }))
    })

    dispatch(clearFaqError())
    dispatch(createDraftFaqStart())
    client.start(meta)
    client.send(req)
    client.finishSend()
  }
