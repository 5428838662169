import { GripperBarHorizontalIcon } from '@fluentui/react-icons-mdl2'
import { Button, Flex, TrashCanIcon } from '@fluentui/react-northstar'
import { Text } from '@fluentui/react-northstar'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'
import { Column, Row, useTable } from 'react-table'

import { Dropdown } from '../../components/Dropdown'
import { DefaultFields } from '../../consts'
import { hoverFunc } from '../../draggableRow'
import { TicketCustomField } from '../../proto/ticket_custom_field_pb'
import { consoleErrorWithAirbrake } from '../../utils'
import styles from '../ticket/TicketCustomFieldList.module.css'
import style from './TabConfig.module.css'
import {
  DefaultTabConfig,
  addField,
  moveFields,
  removeField,
} from './tabConfigSlice'

interface DragItem {
  index: number
  type: string
}
const dndItemType = 'tabConfigFieldRow'

const DraggableRow: FC<{
  row: Row<FieldRow>
  index: number
  moveRow: (i: number, j: number) => void
  onDragEnd: () => void
}> = ({ row, index, moveRow, onDragEnd }) => {
  const dropRef = useRef<HTMLTableRowElement>(null)
  const dragRef = useRef(null)

  const [, drop] = useDrop({
    accept: dndItemType,
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      hoverFunc(index, dropRef, moveRow, item, monitor)
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: dndItemType,
    item: { type: dndItemType, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      onDragEnd()
    },
  })
  const opacity = isDragging ? 0.3 : 1
  preview(drop(dropRef))
  drag(dragRef)

  return (
    <tr
      ref={dropRef}
      className={styles.tr}
      {...row.getRowProps()}
      style={{ opacity }}
    >
      <td className={styles.td} ref={dragRef}>
        <GripperBarHorizontalIcon className={styles.icon} />
      </td>
      {row.cells.map((cell) => {
        return (
          <td
            className={styles.td}
            {...cell.getCellProps()}
            key={cell.getCellProps().key}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}

interface FieldRow {
  id: number
  name: string
  action: JSX.Element
}

interface FieldOption {
  value: string
  header: string
  ticketCustomFieldId: number | null
}

type Props = {
  customFields: TicketCustomField.AsObject[]
  tabConfigState: DefaultTabConfig
}

const columns: Column<FieldRow>[] = [
  {
    Header: '',
    accessor: 'name',
  },
  {
    Header: '',
    accessor: 'action',
  },
]

const TabConfigFields: React.FC<Props> = ({
  customFields,
  tabConfigState,
}): JSX.Element => {
  const dispatch = useDispatch()
  const moveRow = (dragIndex: number, hoverIndex: number) => {
    dispatch(moveFields({ dragIndex: dragIndex, hoverIndex: hoverIndex }))
  }

  const defaultSelectableFieldOptions: FieldOption[] = useMemo(
    () => [
      {
        value: DefaultFields.KeyId.value,
        header: DefaultFields.KeyId.name,
        ticketCustomFieldId: null,
      },
      {
        value: DefaultFields.Requester.value,
        header: DefaultFields.Requester.name,
        ticketCustomFieldId: null,
      },
      {
        value: DefaultFields.Subject.value,
        header: DefaultFields.Subject.name,
        ticketCustomFieldId: null,
      },
      {
        value: DefaultFields.AssignedUserId.value,
        header: DefaultFields.AssignedUserId.name,
        ticketCustomFieldId: null,
      },
      {
        value: DefaultFields.Status.value,
        header: DefaultFields.Status.name,
        ticketCustomFieldId: null,
      },
      {
        value: DefaultFields.CreatedAt.value,
        header: DefaultFields.CreatedAt.name,
        ticketCustomFieldId: null,
      },
      {
        value: DefaultFields.ReceivedAt.value,
        header: DefaultFields.ReceivedAt.name,
        ticketCustomFieldId: null,
      },
      {
        value: DefaultFields.SentAt.value,
        header: DefaultFields.SentAt.name,
        ticketCustomFieldId: null,
      },
      {
        value: DefaultFields.FaqStatus.value,
        header: DefaultFields.FaqStatus.name,
        ticketCustomFieldId: null,
      },
      {
        value: DefaultFields.FaqCreator.value,
        header: DefaultFields.FaqCreator.name,
        ticketCustomFieldId: null,
      },
      ...customFields.map((customField) => {
        return {
          value: `custom.${customField.id}.${customField.name}`,
          header: customField.name,
          ticketCustomFieldId: customField.id,
        }
      }),
    ],
    [customFields]
  )

  const [selectableFieldOptions, setselectableFieldOptions] = useState<
    FieldOption[]
  >([])

  useEffect(() => {
    const selectedFieldOptions = tabConfigState.fields.map(
      (field) => field.identifierName
    )
    const availables = defaultSelectableFieldOptions.filter((option) => {
      const found = defaultSelectableFieldOptions.find((obj) => {
        return obj.value === option.value
      })
      if (!found) {
        consoleErrorWithAirbrake(
          `could not find proper option ${option.value} from ${defaultSelectableFieldOptions}`
        )
        return
      }
      return !selectedFieldOptions.includes(found.header)
    })
    setselectableFieldOptions(availables)
  }, [tabConfigState.fields, defaultSelectableFieldOptions])

  const [fieldHeader, setFieldHeader] = useState('')

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<FieldRow>({
      columns,
      data: tabConfigState.fields.map((r, index) => ({
        id: index,
        name: r.identifierName,
        action: (
          <Button
            icon={<TrashCanIcon />}
            text
            iconOnly
            onClick={() =>
              dispatch(removeField({ tabConfigFieldIndex: index }))
            }
          />
        ),
      })),
      getRowId: (row) => row.id.toString(),
    })

  const Content = () => {
    if (tabConfigState.fields.length === 0) {
      return (
        <Text
          content="表示項目は一つ以上設定してください。"
          styles={({ theme: { siteVariables } }) => ({
            color: siteVariables.colorScheme.red.foreground,
            fontSize: '12px',
            marginTop: '5px',
          })}
        />
      )
    }

    return (
      <table {...getTableProps()} className={styles.table}>
        <thead className={styles.thead}>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              <th className={styles.th}></th>
              {headerGroup.headers.map((column) => (
                <th
                  className={styles.th}
                  {...column.getHeaderProps()}
                  key={column.getHeaderProps().key}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <DraggableRow
                index={index}
                row={row}
                moveRow={moveRow}
                onDragEnd={() => {
                  return
                }}
                {...row.getRowProps()}
                key={row.getRowProps().key}
              />
            )
          })}
        </tbody>
      </table>
    )
  }

  return (
    <>
      <Content />
      <Flex gap="gap.smaller" className={style.tabConfigContainer}>
        <Dropdown
          fluid
          value={[fieldHeader]}
          items={[
            {
              value: '',
              header: '選択する',
            },
            ...selectableFieldOptions,
          ]}
          onValueChange={(values) => {
            setFieldHeader(values[0])
          }}
        />
        <Button
          content="項目を追加"
          primary
          onClick={() => {
            if (fieldHeader === '') return

            const found = defaultSelectableFieldOptions.find((obj) => {
              return obj.value === fieldHeader
            })
            if (!found) {
              consoleErrorWithAirbrake(
                `could not find proper option ${fieldHeader} from ${defaultSelectableFieldOptions}`
              )
              return
            }
            const ticketCustomFieldId = found.ticketCustomFieldId
            const identifierName = found.header

            dispatch(
              addField({
                identifierName: identifierName,
                ticketCustomFieldId: ticketCustomFieldId,
              })
            )
            setFieldHeader('')
          }}
        />
      </Flex>
    </>
  )
}

export default TabConfigFields
