import * as React from 'react'
import { debounce } from 'throttle-debounce'

import styles from './styles.module.css'

export type ChatButton = {
  key: string
  label: string
  url: string
  type: string
  data?: string
}

export type ChatButtonRow = ChatButton[]
export type ChatButtonsProp = ChatButtonRow[]

type Props = {
  buttons: ChatButtonsProp
  onClick: (key: string) => void
}
const RequesterChatButtonGroup: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      {props.buttons.map((row, index) => (
        <div key={index} className={styles.row}>
          {row.map((b) => (
            <ClickableListItem
              key={b.key}
              label={b.label}
              onClick={debounce(500, () => props.onClick(b.key))}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

type ClickableListItemProps = {
  label: string
  onClick: () => void
}
const ClickableListItem = React.memo<ClickableListItemProps>((props) => {
  return (
    <div className={styles.item} onClick={props.onClick}>
      {props.label}
    </div>
  )
})

export default RequesterChatButtonGroup
