import * as RTK from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'
import compareAsc from 'date-fns/compareAsc'
import formatISO from 'date-fns/fp/formatISO'
import parseISO from 'date-fns/fp/parseISO'

import { deskApi } from '../deskApi'
import { requesterTicketListApi } from './api'
import { RequesterReplyEntity } from './types/RequesterReplyEntity'
import { RequesterTicketEntity } from './types/RequesterTicketEntity'

// we don't want the change of this info cause the rendering so keep it in a static memory
// TODO find a way use React to control
export const requesterTicketListsInCurrentViewport = new Map<string, boolean>()
export declare type TicketEntityWithFlag = RequesterTicketEntity & {
  hasNewMessage?: boolean
  isNewlyCreated?: boolean
}
export const requesterTicketEntityAdapter =
  RTK.createEntityAdapter<RequesterTicketEntity>({
    sortComparer: (a, b) =>
      compareAsc(parseISO(a.createdAt), parseISO(b.createdAt)),
  })
export const requesterReplyEntityAdapter =
  RTK.createEntityAdapter<RequesterReplyEntity>({
    sortComparer: (a, b) =>
      compareAsc(parseISO(a.createdAt), parseISO(b.createdAt)),
  })

export type State = {
  /** 実行中のクエリ用の `maxUpdatedAt` */
  queryArgMaxUpdatedAt: string
  // 新規チケット作成後下にスクロール用
  triggerScrollToBottom: number
  /** 実行済みのクエリの `maxUpdatedAt` */
  latestUpdate: string
  /** アクティビティフィードからリンクされたConversationID */
  linkedConversationID: string | null
  /** アクティビティフィードからリンクされたReplyMessageID */
  linkedReplyMessageID: string | null
  latestReceivedTime: number
  ticketOrders: TicketEntityWithFlag[]
  showRepliesTicketIds: string[]
}
const initialState: State = {
  queryArgMaxUpdatedAt: formatISO(new Date()),
  latestUpdate: formatISO(new Date()),
  linkedConversationID: null,
  linkedReplyMessageID: null,
  latestReceivedTime: 0,
  ticketOrders: [],
  showRepliesTicketIds: [],
  triggerScrollToBottom: 0,
}
const slice = RTK.createSlice({
  name: 'requesterTicketList',
  initialState,
  reducers: {
    updateMaxUpdatedAtToNow(state) {
      state.queryArgMaxUpdatedAt = formatISO(new Date())
    },
    setLinkedMessageID(
      state,
      action: PayloadAction<{ conversationID: string; replyMessageID: string }>
    ) {
      state.linkedConversationID = action.payload.conversationID
      state.linkedReplyMessageID = action.payload.replyMessageID
    },
    setTicketRepliesOpen(
      state,
      action: PayloadAction<{ conversationID: string; open: boolean }>
    ) {
      if (!action.payload.open) {
        state.showRepliesTicketIds = state.showRepliesTicketIds.filter(
          (id) => id !== action.payload.conversationID
        )
        return
      }
      state.showRepliesTicketIds = [
        ...state.showRepliesTicketIds.filter(
          (id) => id !== action.payload.conversationID
        ),
        action.payload.conversationID,
      ]
    },
    setTriggerScrollToBottom(state) {
      state.triggerScrollToBottom = state.triggerScrollToBottom + 1
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      requesterTicketListApi.endpoints.getRequesterConversations.matchFulfilled,
      (state, action) => {
        const successfulRequestMaxUpdateAt = new Date(
          action.meta.arg.originalArgs.maxUpdatedAt || 0
        )
        const latestUpdate = new Date(state.latestUpdate)
        // update latest success fetch time
        // since different response may not return in order, secure it is later than already stored state
        if (successfulRequestMaxUpdateAt.getTime() > latestUpdate.getTime()) {
          state.latestUpdate = formatISO(successfulRequestMaxUpdateAt)
        }

        let nextLastLatestReceivedTime = 0

        for (const key in action.payload.entities) {
          nextLastLatestReceivedTime = Math.max(
            nextLastLatestReceivedTime,
            action.payload.entities[key]?.lastReplyUpdatedAt.seconds || 0
          )
        }

        // receive payload and adpter it to the current order in state
        const nextTicketOrders = reOrderTicketEntities(
          state.ticketOrders,
          action.payload.entities,
          state.latestReceivedTime,
          requesterTicketListsInCurrentViewport
        )

        // one new ticket is created since last load, keep it stable in the state
        const newlyCreatedTicketIds = nextTicketOrders
          .filter((f) => f.isNewlyCreated)
          .map((f) => f.id)
        state.ticketOrders = nextTicketOrders
        state.showRepliesTicketIds = [
          ...new Set([...newlyCreatedTicketIds, ...state.showRepliesTicketIds]),
        ]

        state.latestReceivedTime = nextLastLatestReceivedTime
      }
    )
    // ウェルカムメッセージ送信後、再びfetchを行う
    builder.addMatcher(
      requesterTicketListApi.endpoints.createWelcomeMessage.matchFulfilled,
      (state) => {
        const s = formatISO(new Date(new Date().getTime() + 1000))
        state.queryArgMaxUpdatedAt = s
      }
    )
    // 自動応答のボタンをクリックして、すぐlistConversationsを実行させるためです。なぜかというと、最新のlastReplyをlistConversationsのresponseから取りたいです。
    builder.addMatcher(
      requesterTicketListApi.endpoints.sendRequesterReplyButton.matchFulfilled,
      (state) => {
        state.queryArgMaxUpdatedAt = formatISO(
          new Date(new Date().getTime() + 1000)
        )
      }
    )
    // 返信テキストを送信してから、すぐlistConversationsを実行させるためです。なぜかというと、最新のlastReplyをlistConversationsのresponseから取りたいです。
    builder.addMatcher(
      deskApi.endpoints.createMessageReply.matchFulfilled,
      (state) => {
        state.queryArgMaxUpdatedAt = formatISO(
          new Date(new Date().getTime() + 1000)
        )
      }
    )
  },
})

export const reOrderTicketEntities = (
  originalTicketOrders: TicketEntityWithFlag[],
  payloads: RTK.Dictionary<RequesterTicketEntity>,
  latestReceivedTime: number,
  viewPortIds: Map<string, boolean>
): TicketEntityWithFlag[] => {
  const nextTicketOrders: TicketEntityWithFlag[] = []
  const checkedEntities = new Set<string>()

  // すでにstateに入ってあるticketOrdersを確認します
  for (let i = 0; i < originalTicketOrders.length; i++) {
    const v = originalTicketOrders[i]
    const targetInReceived = payloads[v.id]
    // current state exists in received target
    if (targetInReceived != null) {
      const isNewUpdated =
        (targetInReceived?.lastReplyUpdatedAt.seconds || 0) > latestReceivedTime
      nextTicketOrders.push({
        ...targetInReceived,
        hasNewMessage: isNewUpdated,
      })
      checkedEntities.add(targetInReceived.id)
      continue
    }
  }

  for (const key in payloads) {
    const entity = payloads[key]
    if (entity == null || checkedEntities.has(entity.id)) {
      continue
    }

    const hasNewMessage =
      (entity?.lastReplyUpdatedAt.seconds || 0) > latestReceivedTime
    // newCreated unless originalArrs is empty
    const isNewlyCreated = originalTicketOrders.length > 0
    // if newCreated is created, do not modify it

    // Received payload does not exists in the current state, which means it is new received, so append it
    nextTicketOrders.push({
      ...entity,
      hasNewMessage: hasNewMessage,
      isNewlyCreated: isNewlyCreated,
    })
  }

  const entitiesToBeReorderedAndAppended = nextTicketOrders
    .filter((v) => filterEntitiesOutsideOfViewport(v, viewPortIds))
    .sort((a, b) => a.lastReplyUpdatedAt.seconds - b.lastReplyUpdatedAt.seconds)

  const entitiesToBeStable = nextTicketOrders.filter(
    (v) => !filterEntitiesOutsideOfViewport(v, viewPortIds)
  )

  return [...entitiesToBeStable, ...entitiesToBeReorderedAndAppended]
}

// reorder the items outside of current viewport
// remain the items inside current viewport
const filterEntitiesOutsideOfViewport = (
  v: TicketEntityWithFlag,
  viewPortIds: Map<string, boolean>
) => {
  // viewportにあるものを移動しません
  if (viewPortIds.has(v.id)) {
    return false
  }
  // viewport外もの新着だけ移動させます
  return v.hasNewMessage
}

export const { reducer, actions } = slice
