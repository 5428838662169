import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../app/store'
import {
  FileProp,
  FilePropStatus,
} from '../../components/RequesterChatFileList'
import { assertNever } from '../../utils'
import { attachmentEntityAdapter } from '../ActivityMessageAttachment/lib/entityAdapter'

export const stateSelector = (state: RootState) =>
  state.activityMessagePlanNewForm
export const attachmentsSelector = createSelector(
  stateSelector,
  (state): FileProp[] => {
    return attachmentEntityAdapter
      .getSelectors()
      .selectAll(state)
      .map((attachment) => {
        return {
          id: attachment.id,
          status: statusToFilePropStatus(attachment.status),
          url: attachment.url,
          mimeType: attachment.mimeType,
          fileName: attachment.fileName,
        }
      })
  }
)

function statusToFilePropStatus(
  status: 'success' | 'error' | 'pending'
): FilePropStatus {
  switch (status) {
    case 'error':
      return 'error'
    case 'pending':
      return 'pending'
    case 'success':
      return 'done'
    default:
      assertNever(status)
      return 'error'
  }
}
