import { Button, Input, LinkIcon, Popup, Text } from '@fluentui/react-northstar'
import * as React from 'react'

import styles from './style.module.css'

type Props = {
  onClick: (value: string) => void
}
const LinkInsertPopup: React.FC<Props> = (props) => {
  return (
    <Popup
      trigger={<Button iconOnly text icon={<LinkIcon />} />}
      content={<Content {...props} />}
    />
  )
}

export default LinkInsertPopup

const Content: React.FC<Props> = (props) => {
  const [url, setUrl] = React.useState('')
  const [error, setError] = React.useState(false)

  const changeUrl = (value: string) => {
    setUrl(value)
    setError(!value.startsWith('https://'))
  }

  return (
    <div className={styles.content}>
      <div>
        <Text size="medium" weight="bold">
          リンクを挿入
        </Text>
      </div>
      <Input
        value={url}
        label="リンク"
        required
        placeholder="https://"
        error={error}
        onChange={(_, p) => changeUrl(p?.value ?? '')}
      />
      <Button
        content="挿入"
        primary
        onClick={() => props.onClick(url)}
        disabled={error || url === ''}
      />
    </div>
  )
}
