import { Pill } from '@fluentui/react-northstar'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { FaqStatusLabels } from '../../../consts'
import { setFaqStatuses } from '../ticketSlice'
import styles from './Pill.module.css'

const FaqStatusFilterPill: React.FC = () => {
  const ticketState = useSelector((state: RootState) => state.ticket)
  const dispatch = useDispatch()
  return (
    <Pill
      actionable={ticketState.filters.faqStatuses.length > 0}
      onDismiss={() => dispatch(setFaqStatuses({ statuses: [] }))}
      className={styles.pill}
    >
      FAQ作成ステータス：
      {ticketState.filters.faqStatuses
        .map((st) => {
          return FaqStatusLabels[st]
        })
        .join('・')}
    </Pill>
  )
}

export default FaqStatusFilterPill
