import { Datepicker, Flex, Text } from '@fluentui/react-northstar'
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import ticket_custom_field from '../../../proto/ticket_custom_field_pb'
import { customFormatter, displayDate } from '../../../utils'
import style from '../TabConfig.module.css'
import {
  clearTabConfigFilterError,
  isCustomDateValueFilter,
  setCustomDateFilterValue,
  setTabConfigFilterError,
} from '../tabConfigSlice'

type Props = {
  customField: ticket_custom_field.TicketCustomField.AsObject
  tabConfigFilterIndex: number
}

const datePattern = 'yyyy/MM/dd'

const TicketCustomFieldValueDateFilter: React.FC<Props> = ({
  customField,
  tabConfigFilterIndex,
}) => {
  const tabConfigState = useSelector((state: RootState) => state.tabConfig)
  const filter = tabConfigState.filters[tabConfigFilterIndex].filter
  let initialFilterStartDate = undefined
  let initialFilterEndDate = undefined
  if (isCustomDateValueFilter(filter)) {
    if ('startDate' in filter) {
      initialFilterStartDate = String(filter.startDate)
    }
    if ('endDate' in filter) {
      initialFilterEndDate = String(filter.endDate)
    }
  }
  const [startInputDate, setStartInputDate] = useState(initialFilterStartDate)
  const [endInputDate, setEndInputDate] = useState(initialFilterEndDate)
  const [isError, setIsError] = useState(false)
  const isMountRef = useRef(true)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isError) {
      dispatch(
        setTabConfigFilterError({
          identifierName:
            tabConfigState.filters[tabConfigFilterIndex].identifierName,
        })
      )
    } else {
      dispatch(
        clearTabConfigFilterError({
          identifierName:
            tabConfigState.filters[tabConfigFilterIndex].identifierName,
        })
      )
    }
  }, [dispatch, isError, tabConfigFilterIndex, tabConfigState.filters])

  useEffect(() => {
    if (isMountRef.current) {
      isMountRef.current = false
      return
    }

    if (
      startInputDate != null &&
      endInputDate != null &&
      new Date(startInputDate) > new Date(endInputDate)
    ) {
      setIsError(true)
      return
    } else {
      setIsError(false)
    }

    if (startInputDate == null && endInputDate == null) {
      dispatch(
        setCustomDateFilterValue({
          customFieldId: customField.id,
          tabConfigFilterIndex,
        })
      )
      return
    }

    const filterArg: {
      customFieldId: number
      tabConfigFilterIndex: number
      startDate?: string
      endDate?: string
    } = { customFieldId: customField.id, tabConfigFilterIndex }
    if (startInputDate !== '' && startInputDate != null)
      filterArg.startDate = startInputDate
    if (endInputDate !== '' && endInputDate != null)
      filterArg.endDate = endInputDate
    dispatch(setCustomDateFilterValue(filterArg))
  }, [
    dispatch,
    customField.id,
    startInputDate,
    endInputDate,
    tabConfigFilterIndex,
  ])

  return (
    <Flex column gap="gap.small" space="around" className={style.filter}>
      <Flex vAlign="center" gap="gap.small">
        <Text content="開始日" className={style.dateFilterSubject} />
        <Datepicker
          allowManualInput={false}
          inputPlaceholder="日付を選択"
          formatMonthDayYear={customFormatter}
          selectedDate={displayDate(startInputDate)}
          onDateChange={(_, date) => {
            if (date?.value == null) return setStartInputDate(undefined)
            const formattedDateString = format(date.value, datePattern)
            setStartInputDate(formattedDateString)
          }}
          input={{
            clearable: true,
          }}
        />
      </Flex>
      <Flex vAlign="center" gap="gap.small">
        <Text content="終了日" className={style.dateFilterSubject} />
        <Datepicker
          allowManualInput={false}
          inputPlaceholder="日付を選択"
          formatMonthDayYear={customFormatter}
          selectedDate={displayDate(endInputDate)}
          onDateChange={(_, date) => {
            if (date?.value == null) return setEndInputDate(undefined)
            const formattedDateString = format(date.value, datePattern)
            setEndInputDate(formattedDateString)
          }}
          input={{
            clearable: true,
          }}
        />
      </Flex>
      {isError && (
        <Text
          content="開始日は終了日より前の日付に設定してください"
          styles={({ theme: { siteVariables } }) => ({
            color: siteVariables.colorScheme.red.foreground,
            fontSize: '12px',
          })}
        />
      )}
    </Flex>
  )
}

export default TicketCustomFieldValueDateFilter
