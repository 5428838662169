import { EyeIcon, EyeSlashIcon } from '@fluentui/react-icons-northstar'
import { Button } from '@fluentui/react-northstar'
import React from 'react'

import styles from './TicketDetail.module.css'

interface Props {
  visible: boolean
  onClick?: (visible: boolean) => void
}

const TicketVisibleEvent: React.FC<Props> = ({ visible, onClick }) => {
  return (
    <Button
      className={styles.headerToolsButton}
      size="small"
      color="gray"
      icon={visible ? <EyeIcon /> : <EyeSlashIcon />}
      content={
        <span className={styles.headerToolsButtonText}>{'操作履歴'}</span>
      }
      text
      onClick={() => (onClick ? onClick(!visible) : null)}
    />
  )
}

export default TicketVisibleEvent
