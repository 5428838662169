import * as Graph from '@microsoft/microsoft-graph-types'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk } from '../../app/store'
import { consoleErrorWithAirbrake } from '../../utils'
import { resetTokens } from './authSlice'

export interface UserState {
  meId: string | null
  loading: boolean
  error: string | null
}

const initialState: UserState = {
  loading: false,
  error: null,
  meId: null,
}

export const usersAdapter = createEntityAdapter<Graph.User>({
  selectId: (u) => u.id || ('' as string),
})

export const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState<UserState>(initialState),
  reducers: {
    fetchMeStart(state) {
      state.loading = true
      state.error = null
    },
    fetchMeSuccess(
      state,
      action: PayloadAction<{
        meId: string
        user: Graph.User
      }>
    ) {
      const { user } = action.payload
      usersAdapter.addOne(state, user)
      state.meId = action.payload.meId
      state.loading = false
    },
    fetchMeFailure(state, action: PayloadAction<{ error: string }>) {
      const { error } = action.payload
      state.loading = false
      state.error = error
      consoleErrorWithAirbrake(error)
    },
    fetchUsersStart(state) {
      state.loading = true
      state.error = null
    },
    fetchUsersSuccess(state, action: PayloadAction<{ users: Graph.User[] }>) {
      usersAdapter.upsertMany(state, action.payload.users)
      state.loading = false
    },
    fetchUsersFailure(state, action: PayloadAction<{ error: string }>) {
      const { error } = action.payload
      state.loading = false
      state.error = error
      consoleErrorWithAirbrake(error)
    },
  },
})

export const {
  fetchMeStart,
  fetchMeSuccess,
  fetchMeFailure,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFailure,
} = usersSlice.actions
export default usersSlice.reducer

export const fetchMe =
  (): AppThunk =>
  async (dispatch, getState, { graphAPI }) => {
    const graphAccessToken = getState().auth.graphAccessToken
    if (!graphAccessToken) return
    try {
      dispatch(fetchMeStart())
      const res = await graphAPI.api('/me').get()
      dispatch(fetchMeSuccess({ meId: res.id, user: res }))
    } catch (err) {
      if (err.statusCode === 401) {
        dispatch(resetTokens())
        return
      }
      dispatch(fetchMeFailure({ error: err.toString() }))
    }
  }

export const fetchUsers =
  (ids: string[]): AppThunk =>
  async (dispatch, getState, { graphAPI }) => {
    const graphAccessToken = getState().auth.graphAccessToken
    if (!graphAccessToken) return
    // filter unfetched userIds
    const userIds = ids
      .filter((userId) => !getState().users.entities[userId])
      .filter((id) => id != null)
      .filter((id) => id.trim() !== '')
    if (userIds.length === 0) return
    dispatch(fetchUsersStart())
    // fetch users infos
    try {
      const res = await graphAPI.api(`/directoryObjects/getByIds`).post({
        ids: userIds,
        types: ['user'],
      })
      dispatch(fetchUsersSuccess({ users: res.value }))
    } catch (e) {
      if (e.statusCode === 401) {
        dispatch(resetTokens())
        return
      }
      dispatch(fetchUsersFailure({ error: e }))
    }
  }
