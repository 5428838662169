import { Datepicker, Text } from '@fluentui/react-northstar'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { TicketCustomField } from '../../../proto/ticket_custom_field_pb'
import { customFormatter, displayDate } from '../../../utils'
import { updateTicketCustomFieldValue } from '../customFieldValueSlice'
import styles from '../TicketDetail.module.css'
import { useErrorHandler } from './useErrorHandler'

type Props = {
  ticketId: number
  field: TicketCustomField.AsObject
  fieldValue?: string
}

const TicketPropertyCustomDateField: React.FC<Props> = ({
  ticketId,
  field,
  fieldValue,
}) => {
  const dispatch = useDispatch()
  const [inputDate, setInputDate] = useState(fieldValue)
  const customFieldValuesState = useSelector(
    (state: RootState) => state.customFieldValue
  )
  const customFieldValueEntity = customFieldValuesState.entities[field.id]
  const hasValue = customFieldValueEntity != null
  const selectedDate = displayDate(inputDate)
  const contentDate = selectedDate ? customFormatter(selectedDate) : '--'

  useErrorHandler(customFieldValueEntity, field, dispatch, hasValue)

  return (
    <>
      <Text weight="bold" content={field.name} />
      {field.editable ? (
        <Datepicker
          className={styles.ticketCustomFieldDatePicker}
          allowManualInput={false}
          inputPlaceholder="日付を選択"
          formatMonthDayYear={customFormatter}
          selectedDate={selectedDate}
          onDateChange={(_, date) => {
            if (!field.editable) return
            if (date?.value == null) return
            // 2022-03-13T00:00:00.000Z の形式に変換し、日付のみを考慮した形にする
            const pattern = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
            const formattedISOString = format(date.value, pattern)
            setInputDate(formattedISOString)
            dispatch(
              updateTicketCustomFieldValue(ticketId, field.id, field.type, [
                { dateValue: formattedISOString },
              ])
            )
          }}
        />
      ) : (
        <Text content={contentDate} />
      )}
    </>
  )
}

export default TicketPropertyCustomDateField
