import * as RTK from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'

export type State = {
  page: number
}
const slice = RTK.createSlice({
  initialState: {
    page: 1,
  } as State,
  name: 'ActivityMessagePlansPaginator',
  reducers: {
    incrementPage: (state, action: PayloadAction<{ lastPage: number }>) => {
      if (action.payload.lastPage <= state.page) return
      state.page = state.page + 1
    },
    decrementPage: (state) => {
      if (state.page <= 1) return
      state.page = state.page - 1
    },
    setFirstPage: (state) => {
      state.page = 1
    },
    setLastPage: (state, action: RTK.PayloadAction<{ lastPage: number }>) => {
      state.page = action.payload.lastPage
    },
  },
})

export const { actions, reducer } = slice
