import { LinkIcon } from '@fluentui/react-icons-northstar'
import { Button, Popup } from '@fluentui/react-northstar'
import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { copyToClipboard } from '../../utils'
import styles from './TicketDetail.module.css'

interface Props {
  entityId: string
  subEntityId: string
}

const TicketShare: React.FC<Props> = ({ entityId, subEntityId }) => {
  const authState = useSelector((state: RootState) => state.auth)
  const channelId = authState.context?.channel?.id
  const appID = authState.decodedSsoToken?.aud
  const context = encodeURI(
    JSON.stringify({
      subEntityId: subEntityId,
      channelId: channelId,
    })
  )
  const shareURL = `https://teams.microsoft.com/l/entity/${appID}/${entityId}?context=${context}`

  return (
    <Popup
      pointing={true}
      trigger={
        <div className={styles.copyParent}>
          <Button
            className={styles.copyButton}
            onClick={() => {
              copyToClipboard(shareURL)
            }}
            text
            content="共有"
            icon={<LinkIcon />}
          />
        </div>
      }
      content={'クリップボードにコピーしました'}
      trapFocus
      on="click"
    />
  )
}

export default TicketShare
