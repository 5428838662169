import * as Graph from '@microsoft/microsoft-graph-types'
import { Dictionary } from '@reduxjs/toolkit'

import {
  CustomFieldType,
  CustomFieldValue,
  FaqStatus,
  TicketStatus,
  Tickets,
} from '../../consts'
import { TicketCustomField } from '../../proto/ticket_custom_field_pb'
import {
  formatAbsolute,
  getWebagentDisplayName,
  isGreaterEqThan,
} from '../../utils'
import { UserPhoto } from '../auth/userPhotosSlice'
import { createDisplayName } from '../auth/users'
import { Data } from './TicketList'
import { Ticket } from './ticketSlice'

export const createTicketItems = (
  tickets: Ticket[],
  users: Dictionary<Graph.User>,
  members: Dictionary<Graph.AadUserConversationMember>,
  userPhotos: Dictionary<UserPhoto>,
  customFields: TicketCustomField.AsObject[]
): Data[] => {
  return tickets.map((t) =>
    createTicketItem(t, users, members, userPhotos, customFields)
  )
}

const createTicketItem = (
  ticket: Ticket,
  users: Dictionary<Graph.User>,
  members: Dictionary<Graph.AadUserConversationMember>,
  userPhotos: Dictionary<UserPhoto>,
  customFields: TicketCustomField.AsObject[]
): Data => {
  const userDisplayName =
    ticket.requesterType === Tickets.RequesterType.RequesterTypeWebagent
      ? getWebagentDisplayName(ticket.requesterUserId)
      : createDisplayName(ticket.requesterUserId, users) // 質問者
  const assignedUserName = createDisplayName(ticket.assignedUserId, members) // 担当者
  const userPhoto = userPhotos[ticket.requesterUserId]
  const faqCreatorDisplayName = ticket.faqCreatorUserId
    ? createDisplayName(ticket.faqCreatorUserId, members)
    : null
  const faqCreatorPhoto = ticket.faqCreatorUserId
    ? userPhotos[ticket.faqCreatorUserId]
    : null
  const customFieldData: {
    [key: number]: string | number
  } = {}

  if (customFields.length > 0) {
    customFields.forEach((cf, idx) => {
      const fieldOneOfValues = ticket.ticketCustomFieldValuesList.find(
        (fieldValue) => fieldValue.ticketCustomFieldId === cf.id
      )
      if (fieldOneOfValues != null) {
        switch (cf.type) {
          case CustomFieldType.multiselect:
            customFieldData[idx] = fieldOneOfValues.valueList
              .map((v) => v.stringValue)
              .join('/')
            break
          case CustomFieldType.select:
          case CustomFieldType.text:
            customFieldData[idx] = fieldOneOfValues.valueList.map(
              (v) => v.stringValue
            )[0]
            break
          case CustomFieldType.textarea:
            customFieldData[idx] = fieldOneOfValues.valueList.map(
              (v) => v.textValue
            )[0]
            break
          case CustomFieldType.checkbox:
          case CustomFieldType.number:
            customFieldData[idx] = fieldOneOfValues.valueList.map(
              (v) => v.numberValue
            )[0]
            break
          case CustomFieldType.date: {
            const stringDate = fieldOneOfValues.valueList.map(
              (v) => v.dateValue
            )[0]
            if (stringDate.includes('T')) {
              customFieldData[idx] = stringDate
                .substring(0, stringDate.indexOf('T'))
                .replace(/-/g, '/')
            } else {
              customFieldData[idx] = stringDate
            }
            break
          }
        }
      } else {
        customFieldData[idx] = CustomFieldValue.DisplayedNullValue
      }
    })
  }
  return {
    inquiry: {
      id: ticket.id,
      avatar: {
        image: !userPhoto ? '' : userPhoto.avatarBlob || '',
        name: userDisplayName,
      },
      name: userDisplayName,
      subject: {
        text: ticket.subject,
        emphasis: isReplyNeeded(ticket),
      },
    },
    requester: {
      name: userDisplayName,
      avatar: {
        image: !userPhoto ? '' : userPhoto.avatarBlob || '',
        name: userDisplayName,
      },
    },
    subject: {
      text: ticket.subject,
      emphasis: isReplyNeeded(ticket),
      isManual: ticket.isManual,
    },
    assignedUserId: assignedUserName,
    status: ticket.status as TicketStatus,
    createdAt: formatAbsolute(ticket.createdAt),
    receivedAt: formatAbsolute(ticket.receivedAt, '--'),
    sentAt: formatAbsolute(ticket.sentAt, '--'),
    keyId: ticket.keyId,
    faqStatus: ticket.faqStatus as FaqStatus,
    faqCreator: faqCreatorDisplayName
      ? {
          name: faqCreatorDisplayName,
          avatar: {
            image: !faqCreatorPhoto ? '' : faqCreatorPhoto.avatarBlob || '',
            name: faqCreatorDisplayName,
          },
        }
      : null,
    ...customFieldData,
  }
}

const isReplyNeeded = (ticket: Ticket): boolean => {
  if (ticket.status === TicketStatus.completed) {
    return false
  }
  if (!ticket.receivedAt || !ticket.sentAt) return true
  return isGreaterEqThan(ticket.receivedAt, ticket.sentAt)
}
