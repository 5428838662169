export type RequesterMessage =
  | RequesterMessageButtons
  | RequesterMessageText
  | RequesterMessageConfirm
  | RequesterMessageImage
  | RequesterMessageVideo
  | RequesterMessagePostback

export type RequesterMessageButtons = {
  type: 'buttons'
  text: string
  buttons: MessageButtonsItem[]
}
export type MessageButtonsItem = {
  key: string
  label: string
  url: string
  type: string
  data?: string
}

export type RequesterMessageConfirm = {
  type: 'confirm'
  text: string
  buttons: MessageButtonsItem[]
}

export type RequesterMessageText = {
  type: 'text'
  text: string
}

export type RequesterMessageImage = {
  type: 'image'
  src: string
  to?: {
    data?: string
    type?: string
    url?: string
  }
}

export type RequesterMessageVideo = {
  type: 'video'
  src: string
}

export type RequesterMessagePostback = {
  type: 'postback'
  text: string
  buttons: MessageButtonsItem[]
}

export const hasButtonsMessage = (
  message: RequesterMessage
): message is
  | RequesterMessageButtons
  | RequesterMessageConfirm
  | RequesterMessagePostback => {
  switch (message.type) {
    case 'buttons':
    case 'confirm':
      return true
    default:
      return false
  }
}

export const hasTextMessage = (
  message: RequesterMessage
): message is Exclude<
  RequesterMessage,
  RequesterMessageImage | RequesterMessageVideo
> => {
  return message.type !== 'image' && message.type !== 'video'
}
