import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type AttachmentItemStatus = 'pending' | 'success' | 'error'
type FieldKey = string
export type AttachmentItem = {
  id: string
  file: File
  status: AttachmentItemStatus
  driveId?: string
  itemId?: string
  url?: string
  shareLink?: string
  uniqueId: string
}
type ChatField = {
  key: FieldKey
  message: string
  attachments: AttachmentItem[]
}
type State = {
  /* 複数のChatInputがレンダリングされそれぞれの値を分けて管理するためRecordで持っておく */
  fields: Record<FieldKey, ChatField>
}
const initialState: State = {
  fields: {} as Record<FieldKey, ChatField>,
}

const slice = createSlice({
  name: 'requesterChatInput',
  initialState,
  reducers: {
    appendAttachment: (
      state,
      action: PayloadAction<{
        key: FieldKey
        file: File
        id: string
        uniqueId: string
      }>
    ) => {
      if (!state.fields[action.payload.key]) {
        state.fields[action.payload.key] = {
          key: action.payload.key,
          message: '',
          attachments: [
            {
              id: action.payload.id,
              status: 'pending',
              file: action.payload.file,
              uniqueId: action.payload.uniqueId,
            },
          ],
        }
        return
      }

      state.fields[action.payload.key].attachments.push({
        id: action.payload.id,
        status: 'pending',
        file: action.payload.file,
        uniqueId: action.payload.uniqueId,
      })
    },
    finishUploadAttachment: (
      state,
      action: PayloadAction<{
        key: FieldKey
        id: string
        driveId: string
        itemId: string
        url: string
        shareLink: string
        uniqueId: string
      }>
    ) => {
      if (!state.fields[action.payload.key]) return
      state.fields[action.payload.key].attachments = state.fields[
        action.payload.key
      ].attachments.map((a) => {
        if (a.id !== action.payload.id) return a
        return {
          ...a,
          status: 'success',
          driveId: action.payload.driveId,
          itemId: action.payload.itemId,
          url: action.payload.url,
          shareLink: action.payload.shareLink,
          uniqueId: action.payload.uniqueId,
        }
      })
    },
    errorUploadAttachment: (
      state,
      action: PayloadAction<{ key: FieldKey; id: string }>
    ) => {
      if (!state.fields[action.payload.key]) return
      state.fields[action.payload.key].attachments = state.fields[
        action.payload.key
      ].attachments.map((a) => {
        if (a.id !== action.payload.id) return a
        return {
          ...a,
          status: 'error',
        }
      })
    },
    removeAttachment: (
      state,
      action: PayloadAction<{ key: FieldKey; id: string }>
    ) => {
      if (!state.fields[action.payload.key]) return
      state.fields[action.payload.key].attachments = state.fields[
        action.payload.key
      ].attachments.filter((f) => f.id !== action.payload.id)
    },
    updateMessage: (
      state,
      action: PayloadAction<{ key: FieldKey; message: string }>
    ) => {
      if (!state.fields[action.payload.key]) {
        state.fields[action.payload.key] = {
          key: action.payload.key,
          message: action.payload.message,
          attachments: [],
        }
      }

      state.fields[action.payload.key].message = action.payload.message
    },
    resetField: (state, action: PayloadAction<{ key: FieldKey }>) => {
      state.fields[action.payload.key] = {
        key: action.payload.key,
        message: '',
        attachments: [],
      }
    },
  },
})

export const { actions, reducer } = slice
