import { Avatar, Loader } from '@fluentui/react-northstar'
import * as React from 'react'

import styles from './styles.module.css'

type Props = {
  isLoading?: boolean
  src: string
  name: string
}
const ActivityMessagePlanPreviewReceiverListItemPresenter: React.FC<Props> = (
  props
) => {
  if (props.isLoading) {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader />
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <Avatar name={props.name} image={props.src} />
      </div>
      <div className={styles.right}>{props.name}</div>
    </div>
  )
}

export default ActivityMessagePlanPreviewReceiverListItemPresenter
