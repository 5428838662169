import { EntityId, createSelector } from '@reduxjs/toolkit'
import map from 'lodash/fp/map'
import { compose } from 'redux'

import type { ChatButtonsProp } from '../../../../components/RequesterChatButtonGroup'
import type { FileProp } from '../../../../components/RequesterChatFileList'
import { Tickets } from '../../../../consts'
import { graphApi } from '../../../graphApi'
import { dateToTicketDateTime } from '../../lib/dateToTicketDateTime'
import { requesterReplyListApiDataSelector } from '../../selectors'
import { requesterReplyEntityAdapter } from '../../slice'
import { hasButtonsMessage } from '../../types/RequesterMessage'
import { RequesterChatFile } from '../../types/RequesterTicketEntity'

export const authorId = createSelector(
  [requesterReplyListApiDataSelector, (_, __, id: EntityId) => id],
  (replyEntity, id): string => {
    if (!replyEntity) return ''
    return (
      requesterReplyEntityAdapter.getSelectors().selectById(replyEntity, id)
        ?.authorId ?? ''
    )
  }
)

export const time = createSelector(
  [requesterReplyListApiDataSelector, (_, __, id: EntityId) => id],
  (replyEntity, id): string => {
    if (!replyEntity) return ''
    return compose(
      dateToTicketDateTime,
      (str: string) => new Date(str),
      (reply) => reply?.createdAt ?? '',
      requesterReplyEntityAdapter.getSelectors().selectById
    )(replyEntity, id)
  }
)

export const fileProps = createSelector(
  [requesterReplyListApiDataSelector, (_, __, id: EntityId) => id],
  (replyEntity, id): FileProp[] => {
    if (!replyEntity) return []
    return compose(
      map<RequesterChatFile, FileProp>((f) => ({
        id: f.id,
        fileName: f.name,
        url: f.url,
        mimeType: f.mimeType,
        status: 'done',
      })),
      (reply) => reply?.files ?? [],
      requesterReplyEntityAdapter.getSelectors().selectById
    )(replyEntity, id)
  }
)

export const isOwnMessage = createSelector(
  [
    requesterReplyListApiDataSelector,
    (_, __, id: EntityId) => id,
    (state) => graphApi.endpoints.getProfileMe.select()(state).data,
  ],
  (replyEntity, id, profileMe): boolean => {
    if (!replyEntity) return false
    if (!profileMe) return false

    const reply = requesterReplyEntityAdapter
      .getSelectors()
      .selectById(replyEntity, id)
    if (!reply) return false

    return reply.authorId === profileMe.id
  }
)

// 発話者のアバター画像と名前をチャット横に表示させるかの判定に使われている
// true: only show the message
// false: show message and head info
export const isSimpleMessage = createSelector(
  [requesterReplyListApiDataSelector, (_, __, id: EntityId) => id],
  (replyEntity, id): boolean => {
    if (!replyEntity) return false
    const currentReplyIndex = requesterReplyEntityAdapter
      .getSelectors()
      .selectIds(replyEntity)
      .findIndex((replyId) => replyId === id)

    if (isNotFoundIndex(currentReplyIndex) || isHeadIndex(currentReplyIndex))
      return false

    const currentReply = requesterReplyEntityAdapter
      .getSelectors()
      .selectById(replyEntity, id)
    const prevReplyId = requesterReplyEntityAdapter
      .getSelectors()
      .selectIds(replyEntity)[prevIndex(currentReplyIndex)]
    const prevReply = requesterReplyEntityAdapter
      .getSelectors()
      .selectById(replyEntity, prevReplyId)

    if (!currentReply) return false
    if (!prevReply) return false

    // 発話者が切り替わっているならfalse
    if (currentReply.senderType !== prevReply.senderType) return false

    return currentReply.authorId === prevReply.authorId
  }
)

const isNotFoundIndex = (index: number) => index === -1
const isHeadIndex = (index: number) => index === 0
const prevIndex = (index: number) => index - 1

export const isRenderButtonGroup = createSelector(
  [requesterReplyListApiDataSelector, (_, __, id: EntityId) => id],
  (replyEntity, id): boolean => {
    if (!replyEntity) return false
    const reply = requesterReplyEntityAdapter
      .getSelectors()
      .selectById(replyEntity, id)
    if (!reply) return false
    return hasButtonsMessage(reply.message)
  }
)
export const buttonGroup = createSelector(
  [requesterReplyListApiDataSelector, (_, __, id: EntityId) => id],
  (replyEntity, id): ChatButtonsProp => {
    if (!replyEntity) return []
    const reply = requesterReplyEntityAdapter
      .getSelectors()
      .selectById(replyEntity, id)
    if (!reply) return []
    if (!hasButtonsMessage(reply.message)) return []

    if (reply.message.type === 'buttons')
      return reply.message.buttons.map((b) => [
        { key: b.key, label: b.label, url: b.url, type: b.type, data: b.data },
      ])
    return [
      reply.message.buttons.map((b) => ({
        key: b.key,
        label: b.label,
        url: b.url,
        type: b.type,
      })),
    ]
  }
)

export const isRequesterMessage = createSelector(
  [requesterReplyListApiDataSelector, (_, __, id: EntityId) => id],
  (replyEntity, id): boolean => {
    if (!replyEntity) return false
    const reply = requesterReplyEntityAdapter
      .getSelectors()
      .selectById(replyEntity, id)
    if (!reply) return false
    return reply.senderType === Tickets.Messages.SenderTypeRequester
  }
)
