import * as React from 'react'

import { RecommendedFaqEntity } from '../../types/RecommendedFaqEntity'
import RecommendedFaqItemContent from '../RecommendedFaqContent'
import RecommendedFaqItemPresenter from './presenter'

type Props = {
  recommendedFaqItem: RecommendedFaqEntity
  open: boolean
  onClick: (faq: RecommendedFaqEntity) => void
}
const RecommendedFaqItem: React.FC<Props> = (props) => {
  return (
    <RecommendedFaqItemPresenter
      recommendedFaqItem={props.recommendedFaqItem}
      renderRecommendedFaqItemContent={() => {
        return (
          <RecommendedFaqItemContent
            recommendedFaqItem={props.recommendedFaqItem}
          />
        )
      }}
      open={props.open}
      onClick={props.onClick}
    />
  )
}

export default RecommendedFaqItem
