import { Filter } from '@cubejs-client/core'
import { useCubeQuery } from '@cubejs-client/react'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as DashboardFilterSidepanelSelectors from '../../../DashboardFilterSidepanel/selectors'
import NumberChart from '../../components/NumberChart'

export const CreatedTicketsChart: React.FC = () => {
  const startDate = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.startDateForQuery
  )
  const endDate = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.endDateForQuery
  )
  const assignedUserIdsFilter: Filter | null = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.assigneeUserIdsFilterForTickets
  )

  const filters: Filter[] | undefined = assignedUserIdsFilter
    ? [assignedUserIdsFilter]
    : undefined

  const query = useCubeQuery({
    measures: ['Tickets.count'],
    timeDimensions: [
      {
        dimension: 'Tickets.createdAt',
        dateRange: [startDate, endDate],
      },
    ],
    filters: filters,
    timezone: 'Asia/Tokyo',
  })

  return (
    <NumberChart
      title="問い合わせ数"
      data={query.resultSet?.rawData()[0]['Tickets.count']?.toString() ?? ''}
    />
  )
}

export default React.memo(CreatedTicketsChart)
