import * as Graph from '@microsoft/microsoft-graph-types'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { AppThunk, RootState } from '../../app/store'

export interface RequesterSuggetsState {
  loading: boolean
  error: string | null
}

export const requesterSuggestsAdapter = createEntityAdapter<{
  userId: string
  displayName: string
  mail: string
}>({
  selectId: (requesterSuggest) => requesterSuggest.userId || '',
  sortComparer: (a, b) => {
    if (a.displayName == null) return -1
    if (b.displayName == null) return 1
    return a.displayName.localeCompare(b.displayName)
  },
})

export const requesterSuggestsSelector = requesterSuggestsAdapter.getSelectors(
  (root: RootState) => root.requesterSuggests
)

export const requesterSuggestsSlice = createSlice({
  name: 'requesterSuggests',
  initialState: requesterSuggestsAdapter.getInitialState<RequesterSuggetsState>(
    {
      loading: false,
      error: null,
    }
  ),
  reducers: {
    fetchRequesterSuggestsStart(state) {
      state.loading = true
      state.error = null
    },
    fetchRequesterSuggestsSuccess(
      state,
      action: PayloadAction<{ users: Array<Graph.User> }>
    ) {
      const { users } = action.payload
      const suggests = users
        .map((user) => {
          return {
            userId: user.id ?? '',
            displayName: user.displayName ?? '',
            mail: user.mail ?? '',
          }
        })
        .filter((obj) => obj !== { userId: '', displayName: '', mail: '' })
      requesterSuggestsAdapter.setAll(state, suggests)
      state.loading = false
      state.error = null
    },
    initRequesterSuggests(state) {
      requesterSuggestsAdapter.setAll(state, [])
    },
  },
})

export const {
  fetchRequesterSuggestsStart,
  fetchRequesterSuggestsSuccess,
  initRequesterSuggests,
} = requesterSuggestsSlice.actions
export default requesterSuggestsSlice.reducer

export const fetchRequesterSuggests =
  (query: string): AppThunk =>
  async (dispatch, getState, { graphAPI }) => {
    // リクエストするクエリストリングに濁点が含まれており、queryとして濁点が挿入されてしまうと例外が発生するのでサニタイズ
    const sanitizedQuery = query.replace(/"/g, '')
    if (!sanitizedQuery || !sanitizedQuery.match(/\S/g))
      return dispatch(initRequesterSuggests())
    dispatch(fetchRequesterSuggestsStart())

    const searchParams = new URLSearchParams()
    searchParams.append('$top', '50')
    searchParams.append(
      '$search',
      `"displayName:${sanitizedQuery}" OR "surName:${sanitizedQuery}" OR "givenName:${sanitizedQuery}" OR "mail:${sanitizedQuery}"`
    )

    // 50人まで && (displayName || surName || givenName || mail) で検索
    const res = await graphAPI
      .api(`/users?${searchParams.toString()}`)
      .header('ConsistencyLevel', 'eventual')
      .get()
    dispatch(fetchRequesterSuggestsSuccess({ users: res.value }))
  }
