import { Flex, Text } from '@fluentui/react-northstar'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { Dropdown } from '../../components/Dropdown'
import { TicketStatus, TicketStatusLabels } from '../../consts'
import styles from './TicketDefaultFieldFilter.module.css'
import { setStatuses } from './ticketSlice'

const TicketStatusFilter: React.FC = () => {
  const ticketState = useSelector((state: RootState) => state.ticket)
  const dispatch = useDispatch()
  return (
    <Flex column gap="gap.small">
      <Text content="絞り込み" weight="bold" className={styles.headingText} />
      <Dropdown
        fluid
        className={styles.dropdown}
        multiple
        placeholder={'すべて'}
        clearable
        checkable
        value={ticketState.filters.statuses}
        items={[
          {
            value: TicketStatus.new,
            header: TicketStatusLabels[TicketStatus.new],
          },
          {
            value: TicketStatus.working,
            header: TicketStatusLabels[TicketStatus.working],
          },
          {
            value: TicketStatus.pending,
            header: TicketStatusLabels[TicketStatus.pending],
          },
          {
            value: TicketStatus.completed,
            header: TicketStatusLabels[TicketStatus.completed],
          },
        ]}
        onValueChange={(values) => {
          dispatch(
            setStatuses({ statuses: values.map((v) => v as TicketStatus) })
          )
        }}
      />
    </Flex>
  )
}

export default TicketStatusFilter
