import { Loader } from '@fluentui/react-northstar'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import type { RouteProps } from 'react-router-dom'

import { useGetRequesterAuthQuery } from '../RequesterAuth/api'
import * as RequesterAuthSelectors from '../RequesterAuth/selectors'
import { getAzureADToken } from './operations'

const RequesterProtectedRoute: React.FC<React.PropsWithChildren<RouteProps>> = (
  props
) => {
  const dispatch = ReactRedux.useDispatch()
  const azureADToken = ReactRedux.useSelector(
    RequesterAuthSelectors.azureADToken
  )
  const { isLoading, isUninitialized, error } = useGetRequesterAuthQuery(
    azureADToken ?? '',
    {
      // AzureADTokenが未取得の時はこのクエリを実行しない
      skip: !azureADToken,
    }
  )

  React.useEffect(() => {
    // AzureADTokenを取得する
    dispatch(getAzureADToken())
  }, [dispatch])

  if (error) {
    console.error(error)
    return <Redirect to="/errors/unknown" />
  }
  if (isUninitialized || isLoading)
    return <Loader styles={{ height: '100vh' }} />

  return <Route {...props}>{props.children}</Route>
}

export default RequesterProtectedRoute
