import { Button, ChevronStartIcon } from '@fluentui/react-northstar'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import styles from './styles.module.css'

const RecommendedFaqListHeaderPresenter: React.FC = () => {
  const history = useHistory()
  return (
    <div className={styles.container}>
      <div className={styles.headerLeft}>
        <Button
          text
          icon={<ChevronStartIcon />}
          content="問い合わせ一覧"
          onClick={() => {
            history.push('/tickets')
          }}
        />
      </div>
    </div>
  )
}

export default RecommendedFaqListHeaderPresenter
