import { Flex, Text } from '@fluentui/react-northstar'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { Tickets } from '../../consts'
import type { PulldownItem } from '../assigneeSelection/types/PulldownItem'
import styles from './TicketDefaultFieldFilter.module.css'
import { setAssignedUserIds } from './ticketSlice'

type Props = {
  renderAssigneeSelection: (
    values: string[],
    onChange: (values: string[]) => void,
    extendedItems: PulldownItem[]
  ) => React.ReactElement
}
const TicketAssignedUserIdFilter: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const ticketState = useSelector((state: RootState) => state.ticket)

  return (
    <Flex column gap="gap.small" className={styles.dropdownWide}>
      <Text content="絞り込み" weight="bold" className={styles.headingText} />
      {props.renderAssigneeSelection(
        ticketState.filters.assignedUserIds ?? [],
        (values) => dispatch(setAssignedUserIds({ assignedUserIds: values })),
        [
          {
            value: '',
            header: Tickets.Users.UserNotIndicated,
          },
        ]
      )}
    </Flex>
  )
}

export default TicketAssignedUserIdFilter
