import { Flex, Input, Text } from '@fluentui/react-northstar'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import styles from './TicketDefaultFieldFilter.module.css'
import { setSubject } from './ticketSlice'

const TicketSubjectFilter: React.FC = () => {
  const delayTime = 500
  const maxTextLength = 255
  const dispatch = useDispatch()
  const ticketState = useSelector((state: RootState) => state.ticket)
  const [inputText, setInputText] = useState(ticketState.filters.subject)
  const isMountRef = useRef(true)
  useEffect(() => {
    if (isMountRef.current) {
      isMountRef.current = false
      return
    }
    const timeOutId = setTimeout(
      (text: string) => {
        dispatch(setSubject({ subject: text }))
      },
      delayTime,
      inputText
    )
    return () => clearTimeout(timeOutId)
  }, [dispatch, inputText])
  return (
    <Flex column gap="gap.small">
      <Text content="絞り込み" weight="bold" className={styles.headingText} />
      <Input
        fluid
        maxLength={maxTextLength}
        value={inputText}
        placeholder={'検索対象を入力'}
        onChange={(_, e) => {
          const text = String(e?.value)
          setInputText(text)
        }}
      />
    </Flex>
  )
}

export default TicketSubjectFilter
