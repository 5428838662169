import { Box, Button, Flex, TextArea } from '@fluentui/react-northstar'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { RootState } from '../../app/store'
import DialogWithCloseIcon from '../../components/DialogWithCloseIcon'
import { SearchableDropdownList } from '../../components/SearchableDropdown'
import { fetchUsers } from '../auth/usersSlice'
import styles from './ManualTicketSettingDialog.module.css'
import {
  fetchRequesterSuggests,
  requesterSuggestsSelector,
} from './requesterSuggestsSlice'
import { createManualTicket } from './ticketSlice'

interface Props {
  open: boolean
  onCancel: () => void
  onConfirm: () => void
}

const ManualTicketSettingDialog: React.FC<Props> = (props: Props) => {
  const { onConfirm } = props
  const [requesterUserId, setRequesterUserId] = useState<string>()
  const [lastSelectedValue, setLastSelectedValue] = useState<string>()
  const [inputText, setInputText] = useState('')
  const [inputSubject, setInputSubject] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()

  const buttonDisabled = useMemo(() => {
    if (requesterUserId == null || requesterUserId === '') {
      return true
    }

    if (inputSubject == null || inputSubject?.trim() === '') {
      return true
    }

    if (inputText !== lastSelectedValue) {
      return true
    }
    return false
  }, [requesterUserId, inputSubject, inputText, lastSelectedValue])

  const handleCreate = useCallback(() => {
    if (buttonDisabled === true) {
      return
    }

    if (requesterUserId == null) {
      return
    }
    dispatch(
      createManualTicket(requesterUserId, inputText, inputSubject, history)
    )

    // 指定したリクエスター情報を改めて取得します。
    dispatch(fetchUsers([requesterUserId]))
    onConfirm()
    setInputText('')
    setInputSubject('')
  }, [
    dispatch,
    history,
    inputSubject,
    inputText,
    buttonDisabled,
    requesterUserId,
    onConfirm,
  ])

  return (
    <DialogWithCloseIcon
      open={props.open}
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
      content={
        <Box>
          <h3>依頼人</h3>
          <Flex className={styles.content}>
            <ManualTicketRequester
              requesterUserId={requesterUserId}
              setRequesterUserId={setRequesterUserId}
              setLastSelectedValue={setLastSelectedValue}
              inputText={inputText}
              setInputText={setInputText}
            />
          </Flex>
          <h3>タイトル</h3>
          <Flex className={styles.content}>
            <TextArea
              value={inputSubject}
              onChange={(_, d) => setInputSubject(d?.value || '')}
              maxLength={1000}
            />
          </Flex>
        </Box>
      }
      footer={
        <Button primary disabled={buttonDisabled} onClick={handleCreate}>
          作成
        </Button>
      }
      header="問い合わせ記録の追加"
      className={styles.dialog}
    />
  )
}

interface ManualTicketRequesterProps {
  requesterUserId?: string
  setRequesterUserId: (id: string) => void
  setLastSelectedValue: React.Dispatch<React.SetStateAction<string | undefined>>
  inputText: string
  setInputText: React.Dispatch<React.SetStateAction<string>>
}

const ManualTicketRequester: React.FC<ManualTicketRequesterProps> = (
  props: ManualTicketRequesterProps
) => {
  const {
    requesterUserId,
    setRequesterUserId,
    setLastSelectedValue,
    inputText,
    setInputText,
  } = props

  const requesterSuggests = useSelector(requesterSuggestsSelector.selectAll)
  const requesterSuggestsState = useSelector(
    (state: RootState) => state.requesterSuggests
  )
  const dispatch = useDispatch()

  const searchForRequester = useCallback(
    (inputText: string) => {
      dispatch(fetchRequesterSuggests(inputText))
    },
    [dispatch]
  )

  const list = useMemo(
    () =>
      requesterSuggests.map((r) => ({
        id: r.userId,
        header: r.displayName,
        key: r.userId,
        content: r.mail,
      })),
    [requesterSuggests]
  )

  return (
    <Flex column className={styles.container} gap="gap.smaller">
      <SearchableDropdownList
        loading={requesterSuggestsState.loading}
        handleUpdateOnInputChange={searchForRequester}
        setInputText={setInputText}
        list={list}
        isSelected={(userId: string) => requesterUserId === userId}
        inputText={inputText}
        handleOptionSelected={(data) => {
          if (data.index == null) {
            return
          }
          const nextId = requesterSuggests[data.index].userId
          setRequesterUserId(nextId)
          const nextValue = data.header?.toString()
          if (nextValue == null) {
            return
          }
          setInputText(nextValue)
          setLastSelectedValue(nextValue)
        }}
      />
    </Flex>
  )
}

export default ManualTicketSettingDialog
