import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../app/store'

const stateSelector = (state: RootState) => state.recommendedFaqTicket

export const showAllAutoMessageSelector = createSelector(
  stateSelector,
  (state) => {
    return state.showAllAutoMessage
  }
)
