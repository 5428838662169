import * as React from 'react'
import * as ReactRedux from 'react-redux'
import { debounce } from 'throttle-debounce'

import type { RootState, ThunkDispatchType } from '../../app/store'
import { fileUploadAvailable } from '../RequesterAuth/selectors'
import * as AuthSelectors from '../RequesterAuth/selectors'
import * as Operations from './operations'
import RequesterChatInputPresenter from './presenter'
import * as Selectors from './selectors'
import * as Slice from './slice'

type Props = {
  /** 複数個あるこのChatInputを識別するためのキー */
  chatKey: string
  placeholder?: string
  inputFocus?: boolean
  onClickSend: (value: string) => Promise<void>
  onClickClose: () => void
}
const RequesterChatInput: React.FC<Props> = (props) => {
  // print focusTrigger to console
  const dispatch = ReactRedux.useDispatch<ThunkDispatchType>()

  const fileProps = ReactRedux.useSelector((state: RootState) =>
    Selectors.filePropsSelector(state, props.chatKey)
  )
  const message = ReactRedux.useSelector((state: RootState) =>
    Selectors.message(state, props.chatKey)
  )
  const isFileUploadAvailable = ReactRedux.useSelector(fileUploadAvailable)
  const appId = ReactRedux.useSelector(AuthSelectors.appId)

  const clickSend = React.useCallback(
    (message: string) => {
      props.onClickSend(message).then(() => {
        dispatch(Slice.actions.resetField({ key: props.chatKey }))
      })
    },
    [props, dispatch]
  )
  const clickClose = React.useCallback(() => {
    props.onClickClose()
    dispatch(Slice.actions.resetField({ key: props.chatKey }))
  }, [props, dispatch])
  const uploadImage = React.useCallback(
    (file: File) => {
      return dispatch(Operations.uploadImageToGraph({ file, appId })).unwrap()
    },
    [dispatch, appId]
  )
  const appendFile = React.useCallback(
    (files: File[]) => {
      return dispatch(
        Operations.uploadFileToGraph({
          file: files[0],
          key: props.chatKey,
          appId,
        })
      )
    },
    [dispatch, props.chatKey, appId]
  )
  const removeFile = React.useCallback(
    (id: string) => {
      return dispatch(
        Operations.deleteFileFromGraph({ key: props.chatKey, id })
      )
    },
    [dispatch, props.chatKey]
  )
  const onChange = React.useCallback(
    (message: string) => {
      dispatch(Slice.actions.updateMessage({ key: props.chatKey, message }))
    },
    [dispatch, props]
  )

  return (
    <RequesterChatInputPresenter
      value={message}
      placeholder={props.placeholder}
      files={fileProps}
      isAvailableFileUpload={isFileUploadAvailable}
      onClickAppendFile={appendFile}
      onClickRemoveFile={removeFile}
      onClickClose={clickClose}
      onClickSend={clickSend}
      onChange={debounce(300, onChange)}
      uploadImage={uploadImage}
      inputFocus={props.inputFocus}
    />
  )
}

export default RequesterChatInput
