import { Filter } from '@cubejs-client/core'
import { useCubeQuery } from '@cubejs-client/react'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as DashboardFilterSidepanelSelectors from '../../../DashboardFilterSidepanel/selectors'
import NumberChart from '../../components/NumberChart'

const CompletedTicketsChart = () => {
  const startDate = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.startDateForQuery
  )
  const endDate = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.endDateForQuery
  )
  const assignedUserIdsFilter: Filter | null = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.assigneeUserIdsFilterForTickets
  )

  const filters: Filter[] = [
    {
      member: 'Tickets.status',
      operator: 'contains',
      values: ['completed'],
    },
  ]
  if (assignedUserIdsFilter) {
    filters.push(assignedUserIdsFilter)
  }

  const query = useCubeQuery({
    measures: ['Tickets.count'],
    filters: filters,
    timeDimensions: [
      {
        dimension: 'Tickets.createdAt',
        dateRange: [startDate, endDate],
      },
    ],
    timezone: 'Asia/Tokyo',
  })

  return (
    <NumberChart
      title="解決済みの問い合わせ"
      data={query.resultSet?.rawData()[0]['Tickets.count']?.toString() ?? ''}
    />
  )
}

export default React.memo(CompletedTicketsChart)
