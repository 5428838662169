import format from 'date-fns/format'

import * as ActivityMessagePB from '../../proto/activity_message_pb'
import * as ActivityMessageServicePB from '../../proto/activity_message_pb_service'
import { AttachmentItem } from '../ActivityMessageAttachment/types/AttachmentItem'
import { protoIntoActivityMessagePlanStatus } from '../ActivityMessagePlanList/api'
import { deskOperatorApi } from '../deskApiOperator'
import {
  ActivityMessagePlanDetailEntity,
  ActivityMessagePlanReceiverType,
} from './types/ActivityMessagePlanDetailEntity'

const activityMessagePlanPreviewAPI = deskOperatorApi.injectEndpoints({
  endpoints: (build) => ({
    planDetail: build.query<ActivityMessagePlanDetailEntity, { id: number }>({
      query: (args) => ({
        service:
          ActivityMessageServicePB.ActivityMessageService
            .GetActivityMessagePlan,
        body: (() => {
          const req = new ActivityMessagePB.GetActivityMessagePlanRequest()
          req.setId(args.id)
          return req
        })(),
      }),
      transformResponse(
        result: ActivityMessagePB.GetActivityMessagePlanResponse.AsObject
      ): ActivityMessagePlanDetailEntity {
        return {
          id: result.activityMessageDetail?.activityMessage?.id ?? 0,
          body: result.activityMessageDetail?.activityMessage?.body ?? '',
          receiverType: protoIntoActivityMessageReceiverType(
            result.activityMessageDetail?.activityMessage?.receiver ?? null
          ),
          createdAt: format(
            result.activityMessageDetail?.activityMessage?.createdAt
              ? new Date(
                  result.activityMessageDetail?.activityMessage?.createdAt
                )
              : new Date(),
            'yyyy/MM/dd HH:MM'
          ),
          creatorID:
            result.activityMessageDetail?.activityMessage?.creatorId ?? '',
          sentAt: result.activityMessageDetail?.latestPublishLog?.publishedAt
            ? format(
                new Date(
                  result.activityMessageDetail?.latestPublishLog?.publishedAt
                ),
                'yyyy/MM/dd hh:MM'
              )
            : null,
          receiverIDs:
            result.activityMessageDetail?.activityMessage?.receiver?.userIds
              ?.userIdsList ??
            result.activityMessageDetail?.latestPublishLog
              ?.planedReceiverIdsList ??
            [],
          status: protoIntoActivityMessagePlanStatus(
            result.activityMessageDetail?.activityMessage?.status ?? 0
          ),
          succeedReceiverIDs:
            result.activityMessageDetail?.latestPublishLog
              ?.succeedReceiverIdsList ?? [],
          failedReceiverIDs:
            result.activityMessageDetail?.latestPublishLog
              ?.failedReceiverIdsList ?? [],
          attachments:
            result.activityMessageDetail?.attachmentsList.map(
              protoIntoAttachmentItem
            ) ?? [],
        }
      },
      providesTags: (result, _, arg) => [
        { type: 'ActivityMessagePlanDetail', id: arg.id },
      ],
    }),
  }),
})

export const { usePlanDetailQuery } = activityMessagePlanPreviewAPI

export default activityMessagePlanPreviewAPI

function protoIntoActivityMessageReceiverType(
  receiver: ActivityMessagePB.ActivityMessageReceiver.AsObject | null
): ActivityMessagePlanReceiverType {
  if (!receiver) {
    return ActivityMessagePlanReceiverType.All
  }

  if (receiver.all) {
    return ActivityMessagePlanReceiverType.All
  } else if (receiver.userIds?.userIdsList) {
    return ActivityMessagePlanReceiverType.ReceiverIDs
  }

  return ActivityMessagePlanReceiverType.All
}

function protoIntoAttachmentItem(
  proto: ActivityMessagePB.ActivityMessagePlanAttachment.AsObject
): AttachmentItem {
  return {
    id: proto.id.toString(),
    url: proto.url,
    fileName: proto.name,
    itemID: proto.itemId,
    groupID: proto.groupId,
    mimeType: proto.mimeType,
    status: 'success',
  }
}
