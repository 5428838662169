import { $generateHtmlFromNodes } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_LOW,
  KEY_ENTER_COMMAND,
} from 'lexical'
import React from 'react'

const KeyEventPlugin: React.FC<{
  isLimited: boolean
  isEmptyText: boolean
  hasImageNodeOrAttachments: boolean
  showToolbar: boolean
  onClickSend: (message: string) => void
}> = (props) => {
  const [editor] = useLexicalComposerContext()
  const { showToolbar, onClickSend } = props

  React.useEffect(() => {
    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      (e) => {
        // テキストがリミット超えていたらスキップ
        if (props.isLimited) {
          return true
        }
        // テキストが未入力、かつ、画像・ファイル添付がないならスキップ。
        if (props.isEmptyText && !props.hasImageNodeOrAttachments) {
          return true
        }

        if (e?.key !== 'Enter') {
          return true
        }

        // shiftが押されてあるなら、デフォルトの改行にします
        if (e.shiftKey) {
          return true
        }

        // ツールバーが開かれてないなら、enterキーが押されたら、送信します
        if (!showToolbar) {
          onClickSend($generateHtmlFromNodes(editor))
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
          return false
        }

        // ツールバーが開かれてあるなら、enterキーとcmdもしくはctrと一緒に押されてから、送信します
        if (e.metaKey || e.ctrlKey) {
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
          onClickSend($generateHtmlFromNodes(editor))
          return false
        }

        return true
      },
      COMMAND_PRIORITY_LOW
    )
  }, [editor, onClickSend, showToolbar, props])

  return <></>
}

export default KeyEventPlugin
