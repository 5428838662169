import { Filter } from '@cubejs-client/core'
import { useCubeQuery } from '@cubejs-client/react'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as DashboardFilterSidepanelSelectors from '../../../DashboardFilterSidepanel/selectors'
import HoulyTicketsChartPresenter, { ChartRow } from './presenter'

const HourlyTicketsChart = () => {
  const startDate = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.startDateForQuery
  )
  const endDate = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.endDateForQuery
  )
  const assignedUserIdsFilterForTicketRateHourly = ReactRedux.useSelector(
    DashboardFilterSidepanelSelectors.assigneeUserIdsFilterForTicketRateHourly
  )

  const filters: Filter[] | undefined = assignedUserIdsFilterForTicketRateHourly
    ? [assignedUserIdsFilterForTicketRateHourly]
    : undefined

  const query = useCubeQuery({
    limit: 5000,
    measures: ['TicketRateHourly.rate'],
    timeDimensions: [
      {
        dimension: 'TicketRateHourly.created_at',
        dateRange: [startDate, endDate],
      },
    ],
    filters: filters,
    order: [['TicketRateHourly.hour', 'asc']],
    dimensions: ['TicketRateHourly.hour', 'TicketRateHourly.total'],
    timezone: 'Asia/Tokyo',
  })

  const data = React.useMemo<ChartRow[]>(() => {
    return (
      query.resultSet?.tablePivot().map((row) => ({
        hour: `${row['TicketRateHourly.hour'] as number}時` as const,
        ['比率']: row['TicketRateHourly.rate'] as number,
      })) ?? []
    )
  }, [query.resultSet])

  return <HoulyTicketsChartPresenter data={addMissingHoursData(data)} />
}

const addMissingHoursData = (data: ChartRow[]): ChartRow[] => {
  const newData: ChartRow[] = []

  for (let i = 0; i < 24; i++) {
    const hour = i.toString().padStart(2, '0') + '時'
    const existingData = data.find((entry) => entry.hour === hour)
    const ratio = existingData ? existingData.比率 : 0
    newData.push({
      hour: `${i}時` as const,
      ['比率']: ratio,
    })
  }

  return newData
}

export default React.memo(HourlyTicketsChart)
