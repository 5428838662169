import * as RTK from '@reduxjs/toolkit'

export type State = {
  showAllAutoMessage: boolean
}

const initialState: State = {
  showAllAutoMessage: false,
}

const slice = RTK.createSlice({
  name: 'recommendedFaqTicket',
  initialState,
  reducers: {
    toggleShowAllAutoMessage: (state) => {
      state.showAllAutoMessage = !state.showAllAutoMessage
    },
    resetShowAllAutoMessage: (state) => {
      if (!state.showAllAutoMessage) {
        return
      }
      state.showAllAutoMessage = false
    },
  },
})

export const { reducer, actions } = slice
